<template>
  <div>
    <v-toolbar dense fixed flat height="65" class="mb-4">
      <v-text-field v-model="search" dense outlined :clearable="true" persistent-placeholder style="width:200px;margin-top:34px" @click:append="search=''" :append-icon="mdiMagnify" label="Search roles" />
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider />
    <v-data-table :headers="headers" :items="roles" :search="search" :single-expand="true" :expanded.sync="expanded"
                  item-key="id" :sort-by="sortBy" :sort-desc="sortDesc" :footer-props="{ showFirstLastPage: true}"
                  mobile-breakpoint="100">
<template #item="{item}">
        <tr @click="expandPanel(item)">
          <td>{{ item.name }}</td>
          <td>{{ item.resourceText }}</td>
          <td>{{ item.author }}</td>
          <td>{{ formatDate(item.updated) }}</td>
        </tr>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-card>
            <v-card-actions>
              <v-btn text color="warn" @click="cancel();">
                Close
              </v-btn>
            </v-card-actions>
            <v-divider />
            <v-form class="mt-6" lazy-validation>
              <v-layout wrap>
                <v-flex px-4 xs12 sm6 md3>
                  <v-text-field v-model="item.name" outlined required persistent-placeholder
                                readonly :prepend-icon="mdiAccountTieHatOutline"
                                label="Name" placeholder="Captain"></v-text-field>
                </v-flex>
                <v-flex px-4 xs12 sm6 md9>
                  <v-layout wrap v-for="(row, index) in item.data" :key="index">
                    <v-flex px-4 my-n1 xs12 sm4>
                      <v-select outlined v-model="row.resource" :items="resources" item-key="id" item-text="text"
                                item-value="value" readonly :rules="resourceRules"
                                label="Resource" persistent-placeholder />
                    </v-flex>
                    <v-flex px-4 my-n1 xs12 sm4>
                      <v-select outlined v-model="row.action" :items="row.resource.startsWith('book:') ? actionsBook : actionsUser"
                                item-key="id" item-text="text" item-value="value"
                                readonly :rules="actionRules" label="Action"
                                persistent-placeholder />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
            <v-divider />
          </v-card>
        </td>
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="warning">
        Your search for "{{ search }}" found no results.
      </v-alert>
    </v-data-table>
</div>
</template>

<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import moment from 'moment';
  import {
    mdiMagnify,
    mdiCheck,
    mdiClose,
    mdiAccountTieHatOutline,
    mdiAccountOutline,
    mdiLockOutline,
    mdiEyeOutline,
    mdiEyeOffOutline,
    mdiBadgeAccountHorizontalOutline,
    mdiPhoneOutline,
    mdiEmailOutline,
    mdiBadgeAccountOutline,
    mdiPlusCircleOutline,
    mdiShieldLockOutline,
  } from '@mdi/js';

  export default {
    name: 'Roles',
    components: {

    },
    data: () => ({
      mdiMagnify,
      mdiCheck,
      mdiClose,
      mdiAccountTieHatOutline,
      mdiAccountOutline,
      mdiLockOutline,
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiBadgeAccountHorizontalOutline,
      mdiBadgeAccountOutline,
      mdiPhoneOutline,
      mdiEmailOutline,
      mdiPlusCircleOutline,
      mdiShieldLockOutline,
      search: '',
      sortBy: 'name',
      sortDesc: false,
      headers: [{
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Permissions',
          value: 'resourceText'
        },
        {
          text: 'Author',
          value: 'author'
        },
        {
          text: 'Updated',
          value: 'updated'
        },
      ],
      actionsUser: [{
          text: 'View',
          value: 'view'
        },
        {
          text: 'Edit',
          value: 'edit'
        },
        {
          text: 'Create',
          value: 'create'
        },
        {
          text: 'Delete',
          value: 'delete'
        },
        {
          text: 'Restricted',
          value: 'restricted'
        },
      ],
      actionsBook: [{
          text: 'View',
          value: 'view'
        },
        {
          text: 'Draft',
          value: 10
        },
        {
          text: 'Verified',
          value: 20
        },
        {
          text: 'Unsign',
          value: 30
        },
        {
          text: 'Sign',
          value: 40
        },
        {
          text: 'Delete',
          value: -10
        },
        {
          text: 'Restricted',
          value: 'restricted'
        },
      ],
      expanded: [],
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => (v ? v.length >= 8 || 'Min 8 characters' : ''),
      },
      nameRules: [
        (v) => !!v || 'Name is required',
      ],
      resourceRules: [
        (v) => !!v || 'Resource is required',
      ],
      actionRules: [
        (v) => !!v || 'Action is required',
      ],
      newRule: {
        resource: null,
        action: null,
      },
      newRuleActions: [],
    }),
    created() {
    },
    watch: {
    },
    computed: {
      ...mapGetters({
        roles: 'logbook/getRoles',
        ship: 'logbook/getShip'
      }),
      resources() {
        let resources = this.ship.book;
        resources = resources.map(o => {
          o.text = o.description;
          o.value = 'book:' + o.name;
          return o;
        })
        resources.push({
          text: 'User',
          value: 'user',
        });
        resources.push({
          text: 'Role',
          value: 'role',
        });
        resources.push({
          text: 'System',
          value: 'system',
        });
        resources.push({
          text: 'Regulations',
          value: 'regulations',
        })
        return resources;
      }
    },
    methods: {
      ...mapActions({
        
      }),
      ...mapGetters({
      }),
      ...mapMutations({
        alertConfirm: 'alert/confirm',
        alertError: 'alert/error',
      }),
      expandPanel(item) {
        this.expanded = item === this.expanded[0] ? [] : [item];
      },
      cancel() {
          this.expanded = [];
      },
      formatDate(date) {
        if (date) {
          return moment.utc(date).local().format('D.M.Y HH:mm');
        }
        return '';
      },
      showAction(value) {
        switch (value) {
          case '-10':
            return 'deleted';
          case '10':
            return 'draft';
          case '20':
            return 'verified';
          case '30':
            return 'unsigned';
          case '40':
            return 'signed';
          default:
            return value;
        }
      },
      filterActions(resource) {
        if (resource.includes('book:')) {
          this.newRuleActions = this.actionsBook;
        } else if (!resource) {
          this.newRuleActions = [];
        } else {
          this.newRuleActions = this.actionsUser;
        }
      },
      callAddRule(data) {
        this.addRule(data);
        this.newRule = {
          resource: null,
          action: null,
        }
        this.newRuleActions = [];
      }
    }
  };
</script>

<style>

</style>
<style scoped>

</style>