import moment from 'moment';

export const tools = {
    isNumeric,
    round,
    timeAgo,
    timeAgoColor,
    escapeHtml,
    linearRegression,
    ConvertDDToDMS,
    decimalToDmm,
    dmmToDecimal,
    dmsToDecimal,
    getPerfUrl,
    secsToHoursMins,
    secsDelayToHoursMins,
    saveFile,
    arrayMove,
    severity,
    getCompassPoint,
    replaceAll,
    checkRules,
    parseLogVoyageData,
    getStatusText,
    formatExcelData,
    formatDate,
};

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

// Time ago
function timeAgo(date) {
    const unixDate = moment.utc(date).format('x');
    const now = Date.now();
    if (now - unixDate < 0) {
        return '';
    } if (now - unixDate < 60 * 1000) {
        return `${Math.round((now - unixDate) / (1000))} secs ago`;
    } if (now - unixDate < 2 * 60 * 1000) {
        return `${Math.round((now - unixDate) / (60 * 1000))} min ago`;
    } if (now - unixDate < 60 * 60 * 1000) {
        return `${Math.round((now - unixDate) / (60 * 1000))} mins ago`;
    } if (now - unixDate < 1.5 * 60 * 60 * 1000) {
        return `${Math.round((now - unixDate) / (60 * 60 * 1000))} hour ago`;
    } if (now - unixDate < 48 * 60 * 60 * 1000) {
        return `${Math.round((now - unixDate) / (60 * 60 * 1000))} hours ago`;
    } 
        return moment.utc(date).format('D.M HH:mm');
}

function timeAgoColor(date) {
    const unixDate = moment.utc(date).format('x');
    const now = Date.now();
    if (now - unixDate < 2 * 60 * 60 * 1000) {
        return `success`;
    } if (now - unixDate < 12 * 60 * 60 * 1000) {
        return `warning`;
    } else {
        return `error`;
    } 
}

// escape HTML special chars
function escapeHtml(unsafe) {
    if (unsafe) {
        return unsafe
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    }
}

// Trend line points from data (Y = slope * X + intercept).
function linearRegression(y, x){
    const lr = {};
    const n = y.length;
    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumXX = 0;
    // var sumYY = 0;
    for (let i = 0; i < y.length; i++) {
        sumX += x[i];
        sumY += y[i];
        sumXY += (x[i] * y[i]);
        sumXX += (x[i] * x[i]);
        // sumYY += (y[i]*y[i]);
    } 

    lr.slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
    lr.intercept = (sumY - lr.slope * sumX) / n;
    // lr['r2'] = Math.pow((n*sumXY - sumX*sumY)/Math.sqrt((n*sumXX-sumX*sumX)*(n*sumYY-sumY*sumY)),2);

    return lr;
}

// Convert decimal coordinates to DMS string
function ConvertDDToDMS(D, lng){
    const dir = D < 0 ? lng ? 'W' : 'S' : lng ? 'E' : 'N';
    const deg = 0 | (D < 0 ? D = -D : D);
    const min = 0 | D % 1 * 60;
    const sec = (0 | D * 60 % 1 * 6000) / 100;
    return `${deg}°${min}'${sec}" ${dir}`; 
}

function decimalToDmm(D, lng){
    const dir = D < 0 ? lng ? 'W' : 'S' : lng ? 'E' : 'N';
    const deg = 0 | (D < 0 ? D = -D : D);
    const min = Math.round((Math.abs(D) - Math.abs(deg)) * 10000) / 1000;
    return `${deg}°${min}' ${dir}`; 
}

/**
 * @description Convert degrees and minutes format to decimal format
 *
 * @param {*} value value in degrees and minutes
 * @returns {float} value in decimals
 */
 function dmmToDecimal(value) {
    const parts = value.split(/[^\d\w.]+/);
    const degrees = parts[0];
    const minutes = parts[1];
    const direction = parts[2];
    let dd = (Number(degrees) + (Number(minutes) / 60));
    if (direction == 'S' || direction == 'W') {
        dd = dd * -1;
    }
    return Math.round(dd*1000000)/1000000;
  }
  
  /**
   * @description Convert DMS format to decimal format
   *
   * @param {string} value value in degrees and minutes
   * @returns {float} value in decimals
   */
  function dmsToDecimal(value) {
    const parts = value.split(/[^\d\w.]+/);
    const degrees = parts[0];
    const minutes = parts[1];
    const seconds = parts[2];
    const direction = parts[3];
    let dd = Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60);
  
    if (direction == 'S' || direction == 'W') {
      dd = dd * -1;
    } // Don't do anything for N or E
    return dd;
  }

// Get performance url from login data
function getPerfUrl() {
    let perfUrl;
    try {
        const storage = JSON.parse(localStorage.getItem('user'));
        perfUrl = storage && storage.user && storage.user.perfUrl ? storage.user.perfUrl : null;
    } catch (e) {
        perfUrl = null;
    }
    if (!perfUrl) {
        console.error('Performance data server url is not defined.');
    }
    return perfUrl;
    // return 'http://localhost:3000'; 
}

// Convert seconds to hours and minutes
function secsToHoursMins(secs) {
    if (secs && secs > 0) {
        let hours = Math.floor(secs / 3600);
        hours = hours < 10 ? `0${hours}` : hours;
        let mins = Math.round(secs % 3600 / 60);
        mins = mins < 10 ? `0${mins}` : mins;
        return `${hours}:${mins}`;
    } 
        return '00:00';
}

// Convert seconds to hours and minutes
function secsDelayToHoursMins(secs) {
    if (secs) {
        const sign = secs < 0 ? '-' : '';
        secs = Math.abs(secs);
        let hours = Math.floor(secs / 3600);
        hours = hours < 10 ? `0${hours}` : hours;
        let mins = Math.round(secs % 3600 / 60);
        mins = mins < 10 ? `0${mins}` : mins;        
        return `${sign + hours}:${mins}`;
    } 
        return '';
}

// Save file
function saveFile(data, headers, name = null) {
    const octetStreamMime = 'application/octet-stream';
    let success = false;
    // headers = headers();
    // Get the filename from the x-file-name header or default to "download.bin"
    const filename = headers['x-file-name'] || name || 'download.bin';
    console.log('filename', filename);
    // Determine the content type from the header or default to "application/octet-stream"
    const contentType = headers['content-type'] || octetStreamMime;
    console.log('content-type', contentType);
    let blob;
    let url;
    try {
        // Try using msSaveBlob if supported
        console.log('Trying saveBlob method ...');
        blob = new Blob([data], { type: contentType });
        if (navigator.msSaveBlob) navigator.msSaveBlob(blob, filename);
        else {
            // Try using other saveBlob implementations, if available
            const saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
            if (saveBlob === undefined) throw 'Not supported';
            saveBlob(blob, filename);
        }
        console.log('saveBlob succeeded');
        success = true;
    } catch (ex) {
        console.log('saveBlob method failed with the following exception:');
        console.log(ex);
    }
    if (!success) {
        try {
            console.log('Trying click method ...');
            blob = new Blob([data], { type: contentType });
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob, { type: contentType });
            a.download = filename;
            document.body.appendChild(a);
            a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a);
            console.log('Click method succeeded');
            success = true;
        } catch (ex) {
            console.log('Click method failed with the following exception:');
            console.log(ex);
        }
    }
    if (!success) {
        // Get the blob url creator
        const urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
        if (urlCreator) {
            // Try to use a download link
            const link = document.createElement('a');
            if ('download' in link) {
                // Try to simulate a click
                try {
                    // Prepare a blob URL
                    console.log('Trying download link method with simulated click ...');
                    blob = new Blob([data], { type: contentType });
                    url = urlCreator.createObjectURL(blob);
                    link.setAttribute('href', url);
                    // Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
                    link.setAttribute('download', filename);
                    // Simulate clicking the download link
                    const event = document.createEvent('MouseEvents');
                    event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                    link.dispatchEvent(event);
                    console.log('Download link method with simulated click succeeded');
                    success = true;
                } catch (ex) {
                    console.log('Download link method with simulated click failed with the following exception:');
                    console.log(ex);
                }
            }
            if (!success) {
                // Fallback to window.location method
                try {
                    // Prepare a blob URL
                    // Use application/octet-stream when using window.location to force download
                    console.log('Trying download link method with window.location ...');
                    blob = new Blob([data], { type: octetStreamMime });
                    url = urlCreator.createObjectURL(blob);
                    window.location = url;
                    console.log('Download link method with window.location succeeded');
                    success = true;
                } catch (ex) {
                    console.log('Download link method with window.location failed with the following exception:');
                    console.log(ex);
                }
            }
        }
    }
}

function arrayMove(arr, fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
}

function severity(severity) {
    let icon = 'event_note';
    let color = 'success';
    let colorHex = '#4caf50';
    let text = 'Routine';
    if (severity === 'Critical') {
        icon = 'warning';
        color = 'error';
        colorHex = '#f44336';
        text = 'Critical';
    } else if (severity === 'Notification') {
        icon = 'notifications';
        color = 'warning';
        colorHex = '#FFB300';
        text = 'Notification';
    }
    return {
        icon,
        color,
        colorHex,
        text,
    };
}

function getCompassPoint(dir) {
    if (dir >= 348.75 || dir < 11.25)
        return "N";
    if (dir >= 11.25 && dir < 33.75)
        return "NNE";
    if (dir >= 33.75 && dir < 56.25)
        return "NE";
    if (dir >= 56.25 && dir < 78.75)
        return "ENE";
    if (dir >= 78.75 && dir < 101.25)
        return "E";
    if (dir >= 101.25 && dir < 123.75)
        return "ESE";
    if (dir >= 123.75 && dir < 146.25)
        return "SE";
    if (dir >= 146.25 && dir < 168.75)
        return "SSE";
    if (dir >= 168.75 && dir < 191.25)
        return "S";
    if (dir >= 191.25 && dir < 213.75)
        return "SSW";	
    if (dir >= 213.75 && dir < 236.25)
        return "SW";		
    if (dir >= 236.25 && dir < 258.75)
        return "WSW";
    if (dir >= 258.75 && dir < 281.25)
        return "W";
    if (dir >= 281.25 && dir < 303.75)
        return "WNW";
    if (dir >= 303.75 && dir < 326.25)
        return "NW";
    if (dir >= 326.25 && dir < 348.75)
        return "NNW";
}

function escapeRegExp(str) {
    // eslint-disable-next-line
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}
function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function checkRules(item) {
    const rules = [];
    if (!item) {
      return rules;
    }
    if (item.required) {
      const rule =
        v => !!v || v === 0 ||
        `Required field.`
      rules.push(rule)
    }
    if (item.maxChar) {
      const rule =
        v => (v || '').length <= item.maxChar ||
        `A maximum of ${item.maxChar} characters is allowed`
      rules.push(rule)
    }
    if (item.minChar) {
      const rule =
        v => (v || '').length >= item.minChar ||
        `A minimum of ${item.minChar} characters is allowed`
      rules.push(rule)
    }
    if (item.int) {
      const rule =
        v =>
        /^-?\d+$/
        .test(v) || `Value must be integer`
      rules.push(rule)
    }
    if (typeof item.minValue !== 'undefined') {
      const rule =
        v => v >= item.minValue ||
        `Minimum value is ${item.minValue}`
      rules.push(rule)
    }
    if (typeof item.maxValue !== 'undefined') {
      const rule =
        v => v <= item.maxValue ||
        `Maximum value is ${item.maxValue}`
      rules.push(rule)
    }
    if (item.date) {
      const rule =
        v => v === null || v.length === 0 ||
        /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
        .test(v) || `Required format is YYYY-MM-DD`
      rules.push(rule)
    }
    if (item.time) {
      const rule =
      v => v == null || v.length === 0 ||
        /^(0?[0-9]|1[0-9]|2[0-3]):([0-5]\d)\s?$/
        .test(v) || `Required format is HH:mm`
      rules.push(rule)
    }
    if (item.longDMS) {
      const rule =
        v =>
        /^((180[°|\s]\s*)(0{1,2}['|\s]\s*)(0{1,2}([.|,]0{1,20})?["|\s]\s*)|((1[0-7]\d|0?\d\d|\d)[°|\s]\s*)(([0-5]\d|\d)['|\s]\s*)(([0-5]\d|\d)([.|,]\d{1,20})?["|\s]\s*))[EWew]$/
        .test(v) || `Required format is DDD° MM' SS.S" [W/E]`
      rules.push(rule)
    }
    if (item.latDMS) {
      const rule =
        v =>
        /^((90[°|\s]\s*)(0{1,2}['|\s]\s*)(0{1,2}([.|,]0{1,20})?["|\s]\s*)|(([1-8]\d|\d)[°|\s]\s*)(([0-5]\d|\d)['|\s]\s*)(([0-5]\d|\d)([.|,]\d{1,20})?["|\s]\s*))[NSns]$/
        .test(v) || `Required format is DD° MM' SS.S" [N/S]`
      rules.push(rule)
    }
    if (item.longDMM) {
      const rule =
        v =>
        /^((180[°|\s]\s*)(0{1,2}['|\s]\s*)(0{1,2}([.|,]0{1,20})?["|\s]\s*)|((1[0-7]\d|0?\d\d|\d)[°|\s]\s*)(([0-5]\d|\d)([.|,]\d{1,20})?['|\s]\s*))[EWew]$/
        .test(v) || `Required format is DDD° MM.MMM' [W/E]`
      rules.push(rule)
    }
    if (item.latDMM) {
      const rule =
        v =>
        /^((90[°|\s]\s*)(0{1,2}['|\s]\s*)(0{1,2}([.|,]0{1,20})?["|\s]\s*)|(([1-8]\d|\d)[°|\s]\s*)(([0-5]\d|\d)([.|,]\d{1,20})?['|\s]\s*))[NSns]$/
        .test(v) || `Required format is DD° MM.MMM' [N/S]`
      rules.push(rule)
    }
    return rules
  }

  function parseLogVoyageData(data, ship = null, findIssues) {
    if (data && data.length > 0) {
        let sorted = {};
        for (let i = 0, il = data.length; i < il; i++) {
            if (data[i].etd) {
                data[i].etd = moment.utc(data[i].etd).format();
                data[i].etdTime = moment.utc(data[i].etd).local().format('HH:mm');
            }
            if (data[i].atd) {
                data[i].atd = moment.utc(data[i].atd).format();
            }
            if (data[i].eta) {
                data[i].eta = moment.utc(data[i].eta).format();
            }
            if (data[i].ata) {
                data[i].ata = moment.utc(data[i].ata).format();
            }
            if (data[i].time) {
                data[i].time = moment.utc(data[i].time).format();
            }
            if (data[i].atd && data[i].etd) {
                data[i].depDelay = secsDelayToHoursMins(moment.utc(data[i].atd).unix() - moment.utc(data[i].etd).unix());
            }
            if (data[i].ata && data[i].eta) {
                data[i].arrDelay = secsDelayToHoursMins(moment.utc(data[i].ata).unix() - moment.utc(data[i].eta).unix());
            }
            if (data[i].atd && data[i].ata) {
                data[i].atSea = secsToHoursMins(moment.utc(data[i].ata).unix() - moment.utc(data[i].atd).unix());
            }
            if (data[i].atd || data[i].etd) {
                if (data[i].inPort) { // in port time is available
                    data[i].inPort = secsToHoursMins(data[i].inPort);
                } else {
                    // Find previous arrival to same port
                    let depTime = data[i].atd ? data[i].atd : data[i].etd;
                    for (let j = i + 1, jl = data.length; j < jl; j++) { // sorted desc so start from i+1 index
                        if (data[j].arrLocode === data[i].depLocode && data[j].shipId === data[i].shipId) { // Same port and ship previous voyage
                            let arrTime = data[j].ata ? data[j].ata : data[j].eta;
                            data[i].inPort = secsToHoursMins(moment.utc(depTime).unix() - moment.utc(arrTime).unix());
                            break;
                        }
                    }
                }
            }
            if (typeof data[i].cargoTons !== 'undefined' && data[i].cargoTons !== null) {
                data[i].cargoTons = Math.round(data[i].cargoTons * 100) / 100;
            }
            if (typeof data[i].cargoMeters !== 'undefined' && data[i].cargoMeters !== null) {
                data[i].cargoMeters = Math.round(data[i].cargoMeters * 100) / 100;
            }
            if (typeof data[i].carMeters !== 'undefined' && data[i].carMeters !== null) {
                data[i].carMeters = Math.round(data[i].carMeters * 100) / 100;
            }
            if (typeof data[i].carArea !== 'undefined' && data[i].carArea !== null) {
                data[i].carArea = Math.round(data[i].carArea * 100) / 100;
            } 
            if (typeof data[i].cargoArea !== 'undefined' && data[i].cargoArea !== null) {
                data[i].cargoArea = Math.round(data[i].cargoArea * 100) / 100;
            }
            if (typeof data[i].carTons !== 'undefined' && data[i].carTons !== null) {
                data[i].carTons = Math.round(data[i].carTons * 100) / 100;
            }
            if (typeof data[i].paxAreaShare !== 'undefined' && data[i].paxAreaShare !== null) {
                data[i].paxAreaShare = Math.round(data[i].paxAreaShare * 100) / 100;
            }
            if (typeof data[i].cargoAreaShare !== 'undefined' && data[i].cargoAreaShare !== null) {
                data[i].cargoAreaShare = Math.round(data[i].cargoAreaShare * 100) / 100;
            }
            if (typeof data[i].paxMassShare !== 'undefined' && data[i].paxMassShare !== null) {
                data[i].paxMassShare = Math.round(data[i].paxMassShare * 100) / 100;
            }
            if (typeof data[i].cargoMassShare !== 'undefined' && data[i].cargoMassShare !== null) {
                data[i].cargoMassShare = Math.round(data[i].cargoMassShare * 100) / 100;
            }

            data[i].focSea = Math.round(((data[i].focMeSea || 0) + (data[i].focAuxSea || 0) + (data[i].focBoilerSea || 0)) * 1000) / 1000;
            data[i].focPort = Math.round(((data[i].focMePort || 0) + (data[i].focAuxPort || 0) + (data[i].focBoilerPort || 0)) * 1000) / 1000;
            data[i].focTotal = Math.round((data[i].focSea + data[i].focPort) * 1000) / 1000;
            if (data[i].focMeSea) {
                data[i].focMeSea = Math.round(data[i].focMeSea * 1000) / 1000;
            }
            if (data[i].focMePort) {
                data[i].focMePort = Math.round(data[i].focMePort * 1000) / 1000;
            }
            if (data[i].focAuxSea) {
                data[i].focAuxSea = Math.round(data[i].focAuxSea * 1000) / 1000;
            }
            if (data[i].focAuxPort) {
                data[i].focAuxPort = Math.round(data[i].focAuxPort * 1000) / 1000;
            }
            if (data[i].focBoilerSea) {
                data[i].focBoilerSea = Math.round(data[i].focBoilerSea * 1000) / 1000;
            }
            if (data[i].focBoilerPort) {
                data[i].focBoilerPort = Math.round(data[i].focBoilerPort * 1000) / 1000;
            }

            // Fuel 1
            data[i].fuel1Co2 = Math.round(data[i].fuel1Co2 * 1000) / 1000 || 0;
            data[i].fuel1FocSea = Math.round(data[i].fuel1FocSea * 1000) / 1000 || 0;
            data[i].fuel1Co2Sea = Math.round(data[i].fuel1Co2Sea * 1000) / 1000 || 0;
            data[i].fuel1FocPort = Math.round(data[i].fuel1FocPort * 1000) / 1000 || 0;
            data[i].fuel1Co2Port = Math.round(data[i].fuel1Co2Port * 1000) / 1000 || 0;

            data[i].fuel1MeFocSea = Math.round(data[i].fuel1MeFocSea * 1000) / 1000 || 0;
            data[i].fuel1MeFocPort = Math.round(data[i].fuel1MeFocPort * 1000) / 1000 || 0;
            data[i].fuel1AuxFocSea = Math.round(data[i].fuel1AuxFocSea * 1000) / 1000 || 0;
            data[i].fuel1AuxFocPort = Math.round(data[i].fuel1AuxFocPort * 1000) / 1000 || 0;
            data[i].fuel1BoilerFocSea = Math.round(data[i].fuel1BoilerFocSea * 1000) / 1000 || 0;
            data[i].fuel1BoilerFocPort = Math.round(data[i].fuel1BoilerFocPort * 1000) / 1000 || 0;

            // Fuel 2
            data[i].fuel2Co2 = Math.round(data[i].fuel2Co2 * 1000) / 1000 || 0;
            data[i].fuel2FocSea = Math.round(data[i].fuel2FocSea * 1000) / 1000 || 0;
            data[i].fuel2Co2Sea = Math.round(data[i].fuel2Co2Sea * 1000) / 1000 || 0;
            data[i].fuel2FocPort = Math.round(data[i].fuel2FocPort * 1000) / 1000 || 0;
            data[i].fuel2Co2Port = Math.round(data[i].fuel2Co2Port * 1000) / 1000 || 0;

            data[i].fuel2MeFocSea = Math.round(data[i].fuel2MeFocSea * 1000) / 1000 || 0;
            data[i].fuel2MeFocPort = Math.round(data[i].fuel2MeFocPort * 1000) / 1000 || 0;
            data[i].fuel2AuxFocSea = Math.round(data[i].fuel2AuxFocSea * 1000) / 1000 || 0;
            data[i].fuel2AuxFocPort = Math.round(data[i].fuel2AuxFocPort * 1000) / 1000 || 0;
            data[i].fuel2BoilerFocSea = Math.round(data[i].fuel2BoilerFocSea * 1000) / 1000 || 0;
            data[i].fuel2BoilerFocPort = Math.round(data[i].fuel2BoilerFocPort * 1000) / 1000 || 0;
            // Fuel 3
            data[i].fuel3Co2 = Math.round(data[i].fuel3Co2 * 1000) / 1000 || 0;
            data[i].fuel3FocSea = Math.round(data[i].fuel3FocSea * 1000) / 1000 || 0;
            data[i].fuel3Co2Sea = Math.round(data[i].fuel3Co2Sea * 1000) / 1000 || 0;
            data[i].fuel3FocPort = Math.round(data[i].fuel3FocPort * 1000) / 1000 || 0;
            data[i].fuel3Co2Port = Math.round(data[i].fuel3Co2Port * 1000) / 1000 || 0;

            data[i].fuel3MeFocSea = Math.round(data[i].fuel3MeFocSea * 1000) / 1000 || 0;
            data[i].fuel3MeFocPort = Math.round(data[i].fuel3MeFocPort * 1000) / 1000 || 0;
            data[i].fuel3AuxFocSea = Math.round(data[i].fuel3AuxFocSea * 1000) / 1000 || 0;
            data[i].fuel3AuxFocPort = Math.round(data[i].fuel3AuxFocPort * 1000) / 1000 || 0;
            data[i].fuel3BoilerFocSea = Math.round(data[i].fuel3BoilerFocSea * 1000) / 1000 || 0;
            data[i].fuel3BoilerFocPort = Math.round(data[i].fuel3BoilerFocPort * 1000) / 1000 || 0;
           
            data[i].co2Sea = Math.round(((data[i].fuel1Co2Sea || 0) + (data[i].fuel2Co2Sea || 0) + (data[i].fuel3Co2Sea || 0)) * 1000) / 1000;
            data[i].co2Port = Math.round(((data[i].fuel1Co2Port || 0) + (data[i].fuel2Co2Port || 0) + (data[i].fuel3Co2Port || 0)) * 1000) / 1000;
            data[i].co2Total = Math.round((data[i].co2Sea + data[i].co2Port) * 1000) / 1000;

            data[i].scPower = Math.round(data[i].scPower * 1000) / 1000 || 0;
            data[i].fuel1lcvEu = Math.round(data[i].fuel1lcvEu * 1000) / 1000 || 0;
            data[i].fuel1lcvImo = Math.round(data[i].fuel1lcvImo * 1000) / 1000 || 0;
            data[i].fuel2lcvEu = Math.round(data[i].fuel2lcvEu * 1000) / 1000 || 0;
            data[i].fuel2lcvImo = Math.round(data[i].fuel2lcvImo * 1000) / 1000 || 0;
            data[i].fuel3lcvEu = Math.round(data[i].fuel3lcvEu * 1000) / 1000 || 0;
            data[i].fuel3lcvImo = Math.round(data[i].fuel3lcvImo * 1000) / 1000 || 0;
            
            if (data[i].propulsionPower) {
                data[i].propulsionPower = Math.round(data[i].propulsionPower * 100) / 100;
            }
            if (data[i].hotelPower) {
                data[i].hotelPower = Math.round(data[i].hotelPower * 100) / 100;
            }
            
            if (data[i].focSea && data[i].distance) {
                data[i].cons = Math.round((data[i].focSea * 1000 / data[i].distance) * 100) / 100;
            }
            if (data[i].co2Sea && data[i].distance && data[i].pax) {
                data[i].eeoiPax = Math.round((data[i].co2Sea * 1000000 / (data[i].pax * data[i].distance)) * 100) / 100;
            }
            if (data[i].co2Sea && data[i].distance && data[i].cargoTons) {
                data[i].eeoiTons = Math.round((data[i].co2Sea * 1000000 / (data[i].cargoTons * data[i].distance)) * 100) / 100;
            }
            const gt = ship ? ship.gt : data[i].gt;
            if (data[i].co2Sea && data[i].distance && gt) {
                data[i].eeoiGt = Math.round((data[i].co2Sea * 1000000 / (gt * data[i].distance)) * 100) / 100;
            }
            if (data[i].cii) {
                data[i].cii = Math.round(data[i].cii * 100) / 100;
            }
            
            if (data[i].distance) { 
                data[i].transportWorkPax = Math.round(data[i].pax * data[i].distance * 100) / 100;
                data[i].transportWorkCargo = Math.round(((data[i].carTons || 0) + (data[i].cargoTons || 0)) * data[i].distance * 100) / 100;
                data[i].distance = Math.round(data[i].distance * 100) / 100;
            } else {
                data[i].transportWorkPax = 0;
                data[i].transportWorkCargo = 0;
            }
            
            data[i].tws = Math.round((data[i].tws || 0) * 100) / 100;
            data[i].headwind = Math.round((data[i].headwind || 0) * 100) / 100;
            
            // Status
            data[i].statusText = getStatusText(data[i].status);

            if (data[i].cargoMeters) {
                data[i].gCo2Lm = Math.round((((data[i].co2Sea || 0) * (data[i].cargoAreaShare || 0) * 1000 * 1000) / data[i].cargoMeters) * 100) / 100;
            } else {
                data[i].gCo2Lm = 0;
            }
            if (data[i].distance) {
                data[i].gCo2LmNm = Math.round((data[i].gCo2Lm || 0) / data[i].distance * 100) / 100;
            } else {
                data[i].gCo2LmNm = 0;
            }   
            data[i].gCo217m = Math.round(data[i].gCo2Lm * 17 * 100) / 100;
            data[i].gCo217mNm = Math.round(data[i].gCo2LmNm * 17 * 100) / 100;
            
            if (data[i].cargoMeters) {
                data[i].gCo2LmM = Math.round((((data[i].co2Sea || 0) * (data[i].cargoMassShare || 0) * 1000 * 1000) / data[i].cargoMeters) * 100) / 100;
            } else {
                data[i].gCo2LmM = 0;
            }
            if (data[i].distance) {
                data[i].gCo2LmNmM = Math.round(data[i].gCo2LmM / data[i].distance * 100) / 100;
            } else {
                data[i].gCo2LmNmM = 0;
            }
            data[i].gCo217mM = Math.round(data[i].gCo2LmM * 17 * 100) / 100;
            data[i].gCo217mNmM = Math.round(data[i].gCo2LmNmM * 17 * 100) / 100;
            if (data[i].notes === 'null') {
                data[i].notes = '';
            }
            if (findIssues) {
                // Create array for checking false ports
                if (typeof sorted[data[i].name] === 'undefined') {
                    sorted[data[i].name] = [];
                }
                sorted[data[i].name].push(data[i]);
            }
        }
        if (findIssues) {
            // Sort based on etd
            let portIssues = [];
            for (const key in sorted) {
                if (Object.hasOwnProperty.call(sorted, key)) {
                    const voyages = sorted[key].sort((a, b) => ((moment(a.etd).valueOf() < moment(b.etd).valueOf()) ? 1 : ((moment(a.etd).valueOf() > moment(b.etd).valueOf()) ? -1 : 0)));
                    for (let i = 0, il = voyages.length-1; i < il; i++) { 
                        if (voyages[i].arrLocode === voyages[i+1].arrLocode || voyages[i].depLocode === voyages[i+1].depLocode || voyages[i].arrLocode === voyages[i].depLocode || voyages[i].depLocode !== voyages[i+1].arrLocode) {
                            // Mark as port issue!
                            portIssues.push(voyages[i]);
                        }
                    }    
                }
            }
            // console.log(portIssues);
            if (portIssues.length > 0) {
                for (const issue of portIssues) {
                    const index = data.findIndex(o => o.name === issue.name && o.etd === issue.etd && o.route === issue.route);
                    if (index > -1) {
                        data[index].portIssue = true;
                    }
                }
            }
        }
    }
    return data;
}

function getStatusText(status) {
    switch (status) {
        case -1:
            return 'Deleted';
        case 0:
            return 'Not completed ';
        case 1:
            return 'Check';
        case 2:
            return 'Auto OK';
        case 3:
            return 'Editing';
        case 4:
            return 'Verified OK';
        case 5:
            return 'Webfocus error';
        default:
            return 'Check';
    }
}

function formatExcelData(data) {
    let keys = Object.keys(data[0]);
    keys = keys.filter(o => o !== 'time');
    const headerRow = [{type: String, value: 'Time', fontWeight: 'bold'}];
    keys.map((o) => {
        headerRow.push({ type: String, value: o, fontWeight:'bold' });
    })
    const rows = [headerRow];
    const length = keys.length;
    if (length > 0) {
        for (const entry of data) {
            const columns = [];
            columns.push({type: Date, value: entry.time, format: 'dd.mm.yyyy hh:mm:ss'});
            for (let i = 0; i < length; i++) {
                let value = entry[keys[i]]; 
                if (typeof value !== 'undefined') {
                    let t = String;
                    if (value === null || value === '') {
                        value = '';
                    } else if (typeof value === 'number') {
                        t = Number;
                    } else if (typeof value === 'string' && value.startsWith('2') && value.includes('-') && moment(value).isValid()) {
                        t = String;
                    } else if (typeof value === 'string' && this.isNumeric(value) && value !== null && value !== '') {
                        t = Number;
                        value = parseFloat(value);
                    }
                    columns.push({ type: t, value: value});
                }
            }
            rows.push(columns);
        }
    }
    return rows;
}

function formatDate(date, timezone = 0) {
    timezone = parseInt(timezone);
    if (timezone >= -12 && timezone <= 12) {
        timezone = timezone * 60;
    }
    let tz = timezones().find((o) => o.value === timezone);
    // console.log('Timezone', timezone, tz);
    return date ? moment.utc(date).utcOffset(timezone).format('D.M Y HH:mm') + ' ' + tz.name : '';
}

function timezones() {
    return [{
            name: '-11:00',
            value: -660
        },
        {
            name: '-10:00',
            value: -600
        },
        {
            name: '-09:00',
            value: -540
        },
        {
            name: '-08:00',
            value: -480
        },
        {
            name: '-07:00',
            value: -420
        },
        {
            name: '-06:00',
            value: -360
        },
        {
            name: '-05:00',
            value: -300
        },
        {
            name: '-04:00',
            value: -240
        },
        {
            name: '-03:00',
            value: -180
        },
        {
            name: '-02:00',
            value: -120
        },
        {
            name: '-01:00',
            value: -60
        },
        {
            name: 'UTC',
            value: 0
        },
        {
            name: '+01:00',
            value: 60
        },
        {
            name: '+02:00',
            value: 120
        },
        {
            name: '+03:00',
            value: 180
        },
        {
            name: '+04:00',
            value: 240
        },
        {
            name: '+05:00',
            value: 300
        },
        {
            name: '+06:00',
            value: 360
        },
        {
            name: '+07:00',
            value: 420
        },
        {
            name: '+08:00',
            value: 480
        },
        {
            name: '+09:00',
            value: 540
        },
        {
            name: '+10:00',
            value: 600
        },
        {
            name: '+11:00',
            value: 660
        },
        {
            name: '+12:00',
            value: 720
        },
    ]
}
