<template>
    <div>
        <v-card class="cardbglight">
            <v-data-table :headers="headers" :items="data" :search="search" :single-expand="true" :expanded.sync="expanded" item-key="id" :sort-by="sortBy"
                :sort-desc="sortDesc" :footer-props="{ showFirstLastPage: true}" mobile-breakpoint="100" class="elevation-1">
                <template #top>
                    <v-toolbar>
                        <v-toolbar-title>Project types </v-toolbar-title>
                        <v-spacer />
                        <v-spacer />
                        <v-spacer />
                        <v-layout wrap>
                            <v-flex mt-8 xs6>
                                <v-switch v-model="active" :false-value="0" :true-value="1" :label="active === 1 ? 'Active' : 'Inactive'" color="primary"
                                    hint="Status" persistent-hint />
                            </v-flex>
                            <v-flex mt-6 xs6>
                                <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details />
                            </v-flex>
                        </v-layout>
                    </v-toolbar>
                </template>
                <template #item="{item}">
                    <tr @click="expandPanel(item)">
                        <td width="85">{{ item.id }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ showItems(item.defaultItems) }}</td>
                        <td>{{ item.author }}</td>
                        <td>{{ formatDate(item.updated) }}</td>
                        <td width="95">
                            <v-chip small class="ml-1" :color="item.eventColor"></v-chip>
                        </td>
                    </tr>
                </template>
                <template #expanded-item="{ headers }">
                    <td :colspan="headers.length" class="pa-0">
                        <v-card class="cardbg primarytext--text">
                            <v-form>
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex px-4 xs12 sm6 md3>
                                            <v-text-field v-model="entry.name" prepend-icon="wysiwyg" label="Name"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md5>
                                            <v-select v-model="entry.defaultItems" :items="moneyItems" multiple :item-text="'name'" item-value="id"
                                                prepend-icon="attach_money" label="Default money items">
                                                <template slot="selection" slot-scope="data">
                                                    <!-- HTML that describe how select should render selected items -->
                                                    ID{{ data.item.id }} {{ data.item.name }} ({{ data.item.type }})
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    <!-- HTML that describe how select should render items when the select is open -->
                                                    ID{{ data.item.id }} {{ data.item.name }} ({{ data.item.type }})
                                                </template>
                                            </v-select>
                                        </v-flex>
                                        <v-flex px-4 xs11 sm5 md3>
                                            <v-text-field v-model="entry.eventColor" hide-details prepend-icon="color_lens" label="Color">
                                                <template #append>
                                                    <v-menu v-model="menu1" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                                        <template #activator="{ on }">
                                                            <div :style="swatchStyle1" v-on="on" />
                                                        </template>
                                                        <v-card>
                                                            <v-card-text class="pa-0">
                                                                <v-color-picker v-model="color1" @input="entry.eventColor = color1" flat />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>
                                                </template>
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-switch style="margin-top: 18px;" v-model="entry.active" :false-value="0" :true-value="1"
                                            :label="entry.active === 1 ? 'Active' : 'Inactive'" color="primary" hint="Status"
                                            persistent-hint />

                                        <v-spacer />
                                        <v-btn class="mt-5 ml-8" color="primary" @click="callSave(entry)" :disabled="userLevel < 1">
Save
                                            <v-icon right dark>check</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-container>
                            </v-form>
                        </v-card>
                    </td>
                </template>
                <v-alert slot="no-results" :value="true" color="error" icon="warning">
                    Your search for "{{ search }}" found no results.
                </v-alert>
            </v-data-table>
            <v-expansion-panels :disabled="userLevel < 1" v-model="newPanel">
                <v-expansion-panel :disabled="userLevel < 1">
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="teal">add_circle</v-icon> <span class="text-body-1 ml-2">Add new project type</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <v-card class="cardbg">
                            <v-form>
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex px-4 xs12 sm6 md3>
                                            <v-text-field v-model="newEntry.name" prepend-icon="wysiwyg" label="Name"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md5>
                                            <v-select v-model="newEntry.defaultItems" :items="moneyItems" multiple :item-text="'name'" item-value="id"
                                                prepend-icon="attach_money" label="Default money items"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs11 sm5 md3>
                                            <v-text-field v-model="newEntry.eventColor" hide-details prepend-icon="color_lens" label="Color">
                                                <template #append>
                                                    <v-menu v-model="menu2" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                                        <template #activator="{ on }">
                                                            <div :style="swatchStyle2" v-on="on" />
                                                        </template>
                                                        <v-card>
                                                            <v-card-text class="pa-0">
                                                                <v-color-picker v-model="color2" @input="newEntry.eventColor = color2" flat />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>
                                                </template>
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-switch style="margin-top: 18px;" v-model="newEntry.active" :false-value="0" :true-value="1"
                                            :label="newEntry.active === 1 ? 'Active' : 'Inactive'" color="primary" hint="Status" persistent-hint />

                                        <v-spacer />
                                        <v-btn color="primary" class="mt-5 ml-8" @click="callAdd(newEntry)" :disabled="!newEntry.name || !newEntry.eventColor">
                                            Add
                                            <v-icon right dark>add_circle</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-container>
                            </v-form>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="primary">help</v-icon>
                            <span class="text-body-1 ml-2">What does this page show</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <br />
                        <p>The project type settings page...</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <StatusDialog />
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    import moment from 'moment';
    import StatusDialog from '../components/StatusDialog.vue';

    export default {
        name: 'ProjectTypes',
        components: {
            StatusDialog
        },
        data: () => ({
            search: '',
            sortBy: 'name',
            sortDesc: false,
            selected: [],
            active: 1,
            actives: [{
                    text: 'Inactive',
                    value: 0
                },
                {
                    text: 'Active',
                    value: 1
                },
            ],
            headers: [{
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Name',
                    value: 'name'
                },
                {
                    text: 'Default items',
                    value: 'defaultItems'
                },
                {
                    text: 'Author',
                    value: 'author'
                },
                {
                    text: 'Updated',
                    value: 'updated'
                },
                {
                    text: 'Color',
                    value: 'eventColor'
                },
            ],
            expanded: [],
            newEntry: {
                name: '',
                eventColor: '#FF00FFFF',
                defaultItems: [],
                active: 1
            },
            newPanel: [],
            color1: '#FF00FFFF',
            color2: '#FF00FFFF',
            menu1: false,
            menu2: false,
            entry: {},
        }),
        created() {
            this.fetchSchedulerParams();
        },
        computed: {
            userLevel() {
                return this.getUser().user.level;
            },
            data() {
                const data = this.getSchedulerParams();
                if (data && data.projectTypes && data.projectTypes.length > 0) {
                    return data.projectTypes.filter((o) => o.active === this.active);
                } 
                    return [];
            },
            moneyItems() {
                const data = this.getSchedulerParams();
                if (data && data.moneyItems && data.moneyItems.length > 0) {
                    return data.moneyItems;
                } 
                    return [];
            },
            swatchStyle1() {
                const {
                    color1,
                    menu1
                } = this;
                return {
                    backgroundColor: color1,
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                    borderRadius: menu1 ? '50%' : '4px',
                    transition: 'border-radius 200ms ease-in-out'
                };
            },
            swatchStyle2() {
                const {
                    color2,
                    menu2
                } = this;
                return {
                    backgroundColor: color2,
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                    borderRadius: menu2 ? '50%' : '4px',
                    transition: 'border-radius 200ms ease-in-out'
                };
            }
        },
        methods: {
            ...mapActions({
                fetchSchedulerParams: 'data/fetchSchedulerParams',
                saveProjectType: 'data/saveProjectType',
                addProjectType: 'data/addProjectType',
            }),
            ...mapGetters({
                getUser: 'authentication/getUser',
                getSchedulerParams: 'data/getSchedulerParams',
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
            }),
            expandPanel(item) {
                this.expanded = item === this.expanded[0] ? [] : [item];
                this.color1 = item.eventColor ? item.eventColor : '#FF00FFFF';
                this.menu1 = false;
                if (this.expanded.length > 0) {
                    this.entry = JSON.parse(JSON.stringify(item));
                } else {
                    this.entry = {};
                }
            },
            callSave(item) {
                this.saveProjectType(item).then(() => {
                    this.fetchSchedulerParams();
                });
            },
            callAdd(item) {
                this.addProjectType(item).then(() => {
                    this.fetchSchedulerParams();
                    this.cancel();
                });
            },
            cancel() {
                this.newPanel = [];
                this.newProjectType = {
                    name: '',
                    eventColor: '#FF00FF',
                    defaultItems: [],
                    active: 1
                };
            },
            showItems(items) {
                let itemList = '';
                if (items && items.length > 0) {
                    for (let i = 0, il = items.length; i < il; i++) {
                        const item = this.moneyItems.find((o) => o.id === items[i]);
                        if (item && item.name) {
                            itemList += `ID${item.id} ${item.name} (${item.type}), `;
                        }
                    }
                }
                return itemList.substr(0, itemList.length - 2);
            },
            formatDate(date) {
                if (date) {
                    return moment.utc(date).local().format('D.M.Y HH:mm');
                } 
                    return '';
            },
        }
    };
</script>

<style>
    .v-list--dense .v-list__tile:not(.v-list__tile--avatar) {
        height: 100%;
    }
</style>
<style scoped>
    .mypanel .v-expansion-panel__body {
        display: block !important;
        /* Dirty fix for expansion panel content */
    }

    .sectionheader {
        padding-bottom: 10px;
    }

    .shipheader {
        padding: 10px 0px;
    }

    .headertext {
        margin: 16px;
    }

    tr.expand td {
        padding: 0 !important;
    }

    tr.expand .expansion-panel {
        box-shadow: none;
    }

    tr.expand .expansion-panel li {
        border: none;
    }
</style>
