<template>
    <v-dialog :value="dialogType" width="500">
        <v-card>
            <v-card-title class="text-h5" :class="dialogType === 'confirm' || dialogType === 'quality check' ? 'warning' : dialogType" primary-title>
                {{ dialogType | capitalize }}
            </v-card-title>
            <v-card-text>
                <br />
                <!-- {{ dialogMessage }} -->
                <span v-html="dialogMessage"></span>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <span v-if="dialogType==='confirm'">
                    <v-btn color="error" text @click="alertClear();cancel()">Cancel
                    </v-btn>
                    <v-btn color="primary" text @click="confirm()">Confirm
                    </v-btn>
                </span>
                <span v-else-if="dialogType==='quality check'">
                    <v-btn color="error" text @click="confirm()">Confirm save with issues
                    </v-btn>
                    <v-btn color="primary" text @click="alertClear();cancel()">Cancel and correct
                    </v-btn>
                </span>
                <v-btn v-else text color="primary" @click="alertClear()">
                    OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {
        mapMutations
    } from 'vuex';
    import {
        eventBus
    } from '../main';

    export default {
        name: 'StatusDialog',
        computed: {
            dialogMessage() {
                return this.$store.state.alert.message;
            },
            dialogType() {
                return this.$store.state.alert.type;
            },
        },
        watch: {
            dialogType(newValue) {
                if (newValue === 'success') {
                    setTimeout(() => {
                        this.alertClear();
                    }, 1500);
                }
            }
        },
        methods: {
            ...mapMutations({
                alertSuccess: 'alert/success',
                alertClear: 'alert/clear',
                alertError: 'alert/error',
            }),
            confirm() {
                if (this.$store.state.alert.data) {
                    eventBus.$emit(this.$store.state.alert.emit, this.$store.state.alert.data);
                    console.log(`emitting: ${this.$store.state.alert.emit} with data `, this.$store.state.alert.data);
                } else {
                    eventBus.$emit(this.$store.state.alert.emit);
                    console.log(`emitting: ${this.$store.state.alert.emit}`);
                    setTimeout(() => {
                        // this.alertClear();
                        eventBus.$emit(this.$store.state.alert.emit);
                    }, 300);
                }
            },
            cancel() {
                eventBus.$emit('cancel');
            }
        }
    };
</script>

<style scoped>
    .v-dialog__content.v-dialog__content--active {
        z-index: 999999 !important;
    }

    .v-overlay.v-overlay--active {
        z-index: 999998 !important;
    }
</style>