<template>
    <div>     
        <div style="height: calc(100vh - 128px)">
            <ejs-grid ref="grid" id="grid" :dataSource="data" 
                :allowResizing="true"
                :toolbar="toolbar"
                :allowReordering="true"
                :allowExcelExport="true"
                :toolbarClick="toolbarClick" 
                :allowPaging="true"
                :allowSorting="true"
                :allowGrouping="true"
                :filterSettings="filterOptions"
                :sortSettings="sortOptions"
                :groupSettings="groupOptions" 
                :pageSettings="pageSettings"
                :dataStateChange="dataStateChange" 
                :dataSourceChanged="dataSourceChanged" 
                :actionComplete="actionHandler"
                :excelQueryCellInfo="excelQueryCellInfo"
                height="100%">
                <e-columns>
                    <e-column field="id" headerText="ID" :visible="false" :allowGrouping="false" :allowFiltering="false" width="70" :isPrimaryKey="true"></e-column>
                    <e-column field="name" headerText="Item" width="160"></e-column>
                    <e-column field="startDate" headerText="Earliest loading" format="d.M" type="date" :allowGrouping="false" width="120"></e-column>
                    <e-column field="yearmonth" headerText="Month" :filter="filter" width="90"></e-column>
                    <e-column field="year" headerText="Year" :filter="filter" width="80"></e-column>
                    <e-column field="endDate" headerText="Latest discharging" format="d.M" type="date" :allowGrouping="false" width="120"></e-column>
                    <e-column field="portIdLoad" headerText="POL" :valueAccessor="valueAccessor" :allowFiltering="false" width="120"></e-column>
                    <e-column field="portIdDisch" headerText="POD" :valueAccessor="valueAccessor" :allowFiltering="false" width="120"></e-column>
                    <e-column field="unit" headerText="Unit" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" width="70"></e-column>
                    <e-column field="quantityMin" headerText="Quantity min" :allowFiltering="false" :allowGrouping="false" textAlign="Right" width="100"></e-column>
                    <e-column field="quantityMax" headerText="Quantity max" :allowFiltering="false" :allowGrouping="false" textAlign="Right" width="100"></e-column>
                    <e-column field="impExpCab" headerText="Imp/Exp/Cab/Crosstrade" :valueAccessor="valueAccessor" :allowFiltering="false" width="140"></e-column>
                    <e-column field="notes" headerText="Notes" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" width="150"></e-column>
                    <e-column field="authorName" headerText="Author" :allowFiltering="false" :allowGrouping="false" :allowSorting="false" width="120"></e-column>
                </e-columns>
            </ejs-grid>
        </div>
       
        <!-- Components -->
        <ProgressCircular /> 
    </div>
</template>

<script>
/* eslint-disable */ 
    import {
        mapGetters,
        mapActions,
        mapMutations,
    } from 'vuex';
    import Vue from 'vue';
    import moment from 'moment';
    import { GridPlugin, Group, Page, Sort, Toolbar, ExcelExport, ColumnChooser, Reorder, Resize, Filter } from "@syncfusion/ej2-vue-grids";
    import { loadCldr, setCulture, createElement} from '@syncfusion/ej2-base';
    import { MultiSelect, CheckBoxSelection, DropDownList } from "@syncfusion/ej2-dropdowns";
    import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
    MultiSelect.Inject(CheckBoxSelection);
    
    import ProgressCircular from '../ProgressCircular.vue';
    import CaptionTemplate from "./CaptionTemplate.vue";
    import GridDataService from '../../services/CargoGridData.service'; 
    import { eventBus } from '../../main';
    
    loadCldr( 
        require('cldr-data/main/en-FI/ca-gregorian.json'), 
        require('cldr-data/main/en-FI/numbers.json'), 
        require('cldr-data/main/en-FI/timeZoneNames.json'), 
        require('cldr-data/supplemental/numberingSystems.json'),
        require('cldr-data/supplemental/weekData.json'),
    ); 
    
    Vue.use(GridPlugin);
    Vue.use(MultiSelectPlugin);

    export default {
        name: 'SchedulerTableCargo',
        props: {
            events: Array,
            resources: Array,
            params: Object,
        },
        components: {
            ProgressCircular
        },
        data() {
            let dropInstance = {};
            return {
                data: [],
                state: null,
                pageSettings: {pageSizes: [10,25,50,100,200,500,1000,'All'], pageSize: 1000},
                groupOptions: {captionTemplate:  function () {
                    return  { template : CaptionTemplate }
                }},
                sortOptions: {columns: [{ field: 'startDate', direction: 'Descending' }]},
                toolbar: [
                    { text: 'Refresh', tooltipText: 'Refresh table', prefixIcon: 'refresh', id: 'refresh' },
                    'Print', 'ExcelExport'],
                intParams: {params: {decimals: 3, format: 'n'}},
                decimal2Params: {params: {decimals: 2}},
                dataService: new GridDataService(),
                ddParams: { params: { dataSource: []} },
                // footerSum: function () {
                //     return  { template : SumAggregateNoText }
                // },
                filterOptions: {type: 'Menu'},
                filter: {
                    // type: 'Checkbox',
                    operator: 'equal',
                    ui: {
                        create: function (args) {
                            let flValInput = createElement('input', { className: 'flm-input' });
                            args.target.appendChild(flValInput);
                            let field = args.column.field;
                            if (field === 'yearmonth') {
                                let fields = {text: 'name', value: 'value'};
                                let dataService = new GridDataService();
                                dataService.getParam('scheduler/projectparams/cargo/yearmonth').then((data) => {
                                    dropInstance = new MultiSelect({
                                        dataSource: data,
                                        fields: fields,
                                        placeholder: 'Select month/year',
                                        popupHeight: '200px',
                                        allowFiltering: true,
                                        mode: 'CheckBox',
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'year') {
                                let fields = {text: 'name', value: 'value'};
                                let dataService = new GridDataService();
                                dataService.getParam('scheduler/projectparams/cargo/year').then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields: fields,
                                        placeholder: 'Select ETD year',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } 
                        },
                        read: function (args) {
                            var grid = document.getElementById("grid").ej2_instances[0];
                            grid.removeFilteredColsByField(args.column.field);
                            args.fltrObj.filterByColumn(
                                args.column.field,
                                "contains",
                                dropInstance.value
                            );
                        }
                    }
                }
            }
        },
        mounted() {
            setCulture('en-FI');
            let state = { skip: 0, take: 1000 };
            this.dataStateChange(state);
            eventBus.$on('reloadDataTable', () => {
                // this.dataStateChange(this.state);
                this.$refs.grid.refresh();
            });
        },
        computed: {
            ...mapGetters({
                user: 'authentication/getUser',
                features: 'authentication/features',
            }),
        },
        beforeDestroy() {
            eventBus.$off('reloadDataTable');
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getDynamic: 'data/getDynamic',
            }),
            ...mapActions({
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
            }),
            
            dataStateChange(state) {
                if (state && state.action && state.action.requestType === 'filterchoicerequest') {
                    // state.action.filterModel.values['Not completed ', 'Auto OK'];
                } else { // Get data from backend
                    this.dataService.get(state).then((gridData) => this.data = gridData);
                }
                this.state = state;
            },
            dataSourceChanged(state) {
                if (state.action === 'add' && state.requestType === 'save') {
                   
                } else if (state.action === 'edit' && state.requestType === 'save') {
                   
                } else if (state.requestType === 'delete') {
                   
                }
            },
            actionHandler(args) {
                if (args.requestType === 'grouping') {
                    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['collapse','expand'],true);
                    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['refresh'],false);
                } else if (args.requestType === 'ungrouping') {
                    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['refresh'],true);
                    this.$refs.grid.ej2Instances.toolbarModule.enableItems(['collapse','expand'],false);
                }
            },
            async toolbarClick(args) {
                if (args.item.id.includes('excelexport')) {
                    // let records = this.$refs.grid.dataSource.result;
                    // let length = records.length;
                    // console.log(records);
                    // for (let i = 0; i < length; i++) {
                    //     records[i].startDate = records[i].startDate ? moment(records[i].startDate).format('D.M') : '';
                    // }
                    let exportProperties = {
                        // dataSource: records,
                        fileName: "desk_report.xlsx"
                    };
                    this.$refs.grid.excelExport(exportProperties);
                } else if (args.item.id === 'refresh') {
                    this.dataStateChange(this.state);
                    this.$refs.grid.refresh();
                } else if (args.item.id === 'expand') {
                    this.$refs.grid.ej2Instances.groupModule.expandAll();
                } else if (args.item.id === 'collapse') {
                    this.$refs.grid.ej2Instances.groupModule.collapseAll();
                } else if (args.item.id === 'edit') {
                    let selectedRecords = this.$refs.grid.getSelectedRecords();  
                    if (selectedRecords && selectedRecords[0] && selectedRecords[0].id) {
                        let resource = null;
                        if (this.resources && this.resources.length > 0) {
                            resource = this.resources.find((o) => {
                                return o.id === selectedRecords[0].resourceId;
                            })
                            if (!resource) {
                                console.log('Resource not found. Fetching from server.')
                                resource = await this.fetchResource(selectedRecords[0].resourceId)
                                if (!resource) {
                                    this.$store.dispatch('alert/error', 'Resource is not active and not found on database.', {root: true});  
                                    return;  
                                } else {
                                    console.log('Resource record found', resource);
                                }
                            }
                            let event = selectedRecords[0];
                            event.data = selectedRecords[0];
                            // console.log('tableEdit', event);
                            eventBus.$emit('tableEdit', ({
                                event: event,
                                events: this.events,
                                resource: resource,
                            }));
                        } else {
                            this.$store.dispatch('alert/error', 'Resources are not available.', {root: true});    
                        }
                    } else {
                        this.$store.dispatch('alert/error', 'Select project/voyage first.', {root: true});
                    }
                }
            },
            isVisible(param) {
                if (typeof this.features.schedulerTable === 'object' && this.features.schedulerTable !== null && 
                    typeof this.features.schedulerTable[param] !== 'undefined' && this.features.schedulerTable[param] === 1) {
                    return true;
                } else {
                    return false;
                }
            },
            excelQueryCellInfo(args) { 
                if (args.column.field === 'startDate' ||args.column.field === 'endDate') { 
                    args.value = moment(args.value).isValid() ? moment(args.value).toDate() : args.value;
                } else if (args.column.field === 'yearmonth') { 
                    args.value = args.value ? parseInt(args.value.split('/')[0]) : '';
                } else if (args.column.field === 'year') { 
                    args.value = args.value ? parseInt(args.value) : '';
                }
            },
            valueAccessor(field, data) {
                if (field === 'impExpCab') {
                    if (data.impExpCab === 0) {
                        return 'Import';
                    } else if (data.impExpCab === 1) {
                        return 'Export';
                    } else if (data.impExpCab === 2) {
                        return 'Cabotage';
                    } else if (data.impExpCab === 3) {
                        return 'Crosstrade';
                    }
                } else if (field === 'portIdLoad') {
                    return data.pol;
                } else if (field === 'portIdDisch') {
                    return data.pod;
                }
            },
        },
        provide: {
            grid: [Group, Page, Sort, Toolbar, ExcelExport, Reorder, Resize, Filter ]
        }
    }

</script>

<style>
    /* Hide filter operator dropdown */
    .e-flm_optrdiv {
        display: none;
    }
    .e-grid .e-flmenu-valuediv {
        padding: 4px 0 0;
    }
    .refresh::before {
      content: '\e606';
    }
    .edit::before {
      content: '\e7a3';
    }
    .expand::before {
      content: '\e556';
    }
    .collapse::before {
      content: '\e554';
    }
</style>