<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card class="bg2">
      <v-card-actions>
        <v-card-title>Excel export</v-card-title>
        <v-spacer></v-spacer>
        <v-btn text color="warn" @click="cancel()">
          Close
        </v-btn>
        <v-btn @click="save()" :disabled="!template" text class="white--text" color="primary" depressed> Export </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <v-container class="mt-6" fluid grid-list-lg>
            <v-layout wrap align-center>
              <v-flex d-flex class="xs12">
                <v-autocomplete v-model="template" :items="templates" :item-text="templateText" item-key="id" return-object clearable required
                  outlined text persistent-placeholder hide-no-data label="Select record">
                </v-autocomplete>
              </v-flex>
              <v-flex d-flex class="xs6">
                <v-menu ref="sdmenu" v-model="startDateMenu" :close-on-content-click="true" :return-value.sync="startDate" required
                        transition="scale-transition" offset-y>
                  <template #activator="{ on }">
                    <v-text-field outlined v-model="startDate" persistent-placeholder label="Start date" :clearable="true">
                      <template #prepend-inner>
                        <v-icon v-on="on" color="primary">{{ mdiCalendar }}</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker @change="$refs.sdmenu.save(startDate);" first-day-of-week="1" :show-week="true"
                                v-model="startDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.sdmenu.save(startDate)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex d-flex class="xs6">
                <v-menu ref="edmenu" v-model="endDateMenu" :close-on-content-click="true" :return-value.sync="endDate" required
                      transition="scale-transition" offset-y>
                <template #activator="{ on }">
                  <v-text-field outlined v-model="endDate" persistent-placeholder label="End date" :clearable="true">
                    <template #prepend-inner>
                      <v-icon v-on="on" color="primary">{{ mdiCalendar }}</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker @change="$refs.edmenu.save(endDate);" first-day-of-week="1" :show-week="true"
                              v-model="endDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="endDateMenu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.edmenu.save(endDate)">OK</v-btn>
                </v-date-picker>
              </v-menu>
              </v-flex>
            </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  const moment = require('moment');
  import writeXlsxFile from 'write-excel-file';
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import {
    mdiMagnify,
    mdiCheck,
    mdiClose,
    mdiCalendar,
  } from '@mdi/js';
  
  export default {
    name: 'ExcelExport',
    components: {
      
    },
    props: {
      value: [Boolean, Object],
      ship: [Object],
    },
    data: () => ({
      dialog: false,
      startDate: moment.utc().subtract(2, 'w').format('YYYY-MM-DD'),
      endDate: moment.utc().format('YYYY-MM-DD'),
      startDateMenu: false,
      endDateMenu: false,
      mdiMagnify,
      mdiCheck,
      mdiClose,
      mdiCalendar,
      valid: false,
      template: null,
    }),
    created() {
      
    },
    mounted() {
      this.dialog = this.value;
    },
    beforeDestroy() {

    },
    watch: {
     
    },
    computed: {
      ...mapGetters({
        templates: 'logbook/getTemplates',
      }),
    },
    methods: {
      ...mapGetters({
      }),
      ...mapActions({
        fetchExcelRecords: 'logbook/fetchExcelRecords',
      }),
      ...mapMutations({
        alertError: 'alert/error',
        alertWarning: 'alert/warning',
        alertSuccess: 'alert/success',
      }),
      cancel() {
        this.$emit('input', false);
      },
      async save() {
        if (!this.template.id) {
          this.alertError('Select valid template');
        }
        if (!this.startDate || !moment(this.startDate).isValid()) {
          this.alertError('Start date is not valid');
        }
        if (!this.endDate || !moment(this.endDate).isValid()) {
          this.alertError('End date is not valid');
        }
        this.fetchExcelRecords({logDb: this.ship.logDb, id: this.template.id, start: this.startDate, end: this.endDate}).then(data => {
          const rows = this.formatData(data);
          this.exportToExcel(rows);
        })
      },
      formatData(data) {
        const headerRow = [{ value: 'Event time', fontWeight: 'bold'}, {value: 'Template name', fontWeight: 'bold'}, {value: 'Template ID', fontWeight: 'bold'}];
        const ids = [];
        if (data && data[0] && data[0].content) {
          let c;
          try {
            c = typeof data[0].content === 'string' ? JSON.parse(data[0].content) : data[0].content;
          } catch (e) {
            console.warn(e);
          }
          if (c && c.fields && c.fields.length > 0) {
            for (let i = 0, il = c.fields.length; i < il; i++) {
              if (!c.fields[i].hidden && c.fields[i].type !== 'newline') {
                headerRow.push({ value: c.fields[i].label, fontWeight:'bold' });
                if (c.fields[i].id) {
                  ids.push(c.fields[i].id);
                }
              }
            }
          }
        }
        const rows = [headerRow];
        const length = ids.length;
        for (const entry of data) {
          const columns = [
            {type: Date, value: moment.utc(entry.eventTime).toDate(), format: 'dd.mm.yyyy hh:mm'},    
            {type: String, value: entry.name},
            {type: Number, value: parseInt(entry.entryId)},
          ];
          let c;
          try {
            c = typeof entry.content === 'string' ? JSON.parse(entry.content) : entry.content;
          } catch (e) {
            console.warn(e);
          }
          if (c && c.fields && c.fields.length > 0) {
            for (let i = 0; i < length; i++) {
              // Find value based on field id
              const field = c.fields.find(o => o.id === ids[i]);
              if (field) {
                let value = field.value;
                if (value && typeof value === 'object') {
                  if (field.type === 'autocompletePort') {
                    value = value.name + ' - ' + value.ddtLocode;
                  } else if (typeof value.value !== 'undefined') {
                    value = value.value;
                  } else if (typeof value.name !== 'undefined') {
                    value = value.name;
                  }
                }
                let t = String;
                if (value === null || value === '') {
                  value = '';
                } else if (typeof value === 'number') {
                  t = Number;
                } else if (value.startsWith('2') && value.includes('-') && moment(value).isValid()) {
                  t = String;
                } else if (typeof value === 'string' && this.isNumeric(value) && value !== null && value !== '') {
                  t = Number;
                  value = parseFloat(value);
                }
                columns.push({ type: t, value: value});
              } else {
                columns.push({ type: String, value: ''});
              }
            }
          }
          rows.push(columns);     
        }
        return rows;
      },
      async exportToExcel(rows) {
        const columns = [{width: 16},{width: 18},{width: 12},{width: 12},{width: 10},{width: 16},{width: 16}];
        const length = rows[0].length - 7;
        for (let i = 0; i < length; i++) {
          columns.push({width: 16});
        }
        await writeXlsxFile(rows, {
          columns: columns,
          fileName: `${moment.utc(this.startDate).format('YYMMDD')}-${moment.utc(this.endDate).format('YYMMDD')}_${this.template.name}.xlsx`,
          stickyRowsCount: 1,
        });
      },
      templateText(item) {
        return `${item.id} — ${item.name}`;
      },
      isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }
    }
  }
</script>

<style>

</style>
<style scoped>

</style>