<template>
    <div>
        <v-card class="cardbglight">
            <v-data-table :headers="headers" :items="moneyItems" :search="search" :single-expand="true" :expanded.sync="expanded" item-key="id"
                :sort-by="sortBy" :sort-desc="sortDesc" :footer-props="{ showFirstLastPage: true}" mobile-breakpoint="100" class="elevation-1">
                <template #top>
                    <v-toolbar>
                        <v-toolbar-title>Money items </v-toolbar-title>
                        <v-spacer />
                        <v-spacer />
                        <v-spacer />
                        <v-layout wrap>
                            <v-flex mt-8 xs6>
                                <v-switch v-model="active" :false-value="0" :true-value="1" :label="active === 1 ? 'Active' : 'Inactive'" color="primary"
                                    hint="Status" persistent-hint />
                            </v-flex>
                            <v-flex mt-6 xs6>
                                <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details />
                            </v-flex>
                        </v-layout>
                    </v-toolbar>
                </template>
                <template #item="{item}">
                    <tr @click="expandPanel(item)">
                        <td width="70">{{ item.id }}</td>
                        <td width="95">
                            <v-icon color="green" v-show="item.income === 1" right>monetization_on</v-icon>
                            <v-icon color="red" v-show="item.income === 0" right>monetization_on</v-icon>
                        </td>
                        <td>{{ item.type }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.portName }}</td>
                        <td>{{ item.portNameLoad }}</td>
                        <td>{{ item.portNameDisch }}</td>
                        <td>{{ item.rate }}</td>
                        <td>{{ item.unit }}</td>
                        <td>{{ item.shipName }}</td>
                        <td>{{ item.notes }}</td>
                        <td>{{ item.author }}</td>
                        <td>{{ formatDate(item.updated) }}</td>
                        <td width="95">
                            <v-chip small class="ml-1" :color="item.eventColor"></v-chip>
                        </td>
                    </tr>
                </template>
                <template #expanded-item="{ headers }">
                    <td :colspan="headers.length" class="pa-0">
                        <v-card class="cardbg primarytext--text">
                            <v-form>
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="entry.type" :items="types" prepend-icon="flag" label="Type"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-model="entry.name" prepend-icon="wysiwyg" label="Name"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-if="entry.portName" readonly v-model="entry.portName" prepend-icon="anchor" label="Port" clearable
                                                @click:clear="clearAutocomplete('port', entry)"></v-text-field>
                                            <v-autocomplete v-else v-model="entry.port" class="mb-4" :loading="portLoading" @change="updatePort('port', entry)"
                                                :items="ports" :item-text="portItemText" item-key="id" :clearable="true" :search-input.sync="portSearch"
                                                cache-items return-object prepend-icon="anchor" text hide-no-data hide-details label="Port">
                                            </v-autocomplete>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="entry.shipId" :items="ships" item-text="name" item-value="id" prepend-icon="directions_boat"
                                                label="Ship"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-if="entry.portNameLoad" readonly v-model="entry.portNameLoad" prepend-icon="anchor" label="POL"
                                                clearable @click:clear="clearAutocomplete('load', entry)"></v-text-field>
                                            <v-autocomplete v-else v-model="entry.portLoad" class="mb-4" :loading="portLoadingLoad"
                                                @change="updatePort('load', entry)" :items="ports" :item-text="portItemText" item-key="id" :clearable="true"
                                                :search-input.sync="portSearchLoad" cache-items return-object prepend-icon="anchor" text hide-no-data
                                                hide-details label="POL">
                                            </v-autocomplete>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-if="entry.portNameDisch" readonly v-model="entry.portNameDisch" prepend-icon="anchor" label="POD"
                                                clearable @click:clear="clearAutocomplete('disch', entry)"></v-text-field>
                                            <v-autocomplete v-else v-model="entry.portDisch" class="mb-4" :loading="portLoadingDisch"
                                                @change="updatePort('disch', entry)" :items="ports" :item-text="portItemText" item-key="id" :clearable="true"
                                                :search-input.sync="portSearchDisch" cache-items return-object prepend-icon="anchor" text hide-no-data
                                                hide-details label="POD">
                                            </v-autocomplete>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field type="number" v-model="entry.rate" prepend-icon="money" label="Rate"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field type="number" v-model="entry.rateBaf" prepend-icon="money" label="BAF rate"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="entry.unit" :items="units" prepend-icon="mdi-percent" label="Unit"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="entry.currency" :items="currencies" prepend-icon="mdi-currency-eur" label="Currency"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-model="entry.terms" prepend-icon="mdi-note" label="Terms"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="entry.income" :items="[{text: 'Income', value: 1}, {text: 'Cost', value: 0}]"
                                                prepend-icon="mdi-currency-usd" label="Income"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-model="entry.sf" prepend-icon="mdi-percent" label="SF"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-model="entry.tonM2" prepend-icon="mdi-cube-outline" label="Ton/m2"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="entry.impExpCab" :items="impExpCabs" prepend-icon="compare_arrows" label="Imp/Exp/Cab/Crosstrade">
                                            </v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-if="entry.contactName" readonly v-model="entry.contactName" prepend-icon="mdi-account" label="Contact"
                                                clearable @click:clear="clearAutocomplete('contact', entry)"></v-text-field>
                                            <v-autocomplete v-else :disabled="entry.shipContactRule === 1" v-model="entry.contact" :loading="contactLoading" @change="updateContact(entry)"
                                                :items="contacts" :item-text="contactItemText" item-key="id" :clearable="true"
                                                :search-input.sync="contactSearch" cache-items return-object prepend-icon="mdi-account" text hide-no-data
                                                hide-details label="Contact">
                                            </v-autocomplete>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="entry.account" :items="accounts" item-text="text" item-value="value" prepend-icon="mdi-account-box-outline"
                                                label="Account" />
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="entry.calcRule" :items="calcRules" prepend-icon="settings" label="Calculation rule"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-model="entry.quantityRule" prepend-icon="settings" label="Quantity rule"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="entry.projectTypeId" :items="projectTypes" item-text="name" item-value="id" prepend-icon="wysiwyg"
                                                label="Project type" />
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="entry.brand" :items="brands" prepend-icon="mdi-crane" label="Brand" />
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-model="entry.eventColor" hide-details prepend-icon="color_lens" label="Color">
                                                <template #append>
                                                    <v-menu v-model="menu1" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                                        <template #activator="{ on }">
                                                            <div :style="swatchStyle1" v-on="on" />
                                                        </template>
                                                        <v-card>
                                                            <v-card-text class="pa-0">
                                                                <v-color-picker v-model="color1" @input="entry.eventColor = color1" flat />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>
                                                </template>
                                            </v-text-field>
                                        </v-flex>
                                        <v-flex px-4 sm12 md8 lg6 xl4>
                                            <v-text-field v-model="entry.notes" prepend-icon="notes" label="Notes"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-checkbox v-model="entry.shipContactRule" :false-value="0" :true-value="1"
                                                label="Ship contact rule"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-switch style="margin-top: 18px;" v-model="entry.active" :false-value="0" :true-value="1"
                                            :label="entry.active === 1 ? 'Active' : 'Inactive'" color="primary" hint="Status"
                                            persistent-hint />

                                        <v-spacer />
                                        <v-btn class="mt-5 ml-8" color="primary" @click="callSave(entry)" :disabled="userLevel < 1">
Save
                                            <v-icon right dark>check</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-container>
                            </v-form>
                        </v-card>
                    </td>
                </template>
                <v-alert slot="no-results" :value="true" color="error" icon="warning">
                    Your search for "{{ search }}" found no results.
                </v-alert>
            </v-data-table>
            <v-expansion-panels :disabled="userLevel < 1" v-model="newPanel">
                <v-expansion-panel :disabled="userLevel < 1">
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="teal">add_circle</v-icon> <span class="text-body-1 ml-2">Add new money item</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <v-card class="cardbg">
                            <v-form>
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="newEntry.type" :items="types" prepend-icon="flag" label="Type"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-model="newEntry.name" prepend-icon="wysiwyg" label="Name"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-if="newEntry.portName" readonly v-model="newEntry.portName" prepend-icon="anchor" label="Port"
                                                clearable @click:clear="clearAutocomplete('port', newEntry)"></v-text-field>
                                            <v-autocomplete v-else v-model="newEntry.port" class="mb-4" :loading="portLoading"
                                                @change="updatePort('port', newEntry)" :items="ports" :item-text="portItemText" item-key="id" :clearable="true"
                                                :search-input.sync="portSearch" cache-items return-object prepend-icon="anchor" text hide-no-data hide-details
                                                label="Port">
                                            </v-autocomplete>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="newEntry.shipId" :items="ships" item-text="name" item-value="id" prepend-icon="directions_boat"
                                                label="Ship"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-if="newEntry.portNameLoad" readonly v-model="newEntry.portNameLoad" prepend-icon="anchor"
                                                label="POL" clearable @click:clear="clearAutocomplete('load', newEntry)"></v-text-field>
                                            <v-autocomplete v-else v-model="newEntry.portLoad" class="mb-4" :loading="portLoadingLoad"
                                                @change="updatePort('load', newEntry)" :items="ports" :item-text="portItemText" item-key="id" :clearable="true"
                                                :search-input.sync="portSearchLoad" cache-items return-object prepend-icon="anchor" text hide-no-data
                                                hide-details label="POL">
                                            </v-autocomplete>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-if="newEntry.portNameDisch" readonly v-model="newEntry.portNameDisch" prepend-icon="anchor"
                                                label="POD" clearable @click:clear="clearAutocomplete('disch', newEntry)"></v-text-field>
                                            <v-autocomplete v-else v-model="newEntry.portDisch" class="mb-4" :loading="portLoadingDisch"
                                                @change="updatePort('disch', newEntry)" :items="ports" :item-text="portItemText" item-key="id" :clearable="true"
                                                :search-input.sync="portSearchDisch" cache-items return-object prepend-icon="anchor" text hide-no-data
                                                hide-details label="POD">
                                            </v-autocomplete>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field type="number" v-model="newEntry.rate" prepend-icon="money" label="Rate"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field type="number" v-model="newEntry.rateBaf" prepend-icon="money" label="BAF rate"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="newEntry.unit" :items="units" prepend-icon="mdi-percent" label="Unit"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="newEntry.currency" :items="currencies" prepend-icon="mdi-currency-eur" label="Currency">
                                            </v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-model="newEntry.terms" prepend-icon="mdi-note" label="Terms"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="newEntry.income" :items="[{text: 'Income', value: 1}, {text: 'Cost', value: 0}]"
                                                prepend-icon="mdi-currency-usd" label="Income"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-model="newEntry.sf" prepend-icon="mdi-percent" label="SF"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-model="newEntry.tonM2" prepend-icon="mdi-cube-outline" label="Ton/m2"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="newEntry.impExpCab" :items="impExpCabs" prepend-icon="compare_arrows"
                                                label="Imp/Exp/Cab/Crosstrade"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-if="newEntry.contactName" readonly v-model="newEntry.contactName" prepend-icon="mdi-account"
                                                label="Contact" clearable @click:clear="clearAutocomplete('contact', newEntry)"></v-text-field>
                                            <v-autocomplete v-else :disabled="newEntry.shipContactRule === 1" v-model="newEntry.contact" :loading="contactLoading" @change="updateContact(newEntry)"
                                                :items="contacts" :item-text="contactItemText" item-key="id" :clearable="true"
                                                :search-input.sync="contactSearch" cache-items return-object prepend-icon="mdi-account" text hide-no-data
                                                hide-details label="Contact">
                                            </v-autocomplete>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="newEntry.account" :items="accounts" item-text="text" item-value="value" prepend-icon="mdi-account-box-outline"
                                                label="Account" />
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="newEntry.calcRule" :items="calcRules" prepend-icon="settings" label="Calculation rule" />
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-model="newEntry.quantityRule" prepend-icon="settings" label="Quantity rule"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="newEntry.projectTypeId" :items="projectTypes" item-text="name" item-value="id"
                                                prepend-icon="wysiwyg" label="Project type" />
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-select v-model="newEntry.brand" prepend-icon="mdi-crane" :items="brands" label="Brand" />
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4 lg3 xl2>
                                            <v-text-field v-model="newEntry.eventColor" hide-details prepend-icon="color_lens" label="Color">
                                                <template #append>
                                                    <v-menu v-model="menu1" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                                        <template #activator="{ on }">
                                                            <div :style="swatchStyle1" v-on="on" />
                                                        </template>
                                                        <v-card>
                                                            <v-card-text class="pa-0">
                                                                <v-color-picker v-model="color1" @input="newEntry.eventColor = color1" flat />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>
                                                </template>
                                            </v-text-field>
                                        </v-flex>
                                        <v-flex px-4 sm12 md8 lg6 xl4>
                                            <v-text-field v-model="newEntry.notes" prepend-icon="notes" label="Notes"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-checkbox v-model="newEntry.shipContactRule" :false-value="0" :true-value="1"
                                                label="Ship contact rule"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-switch style="margin-top: 18px;" v-model="newEntry.active" :false-value="0" :true-value="1"
                                            :label="newEntry.active === 1 ? 'Active' : 'Inactive'" color="primary" hint="Status" persistent-hint />

                                        <v-spacer />
                                        <v-btn color="primary" class="mt-5 ml-8" @click="callAdd(newEntry)" :disabled="!newEntry.name || !newEntry.type">
                                            Add
                                            <v-icon right dark>add_circle</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-container>
                            </v-form>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="primary">help</v-icon>
                            <span class="text-body-1 ml-2">What does this page show</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <br />

                        <h3>This page lists your income and cost items</h3>

                        <p>
Each item can have a number of different parameters, which then control and affect how the items are used in a project or on a
                            voyage. The parameters also affect how various cost and income items behave in the system reporting.
</p>
<h4>Type and quantity rule parameter</h4>
<p>
The type and quantity rule parameters are used by the Match quantities function. If you have a Freight-type item with POL=Helsinki AND POD=Hamburg AND a Quantity rule called for example "General cargo tons" and you also have a Loading-type cost item with Port=Helsinki AND the same Quantity rule "General cargo tons", then the Match quantities function will copy the Qty and Qty final information from the Freight-row(s) to that Loading cost. 
</p>
<p>
The Quantity rule is a free text field and can therefore be freely defined and will work in cases where the exact same text is used in both freight and cost items. In case the Freight item unit is not ton and the cost item unit is ton, then the quantity matching will be done based on the Ton final value for for the freight item. This is useful if you want to match for example m3 freight quantities with ton-based Discharge costs.
</p>
<p>
The Quantity rule works for following money item types:
</p>
<p>
<ul>
    <li>Loading</li>
    <li>Discharging</li>
    <li>Discharging - other</li>
    <li>Freight cost</li>
    <li>Port cost</li>
    <li>Tally</li>
</ul>
</p>
                        <h4>Port parameter</h4>
                        <p>
The Port parameter is used together with the Port cost, Loading cost and Discharge cost calculation rules to match a (cost) item with
                            a specific port. If you for example define an item with Port=Helsinki AND calculation rule Port cost AND add Helsinki to a project,
                            then the item will be added to the project. The Brand-definiton can be used to further restrict this matching to specific ship
                            types.
</p>

                        <h4>Ship parameter</h4>
                        <p>The Ship parameter is used together with the Ship calculation rule to match an item with a specific vessel.</p>

                        <h4>POL and POD parameters</h4>
                        <p>
The POL (Port Of Loading) and POD (Port Of Discharging) parameters are used together with the Freight calculation rule to match an
                            item with a specific pair of ports. See below for further details.
</p>

                        <h4>BAF rate parameter</h4>
                        <p>
BAF rate parameter is used together with the BAF % definition and Final quantity in a project. If the BAF rate is defined as for
                            example 10 and you then add a BAF % of 50% and a Final quantity of 1000 to an income item, the system will add 10 x 50% x 1000 =
                            5000 to the Final sum. The BAF amount can be viewed in a project money table by enabling the hidden BAF-column.
</p>

                        <h4>Unit parameter</h4>
                        <p>Unit parameter affects how the rate is defined and how the Final tons are matched.</p>
                        <p>
                            <ul>
                                <li>
If the Unit=ton, then the Final tons will automatically be set equal to the Final quantity. For other units this Final
                                    quantity=Final tons is not applied and Final tons need to be added manually.
</li>
                                <li>If the Unit=%, then the Rate can be defined directly as a percentage.</li>
                            </ul>
                        </p>

                        <h4>Currency parameter</h4>
                        <p>
The currency parameter defines the default currency for an item. If the currency is different from the base currency in your profile,
                            then it will trigger an automatic currency conversion calculation when added to a project. The currency conversion data is fetched
                            automatically and is based on a general mid-point exhange rate for the time when the item is added to a project.
</p>

                        <h4>Imp/Exp/Cabotage/Crosstrade parameter</h4>
                        <p>
This parameter can be used for separating Freight tons (or income/cost items) and is best visible in the Table view, which features
                            automatic aggregation of Imp/Exp/Cabotage/Crosstrade tons.
</p>

                        <h4>Brand parameter</h4>
                        <p>
The brand parameter can be used to further restrict adding in a specific money item to a project, based on the selected vessel's
                            corresponding Brand-definiton. If you have a cost item with a "LoLo" brand-setting, then it will only be added to a project if the
                            performing vessel has the same "LoLo" brand definition.
</p>

                        <h4>Project type parameter</h4>
                        <p>The project type parameter can be used to further limit cost matching to only the selected project type.</p>

                        <h3>Calculation rule parameter</h3>
                        <p>The calculation rule defines how an item is used when creating and calculating projects.</p>

                        <h4>Calculation rule Basic</h4>
                        <p>
This rule is used for general items and calculation rule is sum = rate * quantity * income/cost sign. All rules are based on this
                            equation with smallish variations.
</p>

                        <h4>Calculation rule Daily</h4>
                        <p>
Items which are defined as "Daily" will have a quantity equal to the amount of days of a project and the default Rate is equal to the
                            rate from the ship's previous Confirmed voyage. The Daily-type is usually used for T/C cost or daily fixed costs, but it can also be
                            used for Income items.
</p>

                        <h4>Calculation rule DA</h4>
                        <p>The DA calculation rule is used for recurring DA-costs in ports. This rule has a few functions as follows:</p>
                        <p>
                            <ul>
                                <li>A DA-item will be added to all ports of a project/voyage</li>
                                <li>
If you have defined a DA-item for a specific port with specific settings for currency and Contact, then that item will be
                                    added
                                    for that port.
</li>
                                <li>The rate will be calculated based on the 10 last port calls in that port for that ship</li>
                                <li>The quantity will be set as 1</li>
                            </ul>
                        </p>

                        <h4>Calculation rule Bunker</h4>
                        <p>
                        Bunker-type items will have a quantity equal to the estimated bunker consumption. The consumption is calculated based on the travelled voyage distance and port time and the ship's consumption figures, as defined in Settings -> Ships. The default rate is equal to the rate from the ship's previous Confirmed voyage. Final rate is backwards updated based on Final sum and Final quantity, when the item is saved as Confirmed.
</p>

                        <h4>Calculation rule Bunker (steaming)</h4>
                        <p>
                            Bunker (steaming)-type items will have a quantity equal to the estimated bunker consumption when the ship is at sea. The consumption is calculated based on the travelled voyage distance in ballast and normal load and multiplied by the fuel consumption per nautical mile for ballast and normal, as defined in Settings -> Ships. The default rate is equal to the rate from the ship's previous Confirmed voyage. In this rule the Final quantity is NOT set automatically to the estimated quantity and needs to be added in manually. Final rate is backwards updated based on Final sum and Final quantity, when the item is saved as Confirmed.
</p>

                        <h4>Calculation rule Bunker (port)</h4>
                        <p>
                        Bunker (port)-type items will have a quantity equal to the estimated bunker consumption when the ship is in port. The port consumption is calculated by multiplying the totat port time with the port consumption, as defined in Settings -> Ships. The default rate is equal to the rate from the ship's previous Confirmed voyage. In this rule the Final quantity is NOT set automatically to the estimated quantity and needs to be added in manually. Final rate is backwards updated based on Final sum and Final quantity, when the item is saved as Confirmed.
</p>

                        <h4>Calculation rule Commission</h4>
                        <p>Commission items will get a quantity equaly to the total amount of all Income and the Rate is handled as a percentage.</p>

                        <h4>Calculation rule Port cost</h4>
                        <p>
Items defined as Port costs will be added to the project when you add the defined port to a project. The Brand-selection can be used
                            to further limit the cost matching to only the selected ship type/brand. The project type parameter can be used to further limit
                            cost matching to only the selected project type.
</p>

                        <h4>Calculation rule Loading cost</h4>
                        <p>
                            Items defined as Loading costs will be added to the project when you add the defined port to a project and define its type as Loading or Load/Disch. The Brand-selection can be used to further limit the cost matching to only the selected ship type/brand. The project type parameter can be used to further limit cost matching to only the selected project type. Final rate is copied from the budget rate, in case the final rate is 0 or missing or emptied, but otherwise it remains as the User has defined it.
                        </p>

                        <h4>Calculation rule Discharge cost</h4>
                        <p>
                            Items defined as Discharge costs will be added to the project when you add the defined port to a project and define its type as Discharging or Load/Disch. The Brand-selection can be used to further limit the cost matching to only the selected ship type/brand. The project type parameter can be used to further limit cost matching to only the selected project type. Final rate is copied from the budget rate, in case the final rate is 0 or missing or emptied, but otherwise it remains as the User has defined it.
                        </p>

                        <h4>Calculation rule Freight</h4>
                        <p>
Items defined as Freight will be added to the project in case the POL (Port Of Loading) and POD (Port Of Discharge) match the port
                            definitions in the project. In other words, if you have a Freight type money item defined with POL = Hamburg and POD = Helsinki and
                            you add in Hamburg as Loading or Load/Disch and Helsinki as Discharging, Load/Disch or Ballast port, then the item will be added to
                            the project. The Brand-selection can be used to further limit the cargo matching to only the selected ship type/brand.
</p>

                        <h4>Calculation rule Ship</h4>
                        <p>Items defined with calculation rule Ship will be added to the project, if they match the Ship-selection.</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <StatusDialog />
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    import moment from 'moment';
    import StatusDialog from '../components/StatusDialog.vue';

    export default {
        name: 'MoneyItems',
        components: {
            StatusDialog
        },
        data: () => ({
            search: '',
            sortBy: 'name',
            sortDesc: false,
            selected: [],
            active: 1,
            portSearch: null,
            portSearchLoad: null,
            portSearchDisch: null,
            contactSearch: null,
            ports: [],
            contacts: [],
            portLoading: false,
            portLoadingLoad: false,
            portLoadingDisch: false,
            contactLoading: false,
            actives: [{
                    text: 'Inactive',
                    value: 0
                },
                {
                    text: 'Active',
                    value: 1
                },
            ],
            headers: [{
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Income',
                    value: 'income'
                },
                {
                    text: 'Type',
                    value: 'type'
                },
                {
                    text: 'Name',
                    value: 'name'
                },
                {
                    text: 'Port',
                    value: 'portName'
                },
                {
                    text: 'POL',
                    value: 'portNameLoad'
                },
                {
                    text: 'POD',
                    value: 'portNameDisch'
                },
                {
                    text: 'Rate',
                    value: 'rate'
                },
                {
                    text: 'Unit',
                    value: 'unit'
                },
                {
                    text: 'Ship',
                    value: 'shipName'
                },
                {
                    text: 'Notes',
                    value: 'notes'
                },
                {
                    text: 'Author',
                    value: 'author'
                },
                {
                    text: 'Updated',
                    value: 'updated'
                },
                {
                    text: 'Color',
                    value: 'eventColor'
                },

            ],
            types: ['General', 'Fixed', 'T/C', 'Bunker', 'Port cost', 'Fairway', 'Freight', 'Freight cost', 'Commission', 'Loading', 'Loading - other',
                'Discharging', 'Discharging - other', 'Overtime', 'Waiting time', 'Tally'
            ],
            units: ['', 'hour', 'man/hour', 'gang/hour', 'shift', 'man/shift', 'gang/shift', 'day', 'call', 'port', 'pc', 'ton', 'dton', 'm3',
                'cuf', 'cassette', 'container', 'mafi', 'trailer', 'lumpsum', '%'
            ],
            currencies: ['AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND',
                'BOB', 'BRL', 'BSD', 'BTC', 'BTN', 'BWP', 'BYN', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP',
                'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'EUR', 'FJD', 'FKP', 'GBP', 'GEL', 'GGP', 'GHS', 'GIP', 'GMD', 'GNF',
                'GTQ', 'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'IMP', 'INR', 'IQD', 'IRR', 'ISK', 'JEP', 'JMD', 'JOD', 'JPY', 'KES',
                'KGS', 'KHR', 'KMF', 'KPW', 'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LTL', 'LVL', 'LYD', 'MAD', 'MDL', 'MGA',
                'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB',
                'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL',
                'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'USD', 'UYU',
                'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XAG', 'XAU', 'XCD', 'XDR', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZMW', 'ZWL'
            ],
            brands: [{
                text: 'None',
                value: null
            }, {
                text: 'LoLo',
                value: 'LoLo'
            }, {
                text: 'StoLo',
                value: 'StoLo'
            }, {
                text: 'StoRo',
                value: 'StoRo'
            }, {
                text: 'RoRo',
                value: 'RoRo'
            }],
            calcRules: [{
                text: 'Basic',
                value: 'basic'
            }, {
                text: 'Daily',
                value: 'day'
            }, {
                text: 'DA',
                value: 'da'
            }, {
                text: 'Bunker',
                value: 'bunker'
            }, {
                text: 'Primary bunker (steaming)',
                value: 'bunkerSteaming'
            }, {
                text: 'Secondary bunker (steaming)',
                value: 'secondaryBunkerSteaming'
            }, {
                text: 'Bunker (port)',
                value: 'bunkerPort'
            }, {
                text: 'Commission',
                value: 'commission'
            }, {
                text: 'Port cost',
                value: 'portCost'
            }, {
                text: 'Loading cost',
                value: 'loadingCost'
            }, {
                text: 'Discharge cost',
                value: 'dischargeCost'
            }, {
                text: 'Freight',
                value: 'freight'
            }, {
                text: 'Ship',
                value: 'ship'
            }],
            impExpCabs: [{
                text: 'None',
                value: null
            }, {
                text: 'Import',
                value: 0
            }, {
                text: 'Export',
                value: 1
            }, {
                text: 'Cabotage',
                value: 2
            }, {
                text: 'Crosstrade',
                value: 3
            }],
            expanded: [],
            newEntry: {
                contactId: null,
                shipId: null,
                portId: null,
                terms: null,
                portIdLoad: null,
                portIdDisch: null,
                impExpCab: null,
                name: '',
                rate: null,
                rateBaf: null,
                currency: 'EUR',
                sf: null,
                tonM2: null,
                account: null,
                unit: null,
                income: 1,
                type: null,
                calcRule: 'basic',
                quantityRule: null,
                projectTypeId: null,
                notes: null,
                eventColor: '#FF00FF',
                active: 1,
                brand: null,
                shipContactRule: 0,
            },
            newPanel: [],
            color1: '#1976D2FF',
            color2: '#1976D2FF',
            menu1: false,
            menu2: false,
        }),
        created() {
            this.fetchSchedulerParams();
        },
        watch: {
            portSearch(val) {
                val && val !== this.select && this.portQuerySelections(val, 0);
            },
            portSearchLoad(val) {
                val && val !== this.select && this.portQuerySelections(val, 1);
            },
            portSearchDisch(val) {
                val && val !== this.select && this.portQuerySelections(val, 2);
            },
            contactSearch(val) {
                val && val !== this.select && this.contactQuerySelections(val);
            }
        },
        computed: {
            ships() {
                const ships = this.getShips().filter((o) => o.external !== 1);
                return [{
                    name: 'None',
                    id: null
                }].concat(ships);
            },
            userLevel() {
                return this.getUser().user.level;
            },
            moneyItems() {
                const data = this.getSchedulerParams();
                if (data && data.moneyItems && data.moneyItems.length > 0) {
                    return data.moneyItems.filter((o) => o.active === this.active);
                } 
                    return [];
            },
            projectTypes() {
                const data = this.getSchedulerParams();
                if (data && data.projectTypes && data.projectTypes.length > 0) {
                    const projectTypes = data.projectTypes;
                    projectTypes.unshift({
                        name: 'None',
                        id: null
                    });
                    return projectTypes;
                } 
                    return [];
            },
            accounts() {
                const data = this.getSchedulerParams();
                if (data && data.accounts && data.accounts.length > 0) {
                    return data.accounts;
                } 
                    return [];
            },
            swatchStyle1() {
                const {
                    color1,
                    menu1
                } = this;
                return {
                    backgroundColor: color1,
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                    borderRadius: menu1 ? '50%' : '4px',
                    transition: 'border-radius 200ms ease-in-out'
                };
            },
            swatchStyle2() {
                const {
                    color2,
                    menu2
                } = this;
                return {
                    backgroundColor: color2,
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                    borderRadius: menu2 ? '50%' : '4px',
                    transition: 'border-radius 200ms ease-in-out'
                };
            }
        },
        methods: {
            ...mapActions({
                fetchSchedulerParams: 'data/fetchSchedulerParams',
                saveMoneyItem: 'data/saveMoneyItem',
                addMoneyItem: 'data/addMoneyItem',
                fetchPorts: 'data/fetchPorts',
                fetchContacts: 'data/fetchContacts',
            }),
            ...mapGetters({
                getUser: 'authentication/getUser',
                getSchedulerParams: 'data/getSchedulerParams',
                getShips: 'data/getShips',
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
            }),
            expandPanel(item) {
                this.expanded = item === this.expanded[0] ? [] : [item];
                this.color1 = item.eventColor ? item.eventColor : '#FF00FFFF';
                this.menu1 = false;
                if (this.expanded.length > 0) {
                    this.entry = JSON.parse(JSON.stringify(item));
                } else {
                    this.entry = {};
                }
            },
            callSave(item) {
                this.saveMoneyItem(item).then(() => {
                    this.fetchSchedulerParams();
                });
            },
            callAdd(item) {
                this.addMoneyItem(item).then(() => {
                    this.fetchSchedulerParams();
                    this.cancel();
                });
            },
            cancel() {
                this.newPanel = [];
                this.newMoneyItem = {
                    contactId: null,
                    shipId: null,
                    portId: null,
                    terms: null,
                    portIdLoad: null,
                    portIdDisch: null,
                    impExpCab: null,
                    name: '',
                    rate: null,
                    rateBaf: null,
                    currency: 'EUR',
                    sf: null,
                    tonM2: null,
                    account: null,
                    unit: null,
                    income: 1,
                    type: null,
                    calcRule: 'basic',
                    quantityRule: null,
                    projectTypeId: null,
                    notes: null,
                    eventColor: '#FF00FF',
                    active: 1,
                    brand: null,
                    shipContactRule: 0,
                };
            },
            showItems(items) {
                let itemList = '';
                if (items && items.length > 0) {
                    for (let i = 0, il = items.length; i < il; i++) {
                        const item = this.moneyItems.find((o) => o.id === items[i]);
                        itemList += `${item.name}, `;
                    }
                }
                return itemList.substr(0, itemList.length - 2);
            },
            portQuerySelections(v, type) {
                if (v.length > 2) {
                    if (type === 0) {
                        this.portLoading = true;
                    } else if (type === 1) {
                        this.portLoadingLoad = true;
                    } else if (type === 2) {
                        this.portLoadingDisch = true;
                    }
                    this.fetchPorts({
                        query: v
                    }).then((data) => {
                        this.ports = data;
                        if (type === 0) {
                            this.portLoading = false;
                        } else if (type === 1) {
                            this.portLoadingLoad = false;
                        } else if (type === 2) {
                            this.portLoadingDisch = false;
                        }
                    });
                }
            },
            contactQuerySelections(v) {
                if (v.length > 2) {
                    this.contactLoading = true;
                    this.fetchContacts({
                        query: v,
                        type: null,
                    }).then((data) => {
                        this.contacts = data;
                        this.contactLoading = false;
                    });
                }
            },
            portItemText(item) {
                return `${item.name} — ${item.ddtLocode}`;
            },
            contactItemText(item) {
                // if (item.name && item.locode) {
                //     return item.name + ' — ' + item.locode;
                // } else if (item.name && item.pics) {
                //     return item.name + ' — ' + item.pics;
                // } else {
                return item.name;
                // }
            },
            updateContact(item) {
                if (item) {
                    item.contactId = item.contact.id;
                    item.contactName = item.contact.name;
                } else {
                    item.contactId = null;
                    item.contactName = null;
                }
            },
            updatePort(type, item) {
                if (type === 'port') {
                    if (item) {
                        item.portId = item.port.portId;
                        item.portName = item.port.name;
                    } else {
                        item.portId = null;
                        item.portName = null;
                    }
                } else if (type === 'load') {
                    if (item) {
                        item.portIdLoad = item.portLoad.portId;
                        item.portNameLoad = item.portLoad.name;
                    } else {
                        item.portIdLoad = null;
                        item.portNameLoad = null;
                    }
                } else if (type === 'disch') {
                    if (item) {
                        item.portIdDisch = item.portDisch.portId;
                        item.portNameDisch = item.portDisch.name;
                    } else {
                        item.portIdDisch = null;
                        item.portNameDisch = null;
                    }
                }
            },
            clearAutocomplete(type, item) {
                if (type === 'port') {
                    this.ports = [];
                    item.portId = null;
                    item.portName = null;
                } else if (type === 'load') {
                    this.ports = [];
                    item.portIdLoad = null;
                    item.portNameLoad = null;
                } else if (type === 'disch') {
                    this.ports = [];
                    item.portIdDisch = null;
                    item.portNameDisch = null;
                } else if (type === 'contact') {
                    this.contacts = [];
                    item.contactId = null;
                    item.contactName = null;
                }
            },
            formatDate(date) {
                if (date) {
                    return moment.utc(date).local().format('D.M.Y HH:mm');
                } 
                    return '';
            }
        }
    };
</script>

<style>
    .v-list--dense .v-list__tile:not(.v-list__tile--avatar) {
        height: 100%;
    }
</style>
<style scoped>
    .mypanel .v-expansion-panel__body {
        display: block !important;
        /* Dirty fix for expansion panel content */
    }

    .sectionheader {
        padding-bottom: 10px;
    }

    .shipheader {
        padding: 10px 0px;
    }

    .headertext {
        margin: 16px;
    }

    tr.expand td {
        padding: 0 !important;
    }

    tr.expand .expansion-panel {
        box-shadow: none;
    }

    tr.expand .expansion-panel li {
        border: none;
    }
</style>
