<template>
    <v-container fill-height>
        <div class="video-area">
            <div class="video_background">
                <video autoplay="autoplay" preload="auto" loop="loop" :poster="require('@/assets/img/login_bg_nexfleet.jpg')"
                    class="video-playing" id="bg-video">
                    <source :src="require('@/assets/video/nextfleet.mp4')" type="video/mp4" />
                </video>
            </div>
            <div class="video_overlay"></div>
        </div>
        <v-layout align-center justify-center fill-height>
            <v-card class="login_window elevation-10">
                <v-toolbar color="navbar" dark text>
                    <v-toolbar-title>Login</v-toolbar-title>
                    <div class="flex-grow-1"></div>
                    <v-tooltip right>
                        <template #activator="{ on }">
                            <v-btn top right
                                href="mailto:support@nauticai.com?subject=Password%20reset%20request&body=Please%20reset%20my%20BOQA%20password.%0A%0AKind%20regards%2C%0A%0A%5Byour%20name%20here%5D"
                                icon large v-on="on" style="right:0px;color:white !important">
                                <v-icon>help</v-icon>
                            </v-btn>
                        </template>
                        <span>Forgot password?</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text class="pa-6 pt-6">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field @keyup.enter="submit" v-model="email" :rules="emailRules"
                            prepend-icon="email" label="Email" required />
                        <v-text-field @keyup.enter="submit" v-model="password"
                            :append-icon="show1 ? 'visibility_off' : 'visibility'"
                            :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                            prepend-icon="lock" name="input-10-1" label="Password" hint="At least 8 characters"
                            counter @click:append="show1 = !show1" />
                    </v-form>
                </v-card-text>
                <v-card-actions class="pr-6 pl-6 pb-6">
                    <v-btn text @click="clear">Cancel</v-btn>
                    <v-spacer />
                    <v-btn :disabled="!valid" color="primary" @click="submit">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-layout>
        <!-- Status dialog -->
        <StatusDialog />
    </v-container>
</template>

<script>
    import StatusDialog from '../components/StatusDialog.vue';

    export default {
        name: 'TheLoginForm',
        components: {
            StatusDialog
        },
        data() {
            return {
                valid: true,
                show1: false,
                submitted: false,
                password: '',
                rules: {
                    required: (value) => !!value || 'Required.',
                    min: (v) => (v && v.length >= 8) || 'Min 8 characters',
                },
                email: '',
                emailRules: [
                    (v) => !!v || 'Email is required',
                    (v) => /.+@.+/.test(v) || 'Email must be valid',
                ],
            };
        },
        created() {
            // reset login status
            this.$store.dispatch('authentication/logout');
        },
        methods: {
            submit() {
                this.submitted = true;
                const {
                    email,
                    password
                } = this;
                if (email && password) {
                    this.$store.dispatch('authentication/login', {
                        email,
                        password
                    });
                }
            },
            clear() {
                this.$refs.form.reset();
            },
            env(variableName) {
                return process.env[variableName];
            },
        },
    };
</script>

<style scoped>
    .login_window {
        width:400px;
    }
   
    .video-area {
        width: auto;
        height: auto;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: #263238;
    }

    .video_background {
        width: 100%;
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
    }

    .video_background video {
        position: absolute;
        width: auto;
        min-width: 100%;
        top: 0;
        bottom: 0;
    }
    .video_overlay {
        position:absolute;
        top:0;
        width:100%;
        height:100%;
        background-repeat: repeat;
        background-image:url(https://static.wixstatic.com/media/0da768_4371c7e64a49489bb88c536f515f2040.png);
    }
    @media only screen and (max-width: 440px) {
        .login_window {
            width:95%;
        }
    }
</style>
