import axios from 'axios';
import moment from 'moment';
import { authHeader, handleResponse, tools } from '../helpers';
// import { authHeader, handleResponse } from '../helpers';

export default class GridDataService {
    constructor() {
        this.requestOptions = {
            headers: authHeader()
        };
        this.BASE_URL = tools.getPerfUrl();
        // this.BASE_URL = 'http://localhost:3000';
    }

    get(state) {
        const pageQuery = `/${state.skip}/${state.take}`;
        let sortQuery = '/Bunker_Delivery_Date desc';
        if ((state.sorted || []).length) {
            sortQuery = `/${state.sorted.map((obj) => {
                return obj.direction === 'descending' ? `${obj.name} desc` : obj.name;
            }).reverse().join(',')}`;
        }
        let whereQuery = '/0';
        if (state && state.where && state.where.length > 0) {
            whereQuery = `/${state.where[0].predicates.map((obj) => {
                obj.name = `v.${obj.field}='${obj.value}'`;
                return obj.name;
            }).join(' AND ')}`;
        }
        this.url = `${this.BASE_URL}/dnvbunker${pageQuery}${sortQuery}${whereQuery}`;
        return axios.get(this.url, this.requestOptions)
            .then(handleResponse)
            .then((data) => ({ result: this.parseData(data.result), count: data.count, avg: data.avg })).catch((e) => {
                console.error(e);
                return [];
            });
    }

    getParam(param) {
        return axios.get(`${this.BASE_URL}/dnv/${param}`, this.requestOptions)
            .then(handleResponse)
            .then((data) => data).catch((e) => {
                console.error(e);
                return 0;
            });
    }

    parseData(data) {
    // parseData(data, findIssues = true) {
        // id, customerId, shipId, name, supplier, marpolSampleNr, externalAnalysis, lop, BDN_Number, IMO, Fuel_Type, Grade, Mass, Bunker_Port, Bunker_Delivery_Date, Bunker_Delivery_Time,
        // Sulphur_Content, Density_At_15dg, Viscosity, Water_Content, Lower_Heating_Value, Higher_Heating_Value, TtW_CO2_CF, Entry_Made_By_1, Reporting_date, time, uuid, active
        if (data && data.length > 0) {
            // let sorted = {};
            for (let i = 0, il = data.length; i < il; i++) {
                // Format data
                if (data[i].Reporting_date) {
                    data[i].Reporting_date = moment.utc(data[i].Reporting_date).format();
                }
                if (data[i].time) {
                    data[i].time = moment.utc(data[i].time).format();
                }
                if (typeof data[i].Mass !== 'undefined' && data[i].Mass !== null) {
                    data[i].Mass = Math.round(data[i].Mass * 1000) / 1000;
                }
                if (typeof data[i].Sulphur_Content !== 'undefined' && data[i].Sulphur_Content !== null) {
                    data[i].Sulphur_Content = Math.round(data[i].Sulphur_Content * 1000) / 1000;
                }
                if (typeof data[i].Density_At_15dg !== 'undefined' && data[i].Density_At_15dg !== null) {
                    data[i].Density_At_15dg = Math.round(data[i].Density_At_15dg * 1000) / 1000;
                }
                if (typeof data[i].Viscosity !== 'undefined' && data[i].Viscosity !== null) {
                    data[i].Viscosity = Math.round(data[i].Viscosity * 1000) / 1000;
                }

                // if (findIssues) {
                //     // Create array for checking false ports
                //     if (typeof sorted[data[i].name] === 'undefined') {
                //         sorted[data[i].name] = [];
                //     }
                //     sorted[data[i].name].push(data[i]);
                // }
            }
            // if (findIssues) {
            //     // Sort based on etd
            //     let issues = [];
            //     for (const key in sorted) {
            //         if (Object.hasOwnProperty.call(sorted, key)) {
            //             let lastDepArr;
            //             let lastArrival;
            //             let Cargo_Mt;
            //             const voyages = sorted[key].sort((a, b) => ((moment(a.Date_UTC + ' ' + a.Time_UTC).valueOf() > moment(b.Date_UTC + ' ' + b.Time_UTC).valueOf()) ? 1 : ((moment(a.Date_UTC + ' ' + a.Time_UTC).valueOf() < moment(b.Date_UTC + ' ' + b.Time_UTC).valueOf()) ? -1 : 0)));
            //             for (let i = 0, il = voyages.length-1; i < il; i++) {
            //                 if (voyages[i].Event === 'Departure') {
            //                     Cargo_Mt = voyages[i].Cargo_Mt;
            //                 }
            //                 if (voyages[i].Event === voyages[i + 1].Event && voyages[i].Event !== 'Noon') {
            //                     issues.push({
            //                       id: voyages[i + 1].id,
            //                       type: 'Two events of the same type after each other'
            //                     });
            //                   } else if (voyages[i].Event === 'Departure' && voyages[i + 1].Event === 'End anchoring') {
            //                     issues.push({
            //                       id: voyages[i + 1].id,
            //                       type: 'End achoring cannot be after departure to port'
            //                     });
            //                   } else if (voyages[i].Event === 'Arrival' && (voyages[i + 1].Event === 'Begin anchoring' || voyages[i + 1].Event === 'End anchoring')) {
            //                     issues.push({
            //                       id: voyages[i + 1].id,
            //                       type: 'Achoring cannot be after arrival'
            //                     });
            //                   } else if (voyages[i].Event === 'Begin anchoring' && (voyages[i + 1].Event === 'Arrival' || voyages[i + 1].Event === 'Departure')) {
            //                     issues.push({
            //                       id: voyages[i].id,
            //                       type: 'End achoring missing before departure or arrival'
            //                     });
            //                   } else if (lastDepArr && voyages[i].Event === lastDepArr) { // Dep and Arr must be every second -> 2 dep or arr in a row
            //                     issues.push({
            //                       id: voyages[i].id,
            //                       type: 'Two departures or arrivals after each other'
            //                     });
            //                   } else if (Math.abs(voyages[i + 1].Time_Since_Previous_Report - moment(voyages[i + 1].Date_UTC + ' ' + voyages[i + 1].Time_UTC).diff(moment(voyages[i].Date_UTC + ' ' + voyages[i].Time_UTC), 'hours', true)) > 0.1) {
            //                     // console.log(voyages[i + 1].Time_Since_Previous_Report, moment(voyages[i + 1].Date_UTC + ' ' + voyages[i + 1].Time_UTC).diff(moment(voyages[i].Date_UTC + ' ' + voyages[i].Time_UTC), 'hours', true), ( moment(voyages[i + 1].Date_UTC + ' ' + voyages[i + 1].Time_UTC).unix() -moment(voyages[i].Date_UTC + ' ' + voyages[i].Time_UTC).unix())/3600);
            //                     issues.push({
            //                       id: voyages[i + 1].id,
            //                       type: 'Time since previous report does not match time between consecutive events'
            //                     });
            //                   } else if (typeof Cargo_Mt !== 'undefined' && voyages[i].Cargo_Mt !== Cargo_Mt) {
            //                     issues.push({
            //                       id: voyages[i].id,
            //                       type: 'Cargo MT has changed during a voyage'
            //                     });
            //                   } else if (lastArrival && voyages[i].Event === 'Departure' && lastArrival.Voyage_To !== voyages[i].Voyage_From) { 
            //                     issues.push({
            //                       id: voyages[i].id,
            //                       type: 'Departure port is different from previous arrival'
            //                     });
            //                   }

            //                 if (voyages[i].Event === 'Arrival' || voyages[i].Event === 'Departure') {
            //                     lastDepArr = voyages[i].Event;
            //                     if (voyages[i].Event === 'Arrival') {
            //                         lastArrival = voyages[i];
            //                     }
            //                 }

            //             }    
            //         }
            //     }
            //     // console.log('ISSUES:', issues);
            //     data = data.map(o => {
            //         o.issue = 'Check OK';
            //         return o;
            //     });
            //     if (issues.length > 0) {
            //         for (const issue of issues) {
            //             const index = data.findIndex(o => o.id === issue.id);
            //             if (index > -1) {
            //                 data[index].issue = issue.type;
            //             }
            //         }
            //     }
            // }
            
        }
        data.sort((a, b) => ((moment(a.Bunker_Delivery_Date + ' ' + a.Bunker_Delivery_Time).valueOf() < moment(b.Bunker_Delivery_Date + ' ' + b.Bunker_Delivery_Time).valueOf()) ? 1 : ((moment(a.Bunker_Delivery_Date + ' ' + a.Bunker_Delivery_Time).valueOf() > moment(b.Bunker_Delivery_Date + ' ' + b.Bunker_Delivery_Time).valueOf()) ? -1 : 0)));
        return data;
    }
}
