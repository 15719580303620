<template>
  <div>
    <v-card class="head">
      <v-card-actions>
        <v-btn text color="warn" @click="cancel();">
          Close
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-text class="mb-0 pb-0">
        <v-list dense>
          <v-list-item>
            <v-list-item-content two-line>
              <v-list-item-title class="text-h6">
                {{ display.id }} {{ display.name }} <span
                      class="float-right mt-n1 text-subtitle-1">{{ showStatusAndVersion() }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Summary</v-list-item-subtitle>
              <v-list-item-content v-text="display.summary"></v-list-item-content>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card class="fields scrollbar">
      <v-card-text class="mt-0 pt-0 ">
        <v-list dense class="mb-0 pb-0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Description</v-list-item-subtitle>
              <v-list-item-content v-text="display.description"></v-list-item-content>
            </v-list-item-content>
          </v-list-item>
          </v-list>
          <v-row>
            <v-col cols="5">
              <v-list dense class="mt-0 pt-0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Books</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <span class="ml-2">
                <v-chip class="ml-2" v-for="(value, index) in display.book" label :key="index">
                  <v-icon left>
                    {{ bookIcon(value) }}
                  </v-icon>{{ value }}
                </v-chip>
              </span>
              </v-list>
            </v-col>
            <v-col cols="7">
              <v-list dense class="mt-0 pt-0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>Categories</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <span class="ml-2">
                  <v-chip class="ml-2" v-for="(value, index) in display.category" label :key="index">
                    <v-icon left>
                      {{ categoryIcon(value) }}</v-icon>{{ value }}
                  </v-chip>
                </span>
              </v-list>
            </v-col>
        </v-row>
        <v-divider class="mt-4 mb-6"></v-divider>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container fluid grid-list-lg>
            <v-layout wrap align-center>
              <v-flex d-flex v-for="(item, index) in record.fields" :key="index" style="position:relative"
                      :style="{ display: item.hidden ? 'none !important' : 'flex' }"
                      :class="item.breakpoint ? item.breakpoint : item.type === 'newline' ? 'xs12' : item.type === 'attachment' ? 'xs12 sm12 lg6 xl4' : 'xs12 sm6 lg3 xl2'">
                <v-text-field v-if="item.type === 'text' || item.type === 'latitude' || item.type === 'longitude'" :error-messages="errorMessages[item.id]"
                              outlined v-model="item.value" :label="item.label" :rules="getRules(item.rules)" :dense="item.dense"
                              readonly :required="item.rules && item.rules.required ? item.rules.required : false" persistent-placeholder :disabled="item.disabled"
                              persistent-hint :loading="loading[item.id]"
                              :hint="autoValueHint(item)">
                </v-text-field>
                <v-textarea v-else-if="item.type === 'textarea'" auto-grow outlined rows="1" v-model="item.value" :error-messages="errorMessages[item.id]"
                            :label="showLabel(item)" :rules="getRules(item.rules)" :required="item.rules && item.rules.required ? item.rules.required : false" :dense="item.dense"
                            readonly persistent-placeholder persistent-hint :loading="loading[item.id]" :disabled="item.disabled"
                            :hint="autoValueHint(item)">
                </v-textarea>
                <v-text-field v-else-if="item.type === 'number'" outlined :type="item.type" :step="item.step" :error-messages="errorMessages[item.id]"
                              v-model="item.value" :label="showLabel(item)" :rules="getRules(item.rules)" :dense="item.dense"
                              :required="item.rules && item.rules.required ? item.rules.required : false" persistent-placeholder :disabled="item.disabled"
                              persistent-hint readonly :loading="loading[item.id]"
                              :hint="autoValueHint(item)">
                </v-text-field>
                <v-select v-else-if="item.type === 'select'" outlined v-model="item.value" :items="item.items" :error-messages="errorMessages[item.id]"
                          item-key="value" :rules="getRules(item.rules)" item-text="text" item-value="value" :dense="item.dense"
                          :label="showLabel(item)" persistent-placeholder persistent-hint :disabled="item.disabled"
                          :multiple="item.multiple"
                          :hint="autoValueHint(item)" />
                <v-combobox v-else-if="item.type === 'combobox'" outlined v-model="item.value" :items="item.items" :error-messages="errorMessages[item.id]"
                          item-key="value" :rules="getRules(item.rules)" item-text="text" item-value="value" :dense="item.dense"
                          :label="showLabel(item)" readonly persistent-placeholder persistent-hint :disabled="item.disabled"
                           :multiple="item.multiple"
                          :hint="autoValueHint(item)" />

                <v-switch v-else-if="item.type === 'switch'" v-model="item.value" :error-messages="errorMessages[item.id]" :dense="item.dense"
                            :label="showLabel(item)" :rules="getRules(item.rules)" :required="item.rules && item.rules.required ? item.rules.required : false" 
                            persistent-placeholder persistent-hint readonly :loading="loading[item.id]" :disabled="item.disabled"
                            :hint="autoValueHint(item)" false-value="0" true-value="1"
                ></v-switch>
                <v-checkbox v-else-if="item.type === 'checkbox'" v-model="item.value" :error-messages="errorMessages[item.id]" :dense="item.dense"
                            :label="showLabel(item)" :rules="getRules(item.rules)" :required="item.rules && item.rules.required ? item.rules.required : false" 
                            :disabled="item.disabled" persistent-placeholder persistent-hint readonly :loading="loading[item.id]"
                            :hint="autoValueHint(item)" false-value="0" true-value="1" />


                <v-menu v-else-if="item.type === 'date'" ref="dmenu" v-model="item.menu" :close-on-content-click="true"
                        readonly :return-value.sync="item.value" :disabled="item.disabled"
                        transition="scale-transition" offset-y>
                  <template #activator="{ on }">
                    <v-text-field outlined v-model="item.value" persistent-placeholder readonly :error-messages="errorMessages[item.id]"
                                  :label="eventTimeLabel(item.label)" :disabled="item.disabled" hint="Pick date" @change="onChange(item)" :dense="item.dense"
                                  :rules="getRules(item.rules)" :required="item.rules && item.rules.required ? item.rules.required : false">
                      <template #prepend-inner>
                        <v-icon v-on="on" color="primary">{{ mdiCalendar }}</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker @change="$refs.dmenu[item.index].save(item.value)" first-day-of-week="1"
                                 :show-week="true" v-model="item.value" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="item.menu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.dmenu[item.index].save(item.value)">
OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <v-menu v-else-if="item.type === 'time'" ref="tmenu" v-model="item.menu" :close-on-content-click="false"
                        :disabled="item.disabled" :nudge-right="40" :return-value.sync="item.value"
                        readonly transition="scale-transition" offset-y>
                  <template #activator="{ on }">
                    <v-text-field outlined v-model="item.value" persistent-placeholder hint="Pick time" :error-messages="errorMessages[item.id]"
                                  :disabled="item.disabled" :label="eventTimeLabel(item.label)" @change="onChange(item)" :dense="item.dense"
                                  readonly :rules="getRules(item.rules)"
                                  :required="item.rules && item.rules.required ? item.rules.required : false">
                      <template #prepend-inner>
                        <v-icon v-on="on" color="primary">{{ mdiClockOutline }}</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-time-picker v-if="item.menu" v-model="item.value" format="24hr"
                                 @click:minute="$refs.tmenu[item.index].save(item.value)"></v-time-picker>
                </v-menu>
                <v-autocomplete v-else-if="item.type === 'autocompletePort' && item.index === 0" v-model="item.value" :error-messages="errorMessages[item.id]"
                                readonly :items="ports0" :loading="portLoading0" :dense="item.dense"
                                :item-text="portItemText" item-key="id" :rules="getRules(item.rules)"
                                :search-input.sync="portSearch0" cache-items return-object class="mt-n1 mb-7" outlined
                                :disabled="item.disabled" text persistent-placeholder hide-no-data hide-details
                                :label="showLabel(item)">
                </v-autocomplete>
                <v-autocomplete v-else-if="item.type === 'autocompletePort' && item.index === 1" v-model="item.value" :error-messages="errorMessages[item.id]"
                                readonly :items="ports1" :loading="portLoading1" :dense="item.dense"
                                :item-text="portItemText" item-key="id" :rules="getRules(item.rules)"
                                :search-input.sync="portSearch1" cache-items return-object class="mt-n1 mb-7" outlined
                                :disabled="item.disabled" text persistent-placeholder hide-no-data hide-details
                                :label="item.label">
                </v-autocomplete>
                <v-text-field v-else-if="item.type === 'importFile'" outlined :dense="item.dense"
                              v-model="item.name" :label="showLabel(item)" 
                               persistent-placeholder :disabled="item.disabled"
                              persistent-hint readonly :loading="loading[item.id]"
                              :hint="autoValueHint(item)">
                </v-text-field>
                <!-- <FileAttachment style="width:calc(50% - 8px)" :uuid="uuid" :record="true" v-else-if="item.type === 'attachment'"
                                  :readonly="status === 40" /> -->
                <div v-else-if="item.type === 'newline'" style="height:1px"></div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import {
    tools
  } from '../../helpers';
  import {
    v4 as uuidv4
  } from 'uuid';
  import moment from "moment";
  const jv = require('json-variables');
//   import FileAttachment from './FileAttachment.vue';
  import {
    mdiCalendar,
    mdiClockOutline,
    mdiClipboardOutline,
    mdiShapeOutline,
    mdiEngineOutline,
    mdiShipWheel,
    mdiFerry,
    mdiImport,
    mdiWindowClose,
    mdiBinoculars,
    mdiBarrel,
  } from '@mdi/js';

  export default {
    name: 'Record',
    components: {
    //   FileAttachment
    },
    data: () => ({
      record: {},
      display: {},
      timezone: '+00:00',
      version: 0,
      valid: false,
      submitted: false,
      variables: [],
      mdiCalendar,
      mdiClockOutline,
      mdiClipboardOutline,
      mdiShapeOutline,
      mdiEngineOutline,
      mdiShipWheel,
      mdiFerry,
      mdiImport,
      mdiWindowClose,
      mdiBinoculars,
      mdiBarrel,
      openRecord: false,
      ports0: [],
      portSearch0: null,
      portLoading0: false,
      ports1: [],
      portSearch1: null,
      portLoading1: false,
      uuid: uuidv4(),
      status: 10,
      errorMessages: {},
      loading: {},
      autoSaveTimeout: null,
    }),
    async created() {
      if (this.getRecord() && typeof this.getRecord() === 'object' && this.getRecord().content && this.getRecord()
        .template) {
        this.openRecord = true;
        this.uuid = this.getRecord().uuid;
        this.template = this.getRecord().template;
        this.timezone = this.getRecord().timezone;
        this.status = this.getRecord().status;
        this.version = this.getRecord().version;
        this.timezone = this.timezone ? this.timezone : '+00:00';
        if (this.template && typeof this.template === 'object') {
          this.record = JSON.parse(JSON.stringify(this.getRecord().template));
          this.display = this.getRecord().content;
          for (let i = 0, il = this.display.fields.length; i < il; i++) {
            // Convert date & time fields to UTC
            if (this.display.fields[i].type === 'date' && this.display.fields[i + 1] && this.display.fields[i + 1]
              .type === 'time') {
              let date = this.display.fields[i].value;
              let time = this.display.fields[i + 1].value;
              if (date && time && moment.utc(`${date}T${time}`).isValid()) {
                let datetime = moment.utc(`${date}T${time}`).utcOffset(this.timezone);
                this.record.fields[i].value = datetime.format('YYYY-MM-DD');
                this.record.fields[i + 1].value = datetime.format('HH:mm');
              }
            } else if (this.display.fields[i].type !== 'time') {
              this.record.fields[i].value = this.display.fields[i].value;
              if (this.display.fields[i].autoValue || this.display.fields[i].autoValue === 0) {
                this.record.fields[i].autoValue = this.display.fields[i].autoValue;
              }
              if (this.display.fields[i].type === 'autocompletePort' && typeof this.display.fields[i].value ===
                'object') {
                if (this.display.fields[i].index === 0) {
                  this.ports0.push(this.record.fields[i].value);
                } else if (this.display.fields[i].index === 1) {
                  this.ports1.push(this.record.fields[i].value);
                }
              }
              if (this.display.fields[i].type === 'importFile') {
                this.record.fields[i].name = this.display.fields[i].name;
              }
            }
          }
          this.display = jv.jVar(this.record, {
              allowUnresolved: true
            });
        }
      } else {
        this.openRecord = false;
        this.template = this.getTemplate;
        if (this.template && typeof this.template === 'object') {
          this.record = this.template;
          this.timezone = this.timezone ? this.timezone : '+00:00';
            let eventTime = moment.utc(this.record.fields[0].value + 'T' + this.record.fields[1].value).utcOffset(this
              .timezone, true).utc();
            if (eventTime.isValid()) {
              this.record = await this.setData(eventTime, this.record);
            }
            this.display = jv.jVar(this.record, {
              allowUnresolved: true
            });
        }
      }
    },
    watch: {
    },
    computed: {
      ...mapGetters({
        getTemplate: 'logbook/getTemplate',
        isHistoryRecord: 'logbook/isHistoryRecord',
      }),
      eventTime() {
        if (this.record && this.record.fields) {
          return this.record.fields[0].value + 'T' + this.record.fields[1].value;
        } else {
          return '';
        }
      },
      
    },
    beforeDestroy() {
      this.setTemplate(null);
      this.setRecord(null);
      this.record = {};
      this.display = {};
      this.openRecord = false;
      this.setHistoryRecord(false);
    },
    methods: {
      ...mapActions({
        fetchRecords: 'logbook/fetchRecords',
        listFiles: 'logbook/listFiles',
      }),
      ...mapGetters({
        getRecord: 'logbook/getRecord',
      }),
      ...mapMutations({
        setTemplate: 'logbook/setTemplate',
        setRecord: 'logbook/setRecord',
        setHistoryRecord: 'logbook/setHistoryRecord',
        alertError: 'alert/error',
        alertWarning: 'alert/warning',
        alertSuccess: 'alert/success',
      }),
      getRules(item) {
        return tools.checkRules(item);
      },
      bookIcon(value) {
        value = value.toLowerCase();
        switch (value) {
          case 'deck':
            return mdiShipWheel;
          case 'engine':
            return mdiEngineOutline;
          default:
            return mdiClipboardOutline;
        }
      },
      categoryIcon(value) {
        value = value.toLowerCase();
        switch (value) {
          case 'arrival':
            return mdiImport;
          case 'voyage':
            return mdiFerry;
          case 'watch':
            return mdiBinoculars;
          case 'events':
            return mdiCalendar;
          case 'oil':
            return mdiBarrel;
          default:
            return mdiShapeOutline;
        }
      },
      showStatus(status) {
        if (status === -10) {
          return 'Deleted';
        } else if (status === 10) {
          return 'Draft';
        } else if (status === 20) {
          return 'Verified';
        } else if (status === 30) {
          return 'Unsigned';
        } else if (status === 40) {
          return 'Signed';
        } else {
          return '';
        }
      },
      showStatusAndVersion() {
        return `${this.showStatus(this.status)} (${this.version})`;
      },
      cancel() {
        this.setTemplate(null);
        this.setRecord(null);
        this.setHistoryRecord(false);
        // if (this.$router.history.current.path !== '/log' && this.$router.history.current.path !== '/history') {
        //   this.$router.push({
        //     name: 'log'
        //   });
        // }
      },
      round(value, step) {
        if (!Number.isNaN(value) && step) {
          let arr = step.toString().split('.');
          let decimals = arr && arr[1] ? arr[1].length : 0;
          return tools.round(value, decimals);
          // return value.toFixed(decimals);
        } else {
          return value;
        }
      },
      eventTimeLabel(label) {
        if (this.timezone) {
          return label + ' ' + this.timezone;
        } else {
          return label;
        }
      },
      portItemText(item) {
        return `${item.name} — ${item.ddtLocode}`;
      },
      showLabel(item) {
        if (item.displayUnit) {
          return item.label + ' [' + item.displayUnit + ']';
        } else {
          return item.label;
        }
      },
      autoValueHint(item) {
        let hint = null;
        if (item.autoValue || item.autoValue === 0) {
          hint = 'Auto: ' + item.autoValue;
        } else if (item.dataTag || item.dataTagOnChange) {
          hint = 'Auto: N/A';
        } 
        return hint
      },
    }
  }
</script>

<style scoped>

</style>