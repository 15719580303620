import { dataService } from '../services';
import moment from 'moment';

export const logbook = {
  namespaced: true,
  state: {
    templates: [],
    triggers: [],
    template: null,
    records: [],
    record: null,
    timezone: {},
    tab: null,
    ship: {},
    shipId: null,
    historyRecord: false,
    historyRecords: [],
    searchRecords: [],
    auditLog: [],
    users: [],
    roles: [],
    pdfData: {},
    search: '',
    startDate: moment.utc().subtract(3, 'month').format('YYYY-MM-DD'),
    endDate: moment.utc().format('YYYY-MM-DD'),
  },
  actions: {
    fetchTemplates({
      commit
    }, logDb) {
      commit('data/progressOn', null, { root: true });
      return dataService.getByIdPerf('log/templates', logDb)
        .then(
            (data) => {
                commit('setTemplates', data);
                commit('data/progressOff', null, { root: true });
                return data;
            },
            (error) => {
              console.error('Failed to fetch template data', error);
              commit('data/progressOff', null, { root: true });
              return [];
            }
        );
    },
    fetchTriggers({
      commit
    }, logDb) {
      commit('data/progressOn', null, { root: true });
      return dataService.getByIdPerf('log/triggers', logDb)
        .then(
            (data) => {
                commit('setTriggers', data);
                commit('data/progressOff', null, { root: true });
                return data;
            },
            (error) => {
              console.error('Failed to fetch trigger data', error);
              commit('data/progressOff', null, { root: true });
              return [];
            }
        );
    },
    fetchShipLog({
      commit
    }, logDb) {
        return dataService.getByIdPerf('log/ship', logDb)
        .then(
            (data) => {
                commit('setShip', data);
                return data;
            },
            (error) => {
              console.error('Failed to fetch ship data', error);
              return [];
            }
        );
    },
    fetchRecords({
      commit
    }, params) {
      commit('data/progressOn', null, { root: true });
        return dataService.postPerf('log/records', params)
        .then(
            (data) => {
                commit('setRecords', data);
                commit('data/progressOff', null, { root: true });
                return data;
            },
            (error) => {
              console.error('Failed to fetch records', error);
              commit('alert/error', 'Failed to fetch records. One possible reason is that data amount is too large. Select shorter timespan and try again.', { root: true });
              commit('data/progressOff', null, { root: true });
              return [];
            }
        );
    },
    fetchHistoryRecords({
      commit
    }, params) {
      commit('data/progressOn', null, { root: true });
        return dataService.postPerf('log/history', params)
        .then(
            (data) => {
                commit('setHistoryRecords', data);
                commit('data/progressOff', null, { root: true });
                return data;
            },
            (error) => {
              console.error('Failed to fetch records', error);
              commit('data/progressOff', null, { root: true });
              return [];
          }
        );
    },
    fetchSearchRecords({
      commit
    }, params) {
      commit('data/progressOn', null, { root: true });
      commit('setSearchRecords', []);
        return dataService.postPerf('log/search', params)
        .then(
            (data) => {
                commit('setSearchRecords', data);
                commit('data/progressOff', null, { root: true });
                return data;
            },
            (error) => {
              console.error('Failed to search records', error);
              commit('alert/error', 'Failed to search records. One possible reason is that data amount is too large. Select shorter timespan and try again.', { root: true });
              commit('data/progressOff', null, { root: true });
              return [];
          }
        );
    },
    fetchRecord({
      commit
    }, params) {
      commit('data/progressOn', null, { root: true });
      return dataService.postPerf('log/record', params)
        .then(
            (data) => {
              commit('setRecord', data);
              if (typeof data.template === 'object') {
                commit('setTemplate', data.template);
              }
              commit('data/progressOff', null, { root: true });
              return data;
            },
            (error) => {
              console.error('Failed to fetch record', error);
              commit('data/progressOff', null, { root: true });
              return [];
            }
        );
    },
    fetchHistoryRecord({
      commit
    }, item) {
      commit('data/progressOn', null, { root: true });
      dataService.postPerf('log/historyrecord', item)
        .then(
            (data) => {
              commit('setRecord', data);
              if (typeof data.template === 'object') {
                commit('setTemplate', data.template);
              }
              commit('data/progressOff', null, { root: true });
            },
            (error) => {
              console.error('Failed to fetch record', error);
              commit('data/progressOff', null, { root: true });
              return [];
            }
        );
    },
    // async fetchTimezone({
    //   commit
    // }, eventTime) {
    //   let data = db.get(`SELECT value FROM timezone WHERE datetime(time) < datetime(?) AND shipId = ? ORDER BY time DESC LIMIT 1`, [eventTime, store.state.authentication.shipId]);
    //   data = data && data.value ? data : {value: '+00:00'};
    //   // console.log('Timezone:', data);
    //   commit('setTimezone', data);
    //   return data.value;
    // },
   
    fetchRecordHistory({
      commit
    }, params) {
      commit('data/progressOn', null, { root: true });
      return dataService.postPerf('log/recordhistory', params)
        .then(
            (data) => {
              commit('data/progressOff', null, { root: true });
              return data;
            },
            (error) => {
              console.error('Failed to fetch records', error);
              commit('data/progressOff', null, { root: true });
              return [];
            }
        );
    },
    fetchAuditLog({
      commit
    }, params) {
      commit('data/progressOn', null, { root: true });
      dataService.postPerf('log/auditlog', params)
        .then(
            (data) => {
              commit('data/progressOff', null, { root: true });
              commit('setAuditLog', data);
            },
            (error) => {
              console.error('Failed to fetch audit log', error);
              commit('data/progressOff', null, { root: true });
              return [];
            }
        );
    },
    fetchUsers({
      commit
    }, logDb) {
      return dataService.getByIdPerf('log/users', logDb)
        .then(
          (data) => {
            try {
            data = parseUsers(data);
            commit('setUsers', data.users);
            data = parseRoles(data);
            commit('setRoles', data.roles);
            return data;
          } catch (e) {
            console.log(e)
          }
          },
          () => {
            commit('alert/error', 'Failed to download users from server', {
              root: true
            });
          }
        );
    },
    fetchReportData({ commit }, report) {
      commit('data/progressOn', null, { root: true });
      return dataService.postPerf('log/report', report)
        .then(
          (data) => {
              commit('data/progressOff', null, { root: true });
              return data
          },
          (error) => {
              commit('data/progressOff', null, { root: true });
              console.error('Failed to download report data', error);
              commit('alert/error', 'Failed to download report data', { root: true });
          }
        );
    },
    fetchExcelRecords({
      commit
    }, params) {
      commit('data/progressOn', null, { root: true });
      params.start = moment(params.start).isValid() ? moment.utc(params.start).hour(0).minute(0).format() : moment.utc().subtract(2, 'w').hour(0).minute(0).format();
      params.end = moment(params.end).isValid() ? moment.utc(params.end).hour(23).minute(59).format() : moment.utc().hour(23).minute(59).format();
      return dataService.postPerf('log/excel', params)
        .then(
          (data) => {
            commit('data/progressOff', null, { root: true });
            return data;
          },
          () => {
            commit('data/progressOff', null, { root: true });
            commit('alert/error', 'Failed to fetch Excel data from server', { root: true });
            return [];
          }
        );
    },
    fetchPdfData({
      commit
    }, params) {
      commit('data/progressOn', null, { root: true });
      return dataService.postPerf('log/pdfdata', params)
        .then(
            (data) => {
              commit('data/progressOff', null, { root: true });
              commit('setPdfData', data);
              return data;
            },
            (error) => {
              console.error('Failed to fetch PDF data', error);
              commit('data/progressOff', null, { root: true });
              return [];
            }
        );
    },
  },
  mutations: {
    setShip(state, data) {
      state.ship = data;
    },
    setShipId(state, data) {
      state.shipId = data;
    },
    setTemplate(state, data) {
      state.template = data;
    },
    setTemplates(state, data) {
      state.templates = data;
    },
    setTriggers(state, data) {
      state.triggers = data;
    },
    setRecords(state, data) {
      state.records = data;
    },
    setHistoryRecords(state, data) {
      state.historyRecords = data;
    },
    setSearchRecords(state, data) {
      state.searchRecords = data;
    },
    setRecord(state, data) {
      state.record = data;
    },
    setTimezone(state, data) {
      state.timezone = data;
    },
    setTab(state, data) {
      state.tab = data;
    },
    setHistoryRecord(state, data) {
      state.historyRecord = data;
    },
    setAuditLog(state, data) {
      state.auditLog = data;
    },
    setUsers(state, data) {
      state.users = data;
    },
    setRoles(state, data) {
      state.roles = data;
    },
    setPdfData(state, data) {
      state.pdfData = data;
    },
    setSearch(state, data) {
      state.search = data;
    },
    setStartDate(state, data) {
      state.startDate = data;
    },
    setEndDate(state, data) {
      state.endDate = data;
    },
  },
  getters: {
    getProgress(state) {
      return state.progress;
    },
    getShipId(state) {
      return state.shipId;
    },
    getTemplates(state) {
      return state.templates;
    },
    getTriggers(state) {
      return state.triggers;
    },
    getTemplate(state) {
      return state.template;
    },
    getRecords(state) {
      return state.records;
    },
    getHistoryRecords(state) {
      return state.historyRecords;
    },
    getSearchRecords(state) {
      return state.searchRecords;
    },
    getRecord(state) {
      return state.record;
    },
    getTimezone(state) {
      return state.timezone;
    },
    getTab(state) {
      return state.tab;
    },
    getShip(state) {
      return state.ship;
    },
    isHistoryRecord(state) {
      return state.historyRecord;
    },
    getAuditLog(state) {
      return state.auditLog;
    },
    getUsers(state) {
      return state.users;
    },
    getRoles(state) {
      return state.roles;
    },
    getPdfData(state) {
      return state.pdfData;
    },
    getSearch(state) {
      return state.search;
    },
    getStartDate(state) {
      return state.startDate;
    },
    getEndDate(state) {
      return state.endDate;
    },
  },
};

function parseUsers(data) {
  for (let i = 0, il = data.users.length; i < il; i++) {
    data.users[i].roleNames = [];
    data.users[i].title = JSON.parse(data.users[i].title);
    data.users[i].titleName = data.users[i].title ? data.users[i].title.join(', ') : '';
    try {
      if (data.users[i].roles.length > 0) {
        for (let j = 0, jl = data.users[i].roles.length; j < jl; j++) {
          let role = data.roles.find(o => o.id === data.users[i].roles[j]);
          if (role && role.name) {
            data.users[i].roleNames.push(role.name);
          }
        }
      }
      data.users[i].roleNamesText = data.users[i].roleNames.join(', ');
    } catch (e) {
      console.error(e);
    }
  }
  return data;
}

function parseRoles(data) {
  for (let i = 0, il = data.roles.length; i < il; i++) {
    data.roles[i].resourceList = {};
    data.roles[i].resourceText = '';
    try {
      if (data.roles[i].data && data.roles[i].data.length > 0) {
        for (let j = 0, jl = data.roles[i].data.length; j < jl; j++) {
          if (typeof data.roles[i].resourceList[data.roles[i].data[j].resource] === 'undefined') {
            data.roles[i].resourceList[data.roles[i].data[j].resource] = [];
          }
          data.roles[i].resourceList[data.roles[i].data[j].resource].push(showAction(data.roles[i].data[j].action));
        }
      }
      for (const key in data.roles[i].resourceList) {
        // console.log(key, data.roles[i].resourceList[key])
        data.roles[i].resourceText += capitalizeFirstLetter(key.toString().replace('book:', '')) + ': ' + data.roles[i].resourceList[key].join(', ') + '. ';
      }
      // console.log(data.roles[i].resourceText);
    } catch (e) {
      console.error(e);
    }
  }
  return data;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function showAction(value) {
  switch (value) {
    case -10:
      return 'deleted';
    case 10:
      return 'draft';
    case 20:
      return 'verified';
    case 30:
      return 'unsigned';
    case 40:
      return 'signed';
    default:
      return value;
  }
}