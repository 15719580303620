<template>
    <div> 
        <div style="height: calc(100vh - 162px)">
            <ejs-grid ref="grid" :dataSource="data" :allowGrouping="true" :allowResizing="true" :toolbar="toolbar" :allowReordering="true" :queryCellInfo="customizeCell"
            :toolbarClick="toolbarClick" :allowExcelExport="true" :allowPaging="true" :allowSorting="true" :sortSettings="sortOptions" :allowTextWrap="true" :textWrapSettings="wrapSettings"
            :groupSettings="groupOptions" :pageSettings="pageSettings" :allowFiltering="true" :filterSettings="filterOptions" :showColumnChooser="true" 
            :excelQueryCellInfo="excelQueryCellInfo" :dataStateChange="dataStateChange" :actionBegin="actionBegin" height="100%">
                <e-columns>
                    <!-- id, customerId, shipId, name, supplier, marpolSampleNr, externalAnalysis, lop, BDN_Number, IMO, Fuel_Type, Grade, Mass, Bunker_Port, Bunker_Delivery_Date, Bunker_Delivery_Time,
                        /Sulphur_Content, Density_At_15dg, Viscosity, Water_Content, Lower_Heating_Value, Higher_Heating_Value, TtW_CO2_CF, Entry_Made_By_1, Reporting_date, time, uuid, active-->
                    <!-- <e-column field="issue" headerText="Quality check" width="110" :allowGrouping="false" :allowEditing="false" :allowFiltering="false" /> -->
                    <e-column field="id" :visible="false" headerText="ID" :showInColumnChooser="false" :allowGrouping="false" width="70" :isPrimaryKey="true" />
                    <e-column field="shipId" :visible="false" headerText="Ship ID" width="90" :allowFiltering="false" />
                    <e-column field="name" headerText="Name" :filter="filter" width="110" />
                    <e-column field="IMO" :visible="false" headerText="IMO" :allowFiltering="false" width="90" />
                    <e-column field="Bunker_Delivery_Date" headerText="Delivery_Date" width="140" :filter="filter" />
                    <e-column field="Bunker_Delivery_Time" headerText="Delivery_Time" width="130" :allowFiltering="false" />
                    <e-column field="supplier" headerText="Supplier" width="120" :filter="filter" />
                    <e-column field="marpolSampleNr" headerText="MarpolSampleNr" width="120" :allowFiltering="false" />
                    <e-column field="lop" headerText="Lop" width="70" :allowFiltering="false" />
                    <e-column field="BDN_Number" headerText="BDN_Number" width="100" :allowFiltering="false" />
                    <e-column field="Fuel_Type" headerText="Fuel_Type" width="90" :allowFiltering="false" />
                    <e-column field="Grade" headerText="Grade" width="90" :allowFiltering="false" />
                    <e-column field="Mass" headerText="Mass" width="90" :allowFiltering="false" />
                    <e-column field="Bunker_Port" headerText="Bunker_Port" width="120" :filter="filter" />
                    <e-column field="Sulphur_Content" headerText="Sulphur_Content" width="120" :allowFiltering="false" />
                    <e-column field="Density_At_15dg" headerText="Density_At_15dg" width="120" :allowFiltering="false" />
                    <e-column field="Viscosity" headerText="Viscosity" width="90" :allowFiltering="false" />
                    <e-column field="Water_Content" headerText="Water_Content" width="120" :allowFiltering="false" />
                    <e-column field="Lower_Heating_Value" headerText="Lower_Heating_Value" width="140" :allowFiltering="false" />
                    <e-column field="Higher_Heating_Value" headerText="Higher_Heating_Value" width="145" :allowFiltering="false" />
                    <e-column field="TtW_CO2_CF" headerText="TtW_CO2_CF" width="110" :allowFiltering="false" />
                    
                    <e-column field="Entry_Made_By_1" headerText="Entry_Made_By_1" width="120" :allowGrouping="false" :allowEditing="false" :allowFiltering="false" />
                    
                    <e-column field="Reporting_date" headerText="Reporting_date" format="d/M/y HH:mm" type="date" :allowGrouping="false" :allowEditing="false" width="110" :allowFiltering="false" />
                </e-columns>
            </ejs-grid>
        </div>
        <!-- Components -->
        <ProgressCircular /> 
    </div>
</template>

<script>
    import {
    mapGetters,
    mapActions,
    mapMutations,
    } from 'vuex';
    import Vue from 'vue';
    import moment from 'moment';
    import {
    GridPlugin, Group, Page, Sort, Edit, Toolbar, ExcelExport, ColumnChooser, Reorder, Resize, Filter, CommandColumn
    } from '@syncfusion/ej2-vue-grids';
    import { DateTimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
    import {
    loadCldr, L10n, setCulture, createElement 
    } from '@syncfusion/ej2-base';
    import { DropDownList } from '@syncfusion/ej2-dropdowns';
    import ProgressCircular from './ProgressCircular.vue';
    import GridDataService from '../services/dnvBunkerGrid.service';
    // import {
    //     tools,
    // } from '../helpers';

    loadCldr( 
        require('cldr-data/main/en-FI/ca-gregorian.json'), 
        require('cldr-data/main/en-FI/numbers.json'), 
        require('cldr-data/main/en-FI/timeZoneNames.json'), 
        require('cldr-data/supplemental/numberingSystems.json'),
        require('cldr-data/supplemental/weekData.json'),
    ); 

    L10n.load({
        'en-FI': { datetimepicker: { placeholder: 'Set date/time', today: 'Today' } }
    });

    Vue.use(DateTimePickerPlugin);
    Vue.use(GridPlugin);

    export default {
        name: 'DnvBunker',
        components: {
            ProgressCircular,
        },
        data() {
            let dropInstance = {};
            return {
                data: [],
                wrapSettings: { wrapMode: 'Content' },
                state: null,
                pageSettings: { pageSizes: [10, 25, 50, 100, 200, 'All'], pageSize: 25 },
                editSettings: {
                    showDeleteConfirmDialog: false, allowEditOnDblClick: false, allowEditing: false, allowAdding: false, allowDeleting: false 
                },
                commands: [{ buttonOption: {content: "View", cssClass: "e-info custombutton" } } ],
                groupOptions: {},
                sortOptions: { columns: [{ field: 'Bunker_Delivery_Date', direction: 'Descending' }, { field: 'Bunker_Delivery_Time', direction: 'Descending' }] },
                toolbar: ['Print', 'ColumnChooser', 'ExcelExport'],
                intParams: { params: { decimals: 3, format: 'n' } },
                decimal2Params: { params: { decimals: 2 } },
                dataService: new GridDataService(),
                ddParams: { params: { dataSource: [] } },
                dpParams: { params: { locale: 'en-FI' } },
                filterOptions: { type: 'Menu' },
                filter: {
                    operator: 'equal',
                    ui: {
                        create(args) {
                            const flValInput = createElement('input', { className: 'flm-input' });
                            args.target.appendChild(flValInput);
                            const field = args.column.field;
                            if (field === 'name') {
                                const fields = { text: 'name', value: 'name' };
                                const dataService = new GridDataService();
                                dataService.getParam('DNVBunker_name').then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields,
                                        placeholder: 'Select ship',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'Bunker_Delivery_Date' || field === 'supplier' || field === 'Bunker_Port') {
                                const fields = { text: 'name', value: 'value' };
                                const dataService = new GridDataService();
                                dataService.getParam('DNVBunker_' + field).then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields,
                                        placeholder: 'Select',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            
                            }
                        },
                        write(args) {
                            console.log('WRITE', args.filteredValue);
                            dropInstance.value = args.filteredValue;
                        },
                        read(args) {
                            console.log('READ', args.column.field, args.operator, dropInstance.value);
                            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstance.value);
                        }
                    }
                },
            };
        },
        mounted() {
            setCulture('en-FI');
            const state = { skip: 0, take: 25 };
            this.dataStateChange(state);
            if (this.getShips().length === 0) {
                this.fetchShips().then((data) => { this.ddParams.params.dataSource = data; });
            } else {
                this.ddParams.params.dataSource = this.getShips();
            }
            let ship = localStorage.getItem('dnvBunkerShip');
            if (ship) {
                setTimeout(() => {
                    this.$refs.grid.ej2Instances.filterSettings.columns = [
                        { field: 'name', matchCase: false, operator: 'equal', predicate: 'and', value: ship },
                    ]    
                }, 1000);
            }
            
        },
        computed: {
            ...mapGetters({
                features: 'authentication/features',
                firstDayOfWeek: 'data/getFirstDayOfWeek',
                ships: 'data/getShips',
            })
        },
        beforeDestroy() {
           
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getDynamic: 'data/getDynamic',
                getShips: 'data/getShips',
            }),
            ...mapActions({
                fetchShips: 'data/fetchShips',
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
                alertWarning: 'alert/warning',
                progressOn: 'data/progressOn',
                progressOff: 'data/progressOff',
            }),
            dataStateChange(state) {
                // console.log(state)
                if (state && state.action && state.action.requestType === 'filterchoicerequest') {
                    // state.action.filterModel.values['Not completed ', 'Auto OK'];
                } else { // Get data from backend
                    this.dataService.get(state).then((gridData) => {
                        setTimeout(() => {
                            this.data = gridData;
                        }, 10);
                    });
                }
            },
            toolbarClick(args) {
                if (args.item.id.includes('excelexport')) {
                    const records = this.$refs.grid.dataSource.result;
                    const length = records.length;
                    for (let i = 0; i < length; i++) {
                        records[i].time = records[i].time ? moment(records[i].time).format('Y-MM-DD HH:mm') : '';
                        records[i].Reporting_date = records[i].Reporting_date ? moment(records[i].Reporting_date).format('Y-MM-DD HH:mm') : '';
                    }
                    const exportProperties = {
                        dataSource: records
                    };
                    // console.log('excel: ', exportProperties);
                    this.$refs.grid.excelExport(exportProperties);
                }
            },
            excelQueryCellInfo(args) { 
                if (args.column.field === 'time' || args.column.field === 'Reporting_date') { 
                    args.value = moment(args.value, 'D/M/YYYY hh:mm:ss').isValid() ? moment(args.value, 'D/M/YYYY hh:mm:ss').toDate() : moment(args.value).isValid() ? moment(args.value).toDate() : args.value;
                }
            },
            actionBegin(args) {
                // console.log(args)
                if (args.requestType === 'filtering' && args.currentFilteringColumn === 'name'){
                    // console.log('Setting ship name filter: ' + args.currentFilterObject.value);
                    localStorage.setItem('dnvBunkerShip', args.currentFilterObject.value);
                } else if (args.action === 'clearFilter' && args.currentFilterColumn.field === 'name') {
                    // console.log('Clearing ship name filter');
                    localStorage.removeItem('dnvBunkerShip');
                }
            },
            // rowDataBound(args) {
            //     if (args.data.issue !== 'Check OK') {
            //         args.row.classList.add('issue');
            //     }
            // },
            onCloseEditor() {
                console.log('Close event fired');
            },
            formatDate(date, timezone) {
                if (timezone) {
                return moment.utc(date).utcOffset(timezone).format('DD.MM HH:mm');
                } else {
                return moment.utc(date).format('DD.MM HH:mm');
                }
            },
            customizeCell(args) {
                if (args.column.field === 'depDelay') {
                    if (args.data.atd && args.data.etd) {
                        let delay = moment.utc(args.data.atd).unix() - moment.utc(args.data.etd).unix();
                        if (Math.abs(delay) > 20 * 60) {
                            args.cell.classList.add('warn');
                        }
                    }
                } else if (args.column.field === 'arrDelay') {
                    if (args.data.ata && args.data.eta) {
                        let delay = moment.utc(args.data.ata).unix() - moment.utc(args.data.eta).unix();
                        if (Math.abs(delay) > 20 * 60) {
                            args.cell.classList.add('warn');
                        }
                    }
                } else if (args.column.field === 'distance') {
                    if (this.data && Array.isArray(this.data.avg) && this.data.avg.length > 0) {
                        // Find matching route
                        let avg = this.data.avg.find(o => o.depLocode === args.data.depLocode && o.arrLocode === args.data.arrLocode);
                        if (avg && avg.distance && Math.abs((avg.distance - args.data.distance) / avg.distance) > 0.1) {
                            args.cell.classList.add('warn');
                        }
                    }
                }
            }
        },
        provide: {
            grid: [Group, Page, Sort, Edit, Toolbar, ExcelExport, ColumnChooser, Reorder, Resize, Filter, CommandColumn]
        }
    };
</script>

<style>
   
    
</style>
