import { userService } from '../services';

export const users = {
    namespaced: true,
    state: {
        users: [],
        groups: [],
        groupUsers: [],
        dark: false
    },
    actions: {
        fetchUsers({ commit }) {
            return userService.get('users')
                .then(
                    (data) => {
                        commit('fetchUsersSuccess', data);
                        return data;
                    },
                    (error) => {
                        commit('fetchUsersFailure', error);
                        commit('alert/error', 'Failed to fetch user data', { root: true });
                    }
                );
        },
        fetchGroups({ commit }) {
            userService.get('group')
                .then(
                    (data) => commit('fetchGroupsSuccess', data),
                    (error) => {
                        commit('fetchGroupsFailure', error);
                        commit('alert/error', 'Failed to fetch group data', { root: true });
                    }
                );
        },
        fetchGroupUsers({ commit }, groupId) {
            userService.getById('group', groupId)
                .then(
                    (data) => commit('fetchGroupUsersSuccess', data),
                    (error) => {
                        commit('fetchGroupUsersFailure', error);
                        commit('alert/error', 'Failed to fetch group users', { root: true });
                    }
                );
        },
        saveGroup({ commit }, data) {
            userService.post('group', data)
                .then(
                    (data) => {
                        commit('fetchGroupsSuccess', data);
                        commit('alert/success', 'Alert group saved', { root: true });
                    },
                    () => {
                        commit('alert/error', 'Failed save alert group', { root: true });
                    }
                );
        },
        deleteGroup({ commit }, id) {
            userService.deleteById('group', id)
                .then(
                    (data) => {
                        commit('fetchGroupsSuccess', data);
                        commit('alert/success', 'Alert group deleted', { root: true });
                    },
                    () => {
                        commit('alert/error', 'Alert group deleted failed', { root: true });
                    }
                );
        },
        saveUser({ commit }, data) {
            userService.post('user', data)
                .then(
                    (data) => {
                        commit('saveUser', data);
                        commit('alert/success', 'User data saved', { root: true });
                    },
                    () => {
                        commit('alert/error', 'Failed to save user data', { root: true });
                    }
                );
        },
        addUser({ commit }, data) {
            userService.put('user', data)
                .then(
                    (data) => {
                        commit('addUser', data);
                        commit('alert/success', 'New user saved successfully', { root: true });
                    },
                    () => {
                        commit('alert/error', 'Failed to save new user', { root: true });
                    }
                );
        },
        deleteUser({ commit }, id) {
            userService.deleteById('user', id)
                .then(
                    (id) => {
                        commit('deleteUser', id);
                        commit('alert/success', 'User deleted successfully', { root: true });
                    },
                    () => {
                        commit('alert/error', 'User delete failed', { root: true });
                    }
                );
        },
    },
    mutations: {
        fetchUsersSuccess(state, data) {
            state.users = data;
        },
        saveUser(state, data) {
            let index;
            for (const key in state.users) {
                if (state.users.hasOwnProperty(key)) {
                    if (state.users[key].id === data.id) {
                        index = key;
                    }
                }
            }
            state.users.splice(index, 1, data);  
        },
        addUser(state, data) {
            state.users.push(data);
        },
        deleteUser(state, id) {
            let index;
            for (const key in state.users) {
                if (state.users.hasOwnProperty(key)) {
                    if (state.users[key].id === id) {
                        index = key;
                    }
                }
            }
            state.users.splice(index, 1);  
        },
        fetchUsersFailure(state) {
            state.users = null;
        },
        fetchGroupUsersSuccess(state, data) {
            state.groupUsers = data;
        },
        fetchGroupUsersFailure(state) {
            state.groupUsers = null;
        },
        fetchGroupsSuccess(state, data) {
            state.groups = data;
        },
        fetchGroupsFailure(state) {
            state.groups = null;
        },
        setDarkMode(state, dark) {
            state.dark = dark;
        }
    },
    getters: {
        getUsers(state) {
            return state.users;
        },
        getGroups(state) {
            return state.groups;
        },
        getGroupUsers(state) {
            return state.groupUsers;
        },
        getDarkMode(state) {
            return state.dark;
        }
    }
};
