<template>
    <div>
        <v-tabs v-model="active" height="35" background-color="primary" slider-color="logo">
            <v-tab ripple>
                Logbook
            </v-tab>
            <v-tab>
                Edit history log
            </v-tab>
            <v-tab>
                Audit log
            </v-tab>
            <v-tab>
                User
            </v-tab>
            <v-tab>
                Role
            </v-tab>
            <v-tab>
                Template
            </v-tab>
            <v-tab>
                Trigger
            </v-tab>
            <v-tab>
                Search logbook
            </v-tab>
            <v-tabs-items v-model="active" touchless>
                <v-tab-item :transition="false">
                    <record v-if="this.template" />
                    <record-book v-else />
                </v-tab-item>
                <v-tab-item :transition="false">
                    <record v-if="this.template" />
                    <history-log v-else />
                </v-tab-item>
                <v-tab-item :transition="false">
                    <audit-log />
                </v-tab-item>
                <v-tab-item :transition="false">
                    <users />
                </v-tab-item>
                <v-tab-item :transition="false">
                    <roles />
                </v-tab-item>
                <v-tab-item :transition="false">
                    <record v-if="this.template" />
                    <templates v-else />
                </v-tab-item>
                <v-tab-item :transition="false">
                <trigger />
                </v-tab-item>
                <v-tab-item :transition="false">
                    <record v-if="this.template" />
                    <search v-else />
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>

        <!-- Components -->
        <StatusDialog />
        <ProgressCircular />
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions,
mapMutations,
    } from 'vuex';
    import StatusDialog from '../components/StatusDialog.vue';
    import ProgressCircular from '../components/ProgressCircular.vue';
    import RecordBook from '../components/Logbook/RecordBook.vue';
    import Search from '../components/Logbook/Search.vue';
    import Record from '../components/Logbook/Record.vue';
    import HistoryLog from '../components/Logbook/HistoryLog.vue';
    import AuditLog from '../components/Logbook/AuditLog.vue';
    import Users from '../components/Logbook/Users.vue';
    import Roles from '../components/Logbook/Roles.vue';
    import Templates from '../components/Logbook/Template.vue';
    import Trigger from '../components/Logbook/Trigger.vue';

    export default {
        name: 'Log',
        components: {
            StatusDialog,
            ProgressCircular,        
            RecordBook,
            Search,
            Record,
            HistoryLog,
            AuditLog,
            Users,
            Roles,
            Templates,
            Trigger,
        },
        data() {
            return {
                active: null,
            };
        },
        mounted() {
            
        },
        beforeDestroy() {
            this.setTemplate(null);
            this.setRecord(null);
        },
        watch: {
        },
        computed: {
            
            ...mapGetters({
                template: 'logbook/getTemplate',
                record: 'logbook/getRecord',
            }),
        },
        methods: {
            ...mapGetters({
            }),
            ...mapActions({
            }),
            ...mapMutations({
                setTemplate: 'logbook/setTemplate',
                setRecord: 'logbook/setRecord',
            }),
            
            
        }
    };
</script>

<style scoped>
    .v-subheader:hover {
        color: rgba(0, 0, 0, .65);
    }

</style>
<style>
</style>