<template>
    <div>     
        <div style="height: calc(100vh - 162px)">
            <ejs-grid ref="grid" :dataSource="data" :allowGrouping="true" :allowResizing="true" :toolbar="toolbar" :allowReordering="true"
            :toolbarClick="toolbarClick" :allowExcelExport="true" :editSettings="editSettings" :allowPaging="true" :allowSorting="true" :sortSettings="sortOptions"
            :groupSettings="groupOptions" :pageSettings="pageSettings" :allowFiltering="true" :filterSettings="filterOptions" :showColumnChooser="true" 
            :excelQueryCellInfo="excelQueryCellInfo" :dataStateChange="dataStateChange" :dataSourceChanged="dataSourceChanged" :rowDataBound="rowDataBound" height="100%"
>
                <e-columns>
                    <e-column field="id" :visible="false" headerText="ID" :showInColumnChooser="false" :allowGrouping="false" width="70" :isPrimaryKey="true" />
                    <e-column field="statusText" headerText="Status" :allowFiltering="true" :filter="filter" :allowEditing="false" width="110" />
                    <e-column field="name" headerText="Ship" editType="dropdownedit" :edit="ddParams" :allowFiltering="true" :filter="filter" width="135" />
                    <e-column field="route" :visible="false" headerText="Route" :allowFiltering="true" :filter="filter" width="95" />
                    <e-column field="depLocode" headerText="From" :allowFiltering="false" width="80" />
                    <e-column field="arrLocode" headerText="To" :allowFiltering="false" width="80" />
                    <e-column field="depCode" :visible="false" headerText="Departude code" :allowFiltering="false" width="150" />
                    <e-column field="etd" :visible="false" headerText="ETD" format="d/M/y HH:mm" type="date" editType="datetimepickeredit" :edit="dpParams" :allowFiltering="false" :allowGrouping="false" width="125" />
                    <e-column field="etdTime" :visible="false" headerText="ETD time" :allowFiltering="true" :filter="filter" :allowEditing="false" width="95" />
                    <e-column field="atd" headerText="ATD" format="d/M/y HH:mm" type="date" editType="datetimepickeredit" :edit="dpParams" :allowFiltering="false" :allowGrouping="false" width="185" />
                    <e-column field="depDelay" :visible="false" headerText="Dep delay" :allowSorting="false" :allowEditing="false" :allowFiltering="false" :allowGrouping="false" width="80" />
                    <e-column field="eta" :visible="false" headerText="ETA" format="d/M/y HH:mm" type="date" editType="datetimepickeredit" :edit="dpParams" :allowGrouping="false" width="125" :allowFiltering="false" />
                    <e-column field="ata" headerText="ATA" format="d/M/y HH:mm" type="date" editType="datetimepickeredit" :edit="dpParams" :allowGrouping="false" width="185" :allowFiltering="false" />
                    <e-column field="arrDelay" :visible="false" headerText="Arr delay" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="80" :allowFiltering="false" />
                    <e-column field="atSea" headerText="At sea" :allowSorting="false" :allowEditing="false" :allowFiltering="false" :allowGrouping="false" width="70" />
                    <e-column field="inPort" headerText="In port" :allowSorting="false" :allowEditing="false" :allowFiltering="false" :allowGrouping="false" width="70" />
                    <e-column field="anchorHours" headerText="Anchor hrs" width="90" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowGrouping="false" />
                    <e-column field="timeInIce" headerText="Time in ice" width="90" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowGrouping="false" />
                    <e-column field="distanceInIce" headerText="Distance in ice" width="105" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowGrouping="false" />
                    <e-column field="pax" :visible="false" headerText="PAX" width="80" editType="numericedit" :edit="intParams" :allowFiltering="false" />
                    <e-column field="carUnits" :visible="false" headerText="Cars" width="80" editType="numericedit" :edit="intParams" :allowFiltering="false" />
                    <e-column field="carMeters" :visible="false" headerText="Car [m]" width="80" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="carArea" :visible="false" headerText="Car [m2]" width="80" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="carTons" :visible="false" headerText="Car [t]" width="80" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="cargoUnits" :visible="false" headerText="Cargo" width="80" editType="numericedit" :edit="intParams" :allowFiltering="false" />
                    <e-column field="cargoMeters" :visible="false" headerText="Cargo [m]" width="85" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="cargoArea" :visible="false" headerText="Cargo [m2]" width="85" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="cargoTons" headerText="Cargo [t]" width="85" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="shipPaxArea" :visible="false" headerText="PAX area" width="85" :allowSorting="false" :allowGrouping="false" :allowEditing="false" :allowFiltering="false" />
                    <e-column field="shipCargoArea" :visible="false" headerText="Cargo area" width="90" :allowSorting="false" :allowGrouping="false" :allowEditing="false" :allowFiltering="false" />
                    <e-column field="paxAreaShare" :visible="false" headerText="PAX area [%]" width="95" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="cargoAreaShare" :visible="false" headerText="Cargo area [%]" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="paxMassShare" :visible="false" headerText="PAX mass [%]" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="cargoMassShare" :visible="false" headerText="Cargo mass [%]" width="110" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="focMeSea" headerText="FOC ME sea" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="fuelTypeMeSea" headerText="Fuel type ME sea" width="130" editType="dropdownedit" :edit="ddParamsFuelType" :allowFiltering="false" />
                    <e-column field="co2MeSea" :visible="false" headerText="CO2 ME sea" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="focMePort" headerText="FOC ME port" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="fuelTypeMePort" headerText="Fuel type ME port" width="130" editType="dropdownedit" :edit="ddParamsFuelType" :allowFiltering="false" />
                    <e-column field="co2MePort" :visible="false" headerText="CO2 ME port" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="focAuxSea" headerText="FOC Aux sea" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="fuelTypeAuxSea" headerText="Fuel type AUX sea" width="130" editType="dropdownedit" :edit="ddParamsFuelType" :allowFiltering="false" />
                    <e-column field="co2AuxSea" :visible="false" headerText="CO2 Aux sea" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="focAuxPort" headerText="FOC Aux port" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="fuelTypeAuxPort" headerText="Fuel type AUX port" width="130" editType="dropdownedit" :edit="ddParamsFuelType" :allowFiltering="false" />
                    <e-column field="co2AuxPort" :visible="false" headerText="CO2 Aux port" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="focBoilerSea" headerText="FOC Boiler sea" width="110" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="fuelTypeBoilerSea" headerText="Fuel type Boiler sea" width="130" editType="dropdownedit" :edit="ddParamsFuelType" :allowFiltering="false" />
                    <e-column field="co2BoilerSea" :visible="false" headerText="CO2 Boiler sea" width="110" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="focBoilerPort" :visible="false" headerText="FOC Boiler port" width="110" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="fuelTypeBoilerPort" :visible="false" headerText="Fuel type Boiler port" width="135" editType="dropdownedit" :edit="ddParamsFuelType" :allowFiltering="false" />
                    <e-column field="co2BoilerPort" :visible="false" headerText="CO2 Boiler port" width="110" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="propulsionPower" :visible="false" headerText="Propulsion power" width="130" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" />
                    <e-column field="focSea" headerText="FOC sea" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="70" :allowFiltering="false" />
                    <e-column field="co2Sea" headerText="CO2 sea" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="70" :allowFiltering="false" />
                    <e-column field="focPort" headerText="FOC port" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="75" :allowFiltering="false" />
                    <e-column field="co2Port" headerText="CO2 port" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="75" :allowFiltering="false" />
                    <e-column field="distance" headerText="NM" width="80" editType="numericedit" :edit="intParams" :allowFiltering="false" />
                    <e-column field="cons" headerText="kg/NM" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="70" :allowFiltering="false" />
                    <e-column field="eeoiPax" :visible="false" headerText="EEOI pax" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="90" :allowFiltering="false" />
                    <e-column field="eeoiTons" :visible="false" headerText="EEOI tons" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="90" :allowFiltering="false" />
                    <e-column field="eeoiGt" :visible="false" headerText="EEOI GT" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="90" :allowFiltering="false" />
                    <e-column field="aer" :visible="false" headerText="AER" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="90" :allowFiltering="false" />
                    <e-column field="transportWorkPax" :visible="false" headerText="Pax transport work [t/NM]" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="85" :allowFiltering="false" />
                    <e-column field="transportWorkCargo" :visible="false" headerText="Cargo transport work [t/NM]" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="85" :allowFiltering="false" />
                    <e-column field="transportWorkTotal" :visible="false" headerText="Total transport work [t/NM]" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="85" :allowFiltering="false" />
                    <e-column field="avgTrueWind" :visible="false" headerText="TWS" width="80" editType="numericedit" :edit="intParams" :allowFiltering="false" />
                    <e-column field="avgHeadWind" :visible="false" headerText="Head wind" width="90" editType="numericedit" :edit="intParams" :allowFiltering="false" />
                    <e-column field="yearmonth" headerText="Year/month" :allowSorting="false" :allowEditing="false" :allowFiltering="true" :filter="filter" :allowGrouping="false" width="110" />
                    <e-column field="editorName" headerText="Edit" :allowEditing="false" width="120" :allowFiltering="false" />
                    <e-column field="editTime" headerText="Edit time" format="d/M/y HH:mm" type="date" :allowGrouping="false" :allowEditing="false" width="140" :allowFiltering="false" />
                    <e-column field="notes" headerText="Notes" :allowGrouping="false" :allowSorting="false" width="140" :allowFiltering="false" />
                </e-columns>
            </ejs-grid>
        </div>
        
        <!-- Components -->
        <AddVoyageDialog v-model="addVoyageDialog" @close="onCloseEditor"></AddVoyageDialog>
        <ProgressCircular /> 
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions,
        mapMutations,
    } from 'vuex';
    import Vue from 'vue';
    import moment from 'moment';
    import {
 GridPlugin, Group, Page, Sort, Edit, Toolbar, ExcelExport, ColumnChooser, Reorder, Resize, Filter 
} from '@syncfusion/ej2-vue-grids';
    import { DateTimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
    import { Query } from "@syncfusion/ej2-data"; 
    import {
 loadCldr, L10n, setCulture, createElement 
} from '@syncfusion/ej2-base';
    import { DropDownList } from '@syncfusion/ej2-dropdowns';
    // import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
    // import * as gregorian from 'cldr-data/main/en-FI/ca-gregorian.json';
    // import * as numbers from 'cldr-data/main/en-FI/numbers.json';
    // import * as timeZoneNames from 'cldr-data/main/en-FI/timeZoneNames.json';
    // import * as weekData from 'cldr-data/supplemental/weekData.json';
    import AddVoyageDialog from './AddVoyageDialog.vue';
    import ProgressCircular from './ProgressCircular.vue';
    import GridDataService from '../services/gridData.service'; 
    import { eventBus } from '../main';
    
    // loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
    
    loadCldr( 
        require('cldr-data/main/en-FI/ca-gregorian.json'), 
        require('cldr-data/main/en-FI/numbers.json'), 
        require('cldr-data/main/en-FI/timeZoneNames.json'), 
        require('cldr-data/supplemental/numberingSystems.json'),
        require('cldr-data/supplemental/weekData.json'),
    ); 

    L10n.load({
        'en-FI': { datetimepicker: { placeholder: 'Set date/time', today: 'Today' } }
    });
    
    Vue.use(DateTimePickerPlugin);
    Vue.use(GridPlugin);

    export default {
        name: 'ShipVoyages',
        components: {
            ProgressCircular,
            AddVoyageDialog
        },
        data() {
            let dropInstance = {};
            return {
                data: [],
                state: null,
                pageSettings: { pageSizes: [10, 25, 50, 100, 200, 'All'], pageSize: 25 },
                // editSettings: {showDeleteConfirmDialog: true, allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog'},
                editSettings: {
                    showDeleteConfirmDialog: true, allowEditOnDblClick: false, allowEditing: true, allowAdding: true, allowDeleting: true 
                },
                groupOptions: {},
                sortOptions: { columns: [{ field: 'etd', direction: 'Descending' }] },
                toolbar: ['Add', 'Delete', 'Edit', 'Update', 'Cancel',
                     'Print', 'ColumnChooser', 'ExcelExport'
                    ],
                intParams: { params: { decimals: 3, format: 'n' } },
                decimal2Params: { params: { decimals: 2 } },
                dataService: new GridDataService(),
                ddParams: { params: { dataSource: [] } },
                ddParamsFuelType: { params: { 
                    dataSource: [{text: 'MGO', value: 'MGO'}, {text: 'LFO', value: 'LFO'}, {text: 'HFO', value: 'HFO'}],
                    fields: { text: 'text', value: 'value' },
                    query: new Query(), 
                    actionComplete: () => false,
                }},
                dpParams: { params: { locale: 'en-FI' } },
                filterOptions: { type: 'Menu' },
                filter: {
                    operator: 'equal',
                    ui: {
                        create(args) {
                            const flValInput = createElement('input', { className: 'flm-input' });
                            args.target.appendChild(flValInput);
                            const field = args.column.field;
                            if (field === 'name') {
                                const fields = { text: 'name', value: 'name' };
                                const dataService = new GridDataService();
                                dataService.getParam('ships').then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields,
                                        placeholder: 'Select ship',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'statusText') {
                                dropInstance = new DropDownList({
                                    dataSource: [
                                        { StatusText: 'Not completed ', status: 0 }, 
                                        { StatusText: 'Check', status: 1 }, 
                                        { StatusText: 'Auto OK', status: 2 },
                                        { StatusText: 'Editing', status: 3 },
                                        { StatusText: 'Verified OK', status: 4 },
                                        { StatusText: 'Webfocus error', status: 5 },
                                        { StatusText: 'Deleted', status: -1 }, 
                                    ],
                                    fields: { text: 'StatusText', value: 'status' },
                                    placeholder: 'Select status',
                                    popupHeight: '200px'
                                });
                                dropInstance.appendTo(flValInput);
                            } else if (field === 'route') {
                                const fields = { text: 'name', value: 'name' };
                                const dataService = new GridDataService();
                                dataService.getParam('routes').then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields,
                                        placeholder: 'Select route',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'yearmonth') {
                                const fields = { text: 'name', value: 'value' };
                                const dataService = new GridDataService();
                                dataService.getParam('etdmonths').then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields,
                                        placeholder: 'Select year/month',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'etdTime') {
                                const fields = { text: 'name', value: 'value' };
                                const dataService = new GridDataService();
                                dataService.getParam('etdtimes').then((data) => {
                                    data = data.map((o) => {
                                        o.name = moment.utc(`2020-01-01T${o.name}:00Z`).local().format('HH:mm');
                                        return o;
                                    });
                                    dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields,
                                        placeholder: 'Select time',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            }  
                        },
                        write(args) {
                            console.log('WRITE', args.filteredValue);
                            dropInstance.value = args.filteredValue;
                        },
                        read(args) {
                            // if (args.column.field === 'etd') {
                                
                            // }
                            console.log('READ', args.column.field, args.operator, dropInstance.value);
                            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstance.value);
                        }
                    }
                },
                addVoyageDialog: false,
            };
        },
        mounted() {
            setCulture('en-FI');
            const state = { skip: 0, take: 25 };
            this.dataStateChange(state);
            if (this.getShips().length === 0) {
                this.fetchShips().then((data) => { this.ddParams.params.dataSource = data; });
            } else {
                this.ddParams.params.dataSource = this.getShips();
            }
            // Add optional features
            if (typeof this.features.voyagesOptions !== 'undefined') {
                if (this.features.voyagesOptions.FOC === 1) {
                    this.toolbar.push({ text: 'FOC', tooltipText: 'Re-calculate FOC', prefixIcon: 'e-foc', id: 'foc' })
                }
                if (this.features.voyagesOptions.dataApi === 1) {
                    this.toolbar.push({ text: 'Data API', tooltipText: 'Update voyage data from API', prefixIcon: 'e-apidata', id: 'apidata'})
                }
                if (this.features.voyagesOptions.addCargoShipVoyage === 1) {
                    this.toolbar.push({ text: 'Add cargo ship voyage', tooltipText: 'Add cargo ship voyage', prefixIcon: 'e-add', id: 'cargoshipvoy'})
                }
            } 
            
            // New voyage added
            eventBus.$on('addCargoShipVoyage', () => {     
                let state = this.state ? this.state : { skip: 0, take: 25 };
                console.log('addCargoShipVoyage event received', state);      
                if (state) {
                    this.dataStateChange(state);
                    this.$refs.grid.refresh();
                }
            });
             // Confirm edit save
            eventBus.$on('voyageUpdate', () => {
                console.log('voyageUpdate event received', this.state);    
                if (this.state) {
                    this.dataService.edit(this.state).then((res) => {
                        if (res === 1) {
                            this.$refs.grid.refresh();
                            this.$store.dispatch('alert/success', 'Voyage updated successfully.', { root: true });                            
                        } else if (res === 0) {
                            this.$store.dispatch('alert/error', 'Voyage update failed.', { root: true });
                        }
                    }); 
                    this.state = null;
                }
            });
            // Cancel edit save
            eventBus.$on('cancel', () => {
                if (this.state) {
                    this.state = null;
                    this.$refs.grid.refresh();
                }
            });
        },
        created() {
            
        },
        computed: {
            ...mapGetters({
                features: 'authentication/features',
            })
        },
        updated() {
            
        },
        beforeDestroy() {
            eventBus.$off('voyageUpdate');
            eventBus.$off('cancel');
            eventBus.$off('addCargoShipVoyage');
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getDynamic: 'data/getDynamic',
                getShips: 'data/getShips',
            }),
            ...mapActions({
                fetchShips: 'data/fetchShips',
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
                progressOn: 'data/progressOn',
                progressOff: 'data/progressOff',
            }),
            dataStateChange(state) {
                // console.log(state)
                if (state && state.action && state.action.requestType === 'filterchoicerequest') {
                    // state.action.filterModel.values['Not completed ', 'Auto OK'];
                } else { // Get data from backend
                    this.dataService.get(state).then((gridData) => this.data = gridData);
                }
            },
            dataSourceChanged(state) {
                console.log('dataSourceChanged', state.action, state)
                if (state.action === 'add' && state.requestType === 'save') {
                    this.dataService.add(state).then((res) => {
                        if (res === 1) {
                            this.$refs.grid.refresh();
                            this.$store.dispatch('alert/success', 'New voayge saved successfully.', { root: true });
                        } else if (res === -1) {
                            this.$store.dispatch('alert/error', 'Ship name, departure locode, arrival locode, ETD and ETA are mandatory parameters.', { root: true });
                        } else if (res === 0) {
                            this.$store.dispatch('alert/error', 'Failed to save new voyage.', { root: true });
                        }
                    }); 
                } else if (state.action === 'edit' && state.requestType === 'save') {
                    let edited = false;
                    for (const key in state.data) {
                        if (state.data.hasOwnProperty(key)) {
                            for (const key2 in state.rowData) {
                                if (state.rowData.hasOwnProperty(key2) && key === key2) {
                                    if ((key === 'atd' || key === 'etd' || key === 'ata' || key === 'eta') && state.data[key] !== state.rowData[key2] && moment.utc(state.data[key]).unix() !== moment.utc(state.rowData[key2]).unix()) {
                                        edited = true;
                                        // console.log('Times updated!')
                                    // } else if (key !== 'editTime' && key !== 'atd' && key !== 'etd' && key !== 'ata' && key !== 'eta' && (key === 'name' || key === 'depLocode' || key === 'arrLocode' || key === 'distance' || key === 'avgTrueWind' || key === 'avgHeadWind' || key === 'focMeSea' || key === 'focMePort' || key === 'focAuxSea' || key === 'focAuxPort' || key === 'focBoilerSea' || key === 'focBoilerPort' || key === 'propulsionPower' || key === 'route' || key === 'pax' || key === 'carUnits' || key === 'carMeters' || key === 'cargoTons' || key === 'cargoUnits' || key === 'cargoMeters') && state.data[key] !== null && !Object.is(state.data[key], NaN) && state.data[key] !== state.rowData[key2]) {
                                    } else if (key !== 'editTime' && key !== 'atd' && key !== 'etd' && key !== 'ata' && key !== 'eta' && 
                                        (key === 'name' || key === 'depLocode' || key === 'arrLocode' || key === 'distance' || key === 'avgTrueWind' || key === 'avgHeadWind' 
                                        || key === 'focMeSea' || key === 'focMePort' || key === 'focAuxSea' || key === 'focAuxPort' || key === 'focBoilerSea' || key === 'focBoilerPort' 
                                        || key === 'co2MeSea' || key === 'co2MePort' || key === 'co2AuxSea' || key === 'co2AuxPort' || key === 'co2BoilerSea' || key === 'co2BoilerPort' 
                                        || key === 'propulsionPower' || key === 'route' || key === 'pax' || key === 'carUnits' || key === 'carMeters' || key === 'cargoTons' 
                                        || key === 'cargoUnits' || key === 'cargoMeters' || key === 'depCode' || key === 'anchorHours' || key === 'timeInIce' || key === 'distanceInIce' || key === 'notes' ||
                                        key.includes('fuelType')) && !Object.is(state.data[key], NaN) && state.data[key] !== state.rowData[key2]) {
                                        edited = true;
                                        if (state.data[key] && (state.data[key] === 'HFO' || state.data[key] === 'LFO' || state.data[key] === 'MGO')) {
                                            if (key === 'fuelTypeMeSea') {
                                                state.data.co2MeSea = Math.round(this.co2Factor(state.data.fuelTypeMeSea) * state.data.focMeSea * 100) / 100;
                                            } else if (key === 'fuelTypeMePort') {
                                                state.data.co2MePort = Math.round(this.co2Factor(state.data.fuelTypeMePort) * state.data.focMePort * 100) / 100;
                                            } else if (key === 'fuelTypeAuxSea') {
                                                state.data.co2AuxSea = Math.round(this.co2Factor(state.data.fuelTypeAuxSea) * state.data.focAuxSea * 100) / 100;
                                            } else if (key === 'fuelTypeAuxPort') {
                                                state.data.co2AuxPort = Math.round(this.co2Factor(state.data.fuelTypeAuxPort) * state.data.focAuxPort * 100) / 100;
                                            } else if (key === 'fuelTypeBoilerSea') {
                                                state.data.co2BoilerSea = Math.round(this.co2Factor(state.data.fuelTypeBoilerSea) * state.data.focBoilerSea * 100) / 100;
                                            } else if (key === 'fuelTypeBoilerPort') {
                                                state.data.co2BoilerPort = Math.round(this.co2Factor(state.data.fuelTypeBoilerPort) * state.data.focBoilerPort * 100) / 100;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (edited && state.requestType === 'save') {
                        // Eventbus not working properly. Confirm dialog commented out:
                        // this.state = state;
                        // this.alertConfirm({ message: 'Voyage data has been modified. Click confirm to update.', emit: 'voyageUpdate' });
                        // Complete edit right away:
                        this.dataService.edit(state).then((res) => {
                            if (res === 1) {
                                this.$refs.grid.refresh();
                                this.$store.dispatch('alert/success', 'Voyage updated successfully.', { root: true });                            
                            } else if (res === 0) {
                                this.$store.dispatch('alert/error', 'Voyage update failed.', { root: true });
                            }
                        }); 
                    } else {
                        this.$refs.grid.refresh();
                    }
                } else if (state.requestType === 'delete') {
                    this.dataService.delete(state).then((res) => {
                        if (res === 1) {
                            this.$store.dispatch('alert/success', 'Voyage deleted successfully.', { root: true });
                        } else if (res === 0) {
                            this.$store.dispatch('alert/error', 'Voyage delete failed.', { root: true });
                        } else if (res === -1) {
                            this.$store.dispatch('alert/error', 'Voyage id not valid.', { root: true });
                        }
                    }); 
                }
            },
            co2Factor(fuelType) {
                if (fuelType === 'HFO') {
                    return 3.114;
                } else if (fuelType === 'LFO') {
                    return 3.15104;
                } else if (fuelType === 'MGO') {
                    return 3.206;
                }
            },
            toolbarClick(args) {
                if (args.item.id.includes('excelexport')) {
                    const records = this.$refs.grid.dataSource.result;
                    const length = records.length;
                    for (let i = 0; i < length; i++) {
                        records[i].eta = records[i].eta ? moment(records[i].eta).format('D/M/Y HH:mm:ss') : '';
                        records[i].ata = records[i].ata ? moment(records[i].ata).format('D/M/Y HH:mm:ss') : '';
                        records[i].etd = records[i].etd ? moment(records[i].etd).format('D/M/Y HH:mm:ss') : '';
                        records[i].atd = records[i].atd ? moment(records[i].atd).format('D/M/Y HH:mm:ss') : '';
                        records[i].editTime = records[i].editTime ? moment(records[i].editTime).format('D/M/Y HH:mm:ss') : '';
                    }
                    const exportProperties = {
                        dataSource: records
                    };
                    this.$refs.grid.excelExport(exportProperties);
                } else if (args.item.id === 'foc') {
                    const selectedRecords = this.$refs.grid.getSelectedRecords();  
                    if (selectedRecords && selectedRecords[0] && selectedRecords[0].id) {
                        this.progressOn();
                        this.dataService.update({ type: 'foc', id: selectedRecords[0].id }).then((res) => {
                            if (res === 1) {
                                this.$refs.grid.refresh();
                                this.$store.dispatch('alert/success', 'FOC re-calculated successfully.', { root: true });
                            } else if (res === 0) {
                                this.$store.dispatch('alert/error', 'Failed to re-calculate FOC.', { root: true });
                            }
                        }).finally(() => {
                            this.progressOff();
                        });
                    } else {
                        this.$store.dispatch('alert/error', 'Select voyage first to re-calculate FOC', { root: true });
                    }
                } else if (args.item.id === 'apidata') {
                    const selectedRecords = this.$refs.grid.getSelectedRecords(); 
                    if (selectedRecords && selectedRecords[0] && selectedRecords[0].id) {
                        this.progressOn();
                        this.dataService.update({ type: 'webfocus', id: selectedRecords[0].id }).then((res) => {
                            if (res === 1) {
                                this.$refs.grid.refresh();
                                this.$store.dispatch('alert/success', 'Voyage data from API updated successfully.', { root: true });
                            } else if (res === 0) {
                                this.$store.dispatch('alert/error', 'Failed to update voyage data from API.', { root: true });
                            }
                        }).finally(() => {
                            this.progressOff();
                        });
                    } else {
                        this.$store.dispatch('alert/error', 'Select voyage first to update data from API.', { root: true });
                    }
                 } else if (args.item.id === 'cargoshipvoy') {
                     this.addVoyageDialog = true;
                }
            },
            excelQueryCellInfo(args) { 
                if (args.column.field === 'etd' || args.column.field === 'atd' || args.column.field === 'eta' || args.column.field === 'ata' || args.column.field === 'editTime') { 
                    args.value = moment(args.value, 'D/M/YYYY hh:mm:ss').isValid() ? moment(args.value, 'D/M/YYYY hh:mm:ss').toDate() : moment(args.value).isValid() ? moment(args.value).toDate() : args.value;
                } else if (args.column.field === 'depDelay' || args.column.field === 'arrDelay') {
                    if (args.value) {
                        let sign = 1;
                        if (args.value.includes('-')) {
                            args.value = args.value.substr(1, args.value.length);
                            sign = -1;
                        }
                        let x = args.value.split(':');
                        args.value = sign * (parseInt(x[0], 10) * 60 + parseInt(x[1], 10));
                    }
                } else if (args.column.field === 'inPort' || args.column.field === 'atSea') {
                    if (args.value) {
                        let x = args.value.split(':');
                        args.value = (parseInt(x[0], 10) + parseInt(x[1], 10)/60).toFixed(2);
                    }
                }
            },
            rowDataBound: function(args) {
                if (args.data.status < 0) {
                    args.row.classList.add('deleted');
                }
            },
            onCloseEditor() {
                console.log('Close event fired');
            },
        },
        provide: {
            grid: [Group, Page, Sort, Edit, Toolbar, ExcelExport, ColumnChooser, Reorder, Resize, Filter]
        }
    };
</script>

<style>
    /* Hide filter operator dropdown */
    .e-flm_optrdiv {
        display: none;
    }
    .e-grid .e-flmenu-valuediv {
        padding: 4px 0 0;
    }
    .e-foc::before {
      content: '\e606';
    }
    .e-apidata::before {
      content: '\e354';
    }
    .e-add::before {
      content: '+';
    }
    .deleted {
        text-decoration-line: line-through;
    }
</style>
