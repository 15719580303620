<template>
    <div> 
      <record v-if="this.template" />
      <div v-else>
        <div style="height: calc(100vh - 162px)">
            <ejs-grid ref="grid" :dataSource="data" :allowGrouping="true" :allowResizing="true" :toolbar="toolbar" :allowReordering="true" :queryCellInfo="customizeCell"
            :toolbarClick="toolbarClick" :allowExcelExport="true" :editSettings="editSettings" :allowPaging="true" :allowSorting="true" :sortSettings="sortOptions"
            :groupSettings="groupOptions" :pageSettings="pageSettings" :allowFiltering="true" :filterSettings="filterOptions" :showColumnChooser="true" :allowEditing="false"
            :excelQueryCellInfo="excelQueryCellInfo" :dataStateChange="dataStateChange" :dataSourceChanged="dataSourceChanged" :rowDataBound="rowDataBound" height="100%">
                <e-columns>
                    <e-column field="id" :visible="false" headerText="ID" :showInColumnChooser="false" :allowGrouping="false" width="70" :isPrimaryKey="true" />
                    <e-column field="statusText" headerText="Status" :allowFiltering="true" :filter="filter" :allowEditing="false" width="110" />
                    <e-column field="imo" :visible="false" headerText="IMO" :allowFiltering="false" :allowGrouping="false" :allowEditing="false" width="90" />
                    <e-column field="name" headerText="Ship" editType="dropdownedit" :edit="ddParams" :allowFiltering="true" :allowEditing="false" :filter="filter" width="135" />
                    <e-column field="type" :visible="false" headerText="Type" :allowFiltering="false" :allowGrouping="false" :allowEditing="true" width="90" />
                    <e-column field="route" :visible="false" headerText="Route" :allowFiltering="true" :filter="filter" :allowEditing="true" width="95" />
                    <e-column field="depLocode" headerText="From" :allowFiltering="false" width="80" :allowEditing="true" />
                    <e-column field="arrLocode" headerText="To" :allowFiltering="false" width="80" :allowEditing="true" />
                    <e-column field="depCode" :visible="false" headerText="Departude code" :allowFiltering="false" :allowEditing="true" width="150" />
                    <e-column field="etd" headerText="ETD" format="d/M/y HH:mm" type="date" editType="datetimepickeredit" :allowEditing="true" :edit="dpParams" :allowFiltering="false" :allowGrouping="false" width="125" />
                    <e-column field="etdTime" headerText="ETD time" :allowFiltering="true" :filter="filter" :allowEditing="false" width="95" />
                    <e-column field="atd" headerText="ATD" format="d/M/y HH:mm" type="date" editType="datetimepickeredit" :edit="dpParams" :allowEditing="true" :allowFiltering="false" :allowGrouping="false" width="125" />
                    <e-column field="depDelay" headerText="Dep delay" :allowSorting="false" :allowEditing="false" :allowFiltering="false" :allowGrouping="false" width="80" />
                    <e-column field="depDelayReason" headerText="Dep delay reason" :allowFiltering="false" :allowEditing="false" width="120" />
                    <e-column field="eta" headerText="ETA" format="d/M/y HH:mm" type="date" editType="datetimepickeredit" :allowEditing="true" :edit="dpParams" :allowGrouping="false" width="125" :allowFiltering="false" />
                    <e-column field="ata" headerText="ATA" format="d/M/y HH:mm" type="date" editType="datetimepickeredit" :allowEditing="true" :edit="dpParams" :allowGrouping="false" width="125" :allowFiltering="false" />
                    <e-column field="arrDelay" headerText="Arr delay" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="80" :allowFiltering="false" />
                    <e-column field="arrDelayReason" headerText="Arr delay reason" :allowFiltering="false" :allowEditing="false" width="120" />
                    <e-column field="atSea" headerText="At sea" :allowSorting="false" :allowEditing="false" :allowFiltering="false" :allowGrouping="false" width="70" />
                    <e-column field="inPort" headerText="In port" :allowSorting="false" :allowEditing="true" :allowFiltering="false" :allowGrouping="false" width="70" />
                    <e-column field="anchorHours" :visible="false" headerText="Anchor hrs" width="90" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowGrouping="false" :allowEditing="false" />
                    <e-column field="pax" headerText="PAX" width="80" editType="numericedit" :edit="intParams" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="carUnits" :visible="false" headerText="Cars" width="80" editType="numericedit" :edit="intParams" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="carMeters" :visible="false" headerText="Car [m]" width="80" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="carArea" :visible="false" headerText="Car [m2]" width="80" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="carTons" :visible="false" headerText="Car [t]" width="80" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="cargoUnits" headerText="Cargo" width="80" editType="numericedit" :edit="intParams" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="cargoMeters" :visible="false" headerText="Cargo [m]" width="85" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="cargoArea" :visible="false" headerText="Cargo [m2]" width="85" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="cargoTons" :visible="false" headerText="Cargo [t]" width="85" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="shipPaxArea" :visible="false" headerText="PAX area" width="85" :allowSorting="false" :allowGrouping="false" :allowEditing="false" :allowFiltering="false" />
                    <e-column field="shipCargoArea" :visible="false" headerText="Cargo area" width="90" :allowSorting="false" :allowGrouping="false" :allowEditing="false" :allowFiltering="false" />
                    <e-column field="paxAreaShare" :visible="false" headerText="PAX area [%]" width="95" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="cargoAreaShare" :visible="false" headerText="Cargo area [%]" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="paxMassShare" :visible="false" headerText="PAX mass [%]" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="cargoMassShare" :visible="false" headerText="Cargo mass [%]" width="110" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="focMeSea" :visible="false" headerText="FOC ME sea" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="focMePort" :visible="false" headerText="FOC ME port" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="focAuxSea" :visible="false" headerText="FOC Aux sea" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="focAuxPort" :visible="false" headerText="FOC Aux port" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="focBoilerSea" :visible="false" headerText="FOC Boiler sea" width="110" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="focBoilerPort" :visible="false" headerText="FOC Boiler port" width="110" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="focSea" headerText="FOC sea" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="70" :allowFiltering="false" />
                    <e-column field="focPort" headerText="FOC port" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="75" :allowFiltering="false" />
                    <e-column field="focTotal" headerText="FOC total" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="75" :allowFiltering="false" />
                    <!-- Fuel 1 -->
                    <e-column field="fuel1Type" :visible="false" headerText="Fuel 1 type" width="100" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel1Co2" :visible="false" headerText="Fuel 1 CO2" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel1FocSea" :visible="false" headerText="Fuel 1 sea [t]" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel1Co2Sea" :visible="false" headerText="Fuel 1 sea CO2 [t]" width="120" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel1FocPort" :visible="false" headerText="Fuel 1 port [t]" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel1Co2Port" :visible="false" headerText="Fuel 1 port CO2 [t]" width="125" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />

                    <e-column field="fuel1MeFocSea" :visible="false" headerText="Fuel 1 ME sea CO2 [t]" width="145" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel1MeFocPort" :visible="false" headerText="Fuel 1 ME port CO2 [t]" width="145" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel1AuxFocSea" :visible="false" headerText="Fuel 1 AUX sea CO2 [t]" width="145" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel1AuxFocPort" :visible="false" headerText="Fuel 1 AUX port CO2 [t]" width="150" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel1BoilerFocSea" :visible="false" headerText="Fuel 1 Boiler sea CO2 [t]" width="155" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel1BoilerFocPort" :visible="false" headerText="Fuel 1 Boiler port CO2 [t]" width="155" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />

                    <!-- Fuel 2 -->
                    <e-column field="fuel2Type" :visible="false" headerText="Fuel 2 type" width="100" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel2Co2" :visible="false" headerText="Fuel 2 CO2" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel2FocSea" :visible="false" headerText="Fuel 2 sea [t]" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel2Co2Sea" :visible="false" headerText="Fuel 2 sea CO2 [t]" width="120" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel2FocPort" :visible="false" headerText="Fuel 2 port [t]" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel2Co2Port" :visible="false" headerText="Fuel 2 port CO2 [t]" width="125" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />

                    <e-column field="fuel2MeFocSea" :visible="false" headerText="Fuel 2 ME sea CO2 [t]" width="145" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel2MeFocPort" :visible="false" headerText="Fuel 2 ME port CO2 [t]" width="145" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel2AuxFocSea" :visible="false" headerText="Fuel 2 AUX sea CO2 [t]" width="145" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel2AuxFocPort" :visible="false" headerText="Fuel 2 AUX port CO2 [t]" width="150" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel2BoilerFocSea" :visible="false" headerText="Fuel 2 Boiler sea CO2 [t]" width="155" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel2BoilerFocPort" :visible="false" headerText="Fuel 2 Boiler port CO2 [t]" width="155" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <!-- Fuel 3 -->
                    <e-column field="fuel3Type" :visible="false" headerText="Fuel 3 type" width="100" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel3Co2" :visible="false" headerText="Fuel 3 CO2" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel3FocSea" :visible="false" headerText="Fuel 3 sea [t]" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel3Co2Sea" :visible="false" headerText="Fuel 3 sea CO2 [t]" width="120" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel3FocPort" :visible="false" headerText="Fuel 3 port [t]" width="100" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel3Co2Port" :visible="false" headerText="Fuel 3 port CO2 [t]" width="125" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />

                    <e-column field="fuel3MeFocSea" :visible="false" headerText="Fuel 3 ME sea CO2 [t]" width="145" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel3MeFocPort" :visible="false" headerText="Fuel 3 ME port CO2 [t]" width="145" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel3AuxFocSea" :visible="false" headerText="Fuel 3 AUX sea CO2 [t]" width="145" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel3AuxFocPort" :visible="false" headerText="Fuel 3 AUX port CO2 [t]" width="150" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel3BoilerFocSea" :visible="false" headerText="Fuel 3 Boiler sea CO2 [t]" width="155" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="fuel3BoilerFocPort" :visible="false" headerText="Fuel 3 Boiler port CO2 [t]" width="155" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />

                    <e-column field="co2Sea" :visible="false" headerText="CO2 sea" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="70" :allowFiltering="false" />
                    <e-column field="co2Port" :visible="false" headerText="CO2 port" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="75" :allowFiltering="false" />
                    <e-column field="co2Total" :visible="false" headerText="CO2 total" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="75" :allowFiltering="false" />

                    <e-column field="scPower" :visible="false" headerText="SC power" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="80" :allowFiltering="false" />
                    <e-column field="fuel1lcvEu" :visible="false" headerText="Fuel 1 LCV EU" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="110" :allowFiltering="false" />
                    <e-column field="fuel1lcvImo" :visible="false" headerText="Fuel 1 LCV IMO" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="110" :allowFiltering="false" />
                    <e-column field="fuel2lcvEu" :visible="false" headerText="Fuel 2 LCV EU" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="110" :allowFiltering="false" />
                    <e-column field="fuel2lcvImo" :visible="false" headerText="Fuel 2 LCV IMO" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="110" :allowFiltering="false" />
                    <e-column field="fuel3lcvEu" :visible="false" headerText="Fuel 3 LCV EU" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="110" :allowFiltering="false" />
                    <e-column field="fuel3lcvImo" :visible="false" headerText="Fuel 3 LCV IMO" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="110" :allowFiltering="false" />
                    
                    <e-column field="propulsionPower" :visible="false" headerText="Propulsion power [MWh]" width="155" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="hotelPower" :visible="false" headerText="Hotel power [MWh]" width="130" editType="numericedit" :edit="decimal2Params" :allowFiltering="false" :allowEditing="false" />
                    
                    <e-column field="distance" headerText="NM" width="80" editType="numericedit" :edit="intParams" :allowFiltering="false" :allowEditing="false" />
                    
                    <e-column field="cons" :visible="false" headerText="kg/NM" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="70" :allowFiltering="false" />
                    <e-column field="eeoiPax" :visible="false" headerText="EEOI pax" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="90" :allowFiltering="false" />
                    <e-column field="eeoiTons" :visible="false" headerText="EEOI tons" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="90" :allowFiltering="false" />
                    <e-column field="eeoiGt" :visible="false" headerText="EEOI GT" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="90" :allowFiltering="false" />
                    <e-column field="cii" :visible="false" headerText="CII" width="100" :allowFiltering="false" />
                   
                    <e-column field="transportWorkPax" :visible="false" headerText="Pax transport work [pax*NM]" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="180" :allowFiltering="false" />
                    <e-column field="transportWorkCargo" :visible="false" headerText="Cargo transport work [t*NM]" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="180" :allowFiltering="false" />
                   
                    <e-column field="tws" :visible="false" headerText="TWS [m/s]" width="80" editType="numericedit" :edit="intParams" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="headwind" :visible="false" headerText="Head wind [m/s]" width="120" editType="numericedit" :edit="intParams" :allowFiltering="false" :allowEditing="false" />
                    <e-column field="yearmonth" headerText="Year/month" :allowSorting="false" :allowEditing="false" :allowFiltering="true" :filter="filter" :allowGrouping="false" width="110" />
                    <e-column field="signer" headerText="Signer" :allowEditing="false" width="110" :allowFiltering="false" />
                    <e-column field="editorName" headerText="Author" :allowEditing="false" width="120" :allowFiltering="false" />
                    <e-column field="time" headerText="Updated" format="d/M/y HH:mm" type="date" :allowGrouping="false" :allowEditing="false" width="140" :allowFiltering="false" />
                    <e-column field="notes" headerText="Notes" :allowGrouping="false" :allowSorting="false" width="140" :allowFiltering="false" :allowEditing="false" />
                    <!-- <e-column field="editHistory" headerText="Edit history" width="85" headerAlign="left" textAlign="center" :commands="commands" :allowSorting="false" :allowEditing="false" :allowGrouping="false" :allowFiltering="false"></e-column>  -->
                    <e-column field="dataApi" :visible="false" headerText="Data API" width="80" :allowFiltering="false" :allowEditing="false" />

                    <e-column field="gCo2Lm" :visible="false" headerText="gCO2/LM (area)" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="110" :allowFiltering="false" />
                    <e-column field="gCo2LmNm" :visible="false" headerText="gCO2/LM NM (area)" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="135" :allowFiltering="false" />
                    <e-column field="gCo217m" :visible="false" headerText="gCO2/17m (area)" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="120" :allowFiltering="false" />
                    <e-column field="gCo217mNm" :visible="false" headerText="gCO2/17m NM (area)" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="145" :allowFiltering="false" />
                    <e-column field="gCo2LmM" :visible="false" headerText="gCO2/17m (mass)" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="125" :allowFiltering="false" />
                    <e-column field="gCo2LmNmM" :visible="false" headerText="gCO2/LM NM (mass)" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="140" :allowFiltering="false" />
                    <e-column field="gCo217mM" :visible="false" headerText="gCO2/17m (mass)" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="125" :allowFiltering="false" />
                    <e-column field="gCo217mNmM" :visible="false" headerText="gCO2/17m NM (mass)" :allowSorting="false" :allowEditing="false" :allowGrouping="false" width="145" :allowFiltering="false" />
                </e-columns>
            </ejs-grid>
        </div>
        <v-dialog v-model="dialog" width="550">
            <v-card>
                  <v-card-title>
                    Export EEOI and CII report to excel
                  </v-card-title>
                  <v-card-text>
                    <v-container fluid grid-list-lg>
                        <v-layout wrap align-center>
                            <v-flex xs12 d-flex>
                                <v-select v-model="report.shipId" :items="ships" item-key="id" item-text="[name]" item-value="id" @change="setShip(report.shipId)" label="Select ship">
                                    <template #prepend>
                                        <v-icon color="primary">mdi-ferry</v-icon>
                                    </template>
                                </v-select>
                            </v-flex>
                            <v-flex xs12 d-flex>
                                <v-select v-model="timespan" :items="times" label="Set timespan" @change="setTimeSpan(timespan)">
                                    <template #prepend>
                                        <v-icon color="primary">mdi-calendar-check-outline</v-icon>
                                    </template>
                                </v-select>
                            </v-flex>
                            <v-flex xs12 sm6 d-flex>
                                <v-menu ref="sdmenu" v-model="startDateMenu" :close-on-content-click="true" :return-value.sync="report.startDate"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template #activator="{ on }">
                                        <v-text-field v-model="report.startDate" label="Start date">
                                            <template #prepend>
                                                <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker @change="$refs.sdmenu.save(report.startDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                                        v-model="report.startDate" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.sdmenu.save(report.startDate)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12 sm6 d-flex>
                                <v-menu ref="stmenu" v-model="startTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                    :return-value.sync="report.startTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template #activator="{ on }">
                                        <v-text-field v-model="report.startTime" label="Start time">
                                            <template #prepend>
                                                <v-icon v-on="on" color="primary">access_time</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-time-picker v-if="startTimeMenu" v-model="report.startTime" format="24hr" 
                                        @click:minute="$refs.stmenu.save(report.startTime)"></v-time-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12 sm6 d-flex>
                                <v-menu ref="edmenu" v-model="endDateMenu" :close-on-content-click="true" :return-value.sync="report.endDate"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template #activator="{ on }">
                                        <v-text-field v-model="report.endDate" label="End date">
                                            <template #prepend>
                                                <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker @change="$refs.edmenu.save(report.endDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                                        v-model="report.endDate" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="endDateMenu = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.edmenu.save(report.endDate)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12 sm6 d-flex>
                                <v-menu ref="etmenu" v-model="endTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                    :return-value.sync="report.endTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template #activator="{ on }">
                                        <v-text-field v-model="report.endTime" label="End time">
                                            <template #prepend>
                                                <v-icon v-on="on" color="primary">access_time</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-time-picker v-if="endTimeMenu" v-model="report.endTime" format="24hr" 
                                        @click:minute="$refs.etmenu.save(report.endTime)"></v-time-picker>
                                </v-menu>
                            </v-flex>
                            <v-btn class="mt-6" color="error" @click="dialog = false">
                                Cancel <v-icon right class="hidden-xs-only">cancel</v-icon>
                            </v-btn>
                            <v-spacer />
                            <v-btn class="mt-6" color="primary" @click="getReport(report)"
                                :disabled="!report.shipId">
                                Create reports
                                <v-icon right class="hidden-xs-only">check</v-icon>
                            </v-btn>
                        </v-layout>
                    </v-container>
                  </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Components -->
        <ProgressCircular /> 
      </div>
    </div>
</template>

<script>
    import Record from './Logbook/Record.vue';
    import {
    mapGetters,
    mapActions,
    mapMutations,
    } from 'vuex';
    import Vue from 'vue';
    import moment from 'moment';
    import writeXlsxFile from 'write-excel-file';
    import {
    GridPlugin, Group, Page, Sort, Edit, Toolbar, ExcelExport, ColumnChooser, Reorder, Resize, Filter, CommandColumn
    } from '@syncfusion/ej2-vue-grids';
    import { DateTimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
    import {
    loadCldr, L10n, setCulture, createElement 
    } from '@syncfusion/ej2-base';
    import { DropDownList } from '@syncfusion/ej2-dropdowns';
    import ProgressCircular from './ProgressCircular.vue';
    import GridDataService from '../services/logVoyageGrid.service';
    import {
        tools,
    } from '../helpers';
    import { eventBus } from '../main';

    loadCldr( 
        require('cldr-data/main/en-FI/ca-gregorian.json'), 
        require('cldr-data/main/en-FI/numbers.json'), 
        require('cldr-data/main/en-FI/timeZoneNames.json'), 
        require('cldr-data/supplemental/numberingSystems.json'),
        require('cldr-data/supplemental/weekData.json'),
    ); 

    L10n.load({
        'en-FI': { datetimepicker: { placeholder: 'Set date/time', today: 'Today' } }
    });

    Vue.use(DateTimePickerPlugin);
    Vue.use(GridPlugin);

    export default {
        name: 'LogVoyages',
        components: {
            ProgressCircular,
            Record,
        },
        data() {
            let dropInstance = {};
            return {
                data: [],
                state: null,
                pageSettings: { pageSizes: [10, 25, 50, 100, 200, 'All'], pageSize: 25 },
                editSettings: {
                    showDeleteConfirmDialog: true, allowEditOnDblClick: false, allowEditing: true, allowAdding: false, allowDeleting: true 
                },
                commands: [{ buttonOption: {content: "View", cssClass: "e-info custombutton" } } ],
                groupOptions: {},
                sortOptions: { columns: [{ field: 'etd', direction: 'Descending' }] },
                toolbar: ['Delete', 'Print', 'ColumnChooser', 'ExcelExport',
                    {
                        text: 'EEOI/CII report', tooltipText: 'Export EEOI and CII report to excel', prefixIcon: 'e-report', id: 'report' 
                    },
                ],
                intParams: { params: { decimals: 3, format: 'n' } },
                decimal2Params: { params: { decimals: 2 } },
                dataService: new GridDataService(),
                ddParams: { params: { dataSource: [] } },
                dpParams: { params: { locale: 'en-FI' } },
                filterOptions: { type: 'Menu' },
                filter: {
                    operator: 'equal',
                    ui: {
                        create(args) {
                            const flValInput = createElement('input', { className: 'flm-input' });
                            args.target.appendChild(flValInput);
                            const field = args.column.field;
                            if (field === 'name') {
                                const fields = { text: 'name', value: 'name' };
                                const dataService = new GridDataService();
                                dataService.getParam('ships').then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields,
                                        placeholder: 'Select ship',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'statusText') {
                                dropInstance = new DropDownList({
                                    dataSource: [
                                        { StatusText: 'Not completed ', status: 0 }, 
                                        { StatusText: 'Check', status: 1 }, 
                                        { StatusText: 'Auto OK', status: 2 },
                                        { StatusText: 'Editing', status: 3 },
                                        { StatusText: 'Verified OK', status: 4 },
                                        { StatusText: 'Webfocus error', status: 5 },
                                        { StatusText: 'Deleted', status: -1 }, 
                                    ],
                                    fields: { text: 'StatusText', value: 'status' },
                                    placeholder: 'Select status',
                                    popupHeight: '200px'
                                });
                                dropInstance.appendTo(flValInput);
                            } else if (field === 'route') {
                                const fields = { text: 'name', value: 'name' };
                                const dataService = new GridDataService();
                                dataService.getParam('routes').then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields,
                                        placeholder: 'Select route',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'yearmonth') {
                                const fields = { text: 'name', value: 'value' };
                                const dataService = new GridDataService();
                                dataService.getParam('etdmonths').then((data) => {
                                     dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields,
                                        placeholder: 'Select year/month',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            } else if (field === 'etdTime') {
                                const fields = { text: 'name', value: 'value' };
                                const dataService = new GridDataService();
                                dataService.getParam('etdtimes').then((data) => {
                                    data = data.map((o) => {
                                        o.name = moment.utc(`2020-01-01T${o.name}:00Z`).local().format('HH:mm');
                                        return o;
                                    });
                                    dropInstance = new DropDownList({
                                        dataSource: data,
                                        fields,
                                        placeholder: 'Select time',
                                        popupHeight: '200px'
                                    });
                                    dropInstance.appendTo(flValInput);
                                });
                            }  
                        },
                        write(args) {
                            console.log('WRITE', args.filteredValue);
                            dropInstance.value = args.filteredValue;
                        },
                        read(args) {
                            // if (args.column.field === 'etd') {
                                
                            // }
                            console.log('READ', args.column.field, args.operator, dropInstance.value);
                            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstance.value);
                        }
                    }
                },
                recordHistory: [],
                headers: [{
                    text: 'Time',
                    align: 'start',
                    value: 'eventTime',
                    },
                    {
                    text: 'Timezone',
                    value: 'timezone'
                    },
                    {
                    text: 'Name',
                    value: 'name'
                    },
                    {
                    text: 'Summary',
                    value: 'summary'
                    },
                    {
                    text: 'Author',
                    value: 'author'
                    },
                    {
                    text: 'Status',
                    value: 'statusText'
                    },
                    {
                    text: 'Version',
                    value: 'version'
                    },
                ],
                dialog: false,
                report: {
                    shipId: null,
                    startDate: moment.utc().subtract(7, 'days').format('YYYY-MM-DD'),
                    startTime: moment.utc().format('HH:mm'),
                    endDate: moment.utc().format('YYYY-MM-DD'),
                    endTime: moment.utc().format('HH:mm'),
                },
                startDateMenu: false,
                startTimeMenu: false,
                endDateMenu: false,
                endTimeMenu: false,
                times: ['Last week', 'Last month', 'Previous month', 'Last 3 months', 'Last 12 months', 'Last year', 'Year to date'],
                timespan: 'Last week',
            };
        },
        mounted() {
            setCulture('en-FI');
            const state = { skip: 0, take: 25 };
            this.dataStateChange(state);
            if (this.getShips().length === 0) {
                this.fetchShips().then((data) => { this.ddParams.params.dataSource = data; });
            } else {
                this.ddParams.params.dataSource = this.getShips();
            }
            this.toolbar.push({ text: 'Data API', tooltipText: 'Update voyage data from API', prefixIcon: 'e-apidata', id: 'apidata'}) 
            // Confirm edit save
            eventBus.$on('voyageUpdate', () => {
                console.log('voyageUpdate event received', this.state);    
                if (this.state) {
                    this.dataService.edit(this.state).then((res) => {
                        if (res === 1) {
                            this.$refs.grid.refresh();
                            this.$store.dispatch('alert/success', 'Voyage updated successfully.', { root: true });                            
                        } else if (res === 0) {
                            this.$store.dispatch('alert/error', 'Voyage update failed.', { root: true });
                        }
                    }); 
                    this.state = null;
                }
            });
            // Cancel edit save
            eventBus.$on('cancel', () => {
                if (this.state) {
                    this.state = null;
                    this.$refs.grid.refresh();
                }
            });
        },
        computed: {
            ...mapGetters({
                features: 'authentication/features',
                record: 'logbook/getRecord',
                template: 'logbook/getTemplate',
                firstDayOfWeek: 'data/getFirstDayOfWeek',
                ships: 'data/getShips',
            })
        },
        beforeDestroy() {
            eventBus.$off('voyageUpdate');
            eventBus.$off('cancel');
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getDynamic: 'data/getDynamic',
                getShips: 'data/getShips',
                getShip: 'logbook/getShip',
            }),
            ...mapActions({
                fetchShips: 'data/fetchShips',
                fetchRecordHistory: 'logbook/fetchRecordHistory',
                fetchHistoryRecord: 'logbook/fetchHistoryRecord',
                fetchReportData: 'logbook/fetchReportData',
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
                alertWarning: 'alert/warning',
                progressOn: 'data/progressOn',
                progressOff: 'data/progressOff',
                setHistoryRecord: 'logbook/setHistoryRecord',
            }),
            dataStateChange(state) {
                // console.log(state)
                if (state && state.action && state.action.requestType === 'filterchoicerequest') {
                    // state.action.filterModel.values['Not completed ', 'Auto OK'];
                } else { // Get data from backend
                    this.dataService.get(state).then((gridData) => {
                        setTimeout(() => {
                            this.data = gridData;
                        }, 10);
                    });
                }
            },
            dataSourceChanged(state) {
                console.log('dataSourceChanged', state.action, state)
                if (state.action === 'edit' && state.requestType === 'save') {
                    let edited = false;
                    for (const key in state.data) {
                        if (state.data.hasOwnProperty(key)) {
                            for (const key2 in state.rowData) {
                                if (state.rowData.hasOwnProperty(key2) && key === key2) {
                                    if ((key === 'atd' || key === 'etd' || key === 'ata' || key === 'eta') && state.data[key] !== state.rowData[key2] && moment.utc(state.data[key]).unix() !== moment.utc(state.rowData[key2]).unix()) {
                                        edited = true;
                                        console.log('Times updated!');
                                    } else if (key !== 'editTime' && key !== 'atd' && key !== 'etd' && key !== 'ata' && key !== 'eta' && (key === 'depLocode' || key === 'arrLocode' || key === 'route' || key === 'inPort' || key === 'type' || key === 'depCode') && !Object.is(state.data[key], NaN) && state.data[key] !== state.rowData[key2]) {
                                        edited = true;
                                        console.log('Parameters updated!');
                                    }
                                }
                            }
                        }
                    }
                    if (edited && state.requestType === 'save') {
                        // Eventbus not working properly. Confirm dialog commented out:
                        // this.state = state;
                        // this.alertConfirm({ message: 'Voyage data has been modified. Click confirm to update.', emit: 'voyageUpdate' });
                        // Complete edit right away:
                        this.dataService.edit(state).then((res) => {
                            if (res === 1) {
                                this.$refs.grid.refresh();
                                this.$store.dispatch('alert/success', 'Voyage updated successfully.', { root: true });                            
                            } else if (res === 0) {
                                this.$store.dispatch('alert/error', 'Voyage update failed.', { root: true });
                            }
                        }); 
                    } else {
                        this.$refs.grid.refresh();
                    }
                } else if (state.requestType === 'delete') {
                    this.dataService.delete(state).then((res) => {
                        if (res === 1) {
                            this.$store.dispatch('alert/success', 'Voyage deleted successfully.', { root: true });
                        } else if (res === 0) {
                            this.$store.dispatch('alert/error', 'Voyage delete failed.', { root: true });
                        } else if (res === -1) {
                            this.$store.dispatch('alert/error', 'Voyage id not valid.', { root: true });
                        }
                    }); 
                }
            },
            toolbarClick(args) {
                if (args.item.id.includes('excelexport')) {
                    const records = this.$refs.grid.dataSource.result;
                    const length = records.length;
                    for (let i = 0; i < length; i++) {
                        records[i].eta = records[i].eta ? moment(records[i].eta).format('Y-MM-DD HH:mm') : ''; // was 'D/M/Y HH:mm:ss'
                        records[i].ata = records[i].ata ? moment(records[i].ata).format('Y-MM-DD HH:mm') : '';
                        records[i].etd = records[i].etd ? moment(records[i].etd).format('Y-MM-DD HH:mm') : '';
                        records[i].atd = records[i].atd ? moment(records[i].atd).format('Y-MM-DD HH:mm') : '';
                        records[i].time = records[i].time ? moment(records[i].time).format('Y-MM-DD HH:mm') : '';
                        records[i].editTime = records[i].editTime ? moment(records[i].editTime).format('Y-MM-DD HH:mm') : '';
                        records[i].apiTime = records[i].apiTime ? moment(records[i].apiTime).format('Y-MM-DD HH:mm') : '';
                    }
                    const exportProperties = {
                        dataSource: records
                    };
                    // console.log('excel: ', exportProperties);
                    this.$refs.grid.excelExport(exportProperties);
                } else if (args.item.id === 'foc') {
                    const selectedRecords = this.$refs.grid.getSelectedRecords();  
                    if (selectedRecords && selectedRecords[0] && selectedRecords[0].id) {
                        this.progressOn();
                        this.dataService.update({ type: 'foc', id: selectedRecords[0].id }).then((res) => {
                            if (res === 1) {
                                this.$refs.grid.refresh();
                                this.$store.dispatch('alert/success', 'FOC re-calculated successfully.', { root: true });
                            } else if (res === 0) {
                                this.$store.dispatch('alert/error', 'Failed to re-calculate FOC.', { root: true });
                            }
                        }).finally(() => {
                            this.progressOff();
                        });
                    } else {
                        this.$store.dispatch('alert/error', 'Select voyage first to re-calculate FOC', { root: true });
                    }
                } else if (args.item.id === 'apidata') {
                    const selectedRecords = this.$refs.grid.getSelectedRecords(); 
                    if (selectedRecords && selectedRecords[0] && selectedRecords[0].id) {
                        if (!selectedRecords[0].dataApi) {
                            this.$store.dispatch('alert/error', 'Selected record has no data API parameter.', { root: true });
                        }
                        this.progressOn();
                        this.dataService.update({ type: 'webfocus', id: selectedRecords[0].id }).then((res) => {
                            if (res === 1) {
                                this.$refs.grid.refresh();
                                this.$store.dispatch('alert/success', 'Voyage data from API updated successfully.', { root: true });
                            } else if (res === 0) {
                                this.$store.dispatch('alert/error', 'Failed to update voyage data from API.', { root: true });
                            }
                        }).finally(() => {
                            this.progressOff();
                        });
                    } else {
                        this.$store.dispatch('alert/error', 'Select voyage first to update data from API.', { root: true });
                    }
                } else if (args.item.id === 'report') {
                    const shipId = localStorage.getItem('reportShipId');
                    if (shipId) {
                        this.report.shipId = parseInt(shipId);
                    }
                    this.dialog = true;
                }
            },
            excelQueryCellInfo(args) { 
                if (args.column.field === 'etd' || args.column.field === 'atd' || args.column.field === 'eta' || args.column.field === 'ata' || args.column.field === 'editTime') { 
                    args.value = moment(args.value, 'D/M/YYYY hh:mm:ss').isValid() ? moment(args.value, 'D/M/YYYY hh:mm:ss').toDate() : moment(args.value).isValid() ? moment(args.value).toDate() : args.value;
                } else if (args.column.field === 'depDelay' || args.column.field === 'arrDelay') {
                    if (args.value) {
                        let sign = 1;
                        if (args.value.includes('-')) {
                            args.value = args.value.substr(1, args.value.length);
                            sign = -1;
                        }
                        let x = args.value.split(':');
                        args.value = sign * (parseInt(x[0], 10) * 60 + parseInt(x[1], 10));
                    }
                } else if (args.column.field === 'inPort' || args.column.field === 'atSea') {
                    if (args.value) {
                        let x = args.value.split(':');
                        args.value = (parseInt(x[0], 10) + parseInt(x[1], 10)/60).toFixed(2);
                    }
                }
            },
            rowDataBound: function(args) {
                if (args.data.status < 0) {
                    args.row.classList.add('deleted');
                }
                if (args.data.portIssue) {
                    args.row.classList.add('issue');
                }
            },
            onCloseEditor() {
                console.log('Close event fired');
            },
            formatDate(date, timezone) {
                if (timezone) {
                return moment.utc(date).utcOffset(timezone).format('DD.MM HH:mm');
                } else {
                return moment.utc(date).format('DD.MM HH:mm');
                }
            },
            openRecord(item) {
                if (item && item.uuid) {
                    this.setHistoryRecord(true);
                    item.logDb = this.getShip().id;
                    this.fetchHistoryRecord(item);
                }
            },
            setTimeSpan(timespan) {
                //times: ['Last week', 'Last month', 'Last 3 months', 'Last 12 months', 'Last year', 'Year to date'],
                if (timespan === 'Last week') {
                    this.report.startDate = moment.utc().subtract(7, 'days').format('YYYY-MM-DD');
                    this.report.startTime = moment.utc().format('HH:mm');
                    this.report.endDate = moment.utc().format('YYYY-MM-DD');
                    this.report.endTime = moment.utc().format('HH:mm');
                } else if (timespan === 'Last month') {
                    this.report.startDate = moment.utc().subtract(1, 'months').format('YYYY-MM-DD');
                    this.report.startTime = moment.utc().format('HH:mm');
                    this.report.endDate = moment.utc().format('YYYY-MM-DD');
                    this.report.endTime = moment.utc().format('HH:mm');
                } else if (timespan === 'Previous month') {
                    this.report.startDate = moment.utc().subtract(1, 'months').date(1).format('YYYY-MM-DD');
                    this.report.startTime = '00:00';
                    this.report.endDate = moment.utc().date(1).format('YYYY-MM-DD');
                    this.report.endTime = '00:00';
                } else if (timespan === 'Last 3 months') {
                    this.report.startDate = moment.utc().subtract(3, 'months').format('YYYY-MM-DD');
                    this.report.startTime = moment.utc().format('HH:mm');
                    this.report.endDate = moment.utc().format('YYYY-MM-DD');
                    this.report.endTime = moment.utc().format('HH:mm');
                } else if (timespan === 'Last 12 months') {
                    this.report.startDate = moment.utc().subtract(12, 'months').format('YYYY-MM-DD');
                    this.report.startTime = moment.utc().format('HH:mm');
                    this.report.endDate = moment.utc().format('YYYY-MM-DD');
                    this.report.endTime = moment.utc().format('HH:mm');
                } else if (timespan === 'Last year') {
                    this.report.startDate = moment.utc().subtract(1, 'years').month(0).date(1).format('YYYY-MM-DD');
                    this.report.startTime = '00:00';
                    this.report.endDate = moment.utc().subtract(1, 'years').month(11).date(31).format('YYYY-MM-DD');
                    this.report.endTime = '23:59';
                } else if (timespan === 'Year to date') {
                    this.report.startDate = moment.utc().month(0).date(1).format('YYYY-MM-DD');
                    this.report.startTime = '00:00';
                    this.report.endDate = moment.utc().format('YYYY-MM-DD');
                    this.report.endTime = moment.utc().format('HH:mm');
                }
            },
            setShip(shipId) {
                localStorage.setItem('reportShipId', shipId);
            },
            getReport(report) {
                const ship = this.ships.find(o => o.id === report.shipId)
                if (ship && ship.id) {
                    this.fetchReportData(report).then((data) => {
                        if (!data || data.length === 0) {
                            this.alertWarning('No data found for given timespan.');
                        } else {
                            data = tools.parseLogVoyageData(data, ship, false);
                            // get YTD values from yearly data
                            const co2Total = data.map(o => o.co2Total || 0);
                            const co2TotalSum = Math.round(co2Total.reduce((a, b) => a + b, 0) * 100) / 100;

                            const distance = data.map(o => o.distance || 0);
                            const distanceSum = Math.round(distance.reduce((a, b) => a + b, 0) * 100) / 100;

                            const co2CumulativeTotal = co2TotalSum;
                            const distanceCumulativeTotal = distanceSum;
                            const ciiIceClassCorrection = ship && ship.ciiIceClassCorrection ? ship.ciiIceClassCorrection : 1.05;
                            
                            const ciiTotal = distanceCumulativeTotal ? Math.round((co2CumulativeTotal * 1000 * 1000) / (distanceCumulativeTotal * ship.gt * ciiIceClassCorrection) * 100) / 100 : 0;
                            const ytdValues = {co2CumulativeTotal, distanceCumulativeTotal, ciiTotal};
                            // Filter out entries before start time
                            data = data.filter((o) => moment.utc(o.etd).unix() >= moment.utc(report.startDate + ' ' + report.startTime).unix());      
                            this.createEeoiExcel(data, ship);
                            this.createCiiExcel(data, ship, ytdValues);
                        }
                    })
                }
            },
            async createEeoiExcel(data, ship) {
                const headerRow = [{ value: 'Status', fontWeight: 'bold'}, {value: 'IMO', fontWeight: 'bold'}, {value: 'Ship', fontWeight: 'bold'}, {value: 'Type', fontWeight: 'bold'}, 
                    {value: 'Route', fontWeight: 'bold'}, {value: 'From', fontWeight: 'bold'}, {value: 'To', fontWeight: 'bold'}, {value: 'ETD', fontWeight: 'bold'}, {value: 'ETD time', fontWeight: 'bold'}, 
                    {value: 'ATD', fontWeight: 'bold'}, {value: 'Dep delay', fontWeight: 'bold'}, {value: 'Dep delay reason', fontWeight: 'bold'}, {value: 'ETA', fontWeight: 'bold'}, {value: 'ATA', fontWeight: 'bold'}, 
                    {value: 'Arr delay', fontWeight: 'bold'}, {value: 'Arr delay reason', fontWeight: 'bold'}, {value: 'At sea', fontWeight: 'bold'}, {value: 'In port', fontWeight: 'bold'}, 
                    {value: 'Anchor hrs', fontWeight: 'bold'}, {value: 'PAX', fontWeight: 'bold'}, {value: 'Cars', fontWeight: 'bold'}, {value: 'Car [m]', fontWeight: 'bold'}, {value: 'Cargo', fontWeight: 'bold'}, 
                    {value: 'Cargo [m]', fontWeight: 'bold'}, {value: 'Cargo [t]', fontWeight: 'bold'}, {value: 'FOC ME sea', fontWeight: 'bold'}, {value: 'FOC ME port', fontWeight: 'bold'}, 
                    {value: 'FOC Aux sea', fontWeight: 'bold'}, {value: 'FOC Aux port', fontWeight: 'bold'}, {value: 'FOC Boiler sea', fontWeight: 'bold'}, {value: 'FOC Boiler port', fontWeight: 'bold'}, 
                    {value: 'FOC sea', fontWeight: 'bold'}, {value: 'FOC port', fontWeight: 'bold'}, {value: 'FOC total', fontWeight: 'bold'}, 
                    {value: 'Fuel 1 type', fontWeight: 'bold'}, {value: 'Fuel 1 CO2', fontWeight: 'bold'}, {value: 'Fuel 1 sea [t]', fontWeight: 'bold'}, {value: 'Fuel 1 sea CO2 [t]', fontWeight: 'bold'}, 
                    {value: 'Fuel 1 port [t]', fontWeight: 'bold'}, {value: 'Fuel 1 port CO2 [t]', fontWeight: 'bold'},
                    
                    {value: 'Fuel 1 ME FOC sea [t]', fontWeight: 'bold'}, {value: 'Fuel 1 ME FOC port [t]', fontWeight: 'bold'}, 
                    {value: 'Fuel 1 AUX FOC sea [t]', fontWeight: 'bold'}, {value: 'Fuel 1 AUX FOC port [t]', fontWeight: 'bold'},
                    {value: 'Fuel 1 Boiler FOC sea [t]', fontWeight: 'bold'}, {value: 'Fuel 1 Boiler FOC port [t]', fontWeight: 'bold'},

                    {value: 'Fuel 2 type', fontWeight: 'bold'}, {value: 'Fuel 2 CO2', fontWeight: 'bold'}, {value: 'Fuel 2 sea [t]', fontWeight: 'bold'}, {value: 'Fuel 2 sea CO2 [t]', fontWeight: 'bold'}, 
                    {value: 'Fuel 2 port [t]', fontWeight: 'bold'}, {value: 'Fuel 2 port CO2 [t]', fontWeight: 'bold'}, 

                    {value: 'Fuel 2 ME FOC sea [t]', fontWeight: 'bold'}, {value: 'Fuel 2 ME FOC port [t]', fontWeight: 'bold'}, 
                    {value: 'Fuel 2 AUX FOC sea [t]', fontWeight: 'bold'}, {value: 'Fuel 2 AUX FOC port [t]', fontWeight: 'bold'},
                    {value: 'Fuel 2 Boiler FOC sea [t]', fontWeight: 'bold'}, {value: 'Fuel 2 Boiler FOC port [t]', fontWeight: 'bold'},

                    {value: 'Fuel 3 type', fontWeight: 'bold'}, {value: 'Fuel 3 CO2', fontWeight: 'bold'}, {value: 'Fuel 3 sea [t]', fontWeight: 'bold'}, {value: 'Fuel 3 sea CO2 [t]', fontWeight: 'bold'}, 
                    {value: 'Fuel 3 port [t]', fontWeight: 'bold'}, {value: 'Fuel 3 port CO2 [t]', fontWeight: 'bold'}, 

                    {value: 'Fuel 3 ME FOC sea [t]', fontWeight: 'bold'}, {value: 'Fuel 3 ME FOC port [t]', fontWeight: 'bold'}, 
                    {value: 'Fuel 3 AUX FOC sea [t]', fontWeight: 'bold'}, {value: 'Fuel 3 AUX FOC port [t]', fontWeight: 'bold'},
                    {value: 'Fuel 3 Boiler FOC sea [t]', fontWeight: 'bold'}, {value: 'Fuel 3 Boiler FOC port [t]', fontWeight: 'bold'},

                    {value: 'CO2 sea', fontWeight: 'bold'}, {value: 'CO2 port', fontWeight: 'bold'}, {value: 'CO2 total', fontWeight: 'bold'}, 

                    {value: 'SC power', fontWeight: 'bold'}, 
                    {value: 'Fuel 1 LCV EU', fontWeight: 'bold'}, {value: 'Fuel 1 LCV IMO', fontWeight: 'bold'}, 
                    {value: 'Fuel 2 LCV EU', fontWeight: 'bold'}, {value: 'Fuel 2 LCV IMO', fontWeight: 'bold'}, 
                    {value: 'Fuel 3 LCV EU', fontWeight: 'bold'}, {value: 'Fuel 3 LCV IMO', fontWeight: 'bold'}, 

                    {value: 'Propulsion power [MWh]', fontWeight: 'bold'}, {value: 'Hotel power [MWh]', fontWeight: 'bold'}, {value: 'NM', fontWeight: 'bold'}, {value: 'kg/NM', fontWeight: 'bold'}, 
                    {value: 'EEOI pax', fontWeight: 'bold'}, {value: 'EEOI tons', fontWeight: 'bold'}, {value: 'EEOI GT', fontWeight: 'bold'}, {value: 'Pax transport work [pax*NM]', fontWeight: 'bold'}, 
                    {value: 'Cargo transport work [t*NM]', fontWeight: 'bold'}, {value: 'TWS [m/s]', fontWeight: 'bold'}, {value: 'Head wind [m/s]', fontWeight: 'bold'}, {value: 'Year/month', fontWeight: 'bold'}, 
                    {value: 'Signer', fontWeight: 'bold'}, {value: 'Author', fontWeight: 'bold'}, {value: 'Updated', fontWeight: 'bold'}, {value: 'Notes', fontWeight: 'bold'}]
                const rows = [headerRow];
                
                for (const v of data) {
                    const etd = v.etd ? {type: Date, value: moment.utc(v.etd).toDate(), format: 'dd.mm.yyyy hh:mm'} : {type: String, value: ''};
                    const atd = v.atd ? {type: Date, value: moment.utc(v.atd).toDate(), format: 'dd.mm.yyyy hh:mm'} : {type: String, value: ''};
                    const eta = v.eta ? {type: Date, value: moment.utc(v.eta).toDate(), format: 'dd.mm.yyyy hh:mm'} : {type: String, value: ''};
                    const ata = v.ata ? {type: Date, value: moment.utc(v.ata).toDate(), format: 'dd.mm.yyyy hh:mm'} : {type: String, value: ''};
                    rows.push([
                    {type: String, value: v.statusText},
                    {type: Number, value: parseInt(ship.imo)},
                    {type: String, value: v.name},
                    {type: String, value: v.type},
                    {type: String, value: v.route},
                    {type: String, value: v.depLocode},
                    {type: String, value: v.arrLocode},
                    etd,
                    {type: String, value: v.etdTime},
                    atd,
                    {type: String, value: v.depDelay},
                    {type: String, value: v.depDelayReason},
                    eta,
                    ata,
                    {type: String, value: v.arrDelay},
                    {type: String, value: v.arrDelayReason},
                    {type: String, value: v.atSea ? v.atSea : ' '},
                    {type: String, value: v.inPort ? v.inPort : ' '},
                    {type: Number, value: v.anchorHours},
                    {type: Number, value: v.pax}, 
                    {type: Number, value: v.carUnits},
                    {type: Number, value: v.carMeters},
                    {type: Number, value: v.cargoUnits},
                    {type: Number, value: v.cargoMeters},
                    {type: Number, value: v.cargoTons},
                    {type: Number, value: v.focMeSea},
                    {type: Number, value: v.focMePort},
                    {type: Number, value: v.focAuxSea},
                    {type: Number, value: v.focAuxPort},
                    {type: Number, value: v.focBoilerSea},
                    {type: Number, value: v.focBoilerPort},
                    {type: Number, value: v.focSea},
                    {type: Number, value: v.focPort},
                    {type: Number, value: v.focTotal},
                    {type: String, value: v.fuel1Type},
                    {type: Number, value: v.fuel1Co2},
                    {type: Number, value: v.fuel1FocSea},
                    {type: Number, value: v.fuel1Co2Sea},
                    {type: Number, value: v.fuel1FocPort},
                    {type: Number, value: v.fuel1Co2Port},
                    {type: Number, value: v.fuel1MeFocSea},
                    {type: Number, value: v.fuel1MeFocPort},
                    {type: Number, value: v.fuel1AuxFocSea},
                    {type: Number, value: v.fuel1AuxFocPort},
                    {type: Number, value: v.fuel1BoilerFocSea},
                    {type: Number, value: v.fuel1BoilerFocPort},
                    {type: String, value: v.fuel2Type},
                    {type: Number, value: v.fuel2Co2},
                    {type: Number, value: v.fuel2FocSea},
                    {type: Number, value: v.fuel2Co2Sea},
                    {type: Number, value: v.fuel2FocPort},
                    {type: Number, value: v.fuel2Co2Port},
                    {type: Number, value: v.fuel2MeFocSea},
                    {type: Number, value: v.fuel2MeFocPort},
                    {type: Number, value: v.fuel2AuxFocSea},
                    {type: Number, value: v.fuel2AuxFocPort},
                    {type: Number, value: v.fuel2BoilerFocSea},
                    {type: Number, value: v.fuel2BoilerFocPort},
                    {type: String, value: v.fuel3Type},
                    {type: Number, value: v.fuel3Co2},
                    {type: Number, value: v.fuel3FocSea},
                    {type: Number, value: v.fuel3Co2Sea},
                    {type: Number, value: v.fuel3FocPort},
                    {type: Number, value: v.fuel3Co2Port},
                    {type: Number, value: v.fuel3MeFocSea},
                    {type: Number, value: v.fuel3MeFocPort},
                    {type: Number, value: v.fuel3AuxFocSea},
                    {type: Number, value: v.fuel3AuxFocPort},
                    {type: Number, value: v.fuel3BoilerFocSea},
                    {type: Number, value: v.fuel3BoilerFocPort},
                    {type: Number, value: v.co2Sea},
                    {type: Number, value: v.co2Port},
                    {type: Number, value: v.co2Total},
                    {type: Number, value: v.scPower},
                    {type: Number, value: v.fuel1lcvEu},
                    {type: Number, value: v.fuel1lcvImo},
                    {type: Number, value: v.fuel2lcvEu},
                    {type: Number, value: v.fuel2lcvImo},
                    {type: Number, value: v.fuel3lcvEu},
                    {type: Number, value: v.fuel3lcvImo},
                    {type: Number, value: v.propulsionPower},
                    {type: Number, value: v.hotelPower},
                    {type: Number, value: v.distance},
                    {type: Number, value: v.cons},
                    {type: Number, value: v.eeoiPax},
                    {type: Number, value: v.eeoiTons},
                    {type: Number, value: v.eeoiGt},
                    {type: Number, value: v.transportWorkPax},
                    {type: Number, value: v.transportWorkCargo},
                    {type: Number, value: v.tws},
                    {type: Number, value: v.headwind},
                    {type: String, value: v.yearmonth},
                    {type: String, value: v.signer},
                    {type: String, value: v.editorName},
                    {type: Date, value: v.time ? moment.utc(v.time).toDate() : ' ', format: 'dd.mm.yyyy hh:mm'},
                    {type: String, value: v.notes},
                    ])
                }
                // Calculate AVG and SUM values
                const depDelays = data.map(o => {
                    if (o.depDelay && o.depDelay !== '00:00') {
                        const values = o.depDelay.split(':');
                        const sign = values[0].includes('-') ? -1 : 1;
                        return sign * (parseInt(values[0]) * 60 + parseInt(values[1]));
                    } else {
                        return 0;
                    }
                });
                const depDelaySum = depDelays.reduce((a, b) => a + b, 0);
                const depDelayAvg = Math.round(((depDelaySum / depDelays.length) || 0) * 10) / 10;

                const arrDelays = data.map(o => {
                    if (o.arrDelay && o.arrDelay !== '00:00') {
                        const values = o.arrDelay.split(':');
                        const sign = values[0].includes('-') ? -1 : 1;
                        return sign * (parseInt(values[0]) * 60 + parseInt(values[1]));
                    } else {
                        return 0;
                    }
                });
                const arrDelaySum = arrDelays.reduce((a, b) => a + b, 0);
                const arrDelayAvg = Math.round(((arrDelaySum / arrDelays.length) || 0) * 10) / 10;

                const atSeas = data.map(o => {
                    if (o.atSea && o.atSea !== '00:00') {
                        const values = o.atSea.split(':');
                        return parseInt(values[0]) * 60 + parseInt(values[1]);
                    } else {
                        return 0;
                    }
                });
                const atSeaSum = Math.round(atSeas.reduce((a, b) => a + b, 0) / 60 * 10) / 10; // in hours

                const inPorts = data.map(o => {
                    if (o.inPort && o.inPort !== '00:00') {
                        const values = o.inPort.split(':');
                        return parseInt(values[0]) * 60 + parseInt(values[1]);
                    } else {
                        return 0;
                    }
                });
                const inPortSum = Math.round(inPorts.reduce((a, b) => a + b, 0) / 60 * 10) / 10; // in hours

                const anchorHours = data.map(o => o.anchorHours || 0);
                const anchorHoursSum = Math.round(anchorHours.reduce((a, b) => a + b, 0) * 10) / 10;

                const paxs = data.map(o => o.pax || 0);
                const paxSum = Math.round(paxs.reduce((a, b) => a + b, 0));
                const carUnits = data.map(o => o.carUnits || 0);
                const carUnitsSum = Math.round(carUnits.reduce((a, b) => a + b, 0));
                const carMeters = data.map(o => o.carMeters || 0);
                const carMetersSum = Math.round(carMeters.reduce((a, b) => a + b, 0) * 10) / 10 ;

                const cargoUnits = data.map(o => o.cargoUnits || 0);
                const cargoUnitsSum = Math.round(cargoUnits.reduce((a, b) => a + b, 0));
                const cargoMeters = data.map(o => o.cargoMeters || 0);
                const cargoMetersSum = Math.round(cargoMeters.reduce((a, b) => a + b, 0) * 10) / 10;
                const cargoTons = data.map(o => o.cargoTons || 0);
                const cargoTonsSum = Math.round(cargoTons.reduce((a, b) => a + b, 0) * 100) / 100;

                const focMeSea = data.map(o => o.focMeSea || 0);
                const focMeSeaSum = Math.round(focMeSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const focMePort = data.map(o => o.focMePort || 0);
                const focMePortSum = Math.round(focMePort.reduce((a, b) => a + b, 0) * 100) / 100;

                const focAuxSea = data.map(o => o.focAuxSea || 0);
                const focAuxSeaSum = Math.round(focAuxSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const focAuxPort = data.map(o => o.focAuxPort || 0);
                const focAuxPortSum = Math.round(focAuxPort.reduce((a, b) => a + b, 0) * 100) / 100;

                const focBoilerSea = data.map(o => o.focBoilerSea || 0);
                const focBoilerSeaSum = Math.round(focBoilerSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const focBoilerPort = data.map(o => o.focBoilerPort || 0);
                const focBoilerPortSum = Math.round(focBoilerPort.reduce((a, b) => a + b, 0) * 100) / 100;
                
                const focSea = data.map(o => o.focSea || 0);
                const focSeaSum = Math.round(focSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const focPort = data.map(o => o.focPort || 0);
                const focPortSum = Math.round(focPort.reduce((a, b) => a + b, 0) * 100) / 100;
                const focTotal = data.map(o => o.focTotal || 0);
                const focTotalSum = Math.round(focTotal.reduce((a, b) => a + b, 0) * 100) / 100;

                const fuel1FocSea = data.map(o => o.fuel1FocSea || 0);
                const fuel1FocSeaSum = Math.round(fuel1FocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel1Co2Sea = data.map(o => o.fuel1Co2Sea || 0);
                const fuel1Co2SeaSum = Math.round(fuel1Co2Sea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel1FocPort = data.map(o => o.fuel1FocPort || 0);
                const fuel1FocPortSum = Math.round(fuel1FocPort.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel1Co2Port = data.map(o => o.fuel1Co2Port || 0);
                const fuel1Co2PortSum = Math.round(fuel1Co2Port.reduce((a, b) => a + b, 0) * 100) / 100;

                const fuel1MeFocSea = data.map(o => o.fuel1MeFocSea || 0);
                const fuel1MeFocSeaSum = Math.round(fuel1MeFocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel1MeFocPort = data.map(o => o.fuel1MeFocPort || 0);
                const fuel1MeFocPortSum = Math.round(fuel1MeFocPort.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel1AuxFocSea = data.map(o => o.fuel1AuxFocSea || 0);
                const fuel1AuxFocSeaSum = Math.round(fuel1AuxFocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel1AuxFocPort = data.map(o => o.fuel1AuxFocPort || 0);
                const fuel1AuxFocPortSum = Math.round(fuel1AuxFocPort.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel1BoilerFocSea = data.map(o => o.fuel1BoilerFocSea || 0);
                const fuel1BoilerFocSeaSum = Math.round(fuel1BoilerFocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel1BoilerFocPort = data.map(o => o.fuel1BoilerFocPort || 0);
                const fuel1BoilerFocPortSum = Math.round(fuel1BoilerFocPort.reduce((a, b) => a + b, 0) * 100) / 100;

                const fuel2FocSea = data.map(o => o.fuel2FocSea || 0);
                const fuel2FocSeaSum = Math.round(fuel2FocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel2Co2Sea = data.map(o => o.fuel2Co2Sea || 0);
                const fuel2Co2SeaSum = Math.round(fuel2Co2Sea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel2FocPort = data.map(o => o.fuel2FocPort || 0);
                const fuel2FocPortSum = Math.round(fuel2FocPort.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel2Co2Port = data.map(o => o.fuel2Co2Port || 0);
                const fuel2Co2PortSum = Math.round(fuel2Co2Port.reduce((a, b) => a + b, 0) * 100) / 100;

                const fuel2MeFocSea = data.map(o => o.fuel2MeFocSea || 0);
                const fuel2MeFocSeaSum = Math.round(fuel2MeFocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel2MeFocPort = data.map(o => o.fuel2MeFocPort || 0);
                const fuel2MeFocPortSum = Math.round(fuel2MeFocPort.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel2AuxFocSea = data.map(o => o.fuel2AuxFocSea || 0);
                const fuel2AuxFocSeaSum = Math.round(fuel2AuxFocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel2AuxFocPort = data.map(o => o.fuel2AuxFocPort || 0);
                const fuel2AuxFocPortSum = Math.round(fuel2AuxFocPort.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel2BoilerFocSea = data.map(o => o.fuel2BoilerFocSea || 0);
                const fuel2BoilerFocSeaSum = Math.round(fuel2BoilerFocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel2BoilerFocPort = data.map(o => o.fuel2BoilerFocPort || 0);
                const fuel2BoilerFocPortSum = Math.round(fuel2BoilerFocPort.reduce((a, b) => a + b, 0) * 100) / 100;

                const fuel3FocSea = data.map(o => o.fuel3FocSea || 0);
                const fuel3FocSeaSum = Math.round(fuel3FocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel3Co2Sea = data.map(o => o.fuel3Co2Sea || 0);
                const fuel3Co2SeaSum = Math.round(fuel3Co2Sea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel3FocPort = data.map(o => o.fuel3FocPort || 0);
                const fuel3FocPortSum = Math.round(fuel3FocPort.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel3Co2Port = data.map(o => o.fuel3Co2Port || 0);
                const fuel3Co2PortSum = Math.round(fuel3Co2Port.reduce((a, b) => a + b, 0) * 100) / 100;

                const fuel3MeFocSea = data.map(o => o.fuel3MeFocSea || 0);
                const fuel3MeFocSeaSum = Math.round(fuel3MeFocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel3MeFocPort = data.map(o => o.fuel3MeFocPort || 0);
                const fuel3MeFocPortSum = Math.round(fuel3MeFocPort.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel3AuxFocSea = data.map(o => o.fuel3AuxFocSea || 0);
                const fuel3AuxFocSeaSum = Math.round(fuel3AuxFocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel3AuxFocPort = data.map(o => o.fuel3AuxFocPort || 0);
                const fuel3AuxFocPortSum = Math.round(fuel3AuxFocPort.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel3BoilerFocSea = data.map(o => o.fuel3BoilerFocSea || 0);
                const fuel3BoilerFocSeaSum = Math.round(fuel3BoilerFocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel3BoilerFocPort = data.map(o => o.fuel3BoilerFocPort || 0);
                const fuel3BoilerFocPortSum = Math.round(fuel3BoilerFocPort.reduce((a, b) => a + b, 0) * 100) / 100;

                const co2Sea = data.map(o => o.co2Sea || 0);
                const co2SeaSum = Math.round(co2Sea.reduce((a, b) => a + b, 0) * 100) / 100;
                const co2Port = data.map(o => o.co2Port || 0);
                const co2PortSum = Math.round(co2Port.reduce((a, b) => a + b, 0) * 100) / 100;
                const co2Total = data.map(o => o.co2Total || 0);
                const co2TotalSum = Math.round(co2Total.reduce((a, b) => a + b, 0) * 100) / 100;

                const scPower = data.map(o => o.scPower || 0);
                const scPowerSum = Math.round(scPower.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel1lcvEu = data.map(o => o.fuel1lcvEu || 0);
                const fuel1lcvEuSum = Math.round(fuel1lcvEu.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel1lcvImo = data.map(o => o.fuel1lcvImo || 0);
                const fuel1lcvImoSum = Math.round(fuel1lcvImo.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel2lcvEu = data.map(o => o.fuel2lcvEu || 0);
                const fuel2lcvEuSum = Math.round(fuel2lcvEu.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel2lcvImo = data.map(o => o.fuel2lcvImo || 0);
                const fuel2lcvImoSum = Math.round(fuel2lcvImo.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel3lcvEu = data.map(o => o.fuel3lcvEu || 0);
                const fuel3lcvEuSum = Math.round(fuel3lcvEu.reduce((a, b) => a + b, 0) * 100) / 100;
                const fuel3lcvImo = data.map(o => o.fuel3lcvImo || 0);
                const fuel3lcvImoSum = Math.round(fuel3lcvImo.reduce((a, b) => a + b, 0) * 100) / 100;

                const propulsionPower = data.map(o => o.propulsionPower);
                const propulsionPowerSum = Math.round(propulsionPower.reduce((a, b) => a + b, 0) * 100) / 100;
                const hotelPower = data.map(o => o.hotelPower);
                const hotelPowerSum = Math.round(hotelPower.reduce((a, b) => a + b, 0) * 100) / 100;

                const distance = data.map(o => o.distance || 0);
                const distanceSum = Math.round(distance.reduce((a, b) => a + b, 0) * 100) / 100;
                const cons = data.map(o => o.cons || 0);
                const consSum = Math.round(cons.reduce((a, b) => a + b, 0) * 100) / 100;
                const consAvg = Math.round(((consSum / cons.length) || 0) * 100) / 100;

                const transportWorkPax = data.map(o => o.transportWorkPax || 0);
                const transportWorkPaxSum = Math.round(transportWorkPax.reduce((a, b) => a + b, 0) * 100) / 100;
                const transportWorkCargo = data.map(o => o.transportWorkCargo || 0);
                const transportWorkCargoSum = Math.round(transportWorkCargo.reduce((a, b) => a + b, 0) * 100) / 100;

                const eeoiPaxTotal = Math.round((co2SeaSum * 1000000 / transportWorkPaxSum) * 100) / 100;
                const eeoiTonsTotal = Math.round((co2SeaSum * 1000000 / transportWorkCargoSum) * 100) / 100
                const eeoiGtTotal = Math.round((co2SeaSum * 1000000 / (ship.gt * distanceSum)) * 100) / 100;
                
                const tws = data.map(o => o.tws || 0);
                const twsSum = Math.round(tws.reduce((a, b) => a + b, 0) * 100) / 100;
                const twsAvg = Math.round(((twsSum / tws.length) || 0) * 100) / 100;
                const headwind = data.map(o => o.headwind || 0);
                const headwindSum = Math.round(headwind.reduce((a, b) => a + b, 0) * 100) / 100;
                const headwindAvg = Math.round(((headwindSum / headwind.length) || 0) * 100) / 100;


                rows.push([
                    {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, 
                    {type: Number, value: depDelayAvg, fontWeight: 'bold'}, {value: ''}, {value: ''}, {value: ''}, {type: Number, value: arrDelayAvg, fontWeight: 'bold'},
                    {value: ''}, {type: Number, value: atSeaSum, fontWeight: 'bold'}, {type: Number, value: inPortSum, fontWeight: 'bold'}, {type: Number, value: anchorHoursSum, fontWeight: 'bold'},
                    {type: Number, value: paxSum, fontWeight: 'bold'}, {type: Number, value: carUnitsSum, fontWeight: 'bold'}, {type: Number, value: carMetersSum, fontWeight: 'bold'},
                    {type: Number, value: cargoUnitsSum, fontWeight: 'bold'}, {type: Number, value: cargoMetersSum, fontWeight: 'bold'}, {type: Number, value: cargoTonsSum, fontWeight: 'bold'},
                    {type: Number, value: focMeSeaSum, fontWeight: 'bold'}, {type: Number, value: focMePortSum, fontWeight: 'bold'}, 
                    {type: Number, value: focAuxSeaSum, fontWeight: 'bold'}, {type: Number, value: focAuxPortSum, fontWeight: 'bold'}, 
                    {type: Number, value: focBoilerSeaSum, fontWeight: 'bold'}, {type: Number, value: focBoilerPortSum, fontWeight: 'bold'}, 
                    {type: Number, value: focSeaSum, fontWeight: 'bold'}, {type: Number, value: focPortSum, fontWeight: 'bold'}, {type: Number, value: focTotalSum, fontWeight: 'bold'},
                    {value: ''}, {value: ''}, {type: Number, value: fuel1FocSeaSum, fontWeight: 'bold'}, {type: Number, value: fuel1Co2SeaSum, fontWeight: 'bold'}, {type: Number, value: fuel1FocPortSum, fontWeight: 'bold'}, {type: Number, value: fuel1Co2PortSum, fontWeight: 'bold'}, 
                    {type: Number, value: fuel1MeFocSeaSum, fontWeight: 'bold'}, {type: Number, value: fuel1MeFocPortSum, fontWeight: 'bold'}, {type: Number, value: fuel1AuxFocSeaSum, fontWeight: 'bold'}, 
                    {type: Number, value: fuel1AuxFocPortSum, fontWeight: 'bold'}, {type: Number, value: fuel1BoilerFocSeaSum, fontWeight: 'bold'}, {type: Number, value: fuel1BoilerFocPortSum, fontWeight: 'bold'}, 
                    {value: ''}, {value: ''}, {type: Number, value: fuel2FocSeaSum, fontWeight: 'bold'}, {type: Number, value: fuel2Co2SeaSum, fontWeight: 'bold'}, {type: Number, value: fuel2FocPortSum, fontWeight: 'bold'}, {type: Number, value: fuel2Co2PortSum, fontWeight: 'bold'}, 
                    {type: Number, value: fuel2MeFocSeaSum, fontWeight: 'bold'}, {type: Number, value: fuel2MeFocPortSum, fontWeight: 'bold'}, {type: Number, value: fuel2AuxFocSeaSum, fontWeight: 'bold'}, 
                    {type: Number, value: fuel2AuxFocPortSum, fontWeight: 'bold'}, {type: Number, value: fuel2BoilerFocSeaSum, fontWeight: 'bold'}, {type: Number, value: fuel2BoilerFocPortSum, fontWeight: 'bold'}, 
                    {value: ''}, {value: ''}, {type: Number, value: fuel3FocSeaSum, fontWeight: 'bold'}, {type: Number, value: fuel3Co2SeaSum, fontWeight: 'bold'}, {type: Number, value: fuel3FocPortSum, fontWeight: 'bold'}, {type: Number, value: fuel3Co2PortSum, fontWeight: 'bold'}, 
                    {type: Number, value: fuel3MeFocSeaSum, fontWeight: 'bold'}, {type: Number, value: fuel3MeFocPortSum, fontWeight: 'bold'}, {type: Number, value: fuel3AuxFocSeaSum, fontWeight: 'bold'}, 
                    {type: Number, value: fuel3AuxFocPortSum, fontWeight: 'bold'}, {type: Number, value: fuel3BoilerFocSeaSum, fontWeight: 'bold'}, {type: Number, value: fuel3BoilerFocPortSum, fontWeight: 'bold'}, 
                    {type: Number, value: co2SeaSum, fontWeight: 'bold'}, {type: Number, value: co2PortSum, fontWeight: 'bold'}, {type: Number, value: co2TotalSum, fontWeight: 'bold'}, 

                    {type: Number, value: scPowerSum, fontWeight: 'bold'}, {type: Number, value: fuel1lcvEuSum, fontWeight: 'bold'}, 
                    {type: Number, value: fuel1lcvImoSum, fontWeight: 'bold'}, {type: Number, value: fuel2lcvEuSum, fontWeight: 'bold'}, 
                    {type: Number, value: fuel2lcvImoSum, fontWeight: 'bold'}, {type: Number, value: fuel3lcvEuSum, fontWeight: 'bold'}, 
                    {type: Number, value: fuel3lcvImoSum, fontWeight: 'bold'},  

                    {type: Number, value: propulsionPowerSum, fontWeight: 'bold'}, {type: Number, value: hotelPowerSum, fontWeight: 'bold'}, 
                    {type: Number, value: distanceSum, fontWeight: 'bold'}, {type: Number, value: consAvg, fontWeight: 'bold'}, 
                    {type: Number, value: eeoiPaxTotal, fontWeight: 'bold'}, {type: Number, value: eeoiTonsTotal, fontWeight: 'bold'}, {type: Number, value: eeoiGtTotal, fontWeight: 'bold'}, 
                    {type: Number, value: transportWorkPaxSum, fontWeight: 'bold'}, {type: Number, value: transportWorkCargoSum, fontWeight: 'bold'},
                    {type: Number, value: twsAvg, fontWeight: 'bold'}, {type: Number, value: headwindAvg, fontWeight: 'bold'}
                ])
                // AVG text row
                rows.push([
                    {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, 
                    {value: 'AVG', align: 'right'}, {value: ''}, {value: ''}, {value: ''}, {value: 'AVG', align: 'right'},
                    {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''},
                    {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''},
                    {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''},
                    {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''},
                    {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''}, 

                    {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''},  {value: ''}, 
                    {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''},  {value: ''}, 
                    {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''},  {value: ''}, 
                    {value: ''}, {value: ''}, {value: ''}, {value: ''}, {value: ''},  {value: ''},  {value: ''}, 

                    {value: ''}, {value: 'AVG', align: 'right'},
                    {value: 'Period total', align: 'right'}, {value: 'Period total', align: 'right'}, {value: 'Period total', align: 'right'}, 
                    {value: ''}, {value: ''}, 
                    {value: 'AVG', align: 'right'}, {value: 'AVG', align: 'right'}, 
                ])

                const columns = [{"width": 12}, {"width": 10}, {"width": 14}, {"width": 10}, {"width": 12}, {"width": 8}, {"width": 8}, {"width": 18}, {"width": 8}, {"width": 18}, 
                {"width": 10}, {"width": 18}, {"width": 18}, {"width": 18}, {"width": 8}, {"width": 18}, {"width": 8}, {"width": 8}, {"width": 10}, {"width": 8}, {"width": 8}, 
                {"width": 8}, {"width": 8}, {"width": 10}, {"width": 10}, {"width": 12}, {"width": 12}, {"width": 12}, {"width": 13}, {"width": 14}, {"width": 14}, {"width": 10}, 
                {"width": 10}, {"width": 10}, 
                {"width": 12}, {"width": 12}, {"width": 12}, {"width": 16}, {"width": 14}, {"width": 16},
                {"width": 16}, {"width": 16}, {"width": 16}, {"width": 16}, {"width": 16}, {"width": 16}, 

                {"width": 12}, {"width": 12}, {"width": 12}, {"width": 16}, {"width": 14}, {"width": 16},
                {"width": 16}, {"width": 16}, {"width": 16}, {"width": 16}, {"width": 16}, {"width": 16}, 

                {"width": 12}, {"width": 12}, {"width": 12}, {"width": 16}, {"width": 14}, {"width": 16},
                {"width": 16}, {"width": 16}, {"width": 16}, {"width": 16}, {"width": 16}, {"width": 16},
                
                
                {"width": 10}, {"width": 10}, {"width": 10}, 
                {"width": 10}, {"width": 12}, {"width": 12}, {"width": 12}, {"width": 12}, {"width": 12}, {"width": 12},
                
                
                {"width": 21}, {"width": 18}, 
                {"width": 8}, {"width": 10}, {"width": 10}, {"width": 10}, {"width": 10}, {"width": 24}, {"width": 24}, {"width": 12}, {"width": 16}, {"width": 14}, {"width": 18}, {"width": 18}, {"width": 18}, {"width": 30} ]
                
                await writeXlsxFile(rows, {
                    columns, 
                    fileName: `${moment.utc(this.report.startDate).format('YYMMDD')}-${moment.utc(this.report.endDate).format('YYMMDD')}_EEOI_${ship.name}.xlsx`,
                    stickyRowsCount: 1,
                })
            },
            async createCiiExcel(data, ship, ytdValues) {
                const headerRow = [{ value: 'IMO', fontWeight: 'bold'}, {value: 'Ship', fontWeight: 'bold'}, {value: 'GT', fontWeight: 'bold'}, {value: 'Year', fontWeight: 'bold'}, {value: 'Week', fontWeight: 'bold'}, {value: 'Voyages', fontWeight: 'bold'}, 
                    {value: 'Fuel 1 type', fontWeight: 'bold'}, {value: 'Fuel 1 CO2', fontWeight: 'bold'}, {value: 'Fuel 1 sea [t]', fontWeight: 'bold'}, {value: 'Fuel 1 sea CO2 [t]', fontWeight: 'bold'}, {value: 'Fuel 1 port [t]', fontWeight: 'bold'}, {value: 'Fuel 1 port CO2 [t]', fontWeight: 'bold'},
                    {value: 'Fuel 2 type', fontWeight: 'bold'}, {value: 'Fuel 2 CO2', fontWeight: 'bold'}, {value: 'Fuel 2 sea [t]', fontWeight: 'bold'}, {value: 'Fuel 2 sea CO2 [t]', fontWeight: 'bold'}, {value: 'Fuel 2 port [t]', fontWeight: 'bold'}, {value: 'Fuel 2 port CO2 [t]', fontWeight: 'bold'},
                    {value: 'Fuel 3 type', fontWeight: 'bold'}, {value: 'Fuel 3 CO2', fontWeight: 'bold'}, {value: 'Fuel 3 sea [t]', fontWeight: 'bold'}, {value: 'Fuel 3 sea CO2 [t]', fontWeight: 'bold'}, {value: 'Fuel 3 port [t]', fontWeight: 'bold'}, {value: 'Fuel 3 port CO2 [t]', fontWeight: 'bold'},
                    {value: 'CO2 sea', fontWeight: 'bold'}, {value: 'CO2 port', fontWeight: 'bold'}, {value: 'CO2 total', fontWeight: 'bold'}, {value: 'CO2 cumulative total', fontWeight: 'bold'}, 
                    {value: 'NM', fontWeight: 'bold'}, {value: 'NM cumulative total', fontWeight: 'bold'}, {value: 'CII week', fontWeight: 'bold'}, {value: 'CII total', fontWeight: 'bold'},
                    {value: 'CO2 cumulative total YTD', fontWeight: 'bold'}, {value: 'NM cumulative total YTD', fontWeight: 'bold'}, {value: 'CII total YTD', fontWeight: 'bold'},
                ]
                const rows = [headerRow];
                
                // Get weeks
                function isoWeekNo(dt) {
                    const tdt = new Date(dt.valueOf());
                    const dayn = (dt.getDay() + 6) % 7;
                    tdt.setDate(tdt.getDate() - dayn + 3);
                    const firstThursday = tdt.valueOf();
                    tdt.setMonth(0, 1);
                    if (tdt.getDay() !== 4) {
                        tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
                    }
                    return 1 + Math.ceil((firstThursday - tdt) / 604800000);
                }
                let weeks = data.map(o => {
                    const year = moment.utc(o.etd).format('Y');
                    const week = isoWeekNo(moment.utc(o.etd).toDate());
                    return year + '-' + week;
                });
                weeks.sort((a, b) => a - b);
                weeks = [...new Set(weeks)]; // unique only

                let co2CumulativeTotal = 0;
                let distanceCumulativeTotal = 0;
                for (const v of weeks) {
                    const values = v.split('-');
                    const year = values[0];
                    const week = values[1];
                    const voyages = data.filter(o => {
                        const year = moment.utc(o.etd).format('Y');
                        const week = isoWeekNo(moment.utc(o.etd).toDate());
                        return v === year + '-' + week;
                    });
                    // console.dir(voyages);
                    const voyagesLength = voyages && Array.isArray(voyages) ? voyages.length : 0;

                    const fuel1FocSea = voyages.map(o => o.fuel1FocSea || 0);
                    const fuel1FocSeaSum = Math.round(fuel1FocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                    const fuel1Co2Sea = voyages.map(o => o.fuel1Co2Sea || 0);
                    const fuel1Co2SeaSum = Math.round(fuel1Co2Sea.reduce((a, b) => a + b, 0) * 100) / 100;
                    const fuel1FocPort = voyages.map(o => o.fuel1FocPort || 0);
                    const fuel1FocPortSum = Math.round(fuel1FocPort.reduce((a, b) => a + b, 0) * 100) / 100;
                    const fuel1Co2Port = voyages.map(o => o.fuel1Co2Port || 0);
                    const fuel1Co2PortSum = Math.round(fuel1Co2Port.reduce((a, b) => a + b, 0) * 100) / 100;

                    const fuel2FocSea = voyages.map(o => o.fuel2FocSea || 0);
                    const fuel2FocSeaSum = Math.round(fuel2FocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                    const fuel2Co2Sea = voyages.map(o => o.fuel2Co2Sea || 0);
                    const fuel2Co2SeaSum = Math.round(fuel2Co2Sea.reduce((a, b) => a + b, 0) * 100) / 100;
                    const fuel2FocPort = voyages.map(o => o.fuel2FocPort || 0);
                    const fuel2FocPortSum = Math.round(fuel2FocPort.reduce((a, b) => a + b, 0) * 100) / 100;
                    const fuel2Co2Port = voyages.map(o => o.fuel2Co2Port || 0);
                    const fuel2Co2PortSum = Math.round(fuel2Co2Port.reduce((a, b) => a + b, 0) * 100) / 100;

                    const fuel3FocSea = voyages.map(o => o.fuel3FocSea || 0);
                    const fuel3FocSeaSum = Math.round(fuel3FocSea.reduce((a, b) => a + b, 0) * 100) / 100;
                    const fuel3Co2Sea = voyages.map(o => o.fuel3Co2Sea || 0);
                    const fuel3Co2SeaSum = Math.round(fuel3Co2Sea.reduce((a, b) => a + b, 0) * 100) / 100;
                    const fuel3FocPort = voyages.map(o => o.fuel3FocPort || 0);
                    const fuel3FocPortSum = Math.round(fuel3FocPort.reduce((a, b) => a + b, 0) * 100) / 100;
                    const fuel3Co2Port = voyages.map(o => o.fuel3Co2Port || 0);
                    const fuel3Co2PortSum = Math.round(fuel3Co2Port.reduce((a, b) => a + b, 0) * 100) / 100;

                    const co2Sea = voyages.map(o => o.co2Sea || 0);
                    const co2SeaSum = Math.round(co2Sea.reduce((a, b) => a + b, 0) * 100) / 100;
                    const co2Port = voyages.map(o => o.co2Port || 0);
                    const co2PortSum = Math.round(co2Port.reduce((a, b) => a + b, 0) * 100) / 100;
                    const co2Total = voyages.map(o => o.co2Total || 0);
                    const co2TotalSum = Math.round(co2Total.reduce((a, b) => a + b, 0) * 100) / 100;

                    const distance = voyages.map(o => o.distance || 0);
                    const distanceSum = Math.round(distance.reduce((a, b) => a + b, 0) * 100) / 100;

                    co2CumulativeTotal += co2TotalSum;
                    distanceCumulativeTotal += distanceSum;
                    const ciiIceClassCorrection = ship && ship.ciiIceClassCorrection ? ship.ciiIceClassCorrection : 1.05;
                    
                    const ciiWeek = distanceSum ? Math.round((co2TotalSum * 1000 * 1000) / (distanceSum * ship.gt * ciiIceClassCorrection) * 100) / 100 : 0;
                    const ciiTotal = distanceCumulativeTotal ? Math.round((co2CumulativeTotal * 1000 * 1000) / (distanceCumulativeTotal * ship.gt * ciiIceClassCorrection) * 100) / 100 : 0;

                    rows.push([
                        {type: Number, value: parseInt(ship.imo)},
                        {type: String, value: data[0].name},
                        {type: Number, value: ship.gt},
                        {type: Number, value: parseInt(year)},
                        {type: Number, value: parseInt(week)},
                        {type: Number, value: voyagesLength},
                        {type: String, value: data[0].fuel1Type},
                        {type: Number, value: data[0].fuel1Co2},
                        {type: Number, value: fuel1FocSeaSum},
                        {type: Number, value: fuel1Co2SeaSum},
                        {type: Number, value: fuel1FocPortSum},
                        {type: Number, value: fuel1Co2PortSum},
                        {type: String, value: data[0].fuel2Type},
                        {type: Number, value: data[0].fuel2Co2},
                        {type: Number, value: fuel2FocSeaSum},
                        {type: Number, value: fuel2Co2SeaSum},
                        {type: Number, value: fuel2FocPortSum},
                        {type: Number, value: fuel2Co2PortSum},
                        {type: String, value: data[0].fuel3Type},
                        {type: Number, value: data[0].fuel3Co2},
                        {type: Number, value: fuel3FocSeaSum},
                        {type: Number, value: fuel3Co2SeaSum},
                        {type: Number, value: fuel3FocPortSum},
                        {type: Number, value: fuel3Co2PortSum},
                        {type: Number, value: co2SeaSum},
                        {type: Number, value: co2PortSum},
                        {type: Number, value: co2TotalSum},
                        {type: Number, value: co2CumulativeTotal},
                        {type: Number, value: distanceSum},
                        {type: Number, value: distanceCumulativeTotal},
                        {type: Number, value: ciiWeek},
                        {type: Number, value: ciiTotal},
                        {type: Number, value: ytdValues.co2CumulativeTotal},
                        {type: Number, value: ytdValues.distanceCumulativeTotal},
                        {type: Number, value: ytdValues.ciiTotal}
                    ])
                }
                
                const columns = [{"width": 10}, {"width": 14}, {"width": 8}, {"width": 8}, {"width": 8}, {"width": 10}, {"width": 11},
                {"width": 11}, {"width": 12}, {"width": 16}, {"width": 14}, {"width": 17}, {"width": 11}, {"width": 11}, {"width": 12}, 
                {"width": 16}, {"width": 14}, {"width": 17}, {"width": 11}, {"width": 11}, {"width": 12}, {"width": 16}, {"width": 14}, 
                {"width": 17}, {"width": 10}, {"width": 10}, {"width": 10}, {"width": 19}, {"width": 8}, {"width": 19}, {"width": 10}, {"width": 10},
                {"width": 23}, {"width": 23}, {"width": 12}]
                
                await writeXlsxFile(rows, {
                    columns, 
                    fileName: `${moment.utc(this.report.startDate).format('YYMMDD')}-${moment.utc(this.report.endDate).format('YYMMDD')}_CII_${ship.name}.xlsx`,
                    stickyRowsCount: 1,
                })
            },
            customizeCell(args) {
                if (args.column.field === 'depDelay') {
                    if (args.data.atd && args.data.etd) {
                        let delay = moment.utc(args.data.atd).unix() - moment.utc(args.data.etd).unix();
                        if (Math.abs(delay) > 20 * 60) {
                            args.cell.classList.add('warn');
                        }
                    }
                } else if (args.column.field === 'arrDelay') {
                    if (args.data.ata && args.data.eta) {
                        let delay = moment.utc(args.data.ata).unix() - moment.utc(args.data.eta).unix();
                        if (Math.abs(delay) > 20 * 60) {
                            args.cell.classList.add('warn');
                        }
                    }
                } else if (args.column.field === 'distance') {
                    if (this.data && Array.isArray(this.data.avg) && this.data.avg.length > 0) {
                        // Find matching route
                        let avg = this.data.avg.find(o => o.depLocode === args.data.depLocode && o.arrLocode === args.data.arrLocode);
                        if (avg && avg.distance && Math.abs((avg.distance - args.data.distance) / avg.distance) > 0.1) {
                            args.cell.classList.add('warn');
                        }
                    }
                }
            }
        },
        provide: {
            grid: [Group, Page, Sort, Edit, Toolbar, ExcelExport, ColumnChooser, Reorder, Resize, Filter, CommandColumn]
        }
    };
</script>

<style>
    /* Hide filter operator dropdown */
    .e-flm_optrdiv {
        display: none;
    }
    .e-grid .e-flmenu-valuediv {
        padding: 4px 0 0;
    }
    .e-report::before {
      content: '\e705';
    }
    .e-add::before {
      content: '+';
    }
    .deleted {
        text-decoration-line: line-through;
    }
    .issue {
        background-color: rgb(255, 0, 0, 0.1);
    }
    .custombutton {
        width: 60px !important;
    }
    .warn {
        color:red !important;
    }
    
</style>
