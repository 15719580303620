<template>
  <div>
    <record v-if="this.template" />
    <div v-else>
      <div>
        <v-toolbar fixed flat>
          <v-text-field v-model="search" dense outlined :clearable="true" persistent-placeholder
                        @change="switchToSearchTab()" :append-icon="mdiMagnify" label="Filter records"
                        style="width:200px;margin-top:40px" />
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div style="display:inline-block;width:170px;margin-top:40px">
            <v-menu ref="sdmenu" v-model="startDateMenu" :close-on-content-click="true" :return-value.sync="startDate"
                    transition="scale-transition" offset-y>
              <template #activator="{ on }">
                <v-text-field outlined v-model="startDate" dense persistent-placeholder label="Start date"
                              @change="dateFilter()" :clearable="true">
                  <template #prepend-inner>
                    <v-icon v-on="on" color="primary">{{ mdiCalendar }}</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker @change="$refs.sdmenu.save(startDate);dateFilter()" first-day-of-week="1" :show-week="true"
                             v-model="startDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.sdmenu.save(startDate)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <div style="display:inline-block;width:170px;margin-top:40px;margin-left:12px">
            <v-menu ref="edmenu" v-model="endDateMenu" :close-on-content-click="true" :return-value.sync="endDate"
                    transition="scale-transition" offset-y>
              <template #activator="{ on }">
                <v-text-field outlined v-model="endDate" dense persistent-placeholder label="End date"
                              @change="dateFilter()" :clearable="true">
                  <template #prepend-inner>
                    <v-icon v-on="on" color="primary">{{ mdiCalendar }}</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker @change="$refs.edmenu.save(endDate);dateFilter()" first-day-of-week="1" :show-week="true"
                             v-model="endDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="endDateMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.edmenu.save(endDate)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <template #extension>
            <v-tabs v-model="tab" class="mt-2 mb-2">
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab v-for="item in items" :key="item" :title="item">
                {{ item }} <v-icon small v-if="item === 'Filter results'" @click="closeSearchTab()">
                  {{ mdiWindowClose }}
</v-icon>
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-divider></v-divider>
      </div>
      <v-tabs-items v-model="tab"
                    class="scrollbar">
        <v-tab-item v-for="i in items" :key="i" :transition="false">
          <v-card tile v-if="i === 'Filter results'">
            <v-data-table :headers="headers" :items="filteredRecords" :search="search" mobile-breakpoint="900"
                          :custom-filter="customSearch" :options="options" class="record-table">
              <template #item="{item}">
                <tr @click="openRecord(item)" :class="{'deleted': item.status < 0 || item.latestVersion != 1 }">
                  <td>{{ formatDate(item.eventTime, item.timezone) }}</td>
                  <td>{{ item.timezone ? item.timezone : '+00:00' }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.summary }}</td>
                  <td>{{ item.author }}</td>
                  <td>{{ item.statusText }}</td>
                  <td>
                    <v-chip color="color1">
                      <b>{{ item.version }}</b>
                    </v-chip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
          <v-card tile v-else>
            <v-data-table :headers="headers" :items="records" :options="options" class="record-table"
                          mobile-breakpoint="900">
              <template #item="{item}">
                <tr @click="openRecord(item)" :class="{'deleted': item.status < 0 || item.latestVersion != 1 }">
                  <td>{{ formatDate(item.eventTime, item.timezone) }}</td>
                  <td>{{ item.timezone ? item.timezone : '+00:00' }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.summary }}</td>
                  <td>{{ item.author }}</td>
                  <td>{{ item.statusText }}</td>
                  <td>
                    <v-chip color="color1">
                      <b>{{ item.version }}</b>
                    </v-chip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
  import Record from './Record.vue'
  import moment from "moment";
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import {
    mdiReload,
    mdiMagnify,
    mdiCalendar,
    mdiWindowClose,
  } from '@mdi/js';

  export default {
    name: 'HistoryLog',
    components: {
      Record,
    },
    data: () => ({
      tab: 0,
      items: [
        'All'
      ],
      mdiReload,
      mdiMagnify,
      mdiCalendar,
      mdiWindowClose,
      logInterval: null,
      search: '',
      headers: [{
          text: 'Time',
          align: 'start',
          value: 'eventTime',
        },
        {
          text: 'Timezone',
          value: 'timezone'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Summary',
          value: 'summary'
        },
        {
          text: 'Author',
          value: 'author'
        },
        {
          text: 'Status',
          value: 'statusText'
        },
        {
          text: 'Version',
          value: 'version'
        },
      ],
      options: {
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
        multiSort: true,
      },
      startDateMenu: false,
      endDateMenu: false,
      startDate: moment.utc().subtract(2, 'w').format('YYYY-MM-DD'),
      endDate: moment.utc().format('YYYY-MM-DD'),
    }),
    async created() {
      try {
        setTimeout(() => {
          if (this.getShip() && this.getShip().book) {
              this.items = this.getShip().book.map(o => o.name);
          } else {
              this.items = [];
          }
          this.items.unshift('All');
        }, 200);
        setTimeout(async () => {
          // Set pre defined search string, if set
          let search = this.$route.params.search;
          if (this.getShip() && this.getShip().id) {
            if (typeof search !== 'undefined' && search !== null) {
              await this.fetchHistoryRecords({
                logDb: this.getShip().id,
                uuid: search
              });
              this.search = search.toString();
              this.switchToSearchTab();
            }
          }
        }, 300);
      } catch (e) {
        console.error(e.message);
      }
    },
    mounted() {
      this.tab = this.items.findIndex(o => o === this.getTab());
    },
    beforeDestroy() {

    },
    watch: {
      tab(newVal) {
        this.setTab(this.items[newVal]);
        // console.log(this.items[newVal]);
      }
    },
    computed: {
      ...mapGetters({
        record: 'logbook/getRecord',
        template: 'logbook/getTemplate',
        // ship: 'logbook/getShip',
      }),
      records() {
        let records = this.getHistoryRecords()
        if (Array.isArray(records) && records.length > 0) {
          const bookName = this.items[this.tab];
          if (this.items[this.tab] !== 'All') {
            records = records.filter((o) => {
              if (o.book.includes(bookName)) {
                return o;
              }
            })
          }
          return records;
        } else {
          return [];
        }
      },
      filteredRecords() {
        return this.getHistoryRecords();
      },
    },
    methods: {
      ...mapActions({
        fetchHistoryRecords: 'logbook/fetchHistoryRecords',
        fetchHistoryRecord: 'logbook/fetchHistoryRecord',

      }),
      ...mapGetters({
        getHistoryRecords: 'logbook/getHistoryRecords',
        getTab: 'logbook/getTab',
        getShip: 'logbook/getShip',
      }),
      ...mapMutations({
        setTab: 'logbook/setTab',
        setHistoryRecord: 'logbook/setHistoryRecord',
      }),
      formatDate(date, timezone) {
        if (timezone) {
          return moment.utc(date).utcOffset(timezone).format('DD.MM HH:mm');
        } else {
          return moment.utc(date).format('DD.MM HH:mm');
        }
      },
      openRecord(item) {
        // console.log(item)
        if (item && item.uuid) {
          this.setHistoryRecord(true);
          item.logDb = this.getShip().id;
          this.fetchHistoryRecord(item);
        }
      },
      dateFilter() {
        if (this.getShip() && this.getShip().id) {
          this.fetchHistoryRecords({
            logDb: this.getShip().id,
            start: this.startDate,
            end: this.endDate
          });
        }
      },
      switchToSearchTab() {
        let index = this.items.findIndex(o => o === 'Filter results');
        if (index !== -1) {
          this.tab = index;
        } else {
          this.items.push('Filter results');
          this.tab = this.items.length - 1;
          // console.log(this.items, this.tab)
        }
      },
      customSearch(value, search, item) {
        let found = [];
        let words = search.split(' ');
        for (let i = 0, il = words.length; i < il; i++) {
          if (Object.values(item).some(v => v && v.toString().toLowerCase().includes(words[i].toString()
          .toLowerCase()))) {
            found.push(true);
          }
        }
        return words.length === found.length;
      },
      closeSearchTab() {
        let index = this.items.findIndex(o => o === 'Filter results')
        if (index) {
          this.items.pop();
          this.tab = 0;
          this.search = '';
          if (this.getShip() && this.getShip().id) {
            this.fetchHistoryRecords({
              logDb: this.getShip().id,
              start: this.startDate,
              end: this.endDate
            });
          }
        }
      }
    }
  };
</script>

<style>

</style>
<style scoped>

</style>