<template>
    <v-dialog class="editor" :retain-focus="false" v-model="value" max-width="600" persistent>
        <v-card>
            <v-card-title>Add new voyage</v-card-title>
            <v-card-text>
                <v-layout wrap align-center>
                    <v-flex xs12 sm6 d-flex>
                        <v-select v-model="shipId" :items="ships" item-key="id" item-text="[name]"
                            item-value="id" label="Select ship" prepend-icon="directions_boat" class="mx-2 mt-0" />
                    </v-flex>
                    <v-flex xs12 sm6 d-flex>
                        <v-text-field v-model="code" label="Voyage code" prepend-icon="mdi-code-array" class="mx-2 mt-0">
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 d-flex>
                        <v-autocomplete v-model="depPort" prepend-icon="anchor" :loading="depPortLoading" :items="ports" :item-text="portItemText" item-key="id"
                            :search-input.sync="depPortSearch" cache-items return-object class="mx-2 mt-n1 mb-4" text hide-no-data hide-details label="Departure port">
                        </v-autocomplete>
                    </v-flex>
                     <v-flex xs12 sm6 d-flex>
                        <v-autocomplete v-model="arrPort" prepend-icon="anchor" :loading="arrPortLoading" :items="ports" :item-text="portItemText" item-key="id"
                            :search-input.sync="arrPortSearch" cache-items return-object class="mx-2 mt-n1 mb-4" text hide-no-data hide-details label="Arrival port">
                        </v-autocomplete>
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-menu ref="sdmenu" v-model="etdDateMenu" :close-on-content-click="true" :return-value.sync="etdDate"
                            transition="scale-transition" offset-y min-width="290px">
                            <template #activator="{ on }">
                                <v-text-field v-model="etdDate" label="Departure date" prepend-icon="event" class="mx-2">
                                    <template #prepend>
                                        <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker @change="$refs.sdmenu.save(etdDate)" :first-day-of-week="firstDayOfWeek" :show-week="true" v-model="etdDate"
                                no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="etdDateMenu = false">Cancel</v-btn>
                                <v-btn text color="primary" @click="$refs.sdmenu.save(etdDate)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-menu ref="stmenu" v-model="etdTimeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="etdTime"
                            transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template #activator="{ on }">
                                <v-text-field v-model="etdTime" label="Departure time (UTC)" prepend-icon="access_time" class="mx-2">
                                    <template #prepend>
                                        <v-icon v-on="on" color="primary">mdi-clock-outline</v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-time-picker v-if="etdTimeMenu" scrollable v-model="etdTime" format="24hr" 
                                @click:minute="$refs.stmenu.save(etdTime)">
                            </v-time-picker>
                        </v-menu>
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-menu ref="edmenu" v-model="etaDateMenu" :close-on-content-click="true" :return-value.sync="etaDate" transition="scale-transition"
                            offset-y min-width="290px">
                            <template #activator="{ on }">
                                <v-text-field v-model="etaDate" label="Arrival date" prepend-icon="event" class="mx-2">
                                    <template #prepend>
                                        <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker @change="$refs.edmenu.save(etaDate)" :first-day-of-week="firstDayOfWeek" :show-week="true" v-model="etaDate"
                                no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="etaDateMenu = false">Cancel</v-btn>
                                <v-btn text color="primary" @click="$refs.edmenu.save(etaDate)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-menu ref="etmenu" v-model="etaTimeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="etaTime"
                            transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template #activator="{ on }">
                                <v-text-field v-model="etaTime" label="Arrival time (UTC)" prepend-icon="access_time" class="mx-2">
                                    <template #prepend>
                                        <v-icon v-on="on" color="primary">mdi-clock-outline</v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-time-picker v-if="etaTimeMenu" scrollable v-model="etaTime" format="24hr" 
                                @click:minute="$refs.etmenu.save(etaTime)">
                            </v-time-picker>
                        </v-menu>
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-text-field v-model="cargoTons" type="number" min="0" step="0.001" label="Cargo tons" prepend-icon="mdi-cube-outline" class="mx-2 mt-0">
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-text-field v-model="anchorHours" type="number" min="0" step="0.1" label="Anchor hours" hint="In decimal hours" prepend-icon="mdi-timelapse" class="mx-2 mt-0">
                        </v-text-field>
                    </v-flex>
                     <v-flex xs6 d-flex>
                        <v-text-field v-model="timeInIce" type="number" min="0" step="0.1" label="Time in ice" hint="In decimal hours" prepend-icon="mdi-snowflake" class="mx-2 mt-0">
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-text-field v-model="distanceInIce" type="number" min="0" step="0.1" label="Distance in ice" hint="In nautical miles [NM]" prepend-icon="mdi-snowflake" class="mx-2 mt-0">
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-select v-model="fuelTypeMePort" :items="fuelTypes" label="ME fuel port" prepend-icon="mdi-gas-station-outline" class="mx-2 mt-0" />
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-select v-model="fuelTypeMeSea" :items="fuelTypes" label="ME fuel sea" prepend-icon="mdi-gas-station-outline" class="mx-2 mt-0" />
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-select v-model="fuelTypeAuxPort" :items="fuelTypes" label="AUX fuel port" prepend-icon="mdi-gas-station-outline" class="mx-2 mt-0" />
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-select v-model="fuelTypeAuxSea" :items="fuelTypes" label="AUX fuel sea" prepend-icon="mdi-gas-station-outline" class="mx-2 mt-0" />
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-select v-model="fuelTypeBoilerPort" :items="fuelTypes" label="Boiler fuel port" prepend-icon="mdi-gas-station-outline" class="mx-2 mt-0" />
                    </v-flex>
                    <v-flex xs6 d-flex>
                        <v-select v-model="fuelTypeBoilerSea" :items="fuelTypes" label="Boiler fuel sea" prepend-icon="mdi-gas-station-outline" class="mx-2 mt-0" />
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <v-card class="cardbgdark">
            <v-card-actions>
                <v-btn color="error" @click="cancel()">
                    Cancel <v-icon right class="hidden-xs-only">
                        cancel
                    </v-icon>
                </v-btn>
                <v-spacer />
                <v-btn color="primary" @click="saveHandler()" :disabled="saveDisabled">
                    Save <v-icon right class="hidden-xs-only">check</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
        <ProgressCircular />
    </v-dialog>
</template>

<script>
    import moment from 'moment';
    import {
        mapGetters,
        mapActions,
        mapMutations,
    } from 'vuex';
    import ProgressCircular from './ProgressCircular.vue';
    import {
        eventBus
    } from '../main';
    
    export default {
        name: 'AddVoyageDialog',
        props: {
            value: [Boolean, Object]
        },
        components: {
            ProgressCircular,
        },
        data() {
            return {
                dialog: false,
                etdDate: null,
                etdTime: null,
                etdDateMenu: false,
                etdTimeMenu: false,
                etaDate: null,
                etaTime: null,
                etaDateMenu: false,
                etaTimeMenu: false,
                shipId: null,
                code: null,
                depPort: null,
                arrPort: null,
                ports: [],
                depPortSearch: null,
                arrPortSearch: null,
                depPortLoading: false,
                arrPortLoading: false,
                anchorHours: null,
                cargoTons: null,
                timeInIce: null,
                distanceInIce: null,
                fuelTypeMePort: null,
                fuelTypeMeSea: null,
                fuelTypeAuxPort: null,
                fuelTypeAuxSea: null,
                fuelTypeBoilerPort: null,
                fuelTypeBoilerSea: null,
                fuelTypes: ['HFO', 'LFO', 'MGO'],
            };
        },
        created() {
            this.shipId = localStorage.getItem('cargoShipId') ? parseInt(localStorage.getItem('cargoShipId')) : null;
            this.fuelTypeMePort = localStorage.getItem('fuelTypeMePort') ? localStorage.getItem('fuelTypeMePort') : null;
            this.fuelTypeMeSea = localStorage.getItem('fuelTypeMeSea') ? localStorage.getItem('fuelTypeMeSea') : null;
            this.fuelTypeAuxPort = localStorage.getItem('fuelTypeAuxPort') ? localStorage.getItem('fuelTypeAuxPort') : null;
            this.fuelTypeAuxSea = localStorage.getItem('fuelTypeAuxSea') ? localStorage.getItem('fuelTypeAuxSea') : null;
            this.fuelTypeBoilerPort = localStorage.getItem('fuelTypeBoilerPort') ? localStorage.getItem('fuelTypeBoilerPort') : null;
            this.fuelTypeBoilerSea = localStorage.getItem('fuelTypeBoilerSea') ? localStorage.getItem('fuelTypeBoilerSea') : null;
        },
        beforeDestroy() {
        },
        watch: {
            depPortSearch(val) {
                if (val && val !== this.select) {
                    this.portQuerySelections(val);
                }
            },
            arrPortSearch(val) {
                if (val && val !== this.select) {
                    this.portQuerySelections(val);
                }
            },
        },
        methods: {
            ...mapGetters({
                getShips: 'data/getShips',
                getFirstDayOfWeek: 'data/getFirstDayOfWeek',
            }),
            ...mapActions({
                fetchPorts: 'data/fetchPorts',
                addVoyage: 'data/addVoyage',
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
                alertError: 'alert/error',
                alertWarning: 'alert/warning',
                alertClear: 'alert/clear',
                progressOn: 'data/progressOn',
                progressOff: 'data/progressOff',
                alertSuccess: 'alert/success',
            }),
            portQuerySelections(v) {
                if (v.length > 2) {
                    this.portLoading = true;
                    this.fetchPorts({
                        query: v
                    }).then((data) => {
                        this.ports = data;
                        this.portLoading = false;
                    });
                }
            },
            portItemText(item) {
                return `${item.name} — ${item.ddtLocode}`;
            },
            saveHandler() {
                localStorage.setItem('cargoShipId', this.shipId);
                localStorage.setItem('fuelTypeMePort', this.fuelTypeMePort);
                localStorage.setItem('fuelTypeMeSea', this.fuelTypeMeSea);
                localStorage.setItem('fuelTypeAuxPort', this.fuelTypeAuxPort);
                localStorage.setItem('fuelTypeAuxSea', this.fuelTypeAuxSea);
                localStorage.setItem('fuelTypeBoilerPort', this.fuelTypeBoilerPort);
                localStorage.setItem('fuelTypeBoilerSea', this.fuelTypeBoilerSea);
                let ship = this.ships.find((o) => o.id === this.shipId);
                let name = ship.name;
                let values = {
                    shipId: this.shipId,
                    name: name,
                    depLocode: this.depPort.ddtLocode,
                    atd: this.etdDate + 'T' + this.etdTime + ':00Z',
                    etd: this.etdDate + 'T' + this.etdTime + ':00Z',
                    arrLocode: this.arrPort.ddtLocode,
                    ata: this.etaDate + 'T' + this.etaTime + ':00Z',
                    eta: this.etaDate + 'T' + this.etaTime + ':00Z',
                    depCode: this.code,
                    route: this.depPort.ddtLocode + '-' + this.arrPort.ddtLocode,
                    anchorHours: this.anchorHours,
                    cargoTons: this.cargoTons,
                    timeInIce: this.timeInIce,
                    distanceInIce: this.distanceInIce,
                    fuelTypeMePort: this.fuelTypeMePort,
                    fuelTypeMeSea: this.fuelTypeMeSea,
                    fuelTypeAuxPort: this.fuelTypeAuxPort,
                    fuelTypeAuxSea: this.fuelTypeAuxSea,
                    fuelTypeBoilerPort: this.fuelTypeBoilerPort,
                    fuelTypeBoilerSea: this.fuelTypeBoilerSea,
                };
                // console.log(values);
                this.addVoyage({values: values}).then(() => {
                    eventBus.$emit('addCargoShipVoyage', true);
                    this.$emit('input', false);
                    this.clearValues();
                });
            },
            clearValues() {
                this.etdDate = null;
                this.etdTime = null;
                this.etaDate = null;
                this.etaTime = null;
                // this.shipId = null;
                this.code = null;
                this.depPort = null;
                this.arrPort = null;
                this.ports = [];
                this.depPortSearch = null;
                this.arrPortSearch = null;
                this.anchorHours = null;
                this.cargoTons = null;
            },
            cancel() {
                this.clearValues();
                this.$emit('input', false);
            },
            formatTime(time) {
                return moment(time).format('D.M.YYYY HH:mm');
            },
        },
        computed: {
            ...mapGetters({
                getUser: 'authentication/getUser',
                ships: 'data/getShips',
                features: 'authentication/features',
            }),
            saveDisabled() {
                if (!this.etdDate || !this.etdTime || !this.etaDate || !this.etaTime || 
                    !this.shipId || !this.depPort || !this.arrPort ||
                    this.anchorHours === null || this.cargoTons === null || this.anchorHours === '' || this.cargoTons === '' ||
                    this.timeInIce === null || this.distanceInIce === null || this.timeInIce === '' || this.distanceInIce === '' ||
                    this.fuelTypeMePort === null || this.fuelTypeMeSea === null || this.fuelTypeMePort === '' || this.fuelTypeMeSea === '' ||
                    this.fuelTypeAuxPort === null || this.fuelTypeAuxSea === null || this.fuelTypeAuxPort === '' || this.fuelTypeAuxSea === '' ||
                    this.fuelTypeBoilerPort === null || this.fuelTypeBoilerSea === null || this.fuelTypeBoilerPort === '' || this.fuelTypeBoilerSea === '') {
                    return true;
                } else {
                    return false;
                }
            },
            firstDayOfWeek() {
                return this.getFirstDayOfWeek();
            },
        },
    };
</script>

<style scoped>
    
</style>

<style>
   
</style>