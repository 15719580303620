<template>
    <div>
        <v-card class="cardbglight">
            <v-data-table :headers="headers" :items="ships" :search="search" :single-expand="true" :expanded.sync="expanded" item-key="id" :sort-by="sortBy"
                :sort-desc="sortDesc" :footer-props="{ showFirstLastPage: true}" :items-per-page="15" mobile-breakpoint="100" class="elevation-1">
                <template #top>
                    <v-toolbar>
                        <v-toolbar-title>Ships </v-toolbar-title>
                        <v-spacer />
                        <v-spacer />
                        <v-spacer />
                                <v-text-field v-model="search" class="mt-4" append-icon="close" @click:append="search=''" prepend-icon="search" label="Search"
                                    single-linehide-details />
                    </v-toolbar>
                </template>
                <template #item="{item}">
                    <tr @click="expandPanel(item)">
                        <td>{{ item.name }}</td>
                        <td>{{ item.shortName }}</td>
                        <td>{{ item.imo }}</td>
                        <td class="d-none d-sm-table-cell">{{ item.mmsi }}</td>
                        <td class="d-none d-sm-table-cell">{{ item.callSign }}</td>
                        <td>{{ item.author }}</td>
                        <td>{{ formatDate(item.updated) }}</td>
                        <td><v-icon v-if="item.external === 1" color="success">mdi-check</v-icon></td>
                        <td><v-icon v-if="item.active === 1" color="success">mdi-check</v-icon><v-icon v-else color="error">mdi-close</v-icon></td>
                    </tr>
                </template>
                <template #expanded-item="{ headers }">
                    <td :colspan="headers.length" class="pa-0">
                        <v-card class="cardbg primarytext--text">
                            <v-form v-model="saveValid" lazy-validation>
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.name" :rules="nameRules" persistent-hint :hint="ship.name ? '' : 'Required'"
                                                prepend-icon="directions_boat" label="Name" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.shortName" :rules="shortNameRules" persistent-hint
                                                :hint="ship.shortName ? '' : 'Required'" label="Short name" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.portNr" label="Port Nr" disabled></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.imo" :rules="imoRules" persistent-hint :hint="ship.imo ? '' : 'Required'" label="IMO"
                                                required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.mmsi" :rules="mmsiRules" persistent-hint :hint="ship.mmsi ? '' : 'Required'"
                                                label="MMSI" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.callSign" label="Call sign"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-select v-model="ship.brand" :items="brands" label="Brand" />
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.dwt" step="0.1" min="1" type="number" label="DWT"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.dwcc" step="0.1" min="1" type="number" label="DWCC"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.gt" step="0.1" min="1" type="number" label="GT"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.ciiIceClassCorrection" step="0.001" min="0" type="number" label="CII Ice class correction"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.cuf" step="0.1" min="1" type="number" label="CUF"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.m3" step="0.1" min="1" type="number" label="m3"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.loa" step="0.1" min="0" type="number" label="Loa"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.beam" step="0.1" min="0" type="number" label="Beam"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.maxDraft" step="0.01" min="0" type="number" label="Max draft"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.normalSog" step="0.1" min="1" type="number" :rules="requiredRules" persistent-hint
                                                :hint="ship.normalCons ? '' : 'Required'" label="Normal SOG" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.ballastSog" step="0.1" min="1" type="number" :rules="requiredRules" persistent-hint
                                                :hint="ship.ballastSog ? '' : 'Required'" label="Ballast SOG" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.normalCons" step="0.001" min="0.001" type="number" label="Primary cons (ton/NM)">
                                            </v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.ballastCons" step="0.001" min="0.001" type="number" label="Primary ballast cons (ton/NM)">
                                            </v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.secondaryCons" step="0.001" min="0.001" type="number" label="Secondary cons (ton/NM)">
                                            </v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.secondaryBallastCons" step="0.001" min="0.001" type="number" label="Secondary ballast cons (ton/NM)">
                                            </v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.portCons" step="0.001" min="0.001" type="number" label="Port cons (ton/day)">
                                            </v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.dayRate" step="1" min="0" type="number" label="Day rate"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.a" step="0.1" min="1" type="number" label="A"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.b" step="0.1" min="1" type="number" label="B"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.c" step="0.1" min="1" type="number" label="C"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.d" step="0.1" min="1" type="number" label="D"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="ship.notes" label="Notes"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="ship.link" label="Details link"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="ship.emails" hint="Comma separated list of emails" label="Emails"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-autocomplete style="height:62px !important" class="mr-4" v-show="!ship.ownerName" prepend-icon="mdi-account" v-model="ship.owner"
                                                :loading="ownerLoading" :items="owners" :item-text="ownerItemText" item-key="id" :search-input.sync="ownerSearch" clearable
                                                @change="updateOwner" cache-items return-object text hide-no-data hide-details label="Owner"></v-autocomplete>
                                            <v-text-field v-show="ship.ownerName" prepend-icon="mdi-account" class="mr-4" :readonly="true" v-model="ship.ownerName" label="Owner"
                                                clearable @click:clear="onOwnerClearClicked(ship)"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-checkbox v-model="ship.external" @change="external = ship.external" :false-value="0" :true-value="1"
                                                label="External ship"></v-checkbox>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-checkbox v-model="ship.navtorApi" :false-value="0" :true-value="1" label="Navtor API"></v-checkbox>
                                        </v-flex>
                                        <v-flex v-show="ship.navtorApi" px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.navtorUsername" label="Navtor user"></v-text-field>
                                        </v-flex>
                                        <v-flex v-show="ship.navtorApi" px-4 xs6 sm4 md2>
                                            <v-text-field v-model="ship.navtorPassword" type="password" label="Navtor password"></v-text-field>
                                        </v-flex>
                                        <v-flex v-show="ship.navtorApi" px-4 xs6 sm4 md2>
                                            <v-btn depressed outlined class="mt-3" title="Save modified credentials before connection test"
                                                @click="testNavtorApi(ship)" :loading="testLoading"
                                                :disabled="!ship.navtorUsername || ship.navtorPassword !== 'xxxxxxxx' || userLevel < 1">
                                                Test connection
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-switch style="margin-top: 18px;" :disabled="userLevel < 1" v-model="ship.active" :false-value="0" :true-value="1"
                                            :label="ship.active === 1 ? 'Active' : 'Inactive'" @change="active = ship.active" color="primary" hint="Status"
                                            persistent-hint />
                                        <v-spacer />
                                        <v-btn color="error" @click="callDeleteShip(ship)" :disabled="userLevel < 1">
                                            Delete
                                            <v-icon right dark>delete_forever</v-icon>
                                        </v-btn>
                                        <v-btn color="primary" @click="save(ship)"
                                            :disabled="!saveValid || userLevel < 1 || !ship.name || !ship.shortName || !ship.mmsi || !ship.imo || !ship.normalSog || !ship.ballastSog">
                                            Save
                                            <v-icon right dark>check</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-container>
                            </v-form>
                        </v-card>
                    </td>
                </template>
                <v-alert slot="no-results" :value="true" color="error" icon="warning">
                    Your search for "{{ search }}" found no results.
                </v-alert>
            </v-data-table>
            <v-expansion-panels :disabled="userLevel < 1" v-model="newPanel">
                <v-expansion-panel :disabled="userLevel < 1">
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="teal">add_circle</v-icon> <span class="text-body-1 ml-2">Add new ship</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <v-card class="cardbg">
                            <v-form v-model="newValid" lazy-validation>
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex px-4 xs8 sm5 md2>
                                            <v-text-field v-model="newShip.name" :rules="nameRules" persistent-hint :hint="newShip.name ? '' : 'Required'"
                                                prepend-icon="directions_boat" label="Name" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs4 sm3 md2>
                                            <v-text-field v-model="newShip.shortName" :rules="shortNameRules" persistent-hint
                                                :hint="newShip.shortName ? '' : 'Required'" label="Short name" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.portNr" label="Port Nr"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.imo" label="IMO" :rules="imoRules" persistent-hint
                                                :hint="newShip.imo ? '' : 'Required'" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.mmsi" label="MMSI" :rules="mmsiRules" persistent-hint
                                                :hint="newShip.mmsi ? '' : 'Required'" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.callSign" label="Call sign"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-select v-model="newShip.brand" :items="brands" label="Brand" />
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.dwt" step="0.1" min="1" type="number" label="DWT"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.dwcc" step="0.1" min="1" type="number" label="DWCC"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.gt" step="0.1" min="1" type="number" label="GT"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.ciiIceClassCorrection" step="0.001" min="0" type="number" label="CII Ice class correction"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.cuf" step="0.1" min="1" type="number" label="CUF"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.m3" step="0.1" min="1" type="number" label="m3"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.loa" step="0.1" min="0" type="number" label="Loa"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.beam" step="0.1" min="0" type="number" label="Beam"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.maxDraft" step="0.01" min="0" type="number" label="Max draft"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.normalSog" step="0.1" min="1" type="number" :rules="requiredRules" persistent-hint
                                                :hint="newShip.normalSog? '' : 'Required'" label="Normal SOG" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.ballastSog" step="0.1" min="1" type="number" :rules="requiredRules" persistent-hint
                                                :hint="newShip.ballastSog ? '' : 'Required'" label="Ballast SOG" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.normalCons" step="0.001" min="0.001" type="number" label="Primary cons (ton/NM)">
                                            </v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.ballastCons" step="0.001" min="0.001" type="number" label="Primary ballast cons (ton/NM)">
                                            </v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.secondaryCons" step="0.001" min="0.001" type="number" label="Secondary cons (ton/NM)">
                                            </v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.secondaryBallastCons" step="0.001" min="0.001" type="number" label="Secondary ballast cons (ton/NM)">
                                            </v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.portCons" step="0.001" min="0.001" type="number" label="Port cons (ton/day)">
                                            </v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.dayRate" step="1" min="0" type="number" label="Day rate"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.a" step="0.1" min="1" type="number" label="A"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.b" step="0.1" min="1" type="number" label="B"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.c" step="0.1" min="1" type="number" label="C"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.d" step="0.1" min="1" type="number" label="D"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="newShip.notes" label="Notes"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="newShip.link" label="Details link"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="newShip.emails" hint="Comma separated list of emails" label="Emails"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                             <v-autocomplete prepend-icon="mdi-account" v-model="newShip.owner" :loading="ownerLoading2" :items="owners2"
                                                :item-text="ownerItemText2" item-key="id" :clearable="true" :search-input.sync="ownerSearch2" cache-items return-object text
                                                hide-no-data hide-details label="Owner">
                                            </v-autocomplete>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-checkbox v-model="newShip.external" @change="external = newShip.external" :false-value="0" :true-value="1"
                                                label="External ship"></v-checkbox>
                                        </v-flex>
                                        <v-flex px-4 xs6 sm4 md2>
                                            <v-checkbox v-model="newShip.navtorApi" :false-value="0" :true-value="1" label="Navtor API"></v-checkbox>
                                        </v-flex>
                                        <v-flex v-show="newShip.navtorApi" px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.navtorUsername" label="Navtor user"></v-text-field>
                                        </v-flex>
                                        <v-flex v-show="newShip.navtorApi" px-4 xs6 sm4 md2>
                                            <v-text-field v-model="newShip.navtorPassword" type="password" label="Navtor password"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-switch style="margin-top: 18px;" :disabled="userLevel < 1" v-model="newShip.active" :false-value="0" :true-value="1"
                                            :label="newShip.active === 1 ? 'Active' : 'Inactive'" @change="active = newShip.active" color="primary"
                                            hint="Status" persistent-hint />

                                        <v-spacer />
                                        <v-btn color="primary" @click="callAddShip(newShip)"
                                            :disabled="!newValid || userLevel < 1 || !newShip.name || !newShip.shortName || !newShip.mmsi || !newShip.imo || !newShip.normalSog || !newShip.ballastSog">
                                            Add
                                            <v-icon right dark>add_circle</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-container>
                            </v-form>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="primary">help</v-icon>
                            <span class="text-body-1 ml-2">What does this page show</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <br />
                        <p>
                            The Ships page shows the ships and their detailed data. Admin-users can edit the settings and in principle also add new vessels to
                            the system. Please note though, that since a ship is usually associated with a specific IoT device, you will need to contact
                            sales@nauticai.com before the new ship appears in the system.
                        </p>
                        <p>
                            Some of the ship details are locked for editing and can only be defined and changed by the nauticAi administrators. Contact <a
                                href="mailto:support@nauticai.com">support@nauticai.com</a> for any questions.
                        </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <StatusDialog />
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    import moment from 'moment';
    import StatusDialog from '../components/StatusDialog.vue';
    import {
        eventBus
    } from '../main';

    export default {
        name: 'Ships',
        components: {
            StatusDialog
        },
        data: () => ({
            search: '',
            sortBy: ['active', 'external', 'name'],
            sortDesc: [true, false, false],
            active: 1,
            external: 0,
            selected: [],
            nameRules: [
                (v) => !!v || 'Required',
                (v) => (v ? v.length >= 3 || 'Min 3 characters' : ''),
            ],
            shortNameRules: [
                (v) => !!v || 'Required',
                (v) => (v ? v.length >= 2 || 'Min 2 characters' : ''),
                (v) => (v ? v.length <= 4 || 'Max 4 characters' : ''),
            ],
            imoRules: [
                (v) => !!v || 'Required',
                (v) => (v ? v.length === 7 || 'Must be 7 digits' : ''),
            ],
            mmsiRules: [
                (v) => !!v || 'Required',
                (v) => (v ? v.length === 9 || 'Must be 9 digits' : ''),
            ],
            requiredRules: [
                (v) => !!v || 'Required',
            ],
            headers: [{
                    text: 'Name',
                    value: 'name'
                },
                {
                    text: 'Short name',
                    value: 'shortName'
                },
                {
                    text: 'IMO',
                    value: 'imo'
                },
                {
                    text: 'MMSI',
                    value: 'mmsi'
                },
                {
                    text: 'Call sign',
                    value: 'callSign'
                },
                {
                    text: 'Author',
                    value: 'author'
                },
                {
                    text: 'Updated',
                    value: 'updated'
                },
                {
                    text: 'External',
                    value: 'external'
                },
                {
                    text: 'Active',
                    value: 'active'
                },
            ],
            brands: ['LoLo', 'StoLo', 'StoRo', 'RoRo'],
            expanded: [],
            newShip: {
                name: null,
                mmsi: null,
                shortName: null,
                portNr: null,
                imo: null,
                callSign: null,
                brand: null,
                dwt: null,
                dwcc: null,
                gt: null,
                ciiIceClassCorrection: null,
                cuf: null,
                m3: null,
                loa: null,
                beam: null,
                maxDraft: null,
                normalSog: null,
                ballastSog: null,
                normalCons: null,
                ballastCons: null,
                secondaryCons: null,
                secondaryBallastCons: null,
                portCons: null,
                dayRate: null,
                a: null,
                b: null,
                c: null,
                d: null,
                external: 0,
                active: 1,
                notes: null,
                link: null,
                emails: null,
                owner: null,
                ownerId: null,
                ownerName: null,
            },
            newPanel: [],
            deleteShipId: null,
            testLoading: false,
            saveValid: true,
            newValid: true,
            select: null,
            ownerSearch: null,
            owners: [],
            ownerLoading: false,
            portSearch2: null,
            ports2: [],
            portLoading2: false,
            select2: null,
            ownerSearch2: null,
            owners2: [],
            ownerLoading2: false,
            ship: {},
        }),
        created() {
            this.fetchShips();
        },
        watch: {
            ownerSearch(val) {
                val && val !== this.select && this.ownerQuerySelections(val);
            },
            ownerSearch2(val) {
                val && val !== this.select2 && this.ownerQuerySelections2(val);
            },
        },
        computed: {
            ...mapGetters({
                ships: 'data/getShips',
            }),
            userLevel() {
                return this.getUser().user.level;
            },
        },
        mounted() {
            eventBus.$on('deleteShip', () => {
                if (this.deleteShipId) {
                    this.deleteShip(this.deleteShipId);
                    this.deleteShipId = null;
                }
            });
        },
        beforeDestroy() {
            eventBus.$off('deleteShip');
        },
        methods: {
            ...mapActions({
                fetchShips: 'data/fetchShips',
                saveShip: 'data/saveShip',
                deleteShip: 'data/deleteShip',
                addShip: 'data/addShip',
                fetchNavtorRouteList: 'data/fetchNavtorRouteList',
                fetchContacts: 'data/fetchContacts',
            }),
            ...mapGetters({
                getShips: 'data/getShips',
                getUser: 'authentication/getUser'
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
                alertError: 'alert/error',
                alertSuccess: 'alert/success',
            }),
            expandPanel(item) {
                this.expanded = item === this.expanded[0] ? [] : [item];
                if (this.expanded.length > 0) {
                    this.ship = JSON.parse(JSON.stringify(item));
                } else {
                    this.ship = {};
                }
            },
            callDeleteShip(ship) {
                this.deleteShipId = ship.id;
                this.alertConfirm({
                    message: `Are you sure you want to delete permanently ${ship.name}?`,
                    emit: 'deleteShip'
                });
            },
            save(ship) {
                if (typeof ship.owner === 'object' && ship.owner !== null) {
                    ship.ownerId = ship.owner.id;
                }
                if (typeof ship.ownerId === 'undefined') {
                    ship.ownerId = null;
                }
                // console.log(ship);
                this.saveShip(ship);
            },
            callAddShip(ship) {
                if (typeof ship.owner === 'object' && ship.owner !== null) {
                    ship.ownerId = ship.owner.id;
                } else {
                    ship.ownerId = null;
                }
                this.addShip(ship);
                this.cancel();
            },
            cancel() {
                this.newPanel = [];
                this.newShip = {
                    name: null,
                    mmsi: null,
                    shortName: null,
                    portNr: null,
                    imo: null,
                    callSign: null,
                    brand: null,
                    dwt: null,
                    dwcc: null,
                    gt: null,
                    ciiIceClassCorrection: null,
                    cuf: null,
                    m3: null,
                    loa: null,
                    beam: null,
                    maxDraft: null,
                    normalSog: null,
                    ballastSog: null,
                    normalCons: null,
                    ballastCons: null,
                    secondaryCons: null,
                    secondaryBallastCons: null,
                    portCons: null,
                    dayRate: null,
                    a: null,
                    b: null,
                    c: null,
                    d: null,
                    external: 0,
                    active: 1,
                    notes: null,
                    link: null,
                    emails: null,
                    owner: null,
                    ownerId: null,
                    ownerName: null,
                };
            },
            formatDate(date) {
                if (date) {
                    return moment.utc(date).local().format('D.M.Y HH:mm');
                }
                return '';
            },
            testNavtorApi(ship) {
                this.testLoading = true;
                this.fetchNavtorRouteList(ship.id).then((data) => {
                    // console.log(data); 
                    this.testLoading = false;
                    if (Array.isArray(data)) {
                        this.alertSuccess(`Connection test was successful. Found ${data.length} supported routes.`);
                    } else {
                        this.alertError('Connection test failed.');
                    }
                }, (error) => {
                    console.error(error);
                    this.testLoading = false;
                    this.alertError('Connection test failed.');
                });
            },
            ownerQuerySelections(v) {
                if (v.length > 2) {
                    this.ownerLoading = true;
                    this.fetchContacts({
                        query: v,
                        type: 1,
                    }).then((data) => {
                        this.owners = data;
                        this.ownerLoading = false;
                    });
                }
            },
            ownerItemText(item) {
                return `${item.name}`;
            },
            updateOwner(item) {
                if (item) {
                    this.ownerId = item.id;
                    this.ownerName = item.name;
                } else {
                    this.ownerId = null;
                    this.ownerName = null;
                }
            },
            ownerQuerySelections2(v) {
                if (v.length > 2) {
                    this.ownerLoading2 = true;
                    this.fetchContacts({
                        query: v,
                        type: 1,
                    }).then((data) => {
                        this.owners2 = data;
                        this.ownerLoading2 = false;
                    });
                }
            },
            ownerItemText2(item) {
                return `${item.name}`;
            },
            onOwnerClearClicked(item) {
                console.log('onOwnerClearClicked');
                item.owner = {};
                item.ownerId = null;
                item.ownerName = null;
                this.ownerId = null;
                this.ownerName = null;
            },
        }
    };
</script>

<style>
    .v-list--dense .v-list__tile:not(.v-list__tile--avatar) {
        height: 100%;
    }
</style>
<style scoped>
    .mypanel .v-expansion-panel__body {
        display: block !important;
        /* Dirty fix for expansion panel content */
    }

    .sectionheader {
        padding-bottom: 10px;
    }

    .shipheader {
        padding: 10px 0px;
    }

    .headertext {
        margin: 16px;
    }

    tr.expand td {
        padding: 0 !important;
    }

    tr.expand .expansion-panel {
        box-shadow: none;
    }

    tr.expand .expansion-panel li {
        border: none;
    }
</style>