import axios from 'axios';
import {
    authHeader,
    config,
    handleResponse,
    tools
} from '../helpers';

export const dataService = {
    get,
    getPerf,
    getById,
    getByIdAndType,
    getByIdPerf,
    post,
    postPerf,
    put,
    deleteById,
    fetchSettings,
    fetchRoutes,
    setActiveRoute,
    uploadRoute,
    deleteRoutes,
    saveSettings,
    getCapabilities,
    uploadFiles,
    downloadFile,
    downloadFileS3,
    cameraCommandImage,
    uploadFileS3,
};

// Add a 401 response interceptor
axios.interceptors.response.use((response) => response, (error) => {
    if (error && error.response && error.response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('user');
        location.reload(true);
    }
    return Promise.reject(error);
});

function get(path) {
    const requestOptions = {
        headers: authHeader()
    };
    return axios.get(`${config.apiUrl}/${path}`, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function getById(path, id) {
    const requestOptions = {
        headers: authHeader()
    };
    return axios.get(`${config.apiUrl}/${path}/${id}`, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function downloadFile(path, id) {
    return axios({
        method: 'get',
        url: `${config.apiUrl}/${path}/${id}`,
        headers: authHeader(),
        responseType: 'blob'
        // responseType: 'arraybuffer'
    }).then((response) => response).catch((e) => {
        console.error(e);
        return [];
    });
    // return axios.get(`${config.apiUrl}/${path}/${id}`, requestOptions)
    //     .then(response => {
    //         return response;
    //     }).catch(e => {
    //         console.error(e);
    //         return [];
    //     });
}

function downloadFileS3(path) {
    return axios({
        method: 'get',
        url: path,
        responseType: 'blob'
        // responseType: 'arraybuffer'
    }).then((response) => response).catch((e) => {
        console.error(e);
        return [];
    });
}

function cameraCommandImage(data) {
    return axios({
        method: 'post',
        url: `${config.apiUrl}/cameracommand`,
        data: data,
        headers: authHeader(),
        responseType: 'blob'
        // responseType: 'arraybuffer'
    }).then((response) => response).catch((e) => {
        console.error(e);
        return [];
    });
}

function getByIdAndType(path, id, type) {
    const requestOptions = {
        headers: authHeader()
    };
    return axios.get(`${config.apiUrl}/${path}/${id}/${type}`, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function post(path, data) {
    const requestOptions = {
        headers: authHeader(),
    };
    return axios.post(`${config.apiUrl}/${path}`, data, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function put(path, data) {
    const requestOptions = {
        headers: authHeader()
    };
    return axios.put(`${config.apiUrl}/${path}`, data, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function deleteById(path, id) {
    const requestOptions = {
        headers: authHeader()
    };
    return axios.delete(`${config.apiUrl}/${path}/${id}`, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function fetchSettings(prefix) {
    const requestOptions = {
        headers: authHeader()
    };
    return axios.get(`${config.apiUrl}/settings?prefix=${prefix}`, requestOptions)
        .then(handleResponse)
        .then((data) => ({
                data,
                prefix
            })).catch((e) => Promise.reject(e));
}

function saveSettings(settings, prefix) {
    const requestOptions = {
        headers: authHeader(),
    };
    return axios.put(`${config.apiUrl}/settings`, {
            settings,
            prefix
        }, requestOptions)
        .then(handleResponse)
        .then((data) => ({
                data,
                prefix
            })).catch((e) => Promise.reject(e));
}

function fetchRoutes(shipId, routeId) {
    if (!shipId || routeId) {
        return Promise.resolve([]);
    }
    const requestOptions = {
        headers: authHeader()
    };
    return axios.get(`${config.apiUrl}/ecdisroutes/${shipId}/${routeId}`, requestOptions)
        .then(handleResponse)
        .then((routes) => routes).catch((e) => Promise.reject(e));
}

function getCapabilities(source) {
    const requestOptions = {
        headers: authHeader()
    };
    return axios.get(`${config.apiUrl}/getcapabilities/${source}`, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function setActiveRoute(shipId, routeId) {
    const requestOptions = {
        headers: authHeader()
    };
    return axios.put(`${config.apiUrl}/ecdisroute`, {
            shipId,
            routeId
        }, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function uploadRoute(data) {
    const requestOptions = {
        headers: authHeader(),
    };
    return axios.post(`${config.apiUrl}/ecdisroute`, {
            shipId: data.shipId,
            data: data.file,
            active: data.active
        }, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function uploadFiles(path, data) {
    const requestOptions = {
        headers: authHeader('multipart/form-data'),
    };
    return axios.post(`${config.apiUrl}/${path}`, data, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function deleteRoutes(shipId, data) {
    const requestOptions = {
        headers: authHeader(),
    };
    return axios.post(`${config.apiUrl}/ecdisroute/delete`, {
            shipId,
            data
        }, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function postPerf(path, data) {
    const requestOptions = {
        headers: authHeader(),
    };
    const perfUrl = tools.getPerfUrl();
    // const perfUrl = 'http://localhost:3000';
    return axios.post(`${perfUrl}/${path}`, data, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function getPerf(path) {
    const requestOptions = {
        headers: authHeader()
    };
    const perfUrl = tools.getPerfUrl();
    // const perfUrl = 'http://localhost:3000';
    return axios.get(`${perfUrl}/${path}`, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function getByIdPerf(path, id) {
    const requestOptions = {
        headers: authHeader()
    };
    const perfUrl = tools.getPerfUrl();
    // const perfUrl = 'http://localhost:3000';
    return axios.get(`${perfUrl}/${path}/${id}`, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}

function uploadFileS3(path, data) {
    return axios.post(`${path}`, data)
        .then(handleResponse)
        .then((data) => data).catch((e) => Promise.reject(e));
}
