'use strict'

const moment = require('moment');
import {
  store
} from '../store';
import {
  tools
} from '../helpers';
const {
  jsPDF
} = require("jspdf");
require('./Roboto-Regular-normal.js');
require('./RobotoStrikethrough-Regular-normal.js');
require('jspdf-autotable');

const pdf = {
  create,
};

/**
 * @description Create PDF
 * @param {object} pdfData Static entries and keys
 * @param {array} entries Logbook entries for PDF. 
 * @param {string} book Print format by book. 
 * 
 * @returns {object} Optionally returns PDF data 
 */

async function create(pdfData, entries, book = false) {
  try {
    const pdfk2 = '';
    const author = 'nauticAi Cloud';
    
    // Find start and end dates
    const start = entries[0].eventTime;
    const end = entries[entries.length - 1].eventTime;
    console.log('Pdf print type book is ' + book)
    if (book === 'ORB1' || book === 'ORB2' || book === 'Cargo' || book === 'MarpolCargo' || book === 'Ballast' || book === 'Annex VI'){
      createCustomPdf(book, start, end, entries, pdfData.v90000, pdfData.v90001, null, pdfk2, author);
    } else if (book && book.startsWith('Garbage')) {
      createGarbage(book, start, end, entries, pdfData.v90000, pdfData.v90001, null, pdfk2, author);
    } else if (book === 'GMDSS') {
      createGMDSS(book, start, end, entries, pdfData.v30002, pdfData.v30003, null, pdfk2, author, pdfData.v30011);
    } else {
      createPdf('FinDeckLogbookHistory', start, end, entries, pdfData.v90000, pdfData.v90001, '', pdfk2);
    } 
  } catch (e) {
    console.error(e);
    store.dispatch('data/progressOff');
  }
}

function createPdf(type, startDate, endDate, entries, v90000, v90001, pdfk, pdfk2) {
  console.log(pdfk2);
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a4',
    putOnlyUsedFonts: true,
    floatPrecision: 'smart', // or "smart", default is 16
    encryption: {
      userPassword: pdfk,
      // ownerPassword: pdfk2,
      userPermissions: ['print', 'copy'],
    },
  });
  if (type === 'FinDeckLogbook' || type === 'FinDeckLogbookHistory') {
    addIntroPages('FinDeckLogbook', doc, startDate, endDate, v90000, v90001);
    // doc.setFont('RobotoStrikethrough-Regular');
    addEntryTables(doc, entries, type);
    // Add footers
    addFooters(doc, v90000, v90001, null, null);
  }
  writePdf(doc, startDate, endDate, null);
}

function addEntryTables(doc, entries, type) {
  doc.setFontSize(10);
  if (!entries || entries.length === 0) {
    return;
  }
  // console.log(doc.getFontList());
  jsPDF.autoTableSetDefaults({
    headStyles: {
      fillColor: 0,
      textColor: 255,
    },
  })
  // Create table rows from data
  for (const entry of entries) {
    if (!entry.content) {
      continue;
    }
    let rows = [];
    if (typeof entry.content === 'string') {
      try {
        entry.content = JSON.parse(entry.content);
        if (entry.content.hidden) { // Filter out hidden entries
          continue;
        }
      } catch (e) {
        console.warn('Failed to parse record content JSON. Content: ' + entry.content + ' Error: ' + e);
        continue;
      }
    }
    let bold = '';
    if (!entry.oldVersion && entry.status !== -10) {
      bold = '[bold]';
    }
    entry.timezone = entry.timezone ? entry.timezone : '+00:00';
    rows.push({
      column1: bold + moment.utc(entry.eventTime).format('DD.MM HH:mm') + ' UTC',
      column2: bold + entry.name,
      column3: `${entry.statusText} (${entry.version})`
    });
    let book = entry.book;
    
    rows.push({
      column1: moment(entry.eventTime).isValid() ? moment(entry.eventTime).utcOffset(entry.timezone).format('DD.MM HH:mm Z') : '',
      column2: entry.summary,
      column3: book
    })
    rows.push({
      column1: '',
      column2: '',
      column3: ''
    })
    if (entry.content.fields && entry.content.fields.length > 0) {
      for (const field of entry.content.fields) {
        if (field.id !== 'eventTime' && field.id !== 'eventDate' && field.type !== 'newline' && !field.hidden) {
          let value = formatValue(field);
          let autoValue = formatAutoValue(field);
          rows.push({
            column1: field.displayUnit ? field.label + ' [' + field.displayUnit + ']' : field.label,
            column2: value + autoValue,
            column3: ''
          })
        }
      }
    }
    rows.push({
      column1: '',
      column2: '',
      column3: ''
    })
    rows.push({
      column1: 'Entry status (version)',
      column2: entry.signer ? `${entry.statusText} (${entry.version}) by ${title(entry.signerTitle, false)}${entry.signer}` : `${entry.statusText} (${entry.version}) by ${entry.author}`,

      column3: ''
    })
    rows.push({
      column1: 'Last update',
      column2: `${moment.utc(entry.updated).format('DD.MM HH:mm')} UTC by ${entry.author}`,
      column3: ''
    })
    rows.push({
      column1: 'Originally created',
      column2: `${moment.utc(entry.created).format('DD.MM HH:mm')} UTC by ${entry.origAuthor}`,
      column3: ''
    })
    rows.push({
      column1: 'Verified by',
      column2: entry.verifier ? `${moment.utc(entry.verifiedTime).format('DD.MM HH:mm')} UTC by ${entry.verifier}` : 'not yet verified',
      column3: ''
    });
    rows.push({
      column1: 'Signed',
      column2: entry.signer ? `${moment.utc(entry.updated).format('DD.MM HH:mm')} UTC by ${title(entry.signerTitle, false)}${entry.signer}` : 'not yet signed',
      column3: ''
    })
    if (entry.attachment) {
      try {
        entry.attachment = typeof entry.attachment === 'string' ? JSON.parse(entry.attachment) : entry.attachment;
      } catch (e) {
        console.log('Failed to parse record attachment JSON. Attachment: ' + entry.attachment + ' Error: ' + e);
      }
      rows.push({
        column1: 'Attachments stored in server',
        column2: entry.attachment.join('\n'),
        column3: ''
      })
    }

    let startY = 10;
    if (doc.lastAutoTable) {
      if (entry.oldVersion || entry.status === -10) {
        startY = doc.lastAutoTable.finalY;
      } else {
        startY = doc.lastAutoTable.finalY + 5;
      }
    }
    doc.autoTable({
      // theme: 'plain',
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: entry.oldVersion || entry.status === -10 ? 'RobotoStrikethrough-Regular' : 'helvetica',
      },
      // Override the default above for the column2
      columnStyles: {
        column1: {
          cellWidth: 70
        },
        column2: {
          cellWidth: 100
        },
        column3: {
          cellWidth: 30,
        }
      },
      rowPageBreak: 'auto',
      tableLineWidth: 0.1,
      tableLineColor: 0,
      bodyStyles: {
        valign: 'top',
        cellPadding: {
          top: 0.5,
          right: 1,
          bottom: 0.5,
          left: 1
        },
        fillColor: entry.oldVersion || entry.status === -10 ? 240 : 255,
      },
      alternateRowStyles: {
        fillColor: entry.oldVersion || entry.status === -10 ? 240 : 255,
      },
      startY: startY,
      margin: {
        top: 10,
        right: 5,
        bottom: 10,
        left: 5
      },
      pageBreak: 'avoid',
      showHead: type === 'FinDeckLogbook' || entry.version === 0 ? 'everyPage' : 'never',
      showFoot: 'never',
      body: rows,
      columns: [{
          header: 'Event time',
          dataKey: 'column1'
        },
        {
          header: 'Entry name',
          dataKey: 'column2'
        },
        {
          header: 'Status (version)',
          dataKey: 'column3'
        },
      ],
      didParseCell: function (data) {
        if (data.row.index === 0) {
          data.cell.styles.cellPadding = {
            top: 1,
            right: 1,
            bottom: 0.5,
            left: 1
          };
        }
        if (data.row.index === 1 && data.column.dataKey === 'column3') {
          data.row.cells[2].rowSpan = 3;
        }
      },
      willDrawCell: function (data) {
        if (data.row.section === 'head') {
          data.cell.styles.fontStyle = 'bold';
          doc.setFont('helvetica', 'normal', 700);
        } else if (data.row.section === 'body' && data.cell.raw && data.cell.raw.toString().includes('[bold]')) {
          // console.log(data.cell.text);
          data.cell.styles.fontStyle = 'bold';
          doc.setFont('helvetica', 'normal', 700);
          data.cell.text = data.cell.text.map(o => {
            return o && o.toString().includes('[bold]') ? o.toString().substring(6, o.length) : o;
          })
        }
      },
    });
  }
}

function addIntroPages(type, doc, startDate, endDate, v90000, v90001) {
  if (type === 'FinDeckLogbook' || type === 'FinDeckLogbookHistory') {
    // this describes the intro pages of a FIN deck logbook
    doc.setProperties({
      title: 'Ship\'s logbook',
      subject: 'Records',
      author: 'nauticAi Log',
      creator: 'nauticAi Log'
    }); // first page
    doc.setFontSize(28);
    doc.text("SHIP'S LOGBOOK", 105, 110, {
      align: 'center'
    })
    // labels and lines
    let labelYP1 = 140
    doc.setFontSize(12);
    doc.setLineWidth(0.5);
    doc.setDrawColor(150);
    doc.text('Name of ship', 10, labelYP1);
    doc.line(40, labelYP1 + 1, 123, labelYP1 + 1);
    doc.text('Call sign', 127, labelYP1);
    doc.line(145, labelYP1 + 1, 200, labelYP1 + 1);
    doc.text('IMO', 10, labelYP1 + 15);
    doc.line(40, labelYP1 + 16, 83, labelYP1 + 16);
    doc.text('Home port', 87, labelYP1 + 15);
    doc.line(109, labelYP1 + 16, 200, labelYP1 + 16);
    doc.text('Entries from', 10, labelYP1 + 30);
    doc.line(40, labelYP1 + 31, 102, labelYP1 + 31);
    doc.text('to', 104, labelYP1 + 30);
    doc.line(109, labelYP1 + 31, 200, labelYP1 + 31);

    // dynamic texts
    if (v90000) {
      doc.text(v90000.name, 41, labelYP1);
      doc.text(v90000.imo, 41, labelYP1 + 15);
      doc.text(v90000.regPort, 110, labelYP1 + 15);
    }
    if (v90001) {
      doc.text(v90001.callsign, 147, labelYP1);
    }
    doc.text(moment.utc(startDate).format('Y-MM-DD'), 41, labelYP1 + 30);
    doc.text(moment.utc(endDate).format('Y-MM-DD'), 110, labelYP1 + 30);

    doc.addPage("a4", "p");
    //second page
    doc.setFontSize(20);
    doc.setTextColor(0);
    doc.text("NOTES CONCERNING THE SHIP", 105, 15, {
      align: 'center'
    })
    // labels and lines
    let labelY = 30
    let rowHeight = 11
    doc.setFontSize(12);
    doc.setLineWidth(0.5);
    doc.text('Name of ship', 10, labelY);
    doc.line(40, labelY + 1, 200, labelY + 1);
    doc.text('Call sign', 10, labelY + rowHeight);
    doc.line(40, labelY + rowHeight + 1, 102, labelY + rowHeight + 1);
    doc.text('MMSI', 105, labelY + rowHeight);
    doc.line(127, labelY + rowHeight + 1, 200, labelY + rowHeight + 1);
    doc.text('Type of ship', 10, labelY + 2 * rowHeight);
    doc.line(40, labelY + 2 * rowHeight + 1, 102, labelY + 2 * rowHeight + 1);
    doc.text('Home port', 105, labelY + 2 * rowHeight);
    doc.line(127, labelY + 2 * rowHeight + 1, 200, labelY + 2 * rowHeight + 1);
    doc.text('Company\n+', 10, labelY + 3 * rowHeight);
    doc.line(40, labelY + 3 * rowHeight + 1, 200, labelY + 3 * rowHeight + 1);
    doc.text('Postal address', 10, labelY + 4 * rowHeight);
    doc.line(40, labelY + 4 * rowHeight + 1, 200, labelY + 4 * rowHeight + 1);
    doc.text('Email address', 10, labelY + 5 * rowHeight);
    doc.line(40, labelY + 5 * rowHeight + 1, 200, labelY + 5 * rowHeight + 1);
    doc.text('Place and time built', 10, labelY + 6 * rowHeight);
    doc.line(50, labelY + 6 * rowHeight + 1, 200, labelY + 6 * rowHeight + 1);
    doc.text('Tonnage', 10, labelY + 7 * rowHeight);
    doc.setFontSize(10);
    doc.text('Gross', 35, labelY + 7 * rowHeight);
    doc.line(46, labelY + 7 * rowHeight + 1, 63, labelY + 7 * rowHeight + 1);
    doc.text('Net', 65, labelY + 7 * rowHeight);
    doc.line(72, labelY + 7 * rowHeight + 1, 89, labelY + 7 * rowHeight + 1);
    doc.text('LOA', 91, labelY + 7 * rowHeight);
    doc.line(99, labelY + 7 * rowHeight + 1, 119, labelY + 7 * rowHeight + 1);
    doc.text('Breadth', 121, labelY + 7 * rowHeight);
    doc.line(134, labelY + 7 * rowHeight + 1, 151, labelY + 7 * rowHeight + 1);
    doc.text('Height', 152, labelY + 7 * rowHeight);
    doc.line(163, labelY + 7 * rowHeight + 1, 200, labelY + 7 * rowHeight + 1);
    doc.setFontSize(12);
    doc.text('Summer draught', 10, labelY + 8 * rowHeight);
    doc.line(44, labelY + 8 * rowHeight + 1, 60, labelY + 8 * rowHeight + 1);
    doc.text('Draughts in ballast condition  forward', 70, labelY + 8 * rowHeight);
    doc.line(141, labelY + 8 * rowHeight + 1, 161, labelY + 8 * rowHeight + 1);
    doc.text('aft', 163, labelY + 8 * rowHeight);
    doc.line(169, labelY + 8 * rowHeight + 1, 200, labelY + 8 * rowHeight + 1);
    doc.text('Official Working Language', 10, labelY + 9 * rowHeight);
    doc.line(63, labelY + 9 * rowHeight + 1, 200, labelY + 9 * rowHeight + 1);
    doc.text('Appendices to the log book', 10, labelY + 10 * rowHeight);
    doc.line(63, labelY + 10 * rowHeight + 1, 200, labelY + 10 * rowHeight + 1);
    doc.line(10, labelY + 11 * rowHeight + 1, 200, labelY + 11 * rowHeight + 1);
    doc.line(10, labelY + 12 * rowHeight + 1, 200, labelY + 12 * rowHeight + 1);
    doc.line(10, labelY + 13 * rowHeight + 1, 200, labelY + 13 * rowHeight + 1);
    doc.line(10, labelY + 14 * rowHeight + 1, 200, labelY + 14 * rowHeight + 1);
    doc.text('Additional entries', 10, labelY + 15 * rowHeight);
    doc.line(44, labelY + 15 * rowHeight + 1, 200, labelY + 15 * rowHeight + 1);
    doc.line(10, labelY + 16 * rowHeight + 1, 200, labelY + 16 * rowHeight + 1);
    doc.line(10, labelY + 17 * rowHeight + 1, 200, labelY + 17 * rowHeight + 1);
    doc.line(10, labelY + 18 * rowHeight + 1, 200, labelY + 18 * rowHeight + 1);
    doc.line(10, labelY + 19 * rowHeight + 1, 200, labelY + 19 * rowHeight + 1);

    // dynamic texts
    if (v90000) {
      doc.text(v90000.name, 41, labelY);
      doc.text(v90000.regPort, 128, labelY + 2 * rowHeight);
    }
    if (v90001) {
      doc.text(v90001.callsign, 41, labelY + rowHeight);
      doc.text(v90001.mmsi, 128, labelY + rowHeight);
      doc.text(v90001.type, 41, labelY + 2 * rowHeight);
      if (v90001.regOwners && v90001.regOwners.length > 80) { // Split to 2 line if long text
        const words = v90001.regOwners.split(' ');
        let firstLine = '';
        let secondLine = '';
        for (const word of words) {
          if (firstLine.length < 70) {
            firstLine += word + ' ';
          } else {
            secondLine += word + ' ';
          }
        }
        doc.text(firstLine, 41, labelY + 3 * rowHeight);
        doc.text(secondLine, 41, labelY + 4 * rowHeight);
      } else {
        doc.text(v90001.regOwners, 41, labelY + 3 * rowHeight);
      }
      doc.text(v90001.regOwnersEmail, 41, labelY + 5 * rowHeight);
      doc.text(v90001.built, 51, labelY + 6 * rowHeight);
      doc.text(v90001.gt, 47, labelY + 7 * rowHeight);
      doc.text(v90001.nt, 73, labelY + 7 * rowHeight);
      doc.text(v90001.loa + ' m', 100, labelY + 7 * rowHeight);
      doc.text(v90001.breadth + ' m', 135, labelY + 7 * rowHeight);
      doc.text(v90001.height + ' m', 164, labelY + 7 * rowHeight);
      doc.text(v90001.tMidSummer + ' m', 44, labelY + 8 * rowHeight);
      doc.text(v90001.tForeBallast + ' m', 142, labelY + 8 * rowHeight);
      doc.text(v90001.tAftBallast + ' m', 172, labelY + 8 * rowHeight);
      doc.text(v90001.language, 65, labelY + 9 * rowHeight);
    }
    doc.addPage("a4", "p");
  }
  return doc;
}

function addFooters(doc, v90000, v90001, author = null, type = null) {
  const pageCount = doc.internal.getNumberOfPages();
  doc.setTextColor(150);
  for (var i = 1; i <= pageCount; i++) {
    // let posX = 105;
    // let posY1 = 289;
    // let posY2 = 293;
    doc.setFontSize(9);
    doc.setPage(i);
    const width = doc.internal.pageSize.width;
    const height = doc.internal.pageSize.height;
    if (!author) {
      doc.text(`The document was created ${moment.utc().format('D.M.Y HH:mm')} UTC and is a certified copy of data from a nauticAi Log version X for vessel ${v90000 ? v90000.name : 'N/A'}.`, width/2, height-3, {
        align: 'center'
      });
    } else {
      doc.text(`The document was created ${moment.utc().format('D.M.Y HH:mm')} UTC by ${author} and is a certified copy of data`, width/2, height-8, {
        align: 'center'
      });
      doc.text(`from a nauticAi Log version X for vessel ${v90000 ? v90000.name : 'N/A'}.`, width/2, height-4, {
          align: 'center'
      });
    }
    doc.setFontSize(11);
    doc.text(`${i} (${pageCount})`, width-10, 6, {
      align: 'center'
    });
    if (type && (type.startsWith('ORB') || type === 'Cargo' || type === 'MarpolCargo' || type === 'Ballast' || type === 'Annex VI') && i > 1) {
      doc.setTextColor(0);
      // labels and lines
      let labelY = 10
      doc.setFontSize(16);
      if (type === 'ORB1') {
        doc.text("PART I - MACHINERY SPACE OPERATIONS (ALL SHIPS)", width/2, labelY, { align: 'center' });
      } else if (type === 'ORB2') {
        doc.text("PART II - CARGO/BALLAST OPERATIONS (OIL TANKERS)", width/2, labelY, { align: 'center' });
      } else if (type === 'Cargo' || type === 'MarpolCargo') {
        doc.text("Cargo/Ballast Operations", width/2, labelY, { align: 'center' });
      } else if (type === 'Ballast') {
        doc.text("Ballast Water Operations", 105, labelY, {
          align: 'center'
        })
      } else if (type === 'Annex VI') {
        doc.text("MARPOL ANNEX VI RECORD BOOK", 105, labelY, {
          align: 'center'
        })
      }
      if (type.startsWith('ORB') || type === 'Annex VI') {
        doc.setFontSize(9);
        doc.setLineWidth(0.5);
        doc.text('NAME OF SHIP', 10, labelY + 10);
        doc.line(70, labelY + 11, 180, labelY + 11);
        doc.text('DISTINCTIVE NUMBER OR LETTERS', 10, labelY + 20);
        doc.line(70, labelY + 21, 180, labelY + 21);
        doc.text('SIGNATURE OF MASTER', 10, 275);
        doc.line(70, 276, 180, 276);
        doc.text(v90000 ? v90000.name.toString().toUpperCase() : 'N/A' , 71, labelY + 10);
        doc.text(v90001 ? v90001.callsign.toString().toUpperCase() : 'N/A', 71, labelY + 20);
      } else {
        doc.setFontSize(12);
        doc.setLineWidth(0.5);
        doc.text('Name of ship', 10, labelY + 10);
        doc.line(70, labelY + 11, 180, labelY + 11);
        doc.text('Distinctive number or letters', 10, labelY + 20);
        doc.line(70, labelY + 21, 180, labelY + 21);
        doc.text('Signature of Master', 10, 275);
        doc.line(70, 276, 180, 276);
        doc.text(v90000 ? v90000.name : 'N/A' , 71, labelY + 10);
        if (type === 'Ballast') {
          doc.text(v90000 ? v90000.imo : 'N/A', 71, labelY + 20);
        } else {
          doc.text(v90001 ? v90001.callsign : 'N/A', 71, labelY + 20);
        }
      }
    }
  }
}

function createCustomPdf(book, startDate, endDate, entries, v90000, v90001, pdfk, pdfk2, author) {
  console.log(pdfk2);
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a4',
    putOnlyUsedFonts: true,
    floatPrecision: 'smart', // or "smart", default is 16
    encryption: {
      userPassword: pdfk,
      // ownerPassword: pdfk2,
      userPermissions: ['print', 'copy'],
    },
  });

  if (book === 'ORB1') {
    // this describes the intro pages of an Oil Record Book Pt I
    doc.setProperties({
        title: 'Oil Record Book - Pt I',
        subject: 'Records',
        author: 'nauticAi Log',
        creator: 'nauticAi Log'
    });// first page
    doc.setFontSize(28);
    doc.text("OIL RECORD BOOK", 105, 110, {
        align: 'center'
    })
    doc.setFontSize(14);
    doc.text("PT I (ALL SHIPS) - MACHINERY SPACE OPERATIONS", 105, 120, {align: 'center'})
  } else if (book === 'ORB2') {
    // this describes the intro pages of an Oil Record Book Pt II
    doc.setProperties({
      title: 'Oil Record Book - Pt II',
      subject: 'Records',
      author: 'nauticAi Log',
      creator: 'nauticAi Log'
    });// first page
    doc.setFontSize(28);
    doc.text("OIL RECORD BOOK", 105, 110, {
        align: 'center'
    })
    doc.setFontSize(14);
    doc.text("PT II (OIL TANKERS) - CARGO/BALLAST OPERATIONS", 105, 120, {align: 'center'})
  } else if (book === 'Cargo' || book === 'MarpolCargo') {
    // this describes the intro pages of an Cargo Record Book
    doc.setProperties({
      title: 'Cargo Record Book',
      subject: 'Records',
      author: 'nauticAi Log',
      creator: 'nauticAi Log'
    });// first page
    doc.setFontSize(28);
    doc.text("CARGO RECORD BOOK", 105, 110, {
      align: 'center'
    })
    doc.setFontSize(14);
    doc.text("Cargo Record Book for Ships Carrying Noxious Liquid Substances in Bulk", 105, 120, {
      align: 'center'
    })
  } else if (book === 'Ballast') {
    // this describes the intro pages of an Ballast Water Record Book
    doc.setProperties({
      title: 'Ballast Water Record Book',
      subject: 'Records',
      author: 'nauticAi Log',
      creator: 'nauticAi Log'
    });// first page
    doc.setFontSize(28);
    doc.text("BALLAST WATER RECORD BOOK", 105, 110, {
      align: 'center'
    })
    doc.setFontSize(12);
    doc.text("International Convention for the Control and Management of Ships' Ballast Water and Sediments", 105, 120, {
      align: 'center'
    })
    doc.text("As per BWM.2/Circ.80", 105, 127, {
      align: 'center'
    })
  } else if (book === 'Annex VI') {
    // this describes the intro pages of an Ballast Water Record Book
    doc.setProperties({
      title: 'Marpol Annex VI Record Book',
      subject: 'Records',
      author: 'nauticAi Log',
      creator: 'nauticAi Log'
    });// first page
    doc.setFontSize(28);
    doc.text("MARPOL ANNEX VI RECORD BOOK", 105, 100, {
      align: 'center'
    })
    doc.setFontSize(12);
    doc.text("This record book contains entries as per MARPOL Annex VI, concerning", 105, 110, {
      align: 'center'
    })
    doc.text("Regulation 12 (Ozone Depleting Substances), Regulation 13 (NOx),", 105, 116, {
      align: 'center'
    })
    doc.text("Regulation 14 (SOx and fuel change-over) and Regulation 18 (Fuel oil quality)", 105, 122, {
      align: 'center'
    })
  }

  // labels and lines
  let labelYP1 = 140
  doc.setFontSize(12);
  book.startsWith('ORB') || book === 'Annex VI' ? doc.setFontSize(9) : doc.setFontSize(12);
  doc.setLineWidth(0.5);
  doc.setDrawColor(150);
  let text = 'Name of ship';
  doc.text(book.startsWith('ORB') || book === 'Annex VI' ? text.toUpperCase() : text, 10, labelYP1);
  doc.line(70, labelYP1 + 1, 180, labelYP1 + 1);

  if (book === 'Ballast') {
    doc.text('IMO number', 10, labelYP1 + 15);
    doc.line(70, labelYP1 + 16, 180, labelYP1 + 16);
    doc.text('Gross tonnage', 10, labelYP1 + 30);
    doc.line(70, labelYP1 + 31, 180, labelYP1 + 31);
    doc.text('Flag', 10, labelYP1 + 45);
    doc.line(70, labelYP1 + 46, 180, labelYP1 + 46);
    doc.text('Total ballast capacity (m3)', 10, labelYP1 + 60);
    doc.line(70, labelYP1 + 61, 180, labelYP1 + 61);
    doc.text('The ship is provided with a ballast water management plan', 10, labelYP1 + 75);
    doc.line(122, labelYP1 + 76, 180, labelYP1 + 76);
    doc.text('Entries from', 10, labelYP1 + 90);
    doc.line(70, labelYP1 + 91, 102, labelYP1 + 91);
    doc.text('to', 104, labelYP1 + 90);
    doc.line(109, labelYP1 + 91, 180, labelYP1 + 91);
    if (v90000) {
      doc.text(v90000.name, 71, labelYP1);
      doc.text(v90000.imo, 71, labelYP1 + 15);
      doc.text(v90000.flag, 71, labelYP1 + 45);
    }
    if (v90001) {
      doc.text(v90001.gt, 71, labelYP1 + 30);
      doc.text(v90001.ballastCapacity, 71, labelYP1 + 60);
      doc.text(v90001.bwmp, 123, labelYP1 + 75);
    }
    doc.text(moment.utc(startDate).format('Y-MM-DD'), 71, labelYP1 + 90);
    doc.text(moment.utc(endDate).format('Y-MM-DD'), 120, labelYP1 + 90)
  } else {
    text = 'Distinctive number or letters';
    doc.text(book.startsWith('ORB') || book === 'Annex VI' ? text.toUpperCase() : text, 10, labelYP1 + 15);
    doc.line(70, labelYP1 + 16, 180, labelYP1 + 16);
    text = 'IMO number';
    doc.text(book.startsWith('ORB') || book === 'Annex VI' ? text.toUpperCase() : text, 10, labelYP1 + 30);
    doc.line(70, labelYP1 + 31, 180, labelYP1 + 31);
    text = 'Port of registry';
    doc.text(book.startsWith('ORB') || book === 'Annex VI' ? text.toUpperCase() : text, 10, labelYP1 + 45);
    doc.line(70, labelYP1 + 46, 180, labelYP1 + 46);
    text = 'Gross tonnage';
    doc.text(book.startsWith('ORB') || book === 'Annex VI' ? text.toUpperCase() : text, 10, labelYP1 + 60);
    doc.line(70, labelYP1 + 61, 90, labelYP1 + 61);
    text = 'Deadweight in metric tonnes';
    doc.text(book.startsWith('ORB') || book === 'Annex VI' ? text.toUpperCase() : text, 93, labelYP1 + 60);
    doc.line(148, labelYP1 + 61, 180, labelYP1 + 61);
  }

  if (book === 'ORB1') {
    doc.text('ENTRIES FROM', 10, labelYP1 + 75);
    doc.line(70, labelYP1 + 76, 102, labelYP1 + 76);
    doc.text('TO', 104, labelYP1 + 75);
    doc.line(109, labelYP1 + 76, 180, labelYP1 + 76);
    doc.text('NOTE: OIL RECORD BOOK PART I SHALL BE PROVIDED TO EVERY OIL TANKER OF 150 GROSS TONNAGE AND ABOVE', 10, labelYP1 + 90);
    doc.text('AND EVERY SHIP OF 400 GROSS TONNAGE AND ABOVE, OTHER THAN OIL TANKERS, TO RECORD RELEVANT MACHINERY', 10, labelYP1 + 95);
    doc.text('SPACE OPERATIONS. FOR OIL TANKERS, OIL RECORD BOOK PART II SHALL ALSO BE PROVIDED TO RECORD RELEVANT', 10, labelYP1 + 100);
    doc.text('CARGO/BALLAST OPERATIONS. ', 10, labelYP1 + 105);
    if (v90000) {
      doc.text(v90000.name.toString().toUpperCase(), 71, labelYP1);
      doc.text(v90000.imo.toString().toUpperCase(), 71, labelYP1 + 30);
      doc.text(v90000.regPort.toString().toUpperCase(), 71, labelYP1 + 45);
    }
    if (v90001) {
      doc.text(v90001.callsign.toString().toUpperCase(), 71, labelYP1 + 15);
      doc.text(v90001.gt.toString().toUpperCase(), 71, labelYP1 + 60);
      doc.text(v90001.dwt.toString().toUpperCase(), 149, labelYP1 + 60);
    }
    doc.text(moment.utc(startDate).format('Y-MM-DD'), 71, labelYP1 + 75);
    doc.text(moment.utc(endDate).format('Y-MM-DD'), 120, labelYP1 + 75);
  } else if (book === 'ORB2') {
    doc.text('TOTAL CARGO CAPACITY (M3)', 10, labelYP1 + 75);
    doc.line(70, labelYP1 + 76, 180, labelYP1 + 76);
    doc.text('ENTRIES FROM', 10, labelYP1 + 90);
    doc.line(70, labelYP1 + 91, 102, labelYP1 + 91);
    doc.text('TO', 104, labelYP1 + 90);
    doc.line(109, labelYP1 + 91, 180, labelYP1 + 91);
    doc.text('NOTE: EVERY OIL TANKER OF 150 GROSS TONNAGE AND ABOVE SHALL BE PROVIDED WITH OIL RECORD BOOK PART II', 10, labelYP1 + 105);
    doc.text('TO RECORD RELEVANT CARGO/BALLAST OPERATIONS. SUCH A TANKER SHALL ALSO BE PROVIDED WITH OIL RECORD BOOK', 10, labelYP1 + 110);
    doc.text('PART I TO RECORD RELEVANT MACHINERY SPACE OPERATIONS.', 10, labelYP1 + 115);
    if (v90000) {
      doc.text(v90000.name.toString().toUpperCase(), 71, labelYP1);
      doc.text(v90000.imo.toString().toUpperCase(), 71, labelYP1 + 30);
      doc.text(v90000.regPort.toString().toUpperCase(), 71, labelYP1 + 45);
    }
    if (v90001) {
      doc.text(v90001.callsign.toString().toUpperCase(), 71, labelYP1 + 15);
      doc.text(v90001.gt.toString().toUpperCase(), 71, labelYP1 + 60);
      doc.text(v90001.dwt.toString().toUpperCase(), 149, labelYP1 + 60);
      doc.text(v90001.cargoCapacity.toString().toUpperCase(), 71, labelYP1 + 75);
    }
    doc.text(moment.utc(startDate).format('Y-MM-DD'), 71, labelYP1 + 90);
    doc.text(moment.utc(endDate).format('Y-MM-DD'), 120, labelYP1 + 90);
  } else if (book === 'Cargo' || book === 'MarpolCargo') {
    doc.text('Total cargo capacity (m3)', 10, labelYP1 + 75);
    doc.line(70, labelYP1 + 76, 180, labelYP1 + 76);
    doc.text('Entries from', 10, labelYP1 + 90);
    doc.line(70, labelYP1 + 91, 102, labelYP1 + 91);
    doc.text('to', 104, labelYP1 + 90);
    doc.line(109, labelYP1 + 91, 180, labelYP1 + 91);
    if (v90000) {
      doc.text(v90000.name, 71, labelYP1);
      doc.text(v90000.imo, 71, labelYP1 + 30);
      doc.text(v90000.regPort, 71, labelYP1 + 45);
    }
    if (v90001) {
      doc.text(v90001.callsign, 71, labelYP1 + 15);
      doc.text(v90001.gt, 71, labelYP1 + 60);
      doc.text(v90001.dwt, 149, labelYP1 + 60);
      doc.text(v90001.cargoCapacity, 71, labelYP1 + 75);
    }
    doc.text(moment.utc(startDate).format('Y-MM-DD'), 71, labelYP1 + 90);
    doc.text(moment.utc(endDate).format('Y-MM-DD'), 120, labelYP1 + 90);
  } else if (book === 'Annex VI') {
    doc.text('ENTRIES FROM', 10, labelYP1 + 75);
    doc.line(70, labelYP1 + 76, 102, labelYP1 + 76);
    doc.text('TO', 104, labelYP1 + 75);
    doc.line(109, labelYP1 + 76, 180, labelYP1 + 76);
    if (v90000) {
      doc.text(v90000.name.toString().toUpperCase(), 71, labelYP1);
      doc.text(v90000.imo.toString().toUpperCase(), 71, labelYP1 + 30);
      doc.text(v90000.regPort.toString().toUpperCase(), 71, labelYP1 + 45);
    }
    if (v90001) {
      doc.text(v90001.callsign.toString().toUpperCase(), 71, labelYP1 + 15);
      doc.text(v90001.gt.toString().toUpperCase(), 71, labelYP1 + 60);
      doc.text(v90001.dwt.toString().toUpperCase(), 149, labelYP1 + 60);
    }
    doc.text(moment.utc(startDate).format('Y-MM-DD'), 71, labelYP1 + 75);
    doc.text(moment.utc(endDate).format('Y-MM-DD'), 120, labelYP1 + 75);
  }
  doc.addPage("a4", "p");
  // second page
  
  // Entry table
  if (entries.length > 0) {
    // Create table rows from data
    let rows = [];
    for (const entry of entries) {
      if (!entry.content || entry.status === 10) {
        continue;
      }
      if (typeof entry.content === 'string') {
        try {
          entry.content = JSON.parse(entry.content);
          if (entry.content.hidden) { // Filter out hidden entries
            continue;
          }
        } catch (e) {
          console.log('Failed to parse record content JSON. Content: ' + entry.content + ' Error: ' + e);
          continue;
        }
      }
      if (entry.content.pdf && 
        (entry.content.pdf.type.toUpperCase() === book.toUpperCase() || (entry.content.pdf.type.toUpperCase() === 'CARGO' && book === 'MarpolCargo' )) &&
        entry.content.pdf.items.length > 0) {
        let firstRow = true;
        let font = entry.status === -10 || entry.oldVersion ? '[strike]' : ''
        for (const key in entry.content.pdf.items) {
          if (!entry.content.pdf.items[key].condition || (entry.content.pdf.items[key].condition && checkFormula(entry.content.pdf.items[key].condition))) {
            let value = entry.content.pdf.items[key].type !== 'table' ? formatValue(entry.content.pdf.items[key]) : entry.content.pdf.items[key].value;
            rows.push({
              column1: firstRow ? font + moment.utc(entry.eventTime).format('DD-MMM-Y').toUpperCase() : '',
              column2: firstRow || entry.content.pdf.items[key].code ? font + entry.content.pdf.code : '',
              column3: value ? font + entry.content.pdf.items[key].item : '',
              column4: entry.content.pdf.items[key].type !== 'table' && value ? font + value : entry.content.pdf.items[key].type === 'table' && value ? value : '',
            });
            firstRow = false;
          }
        }
        rows.push({
          column1: '',
          column2: '',
          column3: book === 'Ballast' && entry.content.pdf.authorItemNr ? entry.content.pdf.authorItemNr : '',
          column4: font + `[small]Created by ${entry.origAuthor}${title(entry.authorTitle)}\n${moment.utc(entry.created).format('DD-MMM-Y HH:mm').toUpperCase()} UTC`,
        });
        if (entry.verifier && entry.verifiedTime) {
          rows.push({
            column1: '',
            column2: '',
            column3: book === 'Ballast' && entry.content.pdf.authorItemNr ? entry.content.pdf.authorItemNr : '',
            column4: font + `[small]Verified by ${entry.verifier}${title(entry.verifierTitle)}\n${moment.utc(entry.verifiedTime).format('DD-MMM-Y HH:mm').toUpperCase()} UTC`,
          });
        }
        if (entry.signer) {
          rows.push({
            column1: '',
            column2: '',
            column3: book === 'Ballast' && entry.content.pdf.authorItemNr ? entry.content.pdf.authorItemNr : '',
            column4: font + `[small]Digitally signed by ${entry.signer}${title(entry.signerTitle)}\n${moment.utc(entry.updated).format('DD-MMM-Y HH:mm').toUpperCase()} UTC`,
          });
        }
      }
    }
    let columns = [{
        header: 'Date',
        dataKey: 'column1'
      },
      {
        header: 'Code',
        dataKey: 'column2'
      },
      {
        header: 'Item',
        dataKey: 'column3'
      },
      {
        header: 'Record of Operations / signature of officer(s) in charge',
        dataKey: 'column4'
      },
    ];
    doc.autoTable({
      theme: 'grid',
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        fontSize: book === 'ORB1' || book === 'ORB2' || book === 'Annex VI' ? 9 : 12,
        lineWidth: 0.2,
        valign: 'middle',
        fillColor: 255,
        textColor: 0,
        cellPadding: {
          top: 0.5,
          right: 1,
          bottom: 0.5,
          left: 1
        },
      },
      headStyles: {
        fontStyle: 'bold',
        halign: 'center',
        fillColor: 255,
        textColor: 0,
      },
      columnStyles: {
        column1: {
          cellWidth: 32,
          halign: 'center',
        },
        column2: {
          cellWidth: 16,
          halign: 'center',
        },
        column3: {
          cellWidth: 16,
          halign: 'center',
        },
        column4: {
          cellWidth: 126,
        }
      },
      rowPageBreak: 'auto',
      tableLineWidth: 0.2,
      tableLineColor: 0,
      bodyStyles: {
        valign: 'top',
      },
      margin: {
        top: book === 'Ballast' ? 40 : 41,
        right: 10,
        bottom: 35,
        left: 10
      },
      pageBreak: 'auto',
      showHead: 'everyPage',
      showFoot: 'never',
      body: rows,
      columns: columns,
      didParseCell: function (data) {
        if (data.row.section === 'body' && data.cell.raw && data.cell.raw.toString().includes('[small]')) {
          data.cell.styles.valign = 'bottom';
          data.cell.styles.halign = 'right';
          data.cell.styles.cellPadding = {
            top: 0.5,
            right: 2,
            bottom: 0.5,
            left: 0.5,
          };
        }
        if (data.row.section === 'body' && Array.isArray(data.cell.raw)) {
          data.cell.styles.minCellHeight = 8;
          data.cell.styles.maxCellHeight = 8;
          data.cell.styles.cellPadding = 0;
          data.cell.styles.fontSize = 9;
          data.cell.styles.textColor = 0;
          data.cell.styles.valign = 'top';
        }
      },
      didDrawCell: function (data) {
        // Create nested table in case values is an array (for example ORB 2 record)
        if (data.row.section === 'body' && Array.isArray(data.cell.raw)) {
          // debug(data.row, data.cell);
          let values = data.cell.raw.filter((o) => o && o !== ' ');
          // debug('values:', values);
          if (values && values.length > 0 && data.cell.contentHeight > 4) { // Check that there data and cell height is over 4, since under 8 is splitted on 2 rows
            doc.autoTable({
              theme: 'grid',
              styles: {
                overflow: 'linebreak',
                cellWidth: data.cell.width/values.length,
                fontSize: 9,
                lineWidth: {top: 0.2, right: 0.2, bottom: 0, left: 0.2},
                valign: 'top',
                fillColor: 255,
                textColor: 0,
                cellPadding: {
                  top: 0.5,
                  right: 1,
                  bottom: 0.5,
                  left: 1
                },
                maxCellHeight: 8,
                minCellHeight: 8,
              },
              lineColor: 10,
              showHead: 'never',
              showFoot: 'never',
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: data.cell.width,
              body: [
                values
              ],
            })
          }
        }
      },
      willDrawCell: function (data) {
        if (data.row.section === 'body' && data.cell.raw && data.cell.raw.toString().includes('[strike]')) {
          doc.setFont('RobotoStrikethrough-Regular', 'normal', 400);
          data.cell.text = data.cell.text.map(o => {
            return o && o.toString().includes('[strike]') ? o.toString().substring(8, o.length) : o;
          })
        }
        if (data.row.section === 'body' && data.cell.raw && data.cell.raw.toString().includes('[small]')) {
          if (book.startsWith('ORB') || book === 'Annex VI' ) {
            doc.setFontSize(7);
          } else {
            doc.setFontSize(10);
          }
          data.cell.text = data.cell.text.map(o => {
            return o && o.toString().includes('[small]') ? o.toString().substring(7, o.length) : o;
          })
        }
        // if (book.startsWith('ORB')) { // All text UPPERCASE
        //   data.cell.text = data.cell.text.map(o => {
        //     return typeof o === 'string' ? o.toUpperCase() : o;
        //   })
        // }
      },
    });
  }
  // End of entry table

  addFooters(doc, v90000, v90001, author, book);
  
  writePdf(doc, startDate, endDate, book);
}

function createGMDSS(book, startDate, endDate, entries, v30002, v30003, pdfk, pdfk2, author, v30011) {
  console.log(pdfk2);
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a4',
    putOnlyUsedFonts: true,
    floatPrecision: 'smart', // or "smart", default is 16
    encryption: {
      userPassword: pdfk,
      // ownerPassword: pdfk2,
      userPermissions: ['print', 'copy'],
    },
  });

  // this describes the intro pages of a GMDSS Radio logbook
  doc.setProperties({
    title: 'GMDSS Radio logbook',
    subject: 'Records',
    author: 'nauticAi Log',
    creator: 'nauticAi Log'
  });// first page
  doc.setFontSize(28);
  doc.text("GMDSS RADIO LOGBOOK", 105, 110, {
    align: 'center'
  })
  // labels and lines
  let labelYP1 = 140
  doc.setFontSize(12);
  doc.setLineWidth(0.5);
  doc.setDrawColor(150);
  doc.text('Name of ship', 10, labelYP1);
  doc.line(40, labelYP1 + 1, 123, labelYP1 + 1);
  doc.text('Call sign', 127, labelYP1);
  doc.line(145, labelYP1 + 1, 200, labelYP1 + 1);
  doc.text('IMO', 10, labelYP1 + 15);
  doc.line(40, labelYP1 + 16, 83, labelYP1 + 16);
  doc.text('Port of registry', 87, labelYP1 + 15);
  doc.line(116, labelYP1 + 16, 200, labelYP1 + 16);
  doc.text('MMSI', 10, labelYP1 + 30);
  doc.line(40, labelYP1 + 31, 83, labelYP1 + 31);
  doc.text('Sea areas', 87, labelYP1 + 30);
  doc.line(109, labelYP1 + 31, 200, labelYP1 + 31);
  doc.text('Entries from', 10, labelYP1 + 45);
  doc.line(40, labelYP1 + 46, 102, labelYP1 + 46);
  doc.text('to', 104, labelYP1 + 45);
  doc.line(109, labelYP1 + 46, 200, labelYP1 + 46);

  // dynamic texts
  if (v30002 && v30002.name) {
    doc.text(v30002.name, 41, labelYP1);
    doc.text(v30002.callsign, 147, labelYP1);
    doc.text(v30002.imo, 41, labelYP1 + 15);
    doc.text(v30002.regPort, 117, labelYP1 + 15);
    doc.text(v30002.mmsi, 41, labelYP1 + 30);
    doc.text(v30002.seaArea, 110, labelYP1 + 30);
  }
  doc.text(moment.utc(startDate).format('Y-MM-DD'), 41, labelYP1 + 45);
  doc.text(moment.utc(endDate).format('Y-MM-DD'), 110, labelYP1 + 45);

  doc.addPage("a4", "p");
  //second page
  doc.setFontSize(20);
  doc.setTextColor(0);
  doc.text("Section A - PARTICULARS OF SHIP", 105, 15, {
    align: 'center'
  })
  // labels and lines
  let labelY = 25
  let rowHeight = 11
  doc.setFontSize(12);
  doc.setLineWidth(0.5);
  doc.text('1.', 10, labelY);
  doc.text('Name of ship', 15, labelY);
  doc.line(44, labelY + 1, 200, labelY + 1);
  doc.text('Call sign', 15, labelY + rowHeight);
  doc.line(44, labelY + rowHeight + 1, 102, labelY + rowHeight + 1);
  doc.text('MMSI', 105, labelY + rowHeight);
  doc.line(133, labelY + rowHeight + 1, 200, labelY + rowHeight + 1);
  doc.text('IMO number', 15, labelY + 2 * rowHeight);
  doc.line(44, labelY + 2 * rowHeight + 1, 102, labelY + 2 * rowHeight + 1);
  doc.text('Port of registry', 105, labelY + 2 * rowHeight);
  doc.line(133, labelY + 2 * rowHeight + 1, 200, labelY + 2 * rowHeight + 1);
  doc.text('Gross tonnage', 15, labelY + 3 * rowHeight);
  doc.line(44, labelY + 3 * rowHeight + 1, 102, labelY + 3 * rowHeight + 1);
  doc.text('Date keel laid', 105, labelY + 3 * rowHeight);
  doc.line(133, labelY + 3 * rowHeight + 1, 200, labelY + 3 * rowHeight + 1);
  doc.text('Sea area(s) in which the ship is certified to operate', 15, labelY + 4 * rowHeight);
  doc.line(111, labelY + 4 * rowHeight + 1, 200, labelY + 4 * rowHeight + 1);
  doc.text('Date of expiry of current Safety Radio Certificate', 15, labelY + 5 * rowHeight);
  doc.line(111, labelY + 5 * rowHeight + 1, 200, labelY + 5 * rowHeight + 1);
  doc.text('2.', 10, labelY + 6 * rowHeight);
  doc.text('Indicate method(s) used to ensure availability of radio facilities:', 15, labelY + 6 * rowHeight);
  doc.text('a) Duplication of equipment', 15, labelY + 7 * rowHeight);
  doc.line(78, labelY + 7 * rowHeight + 1, 88, labelY + 7 * rowHeight + 1);
  doc.text('b) Shore-based maintenance', 15, labelY + 8 * rowHeight);
  doc.line(78, labelY + 8 * rowHeight + 1, 88, labelY + 8 * rowHeight + 1);
  doc.text('Details of service company (name and address):', 19.7, labelY + 9 * rowHeight);
  doc.line(19.7, labelY + 10 * rowHeight + 1, 200, labelY + 10 * rowHeight + 1);
  doc.line(19.7, labelY + 11 * rowHeight + 1, 200, labelY + 11 * rowHeight + 1);
  doc.text('c) At-sea maintenance capability', 15, labelY + 12 * rowHeight);
  doc.line(78, labelY + 12 * rowHeight + 1, 88, labelY + 12 * rowHeight + 1);
  doc.text('Registered owner(s), managing owner or agent and their address(es)', 10, labelY + 14 * rowHeight);
  doc.line(10, labelY + 15 * rowHeight + 1, 200, labelY + 15 * rowHeight + 1);
  doc.line(10, labelY + 16 * rowHeight + 1, 200, labelY + 16 * rowHeight + 1);
  doc.line(10, labelY + 17 * rowHeight + 1, 200, labelY + 17 * rowHeight + 1);

  // dynamic texts
  if (v30002 && v30002.name) {
    doc.text(v30002.name, 45, labelY);
    doc.text(v30002.callsign, 45, labelY + rowHeight);
    doc.text(v30002.mmsi, 135, labelY + rowHeight);
    doc.text(v30002.regPort, 135, labelY + 2 * rowHeight);
    doc.text(v30002.imo, 45, labelY + 2 * rowHeight);
    doc.text(v30002.gt, 45, labelY + 3 * rowHeight);
    doc.text(v30002.keelLaid, 135, labelY + 3 * rowHeight);
    doc.text(v30002.seaArea, 113, labelY + 4 * rowHeight);
    doc.text(v30002.srcExpiry, 113, labelY + 5 * rowHeight);
    doc.text(v30002.duplication, 79, labelY + 7 * rowHeight);
    doc.text(v30002.shoreBased, 79, labelY + 8 * rowHeight);
    doc.text(v30002.serviceCompany, 21, labelY + 10 * rowHeight);
    doc.text(v30002.atSea, 79, labelY + 12 * rowHeight);
    doc.text(v30002.regOwners, 11, labelY + 15 * rowHeight);
  }

  // Optional fields in some ships
  if (v30002 && typeof v30002.emContactsAshore !== 'undefined') {
    doc.text('Contact person info on shore in case of emergency', 10, labelY + 16 * rowHeight);
    doc.line(110, labelY + 16 * rowHeight + 1, 200, labelY + 16 * rowHeight + 1);
    doc.text('Identification Numbers of ship´s satellite teminal(s)', 10, labelY + 17 * rowHeight);
    doc.line(110, labelY + 17 * rowHeight + 1, 200, labelY + 17 * rowHeight + 1);
    doc.text('Number of ship´s radiotelex', 10, labelY + 18 * rowHeight);
    doc.line(110, labelY + 18 * rowHeight + 1, 200, labelY + 18 * rowHeight + 1);
    doc.text('Radio Licence number', 10, labelY + 19 * rowHeight);
    doc.line(110, labelY + 19 * rowHeight + 1, 200, labelY + 19 * rowHeight + 1);
    doc.text('Radio Licence date of issue', 10, labelY + 20 * rowHeight);
    doc.line(110, labelY + 20 * rowHeight + 1, 200, labelY + 20 * rowHeight + 1);
    doc.text('Radio Licence expiry date', 10, labelY + 21 * rowHeight);
    doc.line(110, labelY + 21 * rowHeight + 1, 200, labelY + 21 * rowHeight + 1);
    doc.text('Accounting authority AAIC', 10, labelY + 22 * rowHeight);
    doc.line(110, labelY + 22 * rowHeight + 1, 200, labelY + 22 * rowHeight + 1);
    if (v30002) {
      doc.text(v30002.emContactsAshore, 110, labelY + 16 * rowHeight);
      doc.text(v30002.satelliteContacts, 110, labelY + 17 * rowHeight);
      doc.text(v30002.radioTelex, 110, labelY + 18 * rowHeight);
      doc.text(v30002.radioLicenseNr, 110, labelY + 19 * rowHeight);
      doc.text(v30002.radioLicenseIssued, 110, labelY + 20 * rowHeight);
      doc.text(v30002.radioLicenseExpiry, 110, labelY + 21 * rowHeight);
      doc.text(v30002.aaic, 110, labelY + 22 * rowHeight);
    }
  }

  doc.addPage("a4", "p");
  //third page
  doc.setFontSize(20);
  doc.setTextColor(0);
  doc.text("Section B - QUALIFIED PERSONNEL", 105, 15, {
    align: 'center'
  })
  // Operators table
  if (v30003) {
    // Create table rows from data
    let rows = [];
    for (let i = 1; i <= 20; i++) {
      if (v30003['name' + i]) {
        rows.push({
          column1: v30003['name' + i],
          column2: 'From ' + v30003['from' + i] + '\nTo ' + v30003['to' + i],
          column3: v30003['emCom' + i],
          column4: v30003['tests' + i],
          column5: v30003['type' + i] + ' ' + v30003['id' + i] + '\nIssued ' + v30003['issue' + i],
        });
      }
    }
    const totalRows = 23;
    if (rows.length < totalRows) {
      for (let i = rows.length; i < totalRows; i++) {
        rows.push({
          column1: '',
          column2: '',
          column3: '',
          column4: '',
          column5: '',
        });
      }
    }
    doc.autoTable({
      theme: 'grid',
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        fontSize: 12,
        halign: 'center',
        valign: 'middle',
        minCellHeight: 10,
        cellPadding: {
          top: 0.5,
          right: 1,
          bottom: 0.5,
          left: 1
        },
        lineWidth: 0.2,
        fillColor: 255,
        textColor: 0,
      },
      headStyles: {
        fontStyle: 'bold',
        fillColor: 255,
        textColor: 0,
      },
      columnStyles: {
        column1: {
          cellWidth: 40,
        },
        column2: {
          cellWidth: 38,
        },
        column3: {
          cellWidth: 23,
        },
        column4: {
          cellWidth: 19,
        },
        column5: {
          cellWidth: 70,
        }
      },
      rowPageBreak: 'auto',
      tableLineWidth: 0.2,
      tableLineColor: 0,
      margin: {
        top: 25,
        right: 10,
        bottom: 25,
        left: 10
      },
      pageBreak: 'auto',
      showHead: 'everyPage',
      showFoot: 'never',
      body: rows,
      columns: [{
          header: 'Name',
          dataKey: 'column1'
        },
        {
          header: 'Dates onboard',
          dataKey: 'column2'
        },
        {
          header: 'Em. com*',
          dataKey: 'column3'
        },
        {
          header: 'Tech.maint.**',
          dataKey: 'column4'
        },
        {
          header: 'Class and number of certificate\nand date of issue',
          dataKey: 'column5'
        },    
      ],
    });
  }

  // labels
  doc.setFontSize(12);
  doc.setLineWidth(0.5);
  doc.text('* Designated personnel with responsibility for radio communications during emergencies', 10, 275);
  doc.text('** GMDSS radio specialist responsible for the technical maintenance of the equipment', 10, 280);

  doc.addPage("a4", "l");
  //content pages page
  doc.setFontSize(20);
  doc.setTextColor(0);
  doc.text("Section C - EVENTS", 148.5, 15, {
    align: 'center'
  })

  // labels and lines
  doc.setFontSize(12);
  doc.setLineWidth(0.5);
  doc.text('Name of ship', 10, labelY );
  doc.line(44, labelY + 1, 130, labelY + 1);
  doc.text('Call sign', 132, labelY);
  doc.line(150, labelY + 1, 180, labelY + 1);
  doc.text('MMSI', 182, labelY);
  doc.line(195, labelY + 1, 280, labelY + 1);
  doc.text('Signature of Master', 10, 188);
  doc.line(70, 189, 280, 189);

  // dynamic texts
  if (v30002 && v30002.name) {
    doc.text(v30002.name, 45, labelY);
    doc.text(v30002.callsign, 151, labelY);
    doc.text(v30002.mmsi, 196, labelY);
  }

  // Entry table
  if (entries.length > 0) {
    // Create table rows from data
    let rows = [];
    for (const entry of entries) {
      if (!entry.content || entry.status === 10) {
        continue;
      }
      if (typeof entry.content === 'string') {
        try {
          entry.content = JSON.parse(entry.content);
          if (entry.content.hidden) { // Filter out hidden entries
            continue;
          }
        } catch (e) {
          console.log('Failed to parse record content JSON. Content: ' + entry.content + ' Error: ' + e);
          continue;
        }
      }
      if (entry.content.pdf && entry.content.pdf.type && entry.content.pdf.type.toUpperCase() === 'GMDSS') {
        let font = entry.status === -10 ? '[strike]' : ''      
        rows.push({
          column1: font + moment.utc(entry.eventTime).format('Y-MM-DD') + '\n' + moment.utc(entry.eventTime).format('HH:mm'),
          column2: font + entry.content.pdf.from,
          column3: font + entry.content.pdf.to,
          column4: font + entry.content.pdf.remarks,
          column5: font + entry.content.pdf.freq,
          column6: font + '[small]' + entry.verifier + (entry.signer ? `\n\nSigned by ${entry.signer}${title(entry.signerTitle)}\n${moment.utc(entry.updated).format('DD-MMM-Y HH:mm').toUpperCase()} UTC` : ''),
        });
      }
    }
    doc.autoTable({
      theme: 'grid',
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        fontSize: 12,
        valign: 'middle',
        halign: 'center',
        lineWidth: 0.2,
        fillColor: 255,
        textColor: 0,
        cellPadding: {
          top: 0.5,
          right: 1,
          bottom: 0.5,
          left: 1
        },
      },
      headStyles: {
        fontStyle: 'bold',
        fillColor: 255,
        textColor: 0,
      },
      columnStyles: {
        column1: {
          cellWidth: 38,
        },
        column2: {
          cellWidth: 31,
        },
        column3: {
          cellWidth: 31,
        },
        column4: {
          cellWidth: 99,
        },
        column5: {
          cellWidth: 43,
        },
        column6: {
          cellWidth: 35,
          cellPadding: {
            top: 0.2,
            right: 1,
            bottom: 0.2,
            left: 1
          },
        }
      },
      rowPageBreak: 'auto',
      tableLineWidth: 0.2,
      tableLineColor: 0,
      margin: {
        top: 45,
        right: 10,
        bottom: 35,
        left: 10
      },
      pageBreak: 'auto',
      showHead: 'everyPage',
      showFoot: 'never',
      body: rows,
      columns: [{
          header: 'Date and\ntime UTC',
          dataKey: 'column1'
        },
        {
          header: 'From',
          dataKey: 'column2'
        },
        {
          header: 'To',
          dataKey: 'column3'
        },
        {
          header: 'Operator\'s actions and remarks or incidents',
          dataKey: 'column4'
        },
        {
          header: 'Frequency, channel\nor satellite system',
          dataKey: 'column5'
        },
        {
          header: 'Recorded by',
          dataKey: 'column6'
        },
      ],
      willDrawCell: function (data) {
        if (data.row.section === 'body' && data.cell.raw && data.cell.raw.toString().includes('[strike]')) {
           doc.setFont('RobotoStrikethrough-Regular', 'normal', 400);
           data.cell.text = data.cell.text.map(o => {
             return o && o.toString().includes('[strike]') ? o.toString().substring(8, o.length) : o;
           })
        }
        if (data.row.section === 'body' && data.cell.raw && data.cell.raw.toString().includes('[small]')) {
          doc.setFontSize(9);
          data.cell.text = data.cell.text.map(o => {
            return o && o.toString().includes('[small]') ? o.toString().substring(7, o.length) : o;
          })
        }
      },
    });
  }
  
  // Create Appendix B page if entry 30011 exists
  console.log('Create GMDSS Appendix B page if entry 30011 exists: ', v30011);
  if (v30011 && v30011.pdf && v30011.pdf.page) {
    doc.addPage("a4", "p");
    doc.setFontSize(20);
    doc.setTextColor(0);
    doc.text(v30011.pdf.page, 105, 15, {
      align: 'center'
    })
    // Create table rows from data    
    doc.autoTable({
      theme: 'grid',
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        fontSize: 12,
        halign: 'center',
        valign: 'middle',
        minCellHeight: 10,
        cellPadding: {
          top: 0.5,
          right: 1,
          bottom: 0.5,
          left: 1
        },
        lineWidth: 0.2,
        fillColor: 255,
        textColor: 0,
      },
      headStyles: {
        fontStyle: 'bold',
        fillColor: 255,
        textColor: 0,
      },
      rowPageBreak: 'auto',
      tableLineWidth: 0.2,
      tableLineColor: 0,
      margin: {
        top: 25,
        right: 10,
        bottom: 25,
        left: 10
      },
      pageBreak: 'auto',
      showHead: 'everyPage',
      showFoot: 'never',
      body: v30011.pdf.items && v30011.pdf.items.length > 0 ? v30011.pdf.items : [],
      columns: [{
          header: 'Item',
          dataKey: 'item'
        },
        {
          header: 'Date',
          dataKey: 'value'
        }
      ],
    });
  }

  addFooters(doc, v30002, v30002, author);
  
  writePdf(doc, startDate, endDate, book);
}

function createGarbage(book, startDate, endDate, entries, v90000, v90001, pdfk, pdfk2, author) {
  console.log(pdfk2);
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a4',
    putOnlyUsedFonts: true,
    floatPrecision: 'smart', // or "smart", default is 16
    encryption: {
      userPassword: pdfk,
      // ownerPassword: pdfk2,
      userPermissions: ['print', 'copy'],
    },
  });
  if (book === 'Garbage1') {
    // this describes the intro pages of an Garbage Record Book Part I
    doc.setProperties({
        title: 'Garbage Record Book - Pt I',
        subject: 'Records',
        author: 'nauticAi Log',
        creator: 'nauticAi Log'
    });// first page
    doc.setFontSize(28);
    doc.text("GARBAGE RECORD BOOK", 105, 110, {
        align: 'center'
    })
    doc.text("Part I - All ships", 105, 122, {
        align: 'center'
    })
    doc.setFontSize(14);
    doc.text("For all garbage other than cargo residues as defined in regulation 1.2", 105, 130, {
        align: 'center'
    })
  } else if (book === 'Garbage2') {
    // this describes the intro pages of an Garbage Record Book Part II
    doc.setProperties({
      title: 'Garbage Record Book - Pt II',
      subject: 'Records',
      author: 'nauticAi Log',
      creator: 'nauticAi Log'
    });// first page
    doc.setFontSize(28);
    doc.text("GARBAGE RECORD BOOK", 105, 110, {
      align: 'center'
    })
    doc.text("Part II - Ships that carry solid bulk cargoes", 105, 122, {
      align: 'center'
    })
    doc.setFontSize(14);
    doc.text("For all cargo residues as defined in regulation 1.2", 105, 130, {
      align: 'center'
    })
  }
  // labels and lines
  let labelYP1 = 150
  doc.setFontSize(12);
  doc.setLineWidth(0.5);
  doc.setDrawColor(150);
  doc.text('Name of ship', 10, labelYP1);
  doc.line(70, labelYP1 + 1, 180, labelYP1 + 1);
  doc.text('Distinctive number or letters', 10, labelYP1 + 15);
  doc.line(70, labelYP1 + 16, 180, labelYP1 + 16);
  doc.text('IMO number', 10, labelYP1 + 30);
  doc.line(70, labelYP1 + 31, 180, labelYP1 + 31);
  doc.text('Port of registry', 10, labelYP1 + 45);
  doc.line(70, labelYP1 + 46, 180, labelYP1 + 46);
  doc.text('Gross tonnage', 10, labelYP1 + 60);
  doc.line(70, labelYP1 + 61, 90, labelYP1 + 61);
  doc.text('Deadweight in metric tonnes', 93, labelYP1 + 60);
  doc.line(148, labelYP1 + 61, 180, labelYP1 + 61);
  doc.text('Entries from', 10, labelYP1 + 75);
  doc.line(70, labelYP1 + 76, 102, labelYP1 + 76);
  doc.text('to', 104, labelYP1 + 75);
  doc.line(109, labelYP1 + 76, 180, labelYP1 + 76);
  
  // dynamic texts
  if (v90000) {
    doc.text(v90000.name, 71, labelYP1);
    doc.text(v90000.imo, 71, labelYP1 + 30);
    doc.text(v90000.regPort, 71, labelYP1 + 45);
  }
  if (v90001) {
    doc.text(v90001.callsign, 71, labelYP1 + 15);
    doc.text(v90001.gt, 71, labelYP1 + 60);
    doc.text(v90001.dwt, 149, labelYP1 + 60);
  }
  doc.text(moment.utc(startDate).format('Y-MM-DD'), 71, labelYP1 + 75);
  doc.text(moment.utc(endDate).format('Y-MM-DD'), 120, labelYP1 + 75);
  
  doc.addPage("a4", "l");
  //second page
  doc.setTextColor(0);
  // labels and lines
  let labelY = 25
  doc.setFontSize(16);
  if (book === 'Garbage1') {
    doc.text("RECORD OF GARBAGE DISCHARGES - PART I (All ships)", 148.5, 10, {
        align: 'center'
    })
    doc.setFontSize(14);
    doc.text("For all garbage other than cargo residues as defined in regulation 1.2 (Definitions)", 148.5, 16, {
        align: 'center'
    })
  } else if (book === 'Garbage2') {
    doc.text("RECORD OF GARBAGE DISCHARGES - PART II (Ships that carry solid bulk cargoes)", 148.5, 10, {
      align: 'center'
    })
    doc.setFontSize(14);
    doc.text("For all cargo residues as defined in regulation 1.2 (Definitions)", 148.5, 16, {
        align: 'center'
    })
  }
  // labels and lines
  doc.setFontSize(12);
  doc.setLineWidth(0.5);
  doc.text('Name of ship', 10, labelY );
  doc.line(36, labelY + 1, 130, labelY + 1);
  doc.text('Distinctive number or letters', 132, labelY);
  doc.line(186, labelY + 1, 210, labelY + 1);
  doc.text('IMO number', 212, labelY);
  doc.line(238, labelY + 1, 280, labelY + 1);
  
  // dynamic texts
  if (v90000) {
    doc.text(v90000.name, 37, labelY);
    doc.text(v90000.imo, 239, labelY);
  }
  if (v90001) {
    doc.text(v90001.callsign, 187, labelY);
  }

  // Tables
  if (entries.length > 0) {
    // static table
    doc.setFontSize(12);
    doc.text('Garbage categories', 10, 35);
    if (book === 'Garbage1') {
      doc.autoTable({ 
        theme: 'grid',  
        body: [
          ['A - Plastics', 'B - Food waste', 'BC - Food waste COMMINUTED',  'BG - Food waste GROUND', 'C - Domestic waste', 'D - Cooking oil'],
        ],
        startY: 37,
        showHead: 'never',
        showFoot: 'never',
        styles: { lineColor: 0 },
        margin: { left: 10, right: 10 },
      })
      doc.autoTable({ 
        theme: 'grid',  
        body: [
          ['E - Incinerator ashes', 'F - Operational waste', 'G - Animal carcasses', 'H - Fishing gear', 'I - E waste'],
        ],
        startY: 44,
        showHead: 'never',
        showFoot: 'never',
        styles: { lineColor: 0 },
        margin: { left: 10, right: 10 },
      })
    
      // Discharges table
      let rows = [];
      for (const entry of entries) {
        if (!entry.content || entry.status === 10) {
          continue;
        }
        if (typeof entry.content === 'string') {
          try {
            entry.content = JSON.parse(entry.content);
            if (entry.content.hidden) { // Filter out hidden entries
              continue;
            }
          } catch (e) {
            console.log('Failed to parse record content JSON. Content: ' + entry.content + ' Error: ' + e);
            continue;
          }
        }
        if (entry.content.pdf && entry.content.pdf.type === 'Garbage1' && !entry.content.pdf.exceptional) {
          let font = entry.status === -10 ? '[strike]' : '';
          if (!entry.content.pdf.items) { 
            entry.content.pdf.items = [entry.content.pdf]; // Convert to items syntax
          }
          if (entry.content.pdf.items && Array.isArray(entry.content.pdf.items) && entry.content.pdf.items.length > 0) {
            for (const item of entry.content.pdf.items) {
              if (!item.condition || (item.condition && checkFormula(item.condition))) {
                rows.push({
                  column1: font + moment.utc(entry.eventTime).format('Y-MM-DD') + '\n' + moment.utc(entry.eventTime).format('HH:mm'),
                  column2: item.position ? font + item.position : '',
                  column3: item.category ? font + item.category : '',
                  column4: item.intoSea ? font + item.intoSea : '',
                  column5: item.toReception ? font + item.toReception : '',
                  column6: item.incinerated ? font + item.incinerated : '',
                  column7: item.remarks ? font + item.remarks : '',
                  column8: entry.verifier ? font + entry.verifier : font + entry.author,
                });
              }
            }
          }
        }
      }
      
      doc.autoTable({
        theme: 'grid',
        styles: {
          overflow: 'linebreak',
          cellWidth: 'wrap',
          fontSize: 12,
          valign: 'middle',
          halign: 'center',
          lineWidth: 0.2,
          fillColor: 255,
          textColor: 0,
          cellPadding: {
            top: 0.5,
            right: 1,
            bottom: 0.5,
            left: 1
          },
        },
        headStyles: {
          fillColor: 255,
          textColor: 0,
          halign: 'left',
          fontStyle: 'normal',
          valign: 'top',
        },
        columnStyles: {
          column1: {
            cellWidth: 36,
          },
          column2: {
            cellWidth: 56,
          },
          column3: {
            cellWidth: 22,
          },
          column4: {
            cellWidth: 27,
          },
          column5: {
            cellWidth: 29,
          },
          column6: {
            cellWidth: 25,
          },
          column7: {
            cellWidth: 50,
          },
          column8: {
            cellWidth: 32,
          }
        },
        rowPageBreak: 'auto',
        tableLineWidth: 0.2,
        tableLineColor: 0,
        startY: 67,
        margin: {
          top: 20,
          right: 10,
          bottom: 32,
          left: 10
        },
        pageBreak: 'auto',
        showHead: 'everyPage',
        showFoot: 'never',
        body: rows,
        columns: [{
            header: 'Date/\nTime UTC',
            dataKey: 'column1'
          },
          {
            header: 'Position of the ship (latitude/longitude) or port if discharged ashore or name of ship if discharged to another ship',
            dataKey: 'column2'
          },
          {
            header: 'Category',
            dataKey: 'column3'
          },
          {
            header: 'Estimated amount discharged into sea (m³)',
            dataKey: 'column4'
          },
          {
            header: 'Estimated amount discharged to reception facilities or another ship (m³)',
            dataKey: 'column5'
          },
          {
            header: 'Estimated amount incinerated (m³)',
            dataKey: 'column6'
          },
          {
            header: 'Remarks: (e.g. start/stop time and position of incineration; general remarks)',
            dataKey: 'column7'
          },
          {
            header: 'Certification/\nSignature',
            dataKey: 'column8'
          },
        ],
        willDrawCell: function (data) {
          if (data.row.section === 'body' && data.cell.raw && data.cell.raw.toString().includes('[strike]')) {
            doc.setFont('RobotoStrikethrough-Regular', 'normal', 400);
            data.cell.text = data.cell.text.map(o => {
              return o && o.toString().includes('[strike]') ? o.toString().substring(8, o.length) : o;
            })
          }
        },
        didDrawPage: function (data) {
          let y = data.settings.startY-7;
          if (data.pageNumber > 1) {
            y = data.settings.margin.top-7;
          }
          doc.text(`Discharges under MARPOL Annex V regulations 4 (Discharge of garbage outside special areas), 5 (Special requirements for discharge`, 10, y);
          doc.text(`of garbage from fixed or floating platforms) or 6 (Discharge of garbage within special areas) or chapter 5 of part II-A of the Polar Code.`, 10, y + 5)

          doc.setTextColor(0);
          doc.setDrawColor(150);
          doc.setFontSize(12);
          doc.setLineWidth(0.5);
          doc.text('Signature of Master', 10, 190);
          doc.line(50, 191, 170, 191);
          doc.text('Date', 172, 190);
          doc.line(182, 191, 280, 191);
        },
      });

      doc.addPage("a4", "l");
      
      // Exceptional discharges table
      // Create table rows from data
      rows = [];
      for (const entry of entries) {
        if (!entry.content || entry.status === 10) {
          continue;
        }
        if (typeof entry.content === 'string') {
          try {
            entry.content = JSON.parse(entry.content);
            if (entry.content.hidden) { // Filter out hidden entries
              continue;
            }
          } catch (e) {
            console.log('Failed to parse record content JSON. Content: ' + entry.content + ' Error: ' + e);
            continue;
          }
        }
        if (entry.content.pdf && entry.content.pdf.type === 'Garbage1' && entry.content.pdf.exceptional) {
          let font = entry.status === -10 ? '[strike]' : '';
          if (!entry.content.pdf.items) { 
            entry.content.pdf.items = [entry.content.pdf]; // Convert to items syntax
          }
          if (entry.content.pdf.items && Array.isArray(entry.content.pdf.items) && entry.content.pdf.items.length > 0) {
            for (const item of entry.content.pdf.items) {
              if (!item.condition || (item.condition && checkFormula(item.condition))) {
                rows.push({
                  column1: font + moment.utc(entry.eventTime).format('Y-MM-DD') + '\n' + moment.utc(entry.eventTime).format('HH:mm'),
                  column2: item.position ? font + item.position : '',
                  column3: item.category ? font + item.category : '',
                  column4: item.estimated ? font + item.estimated : '',
                  column5: item.remarks ? font + item.remarks : '',
                  column6: entry.verifier ? font + entry.verifier : font + entry.author,
                });
              }
            }
          }
        }
      }
      if (rows.length === 0) {
        rows.push({
          column1: '',
          column2: '',
          column3: '',
          column4: '',
          column5: '',
          column6: '',
        })
      }
      
      doc.autoTable({
        theme: 'grid',
        styles: {
          overflow: 'linebreak',
          cellWidth: 'wrap',
          fontSize: 12,
          valign: 'middle',
          halign: 'center',
          lineWidth: 0.2,
          fillColor: 255,
          textColor: 0,
          cellPadding: {
            top: 0.5,
            right: 1,
            bottom: 0.5,
            left: 1
          },
        },
        headStyles: {
          fillColor: 255,
          textColor: 0,
          halign: 'left',
          fontStyle: 'normal',
          valign: 'top',
        },
        columnStyles: {
          column1: {
            cellWidth: 36,
          },
          column2: {
            cellWidth: 56,
          },
          column3: {
            cellWidth: 22,
          },
          column4: {
            cellWidth: 35,
          },
          column5: {
            cellWidth: 96,
          },
          column6: {
            cellWidth: 32,
          }
        },
        rowPageBreak: 'auto',
        tableLineWidth: 0.2,
        tableLineColor: 0,
        startY: 20,
        margin: {
          top: 10,
          right: 10,
          bottom: 32,
          left: 10
        },
        pageBreak: 'auto',
        showHead: 'everyPage',
        showFoot: 'never',
        body: rows,
        columns: [{
            header: 'Date/\nTime UTC',
            dataKey: 'column1'
          },
          {
            header: 'Port or position of the ship (latitude/longitude and water depth if known)',
            dataKey: 'column2'
          },
          {
            header: 'Category',
            dataKey: 'column3'
          },
          {
            header: 'Estimated amount lost or discharged (m³)',
            dataKey: 'column4'
          },
          {
            header: 'Remarks on the reason for the discharge or loss and general remarks (e.g. reasonable precautions taken to prevent or minimize such discharge or accidental loss and general remarks)',
            dataKey: 'column5'
          },
          {
            header: 'Certification/\nSignature',
            dataKey: 'column6'
          },
        ],
        willDrawCell: function (data) {
          if (data.row.section === 'body' && data.cell.raw && data.cell.raw.toString().includes('[strike]')) {
            doc.setFont('RobotoStrikethrough-Regular', 'normal', 400);
            data.cell.text = data.cell.text.map(o => {
              return o && o.toString().includes('[strike]') ? o.toString().substring(8, o.length) : o;
            })
          }
        },
        didDrawPage: function (data) {
          doc.text(`Exceptional discharge or loss of garbage under regulation 7 (Exceptions)`, 10, data.settings.startY-2);
          doc.setTextColor(0);
          doc.setDrawColor(150);
          doc.setFontSize(12);
          doc.setLineWidth(0.5);
          doc.text('Signature of Master', 10, 190);
          doc.line(50, 191, 170, 191);
          doc.text('Date', 172, 190);
          doc.line(182, 191, 280, 191);
        },
      });
    } else if (book === 'Garbage2') {
      doc.autoTable({ 
        theme: 'grid',  
        body: [
          ['J - Cargo residues (non-HME)', 'K - Cargo residues (HME)'],
        ],
        startY: 37,
        showHead: 'never',
        showFoot: 'never',
        styles: { lineColor: 0 },
        margin: { left: 10, right: 10 },
      })

      // Discharges table
      let rows = [];
      for (const entry of entries) {
        if (!entry.content || entry.status === 10) {
          continue;
        }
        if (typeof entry.content === 'string') {
          try {
            entry.content = JSON.parse(entry.content);
            if (entry.content.hidden) { // Filter out hidden entries
              continue;
            }
          } catch (e) {
            console.log('Failed to parse record content JSON. Content: ' + entry.content + ' Error: ' + e);
            continue;
          }
        }
        if (entry.content.pdf && entry.content.pdf.type === 'Garbage2') {
          let font = entry.status === -10 ? '[strike]' : '';
          if (!entry.content.pdf.items) { 
            entry.content.pdf.items = [entry.content.pdf]; // Convert to items syntax
          }
          if (entry.content.pdf.items && Array.isArray(entry.content.pdf.items) && entry.content.pdf.items.length > 0) {
            for (const item of entry.content.pdf.items) {
              if (!item.condition || (item.condition && checkFormula(item.condition))) {
                rows.push({
                  column1: font + moment.utc(entry.eventTime).format('Y-MM-DD') + '\n' + moment.utc(entry.eventTime).format('HH:mm'),
                  column2: item.position ? font + item.position : '',
                  column3: item.category ? font + item.category : '',
                  column4: item.intoSea ? font + item.intoSea : '',
                  column5: item.toReception ? font + item.toReception : '',
                  column7: item.remarks ? font + item.remarks : '',
                  column8: entry.verifier ? font + entry.verifier : font + entry.author,
                });
              }
            }
          }
        }
      }
      
      doc.autoTable({
        theme: 'grid',
        styles: {
          overflow: 'linebreak',
          cellWidth: 'wrap',
          fontSize: 12,
          valign: 'middle',
          halign: 'center',
          lineWidth: 0.2,
          fillColor: 255,
          textColor: 0,
          cellPadding: {
            top: 0.5,
            right: 1,
            bottom: 0.5,
            left: 1
          },
        },
        headStyles: {
          fillColor: 255,
          textColor: 0,
          halign: 'left',
          fontStyle: 'normal',
          valign: 'top',
        },
        columnStyles: {
          column1: {
            cellWidth: 36,
          },
          column2: {
            cellWidth: 56,
          },
          column3: {
            cellWidth: 22,
          },
          column4: {
            cellWidth: 27,
          },
          column5: {
            cellWidth: 29,
          },
          column6: {
            cellWidth: 75,
          },
          column7: {
            cellWidth: 32,
          }
        },
        rowPageBreak: 'auto',
        tableLineWidth: 0.2,
        tableLineColor: 0,
        startY: 56,
        margin: {
          top: 20,
          right: 10,
          bottom: 32,
          left: 10
        },
        pageBreak: 'auto',
        showHead: 'everyPage',
        showFoot: 'never',
        body: rows,
        columns: [{
            header: 'Date/\nTime UTC',
            dataKey: 'column1'
          },
          {
            header: 'Position of the ship (latitude/longitude) or port if discharged ashore',
            dataKey: 'column2'
          },
          {
            header: 'Category',
            dataKey: 'column3'
          },
          {
            header: 'Estimated amount discharged into sea (m³)',
            dataKey: 'column4'
          },
          {
            header: 'Estimated amount discharged to reception facilities or another ship (m³)',
            dataKey: 'column5'
          },
          {
            header: 'Start and stop positions of the ship for discharges into the sea',
            dataKey: 'column6'
          },
          {
            header: 'Certification/\nSignature',
            dataKey: 'column7'
          },
        ],
        willDrawCell: function (data) {
          if (data.row.section === 'body' && data.cell.raw && data.cell.raw.toString().includes('[strike]')) {
            doc.setFont('RobotoStrikethrough-Regular', 'normal', 400);
            data.cell.text = data.cell.text.map(o => {
              return o && o.toString().includes('[strike]') ? o.toString().substring(8, o.length) : o;
            })
          }
        },
        didDrawPage: function (data) {
          let y = data.settings.startY-7;
          if (data.pageNumber > 1) {
            y = data.settings.margin.top-7;
          }
          doc.text('Discharges under regulations 4 (Discharge of garbage outside special areas) and 6 (Discharge of garbage within special areas)', 10, y + 5);

          doc.setTextColor(0);
          doc.setDrawColor(150);
          doc.setFontSize(12);
          doc.setLineWidth(0.5);
          doc.text('Signature of Master', 10, 190);
          doc.line(50, 191, 170, 191);
          doc.text('Date', 172, 190);
          doc.line(182, 191, 280, 191);
        },
      });
    }
  }
  addFooters(doc, v90000, v90001, author);
  
  writePdf(doc, startDate, endDate, book);
}

function formatValue(field) {
  let value = field.value;
  if (value && Array.isArray(value) && value.length > 0) {
    if (typeof value[0] === 'object') { // combobox is array of objects
      value = value.map(o => {
        return o.value ? o.value.toString().trim() : '';
      }).join(', ');
    } else { 
      value = value.map(o => {
        return o ? o.toString().trim() : '';
      }).join(', ');
    }
  } else if (value && field.type === 'time') {
    value = value + ' UTC';
  } else if (typeof value === 'string' && value.includes('  ')) {
    value = value.replaceAll('   ', ' ').replaceAll('  .', '.').replaceAll('  ,', ',');
    value = value.split('  ').filter(o => o.trim()).join(', ');
  } else if (value && typeof value === 'object' && value.name) {
    value = value.name;
  } else if (value === '' || value === null || typeof value === 'undefined') {
    value = '';
  }
  return value;
}

function formatAutoValue(field) {
  let autoValue = '';
  if (typeof field.autoValue !== 'undefined' && field.autoValue !== null && field.autoValue !== '') {
    if (typeof field.autoValue === 'object' && field.autoValue.name) {
      autoValue = ' (auto ' + field.autoValue.name + ')';
    } else {
      if (field.type !== 'date' && field.type !== 'time' && field.autoValue) {
        autoValue = ' (auto ' + field.autoValue + ')';
      }
    }
  }
  return autoValue;
}

function checkFormula(str) {
  if (!str.includes('exec') && !str.includes('null')) {
    try {
      return Function(`'use strict'; return (${str})`)();
    } catch (e) {
      console.log('Not valid formula: ' + str, e);
      return false;
    }
  } else {
    console.log('Not valid formula: ' + str);
    return false;
  }
}

function title(value, addComma = true) {
  let title = '';
  if (value && typeof value === 'string') {
    try {
      value = JSON.parse(value);
    } catch (error) {
      console.log(error);
    }
  }
  if (value && Array.isArray(value)) {
    if (addComma) {
      title = value && value[0] ? ', ' + value[0] : '';
    } else {
      title = value && value[0] ? value[0] + ' ' : '';
    }
  }
  return title;
}

async function writePdf(doc, startDate, endDate, book = null) {
  let data = doc.output('blob');
  if (book) {
    tools.saveFile(data, {}, moment.utc(startDate).format('YMMDD') + '-' + moment.utc(endDate).format('YMMDD') + '_' + book.toLowerCase() + '_records.pdf');
  } else {
    tools.saveFile(data, {}, moment.utc(startDate).format('YMMDD') + '-' + moment.utc(endDate).format('YMMDD') + '_records.pdf');
  }
}

export default pdf;