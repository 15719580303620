<template>
    <v-card class="card" :class="{'large' : card.large}">
        <v-responsive class="cardbg cardbox">
            <div v-if="!card.map && !card.streamUrl" class="cardboxtitle primarytext--text">
                <span class="v-toolbar__title">{{ card.header }}</span>
                <div v-for="(item, index) in card.items" :key="index" :title="item.title" class="subheading primarytext--text">
                    <span :style="card.id === 'ships' ? 'display:inline-block;width:80%' : ''"><v-icon v-if="item.vIcon !== undefined" :color="item.vIconColor">{{ item.vIcon }}</v-icon>
                    {{ item.text }}
                    </span>
                    <span class="control">
                        <span class="mr-2" v-if="item.cams !== undefined && item.cams.length > 0">
                            <v-menu offset-y :close-on-content-click="false">
                                <template #activator="{ on, attrs }">
                                    <v-icon title="Camera menu" color="primary" v-bind="attrs" v-on="on">mdi-video
                                    </v-icon>
                                </template>
                                <v-list dense>
                                    <span v-for="(cam, index2) in item.cams" :key="index2">
                                        <v-list-item v-if="!cam.controls || (cam.controls && cam.controls.length === 0)">
                                            <v-list-item-icon class="mr-2" @click="getStreamUrl(cam, item.id)">
                                                <v-icon :color="cam.vIconColor" :title="cam.name">mdi-video</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title @click="getStreamUrl(cam, item.id)">{{ cam.name }}</v-list-item-title>
                                        </v-list-item>
                                        <v-list-group v-else prepend-icon="mdi-video" :color="cam.vIconColor">
                                            <template #activator>
                                                <v-list-item-title> {{ cam.name }}</v-list-item-title>
                                            </template>
                                            <v-list-item v-if="cam.vIconColor === 'success'" @click="getStreamUrl(cam, item.id)">
                                                <v-list-item-icon class="mr-2">
                                                    <v-icon color="error">mdi-stop-circle-outline</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Stop streaming</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-else @click="getStreamUrl(cam, item.id)">
                                                <v-list-item-icon class="mr-2">
                                                    <v-icon color="success">mdi-play-circle-outline</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Start streaming</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-for="control in cam.controls" :key="control.id" @click="cameraCommand(cam, item.id, control)">
                                                <v-list-item-icon class="mr-2">
                                                    <v-icon v-text="control.icon"></v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>{{ control.name }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list-group>
                                    </span>
                                </v-list>
                            </v-menu>
                        </span>
                        <router-link v-if="item.ciiRating" :to="'/performance/' + item.id">
                            <v-chip small class="cii mr-2" v-if="item.ciiRating === 'A'" title="Current CII rating is A. Click to open history graph" color="#85e085">{{ item.ciiRating }}</v-chip>
                            <v-chip small class="cii mr-2" v-else-if="item.ciiRating === 'B'" title="Current CII rating is B. Click to open history graph" color="#99ff99">{{ item.ciiRating }}</v-chip>
                            <v-chip small class="cii mr-2" v-else-if="item.ciiRating === 'C'" title="Current CII rating is C. Click to open history graph" color="#ffff80">{{ item.ciiRating }}</v-chip>
                            <v-chip small class="cii mr-2" v-else-if="item.ciiRating === 'D'" title="Current CII rating is D. Click to open history graph" color="#ffccb3">{{ item.ciiRating }}</v-chip>
                            <v-chip small class="cii mr-2" v-else-if="item.ciiRating === 'E'" title="Current CII rating is E. Click to open history graph" color="#ff8080">{{ item.ciiRating }}</v-chip>
                        </router-link>
                        <v-icon v-if="item.iotText !== undefined" :title="item.iotText" :color="item.iotColor">mdi-radio-tower</v-icon>
                    </span>
                </div>
            </div>

            <div v-if="card.streamUrl" :key="card.id" :id="'sldp_player_wrapper' + card.id" style="height: calc(100% - 1px)">
            </div>

            <v-map v-if="card.map" ref="map" style="height: 100%;" :zoom="zoom" :center="center" :bounds="bounds" :options="mapOptions">
                <v-tilelayer :url="url" :options="{tileSize: 512, crossOrigin: true, zoomOffset: -1}"></v-tilelayer>
                <span v-for="(ship, index) in ships" :key="index">
                    <v-rotated-marker :lat-lng="ship.latLng" :icon="ship.icon" :rotationAngle="ship.heading">
                        <l-tooltip :content="ship.tooltip" :options="ship.tooltipOptions" />
                    </v-rotated-marker>
                </span>
                <span class="v-toolbar__title mapheadline">{{ card.header }}</span>
            </v-map>
        </v-responsive>
        <v-divider />
        <v-card-actions class="cardbgdark">
            <v-menu open-on-hover top offset-y>
                <template #activator="{ on }">
                    <v-btn v-on="on" icon>
                        <v-icon color="info">info</v-icon>
                    </v-btn>
                </template>
                <v-list style="padding:10px;">
                    {{ card.info }}
                </v-list>
            </v-menu>
            <span v-show="card.streamUrl"> {{ card.header }}</span>
            <v-spacer />
            <router-link v-show="!card.streamUrl" :to="card.path">
                <v-btn text class="accent--text">{{ card.action }}</v-btn>
            </router-link>
            <v-btn v-show="card.streamUrl && buttonText2" text class="accent--text" @click="initPlayer(card.streamUrl)">{{ buttonText2 }}</v-btn>
            <v-btn v-show="card.streamUrl" text class="accent--text" @click="endStream(card)">{{ buttonText }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import L from 'leaflet';
    import {
        LMap,
        LTileLayer,
        LTooltip
    } from 'vue2-leaflet';
    import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker';
    import moment from 'moment';
    import {
        mapGetters,
        mapActions
    } from 'vuex';
    import {
        eventBus
    } from '../main';
    import {
        shipHelpers,
        config,
        tools,
    } from '../helpers';

    export default {
        name: 'DashboardCard',
        components: {
            'v-map': LMap,
            'v-tilelayer': LTileLayer,
            LTooltip,
            'v-rotated-marker': Vue2LeafletRotatedMarker
        },
        props: {
            card: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                // Leaflet specific properties
                zoom: 3,
                center: L.latLng(30, 0),
                bounds: null,
                mapOptions: {
                    zoomControl: false,
                    attributionControl: false
                },
                sldpPlayers: {},
                counter: null,
                counterInterval: null,
                buttonText: 'End stream',
                buttonText2: null,
            };
        },
        computed: {
            url() {
                if (this.getDarkMode()) {
                    return `https://api.maptiler.com/maps/d0b6607a-df0c-4660-af62-030764e2efc4/{z}/{x}/{y}.png?key=${config.apiKey}`;
                }
                return `https://api.maptiler.com/maps/511be076-e981-4e63-8d86-7f23e4d3e7f5/{z}/{x}/{y}.png?key=${config.apiKey}`;
            },
            ships() {
                const dynamic = JSON.parse(JSON.stringify(this.getDynamic()));
                if (dynamic && dynamic.ais) {
                    for (const key in dynamic.ais) {
                        if (dynamic.ais.hasOwnProperty(key)) {
                            if (dynamic.ais[key].latitude && dynamic.ais[key].longitude) {
                                dynamic.ais[key].latLng = L.latLng(dynamic.ais[key].latitude, dynamic.ais[key].longitude);
                            } else {
                                dynamic.ais[key].latLng = L.latLng(0, 0);
                            }
                            dynamic.ais[key].heading = shipHelpers.headingOrCog(dynamic.ais[key].heading, dynamic.ais[
                                key].cog);
                            const prefix = dynamic.ais[key].external === 1 ? 'ts' : 'os';
                            const icon = shipHelpers.shipIcon(dynamic.ais[key].navstat, prefix, dynamic.ais[key].mmsi, null, dynamic.ais[key].sog);
                            dynamic.ais[key].icon = (
                                L.icon({
                                    iconUrl: require(`@/assets/img/ships/${icon}`),
                                    iconSize: [11, 30], // size of the icon
                                    iconAnchor: [5.5, 15], // point of the icon which will correspond to marker's location
                                })
                            );
                            dynamic.ais[key].tooltip = dynamic.ais[key].shortName;
                            dynamic.ais[key].tooltipOptions = shipHelpers.shipTooltipOptions(dynamic.ais[key], L);
                        }
                    }
                    return dynamic.ais;
                }
                return [];
            },
        },
        created() {
            setTimeout(() => {
                this.setBounds();
                if (this.card.streamUrl) {
                    this.initPlayer(this.card.streamUrl);
                }
            }, 300);
        },
        beforeDestroy() {
            if (this.sldpPlayers && this.sldpPlayers[this.card.id]) {
                this.sldpPlayers[this.card.id].destroy();
            }
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getDynamic: 'data/getDynamic',
            }),
            ...mapActions({
                fetchStreamUrl: 'data/fetchStreamUrl',
                sendCameraCommand: 'data/sendCameraCommand',
                sendCameraCommandImage: 'data/sendCameraCommandImage',
            }),
            getStreamUrl(cam, shipId) {
                if (cam.vIconColor === 'success') { // If stream already on, close
                    this.endStream(cam);
                } else {
                    this.fetchStreamUrl({
                        shipId,
                        camId: cam.id
                    }).then((data) => {
                        cam.url = data.url;
                        cam.shipId = shipId;
                        eventBus.$emit('createStream', cam);
                    });
                }
            },
            setBounds() {
                if (this.ships && this.ships.length > 1) {
                    this.bounds = L.latLngBounds(this.ships.map((ship) => [ship.latitude, ship.longitude])).pad(0.07);
                } else if (this.ships && this.ships.length === 1) {
                    this.center = L.latLng(this.ships[0].latitude, this.ships[0].longitude);
                    this.zoom = 6;
                } else {
                    setTimeout(() => {
                        this.setBounds();
                    }, 300);
                }
            },
            initPlayer(url) {
                this.buttonText2 = null;
                // eslint-disable-next-line
                this.sldpPlayers[this.card.id] = SLDP.init({
                    container: `sldp_player_wrapper${this.card.id}`,
                    stream_url: url,
                    height: 'parent',
                    width: 'parent',
                    autoplay: true,
                    muted: true,
                });
                if (this.card.timeout && this.card.timeout !== 0) {
                    this.counter = this.card.timeout;
                    this.counterInterval = setInterval(() => {
                        this.counter--;
                        let mins = Math.floor(this.counter / 60);
                        let secs = this.counter - mins * 60;
                        mins = mins < 10 ? `0${mins}` : mins;
                        secs = secs < 10 ? `0${secs}` : secs;
                        this.buttonText = `End stream ${mins}:${secs}`;
                    }, 1000);
                    setTimeout(() => {
                        // this.endStream(this.card);
                        if (this.sldpPlayers && this.sldpPlayers[this.card.id]) {
                            this.sldpPlayers[this.card.id].destroy();
                        }
                        clearInterval(this.counterInterval);
                        this.buttonText = 'Stream timeout';
                        setTimeout(() => {
                            this.buttonText = 'Close';
                            this.buttonText2 = 'Restart';
                        }, 2000);
                    }, this.card.timeout * 1000);
                }
            },
            endStream(cam) {
                eventBus.$emit('endStream', cam);
                if (this.sldpPlayers && this.sldpPlayers[this.card.id]) {
                    this.sldpPlayers[this.card.id].destroy();
                }
            },
            cameraCommand(cam, shipId, control) {
                if (control.type === 'image') {
                    this.sendCameraCommandImage({
                            shipId: shipId,
                            camId: cam.id,
                            controlId: control.id
                        }).then((response) => {
                            if (response && control.type === 'image') {
                                let filename = control.filename ? control.filename : 'cam-still.jpeg';
                                tools.saveFile(response.data, response.headers, moment.utc().format('YMMDDHHmm') + '-' + filename);
                            }
                        });
                } else {
                    this.sendCameraCommand({
                        shipId: shipId,
                        camId: cam.id,
                        controlId: control.id
                    });
                }
            }
        },
    };
</script>

<style>
    .v-card__media__content {
        width: 100% !important;
    }
</style>
<style scoped>
    .card {
        height: calc(50vh - 60px);
    }

    .cardbox {
        height: calc(100% - 53px) !important;
        overflow-y: auto;
    }

    .large {
        height: calc(100vh - 112px);
    }

    .cardboxtitle {
        height: 135px;
        padding: 8px;
        width: 100%;
    }

    .mapheadline {
        z-index: 9999;
        position: absolute;
        top: 8px;
        left: 8px;
    }

    a {
        text-decoration: inherit;
    }

    .control {
            position: absolute;
            right:10px;
        }

    .cii {
        margin-top: 1px;
        cursor: pointer;
        width:24px;
        padding: 8px;
    }

    @media only screen and (max-width: 499px) {
        .large {
            height: calc(50vh - 60px);
        }

    }

</style>