<template>
    <v-dialog v-model="showViewer" persistent width="950" style="position:absolute">
        <v-map ref="map" style="height: 90vh;" :zoom="zoom" :center="center" :bounds="bounds">
            <v-tilelayer :url="url" :attribution="attribution" :options="{tileSize: 512, crossOrigin: true, zoomOffset: -1}"></v-tilelayer>
            <v-tilelayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png" :attribution="seaMapAttribution"></v-tilelayer>
            <span v-if="routes && routes.length > 0">
                <v-geojson v-for="(route, index) in routes" :key="index" :geojson="route.geojson" :options="routeOptions"></v-geojson>
            </span>
            <span v-if="ballasts && ballasts.length > 0">
                <v-geojson v-for="(ballast, index) in ballasts" :key="index" :geojson="ballast.geojson" :options="ballastOptions"></v-geojson>
            </span>
            <span v-if="ships && ships.length > 0">
                <span v-for="(ship, index) in ships" :key="index">
                    <v-rotated-marker v-if="ship.latLng" :lat-lng="ship.latLng" :icon="ship.icon" :rotationAngle="ship.heading">
                        <l-tooltip :content="ship.tooltip" :options="tooltipOptions" />
                        <l-popup :content="ship.popup"></l-popup>
                    </v-rotated-marker>
                </span>
            </span>
            <div class="map-controls">
                <v-icon @click="setBounds()" title="Zoom to route" class="control-btn">zoom_out_map</v-icon>
            </div>
        </v-map>
         <v-btn icon style="position:absolute;right:0px;top:0px;z-index:1000000">
            <v-icon @click="closeWindow()">clear</v-icon>
        </v-btn>
    </v-dialog>
</template>

<script>
    import L from 'leaflet';
    import 'leaflet-mouse-position';
    import {
        LMap,
        LTileLayer,
        LGeoJson,
        LTooltip,
        LPopup,
    } from 'vue2-leaflet';
    import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker';    
    import {
        mapGetters,
        mapActions,
    } from 'vuex';
    // import moment from 'moment';
    import {
        shipHelpers,
        tools,
        config
    } from '../../helpers';
    import '../../plugins/Control.scaleNM';

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    export default {
        name: 'Routeviewer',
        props: {
            value: [Boolean, Object],
            portCalls: Array,
            prevPort: Number,
        },
        components: {
            'v-map': LMap,
            'v-tilelayer': LTileLayer,
            'v-geojson': LGeoJson,
            LTooltip,
            LPopup,
            'v-rotated-marker': Vue2LeafletRotatedMarker,
        },
        data() {
            return {
                showViewer: false,
                routes: null,
                ballasts: null,
                // Leaflet specific properties
                map: null,
                zoom: 3,
                center: L.latLng(30, 0),
                attribution: '&copy; <a href="http://www.nauticai.com">nauticAi</a>',
                seaMapAttribution: '&copy; <a href="http://www.openseamap.org/">OpenSeaMap</a>',
                tooltipOptions: {
                    permanent: true,
                    direction: 'top',
                    offset: L.point(0, -10),
                    opacity: 0.8,
                },
                routeOptions: {
                    style: {
                        color: '#e67e22',
                        weight: 3,
                        opacity: 0.95,
                        dashArray: '10,5'
                    }
                },
                ballastOptions: {
                    style: {
                        color: '#1E88E5',
                        weight: 2.5,
                        opacity: 0.95,
                        dashArray: '10,5'
                    }
                },
                bounds: null,
            };
        },
        mounted() {
            this.showViewer = this.value;
            this.$nextTick(() => {
                L.control.scale().addTo(this.$refs.map.mapObject);
                L.control.mousePosition({
                    emptyString: '0º N 0º E',
                    separator: ' ',
                    lngFormatter(num) {
                        return tools.ConvertDDToDMS(num, true); 
                    },
                    latFormatter(num) {
                        return tools.ConvertDDToDMS(num, false);
                    }
                }).addTo(this.$refs.map.mapObject);
                this.routes = null;
                if (this.portCalls && this.portCalls.length > 0) {
                    this.routes = this.portCalls.filter((o, i) => {
                        if ((o.routeId !== null && i > 1) || (o.routeId !== null && i === 1 && this.portCalls[i - 1].type !== 6)) {
                            return o;
                        }
                    });
                }
                this.ballasts = null;
                if (this.portCalls && this.portCalls.length > 0) {
                    this.ballasts = this.portCalls.filter((o, i) => {
                        if ((o.routeId !== null && i === 0 && this.prevPort === 1) || (o.routeId !== null && i === 1 && this.prevPort === 1 && this.portCalls[i - 1].type === 6)) {
                            return o;
                        }
                    });
                }
            });
        },
        computed: {
             ...mapGetters({
                features: 'authentication/features',
            }),
            url() {
                if (this.getDarkMode()) {
                    return `https://api.maptiler.com/maps/d0b6607a-df0c-4660-af62-030764e2efc4/{z}/{x}/{y}.png?key=${config.apiKey}`;
                } 
                    return `https://api.maptiler.com/maps/511be076-e981-4e63-8d86-7f23e4d3e7f5/{z}/{x}/{y}.png?key=${config.apiKey}`;
            },
            ships() {
                const dynamic = JSON.parse(JSON.stringify(this.getDynamic()));
                const ships = JSON.parse(JSON.stringify(this.getShips()));
                if (dynamic && dynamic.ais && ships && ships.length > 0) {
                    for (const key in dynamic.ais) {
                        if (dynamic.ais.hasOwnProperty(key)) {
                            const ship = ships.find((o) => o.mmsi === dynamic.ais[key].mmsi);
                            if (ship) {
                                if (ship.a && ship.b && ship.c && ship.d) {
                                    dynamic.ais[key].a = ship.a;
                                    dynamic.ais[key].b = ship.b;
                                    dynamic.ais[key].c = ship.c;
                                    dynamic.ais[key].d = ship.d;
                                    dynamic.ais[key].shipGeojson = shipHelpers.shipGeojson(dynamic.ais[key]);
                                }
                            }
                            if (dynamic.ais[key].latitude && dynamic.ais[key].longitude) {
                                dynamic.ais[key].latLng = L.latLng(dynamic.ais[key].latitude, dynamic.ais[key].longitude);

                                // Ship predictor arrow marker
                                const arrow = shipHelpers.vectorSvg(dynamic.ais[key].sog, 'white');
                                if (arrow) {
                                    dynamic.ais[key].predictor = {
                                        latLng: L.latLng(dynamic.ais[key].latitude, dynamic.ais[key].longitude),
                                        direction: shipHelpers.headingOrCog(dynamic.ais[key].heading, dynamic.ais[
                                            key].cog, true),
                                        icon: L.icon({
                                            iconUrl: arrow.image,
                                            iconSize: [8, arrow.height],
                                            iconAnchor: [4, arrow.height],
                                        }),
                                        options: {
                                            rotationOrigin: 'center bottom'
                                        }
                                    };
                                }
                            } else {
                                dynamic.ais[key].latLng = L.latLng(0, 0);
                                dynamic.ais[key].predictor = null;
                                dynamic.ais[key].circles = null;
                            }
                            dynamic.ais[key].heading = shipHelpers.headingOrCog(dynamic.ais[key].heading, dynamic.ais[
                                key].cog);
                            const prefix = dynamic.ais[key].external === 1 ? 'ts' : 'os';
                            const icon = shipHelpers.shipIcon(dynamic.ais[key].navstat, prefix, dynamic.ais[key].mmsi, null, dynamic.ais[key].sog);
                            dynamic.ais[key].icon = (
                                L.icon({
                                    iconUrl: require(`@/assets/img/ships/${icon}`),
                                    iconSize: [11, 30], // size of the icon
                                    iconAnchor: [5.5, 15], // point of the icon which will correspond to marker's location
                                })
                            );
                            dynamic.ais[key].tooltip = dynamic.ais[key].name;
                            dynamic.ais[key].popup = shipHelpers.shipPopup(dynamic.ais[key]);
                        }
                    }
                    return dynamic.ais;
                }
                return [];
            },
        },
        created() {
            this.fetchShips().then(() => {
                setTimeout(() => {
                    this.setBounds();
                    this.$refs.map.mapObject.invalidateSize();
                }, 50); 
            });
        },
        beforeDestroy() {
            
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getDynamic: 'data/getDynamic',
                getShips: 'data/getShips',
            }),
            ...mapActions({
                fetchShips: 'data/fetchShips',
                fetchDynamic: 'data/fetchDynamic',
            }),
            setBounds() {
                if (this.routes && this.routes.length > 0) {
                    try {
                        const coordinates = [];
                        for (const route of this.routes) {
                            route.geojson.coordinates.map((o) => {
                                coordinates.push([o[1], o[0]]);
                            });
                        }
                        for (const route of this.ballasts) {
                            route.geojson.coordinates.map((o) => {
                                coordinates.push([o[1], o[0]]);
                            });
                        }
                        this.bounds = L.latLngBounds(coordinates).pad(0.1);
                    } catch (e) {
                        console.warn(e);
                    }
                } else {
                    this.center = L.latLng(this.ships[0].latitude, this.ships[0].longitude);
                    this.zoom = 7;
                }
            },
            closeWindow() {
                this.$emit('input', false);
            },
            random() {
                return Math.random();
            },
        },
    };
</script>

<style scoped>
    .map-controls{
        position: absolute;
        top:80px;
        width:30px;
    }
    .control-btn {
        position: relative;
        margin-bottom:5px;
    }
</style>

<style>
    .v-dialog:not(.v-dialog--fullscreen) {
        position: relative;
    }
</style>
