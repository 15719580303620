<template>
    <v-progress-circular v-if="getProgress"
      indeterminate
      color="primary"
      :size="50"
    ></v-progress-circular>
</template>

<script>

import {
        mapGetters,
    } from 'vuex';

export default {
    name: 'ProgressCircular',
    computed: {
        ...mapGetters({
            getProgress: 'data/getProgress',
        }),
    },
};
</script>

<style scoped>
   .v-progress-circular {
        position: fixed;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 10000;
   }
</style>
