<template>
    <div>
        <v-card>
            <v-container fluid style="min-height: 500px;">
                <v-layout wrap align-center>
                    <v-flex xs8 sm6 d-flex>
                        <v-select v-model="compareIds" @change="updateCompareEnabled()" :items="compare" item-key="shipId" item-text="[name]"
                            item-value="shipId" :key="compareKey" attach chips label="Select ships" multiple></v-select>
                    </v-flex>
                    <v-flex xs4 sm2 md1 d-flex>
                        <v-select v-model="duration" :items="durations" item-key="name" item-text="[name]" item-value="value" label="Set duration"></v-select>
                    </v-flex>
                    <v-spacer />
                    <div>
                        <v-btn color="error" @click="clearCompareChart()">
                            Clear <v-icon right class="hidden-xs-only">cancel</v-icon>
                        </v-btn>
                        <v-btn color="primary" class="ml-4" @click="callFetchCompareData(compare)" :disabled="!compare">
                            Get data
                            <v-icon right class="hidden-xs-only">check</v-icon>
                        </v-btn>
                    </div>
                    <div>
                        <v-layout v-for="(item, index) in compare" :key="item.shipId" wrap align-center v-show="item.enabled">
                            <v-flex xs12 sm2>
                                <b>{{ item.name }}</b>
                            </v-flex>

                            <v-flex xs6 sm4 md2 d-flex>
                                <v-menu ref="sdmenu" v-model="item.startDateMenu" :close-on-content-click="true" :return-value.sync="item.startDate"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template #activator="{ on }">
                                        <v-text-field v-model="item.startDate" label="Start date">
                                            <template #prepend>
                                                <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker @change="$refs.sdmenu[index].save(item.startDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                                        v-model="item.startDate" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="item.startDateMenu = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.sdmenu[index].save(item.startDate)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs4 sm3 md1 d-flex>
                                <v-menu ref="stmenu" v-model="item.startTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                    :return-value.sync="item.startTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template #activator="{ on }">
                                        <v-text-field v-model="item.startTime" label="Start time" max-width="290px" min-width="290px">
                                            <template #prepend>
                                                <v-icon v-on="on" color="primary">access_time</v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-time-picker v-if="item.startTimeMenu" v-model="item.startTime" format="24hr"
                                        @click:minute="$refs.stmenu[index].save(item.startTime)"></v-time-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs2 md1>
                                <v-select v-model="item.timezone" :items="timezones" @change="saveTimezone(item.timezone, index)" item-key="value"
                                    item-text="[name]" item-value="value" label="Timezone" />
                            </v-flex>
                            <v-flex xs12 sm12 md6>
                                <v-select v-model="item.params" @change="updateCompareParams(index)" :items="item.allParams" :key="paramsKey" item-key="param"
                                    item-text="[name]" item-value="param" attach chips label="Select parameters" multiple></v-select>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-layout>
            </v-container>
            <v-container fluid class="pa-0" v-show="chart2 && chart2.data && chart2.data.length > 0">
                <v-layout wrap>
                    <v-flex xs12 lg8>
                        <v-card class="eventcard cardbg primarytext--text">
                            <v-container fluid grid-list-md class="pa-0">
                                <div class="chart" ref="chartdiv2">
                                </div>
                            </v-container>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 sm12 lg4>
                        <v-map v-if="chart2 && chart2.data && chart2.data.length > 0" class="map" ref="map2" :zoom="zoom" :center="center" :bounds="bounds"
                            :options="mapOptions">
                            <v-tilelayer :url="url" :options="{tileSize: 512, crossOrigin: true, zoomOffset: -1}"></v-tilelayer>
                            <v-tilelayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"></v-tilelayer>
                            <span v-if="routeGeojsons && routeGeojsons.length > 0">
                                <v-geojson v-for="(geojson, index) in routeGeojsons" :key="index" :geojson="geojson" :options="routeOptions[index]"></v-geojson>
                            </span>
                            <span v-for="m in markers" :key="m.shipId">
                                <span v-if="m && m.shipId">
                                    <v-geojson v-if="m && m.shipGeojson" :geojson="m.shipGeojson" :options="shipGeojsonOptions"></v-geojson>
                                    <v-rotated-marker v-if="m && m.predictor && m.predictor.latLng" :lat-lng="m.predictor.latLng" :icon="m.predictor.icon"
                                        :rotationAngle="m.predictor.direction" :rotationOrigin="m.predictor.rotationOrigin">
                                    </v-rotated-marker>
                                    <v-rotated-marker v-if="m && m.latLng" :lat-lng="m.latLng" :icon="m.icon" :rotationAngle="m.rotation">
                                        <l-tooltip v-if="m.tooltip" :content="m.tooltip" :options="tooltipOptions" />
                                        <l-popup v-if="m.popup" :content="m.popup"></l-popup>
                                    </v-rotated-marker>
                                    <span v-if="m.circles">
                                        <l-circle v-for="(circle, index2) in m.circles" :key="random()+index2" :lat-lng="circle.center" :radius="circle.radius"
                                            :fillOpacity="0.1" :color="circle.color" :weight="circle.weight"></l-circle>
                                    </span>
                                </span>
                            </span>
                            <div class="map-controls">
                                <v-icon v-show="chartTrackType2 === 'cursor'" @click="chartTrackType2 = 'lineSeries'"
                                    title="Chart cursor movement based ship tracking" class="control-btn" style="top:0">
                                    border_inner
                                </v-icon>
                                <v-icon v-show="chartTrackType2 === 'lineSeries'" @click="chartTrackType2 = null" title="Line series hover based ship tracking"
                                    class="control-btn" style="top:0">
                                    show_chart
                                </v-icon>
                                <v-icon v-show="chartTrackType2 === null" @click="chartTrackType2 = 'cursor'" title="No ship tracking on map"
                                    class="control-btn" style="top:0">
                                    block
                                </v-icon>
                                <v-icon v-show="chart2Pan === 'bounds'" @click="chart2Pan = 'ship1'" title="Zoom/pan to keep both ships visible"
                                    class="control-btn" style="top:40px">
                                    open_with
                                </v-icon>
                                <div v-show="chart2Pan === 'ship1'" @click="chart2Pan = 'ship2'" title="Zoom/pan map to ship 1 position" class="control-btn"
                                    style="top:40px;font-size:18px;color:#777">
                                    1
                                </div>
                                <div v-show="chart2Pan === 'ship2'" @click="chart2Pan = null" title="Zoom/pan map to ship 2 position" class="control-btn"
                                    style="top:40px;font-size:18px;color:#777">
                                    2
                                </div>
                                <v-icon v-show="chart2Pan === null" @click="chart2Pan = 'bounds'" title="No zoom or pan to ship position" class="control-btn"
                                    style="top:40px">
                                    block
                                </v-icon>
                            </div>
                        </v-map>
                    </v-flex>
                </v-layout>
            </v-container>
            <v-container fluid class="pa-0" v-show="chart2 && chart2.data && chart2.data.length > 0">
                <v-layout wrap>
                    <v-flex xs12 md6>
                        <v-data-table :headers="aggregateHeaders" :items="aggregateValues" class="elevation-1" dataPagination.rowsPerPage="10"
                            hide-default-footer>
                            <template #items="props">
                                <td>{{ props.item.name }}</td>
                                <td class="text-center">{{ props.item.min }} {{ props.item.unit }}</td>
                                <td class="text-center">{{ props.item.max }} {{ props.item.unit }}</td>
                                <td class="text-center">{{ props.item.avg }} {{ props.item.unit }}</td>
                                <td class="text-center">
                                    {{ props.item.sum }} {{ props.item.sumUnit }}
                                </td>
                            </template>
                        </v-data-table>
                    </v-flex>
                    <v-flex xs12 md6>
                        <v-data-table :headers="aggregateHeaders2" :items="aggregateValues2" class="elevation-1" dataPagination.rowsPerPage="10"
                            hide-default-footer>
                            <template #items="props">
                                <td>{{ props.item.name }}</td>
                                <td class="text-center">{{ props.item.min }} {{ props.item.unit }}</td>
                                <td class="text-center">{{ props.item.max }} {{ props.item.unit }}</td>
                                <td class="text-center">{{ props.item.avg }} {{ props.item.unit }}</td>
                                <td class="text-center">
                                    {{ props.item.sum }} {{ props.item.sumUnit }}
                                </td>
                            </template>
                        </v-data-table>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
        <!-- Components -->
        <ProgressCircular />
    </div>
</template>

<script>
    import L from 'leaflet';
    import {
        LMap,
        LTileLayer,
        LGeoJson,
        LTooltip,
        LPopup,
        LCircle
    } from 'vue2-leaflet';
    import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker';
    import {
        mapGetters,
        mapActions,
    } from 'vuex';
    import moment from 'moment';
    import {
        lineString
    } from '@turf/helpers';
    import * as am4core from '@amcharts/amcharts4/core';
    import * as am4charts from '@amcharts/amcharts4/charts';
    import am4themes_animated from '@amcharts/amcharts4/themes/animated';
    import ProgressCircular from './ProgressCircular.vue';
    import {
        shipHelpers,
        config
    } from '../helpers';

    am4core.useTheme(am4themes_animated);

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    export default {
        name: 'ComparePerformace',
        components: {
            'v-map': LMap,
            'v-tilelayer': LTileLayer,
            'v-geojson': LGeoJson,
            LTooltip,
            LPopup,
            LCircle,
            'v-rotated-marker': Vue2LeafletRotatedMarker,
            ProgressCircular
        },
        data() {
            return {
                // Leaflet specific properties
                zoom: 4,
                center: L.latLng(50, -10),
                mapOptions: {
                    attributionControl: false
                },
                tooltipOptions: {
                    permanent: true,
                    direction: 'top',
                    offset: L.point(0, -10),
                    opacity: 0.8,
                },
                bounds: null,
                shipGeojsonOptions: {
                    style: {
                        color: '#000000',
                        weight: 1,
                        opacity: 0.6
                    }
                },
                routeOptions: [{
                        style: {
                            color: '#e59165',
                            weight: 1.5,
                        }
                    },
                    {
                        style: {
                            color: '#0652DD',
                            weight: 1.5,
                        }
                    },
                    {
                        style: {
                            color: '#16a085',
                            weight: 1.5,
                        }
                    },
                ],
                marker: {
                    latLng: L.latLng(50, -10),
                },
                markers: [],
                routeGeojsons: [],
                ship: null,
                chartTrackType: 'cursor',
                chartTrackType2: 'cursor',
                compareIds: [],
                compare: [],
                chart2: {},
                chartPan: true,
                chart2Pan: 'bounds',
                duration: 24 * 60 * 60,
                durations: [{
                        name: '1h',
                        value: 1 * 60 * 60
                    },
                    {
                        name: '2h',
                        value: 2 * 60 * 60
                    },
                    {
                        name: '3h',
                        value: 3 * 60 * 60
                    },
                    {
                        name: '4h',
                        value: 4 * 60 * 60
                    },
                    {
                        name: '5h',
                        value: 5 * 60 * 60
                    },
                    {
                        name: '6h',
                        value: 6 * 60 * 60
                    },
                    {
                        name: '7h',
                        value: 7 * 60 * 60
                    },
                    {
                        name: '8h',
                        value: 8 * 60 * 60
                    },
                    {
                        name: '9h',
                        value: 9 * 60 * 60
                    },
                    {
                        name: '10h',
                        value: 10 * 60 * 60
                    },
                    {
                        name: '11h',
                        value: 11 * 60 * 60
                    },
                    {
                        name: '12h',
                        value: 12 * 60 * 60
                    },
                    {
                        name: '18h',
                        value: 18 * 60 * 60
                    },
                    {
                        name: '24h',
                        value: 24 * 60 * 60
                    },
                    {
                        name: '36h',
                        value: 36 * 60 * 60
                    },
                    {
                        name: '48h',
                        value: 48 * 60 * 60
                    },
                    {
                        name: '72h',
                        value: 72 * 60 * 60
                    },
                    {
                        name: '96h',
                        value: 96 * 60 * 60
                    },
                ],
                timezones: [{
                        name: '-11:00',
                        value: -660
                    },
                    {
                        name: '-10:00',
                        value: -600
                    },
                    {
                        name: '-09:00',
                        value: -540
                    },
                    {
                        name: '-08:00',
                        value: -480
                    },
                    {
                        name: '-07:00',
                        value: -420
                    },
                    {
                        name: '-06:00',
                        value: -360
                    },
                    {
                        name: '-05:00',
                        value: -300
                    },
                    {
                        name: '-04:00',
                        value: -240
                    },
                    {
                        name: '-03:00',
                        value: -180
                    },
                    {
                        name: '-02:00',
                        value: -120
                    },
                    {
                        name: '-01:00',
                        value: -60
                    },
                    {
                        name: 'UTC',
                        value: 0
                    },
                    {
                        name: '+01:00',
                        value: 60
                    },
                    {
                        name: '+02:00',
                        value: 120
                    },
                    {
                        name: '+03:00',
                        value: 180
                    },
                    {
                        name: '+04:00',
                        value: 240
                    },
                    {
                        name: '+05:00',
                        value: 300
                    },
                    {
                        name: '+06:00',
                        value: 360
                    },
                    {
                        name: '+07:00',
                        value: 420
                    },
                    {
                        name: '+08:00',
                        value: 480
                    },
                    {
                        name: '+09:00',
                        value: 540
                    },
                    {
                        name: '+10:00',
                        value: 600
                    },
                    {
                        name: '+11:00',
                        value: 660
                    },
                    {
                        name: '+12:00',
                        value: 720
                    },
                ],
                paramsKey: 0,
                compareKey: 0,
                colors: ['#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#fabebe', '#008080',
                    '#e6beff', '#9a6324', '#fffac8', '#800000', '#808000', '#ffd8b1', '#000075'
                ],
                aggregateValues: [],
                aggregateValues2: [],
                aggregateHeaders: [{
                        text: 'Parameter',
                        align: 'left',
                        value: 'name'
                    },
                    {
                        text: 'Min',
                        align: 'center',
                        value: 'min'
                    },
                    {
                        text: 'Max',
                        align: 'center',
                        value: 'max'
                    },
                    {
                        text: 'Average',
                        align: 'center',
                        value: 'avg'
                    },
                    {
                        text: 'Sum',
                        align: 'center',
                        value: 'sum'
                    },
                ],
                aggregateHeaders2: [{
                        text: 'Parameter',
                        align: 'left',
                        value: 'name'
                    },
                    {
                        text: 'Min',
                        align: 'center',
                        value: 'min'
                    },
                    {
                        text: 'Max',
                        align: 'center',
                        value: 'max'
                    },
                    {
                        text: 'Average',
                        align: 'center',
                        value: 'avg'
                    },
                    {
                        text: 'Sum',
                        align: 'center',
                        value: 'sum'
                    },
                ],
            };
        },
        mounted() {
            const chart2 = am4core.create(this.$refs.chartdiv2, am4charts.XYChart);
            chart2.dateFormatter.utc = true;
            chart2.paddingRight = 20;
            chart2.data = [];

            const chart2dateAxis = chart2.xAxes.push(new am4charts.DateAxis());
            chart2dateAxis.tooltipDateFormat = 'dd.MM HH:mm';
            chart2dateAxis.renderer.grid.template.location = 0;

            const chart2dateAxis2 = chart2.xAxes.push(new am4charts.DateAxis());
            chart2dateAxis2.tooltipDateFormat = 'dd.MM HH:mm';
            chart2dateAxis2.renderer.grid.template.location = 0;

            const chart2valueAxis = chart2.yAxes.push(new am4charts.ValueAxis());
            chart2valueAxis.tooltip.disabled = true;
            chart2valueAxis.renderer.labels.template.fill = am4core.color('#e59165');
            chart2valueAxis.renderer.width = 30;
            chart2valueAxis.min = 0;
            chart2valueAxis.max = 30;
            // chart2valueAxis.extraMax = 0.05; 
            chart2valueAxis.maxZoomFactor = 10;

            const chart2series = chart2.series.push(new am4charts.LineSeries());
            chart2series.name = 'SOG';
            chart2series.dataFields.dateX = 'time1';
            chart2series.dataFields.valueY = 'sog1';
            chart2series.tooltipText = '{tooltip}: {valueY.value}kn';
            chart2series.fill = am4core.color('#e59165');
            chart2series.stroke = am4core.color('#e59165');
            chart2series.strokeWidth = 2;
            chart2series.segments.template.interactionsEnabled = true;

            const chart2series2 = chart2.series.push(new am4charts.LineSeries());
            chart2series2.name = 'SOG';
            chart2series2.dataFields.dateX = 'time2';
            chart2series2.dataFields.valueY = 'sog2';
            chart2series2.xAxis = chart2dateAxis2;
            chart2series2.tooltipText = '{tooltip}: {valueY.value}kn';
            chart2series2.fill = am4core.color('#0652DD');
            chart2series2.stroke = am4core.color('#0652DD');
            chart2series2.strokeWidth = 2;
            chart2series2.segments.template.interactionsEnabled = true;

            chart2.cursor = new am4charts.XYCursor();
            chart2.cursor.xAxis = chart2dateAxis2;

            chart2.legend = new am4charts.Legend();
            chart2.legend.zIndex = 100;
            chart2.legend.opacity = 0.8;
            chart2.legend.useDefaultMarker = true;
            const markerTemplate2 = chart2.legend.markers.template;
            markerTemplate2.width = 12;
            markerTemplate2.height = 12;
            chart2.legend.itemContainers.template.paddingTop = 0;
            chart2.legend.itemContainers.template.paddingBottom = 0;
            chart2.legend.position = 'bottom';

            chart2dateAxis.renderer.grid.template.strokeOpacity = 0.07;
            chart2dateAxis2.renderer.grid.template.strokeOpacity = 0.07;
            chart2valueAxis.renderer.grid.template.strokeOpacity = 0.07;

            this.chart2 = chart2;

            // Track ship on map based on lineSeries hover
            chart2series.segments.template.events.on('over', (ev) => {
                if (this.chartTrackType2 === 'lineSeries') {
                    const marker = ev.target.dataItem.component.tooltipDataItem.dataContext;
                    this.markers[0] = this.trackShipOnMap(marker, true, 1);
                }
            }, this);
            chart2series2.segments.template.events.on('over', (ev) => {
                if (this.chartTrackType2 === 'lineSeries') {
                    const marker = ev.target.dataItem.component.tooltipDataItem.dataContext;
                    this.markers[1] = this.trackShipOnMap(marker, true, 2);
                }
            }, this);

            // Track ship on map based on cursor position
            chart2series.tooltip.events.on('positionchanged', function(ev) {
                if (this.chartTrackType2 === 'cursor') {
                    const marker = ev.target.dataItem.dataContext;
                    const bounds = {
                        ...this.bounds
                    }; // Store old bounds
                    this.markers[0] = this.trackShipOnMap(marker, true, 1);
                    this.bounds = bounds;
                }
            }, this);
            chart2series2.tooltip.events.on('positionchanged', function(ev) {
                if (this.chartTrackType2 === 'cursor') {
                    const marker = ev.target.dataItem.dataContext;
                    this.markers[1] = this.trackShipOnMap(marker, true, 2);
                }
            }, this);

            // Zoom map to extent on chart zoomed data timeframe
            chart2.cursor.events.on('zoomended', function(ev) {
                const range = ev.target.xRange;
                const axis = ev.target.chart.xAxes.getIndex(0);
                if (range && range.start && range.end) {
                    const start = axis.positionToDate(axis.toAxisPosition(range.start));
                    const end = axis.positionToDate(axis.toAxisPosition(range.end));
                    this.sliceData(start, end, this.chart2.data);
                }
            }, this);

            // Reset data on zoomout button
            chart2.zoomOutButton.events.on('hit', function() {
                this.resetZoomedData(this.chart2.data);
            }, this);

            // Preselect first ship and create compare array
            this.timezone = localStorage.getItem('timezone') ? parseInt(localStorage.getItem('timezone')) : 0;
            this.duration = localStorage.getItem('perfCompareDuration') ? parseInt(localStorage.getItem('perfCompareDuration')) : 24 * 60 * 60;

            this.fetchShips().then((data) => {
                for (const ship of data) {
                    if (ship.perfDb) {
                        this.compare.push({
                            name: ship.name,
                            shipId: ship.id,
                            startDate: moment.utc().utcOffset(this.timezone).subtract(1, 'days').format('YYYY-MM-DD'),
                            startTime: '00:00',
                            enabled: false,
                            timezone: this.timezone,
                            params: ['sog'],
                            prefix: ship.perfDb,
                        });
                    }
                }
                this.compareIds = localStorage.getItem('perfCompareIds') ? JSON.parse(localStorage.getItem('perfCompareIds')) : [];
                if (this.compareIds.length > 0) {
                    this.updateCompareEnabled();
                }
            });
        },
        beforeDestroy() {
            if (this.chart2) {
                this.chart2.dispose();
            }
        },
        computed: {
            url() {
                if (this.getDarkMode()) {
                    return `https://api.maptiler.com/maps/d0b6607a-df0c-4660-af62-030764e2efc4/{z}/{x}/{y}.png?key=${config.apiKey}`;
                }
                return `https://api.maptiler.com/maps/511be076-e981-4e63-8d86-7f23e4d3e7f5/{z}/{x}/{y}.png?key=${config.apiKey}`;
            },
            ships() {
                return this.getShips();
            },
            firstDayOfWeek() {
                return this.getFirstDayOfWeek();
            },
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getShips: 'data/getShips',
                getFirstDayOfWeek: 'data/getFirstDayOfWeek',
            }),
            ...mapActions({
                fetchShips: 'data/fetchShips',
                fetchComparePerformanceData: 'data/fetchComparePerformanceData',
                fetchPerformanceParams: 'data/fetchPerformanceParams',
                fetchAggregateValues: 'data/fetchAggregateValues',
            }),
            callFetchCompareData(compare) {
                this.markers = [];
                if (this.compareIds.length > 3) {
                    this.$store.dispatch('alert/error', 'Max 2 ships can be compared. Remove extra ships', {
                        root: true
                    });
                    return;
                }
                if (this.compareIds.length <= 1) {
                    this.$store.dispatch('alert/error', 'Select 2 ships to compare', {
                        root: true
                    });
                    return;
                }
                // Save selection to localstorage
                localStorage.setItem('perfCompareIds', JSON.stringify(this.compareIds));
                localStorage.setItem('perfCompareDuration', this.duration);
                compare = compare.filter((o) => {
                    const start = moment(`${o.startDate}T${o.startTime}:00`);
                    const end = start.add(this.duration, 'seconds');
                    o.endDate = end.format('YYYY-MM-DD');
                    o.endTime = end.format('HH:mm');
                    return o.enabled;
                });
                // Sort by shipId always, like compareIds array
                compare.sort((a, b) => a.shipId - b.shipId);
                this.fetchComparePerformanceData(compare).then((data) => {
                    if (this.chart2) {
                        const ship1 = [];
                        const ship2 = [];
                        this.routeGeojsons = [];
                        for (const key in data) {
                            if (data[key].time1) {
                                data[key].time1 = moment.utc(data[key].time1).toDate();
                                if (data[key].longitude && data[key].latitude) {
                                    ship1.push([data[key].longitude, data[key].latitude]);
                                }
                            } else if (data[key].time2) {
                                data[key].time2 = moment.utc(data[key].time2).toDate();
                                if (data[key].longitude && data[key].latitude) {
                                    ship2.push([data[key].longitude, data[key].latitude]);
                                }
                            }
                        }
                        if (ship1.length > 1) {
                            this.routeGeojsons.push(lineString(ship1, {
                                name: 'route'
                            }));
                        }
                        if (ship2.length > 1) {
                            this.routeGeojsons.push(lineString(ship2, {
                                name: 'route'
                            }));
                        }

                        // Remove old series, except SOG&Trendline
                        this.removeSeries(this.chart2);

                        // Remove old hidden yaxis and create new
                        if (this.chart2.yAxes.hasIndex(4)) {
                            this.chart2.yAxes.removeIndex(4).dispose();
                        }
                        if (this.chart2.yAxes.hasIndex(3)) {
                            this.chart2.yAxes.removeIndex(3).dispose();
                        }
                        if (this.chart2.yAxes.hasIndex(2)) {
                            this.chart2.yAxes.removeIndex(2).dispose();
                        }
                        if (this.chart2.yAxes.hasIndex(1)) {
                            this.chart2.yAxes.removeIndex(1).dispose();
                        }
                        for (let i = 0; i < 4; i++) {
                            const valueAxis = this.chart2.yAxes.push(new am4charts.ValueAxis());
                            valueAxis.hidden = true;
                            valueAxis.tooltip.disabled = true;
                            valueAxis.renderer.width = 0;
                            valueAxis.renderer.grid.template.disabled = true;
                            valueAxis.renderer.labels.template.disabled = true;
                            // if (i === 3) { // 4th yxis is inverted
                            //     valueAxis.renderer.inversed = true;
                            // }
                        }
                        // Create new selected series
                        const index0 = this.compare.findIndex((o) => o.shipId === this.compareIds[0]);
                        const index1 = this.compare.findIndex((o) => o.shipId === this.compareIds[1]);
                        let params = [];
                        if (this.compare[index0].params && this.compare[index0].params.length > 0) {
                            params = this.compare[index0].allParams.filter((o) => {
                                o.index = 1;
                                return this.compare[index0].params.includes(o.param);
                            });
                        }
                        let params1 = [];
                        if (this.compare[index1].params && this.compare[index1].params.length > 0) {
                            params1 = this.compare[index1].allParams.filter((o) => {
                                o.index = 2;
                                return this.compare[index1].params.includes(o.param);
                            });
                        }
                        Array.prototype.push.apply(params, params1);
                        let i = 0;
                        for (const param of params) {
                            if (param.param !== 'sog') {
                                // Set line color. Special colors for PS and SB
                                let color;
                                switch (param.param) {
                                    case 'rpm_stb1':
                                        color = '#3CBA54';
                                        break;
                                    case 'rpm_ps1':
                                        color = '#DB3236';
                                        break;
                                    case 'p_power_stb1':
                                        color = '#006400';
                                        break;
                                    case 'p_power_ps1':
                                        color = '#FD5C63';
                                        break;
                                    case 'p_torque_stb1':
                                        color = '#228B22';
                                        break;
                                    case 'p_torque_ps1':
                                        color = '#BB0000';
                                        break;
                                    case 'p_efficiency_stb1':
                                        color = '#32CD32';
                                        break;
                                    case 'p_efficiency_ps1':
                                        color = '#FF6347';
                                        break;
                                    case 'motion_roll_max':
                                        color = '#00FF00';
                                        break;
                                    case 'motion_roll_min':
                                        color = '#FF0000';
                                        break;
                                    default:
                                        color = this.colors[i];
                                        break;
                                }

                                const seriesParams = {
                                    name: param.name,
                                    dateX: `time${param.index}`,
                                    unit: param.unit,
                                    valueY: param.param + param.index,
                                    tooltipText: '{tooltip}: {valueY.value} {unit}',
                                    color,
                                    width: 1,
                                    interactions: false
                                };

                                const yAxesIndex = param.yAxis ? param.yAxis : 1;
                                // console.log(seriesParams,this.chart2.xAxes,this.chart2.yAxes.getIndex(yAxesIndex),  this.chart2.xAxes.getIndex(0), param.index, param.param)
                                this.createSeries(seriesParams, this.chart2, this.chart2.yAxes.getIndex(yAxesIndex), this.chart2.xAxes.getIndex(param
                                    .index - 1));
                                ++i;
                            }
                        }
                        this.chart2.data = data;

                        // Get aggregate values
                        this.getAggregateValues(this.compare[index0], 0);
                        this.getAggregateValues(this.compare[index1], 1);
                    }
                });
            },
            createSeries(params, chart, valueAxis, dateAxis) {
                const series = chart.series.push(new am4charts.LineSeries());
                series.name = params.name;
                series.unit = params.unit;
                series.dataFields.dateX = params.dateX;
                series.dataFields.valueY = params.valueY;
                series.yAxis = valueAxis;
                series.xAxis = dateAxis;
                series.tooltipText = params.tooltipText;
                series.fill = am4core.color(params.color);
                series.stroke = am4core.color(params.color);
                series.strokeWidth = params.width;
                series.segments.template.interactionsEnabled = params.interactions;
                if (params.valueY.includes('dpt')) { // Depth has fill color
                    series.fill = am4core.color('#1e90ff');
                    series.stroke = am4core.color('#1e90ff');
                    series.fillOpacity = 1;
                    const fillModifier = new am4core.LinearGradientModifier();
                    fillModifier.opacities = [0, 0.5];
                    fillModifier.offsets = [0, 1];
                    fillModifier.gradient.rotation = 90;
                    series.segments.template.fillModifier = fillModifier;
                    series.yAxis.renderer.inversed = true;
                }
                return series;
            },
            removeSeries(chart) {
                for (let i = 0; i <= chart.series.length; i++) {
                    if (chart.series.hasIndex(3) && chart.series.getIndex(3).name !== 'SOG') {
                        chart.series.removeIndex(3).dispose();
                    }
                    if (chart.series.hasIndex(2) && chart.series.getIndex(2).name !== 'SOG') {
                        chart.series.removeIndex(2).dispose();
                    }
                }
            },
            getAggregateValues(compare, index) {
                this.fetchAggregateValues(compare).then((data) => {
                    const aggregateValues = [];
                    for (const p of compare.params) {
                        const param = compare.allParams.find((o) => o.param === p);
                        if (data && param && param.source !== 'Aggregate') {
                            aggregateValues.push({
                                name: param.name,
                                unit: param.unit,
                                min: data[`${p}_min`],
                                max: data[`${p}_max`],
                                avg: data[`${p}_avg`],
                                sum: data[`${p}_sum`],
                                sumUnit: data[`${p}_sumUnit`],
                            });
                        }
                    }
                    if (index === 0) {
                        this.aggregateValues = aggregateValues;
                        this.aggregateHeaders[4].text =
                            `Sum${this.toHoursMinutes(compare.startDate, compare.startTime, compare.endDate, compare.endTime)}`;
                    } else if (index === 1) {
                        this.aggregateValues2 = aggregateValues;
                        this.aggregateHeaders2[4].text =
                            `Sum${this.toHoursMinutes(compare.startDate, compare.startTime, compare.endDate, compare.endTime)}`;
                    }
                });
            },
            toHoursMinutes(startDate, startTime, endDate, endTime) {
                const end = moment.utc(`${endDate}T${endTime}`);
                const start = moment.utc(`${startDate}T${startTime}`);
                const hours = end.diff(start, 'hours');
                const mins = end.diff(start, 'minutes') - 60 * hours;
                return ` (${hours}h ${mins}m)`;
            },
            trackShipOnMap(marker, compare, shipNro) {
                if (marker && marker.latitude && marker.longitude) {
                    marker.latLng = L.latLng(marker.latitude, marker.longitude);
                    if (compare) {
                        if (this.chart2Pan === 'bounds') {
                            const coords = [];
                            for (const key in this.markers) {
                                if (this.markers[key] && this.markers[key].longitude && this.markers[key].latitude) {
                                    coords.push([this.markers[key].latitude, this.markers[key].longitude]);
                                }
                            }
                            coords.push([marker.latitude, marker.longitude]);
                            this.bounds = L.latLngBounds(coords).pad(0.6);
                        } else if (this.chart2Pan === 'ship1' && shipNro === 1) {
                            this.center = L.latLng(marker.latitude, marker.longitude);
                        } else if (this.chart2Pan === 'ship2' && shipNro === 2) {
                            this.center = L.latLng(marker.latitude, marker.longitude);
                        } else {
                            this.bounds = null;
                        }
                    }
                    marker.rotation = shipHelpers.headingOrCog(marker.heading, marker.cog);
                    if (!this.ship || compare) {
                        this.ship = this.getShips().filter((o) => o.id == marker.shipId)[0];
                    }
                    const prefix = this.ship.external === 1 ? 'ts' : 'os';
                    const icon = shipHelpers.shipIcon(marker.navstat, prefix, 222222222, null, marker.sog);
                    marker.icon = (
                        L.icon({
                            iconUrl: require(`@/assets/img/ships/${icon}`),
                            iconSize: [11, 30], // size of the icon
                            iconAnchor: [5.5, 15], // point of the icon which will correspond to marker's location
                        })
                    );
                    if (this.ship) {
                        marker.tooltip = this.ship.name;
                        marker.a = this.ship.a;
                        marker.b = this.ship.b;
                        marker.c = this.ship.c;
                        marker.d = this.ship.d;
                        marker.shipname = this.ship.shortName;
                    }
                    marker.options = {};
                    marker.options.rotationOrigin = 'center center';
                    // marker.shipGeojson = shipHelpers.shipGeojson(marker);
                    marker.circles = [];
                    const radiuses = [926, 1852, 2778];
                    for (const radius of radiuses) {
                        marker.circles.push({
                            center: L.latLng(marker.latitude, marker.longitude),
                            radius,
                            color: 'rgba(150, 150, 150, 0.3)',
                            weight: 0.5
                        });
                    }
                    marker.predictor = null;
                    let sog = marker.sog !== undefined ? marker.sog : marker.sog1;
                    sog = sog !== undefined ? sog : marker.sog2;
                    sog = sog !== undefined ? sog : marker.sog3;
                    const arrow = shipHelpers.vectorSvg(sog, 'white');
                    if (arrow) {
                        marker.predictor = {
                            latLng: L.latLng(marker.latitude, marker.longitude),
                            direction: shipHelpers.headingOrCog(marker.heading, marker.cog, true),
                            icon: L.icon({
                                iconUrl: arrow.image,
                                iconSize: [8, arrow.height],
                                iconAnchor: [4, arrow.height],
                            }),
                            rotationOrigin: 'center bottom',
                        };
                    }

                    marker.popup = `<b>${this.ship ? this.ship.name : ''}</b><br/>
                        SOG: ${Math.round(sog * 10) / 10} kn<br/>
                        Draught: ${Math.round(marker.draught * 10) / 10} m<br/>
                        Status: <span class="${shipHelpers.getNavstat(marker.navstat).color}--text">${shipHelpers.getNavstat(marker.navstat).status}</span><br/>
                        Last seen: ${moment.utc(marker.time).format('D.M HH:mm')}<br/>`;

                    return marker;
                }
            },
            random() {
                return Math.random();
            },
            getNavstat(navstat) {
                return shipHelpers.getNavstat(navstat);
            },
            sliceData(start, end, data) {
                const zoomedData = [];
                const coordsLatLon = [];
                const coordsLonLat = [];
                const ship1 = [];
                const ship2 = [];
                this.routeGeojsons = [];
                for (const key in data) {
                    if (data.hasOwnProperty(key) && data[key].time) {
                        if (Math.round(moment.utc(data[key].time).unix() / 600) >= Math.round(moment.utc(start).unix() / 600)) {
                            zoomedData.push(data[key]);
                            if (data[key].latitude && data[key].longitude) {
                                coordsLonLat.push([data[key].longitude, data[key].latitude]);
                            }
                            if (Math.round(moment.utc(data[key].time).unix() / 600) >= Math.round(moment.utc(end).unix() / 600)) {
                                break;
                            }
                        }
                    } else if (data[key].time1) {
                        if (Math.round(moment.utc(data[key].time1).unix() / 600) >= Math.round(moment.utc(start).unix() / 600) && Math.round(moment.utc(data[
                                key].time1).unix() / 600) <= Math.round(moment.utc(end).unix() / 600)) {
                            ship1.push([data[key].longitude, data[key].latitude]);
                        }
                    } else if (data[key].time2) {
                        if (Math.round(moment.utc(data[key].time2).unix() / 600) >= Math.round(moment.utc(start).unix() / 600) && Math.round(moment.utc(data[
                                key].time2).unix() / 600) <= Math.round(moment.utc(end).unix() / 600)) {
                            ship2.push([data[key].longitude, data[key].latitude]);
                        }
                    }
                    if (data[key].latitude && data[key].longitude) {
                        coordsLatLon.push([data[key].latitude, data[key].longitude]);
                    }
                }
                // Set bound based on sliced data
                if (coordsLatLon && coordsLatLon.length > 0) {
                    if ((this.chartPan && coordsLonLat.length > 1) || this.chart2Pan && ship1.length > 1) {
                        this.bounds = L.latLngBounds(coordsLatLon);
                    }
                }
                if (ship1.length > 1) {
                    this.routeGeojsons.push(lineString(ship1, {
                        name: 'route'
                    }));
                }
                if (ship2.length > 1) {
                    this.routeGeojsons.push(lineString(ship2, {
                        name: 'route'
                    }));
                }
                return zoomedData;
            },
            resetZoomedData(data) {
                const coordsLatLon = [];
                const coordsLonLat = [];
                const ship1 = [];
                const ship2 = [];
                this.routeGeojsons = [];
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (data[key].time && data[key].latitude && data[key].longitude) {
                            coordsLonLat.push([data[key].longitude, data[key].latitude]);
                        }
                        if (data[key].latitude && data[key].longitude) {
                            coordsLatLon.push([data[key].latitude, data[key].longitude]);
                        }
                        if (data[key].time1) {
                            ship1.push([data[key].longitude, data[key].latitude]);
                        } else if (data[key].time2) {
                            ship2.push([data[key].longitude, data[key].latitude]);
                        }
                    }
                }

                // Set bound based on sliced data
                this.bounds = L.latLngBounds(coordsLatLon);
                // Show sliced track on map
                if (ship1.length > 1) {
                    this.routeGeojsons.push(lineString(ship1, {
                        name: 'route'
                    }));
                }
                if (ship2.length > 1) {
                    this.routeGeojsons.push(lineString(ship2, {
                        name: 'route'
                    }));
                }
            },
            updateCompareEnabled() {
                if (this.compareIds.length > 2) {
                    this.$store.dispatch('alert/error', 'Only 2 ships can be compared same time. Remove extra ships.', {
                        root: true
                    });
                }
                // Sort ids
                this.compareIds.sort((a, b) => a - b);
                if (this.compareIds && this.compareIds.length > 0) {
                    for (const key in this.compare) {
                        if (this.compare.hasOwnProperty(key)) {
                            if (this.compareIds.includes(this.compare[key].shipId)) {
                                this.compare[key].enabled = true;
                                // Get parameter group values
                                if (this.compare[key].prefix) {
                                    this.fetchPerformanceParams({
                                        prefix: this.compare[key].prefix
                                    }).then((data) => {
                                        this.compare[key].allParams = data.filter((o) => {
                                            if (o.param === 'sog') {
                                                o.disabled = true;
                                                o.name = 'SOG';
                                            }
                                            return o.group !== 'Calculated' && o.param !== 'events' && o.param !== 'logbook';
                                        });
                                        this.paramsKey++;
                                    });
                                }
                            } else {
                                this.compare[key].enabled = false;
                            }
                        }
                    }
                } else {
                    for (const key in this.compare) {
                        if (this.compare.hasOwnProperty(key)) {
                            this.compare[key].enabled = false;
                        }
                    }
                }
            },
            updateCompareParams(index) {
                if (this.compare[index] && this.compare[index].params && this.compare[index].params.length > 4) {
                    this.$store.dispatch('alert/error', 'Only 3 parameters per ship can be compared. Remove extra parameter.', {
                        root: true
                    });
                }
            },
            clearCompareChart() {
                this.compareIds = [];
                for (const key in this.compare) {
                    this.compare[key].params = ['sog'];
                    this.compare[key].startDate = moment.utc().utcOffset(this.timezone).subtract(1, 'days').format('YYYY-MM-DD');
                    this.compare[key].startTime = '00:00';
                    this.compare[key].endDate = null;
                    this.compare[key].endTime = null;
                    this.compare[key].enabled = false;
                }
                if (this.chart2 && this.chart2.data) {
                    this.chart2.data = [];
                }
                this.compareKey++;
            },
            saveTimezone(timezone, index) {
                const prevTimezone = localStorage.getItem('timezone') ? parseInt(localStorage.getItem('timezone')) : 0;
                localStorage.setItem('timezone', timezone);
                const start = moment.utc(`${this.compare[index].startDate} ${this.compare[index].startTime}`).utcOffset(timezone - prevTimezone);
                const end = moment.utc(`${this.compare[index].endDate} ${this.compare[index].endTime}`).utcOffset(timezone - prevTimezone);
                this.compare[index].startDate = start.format('YYYY-MM-DD');
                this.compare[index].startTime = start.format('HH:mm');
                this.compare[index].endDate = end.format('YYYY-MM-DD');
                this.compare[index].endTime = end.format('HH:mm');
            },
        }
    };
</script>

<style>
    .v-window__container--is-active {
        overflow: visible;
    }

    .v-select--chips {
        margin-left: 15px;
        margin-bottom: 10px;
    }
</style>
<style scoped>
    .chart,
    .map {
        width: 100%;
        height: 500px;
    }

    .control-btn {
        position: absolute;
    }
</style>