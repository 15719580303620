export function handleResponse(response) {
    // console.log(response)
    if (response.status !== 200 && response.status !== 204) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem('user');
            location.reload(true);
        }

        return Promise.reject(response);
    }
    return response.data;
}
