<template>
    <div>
        <v-container fluid grid-list-lg>
            <v-layout wrap align-center>
                <v-flex xs12 sm4 lg2 d-flex>
                    <v-select v-model="weather.shipId" :items="ships" item-key="id" item-text="[name]" item-value="id" label="Select ship" />
                </v-flex>

                <v-flex xs6 sm4 md2 d-flex>
                    <v-menu ref="wsdmenu" v-model="wStartDateMenu" :close-on-content-click="true" :return-value.sync="weather.startDate"
                        transition="scale-transition" offset-y min-width="290px">
                        <template #activator="{ on }">
                            <v-text-field v-model="weather.startDate" label="Start date">
                                <template #prepend>
                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker @change="$refs.wsdmenu.save(weather.startDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                            v-model="weather.startDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="wStartDateMenu = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.wsdmenu.save(weather.startDate)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>
                <v-flex xs6 sm3 md2 lg1 d-flex>
                    <v-menu ref="wstmenu" v-model="wStartTimeMenu" :close-on-content-click="true" :nudge-right="40" :return-value.sync="weather.startTime"
                        transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template #activator="{ on }">
                            <v-text-field v-model="weather.startTime" label="Start time">
                                <template #prepend>
                                    <v-icon v-on="on" color="primary">access_time</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-time-picker v-if="wStartTimeMenu" v-model="weather.startTime" format="24hr" 
                            @click:minute="$refs.wstmenu.save(weather.startTime)"></v-time-picker>
                    </v-menu>
                </v-flex>
                <v-flex xs6 sm4 md2 d-flex>
                    <v-menu ref="wedmenu" v-model="wEndDateMenu" :close-on-content-click="false" :return-value.sync="weather.endDate"
                        transition="scale-transition" offset-y min-width="290px">
                        <template #activator="{ on }">
                            <v-text-field v-model="weather.endDate" label="End date">
                                <template #prepend>
                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker @change="$refs.wedmenu.save(weather.endDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                            v-model="weather.endDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="wEndDateMenu = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.wedmenu.save(weather.endDate)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>
                <v-flex xs6 sm3 md2 lg1 d-flex>
                    <v-menu ref="wetmenu" v-model="wEndTimeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="weather.endTime"
                        transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template #activator="{ on }">
                            <v-text-field v-model="weather.endTime" label="End time">
                                <template #prepend>
                                    <v-icon v-on="on" color="primary">access_time</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-time-picker v-if="wEndTimeMenu" v-model="weather.endTime" format="24hr" 
                            @click:minute="$refs.wetmenu.save(weather.endTime)"></v-time-picker>
                    </v-menu>
                </v-flex>

                <v-flex xs2 sm1 md1 d-flex>
                    <v-select v-model="weather.timezone" :items="timezones" @change="saveTimezone(analyze.timezone)" item-key="value" item-text="[name]"
                        item-value="value" label="Timezone" />
                </v-flex>
                <v-flex xs6 sm3 md2 xl1 d-flex>
                    <v-select @change="setWindLimit()" v-model="windLimit" :items="windLimits" item-key="value" item-text="[name]" item-value="value"
                        label="Wind treshold" />
                </v-flex>
                <v-spacer />
                <div class="mb-4">
                    <v-btn color="error" @click="clearChart()">
                        Clear <v-icon right class="hidden-xs-only">cancel</v-icon>
                    </v-btn>
                    <v-btn color="primary" class="ml-4" @click="callFetchWeatherData(weather)"
                        :disabled="!weather.shipId && !weather.startDate && !weather.startTime && !weather.endDate && !weather.endTime">
                        Get data
                        <v-icon right class="hidden-xs-only">check</v-icon>
                    </v-btn>
                </div>
            </v-layout>
        </v-container>
        <v-container fluid class="pa-0" v-show="chart && chart.data && chart.data.length > 0">
            <v-layout wrap>
                <v-flex xs12 sm12 lg8>
                    <v-card class="eventcard cardbg primarytext--text">
                        <v-container fluid grid-list-md class="pa-0">
                            <div class="chart" ref="chartdiv">
                            </div>
                        </v-container>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm12 lg4>
                    <v-map v-if="chart && chart.data && chart.data.length > 0" class="map" ref="map" :zoom="zoom" :center="center" :bounds="bounds"
                        :options="mapOptions">
                        <v-tilelayer :url="url" :options="{tileSize: 512, crossOrigin: true, zoomOffset: -1}"></v-tilelayer>
                        <v-tilelayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"></v-tilelayer>
                        <v-geojson v-if="routeGeojson" :geojson="routeGeojson" :options="routeOptions[0]"></v-geojson>
                        <v-geojson v-if="marker && marker.shipGeojson" :geojson="marker.shipGeojson" :options="shipGeojsonOptions"></v-geojson>
                        <v-rotated-marker v-if="marker && marker.predictor && marker.predictor.latLng" :lat-lng="marker.predictor.latLng"
                            :icon="marker.predictor.icon" :rotationAngle="marker.predictor.direction" :rotationOrigin="marker.predictor.rotationOrigin">
                        </v-rotated-marker>
                        <v-rotated-marker v-if="marker && marker.latitude && marker.longitude && marker.latLng" :lat-lng="marker.latLng" :icon="marker.icon"
                            :rotationAngle="marker.rotation">
                            <l-tooltip v-if="marker.tooltip" :content="marker.tooltip" :options="tooltipOptions" />
                            <l-popup v-if="marker.popup" :content="marker.popup"></l-popup>
                        </v-rotated-marker>
                        <span v-if="marker && marker.circles">
                            <l-circle v-for="(circle, index) in marker.circles" :key="random()+index" :lat-lng="circle.center" :radius="circle.radius"
                                :fillOpacity="0.1" :color="circle.color" :weight="circle.weight"></l-circle>
                        </span>
                        <div v-if="marker && marker.sog" class="aisinfo primarytext--text">
                            {{ Math.round(marker.sog*10)/10 }}kn <span :class="getNavstat(marker.navstat).color + '--text'">
                                {{
                                    getNavstat(marker.navstat).status }}</span><br />
                            {{ decimalToDmm(marker.latitude, false) }} {{
                                decimalToDmm(marker.longitude, true) }}
                        </div>
                        <div v-if="marker && marker.roll_max" class="sensorinfo primarytext--text">
                            Roll max: {{
                                Math.round(marker.roll_max*1000)/1000 }}° / Pitch max: {{
                                Math.round(marker.pitch_max*1000)/1000 }}°
                        </div>
                        <div class="map-controls">
                            <v-icon v-show="chartTrackType === 'cursor'" @click="chartTrackType = 'lineSeries'"
                                title="Chart cursor movement based ship tracking" class="control-btn" style="top:0">
                                border_inner
                            </v-icon>
                            <v-icon v-show="chartTrackType === 'lineSeries'" @click="chartTrackType = null" title="Line series hover based ship tracking"
                                class="control-btn" style="top:0">
                                show_chart
                            </v-icon>
                            <v-icon v-show="chartTrackType === null" @click="chartTrackType = 'cursor'" title="No ship tracking on map" class="control-btn"
                                style="top:0">
                                block
                            </v-icon>
                            <v-icon @click="chartPan = false" title="Zoom and pan map to ship position" class="control-btn" style="top:40px">
                                open_with
                            </v-icon>
                            <v-icon v-show="!chartPan" @click="chartPan = true" title="No zoom or pan to ship position" class="control-btn"
                                style="font-size:30px;top:40px;background:rgba(255, 255, 255, 0.5)">
                                clear
                            </v-icon>
                        </div>
                    </v-map>
                </v-flex>
            </v-layout>
        </v-container>
        <v-container fluid class="pa-0" v-show="chart && chart.data && chart.data.length > 0">
            <v-layout wrap>
                <v-flex xs12 md6>
                    <v-data-table :headers="aggregateHeaders" :items="aggregateValues.below" class="elevation-1" hide-default-footer="">
                        <template #items="props">
                            <td>{{ props.item.name }}</td>
                            <td class="text-center">{{ props.item.min }} {{ props.item.unit }}</td>
                            <td class="text-center">{{ props.item.max }} {{ props.item.unit }}</td>
                            <td class="text-center">{{ props.item.avg }} {{ props.item.unit }}</td>
                            <td class="text-center">
                                {{ props.item.sum }} {{ props.item.sumUnit }}
                            </td>
                        </template>
                    </v-data-table>
                </v-flex>
                <v-flex xs12 md6>
                    <v-data-table :headers="aggregateHeaders2" :items="aggregateValues.above" class="elevation-1" hide-default-footer="">
                        <template #items="props">
                            <td>{{ props.item.name }}</td>
                            <td class="text-center">{{ props.item.min }} {{ props.item.unit }}</td>
                            <td class="text-center">{{ props.item.max }} {{ props.item.unit }}</td>
                            <td class="text-center">{{ props.item.avg }} {{ props.item.unit }}</td>
                            <td class="text-center">
                                {{ props.item.sum }} {{ props.item.sumUnit }}
                            </td>
                        </template>
                    </v-data-table>
                </v-flex>
            </v-layout>
        </v-container>

        <!-- Components -->
        <ProgressCircular />
    </div>
</template>

<script>
    import L from 'leaflet';
    import {
        LMap,
        LTileLayer,
        LGeoJson,
        LTooltip,
        LPopup,
        LCircle
    } from 'vue2-leaflet';
    import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker';
    import {
        mapGetters,
        mapActions,
    } from 'vuex';
    import moment from 'moment';
    import {
        lineString
    } from '@turf/helpers';
    import * as am4core from '@amcharts/amcharts4/core';
    import * as am4charts from '@amcharts/amcharts4/charts';
    import am4themes_animated from '@amcharts/amcharts4/themes/animated';
    import ProgressCircular from './ProgressCircular.vue';
    import {
        shipHelpers,
        config,
        tools,
    } from '../helpers';

    am4core.useTheme(am4themes_animated);

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    export default {
        name: 'CPCompliance',
        components: {
            'v-map': LMap,
            'v-tilelayer': LTileLayer,
            'v-geojson': LGeoJson,
            LTooltip,
            LPopup,
            LCircle,
            'v-rotated-marker': Vue2LeafletRotatedMarker,
            ProgressCircular
        },
        data() {
            return {
                // Leaflet specific properties
                zoom: 4,
                center: L.latLng(50, -10),
                mapOptions: {
                    attributionControl: false
                },
                tooltipOptions: {
                    permanent: true,
                    direction: 'top',
                    offset: L.point(0, -10),
                    opacity: 0.8,
                },
                bounds: null,
                shipGeojsonOptions: {
                    style: {
                        color: '#000000',
                        weight: 1,
                        opacity: 0.6
                    }
                },
                routeOptions: [{
                        style: {
                            color: '#e59165',
                            weight: 1.5,
                        }
                    },
                    {
                        style: {
                            color: '#0652DD',
                            weight: 1.5,
                        }
                    },
                    {
                        style: {
                            color: '#16a085',
                            weight: 1.5,
                        }
                    },
                ],
                weather: {
                    shipId: null,
                    startDate: moment.utc().subtract(1, 'days').format('YYYY-MM-DD'),
                    startTime: '00:00',
                    endDate: moment.utc().format('YYYY-MM-DD'),
                    endTime: moment.utc().format('HH:mm'),
                    timezone: 0,
                    type: 'weather'
                },
                wStartDateMenu: false,
                wStartTimeMenu: false,
                wEndDateMenu: false,
                wEndTimeMenu: false,
                marker: {
                    latLng: L.latLng(50, -10),
                },
                markers: [],
                routeGeojson: null,
                routeGeojsons: [],
                ship: null,
                tooltip: null,
                chartTrackType: 'cursor',
                timeoutFired: true,
                chart: {},
                limitSeries: {},
                chartPan: true,
                windLimit: 7.9,
                windLimits: [{
                        value: 0.5,
                        name: 'Over 0BF'
                    },
                    {
                        value: 1.5,
                        name: 'Over 1BF'
                    },
                    {
                        value: 3.3,
                        name: 'Over 2BF'
                    },
                    {
                        value: 5.5,
                        name: 'Over 3BF'
                    },
                    {
                        value: 7.9,
                        name: 'Over 4BF'
                    },
                    {
                        value: 10.7,
                        name: 'Over 5BF'
                    },
                    {
                        value: 13.8,
                        name: 'Over 6BF'
                    },
                    {
                        value: 17.1,
                        name: 'Over 7BF'
                    },
                    {
                        value: 20.7,
                        name: 'Over 8BF'
                    },
                    {
                        value: 24.4,
                        name: 'Over 9BF'
                    },
                    {
                        value: 28.4,
                        name: 'Over 10BF'
                    },
                    {
                        value: 32.6,
                        name: 'Over 11BF'
                    },
                ],
                aggregateValues: [],
                aggregateHeaders: [{
                        text: 'Below wind treshold',
                        align: 'left',
                        value: 'name'
                    },
                    {
                        text: 'Min',
                        align: 'center',
                        value: 'min'
                    },
                    {
                        text: 'Max',
                        align: 'center',
                        value: 'max'
                    },
                    {
                        text: 'Average',
                        align: 'center',
                        value: 'avg'
                    },
                    {
                        text: 'Sum',
                        align: 'center',
                        value: 'sum'
                    },
                ],
                aggregateHeaders2: [{
                        text: 'Above wind treshold',
                        align: 'left',
                        value: 'name'
                    },
                    {
                        text: 'Min',
                        align: 'center',
                        value: 'min'
                    },
                    {
                        text: 'Max',
                        align: 'center',
                        value: 'max'
                    },
                    {
                        text: 'Average',
                        align: 'center',
                        value: 'avg'
                    },
                    {
                        text: 'Sum',
                        align: 'center',
                        value: 'sum'
                    },
                ],
                timezones: [{
                        name: '-11:00',
                        value: -660
                    },
                    {
                        name: '-10:00',
                        value: -600
                    },
                    {
                        name: '-09:00',
                        value: -540
                    },
                    {
                        name: '-08:00',
                        value: -480
                    },
                    {
                        name: '-07:00',
                        value: -420
                    },
                    {
                        name: '-06:00',
                        value: -360
                    },
                    {
                        name: '-05:00',
                        value: -300
                    },
                    {
                        name: '-04:00',
                        value: -240
                    },
                    {
                        name: '-03:00',
                        value: -180
                    },
                    {
                        name: '-02:00',
                        value: -120
                    },
                    {
                        name: '-01:00',
                        value: -60
                    },
                    {
                        name: 'UTC',
                        value: 0
                    },
                    {
                        name: '+01:00',
                        value: 60
                    },
                    {
                        name: '+02:00',
                        value: 120
                    },
                    {
                        name: '+03:00',
                        value: 180
                    },
                    {
                        name: '+04:00',
                        value: 240
                    },
                    {
                        name: '+05:00',
                        value: 300
                    },
                    {
                        name: '+06:00',
                        value: 360
                    },
                    {
                        name: '+07:00',
                        value: 420
                    },
                    {
                        name: '+08:00',
                        value: 480
                    },
                    {
                        name: '+09:00',
                        value: 540
                    },
                    {
                        name: '+10:00',
                        value: 600
                    },
                    {
                        name: '+11:00',
                        value: 660
                    },
                    {
                        name: '+12:00',
                        value: 720
                    },
                ],
                initialTimes: {
                    startDate: null,
                    startTime: null,
                    endDate: null,
                    endTime: null
                },
            };
        },
        mounted() {
            const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
            chart.dateFormatter.utc = true;
            chart.paddingRight = 20;
            chart.data = [];

            const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.tooltipDateFormat = 'dd.MM HH:mm';
            dateAxis.renderer.grid.template.location = 0;

            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.tooltip.disabled = true;
            valueAxis.renderer.labels.template.fill = am4core.color('#e59165');
            valueAxis.renderer.width = 30;
            valueAxis.min = 0;
            valueAxis.extraMax = 0.05;
            valueAxis.maxZoomFactor = 10;

            const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis2.tooltip.disabled = true;
            valueAxis2.renderer.grid.template.strokeDasharray = '2,3';
            valueAxis2.renderer.labels.template.fill = am4core.color('#dfcc64');
            valueAxis2.renderer.width = 30;
            valueAxis2.renderer.opposite = true;

            const series = chart.series.push(new am4charts.LineSeries());
            series.name = 'SOG';
            series.dataFields.dateX = 'time';
            series.dataFields.valueY = 'sog';
            series.tooltipText = '{name}: {valueY.value}kn';
            series.fill = am4core.color('#e59165');
            series.stroke = am4core.color('#e59165');
            series.strokeWidth = 2;
            series.segments.template.interactionsEnabled = true;

            const series2 = chart.series.push(new am4charts.LineSeries());
            series2.name = 'True wind';
            series2.dataFields.dateX = 'time';
            series2.dataFields.valueY = 'tws';
            series2.yAxis = valueAxis;
            series2.xAxis = dateAxis;
            series2.tooltipText = '{name}: {valueY.value}m/s {windKnots}kn {twd}°';
            series2.fill = am4core.color('#911eb4');
            series2.stroke = am4core.color('#911eb4');
            series2.strokeWidth = 1;
            // Add a bullet
            const windBullet2 = series2.bullets.push(new am4charts.Bullet());
            windBullet2.propertyFields.rotation = 'twd';
            const windArrow2 = windBullet2.createChild(am4core.Triangle);
            windArrow2.horizontalCenter = 'middle';
            windArrow2.verticalCenter = 'middle';
            windArrow2.stroke = am4core.color('#fff');
            windArrow2.direction = 'bottom';
            windArrow2.width = 8;
            windArrow2.height = 15;

            // Wind limitSeries line
            this.limitSeries = chart.series.push(new am4charts.LineSeries());
            this.limitSeries.dataFields.dateX = 'time';
            this.limitSeries.dataFields.valueY = 'value';
            this.limitSeries.fill = am4core.color('#d576a3');
            this.limitSeries.stroke = am4core.color('#d576a3');
            this.limitSeries.strokeDasharray = '6 3';
            this.limitSeries.strokeWidth = 2;

            chart.cursor = new am4charts.XYCursor();
            chart.cursor.xAxis = dateAxis;

            chart.legend = new am4charts.Legend();
            chart.legend.zIndex = 100;
            chart.legend.opacity = 0.8;
            chart.legend.useDefaultMarker = true;
            const markerTemplate = chart.legend.markers.template;
            markerTemplate.width = 12;
            markerTemplate.height = 12;
            chart.legend.itemContainers.template.paddingTop = 0;
            chart.legend.itemContainers.template.paddingBottom = 0;
            chart.legend.position = 'bottom';

            valueAxis2.renderer.grid.template.strokeOpacity = 0.07;
            dateAxis.renderer.grid.template.strokeOpacity = 0.07;
            valueAxis.renderer.grid.template.strokeOpacity = 0.07;

            this.chart = chart;

            // Track ship on map based on lineSeries hover
            series.segments.template.events.on('over', (ev) => {
                if (this.chartTrackType === 'lineSeries') {
                    const marker = ev.target.dataItem.component.tooltipDataItem.dataContext;
                    this.marker = this.trackShipOnMap(marker, false, null);
                }
            }, this);

            // Track ship on map based on cursor position
            series.tooltip.events.on('positionchanged', function(ev) {
                if (this.chartTrackType === 'cursor') {
                    const marker = ev.target.dataItem.dataContext;
                    this.marker = this.trackShipOnMap(marker, false, null);
                }
            }, this);

            // Zoom map to extent on chart zoomed data timeframe
            chart.cursor.events.on('zoomended', function(ev) {
                const range = ev.target.xRange;
                const axis = ev.target.chart.xAxes.getIndex(0);
                if (range && range.start && range.end) {
                    const start = axis.positionToDate(axis.toAxisPosition(range.start));
                    const end = axis.positionToDate(axis.toAxisPosition(range.end));
                    this.sliceData(start, end, this.chart.data);
                    // Update date & time pickers on zoom
                    this.weather.startDate = moment.utc(start).format('YYYY-MM-DD');
                    this.weather.startTime = moment.utc(start).format('HH:mm');
                    this.weather.endDate = moment.utc(end).format('YYYY-MM-DD');
                    this.weather.endTime = moment.utc(end).format('HH:mm');
                    this.getCPComplianceValues(this.weather);
                }
            }, this);

            // Reset data on zoomout button
            chart.zoomOutButton.events.on('hit', function() {
                this.resetZoomedData(this.chart.data);
                this.weather.startDate = this.initialTimes.startDate;
                this.weather.startTime = this.initialTimes.startTime;
                this.weather.endDate = this.initialTimes.endDate;
                this.weather.endTime = this.initialTimes.endTime;
                this.getCPComplianceValues(this.weather);
            }, this);

            // Preselect first ship and create compare array
            this.fetchShips().then((data) => {
                const shipId = localStorage.getItem('perfShipId') ? parseInt(localStorage.getItem('perfShipId')) : null;
                // Check if valid ship id
                let ship = data.find((o) => o.perfDb !== null && o.id === shipId);
                if (!ship) {
                    // If not select first ship
                    ship = data.find((o) => o.perfDb !== null);
                }
                this.weather.shipId = ship.id;
            });

            // Get last timezone from localstorage
            this.weather.timezone = localStorage.getItem('timezone') ? parseInt(localStorage.getItem('timezone')) : 0;

            this.weather.startDate = moment.utc().utcOffset(this.weather.timezone).subtract(1, 'days').format('YYYY-MM-DD');
            this.weather.startTime = '00:00';
            this.weather.endDate = moment.utc().utcOffset(this.weather.timezone).format('YYYY-MM-DD');
            this.weather.endTime = moment.utc().utcOffset(this.weather.timezone).format('HH:mm');
        },
        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose();
            }
        },
        computed: {
            url() {
                if (this.getDarkMode()) {
                    return `https://api.maptiler.com/maps/d0b6607a-df0c-4660-af62-030764e2efc4/{z}/{x}/{y}.png?key=${config.apiKey}`;
                }
                return `https://api.maptiler.com/maps/511be076-e981-4e63-8d86-7f23e4d3e7f5/{z}/{x}/{y}.png?key=${config.apiKey}`;
            },
            ships() {
                return this.getShips().filter((o) => o.perfDb);
            },
            firstDayOfWeek() {
                return this.getFirstDayOfWeek();
            },
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getDynamic: 'data/getDynamic',
                getShips: 'data/getShips',
                getAnalyticsData: 'data/getAnalyticsData',
                getFirstDayOfWeek: 'data/getFirstDayOfWeek',
            }),
            ...mapActions({
                fetchShips: 'data/fetchShips',
                fetchAnalyticsData: 'data/fetchAnalyticsData',
                fetchCPComplianceValues: 'data/fetchCPComplianceValues',
            }),
            callFetchWeatherData(weather) {
                if (!this.checkTimespan(weather, 14)) {
                    return;
                }
                this.ship = this.getShips().filter((o) => o.id === weather.shipId)[0];
                localStorage.setItem('perfShipId', this.ship.id);
                this.tooltip = this.ship && this.ship.name ? this.ship.name : '';
                this.initialTimes.startDate = weather.startDate;
                this.initialTimes.startTime = weather.startTime;
                this.initialTimes.endDate = weather.endDate;
                this.initialTimes.endTime = weather.endTime;
                this.fetchAnalyticsData(weather).then((data) => {
                    const coords = [];
                    for (const key in data) {
                        if (data[key].time) {
                            data[key].time = moment.utc(data[key].time).toDate();
                        }
                        if (data[key].longitude && data[key].latitude) {
                            coords.push([data[key].longitude, data[key].latitude]);
                        }
                        if (data[key].tws) {
                            data[key].windKnots = Math.round(data[key].tws * 1.94384 * 10) / 10;
                        }
                        if (data[key].windSpeedR) {
                            data[key].windKnotsR = Math.round(data[key].windSpeedR * 1.94384 * 10) / 10;
                        }
                    }

                    if (coords.length > 1) {
                        this.routeGeojson = lineString(coords, {
                            name: 'route'
                        });
                    }
                    if (this.chart) {
                        this.chart.data = data;
                        if (this.chartPan) {
                            this.bounds = L.latLngBounds(coords);
                        }
                        for (const d of data) {
                            if (d && d.latitude && d.longitude) {
                                this.marker = this.trackShipOnMap(d, false, null);
                                break;
                            }
                        }
                        this.setWindLimit();
                        setTimeout(() => {
                            if (this.chart.series.getIndex(3) && this.chart.series.getIndex(3).bullets.getIndex(0)) {
                                this.chart.series.getIndex(3).bullets.getIndex(0).children.getIndex(0).show();
                            }
                        }, 5000);
                    }
                });
                this.getCPComplianceValues(this.weather);
            },

            trackShipOnMap(marker) {
                if (marker && marker.latitude && marker.longitude) {
                    marker.latLng = L.latLng(marker.latitude, marker.longitude);
                    if (this.chartPan) {
                        this.center = L.latLng(marker.latitude, marker.longitude);
                    }

                    marker.rotation = shipHelpers.headingOrCog(marker.heading, marker.cog);
                    if (!this.ship) {
                        this.ship = this.getShips().filter((o) => o.id == marker.shipId)[0];
                    }
                    const prefix = this.ship.external === 1 ? 'ts' : 'os';
                    const icon = shipHelpers.shipIcon(marker.navstat, prefix, 222222222, null, marker.sog);
                    marker.icon = (
                        L.icon({
                            iconUrl: require(`@/assets/img/ships/${icon}`),
                            iconSize: [11, 30], // size of the icon
                            iconAnchor: [5.5, 15], // point of the icon which will correspond to marker's location
                        })
                    );
                    if (this.ship) {
                        marker.tooltip = this.ship.name;
                        marker.a = this.ship.a;
                        marker.b = this.ship.b;
                        marker.c = this.ship.c;
                        marker.d = this.ship.d;
                        marker.shipname = this.ship.shortName;
                    }
                    marker.options = {};
                    marker.options.rotationOrigin = 'center center';
                    marker.shipGeojson = shipHelpers.shipGeojson(marker);
                    marker.circles = [];
                    const radiuses = [926, 1852, 2778];
                    for (const radius of radiuses) {
                        marker.circles.push({
                            center: L.latLng(marker.latitude, marker.longitude),
                            radius,
                            color: 'rgba(150, 150, 150, 0.3)',
                            weight: 0.5
                        });
                    }
                    marker.predictor = null;
                    const sog = marker.sog !== undefined ? marker.sog : null;
                    const arrow = shipHelpers.vectorSvg(sog, 'white');
                    if (arrow) {
                        marker.predictor = {
                            latLng: L.latLng(marker.latitude, marker.longitude),
                            direction: shipHelpers.headingOrCog(marker.heading, marker.cog, true),
                            icon: L.icon({
                                iconUrl: arrow.image,
                                iconSize: [8, arrow.height],
                                iconAnchor: [4, arrow.height],
                            }),
                            rotationOrigin: 'center bottom'
                        };
                    }

                    marker.popup = `<b>${this.ship ? this.ship.name : ''}</b><br/>
                        SOG: ${Math.round(sog * 10) / 10} kn<br/>
                        Draught: ${Math.round(marker.draught * 10) / 10} m<br/>
                        Status: <span class="${shipHelpers.getNavstat(marker.navstat).color}--text">${shipHelpers.getNavstat(marker.navstat).status}</span><br/>
                        Last seen: ${moment.utc(marker.time).format('D.M HH:mm')}<br/>`;

                    return marker;
                }
            },
            random() {
                return Math.random();
            },
            getNavstat(navstat) {
                return shipHelpers.getNavstat(navstat);
            },
            sliceData(start, end, data) {
                const zoomedData = [];
                const coordsLatLon = [];
                const coordsLonLat = [];
                this.routeGeojsons = [];
                for (const key in data) {
                    if (data.hasOwnProperty(key) && data[key].time) {
                        if (Math.round(moment.utc(data[key].time).unix() / 600) >= Math.round(moment.utc(start).unix() / 600)) {
                            zoomedData.push(data[key]);
                            if (data[key].latitude && data[key].longitude) {
                                coordsLonLat.push([data[key].longitude, data[key].latitude]);
                            }
                            if (Math.round(moment.utc(data[key].time).unix() / 600) >= Math.round(moment.utc(end).unix() / 600)) {
                                break;
                            }
                        }
                    }
                    if (data[key].latitude && data[key].longitude) {
                        coordsLatLon.push([data[key].latitude, data[key].longitude]);
                    }
                }
                // Set bound based on sliced data
                if (coordsLatLon && coordsLatLon.length > 0) {
                    if (this.chartPan && coordsLonLat.length > 1) {
                        this.bounds = L.latLngBounds(coordsLatLon);
                    }
                }
                // Show sliced track on map
                if (coordsLonLat.length > 1) {
                    this.routeGeojson = lineString(coordsLonLat, {
                        name: 'route'
                    });
                }
                return zoomedData;
            },
            resetZoomedData(data) {
                const coordsLatLon = [];
                const coordsLonLat = [];
                this.routeGeojsons = [];
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (data[key].time && data[key].latitude && data[key].longitude) {
                            coordsLonLat.push([data[key].longitude, data[key].latitude]);
                        }
                        if (data[key].latitude && data[key].longitude) {
                            coordsLatLon.push([data[key].latitude, data[key].longitude]);
                        }
                    }
                }

                // Set bound based on sliced data
                this.bounds = L.latLngBounds(coordsLatLon);
                // Show sliced track on map
                if (coordsLonLat.length > 1) {
                    this.routeGeojson = lineString(coordsLonLat, {
                        name: 'route'
                    });
                }
            },
            setWindLimit() {
                if (this.chart && this.chart.data && this.chart.data.length > 1) {
                    // Set wind treshold lineseries data
                    this.limitSeries.name = this.windLimits.filter((o) => o.value === this.windLimit)[0].name;
                    this.limitSeries.data = [{
                        time: this.chart.data[0].time,
                        value: this.windLimit
                    }, {
                        time: this.chart.data[this.chart.data.length - 1].time,
                        value: this.windLimit
                    }];
                    this.getCPComplianceValues(this.weather);
                }
            },
            checkTimespan(data, days) {
                const start = moment.utc(`${data.startDate}T${data.startTime}:00`).unix();
                const end = moment.utc(`${data.endDate}T${data.endTime}:00`).unix();
                if (end - start > days * 24 * 60 * 60) {
                    this.$store.dispatch('alert/error', `Maximum time period is ${days} days. Adjust your times accordingly.`, {
                        root: true
                    });
                    return false;
                }
                return true;
            },
            clearChart() {
                this.weather.startDate = moment.utc().utcOffset(this.weather.timezone).subtract(1, 'days').format('YYYY-MM-DD');
                this.weather.startTime = '00:00';
                this.weather.endDate = moment.utc().utcOffset(this.weather.timezone).format('YYYY-MM-DD');
                this.weather.endTime = moment.utc().utcOffset(this.weather.timezone).format('HH:mm');
                this.windLimit = 7.9;
                if (this.chart && this.chart.data) {
                    this.chart.data = [];
                }
            },
            getCPComplianceValues(weather) {
                weather.windLimit = this.windLimit;
                this.fetchCPComplianceValues(weather).then((data) => {
                    this.aggregateValues = data;
                    this.aggregateHeaders[4].text = `Sum${this.toHoursMinutes(this.aggregateValues.below[0].timespanSecs)}`;
                    this.aggregateHeaders2[4].text = `Sum${this.toHoursMinutes(this.aggregateValues.above[0].timespanSecs)}`;
                });
            },
            toHoursMinutes(timespanSecs) {
                if (timespanSecs) {
                    const hours = Math.floor(timespanSecs / 3600);
                    const mins = Math.round(timespanSecs % 3600 / 60);
                    return ` (${hours}h ${mins}m)`;
                }
                return '';
            },
            decimalToDmm(value, lng) {
                return tools.decimalToDmm(value, lng);
            },
        }
    };
</script>

<style>
    .v-window__container--is-active {
        overflow: visible;
    }
</style>
<style scoped>
    .chart,
    .map {
        width: 100%;
        height: 500px;
    }

    .control-btn {
        position: absolute;
    }
</style>