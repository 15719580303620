<template>
    <div>
        <v-card class="cardbglight">
            <v-data-table :headers="headers" :items="users" :search="search" :single-expand="true" :expanded.sync="expanded" item-key="email" :sort-by="sortBy"
            :sort-desc="sortDesc" :footer-props="{ showFirstLastPage: true}" mobile-breakpoint="100"
            class="elevation-1">
                <template #top>
                    <v-toolbar>
                        <v-toolbar-title>Users </v-toolbar-title>
                        <v-spacer />
                        <v-spacer />
                        <v-spacer />
                        <v-text-field v-model="search" append-icon="close" @click:append="search=''" prepend-icon="search" label="Search" single-line
                            hide-details /> 
                    </v-toolbar>
                </template>
                <template #item="{item}">
                    <tr @click="expandPanel(item)">
                        <td>{{ item.name }}</td>
                        <td>{{ item.email }}</td>
                        <td class="d-none d-sm-table-cell">{{ showUserLevel(item.level) }}</td>
                        <td class="d-none d-sm-table-cell">{{ item.loginTime }}</td>
                        <td class="d-none d-sm-table-cell">{{ item.author }}</td>
                        <td class="d-none d-sm-table-cell">{{ formatDate(item.updated) }}</td>
                    </tr>
                </template>
                <template #expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-0">    
                        <v-card class="cardbg primarytext--text">
                            <v-form v-model="saveValid" lazy-validation>
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex px-4 xs12 sm6 md3>
                                            <v-text-field v-model="item.name" :rules="nameRules" required prepend-icon="person" label="Name" placeholder="John Doe"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="item.emailTmp" required :rules="emailRules" prepend-icon="alternate_email" label="Email" placeholder="john.doe@example.com"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md3>
                                            <v-text-field v-model="item.password" prepend-icon="security" label="Password" :append-icon="show1 ? 'visibility_off' : 'visibility'"
                                                :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                                                hint="At least 8 characters"
                                                counter @click:append="show1 = !show1"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md2>
                                            <v-select v-model="item.level" :disabled="userLevel < 2" required :items="levels" prepend-icon="filter_list" item-text="text" item-value="value" label="User level"></v-select>
                                        </v-flex>
                                    </v-layout>
                                        <v-card-actions>
                                        <v-spacer />
                                        <v-btn color="error" @click="callDeleteUser(item)" :disabled="userLevel < 2">
Delete
                                            <v-icon right dark>delete_forever</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                        <v-btn color="primary" @click="callSaveUser(item)" :disabled="!saveValid || (item.id !== userId && userLevel < 2 ) || (item.password !== undefined && item.password !== '' && item.password.length < 8)">
Save
                                            <v-icon right dark>check</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-container>
                            </v-form>
                        </v-card>
                    </td> 
                </template>
                <v-alert slot="no-results" :value="true" color="error" icon="warning">
                    Your search for "{{ search }}" found no results.
                </v-alert>
            </v-data-table>
            <v-expansion-panels :disabled="userLevel < 2" v-model="newPanel">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="teal">add_circle</v-icon> <span class="text-body-1 ml-2">Add new user</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <v-card class="cardbg">
                            <v-form v-model="newValid" lazy-validation>
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex px-4 xs12 sm6 md3>
                                            <v-text-field v-model="newUser.name" :rules="nameRules" prepend-icon="person" label="Name" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="newUser.email" :rules="emailRules" prepend-icon="alternate_email" label="Email" required></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md3>
                                            <v-text-field v-model="newUser.password" prepend-icon="security" label="Password" required :append-icon="show1 ? 'visibility_off' : 'visibility'"
                                            :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                                            hint="At least 8 characters"
                                            counter @click:append="show1 = !show1"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md2>
                                            <v-select v-model="newUser.level" :items="levels" prepend-icon="filter_list" item-text="text" item-value="value" label="User level" required></v-select>
                                        </v-flex>
                                    </v-layout>
                                        <v-card-actions>
                                        <v-spacer />
                                        <v-btn text @click="cancel()">
Cancel
                                            <!-- <v-icon right dark>cancel</v-icon> -->
                                        </v-btn>
                                        <v-spacer />
                                        <v-btn color="primary" @click="callAddUser(newUser)" :disabled="!newValid || !newUser.name || newUser.level === null || (newUser.password && newUser.password.length < 8)">
Add
                                            <v-icon right dark>add_circle</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-container>
                            </v-form>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="primary">help</v-icon>
                            <span class="text-body-1 ml-2">What does this page show</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <br />
                        <p>User management</p>
                            <p>
On this page you can add and edit Users. The system has three different User levels:<br />
                            1. A Viewer is a User who can only view data, but has no access to changing any settings or upload routes. A Viewer User can only change their own password.<br />
                            2. An Editor is a User who can view all data and who can also change BOQA settings and manage routes as well as change their own password.<br />
                            3. An Admin user is a User who can view all data, change settings, manage routes and add/edit/delete Users.
</p>

                            <p>
Email is the unique User ID<br />
                            The system uses the corporate email address as a unique User ID. This means that each User needs to have a unique email address.
</p>

                            <p>
Changing passwords<br />
                            Each User can change their own password. An Admin User can also change other Users passwords.
</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
        <StatusDialog />
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    import moment from 'moment';
    import StatusDialog from '../components/StatusDialog.vue';
    import { eventBus } from '../main';

    export default {
        name: 'Users',
        components: {
            StatusDialog
        },
        data: () => ({
            search: '',
            sortBy: 'name',
            sortDesc: false,
            headers: [{
                    text: 'Name',
                    value: 'name'
                },
                {
                    text: 'Email',
                    value: 'email'
                },
                {
                    text: 'User level',
                    value: 'level'
                },
                {
                    text: 'Last login',
                    value: 'loginTime'
                },
                {
                    text: 'Author',
                    value: 'author'
                },
                {
                    text: 'Updated',
                    value: 'updated'
                },
            ],
            levels: [
                {
                    text: 'Viewer',
                    value: 0
                },
                {
                    text: 'Editor',
                    value: 1
                },
                {
                    text: 'Administrator',
                    value: 2
                }
            ],
            expanded: [],
            newUser: {
                name: '', email: '', password: '', level: 0 
            },
            newPanel: [],
            deleteUserId: null,
            saveValid: true,
            newValid: true,
            show1: false,
            rules: {
                required: (value) => !!value || 'Required.',
                min: (v) => (v ? v.length >= 8 || 'Min 8 characters' : ''),
            },
            nameRules: [
                (v) => !!v || 'Name is required',
            ],
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
        }),
        created() {
            this.fetchUsers();
        },
        computed: {
            users() {
                return this.getUsers().map((o) => {
                    o.emailTmp = o.email;
                    return o;
                });
            },
            userLevel() {
                return this.getUser().user.level;
            },
            userId() {
                return this.getUser().user.id;
            }
        },
        mounted() {
            eventBus.$on('deleteUser', () => {
                if (this.deleteUserId) {
                    this.deleteUser(this.deleteUserId);
                    this.deleteUserId = null;
                }
            });
        },
        beforeDestroy() {
            eventBus.$off('deleteUser');
        },
        methods: {
            ...mapActions({
                fetchUsers: 'users/fetchUsers',
                saveUser: 'users/saveUser',
                deleteUser: 'users/deleteUser',
                addUser: 'users/addUser',
            }),
            ...mapGetters({
                getUsers: 'users/getUsers',
                getUser: 'authentication/getUser'
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
            }),
            expandPanel(item) {
                this.expanded = item === this.expanded[0] ? [] : [item];
            },
            callDeleteUser(user) {
                this.deleteUserId = user.id;
                this.alertConfirm(
                    { message: `Are you sure you want to delete permanently ${user.name}?`, emit: 'deleteUser' }
                );
            },
            callSaveUser(user) {
                user.email = user.emailTmp;
                this.saveUser(user);
            },
            callAddUser(user) {
                this.addUser(user);
                this.cancel();
            },
            cancel() {
                this.newPanel = [];
                this.newUser = {
                    name: '', email: '', password: '', level: 0 
                };
            },
            showUserLevel(value) {
                switch (value) {
                    case 0:
                        return 'Viewer';
                    case 1:
                        return 'Editor';
                    case 2:
                        return 'Administrator';
                    default:
                        return 'Not set';
                }
            },
            formatDate(date) {
                if (date) {
                    return moment.utc(date).local().format('D.M.Y HH:mm');
                } 
                    return '';
            }
        }
    };
</script>

<style>
    .v-list--dense .v-list__tile:not(.v-list__tile--avatar) {
        height: 100%;
    }
</style>
<style scoped>
    .mypanel .v-expansion-panel__body {
        display: block !important; /* Dirty fix for expansion panel content */
    }
    tr.expand td {
        padding: 0 !important;
    }

    tr.expand .expansion-panel {
        box-shadow: none;
    }

    tr.expand .expansion-panel li {
        border: none;
    }
</style>
