<template>
    <span style="white-space:nowrap;margin-left:-5px"> {{ data.Custom }}</span>
</template>

<script>
    export default {
        data() {
            return {
                data: {},
            };
        },
    };
</script>
