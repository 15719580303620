<template>
    <div id="child" style="margin-top:7px">
        <ejs-dropdownlist name="podDropdown" width="150px" ref="podInstance" id="podDropdown" :showClearButton="true" v-model="data.portIdDisch"
            :select="doCustomAction.bind(this)" :placeholder="placeholder" :dataSource="items"
            :fields="fields">
        </ejs-dropdownlist>
    </div>
</template>

<script>
    import {
        store
    } from '../../store';
    import {
        eventBus
    } from '../../main';

    export default {
        store,
        data() {
            return {
                data: {},
                items: [],
                placeholder: 'Select',
                fields: {
                    text: 'name',
                    value: 'portId',
                },
            };
        },
        mounted() {
            this.items = store.state.data.portCalls.filter((o) => o.type === 3 || o.type === 7);
            if (this.data && this.data.portIdDisch) {
                const portCall = this.items.find((o) => o.portId && this.data.portIdDisch && o.portId === this.data.portIdDisch);
                this.placeholder = portCall ? portCall.name : '';
            }
        },
        methods: {
            doCustomAction(args) {
                if (args && args.itemData) {
                    eventBus.$emit('newPod', args.itemData);
                }
            },
           
        }
    };
</script>

<style>
</style>
