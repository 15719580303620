<template>
    <v-layout style="border-bottom:1px #aaa solid; padding-bottom:15px" wrap>
        <v-flex xs12 md6 lg6>
            <v-card>
                <v-card-title>{{ ship.name }}</v-card-title>
                <!-- currently active route -->
                <v-expansion-panels v-model="panel.deactivate">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div>
                                <v-icon small color="teal">timeline</v-icon>
                                <span v-if="ship.routeName" class="text-body-1 ml-2">Currently active route: {{ ship.routeName }} ({{ ship.routeTime | dateUtc }})
                                </span>
                                <span v-if="!ship.routeName" class="text-body-1 ml-2 error--text">No active route </span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="cardbg">
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn @click="callDeactivateRoute(ship.id)" :disabled="!ship.routeId || userLevel < 1" color="error">
Deactivate
                                        <v-icon right>mdi-map-marker-off</v-icon>
                                    </v-btn>
                                    <v-spacer />
                                </v-card-actions>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-divider />
                <!-- activate route -->
                <v-expansion-panels v-model="panel.activate">
                    <v-expansion-panel>
                        <v-expansion-panel-header @click="updateRoutes(ship.id, 'activate')">
                            <div>
                                <v-icon small color="teal">touch_app</v-icon>
                                <span class="text-body-1 ml-2">Activate/display a route</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="cardbg">
                                 <v-card-text>
                                    <v-autocomplete v-model="routeSelect" :items="getRoutes()" @change="displayRoute(routeSelect)" item-text="[title]" item-value="id" hint="Closest route is highest in the list"
                                        placeholder="Search routes" persistent-hint
>
                                        <v-slide-x-reverse-transition slot="append-outer" mode="out-in" />
                                    </v-autocomplete>
                                </v-card-text>
                                <v-divider />
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn text @click="closePanel('activate')">Cancel</v-btn>
                                    <v-spacer />
                                    <v-btn color="primary" :disabled="routeSelect === null || userLevel < 1" @click="callSetActiveRoute({shipId: ship.id, routeId: routeSelect})">
Activate
                                        <v-icon right class="hidden-xs-only" dark>check</v-icon>
                                    </v-btn>
                                    <v-spacer />
                                </v-card-actions>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-divider />
                <!-- upload route -->
                 <v-expansion-panels v-model="panel.upload">
                    <v-expansion-panel>
                        <v-expansion-panel-header @click="updateRoutes(ship.id, 'activate')">
                            <div>
                                <v-icon small color="teal">cloud_upload</v-icon>
                                <span class="text-body-1 ml-2">Upload new route</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="cardbg">
                                 <v-card-text>
                                <v-text-field label="Select ECDIS route file" @click="pickFile()" v-model="fileName" prepend-icon="attach_file"></v-text-field>
                                <input type="file" accept="text/plain, text/xml, .csv, .dat, .rtz," style="display: none" ref="file" @change="onFilePicked">
                                <v-checkbox v-model="activateRouteOnUpload" label="Activate route"></v-checkbox>
                            </v-card-text>
                            <v-divider />
                            <v-card-actions>
                                <v-spacer />
                                <v-btn text @click="closePanel('upload')">Cancel</v-btn>
                                <v-spacer />
                                <v-btn color="primary" :disabled="!file || userLevel < 1" @click="callUploadRoute({shipId: ship.id, file: file, active: activateRouteOnUpload})">
Upload
                                    <v-icon right class="hidden-xs-only" dark>cloud_upload</v-icon>
                                </v-btn>
                                <v-spacer />
                            </v-card-actions>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-divider />

                 <!-- Navtor routes -->
                <v-expansion-panels v-if="ship.navtorApi === 1" v-model="panel.navtor">
                    <v-expansion-panel>
                        <v-expansion-panel-header @click="callFetchNavtorRouteList(ship.id)">
                            <div>
                                <v-icon small color="teal">directions_boat</v-icon>
                                <span class="text-body-1 ml-2">Navtor route import</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="cardbg">
                                 <v-card-text>
                                    <v-autocomplete @change="pushNavtorSelected()" :loading="loadingNavtorRoutes" v-model="navtorRoute" :items="navtorRouteList" :item-text="navtorRouteText" 
                                        return-object hint="Latest updated route is highest in the list"
                                        placeholder="Search routes" persistent-hint
>
                                        <v-slide-x-reverse-transition slot="append-outer" mode="out-in" />
                                    </v-autocomplete>
                                </v-card-text>
                                 <v-divider />
                                <v-list v-if="navtorSelected.length !== 0">
                                    <v-list-item v-for="item in navtorSelected" :key="item.Id">
                                        <v-list-item-action>
                                            <v-icon small color="teal">timeline</v-icon>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.name + ' (' + formatDate(item.updateTime) + ')'"></v-list-item-title>
                                        </v-list-item-content>
</v-list-item>
                                </v-list>
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn text @click="closePanel('navtor');">Cancel</v-btn>
                                    <v-spacer />
                                    <v-btn color="primary" :disabled="userLevel < 1 || navtorSelected.length === 0 || loadingNavtorRoutes" :loading="loadingNavtorRoutes" @click="callFetchNavtorRoutes(ship.id)">
Download routes
                                        <v-icon right class="hidden-xs-only" dark>check</v-icon>
                                    </v-btn>
                                    <v-spacer />
                                </v-card-actions>
                                <v-divider />
                                <v-expansion-panels>
                                  <v-expansion-panel class="cardbg">
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-icon medium color="secondary">help</v-icon> <span class="ml-2">How does Navtor route import work?</span>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p>
This function is used for downloading route files from Navtor's Navtracker service and requires that the ship's Navtracker user account credentials are added to and up-to-date on the Ship settings page. The function supports currently following file formats:
                                        <ul>
                                        <li>ECDIS standard .rtz format (IEC 61174)</li>
                                        <li>Navtor internal .route format</li>
                                        <li>Navtor internal .csv format</li>
                                        <li>JRC ECDIS .csv format</li>
                                        </ul>
                                        You can dowload multiple routes by first clicking each selected route in the route drop down and then clicking Download routes. This will download the route files from the Navtor Navtracker service, decode them and store them in the BOQA database. You can then use the routes normally in BOQA (activate / create BVS file / delete).
</p>

                                        <p>
There are currently two methods for importing routes from an ECDIS to Navtor NavTracker:
                                        <ul>
                                        <li>The ECDIS routes can be manually imported from an ECDIS to the Navtor NavTracker utility. In such case the routes are in the original format as exported from ECDIS eg .rtz. This process can be done both onboard by the Crew or ashore by the Office.</li>
                                        <li>The other method is to import the route to the Navtor NavStation - Navtor's onboard Planning Station - which communicates locally with the Navtor NavBox onboard the ship. The NavBox will then synchronise the data ashore. The routes that are imported to the NavStation are automatically converted to Navtor's .route format when synchronised ashore.</li>
                                        </ul>
                                        </p>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-divider />

                <!-- BVS -->
                 <v-expansion-panels v-model="panel.bvs">
                    <v-expansion-panel>
                        <v-expansion-panel-header @click="updateRoutes(ship.id, 'bvs')">
                            <div>
                                <span style="text-shadow: 1px 1px #d0d0d0; font-size:11px" class="blue-grey--text">BVS</span>
                                <span class="text-body-1 ml-1">Create BVS file</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="cardbg">
                                <v-card-text>
                                <v-autocomplete @change="pushBvsSelected(ship)" v-model="bvsSelect" :items="getRoutes()" item-text="[title]" return-object hint="Closest route is highest in the list"
                                    placeholder="Select first route" persistent-hint
>
                                    <v-slide-x-reverse-transition slot="append-outer" mode="out-in" />
                                </v-autocomplete>
                            </v-card-text>
                            <v-divider />
                            <v-list v-if="bvsSelected.length !== 0">
                                <v-list-item v-for="item in bvsSelected" :key="item.id">
                                    <v-list-item-action>
                                        <v-icon small color="teal">timeline</v-icon>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.title"></v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-avatar>
                                        <img :src="item.avatar">
                                    </v-list-item-avatar>
                                </v-list-item>
                            </v-list>

                            <v-divider />
                            <v-card-actions>
                                <v-spacer />
                                <v-btn text @click="closePanel('bvs')">Cancel</v-btn>
                                <v-spacer />
                                <v-btn color="primary" :disabled="bvsSelected.length === 0 || userLevel < 1" @click="createBvs(ship)">
Create
                                    <v-icon right class="hidden-xs-only" dark>save_alt</v-icon>
                                </v-btn>
                                <v-spacer />
                            </v-card-actions>
                            <v-divider />
                             <v-expansion-panels>
                                  <v-expansion-panel class="cardbg">
                                    <v-expansion-panel-header>
                                         <div>
                                            <v-icon medium color="secondary">help</v-icon> <span class="ml-2">How does the BVS file creation work?</span>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p>
This function generates a .bvs file from the list of routes you select. You can search all
                                    the routes uploaded for this vessel. The first route is selected by typing in the
                                    autocomplete field and subsequent routes can be added by selecting the from the dropdown
                                    list.
</p>
                                <p>
The routes in the dropdown are sorted so that the closest route to the end of the previous
                                    route is highest in the list.
</p>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-divider />
                
                 <v-expansion-panels v-model="panel.delete">
                    <v-expansion-panel>
                        <v-expansion-panel-header @click="updateRoutes(ship.id, 'delete')">
                            <div>
                                <v-icon small color="error">delete</v-icon>
                                <span class="text-body-1 ml-2">Delete routes</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="cardbg">
                                 <v-card-title>
                                <v-text-field v-model="filter" append-icon="search" label="Filter" single-line hide-details />
                            </v-card-title>
                            <v-data-table :headers="headers" :items="getRoutes()" :search="filter" v-model="deleteSelected" item-key="id" item-text="[name, time]"
                                item-value="id" show-select class="elevation-1"
>
                                <template class="cardbg" slot="headerCell" slot-scope="props">
                                    <v-tooltip bottom>
                                        <span slot="activator">
                                            {{ props.header.text }}
                                        </span>
                                        <span>
                                            {{ props.header.text }}
                                        </span>
                                    </v-tooltip>
                                </template>
                                <template slot="items" slot-scope="props">
                                    <td>
                                        <v-checkbox v-model="props.selected" primary color="teal" hide-details />
                                    </td>
                                    <td>{{ props.item.name }}</td>
                                    <td class="text-xs">{{ props.item.time | dateUtc }}</td>
                                </template>
                            </v-data-table>
                            <v-card-actions>
                                <v-spacer />
                                <v-btn text @click="closePanel('delete')">Cancel</v-btn>
                                <v-spacer />
                                <v-btn color="error" :disabled="deleteSelected.length === 0 || userLevel < 1" @click="callDeleteRoutes(ship)">
                                    Delete
                                    <v-icon right class="hidden-xs-only" dark>delete</v-icon>
                                </v-btn>
                                <v-spacer />
                            </v-card-actions>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-divider />
            </v-card>
        </v-flex>
        <!-- route map -->
        <v-flex sm12 md6 lg6 hidden-xs-only class="pl-4">
            <v-card>
                <v-card-title><small>{{ ship.routeName ? ship.routeName : 'No route' }}</small> <br /></v-card-title>
                <v-responsive>
                    <v-map :style="mapHeight(ship)" :zoom="zoom" :center="marker.center" :bounds="bounds">
                        <v-tilelayer :url="url" :attribution="attribution" :options="{tileSize: 512, crossOrigin: true, zoomOffset: -1}"></v-tilelayer>
                        <v-tilelayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png" :attribution="seaMapAttribution"></v-tilelayer>
                        <v-geojson v-if="ship.routeGeojson" :geojson="ship.routeGeojson" :options="route.options"></v-geojson>
                        <v-geojson v-if="ship.corridorGeojson" :geojson="ship.corridorGeojson" :options="corridor.options"></v-geojson>
                        <v-geojson v-if="ship.displayRouteGeojson" :geojson="ship.displayRouteGeojson" :options="displayRouteOptions"></v-geojson>
                        <v-rotated-marker v-if="marker.latLng"
                            :lat-lng="marker.latLng" 
                            :icon="marker.shipIcon" 
                            :rotationAngle="marker.shipHeading"
>
                            <l-tooltip :content="marker.tooltip" :options="tooltipOptions" />
                        </v-rotated-marker>
                        <v-rotated-marker v-if="marker.forecast" 
                            :lat-lng="marker.forecast.latLng" 
                            :icon="marker.forecast.icon" 
                            :rotationAngle="marker.forecast.direction"
>
                            <l-tooltip :content="marker.forecast.tooltip" :options="tooltipOptions" />
                        </v-rotated-marker>
                    </v-map>
                </v-responsive>
            </v-card>
        </v-flex>
</v-layout>
</template>

<script>
    import moment from 'moment';
    import L from 'leaflet';
    import {
        LMap, LTileLayer, LGeoJson, LTooltip 
    } from 'vue2-leaflet';
    import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker';
    import {
        mapGetters,
        mapActions,
        mapMutations,
    } from 'vuex';
    // import { eventBus } from '../main';
    import { bvs, shipHelpers, config } from '../helpers';
   
   delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    export default {
        name: 'EcdisRouteItem',
        components: {
            'v-map': LMap,
            'v-tilelayer': LTileLayer,
            'v-geojson': LGeoJson,
            LTooltip,
            'v-rotated-marker': Vue2LeafletRotatedMarker
        },
        props: {
            ship: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                routeSelect: null,
                panel: {
                    deactivate: null,
                    active: null,
                    upload: null,
                    delete: null,
                    bvs: null
                },
                userLevel: null,
                fileName: null,
                file: null,
                activateRouteOnUpload: false,
                deleteSelected: [],
                bvsSelect: null,
                bvsSelected: [],
                navtorRoute: null,
                navtorSelected: [],
                navtorRouteList: [],
                loadingNavtorRoutes: false,
                filter: '',
                headers: [{
                        text: 'Route',
                        align: 'left',
                        value: 'name'
                    },
                    {
                        text: 'Uploaded',
                        value: 'time'
                    }
                ],
                // Leaflet specific properties
                zoom: 3,
                center: L.latLng(0, 0),
                attribution: '&copy; <a href="http://www.nauticai.com">Nauticai</a>',
                seaMapAttribution: '&copy; <a href="http://www.openseamap.org/">OpenSeaMap</a>',
                route: {
                    geojson: null,
                    options: {
                        style: {
                            color: '#1E88E5',
                            weight: 1,
                            opacity: 1
                        }
                    }
                },
                corridor: {
                    geojson: null,
                    options: {
                        style: {
                            color: '#4CAF50',
                            weight: 0.1,
                            opacity: 0.68
                        }
                    }
                },
                displayRouteOptions: {
                    style: {
                        color: '#e67e22',
                        weight: 1,
                        opacity: 1
                    }
                },
                bounds: null,
                tooltipOptions: {
                    permanent: true, 
                    direction: 'top', 
                    offset: L.point(0, -10), 
                    opactity: 0.8,
                }
            };
        },
        computed: {
            dialogMessage() {
                return this.$store.state.alert.message;
            },
            dialogType() {
                return this.$store.state.alert.type;
            },
            url() {
                if (this.getDarkMode()) {
                    return `https://api.maptiler.com/maps/d0b6607a-df0c-4660-af62-030764e2efc4/{z}/{x}/{y}.png?key=${config.apiKey}`;
                } 
                    return `https://api.maptiler.com/maps/511be076-e981-4e63-8d86-7f23e4d3e7f5/{z}/{x}/{y}.png?key=${config.apiKey}`;
            },
            marker() {
                let dynamic = {};
                try {
                    dynamic = JSON.parse(JSON.stringify(this.getDynamic()));
                } catch (e) {
                    console.warn('Dynamic data not available.');
                }
                let latLng = L.latLng(0, 0);
                let center = L.latLng(0, 0);
                let shipIcon = null;
                let shipHeading = null;
                let tooltip = null;
                if (dynamic && dynamic.ais) {
                    const ship = dynamic.ais.find((o) => o.mmsi === this.ship.mmsi);
                    if (ship) {
                        latLng = L.latLng(ship.latitude, ship.longitude);
                        center = L.latLng(ship.latitude, ship.longitude);
                        const icon = shipHelpers.shipIcon(ship.navstat, 'os', ship.mmsi, null, ship.sog);
                        shipIcon = L.icon({
                            iconUrl: require(`@/assets/img/ships/${icon}`),
                            iconSize: [11, 30], // size of the icon
                            iconAnchor: [5.5, 15], // point of the icon which will correspond to marker's location
                        });
                        shipHeading = shipHelpers.headingOrCog(ship.heading, ship.cog);
                        tooltip = ship.name;
                    } else {
                        shipIcon = L.icon({
                            iconUrl: require(`@/assets/img/ships/os_white.png`),
                            iconSize: [11, 30], // size of the icon
                            iconAnchor: [5.5, 15], // point of the icon which will correspond to marker's location
                        });
                    }
                }
                return { 
                    latLng, 
                    shipIcon, 
                    shipHeading,
                    tooltip,
                    center,
                };
            },
        },
        mounted() {
            this.userLevel = this.getUser().user.level;
        },
        created() {
            this.setBounds();
        },
        methods: {
            ...mapGetters({
                getRoutes: 'data/getRoutes',
                getWaypoints: 'data/getWaypoints',
                getDynamic: 'data/getDynamic',
                getDarkMode: 'users/getDarkMode',
                getUser: 'authentication/getUser'
            }),
            ...mapActions({
                fetchRoutes: 'data/fetchRoutes',
                fetchWaypoints: 'data/fetchWaypoints',
                fetchNavtorRouteList: 'data/fetchNavtorRouteList',
                fetchNavtorRoutes: 'data/fetchNavtorRoutes',
                setActiveRoute: 'data/setActiveRoute',
                deactivateRoute: 'data/deactivateRoute',
                uploadRoute: 'data/uploadRoute',
                deleteRoutes: 'data/deleteRoutes',
            }),
             ...mapMutations({
                alertError: 'alert/error',
            }),
            closePanel(panel) {
                this.ship.displayRouteGeojson = null;
                switch (panel) {
                    case 'activate':
                        this.panel.activate = null;
                        this.routeSelect = null;
                        break;
                    case 'upload':
                        this.panel.upload = null;
                        this.fileName = null;
                        this.file = null;
                        break;
                    case 'deactivate':
                        this.panel.deactivate = null;
                        break;
                    case 'delete':
                        this.panel.delete = null;
                        this.deleteSelected = [];
                        break;
                    case 'bvs':
                        this.panel.bvs = null;
                        this.routeSelect = null;
                        this.bvsSelect = null;
                        this.bvsSelected = [];
                        break;
                    case 'navtor':
                        this.panel.navtor = null;
                        this.navtorRoute = null;
                        this.navtorSelected = [];
                        break;
                    default:
                        break;
                }
            },
            pickFile(shipId) {
                this.activeShipId = shipId;
                this.$refs.file.click();
            },
            onFilePicked(e) {
                const fileData = this.$refs.file.files[0];
                if (fileData !== undefined) {
                    this.fileName = fileData.name;
                } else {
                    this.fileName = null;
                }
                console.log(this.fileName);
                const input = e.target;
                const reader = new FileReader();
                reader.onload = () => {
                    this.file = reader.result;
                };
                reader.readAsText(input.files[0]);
            },
            updateRoutes(shipId, panel) {
                this.ship.displayRouteGeojson = null;
                if ((panel === 'activate' && this.panel.activate !== 0) 
                || (panel === 'delete' && this.panel.delete !== 0) 
                || (panel === 'bvs' && this.panel.bvs !== 0)) {
                    this.fetchRoutes({ shipId, routeId: null });
                }
            },
            callDeactivateRoute(data) {
                this.deactivateRoute(data);
                this.closePanel('deactivate');
            },
            callSetActiveRoute(data) {
                this.ship.displayRouteGeojson = null;
                this.setActiveRoute(data);
                this.closePanel('activate');
                setTimeout(() => {
                    this.setBounds();
                }, 1000);
            },
            callUploadRoute(data) {
                this.uploadRoute(data);
                this.closePanel('upload');
                if (data.active) {
                    setTimeout(() => {
                        this.setBounds();
                    }, 1000);
                }
            },
            callDeleteRoutes(ship) {
                if (this.deleteSelected.filter((e) => e.id === ship.routeId).length > 0) {
                    this.alertError(
                        `${ship.routeName} (${this.$options.filters.dateUtc(ship.routeTime)}) is active. Deactivate route first.`
                    );
                } else {
                    this.deleteRoutes({
                        shipId: ship.id,
                        data: this.deleteSelected
                    });
                    this.closePanel('delete');
                }
            },
            callFetchNavtorRouteList(shipId) {
                if (this.navtorRouteList.length === 0) {
                    this.loadingNavtorRoutes = true;
                    this.fetchNavtorRouteList(shipId).then((data) => {
                        this.navtorRouteList = data.sort((a, b) => (a.updateTime < b.updateTime ? 1 : b.updateTime < a.updateTime ? -1 : 0));
                        console.log(this.navtorRouteList);
                        this.loadingNavtorRoutes = false;
                    }, (error) => {
                        console.error(error);
                        this.loadingNavtorRoutes = false;
                    }).finally(() => this.loadingNavtorRoutes = false);
                }
            },
            callFetchNavtorRoutes(shipId) {
                if (this.navtorSelected.length > 0) {
                    this.loadingNavtorRoutes = true;
                    this.fetchNavtorRoutes({ shipId, routeList: this.navtorSelected }).then(() => {
                        this.navtorRoute = null;
                        this.navtorSelected = [];
                        this.loadingNavtorRoutes = false;
                    }, (error) => {
                        console.error(error);
                        this.loadingNavtorRoutes = false;
                    }).finally(() => this.loadingNavtorRoutes = false);
                }
            },
            pushBvsSelected(ship) {
                this.bvsSelected.push(this.bvsSelect);
                this.fetchRoutes({ shipId: ship.id, routeId: this.bvsSelect.id });
                this.bvsSelect = null;
            },
            pushNavtorSelected() {
                this.navtorSelected.push(this.navtorRoute);
                this.navtorRoute = null;
            },
            // panelEvent(panel) {
            //     eventBus.$emit('closeAllPanels', panel);
            //     switch (panel) {
            //         case 'activate':
            //             this.panel.activate = 0;
            //             break;
            //         case 'upload':
            //             this.panel.upload = 0;
            //             break;
            //         case 'deactivate':
            //             this.panel.deactivate = 0;
            //             break;
            //         case 'delete':
            //             this.panel.delete = 0;
            //             break;
            //         case 'bvs':
            //             this.panel.bvs = 0;
            //             break;
            //         default:
            //             break;
            //     }
            // },
            setBounds(type = null) {
                let routeGeojson = type === 'displayRoute' ? this.ship.displayRouteGeojson : this.ship.routeGeojson;
                if (routeGeojson) {
                    try {
                        routeGeojson = JSON.parse(JSON.stringify(routeGeojson));    
                        this.bounds = L.latLngBounds(routeGeojson.coordinates[0].map((coordinate) => coordinate.reverse()));
                    } catch (e) {
                        console.warn('Route geojson parse failed.');
                    }
                }
            },
            createBvs(ship) {
                const routeIds = this.bvsSelected.map((route) => route.id);
                this.fetchWaypoints({ routeIds }).then((data) => {
                    bvs(ship, data);
                    // this.closePanel('bvs');
                    // this.bvsSelected = [];
                    });
            },
            navtorRouteText(item) {
                return `${item.name} (${moment.utc(item.updateTime).local().format('DD.MM.Y HH:mm')})`;
            },
            formatDate(date) {
                return moment.utc(date).local().format('DD.MM.Y HH:mm');
            },
            mapHeight(ship){
                return ship.navtorApi === 1 ? 'height: 345px' : 'height: 285px';
            },
            displayRoute(routeId) {
                if (!isNaN(routeId)) {
                    const route = this.getRoutes().find((o) => o.id === routeId);
                    if (typeof route !== 'undefined') {
                        const geojson = {
                            type: 'MultiLineString',
                            coordinates: [route.waypoints.coordinate]
                        };
                        this.ship.displayRouteGeojson = geojson;
                        this.setBounds('displayRoute');
                    }
                } else {
                    this.ship.displayRouteGeojson = null;
                }
            }
        },
    };
</script>

<style scoped>

    .shipheader {
        padding: 10px 0px;
    }

    .headertext {
        margin: 16px;
    }

</style>
