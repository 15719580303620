<template>
    <div>{{ captionValue }}</div>
</template>

<script>
    const trafficAreas = [
        { value: '21', text: '21 France & UK' },
        { value: '35', text: '35 PRS pulp CE & WE' },
        { value: '36', text: '36 Mussalo pulp CE & WE' },
        { value: '43', text: '43 Rauma, Rostock' },
        { value: '54', text: '54 KTK, RAU, FRO, SDR, AMS' },
        { value: '64', text: '64 USA' },
        { value: '91', text: '91 Raw materials' },
        { value: '88', text: '88 Overseas china clay' },
        { value: '89', text: '89 Europe china clay' },
        { value: '95', text: '95 Other imports' },
        { value: '38', text: '38 External' },
    ];

    export default {
        data() {
            return {
                data: {
                },
            };
        },
        methods: {
            impExpCab(value) {
                switch (value) {
                    case 0:
                        return 'Import';
                    case 1:
                        return 'Export';   
                    case 2:
                        return 'Cabotage';   
                    case 3:
                        return 'Crosstrade';   
                
                    default:
                        return '';
                }
            }
        },
        computed: {
            captionValue(value) {
                let x = value.data.count;
                if (x > 1) {
                    x = `${value.data.count} items`;
                } else {
                    x = `${value.data.count} item`;
                }
            
                switch (value.data.field) { 
                    case 'resourceId': 
                        // return the value.data depends on the grouped column level 
                        if (value.data.items.level) { 
                            return (`${value.data.headerText}: ${value.data.items.records[0].resource} ${x}`); 
                        }  
                            return (`${value.data.headerText}: ${value.data.items[0].resource} ${x}`); 
                         
                    case 'projectTypeId': 
                        if (value.data.items.level) { 
                            return (`${value.data.headerText}: ${value.data.items.records[0].projectType} ${x}`); 
                        }  
                            return (`${value.data.headerText}: ${value.data.items[0].projectType} ${x}`); 
                         
                    case 'status': 
                        if (value.data.items.level) { 
                            return (`${value.data.headerText}: ${value.data.items.records[0].statusName} ${x}`); 
                        }  
                            return (`${value.data.headerText}: ${value.data.items[0].statusName} ${x}`); 
                         
                    case 'portIdLoad': 
                        if (value.data.items.level) { 
                            return (`${value.data.headerText}: ${value.data.items.records[0].pol} ${x}`); 
                        }  
                            return (`${value.data.headerText}: ${value.data.items[0].pol} ${x}`); 
                    
                    case 'portIdDisch': 
                        if (value.data.items.level) { 
                            return (`${value.data.headerText}: ${value.data.items.records[0].pod} ${x}`); 
                        }  
                            return (`${value.data.headerText}: ${value.data.items[0].pod} ${x}`); 

                    case 'impExpCab':
                        if (value.data.items.level) { 
                            let str = this.impExpCab(value.data.items.records[0].impExpCab);
                            return (`${value.data.headerText}: ${str} ${x}`); 
                        } else {
                            let str = this.impExpCab(value.data.items[0].impExpCab);
                            return (`${value.data.headerText}: ${str} ${x}`); 
                        }
                    
                    case 'trafficArea': 
                        if (value.data.items.level) { 
                            let name = 'No traffic area';
                            if (value.data.items.records[0].trafficArea !== '') {
                                const area = trafficAreas.find((o) => o.value === value.data.items.records[0].trafficArea);
                                name = area ? area.text : '';
                            }
                            return (`${value.data.headerText}: ${name} ${x}`); 
                        } else {
                            let name = 'No traffic area';
                            if (value.data.items[0].trafficArea !== '') {
                                const area = trafficAreas.find((o) => o.value === value.data.items[0].trafficArea);
                                name = area ? area.text : '';
                            }
                            return (`${value.data.headerText}: ${name} ${x}`); 
                        }
                    default: 
                        return `${value.data.headerText}: ${value.data.key} ${x}`; 
                }
            }
        }
    };     
</script>
