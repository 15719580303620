/* Adds Leaflet scale line a Nautical Mile option */
import L from 'leaflet';

L.Control.Scale.include({
    options: {
        position: 'bottomleft',
        maxWidth: 100,
        metric: true,
        imperial: false,
        nautical: true
    },

    _addScales(options, className, container) {
        if (options.nautical) {
            this._iScale = L.DomUtil.create('div', className, container);
        }
        if (options.metric) {
            this._mScale = L.DomUtil.create('div', className, container);
        }
        if (options.imperial) {
            this._iScale = L.DomUtil.create('div', className, container);
        }
    },

    _updateScales(maxMeters) {
        if (this.options.metric && maxMeters) {
            this._updateMetric(maxMeters);
        }
        if (this.options.imperial && maxMeters) {
            this._updateImperial(maxMeters);
        }
        if (this.options.nautical && maxMeters) {
            this._updateNautical(maxMeters);
        }
    },

    _updateNautical(maxMeters) {
        const maxFeet = maxMeters * 3.2808399;
        let maxMiles;
        let miles;
        let
            feet;

        if (maxFeet > 6076.118) {
            maxMiles = maxFeet / 6076.118;
            miles = this._getRoundNum(maxMiles);
            this._updateScale(this._iScale, `${miles} NM`, miles / maxMiles);
        } else {
            feet = this._getRoundNum(maxFeet);
            this._updateScale(this._iScale, `${feet} ft`, feet / maxFeet);
        }
    },
});
