<template>
    <div>
        <v-tabs v-model="active" height="35" background-color="primary" slider-color="logo">
            <v-tab ripple>
                Analyse performance
            </v-tab>
            <v-tab ripple v-if="typeof features.comparePerformance !== 'undefined' && features.comparePerformance === 1">
                Compare performance
            </v-tab>
            <v-tab v-if="typeof features.voyages !== 'undefined' && features.voyages === 1">
                Voyages
            </v-tab>
            <v-tab v-if="typeof features.logVoyages !== 'undefined' && features.logVoyages === 1">
                Logbook voyages
            </v-tab>
            <v-tab v-if="typeof features.dnvLog !== 'undefined' && features.dnvLog === 1">
                DNV Log abstract
            </v-tab>
            <v-tab v-if="typeof features.dnvBunker !== 'undefined' && features.dnvBunker === 1">
                DNV Bunker
            </v-tab>
            <v-tab ripple v-if="typeof features.CPCompliance !== 'undefined' && features.CPCompliance === 1">
                C/P Compliance
            </v-tab>
            <v-tab ripple v-if="typeof features.perfStatus !== 'undefined' && features.perfStatus === 1">
                Data status
            </v-tab>
            <v-tab ripple v-if="typeof features.cii !== 'undefined' && features.cii === 1">
                CII
            </v-tab>
            <v-tab ripple v-if="typeof features.reports !== 'undefined' && features.reports === 1">
                Reports
            </v-tab>
            <v-tabs-items v-model="active" touchless>
                <v-tab-item eager>
                    <v-card>
                        <v-container fluid grid-list-lg>
                            <v-layout wrap align-center>
                                <v-flex xs12 sm4 lg3 d-flex>
                                    <v-select @change="updateTripsAndParams" v-model="analyze.shipId" :items="ships" item-key="id" item-text="[name]"
                                        item-value="id" label="Select ship" />
                                </v-flex>
                                <v-flex v-if="trips && trips.length > 0" xs12 sm4 lg3 d-flex>
                                    <v-select @change="updateDatesByTrip" v-model="analyze.trip" :items="trips" item-key="date_start"
                                        :item-text="displayTripName" item-value="date_start" label="Select trip" return-object :clearable="true" />
                                </v-flex>
                                <v-flex v-if="paramSets && paramSets.length > 0" xs12 sm4 lg3 d-flex>
                                    <v-select @change="setParamSet(0)" v-model="paramSet" :items="paramSets" item-key="name" item-text="[name]"
                                        item-value="name" :clearable="true" label="Select parameter set" return-object />
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <v-container fluid grid-list-lg>
                            <v-layout wrap align-center>
                                <v-flex xs6 sm4 md2 d-flex>
                                    <v-menu ref="sdmenu" v-model="startDateMenu" :close-on-content-click="true" :return-value.sync="analyze.startDate"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="analyze.startDate" label="Start date">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker @change="$refs.sdmenu.save(analyze.startDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                                            v-model="analyze.startDate" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.sdmenu.save(analyze.startDate)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs6 sm3 md2 lg1 d-flex>
                                    <v-menu ref="stmenu" v-model="startTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                        :return-value.sync="analyze.startTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="analyze.startTime" label="Start time">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">access_time</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-time-picker v-if="startTimeMenu" v-model="analyze.startTime" format="24hr" 
                                            @click:minute="$refs.stmenu.save(analyze.startTime)"></v-time-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs6 sm4 md2 d-flex>
                                    <v-menu ref="edmenu" v-model="endDateMenu" :close-on-content-click="true" :return-value.sync="analyze.endDate"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="analyze.endDate" label="End date">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker @change="$refs.edmenu.save(analyze.endDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                                            v-model="analyze.endDate" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="endDateMenu = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.edmenu.save(analyze.endDate)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs6 sm3 md2 lg1 d-flex>
                                    <v-menu ref="etmenu" v-model="endTimeMenu" :close-on-content-click="false" :nudge-right="40"
                                        :return-value.sync="analyze.endTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="analyze.endTime" label="End time">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">access_time</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-time-picker v-if="endTimeMenu" v-model="analyze.endTime" format="24hr" 
                                            @click:minute="$refs.etmenu.save(analyze.endTime)"></v-time-picker>
                                    </v-menu>
                                </v-flex>

                                <v-flex xs2 sm1 md1 d-flex>
                                    <v-select v-model="analyze.timezone" :items="timezones" @change="saveTimezone(analyze.timezone)" item-key="value"
                                        item-text="[name]" item-value="value" label="Timezone" />
                                </v-flex>
                                <v-spacer />
                                <v-btn color="success" @click="exportToExcel()">
                                    Excel <v-icon right class="hidden-xs-only">
                                        arrow_drop_down_circle
                                    </v-icon>
                                </v-btn>
                                <v-btn color="error" class="ml-4" @click="clearChart()">
                                    Clear <v-icon right class="hidden-xs-only">
                                        cancel
                                    </v-icon>
                                </v-btn>
                                <v-btn color="secondary" class="ml-4" v-show="!showChart2"
                                    @click="analyze2.shipId=analyze.shipId;analyze2.params=analyze.params;showChart2=true;trips2=trips;params2=params;paramGroups2=paramGroups;paramSets2=paramSets;paramSet2=paramSet">
                                    Add chart <v-icon right class="hidden-xs-only">add_circle</v-icon>
                                </v-btn>
                                <v-btn color="primary" class="ml-4" @click="callFetchPerformanceData(analyze, 0)"
                                    :disabled="!analyze.shipId && !analyze.startDate && !analyze.startTime && !analyze.endDate && !analyze.endTime">
                                    Get data
                                    <v-icon right class="hidden-xs-only">check</v-icon>
                                </v-btn>
                            </v-layout>
                        </v-container>
                        <v-container fluid grid-list-lg>
                            <v-flex xs12>
                                <v-expansion-panels v-model="panel">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <div>
                                                <v-icon medium color="primary">settings</v-icon>
                                                <span class="text-body-1 ml-2">Select parameters to analyse</span>
                                            </div>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="cardbg">
                                            <v-card v-for="(group, key) in paramGroups" :key="key" class="cardbg">
                                                <v-container fluid grid-list-lg>
                                                    <v-subheader style="cursor: pointer;" title="Click to toggle all group parameters"
                                                        @click="toggleGroup(group, 0)">
                                                        {{ key }}
                                                    </v-subheader>
                                                    <v-layout wrap align-center>
                                                        <v-flex v-for="(item, index) in group" :key="index" xs4 sm2 lg1 d-flex>
                                                            <v-switch v-model="analyze.params"
                                                                :label="analyze.params && analyze.params.includes(item.param) ? 'On' : 'Off'" color="primary"
                                                                :value="item.param" :hint="item.name" :disabled="item.param==='sog'" persistent-hint />
                                                        </v-flex>
                                                    </v-layout>
                                                </v-container>
                                                <v-divider />
                                            </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-flex>
                        </v-container>
                        <v-divider />
                        <v-container v-if="showChart2" fluid grid-list-lg>
                            <v-layout wrap align-center>
                                <v-flex xs12 sm4 lg3 d-flex>
                                    <v-select @change="updateTripsAndParams2" v-model="analyze2.shipId" :items="ships" item-key="id" item-text="[name]"
                                        item-value="id" label="Select ship" />
                                </v-flex>
                                <v-flex v-if="trips2 && trips2.length > 0" xs12 sm4 lg3 d-flex>
                                    <v-select @change="updateDatesByTrip2" v-model="analyze2.trip" :items="trips2" item-key="date_start"
                                        :item-text="displayTripName2" item-value="date_start" label="Select trip" return-object :clearable="true" />
                                </v-flex>
                                <v-flex v-if="paramSets2 && paramSets2.length > 0" xs12 sm4 lg3 d-flex>
                                    <v-select @change="setParamSet(1)" v-model="paramSet2" :items="paramSets2" item-key="name" item-text="[name]"
                                        item-value="name" :clearable="true" label="Select parameter set" return-object />
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <v-container v-if="showChart2" fluid grid-list-lg>
                            <v-layout wrap align-center>
                                <v-flex xs6 sm4 md2 d-flex>
                                    <v-menu ref="sdmenu2" v-model="startDateMenu2" :close-on-content-click="true" :return-value.sync="analyze2.startDate"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="analyze2.startDate" label="Start date">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker @change="$refs.sdmenu2.save(analyze2.startDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                                            v-model="analyze2.startDate" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="startDateMenu2 = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.sdmenu2.save(analyze2.startDate)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs6 sm3 md2 lg1 d-flex>
                                    <v-menu ref="stmenu2" v-model="startTimeMenu2" :close-on-content-click="false" :nudge-right="40"
                                        :return-value.sync="analyze2.startTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="analyze2.startTime" label="Start time">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">access_time</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-time-picker v-if="startTimeMenu2" v-model="analyze2.startTime" format="24hr" 
                                            @click:minute="$refs.stmenu2.save(analyze2.startTime)"></v-time-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs6 sm4 md2 d-flex>
                                    <v-menu ref="edmenu2" v-model="endDateMenu2" :close-on-content-click="true" :return-value.sync="analyze2.endDate"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="analyze2.endDate" label="End date">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker @change="$refs.edmenu2.save(analyze2.endDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                                            v-model="analyze2.endDate" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="endDateMenu2 = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.edmenu2.save(analyze2.endDate)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-flex>
                                <v-flex xs6 sm3 md2 lg1 d-flex>
                                    <v-menu ref="etmenu2" v-model="endTimeMenu2" :close-on-content-click="false" :nudge-right="40"
                                        :return-value.sync="analyze2.endTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template #activator="{ on }">
                                            <v-text-field v-model="analyze2.endTime" label="End time">
                                                <template #prepend>
                                                    <v-icon v-on="on" color="primary">access_time</v-icon>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <v-time-picker v-if="endTimeMenu2" v-model="analyze2.endTime" format="24hr" 
                                            @click:minute="$refs.etmenu2.save(analyze2.endTime)"></v-time-picker>
                                    </v-menu>
                                </v-flex>

                                <v-flex xs2 sm1 md1 d-flex>
                                    <v-select v-model="analyze2.timezone" :items="timezones" @change="saveTimezone2(analyze2.timezone)" item-key="value"
                                        item-text="[name]" item-value="value" label="Timezone" />
                                </v-flex>
                                <v-spacer />
                                <v-btn color="secondary" v-show="showChart2" @click="showChart2 = false">
                                    Remove chart
                                    <v-icon right class="hidden-xs-only">remove_circle</v-icon>
                                </v-btn>
                                <v-btn color="primary" @click="callFetchPerformanceData(analyze2, 1)"
                                    :disabled="!analyze2.shipId && !analyze2.startDate && !analyze2.startTime && !analyze2.endDate && !analyze2.endTime">
                                    Get data
                                    <v-icon right class="hidden-xs-only">check</v-icon>
                                </v-btn>
                            </v-layout>
                        </v-container>
                        <v-container v-if="showChart2" fluid grid-list-lg>
                            <v-flex xs12>
                                <v-expansion-panels v-model="panel2">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <div>
                                                <v-icon medium color="primary">settings</v-icon>
                                                <span class="text-body-1 ml-2">Select parameters to analyse</span>
                                            </div>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="cardbg">
                                            <v-card v-for="(group, key) in paramGroups2" :key="key" class="cardbg">
                                                <v-container fluid grid-list-lg>
                                                    <v-subheader style="cursor: pointer;" title="Click to toggle all group parameters"
                                                        @click="toggleGroup(group, 1)">
                                                        {{ key }}
                                                    </v-subheader>
                                                    <v-layout wrap align-center>
                                                        <v-flex v-for="(item, index) in group" :key="index" xs4 sm2 lg1 d-flex>
                                                            <v-switch v-model="analyze2.params"
                                                                :label="analyze2.params && analyze2.params.includes(item.param) ? 'On' : 'Off'" color="primary"
                                                                :value="item.param" :hint="item.name" :disabled="item.param==='sog'" persistent-hint />
                                                        </v-flex>
                                                    </v-layout>
                                                </v-container>
                                                <v-divider />
                                            </v-card>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-flex>
                        </v-container>
                        <v-container fluid class="pa-0" v-show="chart[0] && chart[0].data && chart[0].data.length > 0">
                            <v-layout wrap>
                                <v-flex xs12 sm12 lg8>
                                    <v-card class="eventcard cardbg primarytext--text">
                                        <v-container fluid grid-list-lg class="pa-0">
                                            <div class="chart" ref="chartdiv">
                                            </div>
                                            <video class="video" width="600px" controls></video>
                                        </v-container>
                                    </v-card>
                                </v-flex>
                                <v-flex xs12 sm12 lg4>
                                    <v-map v-if="chart[0] && chart[0].data && chart[0].data.length > 0" class="map" ref="map" :zoom="zoom[0]" :center="center0"
                                        :bounds="bounds[0]" :options="mapOptions">
                                        <l-control-layers />
                                        <!-- <v-tilelayer :url="url" :options="{tileSize: 512, crossOrigin: true, zoomOffset: -1}"></v-tilelayer> -->
                                        <v-tilelayer v-for="(layer, index) in baseLayers" :key="index" :name="layer.name" :url="layer.url" :attribution="layer.attribution"
                                            :options="{tileSize: layer.tileSize, crossOrigin: layer.crossOrigin, zoomOffset: -1, maxZoom: 20}" :visible="layer.visible" layer-type="base">
                                        </v-tilelayer>
                                        <v-tilelayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png">
                                        </v-tilelayer>
                                        <v-geojson v-if="routeGeojson[0]" :geojson="routeGeojson[0]" :options="routeOptions[0]"></v-geojson>
                                        <v-geojson v-if="marker0 && marker0.shipGeojson" :geojson="marker0.shipGeojson" :options="shipGeojsonOptions">
                                        </v-geojson>
                                        <v-rotated-marker v-if="marker0 && marker0.predictor && marker0.predictor.latLng" :lat-lng="marker0.predictor.latLng"
                                            :icon="marker0.predictor.icon" :rotationAngle="marker0.predictor.direction"
                                            :rotationOrigin="marker0.predictor.rotationOrigin">
                                        </v-rotated-marker>
                                        <v-rotated-marker v-if="marker0 && marker0.latitude && marker0.longitude && marker0.latLng" :lat-lng="marker0.latLng"
                                            :icon="marker0.icon" :rotationAngle="marker0.rotation">
                                            <l-tooltip v-if="marker0.tooltip" :content="marker0.tooltip" :options="tooltipOptions" />
                                            <l-popup v-if="marker0.popup" :content="marker0.popup"></l-popup>
                                        </v-rotated-marker>
                                        <span v-if="marker0 && typeof marker0.circles !== 'undefined' && Array.isArray(marker0.circles)">
                                            <l-circle v-for="(circle, index) in marker0.circles" :key="random()+index" :lat-lng="circle.center"
                                                :radius="circle.radius" :fillOpacity="0.1" :color="circle.color" :weight="circle.weight"></l-circle>
                                        </span>

                                        <v-geojson v-for="(geo, index) in geojsons[0]" :key="random()+index" :geojson="geo.geojson" :options="geo.options">
                                        </v-geojson>
                                        
                                        <v-rotated-marker v-for="(marker, index) in markers[0]" :key="random()+index" :lat-lng="marker.latLng"
                                            :icon="marker.icon" :rotationAngle="marker.direction" :options="marker.options">
                                            <l-tooltip v-if="marker.tooltip" :content="marker.tooltip" :options="tooltipOptions" />
                                            <l-popup v-if="marker.popup" :content="marker.popup"></l-popup>
                                        </v-rotated-marker>

                                        <span v-if="showTriggers0 && triggers[0] && triggers[0].length > 0">
                                            <span v-for="trigger in triggers[0]" :key="trigger.id">
                                                <v-geojson v-if="trigger.content && trigger.content.geojson" :options-style="triggerStyle"
                                                    :geojson="trigger.content.geojson" :options="triggerGeoOptions">
                                                </v-geojson>
                                            </span>
                                        </span>

                                        <div v-if="marker0">
                                            <div class="aisinfo primarytext--text">
                                                <span v-if="typeof marker0.sog !== 'undefined' && marker0.sog !== null">SOG: {{ Math.round(marker0.sog*10)/10 }} kn <br /></span>
                                                <span v-if="typeof marker0.latitude !== 'undefined' && marker0.latitude !== null">
                                                    {{ decimalToDmm(marker0.latitude, false) }} {{ decimalToDmm(marker0.longitude, true) }} <br />
                                                </span>
                                            </div>
                                            <div v-if="(typeof marker0.tws !== 'undefined' && marker0.tws !== null) || (typeof marker0.twd !== 'undefined' && marker0.twd !== null)" 
                                                class="aisinfo primarytext--text" style="right:5px;left:auto;">
                                                <span v-if="typeof marker0.tws !== 'undefined' && marker0.tws !== null">
                                                    {{ Math.round(marker0.tws*10)/10 }}m/s
                                                </span>
                                                <span v-if="typeof marker0.twd !== 'undefined' && marker0.twd !== null">
                                                    {{ Math.round(marker0.twd) }}º
                                                </span> 
                                                <span v-if="typeof marker0.twd !== 'undefined' && marker0.twd !== null">
                                                    <v-icon :style="'transform: rotate(' + marker0.twd + 'deg)'">
                                                        {{ mdiArrowDownBold }}
                                                    </v-icon>
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div class="map-controls">
                                            <v-icon v-show="chartTrackType === 'cursor'" @click="chartTrackType = 'lineSeries'"
                                                title="Chart cursor movement based ship tracking" class="control-btn" style="top:0">
                                                border_inner
                                            </v-icon>
                                            <v-icon v-show="chartTrackType === 'lineSeries'" @click="chartTrackType = null"
                                                title="Line series hover based ship tracking" class="control-btn" style="top:0">
                                                show_chart
                                            </v-icon>
                                            <v-icon v-show="chartTrackType === null" @click="chartTrackType = 'cursor'" title="No ship tracking on map"
                                                class="control-btn" style="top:0">
                                                block
                                            </v-icon>
                                            <v-icon @click="chartPan = false" title="Zoom and pan map to ship position" class="control-btn" style="top:40px">
                                                open_with
                                            </v-icon>
                                            <v-icon v-show="!chartPan" @click="chartPan = true" title="No zoom or pan to ship position" class="control-btn"
                                                style="font-size:30px;top:40px;background:rgba(255, 255, 255, 0.5)">
                                                clear
                                            </v-icon>
                                            <v-icon @click="aisTargets0 = false;markers[0] = [];geojsons[0] = []" title="Show nearby ais targets"
                                                class="control-btn" style="top:80px">
                                                directions_boat
                                            </v-icon>
                                            <v-icon v-show="!aisTargets0" @click="aisTargets0 = true" title="Do not show nearby ais targets" class="control-btn"
                                                style="font-size:30px;top:80px;background:rgba(255, 255, 255, 0.5)">
                                                clear
                                            </v-icon>
                                            <v-icon @click="showTriggers0 = false;" title="Do not show logbook trigger areas"
                                                class="control-btn" style="top:120px">
                                                mdi-vector-combine
                                            </v-icon>
                                            <v-icon v-show="!showTriggers0" @click="showTriggers0 = true;" title="Show logbook trigger areas"
                                                class="control-btn" style="font-size:30px;top:120px;background:rgba(255, 255, 255, 0.5)">
                                                clear
                                            </v-icon>
                                        </div>
                                    </v-map>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <v-divider />
                        <v-container fluid class="pa-0" v-show="showChart2 && chart[1] && chart[1].data && chart[1].data.length > 0">
                            <v-layout wrap>
                                <v-flex xs12 sm12 lg8>
                                    <v-card class="eventcard cardbg primarytext--text">
                                        <v-container fluid grid-list-lg class="pa-0">
                                            <div class="chart" ref="chartdiv2">
                                            </div>
                                        </v-container>
                                    </v-card>
                                </v-flex>
                                <v-flex xs12 sm12 lg4>
                                    <v-map v-if="chart[1] && chart[1].data && chart[1].data.length > 0" class="map" ref="map2" :zoom="zoom[1]" :center="center1"
                                        :bounds="bounds[1]" :options="mapOptions">
                                        <l-control-layers />
                                        <!-- <v-tilelayer :url="url" :options="{tileSize: 512, crossOrigin: true, zoomOffset: -1}"></v-tilelayer> -->
                                        <v-tilelayer v-for="(layer, index) in baseLayers" :key="index" :name="layer.name" :url="layer.url" :attribution="layer.attribution"
                                            :options="{tileSize: layer.tileSize, crossOrigin: layer.crossOrigin, zoomOffset: -1, maxZoom: 20}" :visible="layer.visible" layer-type="base">
                                        </v-tilelayer>
                                        <v-tilelayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png">
                                        </v-tilelayer>
                                        <v-geojson v-if="routeGeojson[1]" :geojson="routeGeojson[1]" :options="routeOptions[0]"></v-geojson>
                                        <v-geojson v-if="marker1 && marker1.shipGeojson" :geojson="marker1.shipGeojson" :options="shipGeojsonOptions">
                                        </v-geojson>
                                        <v-rotated-marker v-if="marker1 && marker1.predictor && marker1.predictor.latLng" :lat-lng="marker1.predictor.latLng"
                                            :icon="marker1.predictor.icon" :rotationAngle="marker1.predictor.direction"
                                            :rotationOrigin="marker1.predictor.rotationOrigin">
                                        </v-rotated-marker>
                                        <v-rotated-marker v-if="marker1 && marker1.latitude && marker1.longitude && marker1.latLng" :lat-lng="marker1.latLng"
                                            :icon="marker1.icon" :rotationAngle="marker1.rotation">
                                            <l-tooltip v-if="marker1.tooltip" :content="marker1.tooltip" :options="tooltipOptions" />
                                            <l-popup v-if="marker1.popup" :content="marker1.popup"></l-popup>
                                        </v-rotated-marker>
                                        <span v-if="marker1 && typeof marker1.circles !== 'undefined' && Array.isArray(marker1.circles)">
                                            <l-circle v-for="(circle, index) in marker1.circles" :key="random()+index" :lat-lng="circle.center"
                                                :radius="circle.radius" :fillOpacity="0.1" :color="circle.color" :weight="circle.weight"></l-circle>
                                        </span>

                                        <v-geojson v-for="(geo, index) in geojsons[1]" :key="random()+index" :geojson="geo.geojson" :options="geo.options">
                                        </v-geojson>
                                        <v-rotated-marker v-for="(marker, index) in markers[1]" :key="random()+index" :lat-lng="marker.latLng"
                                            :icon="marker.icon" :rotationAngle="marker.direction" :options="marker.options">
                                            <l-tooltip v-if="marker.tooltip" :content="marker.tooltip" :options="tooltipOptions" />
                                            <l-popup v-if="marker.popup" :content="marker.popup"></l-popup>
                                        </v-rotated-marker>

                                        <span v-if="showTriggers1 && triggers[1] && triggers[1].length > 0">
                                            <span v-for="trigger in triggers[1]" :key="trigger.id">
                                                <v-geojson v-if="trigger.content && trigger.content.geojson" :options-style="triggerStyle"
                                                    :geojson="trigger.content.geojson" :options="triggerGeoOptions">
                                                </v-geojson>
                                            </span>
                                        </span>

                                        <div v-if="marker1">
                                            <div class="aisinfo primarytext--text">
                                                <span v-if="typeof marker1.sog !== 'undefined' && marker1.sog !== null">SOG: {{ Math.round(marker1.sog*10)/10 }} kn <br /></span>
                                                <span v-if="typeof marker1.latitude !== 'undefined' && marker1.latitude !== null">
                                                    {{ decimalToDmm(marker1.latitude, false) }}, {{ decimalToDmm(marker1.longitude, true) }} <br />
                                                </span>
                                            </div>
                                            <div v-if="(typeof marker1.tws !== 'undefined' && marker1.tws !== null) || (typeof marker1.twd !== 'undefined' && marker1.twd !== null)" 
                                                class="aisinfo primarytext--text" style="right:5px;left:auto;">
                                                <span v-if="typeof marker1.tws !== 'undefined' && marker1.tws !== null">
                                                    {{ Math.round(marker1.tws*10)/10 }}m/s
                                                </span>
                                                <span v-if="typeof marker1.twd !== 'undefined' && marker1.twd !== null">
                                                    {{ Math.round(marker1.twd) }}º
                                                </span> 
                                                <span v-if="typeof marker1.twd !== 'undefined' && marker1.twd !== null">
                                                    <v-icon :style="'transform: rotate(' + marker1.twd + 'deg)'">
                                                        {{ mdiArrowDownBold }}
                                                    </v-icon>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="map-controls">
                                            <v-icon v-show="chartTrackType2 === 'cursor'" @click="chartTrackType2 = 'lineSeries'"
                                                title="Chart cursor movement based ship tracking" class="control-btn" style="top:0">
                                                border_inner
                                            </v-icon>
                                            <v-icon v-show="chartTrackType2 === 'lineSeries'" @click="chartTrackType2 = null"
                                                title="Line series hover based ship tracking" class="control-btn" style="top:0">
                                                show_chart
                                            </v-icon>
                                            <v-icon v-show="chartTrackType2 === null" @click="chartTrackType2 = 'cursor'" title="No ship tracking on map"
                                                class="control-btn" style="top:0">
                                                block
                                            </v-icon>
                                            <v-icon @click="chartPan2 = false" title="Zoom and pan map to ship position" class="control-btn" style="top:40px">
                                                open_with
                                            </v-icon>
                                            <v-icon v-show="!chartPan2" @click="chartPan2 = true" title="No zoom or pan to ship position" class="control-btn"
                                                style="font-size:30px;top:40px;background:rgba(255, 255, 255, 0.5)">
                                                clear
                                            </v-icon>
                                            <v-icon @click="aisTargets1 = false;markers[1] = [];geojsons[1] = []" title="Show nearby ais targets"
                                                class="control-btn" style="top:80px">
                                                directions_boat
                                            </v-icon>
                                            <v-icon v-show="!aisTargets1" @click="aisTargets1 = true" title="Do not show nearby ais targets" class="control-btn"
                                                style="font-size:30px;top:80px;background:rgba(255, 255, 255, 0.5)">
                                                clear
                                            </v-icon>
                                            <v-icon @click="showTriggers1 = false;" title="Do not show logbook trigger areas"
                                                class="control-btn" style="top:120px">
                                                mdi-vector-combine
                                            </v-icon>
                                            <v-icon v-show="!showTriggers1" @click="showTriggers1 = true;" title="Show logbook trigger areas"
                                                class="control-btn" style="font-size:30px;top:120px;background:rgba(255, 255, 255, 0.5)">
                                                clear
                                            </v-icon>
                                        </div>
                                    </v-map>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <v-container fluid class="pa-0" v-show="chart[0] && chart[0].data && chart[0].data.length > 0">
                            <v-layout wrap>
                                <v-flex xs12 md6>
                                    <v-data-table :headers="aggregateHeaders" :items="aggregateValues" class="elevation-1" dataPagination.rowsPerPage="10"
                                        hide-default-footer>
                                        <template #items="props">
                                            <td>{{ props.item.name }}</td>
                                            <td class="text-center">{{ props.item.min }} {{ props.item.unit }}</td>
                                            <td class="text-center">{{ props.item.max }} {{ props.item.unit }}</td>
                                            <td class="text-center">{{ props.item.avg }} {{ props.item.unit }}</td>
                                            <td class="text-center">
                                                {{ props.item.sum }} {{ props.item.sumUnit }}
                                            </td>
                                        </template>
                                    </v-data-table>

                                    <v-expansion-panels>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <div>
                                                    <v-icon medium color="primary">settings</v-icon>
                                                    <span class="text-body-1 ml-2">Filter aggregate data</span>
                                                </div>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content class="cardbg">
                                                <v-container fluid grid-list-lg class="cardbg mt-6">
                                                    <v-layout wrap v-if="aggregateFilters.length > 0">
                                                        <v-flex xs12>
                                                            <v-list class="cardbg">
                                                                <v-list-item>
                                                                    <v-list-item-title>Active filters:</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-for="(item, index) in aggregateFilters" :key="index">
                                                                    <v-list-item-icon class="mr-4" @click="removeAggregateFilter(index, 0)">
                                                                        <v-icon color="error" title="Delete filter">mdi-close-circle-outline</v-icon>
                                                                    </v-list-item-icon>
                                                                    <v-list-item-title>{{ item.param }} {{ item.condition }} {{ item.value }}</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout wrap>
                                                        <v-flex xs12 md3>
                                                            <v-select v-model="aggregateFilterParam" :items="this.params" item-key="param" item-text="[name]" item-value="param"
                                                                    label="Select parameter" class="ma-2">
                                                            </v-select>
                                                        </v-flex>
                                                        <v-flex xs12 md3>
                                                            <v-select v-model="aggregateFilterCondition" :items="conditionItems" 
                                                                    label="Select condition" class="ma-2">
                                                            </v-select>
                                                        </v-flex>
                                                        <v-flex xs12 md3>
                                                            <v-text-field v-model="aggregateFilterValue" label="Set value" class="ma-2" />
                                                        </v-flex>
                                                        <v-flex xs12 md3>
                                                        <v-btn color="primary" @click="addAggregateFilter(0)" class="ma-4 px-2">
                                                            Add Filter
                                                        </v-btn>
                                                    </v-flex>
                                                </v-layout>
                                            </v-container>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-flex>
                                <v-flex xs12 md6 v-show="showChart2 && chart[1] && chart[1].data && chart[1].data.length > 0">
                                    <v-data-table :headers="aggregateHeaders2" :items="aggregateValues2" class="elevation-1" dataPagination.rowsPerPage="10"
                                        hide-default-footer>
                                        <template #items="props">
                                            <td>{{ props.item.name }}</td>
                                            <td class="text-center">{{ props.item.min }} {{ props.item.unit }}</td>
                                            <td class="text-center">{{ props.item.max }} {{ props.item.unit }}</td>
                                            <td class="text-center">{{ props.item.avg }} {{ props.item.unit }}</td>
                                            <td class="text-center">
                                                {{ props.item.sum }} {{ props.item.sumUnit }}
                                            </td>
                                        </template>
                                    </v-data-table>

                                    <v-expansion-panels>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <div>
                                                    <v-icon medium color="primary">settings</v-icon>
                                                    <span class="text-body-1 ml-2">Filter aggregate data</span>
                                                </div>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content class="cardbg">
                                                <v-container fluid grid-list-lg class="cardbg mt-6">
                                                    <v-layout wrap v-if="aggregateFilters.length > 0">
                                                        <v-flex xs12>
                                                            <v-list class="cardbg">
                                                                <v-list-item>
                                                                    <v-list-item-title>Active filters:</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-for="(item, index) in aggregateFilters2" :key="index">
                                                                    <v-list-item-icon class="mr-4" @click="removeAggregateFilter(index, 1)">
                                                                        <v-icon color="error" title="Delete filter">mdi-close-circle-outline</v-icon>
                                                                    </v-list-item-icon>
                                                                    <v-list-item-title>{{ item.param }} {{ item.condition }} {{ item.value }}</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout wrap>
                                                        <v-flex xs12 md3>
                                                            <v-select v-model="aggregateFilterParam2" :items="this.params" item-key="param" item-text="[name]" item-value="param"
                                                                    label="Select parameter" class="ma-2">
                                                            </v-select>
                                                        </v-flex>
                                                        <v-flex xs12 md3>
                                                            <v-select v-model="aggregateFilterCondition2" :items="conditionItems" 
                                                                    label="Select condition" class="ma-2">
                                                            </v-select>
                                                        </v-flex>
                                                        <v-flex xs12 md3>
                                                            <v-text-field v-model="aggregateFilterValue2" label="Set value" class="ma-2" />
                                                        </v-flex>
                                                        <v-flex xs12 md3>
                                                        <v-btn color="primary" @click="addAggregateFilter(1)" class="ma-4 px-2">
                                                            Add Filter
                                                        </v-btn>
                                                    </v-flex>
                                                </v-layout>
                                            </v-container>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-tab-item>
                <v-tab-item v-if="typeof features.comparePerformance !== 'undefined' && features.comparePerformance === 1">
                    <ComparePerformance />
                </v-tab-item>
                <v-tab-item v-if="typeof features.voyages !== 'undefined' && features.voyages === 1">
                    <ShipVoyages />
                </v-tab-item>
                <v-tab-item v-if="typeof features.logVoyages !== 'undefined' && features.logVoyages === 1">
                    <LogVoyages />
                </v-tab-item>
                <v-tab-item v-if="typeof features.dnvLog !== 'undefined' && features.dnvLog === 1">
                    <DnvLog />
                </v-tab-item>
                <v-tab-item v-if="typeof features.dnvBunker !== 'undefined' && features.dnvBunker === 1">
                    <DnvBunker />
                </v-tab-item>
                <v-tab-item v-if="typeof features.CPCompliance !== 'undefined' && features.CPCompliance === 1">
                    <CPCompliance />
                </v-tab-item>
                <v-tab-item v-if="typeof features.perfStatus !== 'undefined' && features.perfStatus === 1">
                    <PerfStatus />
                </v-tab-item>
                <v-tab-item v-if="typeof features.cii !== 'undefined' && features.cii === 1">
                    <CiiData :ciiShipId="ciiShipId" />
                </v-tab-item>
                <v-tab-item v-if="typeof features.reports !== 'undefined' && features.reports === 1" style="height:calc(100vh - 123px)">
                    <Reports />
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>

        <!-- Components -->
        <StatusDialog />
        <ProgressCircular />
    </div>
</template>

<script>
    import L from 'leaflet';
    import writeXlsxFile from 'write-excel-file';
    import {
        LMap,
        LTileLayer,
        LGeoJson,
        LControlLayers,
        LTooltip,
        LPopup,
        LCircle
    } from 'vue2-leaflet';
    import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker';
    import {
        mapGetters,
        mapActions,
    } from 'vuex';
    import moment from 'moment';
    import {
        lineString
    } from '@turf/helpers';
    import turfLength from '@turf/length';
    import * as am4core from '@amcharts/amcharts4/core';
    import * as am4charts from '@amcharts/amcharts4/charts';
    import am4themes_animated from '@amcharts/amcharts4/themes/animated';
    import am4themes_dark from '@amcharts/amcharts4/themes/dark';
    import StatusDialog from '../components/StatusDialog.vue';
    import ProgressCircular from '../components/ProgressCircular.vue';
    import CPCompliance from '../components/CPCompliance.vue';
    import ShipVoyages from '../components/ShipVoyages.vue';
    import LogVoyages from '../components/LogVoyages.vue';
    import DnvLog from '../components/DnvLog.vue';
    import DnvBunker from '../components/DnvBunker.vue';
    import CiiData from '../components/CiiData.vue';
    import ComparePerformance from '../components/ComparePerformance.vue';
    import PerfStatus from '../components/PerfStatus.vue';
    import Reports from '../components/Reports.vue';
    import {
        shipHelpers,
        tools,
        config
    } from '../helpers';
    import {
     mdiArrowDownBold,
    
    } from '@mdi/js';

    am4core.useTheme(am4themes_animated);

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    export default {
        name: 'Performance',
        components: {
            'v-map': LMap,
            'v-tilelayer': LTileLayer,
            'v-geojson': LGeoJson,
            LTooltip,
            LControlLayers,
            LPopup,
            LCircle,
            'v-rotated-marker': Vue2LeafletRotatedMarker,
            StatusDialog,
            ProgressCircular,
            CPCompliance,
            ShipVoyages,
            LogVoyages,
            DnvLog,
            DnvBunker,
            ComparePerformance,
            PerfStatus,
            CiiData,
            Reports,
        },
        data() {
            return {
                mdiArrowDownBold,
                // Leaflet specific properties
                zoom: [4, 4],
                center0: L.latLng(20, 50),
                center1: L.latLng(20, 50),
                mapOptions: {
                    attributionControl: false
                },
                tooltipOptions: {
                    permanent: true,
                    direction: 'top',
                    offset: L.point(0, -10),
                    opacity: 0.8,
                },
                triggerStyle: {
                    style: {
                        color: '#27ae60',
                        weight: 1,
                        opacity: 0.6
                    }
                },
                bounds: [null, null],
                shipGeojsonOptions: {
                    style: {
                        color: '#000000',
                        weight: 1,
                        opacity: 0.6
                    }
                },
                routeOptions: [{
                        style: {
                            color: '#e59165',
                            weight: 1.5,
                        }
                    },
                    {
                        style: {
                            color: '#0652DD',
                            weight: 1.5,
                        }
                    },
                    {
                        style: {
                            color: '#16a085',
                            weight: 1.5,
                        }
                    },
                ],
                analyze: {
                    shipId: null,
                    // startDate: moment.utc().subtract(1, 'days').format('YYYY-MM-DD'),
                    startDate: moment.utc().format('YYYY-MM-DD'),
                    startTime: '00:00',
                    endDate: moment.utc().format('YYYY-MM-DD'),
                    endTime: moment.utc().format('HH:mm'),
                    trip: {},
                    params: ['sog'],
                    timezone: 0,
                },
                analyze2: {
                    shipId: null,
                    // startDate: moment.utc().subtract(1, 'days').format('YYYY-MM-DD'),
                    startDate: moment.utc().format('YYYY-MM-DD'),
                    startTime: '00:00',
                    endDate: moment.utc().format('YYYY-MM-DD'),
                    endTime: moment.utc().format('HH:mm'),
                    trip: {},
                    params: ['sog'],
                    timezone: 0,
                },
                startDateMenu: false,
                startTimeMenu: false,
                endDateMenu: false,
                endTimeMenu: false,
                startDateMenu2: false,
                startTimeMenu2: false,
                endDateMenu2: false,
                endTimeMenu2: false,
                marker0: {
                    latLng: L.latLng(10, 50),
                },
                marker1: {
                    latLng: L.latLng(10, 50),
                },
                routeGeojson: [null, null],
                markers: [
                    [],
                    []
                ],
                geojsons: [
                    [],
                    []
                ],
                trips: [],
                trips2: [],
                chartTrackType: 'cursor',
                chartTrackType2: 'cursor',
                timeoutFired: true,
                ship: [{}, {}],
                chart: [],
                active: null,
                trendSeries: [{}, {}],
                chartPan: true,
                chartPan2: 'bounds',
                showChart2: false,
                paramGroups: [],
                params: [],
                paramGroups2: [],
                params2: [],
                sogParams: {
                    name: 'SOG',
                    dateX: 'time',
                    valueY: 'sog',
                    tooltipText: '{name}: {valueY.value} kn',
                    color: '#e59165',
                    width: 2,
                    interactions: true
                },
                // colors: ["#0000FF", "#0055FF", "#00AAFF", "#00FFFF", "#55FFAA", "#AAFF55", "#FFFF00", "#FFAA00", "#FF5500", "#FF0000"],
                colors: ['#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#fabebe', '#008080',
                    '#e6beff', '#9a6324', '#fffac8', '#800000', '#808000', '#ffd8b1', '#000075'
                ],
                aisTargetsTimeout: [false, false],
                aisTargets0: false,
                aisTargets1: false,
                panel: [],
                panel2: [],
                aggregateValues: [],
                aggregateValues2: [],
                aggregateHeaders: [{
                        text: 'Parameter',
                        align: 'left',
                        value: 'name'
                    },
                    {
                        text: 'Min',
                        align: 'center',
                        value: 'min'
                    },
                    {
                        text: 'Max',
                        align: 'center',
                        value: 'max'
                    },
                    {
                        text: 'Average',
                        align: 'center',
                        value: 'avg'
                    },
                    {
                        text: 'Sum',
                        align: 'center',
                        value: 'sum'
                    },
                ],
                aggregateHeaders2: [{
                        text: 'Parameter',
                        align: 'left',
                        value: 'name'
                    },
                    {
                        text: 'Min',
                        align: 'center',
                        value: 'min'
                    },
                    {
                        text: 'Max',
                        align: 'center',
                        value: 'max'
                    },
                    {
                        text: 'Average',
                        align: 'center',
                        value: 'avg'
                    },
                    {
                        text: 'Sum',
                        align: 'center',
                        value: 'sum'
                    },
                ],
                aggregateFilterParam: null,
                aggregateFilterCondition: null,
                aggregateFilterValue: null,
                aggregateFilterParam2: null,
                aggregateFilterCondition2: null,
                aggregateFilterValue2: null,
                aggregateFilters: [],
                aggregateFilters2: [],
                conditionItems: [
                    {text: 'Equals', value: '='},
                    {text: 'Not equals', value: '!='},
                    {text: 'Less than', value: '<'},
                    {text: 'Less than or equal to', value: '<='},
                    {text: 'Greater than', value: '>'},
                    {text: 'Greater than or equal to', value: '>='}
                ],
                initialTimes: [{
                        startDate: null,
                        startTime: null,
                        endDate: null,
                        endTime: null
                    },
                    {
                        startDate: null,
                        startTime: null,
                        endDate: null,
                        endTime: null
                    },
                ],
                paramSets: [],
                paramSet: {},
                paramSets2: [],
                paramSet2: {},
                paramsLoaded: false,
                timezones: [{
                        name: '-11:00',
                        value: -660
                    },
                    {
                        name: '-10:00',
                        value: -600
                    },
                    {
                        name: '-09:00',
                        value: -540
                    },
                    {
                        name: '-08:00',
                        value: -480
                    },
                    {
                        name: '-07:00',
                        value: -420
                    },
                    {
                        name: '-06:00',
                        value: -360
                    },
                    {
                        name: '-05:00',
                        value: -300
                    },
                    {
                        name: '-04:00',
                        value: -240
                    },
                    {
                        name: '-03:00',
                        value: -180
                    },
                    {
                        name: '-02:00',
                        value: -120
                    },
                    {
                        name: '-01:00',
                        value: -60
                    },
                    {
                        name: 'UTC',
                        value: 0
                    },
                    {
                        name: '+01:00',
                        value: 60
                    },
                    {
                        name: '+02:00',
                        value: 120
                    },
                    {
                        name: '+03:00',
                        value: 180
                    },
                    {
                        name: '+04:00',
                        value: 240
                    },
                    {
                        name: '+05:00',
                        value: 300
                    },
                    {
                        name: '+06:00',
                        value: 360
                    },
                    {
                        name: '+07:00',
                        value: 420
                    },
                    {
                        name: '+08:00',
                        value: 480
                    },
                    {
                        name: '+09:00',
                        value: 540
                    },
                    {
                        name: '+10:00',
                        value: 600
                    },
                    {
                        name: '+11:00',
                        value: 660
                    },
                    {
                        name: '+12:00',
                        value: 720
                    },
                ],
                triggers: [[], []],
                showTriggers0: false,
                showTriggers1: false,
                currentDate: null,
                currentIndex: null,
                distances: [],
                ciiShipId: 0,
                baseLayers: [{
                        url: `https://api.maptiler.com/maps/511be076-e981-4e63-8d86-7f23e4d3e7f5/{z}/{x}/{y}.png?key=${config.apiKey}`,
                        name: 'Day',
                        attribution: '&copy; <a href="http://www.nauticai.com" target="_blank">nauticAi</a> <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
                        visible: true,
                        crossOrigin: true,
                        tileSize: 512,
                    },
                    {
                        url: `https://api.maptiler.com/maps/d0b6607a-df0c-4660-af62-030764e2efc4/{z}/{x}/{y}.png?key=${config.apiKey}`,
                        name: 'Night',
                        attribution: '&copy; <a href="http://www.nauticai.com" target="_blank">nauticAi</a> <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
                        visible: false,
                        crossOrigin: true,
                        tileSize: 512,
                    },
                    {
                        url: `https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=${config.apiKey}`,
                        name: 'Satellite hybrid',
                        attribution: '&copy; <a href="http://www.nauticai.com" target="_blank">nauticAi</a> <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
                        visible: false,
                        crossOrigin: true,
                        tileSize: 512,
                    },
                    {
                        url: `https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=${config.apiKey}`,
                        name: 'Satellite',
                        attribution: '&copy; <a href="http://www.nauticai.com" target="_blank">nauticAi</a> <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
                        visible: false,
                        crossOrigin: true,
                        tileSize: 512,
                    },
                    {
                        url: `http://viewer.flytomap.com/ftm_tiles/{z}/{x}/{y}.png`,
                        name: 'Nautical vector [ft]',
                        attribution: '<a href="https://flytomap.com/" target="_blank">Powered by FLYTOMAP</a>',
                        visible: false,
                        crossOrigin: false,
                        tileSize: 512,
                    },
                    {
                        url: `https://viewer.flytomap.com/noaatiles/{z}/{x}/{y}.png`,
                        name: 'Nautical raster [ft/m]',
                        attribution: '<a href="https://flytomap.com/" target="_blank">Powered by FLYTOMAP</a>',
                        visible: false,
                        crossOrigin: false,
                        tileSize: 512,
                    },
                ],
            };
        },
        mounted() {
            // Create charts
            if (this.getDarkMode()) {
                am4core.useTheme(am4themes_dark);
            }
            this.createCharts();

            // Preselect stored or first ship and create compare array
            this.fetchShips().then((data) => {
                const shipId = localStorage.getItem('perfShipId') ? parseInt(localStorage.getItem('perfShipId')) : null;
                // Check if valid ship id
                let ship = data.find((o) => o.perfDb !== null && o.id === shipId);
                if (!ship) {
                    // If not select first ship
                    ship = data.find((o) => o.perfDb !== null);
                }
                this.analyze.shipId = ship.id;
                this.analyze2.shipId = ship.id;
                this.updateTripsAndParams(ship.id);
            });

            // Get last timezone from localstorage
            this.analyze.timezone = localStorage.getItem('timezone') ? parseInt(localStorage.getItem('timezone')) : 0;
            this.analyze2.timezone = localStorage.getItem('timezone2') ? parseInt(localStorage.getItem('timezone2')) : 0;

            this.analyze.startDate = moment.utc().utcOffset(this.analyze.timezone).subtract(1, 'days').format('YYYY-MM-DD');
            this.analyze.startTime = '00:00';
            this.analyze.endDate = moment.utc().utcOffset(this.analyze.timezone).format('YYYY-MM-DD');
            this.analyze.endTime = moment.utc().utcOffset(this.analyze.timezone).format('HH:mm');

            this.analyze2.startDate = moment.utc().utcOffset(this.analyze2.timezone).subtract(1, 'days').format('YYYY-MM-DD');
            this.analyze2.startTime = '00:00';
            this.analyze2.endDate = moment.utc().utcOffset(this.analyze2.timezone).format('YYYY-MM-DD');
            this.analyze2.endTime = moment.utc().utcOffset(this.analyze2.timezone).format('HH:mm');
        },
        created() {
            // Pre open CII graph is shipId defined
            if (this.$route.params.shipId) {
                this.ciiShipId = Number.parseInt(this.$route.params.shipId);
                // Count tab length
                let tabCount = 0;
                if (typeof this.features.comparePerformance !== 'undefined' && this.features.comparePerformance === 1) {
                    ++tabCount;
                }
                if (typeof this.features.voyages !== 'undefined' && this.features.voyages === 1) {
                    ++tabCount;
                }
                if (typeof this.features.logVoyages !== 'undefined' && this.features.logVoyages === 1) {
                    ++tabCount;
                }
                if (typeof this.features.dnvLog !== 'undefined' && this.features.dnvLog === 1) {
                    ++tabCount;
                }
                if (typeof this.features.dnvBunker !== 'undefined' && this.features.dnvBunker === 1) {
                    ++tabCount;
                }
                if (typeof this.features.CPCompliance !== 'undefined' && this.features.CPCompliance === 1) {
                    ++tabCount;
                }
                if (typeof this.features.perfStatus !== 'undefined' && this.features.perfStatus === 1) {
                    ++tabCount;
                }
                if (typeof this.features.cii !== 'undefined' && this.features.cii === 1) {
                    ++tabCount;
                }
                this.active = tabCount;
            }
        },
        beforeDestroy() {
            this.disposeCharts();
        },
        watch: {
            getDarkModeComputed(newValue) {
                this.disposeCharts();
                if (newValue) {
                    am4core.useTheme(am4themes_dark);
                } else {
                    am4core.unuseTheme(am4themes_dark);
                }
                // Create charts
                this.createCharts();
            }
        },
        computed: {
            url() {
                if (this.getDarkMode()) {
                    return `https://api.maptiler.com/maps/d0b6607a-df0c-4660-af62-030764e2efc4/{z}/{x}/{y}.png?key=${config.apiKey}`;
                }
                return `https://api.maptiler.com/maps/511be076-e981-4e63-8d86-7f23e4d3e7f5/{z}/{x}/{y}.png?key=${config.apiKey}`;
            },
            ships() {
                return this.getShips().filter((o) => o.perfDb !== null);
            },
            ...mapGetters({
                features: 'authentication/features',
                firstDayOfWeek: 'data/getFirstDayOfWeek',
                getDarkModeComputed: 'users/getDarkMode',
                user: 'authentication/getUser',
            }),
            triggerGeoOptions() {
                return {
                    onEachFeature: this.onEachFeatureFunction
                };
            },
            onEachFeatureFunction() {
                return (feature, layer) => {
                    if (feature.properties && feature.properties.name) {
                        layer.bindTooltip(
                        '<div>' + feature.properties.name + '</div>',
                        { permanent: false, sticky: true }
                        );
                    }
                };
            },
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getDynamic: 'data/getDynamic',
                getShips: 'data/getShips',
                getTrips: 'data/getTrips',
            }),
            ...mapActions({
                fetchShips: 'data/fetchShips',
                fetchTrips: 'data/fetchTrips',
                fetchTriggers: 'logbook/fetchTriggers',
                fetchPerformanceData: 'data/fetchPerformanceData',
                fetchAggregateValues: 'data/fetchAggregateValues',
                fetchPerformanceParams: 'data/fetchPerformanceParams',
                fetchPerformanceAisTargets: 'data/fetchPerformanceAisTargets',
            }),
            disposeCharts() {
                if (this.chart[0]) {
                    this.chart[0].dispose();
                }
                if (this.chart[1]) {
                    this.chart[1].dispose();
                }
            },
            decimalToDmm(value, lng) {
                return tools.decimalToDmm(value, lng);
            },
            callFetchPerformanceData(analyze, index) {
                // Close correct parameter panel
                index === 0 ? this.panel = [false] : this.panel2 = [false];
                if (analyze.params && analyze.params.length > 11) {
                    this.$store.dispatch('alert/error', 'Maximum number of parameters to analyze is 10.', {
                        root: true
                    });
                    return;
                }
                if (!analyze.shipId) {
                    this.$store.dispatch('alert/error', 'No ship selected.', {
                        root: true
                    });
                    return;
                }
                this.ship[index] = this.getShips().filter((o) => o.id === analyze.shipId)[0];
                // copy ship 1 parameters
                analyze.prefix = this.ship[index].perfDb;
                if (index === 0) {
                    analyze.params = this.analyze.params;
                } else if (index === 1) {
                    analyze.params = this.analyze2.params;
                }
                // store parameters to local storage
                localStorage.setItem('perfParams', JSON.stringify(analyze.params));
                this.initialTimes[index].startDate = analyze.startDate;
                this.initialTimes[index].startTime = analyze.startTime;
                this.initialTimes[index].endDate = analyze.endDate;
                this.initialTimes[index].endTime = analyze.endTime;

                this.fetchPerformanceData(analyze).then((data) => {
                    const coords = [];
                    for (const key in data) {
                        if (data[key].time) {
                            data[key].time = moment.utc(data[key].time).toDate();
                        }
                        if (data[key].eventTime) {
                            data[key].eventTime = moment.utc(data[key].eventTime).toDate();
                        }
                        if (data[key].longitude && data[key].latitude) {
                            coords.push([data[key].longitude, data[key].latitude]);
                        }
                        data[key].random = Math.round(Math.random()*30)*3;
                    }
                    if (coords.length > 1) {
                        this.routeGeojson[index] = lineString(coords, {
                            name: 'route'
                        });
                        this.distances[index] = Math.round(turfLength(this.routeGeojson[index], {units: 'kilometers'})/1.852*10)/10;
                        console.log(this.distances[index]);
                    }
                    // Remove old series, except SOG&Trendline
                    this.removeSeries(this.chart[index]);

                    // Remove old hidden yaxis and create new
                    if (this.chart[index].yAxes.hasIndex(4)) {
                        this.chart[index].yAxes.removeIndex(4).dispose();
                    }
                    if (this.chart[index].yAxes.hasIndex(3)) {
                        this.chart[index].yAxes.removeIndex(3).dispose();
                    }
                    if (this.chart[index].yAxes.hasIndex(2)) {
                        this.chart[index].yAxes.removeIndex(2).dispose();
                    }
                    if (this.chart[index].yAxes.hasIndex(1)) {
                        this.chart[index].yAxes.removeIndex(1).dispose();
                    }
                    for (let i = 0; i < 3; i++) {
                        const valueAxis = this.chart[index].yAxes.push(new am4charts.ValueAxis());
                        valueAxis.hidden = true;
                        valueAxis.tooltip.disabled = true;
                        valueAxis.renderer.width = 0;
                        valueAxis.renderer.grid.template.disabled = true;
                        valueAxis.renderer.labels.template.disabled = true;
                    }
                    // Create new selected series
                    let i = 0;
                    for (const key in analyze.params) {
                        if (analyze.params[key] !== 'sog') {
                            const tmpParams = index === 0 ? this.params : this.params2;
                            const paramDef = tmpParams.find((o) => o.param === analyze.params[key]);
                            // Set line color. Special colors for PS and SB
                            let color;
                            switch (analyze.params[key]) {
                                case 'rpm_stb1':
                                    color = '#3CBA54';
                                    break;
                                case 'rpm_ps1':
                                    color = '#DB3236';
                                    break;
                                case 'p_power_stb1':
                                    color = '#006400';
                                    break;
                                case 'p_power_ps1':
                                    color = '#FD5C63';
                                    break;
                                case 'p_torque_stb1':
                                    color = '#228B22';
                                    break;
                                case 'p_torque_ps1':
                                    color = '#BB0000';
                                    break;
                                case 'p_efficiency_stb1':
                                    color = '#32CD32';
                                    break;
                                case 'p_efficiency_ps1':
                                    color = '#FF6347';
                                    break;
                                case 'motion_roll_max':
                                    color = '#00FF00';
                                    break;
                                case 'motion_roll_min':
                                    color = '#FF0000';
                                    break;
                                default:
                                    color = this.colors[i];
                                    break;
                            }

                            if (paramDef) {
                                const seriesParams = {
                                    name: paramDef.name,
                                    dateX: 'time',
                                    unit: paramDef.unit,
                                    valueY: analyze.params[key],
                                    tooltipText: '{name}: {valueY.value} {unit}',
                                    color,
                                    width: 1,
                                    interactions: false
                                };
                                // If TWS and TWD selected create only True wind serie
                                if (analyze.params[key] === 'twd' && analyze.params.includes('tws')) {
                                    continue;
                                }
                                const yAxesIndex = paramDef.yAxis ? paramDef.yAxis : 1;
                                this.createSeries(seriesParams, this.chart[index], this.chart[index].yAxes.getIndex(yAxesIndex), this.chart[index].xAxes.getIndex(0), data);
                                ++i;
                            }
                        }
                    }
                    // add dummy event to fix empty events
                    data.push({
                        id: null,
                        eventTypeId: null,
                        description: null,
                        eventTime: data[0].time,
                        shortName: null,
                        name: null,
                    });
                    if (index === 0) {
                        if (this.chart[0]) {
                            this.chart[0].data = data;
                            this.currentDate = new Date(this.chart[0].data[0].time);
                            if (this.chartPan) {
                                this.bounds[0] = L.latLngBounds(coords);
                            }
                            for (const d of data) {
                                if (d && d.latitude && d.longitude) {
                                    this.marker0 = this.trackShipOnMap({...d}, 0);
                                    break;
                                }
                            }
                        }
                    } else if (index === 1) {
                        if (this.chart[1]) {
                            this.chart[1].data = data;
                            if (this.chartPan2) {
                                this.bounds[1] = L.latLngBounds(coords);
                            }
                            for (const d of data) {
                                if (d && d.latitude && d.longitude) {
                                    this.marker1 = this.trackShipOnMap({...d}, 1);
                                    break;
                                }
                            }
                        }
                    }
                    // Reset slider positions
                    // this.chart[index].scrollbarX.start = 0;
                    // this.chart[index].scrollbarX.end = 1;
                    this.trendSeries[index].data = [{
                        trendValue: null,
                        trendTime: null
                    }];
                    this.trendSeries[index].disabled = true;
                });
                this.getAggregateValues(analyze, index);
                if (this.ship[index] && this.ship[index].logDb) {
                    this.triggers[index] = [];
                    this.fetchTriggers(this.ship[index].logDb).then((data) => {
                        this.triggers[index] = data;
                    })
                }
            },
            createSeries(params, chart, valueAxis, dateAxis, data = null) {
                const series = chart.series.push(new am4charts.LineSeries());
                series.name = params.name;
                series.unit = params.unit;
                series.dataFields.dateX = params.dateX;
                series.dataFields.valueY = params.valueY;
                series.xAxis = dateAxis;
                series.tooltipText = params.tooltipText;
                series.fill = am4core.color(params.color);
                series.stroke = am4core.color(params.color);
                series.strokeWidth = params.width;
                series.segments.template.interactionsEnabled = params.interactions;
                if (params.valueY === 'dpt_trn' || params.valueY === 'dpt' || params.valueY ===
                    'dpt_trn_insa') { // Depth has fill color
                    series.fill = am4core.color('#1e90ff');
                    series.stroke = am4core.color('#1e90ff');
                    series.fillOpacity = 1;
                    const fillModifier = new am4core.LinearGradientModifier();
                    fillModifier.opacities = [0, 0.5];
                    fillModifier.offsets = [0, 1];
                    fillModifier.gradient.rotation = 90;
                    series.segments.template.fillModifier = fillModifier;
                    const vAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    vAxis.hidden = true;
                    vAxis.tooltip.disabled = true;
                    vAxis.renderer.inversed = true;
                    series.yAxis = vAxis;
                } else if (params.valueY === 'events') {
                    series.dataFields.dateX = 'eventTime';
                    series.dataFields.valueY = 'eventTypeId';
                    const circleBullet = series.bullets.push(new am4charts.CircleBullet());
                    const labelBullet = series.bullets.push(new am4charts.LabelBullet());
                    circleBullet.circle.radius = 5;
                    labelBullet.label.text = '{shortName}';
                    labelBullet.label.dy = -10;
                    labelBullet.label.fontSize = 10;
                    series.tooltipText = '{description}';
                    series.fill = am4core.color('#e74c3c');
                    series.stroke = am4core.color('#e74c3c');
                    series.strokeWidth = 0;
                    const vAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    vAxis.hidden = true;
                    vAxis.tooltip.disabled = true;
                    vAxis.extraMax = 0.3;
                    vAxis.extraMin = 0.3;
                    series.yAxis = vAxis;
                } else if (params.valueY === 'logbook') {
                    series.dataFields.dateX = 'eventTime';
                    series.dataFields.valueY = 'random';
                    const circleBullet = series.bullets.push(new am4charts.CircleBullet());
                    const labelBullet = series.bullets.push(new am4charts.LabelBullet());
                    circleBullet.circle.radius = 5;
                    labelBullet.label.text = '{name}';
                    labelBullet.label.dy = -10;
                    labelBullet.label.fontSize = 10;
                    series.tooltipText = '{summary} ({statusText})';
                    series.fill = am4core.color('#3498db');
                    series.stroke = am4core.color('#3498db');
                    series.strokeWidth = 0;
                    const vAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    vAxis.hidden = true;
                    vAxis.tooltip.disabled = true;
                    vAxis.max = 100;
                    vAxis.min = 0;
                    series.yAxis = vAxis;
                } else if (params.valueY === 'tws' && typeof data[0].twd !== 'undefined') {
                    series.name = 'True wind';
                    series.dataFields.dateX = 'time';
                    series.dataFields.valueY = 'tws';
                    series.yAxis = valueAxis;
                    series.xAxis = dateAxis;
                    series.tooltipText = '{name}: {valueY.value}m/s {twd}°';
                    series.fill = am4core.color('#911eb4');
                    series.stroke = am4core.color('#911eb4');
                    series.strokeWidth = 1;
                    // Add a bullet - Wind direction arrows commented out, since not wanted
                    // const windBullet = series.bullets.push(new am4charts.Bullet());
                    // windBullet.propertyFields.rotation = 'twd';
                    // const windArrow = windBullet.createChild(am4core.Triangle);
                    // windArrow.horizontalCenter = 'middle';
                    // windArrow.verticalCenter = 'middle';
                    // windArrow.stroke = am4core.color('#fff');
                    // windArrow.direction = 'bottom';
                    // windArrow.width = 8;
                    // windArrow.height = 15;
                } else {
                    series.yAxis = valueAxis;
                }
                return series;
            },
            removeSeries(chart) {
                for (let i = 0; i <= chart.series.length; i++) {
                    if (chart.series.hasIndex(3) && chart.series.getIndex(3).name !== 'SOG' && chart.series.getIndex(3)
                        .name !== 'Trendline') {
                        chart.series.removeIndex(3).dispose();
                    }
                    if (chart.series.hasIndex(2) && chart.series.getIndex(2).name !== 'SOG' && chart.series.getIndex(2)
                        .name !== 'Trendline') {
                        chart.series.removeIndex(2).dispose();
                    }
                }
            },
            trackShipOnMap(marker, index) {
                if (marker && marker.latitude && marker.longitude) {
                    marker.latLng = L.latLng(marker.latitude, marker.longitude);
                    if (index === 0 && this.chartPan) {
                        this.center0 = L.latLng(marker.latitude, marker.longitude);
                    } else if (index === 1 && this.chartPan2) {
                        this.center1 = L.latLng(marker.latitude, marker.longitude);
                    }
                    marker.rotation = shipHelpers.headingOrCog(marker.heading, marker.cog);
                    const ship = this.ship[index];
                    const prefix = ship.external === 1 ? 'ts' : 'os';
                    const icon = shipHelpers.shipIcon(marker.navstat, prefix, 222222222, null, marker.sog);
                    marker.icon = (
                        L.icon({
                            iconUrl: require(`@/assets/img/ships/${icon}`),
                            iconSize: [11, 30], // size of the icon
                            iconAnchor: [5.5,
                                15
                            ], // point of the icon which will correspond to marker's location
                        })
                    );
                    if (ship) {
                        marker.tooltip = ship.name;
                        marker.a = ship.a;
                        marker.b = ship.b;
                        marker.c = ship.c;
                        marker.d = ship.d;
                        marker.shipname = ship.shortName;
                    }
                    marker.options = {};
                    marker.options.rotationOrigin = 'center center';
                    marker.shipGeojson = shipHelpers.shipGeojson(marker);
                    marker.circles = [];
                    const radiuses = [926, 1852, 2778];
                    for (const radius of radiuses) {
                        marker.circles.push({
                            center: L.latLng(marker.latitude, marker.longitude),
                            radius,
                            color: 'rgba(150, 150, 150, 0.3)',
                            weight: 0.5
                        });
                    }
                    marker.predictor = null;
                    const arrow = shipHelpers.vectorSvg(marker.sog, 'white');
                    if (arrow) {
                        marker.predictor = {
                            latLng: L.latLng(marker.latitude, marker.longitude),
                            direction: shipHelpers.headingOrCog(marker.heading, marker.cog, true),
                            icon: L.icon({
                                iconUrl: arrow.image,
                                iconSize: [8, arrow.height],
                                iconAnchor: [4, arrow.height],
                            }),
                            rotationOrigin: 'center bottom'
                        };
                    }

                    marker.popup = `<b>${ship.name}</b><br/>
                        SOG: ${Math.round(marker.sog * 10) / 10} kn<br/>
                        Draught: ${Math.round(marker.draught * 10) / 10} m<br/>
                        Status: <span class="${shipHelpers.getNavstat(marker.navstat).color}--text">${shipHelpers.getNavstat(marker.navstat).status}</span><br/>
                        Last seen: ${moment.utc(marker.time).format('D.M HH:mm')}<br/>`;

                    if (this.aisTargetsTimeout[index]) {
                        clearTimeout(this.aisTargetsTimeout[index]);
                        this.aisTargetsTimeout[index] = false;
                    }
                    if ((this.aisTargets0 && index === 0) || (this.aisTargets1 && index === 1)) {
                        this.aisTargetsTimeout[index] = setTimeout(() => {
                            this.fetchAisTargets(ship, marker, index);
                        }, 1000);
                    } else if (this.markers[index].length > 0) {
                        this.markers[index] = [];
                        this.geojsons[index] = [];
                    }

                    return marker;
                }
            },
            fetchAisTargets(ship, marker, index) {
                this.markers[index] = [];
                this.geojsons[index] = [];
                this.fetchPerformanceAisTargets({
                    time: marker.time,
                    prefix: ship.perfDb,
                    timezone: index === 0 ? this.analyze.timezone : this.analyze2.timezone
                }).then((data) => {
                    // Other Ais targets
                    const markers = [];
                    const geojsons = [];
                    data = typeof data.vessels !== 'undefined' ? data.vessels : data;
                    if (data && data.length > 0) {
                        for (const entry of data) {
                            if (Array.isArray(entry)) {
                                // map ais array to object
                                entry.mmsi = entry[0];
                                entry.shipname = entry[1];
                                entry.longitude = entry[2];
                                entry.latitude = entry[3];
                                entry.time = entry[4];
                                entry.sog = entry[5];
                                entry.cog = entry[6];
                                entry.heading = entry[7];
                                entry.navstat = entry[8];
                                entry.turn = entry[9];
                                entry.imo = entry[10];
                                entry.shiptype = entry[11];
                                entry.a = entry[12];
                                entry.b = entry[13];
                                entry.c = entry[14];
                                entry.d = entry[15];
                                entry.draught = entry[16];
                                entry.destination = entry[17];
                                entry.eta = entry[18];
                                entry.distance = entry[19];
                            }
                            if (entry.latitude && entry.longitude && entry.mmsi !== ship.mmsi) {
                                // Ship predictor arrow marker
                                const arrow = shipHelpers.vectorSvg(entry.sog, 'yellow');
                                if (arrow) {
                                    markers.push({
                                        latLng: L.latLng(entry.latitude, entry.longitude),
                                        direction: shipHelpers.headingOrCog(parseInt(entry.heading),
                                            parseInt(entry.cog)),
                                        icon: L.icon({
                                            iconUrl: arrow.image,
                                            iconSize: [8, arrow.height],
                                            iconAnchor: [4, arrow.height],
                                        }),
                                        rotationOrigin: 'center bottom'
                                    });
                                }
                                // ship marker
                                const icon = shipHelpers.shipIcon(entry.navstat, 'ts', entry.mmsi, entry
                                    .shipType, entry.sog);
                                const size = {};
                                size.x = 11;
                                size.y = 30;
                                if (entry.mmsi.toString().substring(0, 2) === '99' || entry.mmsi.toString()
                                    .substring(0, 2) === '00') {
                                    size.x = 20;
                                    size.y = 20;
                                }
                                markers.push({
                                    latLng: L.latLng(entry.latitude, entry.longitude),
                                    direction: shipHelpers.headingOrCog(parseInt(entry.heading),
                                        parseInt(entry.cog)),
                                    icon: L.icon({
                                        iconUrl: require(`@/assets/img/ships/${icon}`),
                                        iconSize: [size.x, size.y],
                                        iconAnchor: [size.x / 2, size.y / 2],
                                    }),
                                    tooltip: `<small>${entry.shipname}<small>`,
                                    popup: shipHelpers.shipPopup(entry, false)
                                });
                                // ship geojson
                                geojsons.push({
                                    geojson: shipHelpers.shipGeojson(entry),
                                    options: {
                                        style: {
                                            color: '#000000',
                                            weight: 1,
                                            opacity: 0.6
                                        }
                                    }
                                });
                                // Set multidimensional array reactive property
                                this.$set(this.markers, index, markers);
                                this.$set(this.geojsons, index, geojsons);
                            }
                        }
                    }
                });
            },
            random() {
                return Math.random();
            },
            getNavstat(navstat) {
                return shipHelpers.getNavstat(navstat);
            },
            sliceData(start, end, data, index) {
                const zoomedData = [];
                const coordsLatLon = [];
                const coordsLonLat = [];
                for (const key in data) {
                    if (data.hasOwnProperty(key) && data[key].time) {
                        if (Math.round(moment.utc(data[key].time).unix() / 600) >= Math.round(moment.utc(start).unix() /
                                600)) {
                            zoomedData.push(data[key]);
                            if (data[key].latitude && data[key].longitude) {
                                coordsLonLat.push([data[key].longitude, data[key].latitude]);
                            }
                            if (Math.round(moment.utc(data[key].time).unix() / 600) >= Math.round(moment.utc(end)
                                    .unix() /
                                    600)) {
                                break;
                            }
                        }
                    }
                    if (data[key].latitude && data[key].longitude) {
                        coordsLatLon.push([data[key].latitude, data[key].longitude]);
                    }
                }
                // Set bound based on sliced data
                if (coordsLatLon && coordsLatLon.length > 0) {
                    if (this.chartPan && index === 1 && coordsLonLat.length > 1) {
                        this.bounds[0] = L.latLngBounds(coordsLatLon);
                    } else if (this.chartPan2 && index === 2 && coordsLonLat.length > 1) {
                        this.bounds[1] = L.latLngBounds(coordsLatLon);
                    }
                }
                // Show sliced track on map
                if (coordsLonLat.length > 1) {
                    this.routeGeojson[index] = lineString(coordsLonLat, {
                        name: 'route'
                    });
                    this.distances[index] = Math.round(turfLength(this.routeGeojson[index], {units: 'kilometers'})/1.852*10)/10;
                    console.log(this.distances[index]);

                }
                return zoomedData;
            },
            resetZoomedData(data, index) {
                const coordsLatLon = [];
                const coordsLonLat = [];
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (data[key].time && data[key].latitude && data[key].longitude) {
                            coordsLonLat.push([data[key].longitude, data[key].latitude]);
                        }
                        if (data[key].latitude && data[key].longitude) {
                            coordsLatLon.push([data[key].latitude, data[key].longitude]);
                        }
                    }
                }
                if (coordsLonLat.length > 1) {
                    // Set bound based on sliced data
                    this.bounds[index] = L.latLngBounds(coordsLatLon);
                    // Show sliced track on map
                    this.routeGeojson[index] = lineString(coordsLonLat, {
                        name: 'route'
                    });
                    this.distances[index] = Math.round(turfLength(this.routeGeojson[index], {units: 'kilometers'})/1.852*10)/10;
                    console.log(this.distances[index]);
                }
            },
            trendLinePoints(data) {
                const sogs = [];
                const times = [];
                for (const key in data) {
                    if (data[key].time && data[key].sog != undefined) {
                        sogs.push(data[key].sog);
                        times.push(moment.utc(data[key].time).unix());
                    }
                }
                const linear = tools.linearRegression(sogs, times); // (Y = slope * X + intercept).
                return [{
                    trendValue: Math.round((linear.slope * times[0] + linear.intercept) * 100) / 100,
                    trendTime: moment.unix(times[0]).toDate()
                }, {
                    trendValue: Math.round((linear.slope * times[times.length - 1] + linear.intercept) * 100) /
                        100,
                    trendTime: moment.unix(times[times.length - 1]).toDate()
                }];
            },
            displayTripName(item) {
                return `${item.name}: ${moment.utc(item.date_start * 1000).utcOffset(this.analyze.timezone).format('DD.MM HH:mm')} - ${moment.utc(
                    item.date_end * 1000
).utcOffset(this.analyze.timezone).format('DD.MM HH:mm')}`;
            },
            displayTripName2(item) {
                return `${item.name}: ${moment.utc(item.date_start * 1000).utcOffset(this.analyze2.timezone).format('DD.MM HH:mm')} - ${moment.utc(
                    item.date_end * 1000
).utcOffset(this.analyze2.timezone).format('DD.MM HH:mm')}`;
            },
            updateDatesByTrip(value) {
                if (value) {
                    this.analyze.startDate = moment.utc(value.date_start * 1000).utcOffset(this.analyze.timezone).format('YYYY-MM-DD');
                    this.analyze.startTime = moment.utc(value.date_start * 1000).utcOffset(this.analyze.timezone).format('HH:mm');
                    this.analyze.endDate = moment.utc(value.date_end * 1000).utcOffset(this.analyze.timezone).format('YYYY-MM-DD');
                    this.analyze.endTime = moment.utc(value.date_end * 1000).utcOffset(this.analyze.timezone).format('HH:mm');
                } else {
                    this.analyze.startDate = moment.utc().utcOffset(this.analyze.timezone).subtract(1, 'days').format('YYYY-MM-DD');
                    this.analyze.startTime = '00:00';
                    this.analyze.endDate = moment.utc().utcOffset(this.analyze.timezone).format('YYYY-MM-DD');
                    this.analyze.endTime = moment.utc().utcOffset(this.analyze.timezone).format('HH:mm');
                }
            },
            updateDatesByTrip2(value) {
                if (value) {
                    this.analyze2.startDate = moment.utc(value.date_start * 1000).utcOffset(this.analyze2.timezone).format('YYYY-MM-DD');
                    this.analyze2.startTime = moment.utc(value.date_start * 1000).utcOffset(this.analyze2.timezone).format('HH:mm');
                    this.analyze2.endDate = moment.utc(value.date_end * 1000).utcOffset(this.analyze2.timezone).format('YYYY-MM-DD');
                    this.analyze2.endTime = moment.utc(value.date_end * 1000).utcOffset(this.analyze2.timezone).format('HH:mm');
                } else {
                    this.analyze2.trip = {};
                    this.analyze2.startDate = moment.utc().utcOffset(this.analyze.timezone).subtract(1, 'days').format('YYYY-MM-DD');
                    this.analyze2.startTime = '00:00';
                    this.analyze2.endDate = moment.utc().utcOffset(this.analyze.timezone).format('YYYY-MM-DD');
                    this.analyze2.endTime = moment.utc().utcOffset(this.analyze.timezone).format('HH:mm');
                }
            },
            updateTripsAndParams(shipId) {
                // store shipId to local storage
                localStorage.setItem('perfShipId', shipId);
                const ship = this.ships.find((o) => o.id === shipId);
                this.fetchTrips(ship.perfDb).then((data) => {
                    this.trips = data;
                });
                this.paramSets = ship.paramSet && ship.paramSet.length > 0 ? ship.paramSet : [];
                this.paramSet = {};
                // try {
                //     this.paramSet = JSON.parse(localStorage.getItem('perfParamSet'));
                // } catch (e) {
                //     console.warn(e);
                // }
                if (!this.showChart2) {
                    this.paramSets2 = this.paramSets;
                }
                // Get parameter group values
                this.fetchPerformanceParams({
                    prefix: ship.perfDb
                }).then((data) => {
                    this.analyze.params = ['sog'];
                    if (!this.paramsLoaded) {
                        try {
                            const storageParams = JSON.parse(localStorage.getItem('perfParams'));
                            this.analyze.params = storageParams && storageParams.length > 1 ? storageParams : ['sog'];
                        } catch (e) {
                            this.analyze.params = ['sog'];
                            console.warn(e);
                        }
                        this.paramsLoaded = true;
                    }
                    this.params = data;
                    this.paramGroups = {};
                    for (const key in this.params) {
                        if (this.params.hasOwnProperty(key)) {
                            // Check that user has logbook rights for this ship
                            if (this.params[key].param === 'logbook' && !this.user.user.logbooks.includes(ship.logDb)) {
                                continue;
                            }
                            if (!Array.isArray(this.paramGroups[this.params[key].group])) {
                                this.paramGroups[this.params[key].group] = [];
                            }
                            this.paramGroups[this.params[key].group].push(this.params[key]);
                        }
                    }
                });
            },
            updateTripsAndParams2(shipId) {
                // store shipId to local storage
                const ship = this.ships.find((o) => o.id === shipId);
                this.fetchTrips(ship.perfDb).then((data) => {
                    this.trips2 = data;
                });
                this.paramSets2 = ship.paramSet && ship.paramSet.length > 0 ? ship.paramSet : [];
                this.paramSet2 = {};

                this.fetchPerformanceParams({
                    prefix: ship.perfDb
                }).then((data) => {
                    this.analyze2.params = ['sog'];
                    this.params2 = data;
                    this.paramGroups2 = {};
                    for (const key in this.params2) {
                        if (this.params2.hasOwnProperty(key)) {
                            if (!Array.isArray(this.paramGroups2[this.params2[key].group])) {
                                this.paramGroups2[this.params2[key].group] = [];
                            }
                            this.paramGroups2[this.params2[key].group].push(this.params2[key]);
                        }
                    }
                });
            },
            toggleGroup(group, index) {
                let pushAtLeastOne = false;
                if (index === 0) {
                    for (const i of group) {
                        if (!this.analyze.params.includes(i.param) && i.param !== 'sog') {
                            this.analyze.params.push(i.param);
                            pushAtLeastOne = true;
                        }
                    }
                    if (!pushAtLeastOne) { // remove all
                        for (const i of group) {
                            const pIndex = this.analyze.params.indexOf(i.param);
                            if (pIndex !== -1 && i.param !== 'sog') {
                                this.analyze.params.splice(pIndex, 1);
                            }
                        }
                    }
                } else {
                    for (const i of group) {
                        if (!this.analyze2.params.includes(i.param) && i.param !== 'sog') {
                            this.analyze2.params.push(i.param);
                            pushAtLeastOne = true;
                        }
                    }
                    if (!pushAtLeastOne) { // remove all
                        for (const i of group) {
                            const pIndex = this.analyze2.params.indexOf(i.param);
                            if (pIndex !== -1 && i.param !== 'sog') {
                                this.analyze2.params.splice(pIndex, 1);
                            }
                        }
                    }
                }
            },
            setParamSet(index) {
                // localStorage.setItem('perfParamSet', JSON.stringify(this.paramSet));
                if (index === 0) {
                    if (this.paramSet) {
                        this.analyze.params = ['sog', ...this.paramSet.params];
                    } else {
                        this.paramSet = {};
                        this.analyze.params = ['sog'];
                    }
                } else if (this.paramSet2) {
                    this.analyze2.params = ['sog', ...this.paramSet2.params];
                } else {
                    this.paramSet2 = {};
                    this.analyze2.params = ['sog'];
                }
            },
            clearChart() {
                this.analyze.startDate = moment.utc().utcOffset(this.analyze.timezone).subtract(1, 'days').format('YYYY-MM-DD');
                this.analyze.startTime = '00:00';
                this.analyze.endDate = moment.utc().utcOffset(this.analyze.timezone).format('YYYY-MM-DD');
                this.analyze.endTime = moment.utc().utcOffset(this.analyze.timezone).format('HH:mm');
                this.analyze.params = ['sog'];
                this.paramSet = {};
                this.analyze.trip = {};

                this.analyze2.startDate = moment.utc().utcOffset(this.analyze.timezone).subtract(1, 'days').format('YYYY-MM-DD');
                this.analyze2.startTime = '00:00';
                this.analyze2.endDate = moment.utc().utcOffset(this.analyze.timezone).format('YYYY-MM-DD');
                this.analyze2.endTime = moment.utc().utcOffset(this.analyze.timezone).format('HH:mm');
                this.analyze2.params = ['sog'];
                this.paramSet2 = {};
                this.analyze.trip2 = {};

                if (this.chart[0] && this.chart[0].data) {
                    this.chart[0].data = [];
                }
                if (this.chart[1] && this.chart[1].data) {
                    this.chart[1].data = [];
                }
            },
            getAggregateValues(analyze, index) {
                if (index === 0) {
                    analyze.filters = this.aggregateFilters;
                } else if (index === 1) {
                    analyze.filters = this.aggregateFilters2;
                }
                this.fetchAggregateValues(analyze).then((data) => {
                    const aggregateValues = [];
                    for (const p of analyze.params) {
                        const param = this.params.find((o) => o.param === p);
                        if (data && param && (param.param !== 'events' && param.param !== 'logbook' && (param.param === 'head_wind' || param.param === 'fuelEstMePs' || param
                                .param === 'fuelEstMeStb' || param.param === 'fuelEstMeTotal' || param.param === 'fuel_flow_me_total' || param
                                .param === 'fuel_flow_total' || param.param === 'fuelFlowMeTotal' || param.param === 'fuelFlowTotal' || param
                                .source !== 'Aggregate'))) {
                            if (Array.isArray(analyze.filters) && analyze.filters.length > 0) { // Remove sum values when filters are used, since they are not correct sum values
                                aggregateValues.push({
                                    name: param.name,
                                    unit: param.unit,
                                    min: data[`${p}_min`],
                                    max: data[`${p}_max`],
                                    avg: data[`${p}_avg`],
                                });
                            } else {
                                aggregateValues.push({
                                    name: param.name,
                                    unit: param.unit,
                                    min: data[`${p}_min`],
                                    max: data[`${p}_max`],
                                    avg: data[`${p}_avg`],
                                    sum: data[`${p}_sum`],
                                    sumUnit: data[`${p}_sumUnit`],
                                });
                            }     
                        }
                    }
                    if (index === 0) {
                        this.aggregateValues = aggregateValues;
                        this.aggregateHeaders[4].text = `Sum${this.toHoursMinutes(this.analyze.startDate, this
                            .analyze.startTime, this.analyze.endDate, this.analyze.endTime)}`;
                    } else if (index === 1) {
                        this.aggregateValues2 = aggregateValues;
                        this.aggregateHeaders2[4].text = `Sum${this.toHoursMinutes(this.analyze2.startDate,
                            this.analyze2.startTime, this.analyze2.endDate, this.analyze2.endTime)}`;
                    }
                });
            },
            toHoursMinutes(startDate, startTime, endDate, endTime) {
                const end = moment.utc(`${endDate}T${endTime}`);
                const start = moment.utc(`${startDate}T${startTime}`);
                const hours = end.diff(start, 'hours');
                const mins = end.diff(start, 'minutes') - 60 * hours;
                return ` (${hours}h ${mins}m)`;
            },
            saveTimezone(timezone) {
                const prevTimezone = localStorage.getItem('timezone') ? parseInt(localStorage.getItem('timezone')) : 0;
                localStorage.setItem('timezone', timezone);
                const start = moment.utc(`${this.analyze.startDate} ${this.analyze.startTime}`).utcOffset(timezone - prevTimezone);
                const end = moment.utc(`${this.analyze.endDate} ${this.analyze.endTime}`).utcOffset(timezone - prevTimezone);
                this.analyze.startDate = start.format('YYYY-MM-DD');
                this.analyze.startTime = start.format('HH:mm');
                this.analyze.endDate = end.format('YYYY-MM-DD');
                this.analyze.endTime = end.format('HH:mm');
            },
            saveTimezone2(timezone) {
                const prevTimezone = localStorage.getItem('timezone') ? parseInt(localStorage.getItem('timezone2')) : 0;
                localStorage.setItem('timezone2', timezone);
                const start = moment.utc(`${this.analyze2.startDate} ${this.analyze2.startTime}`).utcOffset(timezone - prevTimezone);
                const end = moment.utc(`${this.analyze2.endDate} ${this.analyze2.endTime}`).utcOffset(timezone - prevTimezone);
                this.analyze2.startDate = start.format('YYYY-MM-DD');
                this.analyze2.startTime = start.format('HH:mm');
                this.analyze2.endDate = end.format('YYYY-MM-DD');
                this.analyze2.endTime = end.format('HH:mm');
            },
            createCharts() {
                /** ***************************** */
                /** Chart 1 first ship         * */
                /** ***************************** */
                const container = am4core.create(this.$refs.chartdiv, am4core.Container);
                container.width = am4core.percent(100);
                container.height = am4core.percent(100);

                container.tooltip = new am4core.Tooltip();
                container.tooltip.background.fill = am4core.color("#000000");
                container.tooltip.background.stroke = am4core.color('#3498db');
                container.tooltip.fontSize = "0.9em";
                container.tooltip.getFillFromObject = false;
                container.tooltip.getStrokeFromObject = false;

                // Chart container
                const chartContainer = container.createChild(am4core.Container);
                chartContainer.width = am4core.percent(100);
                chartContainer.height = am4core.percent(92);
                chartContainer.padding(0, 15, 15, 0);
                chartContainer.valign = "bottom";
                chartContainer.layout = "vertical";

                let sliderAnimation;
                
                 // Slider container
                const sliderContainer = container.createChild(am4core.Container);
                sliderContainer.width = am4core.percent(100);
                sliderContainer.height = am4core.percent(8);
                sliderContainer.padding(0, 15, 15, 0);
                sliderContainer.valign = "top";
                sliderContainer.layout = "horizontal";

                // play button
                const playButton = sliderContainer.createChild(am4core.PlayButton);
                playButton.valign = "middle";
                playButton.marginLeft = 5;

                const slider = sliderContainer.createChild(am4core.Slider);
                slider.width = am4core.percent(100);
                slider.valign = "middle";
                slider.background.opacity = 0.4;
                slider.opacity = 0.7;
                slider.background.fill = am4core.color("#3498db");
                slider.marginLeft = 20;
                slider.marginTop = 20;
                slider.marginRight = 20;
                slider.height = 15;
                slider.start = 1;

                const chart = chartContainer.createChild(am4charts.XYChart);
                chart.dateFormatter.utc = true;
                chart.paddingRight = 20;
                chart.paddingLeft = 30;
                chart.data = [];

                // what to do when slider is dragged
                slider.events.on("rangechanged", function() {
                    if (this && this.chart && this.chart[0]) {
                        var index = Math.round((this.chart[0].data.length - 1) * slider.start);
                        updatePosition(index, this);
                    }
                }, this)

                // stop animation if dragged
                slider.startGrip.events.on("drag", () => {
                    stop();
                    if (sliderAnimation) {
                        sliderAnimation.setProgress(slider.start);
                    }
                });

                function updatePosition(index, vm) {
                    if (!isNaN(index) && vm) {
                        var di = vm.chart[0].data[index];
                        if (!di) {
                            return;
                        }
                        var date = new Date(di.time);
                        vm.currentDate = date;

                        var position = dateAxis.dateToPosition(date);
                        position = dateAxis.toGlobalPosition(position);
                        var x = dateAxis.positionToCoordinate(position);

                        if (chart.cursor) {
                            chart.cursor.triggerMove({ x: x, y: 0 }, "soft", true);
                        }
                        vm.currentIndex = index;
                    }
                }

                container.events.on("layoutvalidated", function() {
                    dateAxis.tooltip.hide();
                    chart.cursor.hide();
                    if (this.currentIndex) {
                        updatePosition(this.currentIndex, this);
                    }
                }, this);

                // play button behavior
                playButton.events.on("toggled", function(event) {
                    if (event.target.isActive) {
                        play(50000, false);
                    } else {
                        stop();
                    }
                }, this)

                // make slider grip look like play button
                slider.startGrip.background.fill = playButton.background.fill;
                slider.startGrip.background.strokeOpacity = 0;
                slider.startGrip.icon.stroke = am4core.color("#ffffff");
                slider.startGrip.background.states.copyFrom(playButton.background.states);

                // play behavior
                function play(duration, start) {
                    if (start && sliderAnimation) {
                        sliderAnimation.stop();
                        sliderAnimation = null;
                    }
                    if (!sliderAnimation) {
                        console.log('Animation duration ' + Math.round(duration/1000) + ' secs');
                        sliderAnimation = slider.animate({ property: "start", to: 1, from: 0 }, duration, am4core.ease.linear).pause();
                        sliderAnimation.events.on("animationended", () => {
                            playButton.isActive = false;
                        })
                    }

                    if (slider.start >= 1) {
                        slider.start = 0;
                        sliderAnimation.start();
                    }
                    sliderAnimation.resume();
                    playButton.isActive = true;
                }

                // stop behavior
                function stop() {
                    if (sliderAnimation) {
                        sliderAnimation.pause();
                    }
                    playButton.isActive = false;
                }

                // Playback duration custom menu
                chart.exporting.menu = new am4core.ExportMenu();
                chart.exporting.menu.align = "right";
                chart.exporting.menu.verticalAlign = "bottom";
                chart.exporting.menu.items = [{
                    label: '...',
                    menu: []
                }];
                chart.exporting.menu.items[0].menu.push({
                    label: "1x playback",
                    type: "custom",
                    options: {
                        callback: function() {
                            const data = chart.data.filter(o => o.time);
                            const duration = data[data.length-1].time.getTime() - data[0].time.getTime();
                            play(duration, true);
                        }
                    }
                });
                chart.exporting.menu.items[0].menu.push({
                    label: "10x playback",
                    type: "custom",
                    options: {
                        callback: function() {
                            const data = chart.data.filter(o => o.time);
                            const duration = Math.round((data[data.length-1].time.getTime() - data[0].time.getTime())/10);
                            play(duration, true);
                        }
                    }
                });
                chart.exporting.menu.items[0].menu.push({
                    label: "100x playback",
                    type: "custom",
                    options: {
                        callback: function() {
                            const data = chart.data.filter(o => o.time);
                            const duration = Math.round((data[data.length-1].time.getTime() - data[0].time.getTime())/100);
                            play(duration, true);
                        }
                    }
                });
                chart.exporting.menu.items[0].menu.push({
                    label: "1000x playback",
                    type: "custom",
                    options: {
                        callback: function() {
                            const data = chart.data.filter(o => o.time);
                            const duration = Math.round((data[data.length-1].time.getTime() - data[0].time.getTime())/1000);
                            play(duration, true);
                        }
                    }
                });
                chart.exporting.menu.items[0].menu.push({
                    label: "10000x playback",
                    type: "custom",
                    options: {
                        callback: function() {
                            const data = chart.data.filter(o => o.time);
                            const duration = Math.round((data[data.length-1].time.getTime() - data[0].time.getTime())/10000);
                            play(duration, true);
                        }
                    }
                });
                chart.exporting.menu.items[0].menu.push({
                    label: "Record screen",
                    type: "custom",
                    options: {
                        callback: async function() {
                            let stream = await navigator.mediaDevices.getDisplayMedia({
                                video: true,
                                preferCurrentTab: true,
                            })

                            //needed for better browser support
                            const mime = MediaRecorder.isTypeSupported("video/webm; codecs=vp9") ? "video/webm; codecs=vp9" : "video/webm";
                            let mediaRecorder = new MediaRecorder(stream, {
                                mimeType: mime
                            });

                            let chunks = []
                            mediaRecorder.addEventListener('dataavailable', function(e) {
                                chunks.push(e.data)
                            });

                            mediaRecorder.addEventListener('stop', function(){
                                let blob = new Blob(chunks, {
                                    type: chunks[0].type
                                });
                                let url = URL.createObjectURL(blob);

                                let video = document.querySelector("video");
                                video.src = url;

                                let a = document.createElement('a');
                                a.href = url;
                                a.download = 'video.webm';
                                a.click();
                            })
                            //we have to start the recorder manually
                            mediaRecorder.start();
                        }
                    }
                });

                const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                dateAxis.tooltipDateFormat = 'dd.MM HH:mm:ss';
                dateAxis.renderer.grid.template.location = 0;

                const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.tooltip.disabled = true;
                valueAxis.renderer.labels.template.fill = am4core.color('#e59165');
                valueAxis.renderer.width = 30;
                valueAxis.min = 0;
                valueAxis.extraMax = 0.1;
                valueAxis.maxZoomFactor = 10;

                const series = this.createSeries(this.sogParams, chart, valueAxis, dateAxis);

                this.trendSeries[0] = chart.series.push(new am4charts.LineSeries());
                this.trendSeries[0].name = 'Trendline';
                this.trendSeries[0].dataFields.dateX = 'trendTime';
                this.trendSeries[0].dataFields.valueY = 'trendValue';
                this.trendSeries[0].yAxis = valueAxis;
                this.trendSeries[0].xAxis = dateAxis;
                this.trendSeries[0].fill = am4core.color('#e59165');
                this.trendSeries[0].stroke = am4core.color('#e59165');
                this.trendSeries[0].strokeDasharray = '6 3';
                this.trendSeries[0].strokeWidth = 2;
                this.trendSeries[0].data = [{
                    trendValue: null,
                    trendTime: null
                }];
                this.trendSeries[0].disabled = true;

                chart.cursor = new am4charts.XYCursor();
                chart.cursor.xAxis = dateAxis;

                // let scrollbarX = new am4charts.XYChartScrollbar();
                // scrollbarX.series.push(series);
                // chart.scrollbarX = scrollbarX;
                // chart.scrollbarX.startGrip.disabled = true;
                // chart.scrollbarX.endGrip.disabled = true;

                chart.legend = new am4charts.Legend();
                chart.legend.zIndex = 100;
                chart.legend.opacity = 0.8;
                chart.legend.useDefaultMarker = true;
                const markerTemplate = chart.legend.markers.template;
                markerTemplate.width = 12;
                markerTemplate.height = 12;
                chart.legend.itemContainers.template.paddingTop = 0;
                chart.legend.itemContainers.template.paddingBottom = 0;
                chart.legend.position = 'bottom';

                dateAxis.renderer.grid.template.strokeOpacity = 0.07;
                valueAxis.renderer.grid.template.strokeOpacity = 0.07;

                this.chart.push(chart);

                // Track ship on map based on lineSeries hover
                series.segments.template.events.on('over', (ev) => {
                    if (this.chartTrackType === 'lineSeries') {
                        const marker = ev.target.dataItem.component.tooltipDataItem.dataContext;
                        if (marker) {
                            this.marker0 = this.trackShipOnMap({
                                ...marker
                            }, 0);
                        }
                    }
                }, this);

                // Track ship on map based on cursor position
                series.tooltip.events.on('positionchanged', function(ev) {
                    if (this.chartTrackType === 'cursor') {
                        const marker = ev.target.dataItem.dataContext;
                        if (marker) {
                            this.marker0 = this.trackShipOnMap(marker, 0);
                        }
                    }
                }, this);

                // Zoom map to extent on chart zoomed data timeframe
                chart.cursor.events.on('zoomended', function(ev) {
                    const range = ev.target.xRange;
                    const axis = ev.target.chart.xAxes.getIndex(0);
                    if (range && range.start && range.end) {
                        const start = axis.positionToDate(axis.toAxisPosition(range.start));
                        const end = axis.positionToDate(axis.toAxisPosition(range.end));
                        const zoomedData = this.sliceData(start, end, this.chart[0].data, 1);
                        // Show trendline based on zoomed data
                        const trendLineData = this.trendLinePoints(zoomedData);
                        this.trendSeries[0].disabled = false;
                        this.trendSeries[0].data = trendLineData;
                        // Update date & time pickers on zoom
                        this.analyze.startDate = moment.utc(start).format('YYYY-MM-DD');
                        this.analyze.startTime = moment.utc(start).format('HH:mm');
                        this.analyze.endDate = moment.utc(end).format('YYYY-MM-DD');
                        this.analyze.endTime = moment.utc(end).format('HH:mm');
                        this.getAggregateValues(this.analyze, 0);
                    }
                }, this);

                // // Same on scrollbar (start, end values are not working so data is reseted only)
                // chart.scrollbarX.events.on("rangechanged", function () {
                //     if (this.timeoutFired) {
                //         this.timeoutFired = false;
                //         setTimeout(() => {
                //             this.resetZoomedData(this.chart[0].data, 1);
                //             this.timeoutFired = true;
                //         }, 700);
                //     }
                // }, this);

                // Reset data on zoomout button
                chart.zoomOutButton.events.on('hit', function() {
                    this.resetZoomedData(this.chart[0].data, 1);
                    this.trendSeries[0].data = [{
                        trendValue: null,
                        trendTime: null
                    }];
                    this.trendSeries[0].disabled = true;
                    this.analyze.startDate = this.initialTimes[0].startDate;
                    this.analyze.startTime = this.initialTimes[0].startTime;
                    this.analyze.endDate = this.initialTimes[0].endDate;
                    this.analyze.endTime = this.initialTimes[0].endTime;
                    this.getAggregateValues(this.analyze, 0);
                }, this);

                /** ************************* */
                /** Chart 2   Second ship  * */
                /** ************************* */

                const chart2 = am4core.create(this.$refs.chartdiv2, am4charts.XYChart);
                chart2.dateFormatter.utc = true;
                chart2.paddingRight = 20;
                chart2.paddingLeft = 30;
                chart2.data = [];

                const c2dateAxis = chart2.xAxes.push(new am4charts.DateAxis());
                c2dateAxis.tooltipDateFormat = 'dd.MM HH:mm:ss';
                c2dateAxis.renderer.grid.template.location = 0;

                const c2valueAxis = chart2.yAxes.push(new am4charts.ValueAxis());
                c2valueAxis.tooltip.disabled = true;
                c2valueAxis.renderer.labels.template.fill = am4core.color('#e59165');
                c2valueAxis.renderer.width = 30;
                c2valueAxis.min = 0;
                c2valueAxis.extraMax = 0.1;
                c2valueAxis.maxZoomFactor = 10;

                const c2valueAxis2 = chart2.yAxes.push(new am4charts.ValueAxis());
                c2valueAxis2.tooltip.disabled = true;
                c2valueAxis2.renderer.grid.template.strokeDasharray = '2,3';
                c2valueAxis2.renderer.labels.template.fill = am4core.color('#dfcc64');
                c2valueAxis2.renderer.width = 30;
                c2valueAxis2.renderer.opposite = true;
                c2valueAxis2.hidden = true;

                const c2valueAxis3 = chart2.yAxes.push(new am4charts.ValueAxis());
                c2valueAxis3.hidden = true;
                c2valueAxis3.tooltip.disabled = true;

                const c2valueAxis4 = chart2.yAxes.push(new am4charts.ValueAxis());
                c2valueAxis4.hidden = true;
                c2valueAxis4.tooltip.disabled = true;

                const c2series = this.createSeries(this.sogParams, chart2, c2valueAxis, c2dateAxis);

                this.trendSeries[1] = chart2.series.push(new am4charts.LineSeries());
                this.trendSeries[1].name = 'Trendline';
                this.trendSeries[1].dataFields.dateX = 'trendTime';
                this.trendSeries[1].dataFields.valueY = 'trendValue';
                this.trendSeries[1].yAxis = c2valueAxis;
                this.trendSeries[1].xAxis = c2dateAxis;
                this.trendSeries[1].fill = am4core.color('#e59165');
                this.trendSeries[1].stroke = am4core.color('#e59165');
                this.trendSeries[1].strokeDasharray = '6 3';
                this.trendSeries[1].strokeWidth = 2;
                this.trendSeries[1].data = [{
                    trendValue: null,
                    trendTime: null
                }];
                this.trendSeries[1].disabled = true;

                chart2.cursor = new am4charts.XYCursor();
                chart2.cursor.xAxis = c2dateAxis;

                // let c2scrollbarX = new am4charts.XYChartScrollbar();
                // c2scrollbarX.series.push(c2series);
                // chart2.scrollbarX = c2scrollbarX;
                // chart2.scrollbarX.startGrip.disabled = true;
                // chart2.scrollbarX.endGrip.disabled = true;

                chart2.legend = new am4charts.Legend();
                chart2.legend.zIndex = 100;
                chart2.legend.opacity = 0.8;
                chart2.legend.useDefaultMarker = true;
                const markerTemplate2 = chart2.legend.markers.template;
                markerTemplate2.width = 12;
                markerTemplate2.height = 12;
                chart2.legend.itemContainers.template.paddingTop = 0;
                chart2.legend.itemContainers.template.paddingBottom = 0;
                chart2.legend.position = 'bottom';

                c2valueAxis2.renderer.grid.template.strokeOpacity = 0.07;
                c2dateAxis.renderer.grid.template.strokeOpacity = 0.07;
                c2valueAxis.renderer.grid.template.strokeOpacity = 0.07;

                this.chart.push(chart2);

                // Track ship on map based on lineSeries hover
                c2series.segments.template.events.on('over', (ev) => {
                    if (this.chartTrackType2 === 'lineSeries') {
                        const marker = ev.target.dataItem.component.tooltipDataItem.dataContext;
                        if (marker) {
                            this.marker1 = this.trackShipOnMap({
                                ...marker
                            }, 1);
                        }
                    }
                }, this);

                // Track ship on map based on cursor position
                c2series.tooltip.events.on('positionchanged', function(ev) {
                    if (this.chartTrackType2 === 'cursor') {
                        const marker = ev.target.dataItem.dataContext;
                        if (marker) {
                            this.marker1 = this.trackShipOnMap({
                                ...marker
                            }, 1);
                        }
                    }
                }, this);

                // Zoom map to extent on chart zoomed data timeframe
                chart2.cursor.events.on('zoomended', function(ev) {
                    const range = ev.target.xRange;
                    const axis = ev.target.chart.xAxes.getIndex(0);
                    if (range && range.start && range.end) {
                        const start = axis.positionToDate(axis.toAxisPosition(range.start));
                        const end = axis.positionToDate(axis.toAxisPosition(range.end));
                        const zoomedData = this.sliceData(start, end, this.chart[1].data, 2);
                        // Show trendline based on zoomed data
                        const trendLineData = this.trendLinePoints(zoomedData);
                        this.trendSeries[1].disabled = false;
                        this.trendSeries[1].data = trendLineData;
                        // Update date & time pickers on zoom
                        this.analyze2.startDate = moment.utc(start).format('YYYY-MM-DD');
                        this.analyze2.startTime = moment.utc(start).format('HH:mm');
                        this.analyze2.endDate = moment.utc(end).format('YYYY-MM-DD');
                        this.analyze2.endTime = moment.utc(end).format('HH:mm');
                        this.getAggregateValues(this.analyze2, 1);
                    }
                }, this);

                // // Same on scrollbar (start, end values are not working so data is reseted only)
                // chart2.scrollbarX.events.on("rangechanged", function () {
                //     if (this.timeoutFired) {
                //         this.timeoutFired = false;
                //         setTimeout(() => {
                //             this.resetZoomedData(this.chart[1].data, 2);
                //             this.timeoutFired = true;
                //         }, 700);
                //     }
                // }, this);

                // Reset data on zoomout button
                chart2.zoomOutButton.events.on('hit', function() {
                    this.resetZoomedData(this.chart[1].data, 2);
                    this.trendSeries[1].data = [{
                        trendValue: null,
                        trendTime: null
                    }];
                    this.trendSeries[1].disabled = true;
                    this.analyze2.startDate = this.initialTimes[1].startDate;
                    this.analyze2.startTime = this.initialTimes[1].startTime;
                    this.analyze2.endDate = this.initialTimes[1].endDate;
                    this.analyze2.endTime = this.initialTimes[1].endTime;
                    this.getAggregateValues(this.analyze2, 1);
                }, this);
            },
            async exportToExcel() {
                const data = this.chart[0].data;
                if (data && data.length > 0) {
                    const rows = tools.formatExcelData(data);
                    const columns = [{width: 20}];
                    await writeXlsxFile(rows, {
                        columns,
                        fileName: `${moment.utc(this.analyze.startDate).format('YYMMDD')}-${moment.utc(this.analyze.endDate).format('YYMMDD')}_performance.xlsx`,
                        stickyRowsCount: 1,
                    });
                } else {
                    this.$store.dispatch('alert/error', 'Get data first', {
                        root: true
                    });
                }
            },
            templateText(item) {
                return `${item.id} — ${item.name}`;
            },
            isNumeric(n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            },
            addAggregateFilter(index) {
                if (index === 0) {
                    if (this.aggregateFilterParam !== null && this.aggregateFilterCondition !== null && this.aggregateFilterValue !== null) {
                        this.aggregateFilters.push({
                            param: this.aggregateFilterParam,
                            condition: this.aggregateFilterCondition,
                            value: this.aggregateFilterValue,
                        });
                        this.aggregateFilterParam = null;
                        this.aggregateFilterCondition = null;
                        this.aggregateFilterValue = null;
                        this.getAggregateValues(this.analyze, 0);
                    } else {
                        this.$store.dispatch('alert/error', 'All filter parameters must be set', {
                            root: true
                        });
                    }
                } else {
                    if (this.aggregateFilterParam2 !== null && this.aggregateFilterCondition2 !== null && this.aggregateFilterValue2 !== null) {
                        this.aggregateFilters2.push({
                            param: this.aggregateFilterParam2,
                            condition: this.aggregateFilterCondition2,
                            value: this.aggregateFilterValue2,
                        });
                        this.aggregateFilterParam2 = null;
                        this.aggregateFilterCondition2 = null;
                        this.aggregateFilterValue2 = null;
                        this.getAggregateValues(this.analyze2, 1);
                    } else {
                        this.$store.dispatch('alert/error', 'All filter parameters must be set', {
                            root: true
                        });
                    }
                }
            },
            removeAggregateFilter(i, index) {
                if (index === 0) {
                    this.aggregateFilters.splice(i, 1);
                    this.getAggregateValues(this.analyze, 0);
                } else {
                    this.aggregateFilters2.splice(i, 1);
                    this.getAggregateValues(this.analyze2, 1);
                }
            }
        }
    };
</script>

<style scoped>
    .chart,
    .map {
        width: 100%;
        height: 500px;

    }

    .v-subheader:hover {
        color: rgba(0, 0, 0, .65);
    }

    .control-btn {
        position: absolute;
    }
</style>
<style>
    .v-icon.v-icon.v-icon--link {
        color: inherit;
    }

    .video {
        display:none;
    }
</style>