import moment from 'moment';
import Vue from 'vue';

Vue.filter('date', (value) => {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY hh:mm');
    }
});
Vue.filter('dateUtc', (value) => {
    if (value) {
        return `${moment.utc(String(value)).format('DD.MM.YYYY hh:mm')} UTC`;
    }
});
Vue.filter('capitalize', (value) => {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});
