import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

let themes = {
    light: {
        cardbg: '#f0f3f4',
        cardbgdark: '#d3dcde',
        cardbglight: '#ffffff', // '#f0f3f4',
        logo: '#79abcf',
        navbar: '#263238',
        secondarynav: '#263238',
        primary: '#4FC3F7',
        secondary: '#0288D1',
        accent: '#F57F17',
        error: '#f44336',
        warning: '#FFB300',
        info: '#b3b3b3',
        success: '#4caf50',
        primarytext: '#000000',
        grey: '#E0E0E0',
        greydark: '#bfbfbf',
        brown: '#8D6E63',
        blue: '#2196f3',
    },
    dark: {
        cardbg: '#262626',
        cardbgdark: '#1e1e1e',
        cardbglight: '#333333',
        logo: '#79abcf',
        navbar: '#1a1a1a',
        secondarynav: '#FFFFFF',
        primary: '#4FC3F7',
        secondary: '#0288D1',
        accent: '#FFC107',
        error: '#f44336',
        warning: '#F57F17',
        info: '#b3b3b3',
        success: '#4caf50',
        primarytext: '#FFFFFF',
        grey: '#E0E0E0',
        greydark: '#bfbfbf',
        brown: '#8D6E63',
        blue: '#2196f3',
    }
};

if (process.env.VUE_APP_NAME === 'danelec') {
    themes = {
        light: {
            cardbg: '#F7F7F7',
            cardbgdark: '#d1d1d1',
            cardbglight: '#ffffff',
            logo: '#F37835',
            navbar: '#007889', // #5E6061
            secondarynav: '#005a66',
            primary: '#4FC3F7',
            secondary: '#0288D1',
            accent: '#F37835',
            error: '#f44336',
            warning: '#FFB300',
            info: '#b3b3b3',
            success: '#4caf50',
            primarytext: '#000000',
            grey: '#E0E0E0',
            greydark: '#bfbfbf',
            brown: '#8D6E63',
            blue: '#2196f3',
        },
        dark: {
            cardbg: '#262626',
            cardbgdark: '#1e1e1e',
            cardbglight: '#333333',
            logo: '#F37835',
            navbar: '#263238',
            secondarynav: '#FFFFFF',
            primary: '#4FC3F7',
            secondary: '#0288D1',
            accent: '#F37835',
            error: '#f44336',
            warning: '#F57F17',
            info: '#b3b3b3',
            success: '#4caf50',
            primarytext: '#FFFFFF',
            grey: '#E0E0E0',
            greydark: '#bfbfbf',
            brown: '#8D6E63',
            blue: '#2196f3',
        }
    };
} else if (process.env.VUE_APP_NAME === 'nextfleet') {
    themes = {
        light: {
            cardbg: '#f0f3f4',
            cardbgdark: '#d3dcde',
            cardbglight: '#ffffff',
            logo: '#f3b700',
            navbar: '#263238',
            secondarynav: '#263238',
            primary: '#4FC3F7',
            secondary: '#0288D1',
            accent: '#e3ac08',
            error: '#f44336',
            warning: '#FFB300',
            info: '#b3b3b3',
            success: '#4caf50',
            primarytext: '#000000',
            grey: '#E0E0E0',
            greydark: '#bfbfbf',
            brown: '#8D6E63',
            blue: '#2196f3',
        },
        dark: {
            cardbg: '#262626',
            cardbgdark: '#1e1e1e',
            cardbglight: '#333333',
            logo: '#f3b700',
            navbar: '#263238',
            secondarynav: '#FFFFFF',
            primary: '#4FC3F7',
            secondary: '#0288D1',
            accent: '#e3ac08',
            error: '#f44336',
            warning: '#F57F17',
            info: '#b3b3b3', // '#2196f3',
            success: '#4caf50',
            primarytext: '#FFFFFF',
            grey: '#E0E0E0',
            greydark: '#bfbfbf',
            brown: '#8D6E63',
            blue: '#2196f3',
        }
    };
} 

export default new Vuetify({
    theme: {
        dark: false,
        themes,
    },
    icons: {
        iconfont: 'mdi',
    },
});
