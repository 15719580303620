<template>
    <v-simple-table class="mt-1" dense>
        <template #default>
            <tbody class="pa-0">
                <tr v-show="showTimes && !ataValid">
                    <td class="text-no-wrap">ETA PBP</td>
                    <td>{{ formatDate(item.etaPbpUser, item.TIMEZONE) }}</td>
                </tr>
                <tr v-show="showTimes && !ataValid">
                    <td class="text-no-wrap">ATA PBP</td>
                    <td>{{ formatDate(item.ataPbpUser, item.TIMEZONE) }}</td>
                </tr>
                <tr v-show="showTimes && !ataValid">
                    <td class="text-no-wrap">ETA Berth</td>
                    <td>{{ formatDate(item.etaUser, item.TIMEZONE) }}</td>
                </tr>
                <tr v-show="showTimes && !ataValid">
                    <td class="text-no-wrap">ATA Berth</td>
                    <td>{{ formatDate(item.ataUser, item.TIMEZONE) }}</td>
                </tr>
                <tr v-show="showTimes">
                    <td class="text-no-wrap">ETD Berth</td>
                    <td>{{ formatDate(item.etdUser, item.TIMEZONE) }}</td>
                </tr>
                <tr v-show="showTimes">
                    <td class="text-no-wrap">ATD Berth</td>
                    <td>{{ formatDate(item.atdUser, item.TIMEZONE) }}</td>
                </tr>
                <tr v-show="showTimes">
                    <td class="text-no-wrap">Prospects</td>
                    <td>
{{ item.Prospects }}<br />
                    <span style="font-size: 11px;" v-show="item.s5Updated">Prospects updated {{ fromNow(item.s5Updated) }}</span>
</td>
                </tr>
                <tr v-show="showDetails"> 
          <td>Voyage number</td>
          <td>{{ item.Voyage_Number }}</td>
        </tr>
        <tr v-show="showDetails"> 
          <td>Cargo</td>
          <td>{{ secure.Cargo }} / {{ secure.Scheduled_Quantity }}</td>
        </tr>
        <tr v-show="showDetails">
          <td>LC / DW</td>
          <td>{{ showLaycan(item) }}</td>
        </tr>
        <tr v-show="showDetails">
          <td>Supplier / Receiver</td>
          <td>{{ secure.Client }}</td>
        </tr>
        <tr v-show="showDetails">
          <td>Charterer</td>
          <td>{{ secure.Charterer }}</td>
        </tr>
        <tr v-show="showDetails">
          <td>Agent</td>
          <td>{{ item.Agent }}</td>
        </tr>
        <tr v-show="showDetails">
          <td>CP/COA date</td>
          <td>{{ secure.CP_DATE }}</td>
        </tr>
        <tr v-show="showDetails">
          <td>Bunker order confirmed?</td>
          <td>{{ secure.Bunker_Confirm === 'x' ? 'Yes' : 'No' }}</td>
        </tr>
        <tr v-show="showDetails">
          <td>Bunker order</td>
          <td :style="secure.Bunker_Confirm === 'x' ? 'color:green' : 'color:red'">{{ secure.Bunker_Quality_to_be_Ordered }}</td> 
        </tr>
        <tr v-show="showDetails">
          <td>Itinerary updated</td>
          <td>{{ fromNow(item.header_lastupdated) }}</td> 
        </tr>        
        <tr v-show="showDetails">
          <td>General reference to updates</td>
          <td style="max-width: 320px">
            ETA updates shall be done whenever there is a significant change to
            your ETA and as per Neste voyage order instructions at
            96/72/48/24/12 hrs before arrival
          </td>
        </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
 import {
        mapGetters,
    } from 'vuex';
import moment from "moment";
import {
    tools
} from '../helpers'
    export default {
        name: 'PortDetails',
        props: {
            item: Object,
            showDetails: Boolean,
            showTimes: Boolean,
            secure: Object
        },
        computed: {
            ataValid(){
                if (this.item.s5Ata && moment.utc(this.item.s5Ata).isValid()) {
                    return true;
                } else if (this.item.ATA && moment.utc(this.item.ATA).isValid()) {
                    return true;
                } else {
                    return false;
                }
            },
        },
        mounted() {
            // console.log(this.secure)
        },  
        methods: {
            ...mapGetters({
            
            }),
            formatDate(date, timezone) {
                console.log(date, timezone)
                if (date && date !== '0000-00-00T00:00:00Z') {
                    timezone = timezone ? timezone : 0;
                    return tools.formatDate(date, timezone);
                } else {
                    return '';
                }
            },
            fromNow(time) {
                return moment.utc(time).fromNow();
            },
            showLaycan(item) {
                let start = item.Scheduled_Date_From ? item.Scheduled_Date_From : item.planned_arrival ? item
                    .planned_arrival : null;
                let end = item.Scheduled_Date_From ? item.Scheduled_Date_To : item.planned_departure ? item
                    .planned_departure : null;
                start = start ? moment.utc(start).format('D.M Y') : '-';
                end = end ? moment.utc(end).format('D.M Y') : '-';
                return start + ' / ' + end;
            },
        }
    }
</script>