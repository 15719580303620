<template>
    <span style="white-space:nowrap;margin-right:0"><span :style="style()">{{ financial(data.Custom) }}</span></span>
</template>

<script>

import accountingJs from 'accounting-js';

    export default {
        data() {
            return {
                data: {},
            };
        },
        mounted() {

        },
        methods: {
            style() {
                let style = 'font-weight:700;white-space:nowrap;';
                style += this.data.Custom >= 0 ? '' : 'color:#f44336 !important';          
                return style;
            },
            financial(x) {
                return accountingJs.formatNumber(x, { precision: 2, thousand: ' ', decimal: ',' });
            }
        }
    };
</script>
