<template>
    <div>
        <v-btn class="hidden-sm-and-down" style="position:absolute; margin-top:-10px;right:10px; z-index:1000" color="primary" @click="updateStatusList()">Update <v-icon right class="hidden-xs-only">refresh</v-icon></v-btn>
        <v-data-table
            :headers="headers"
            :items="statusList"
            class="elevation-1"
            sort-by="time"
            order-by="desc"
            hide-default-footer
            :items-per-page="-1"
        >
            <template #item="{item}">
                <tr>
                    <td class="severity"><v-icon :color="statusColor(item.status)">{{ statusIcon(item.status) }}</v-icon></td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.fromNow }}</td>
                    <td>{{ item.time }}</td>
                </tr>
            </template>
        </v-data-table>

        <!-- Components -->
        <StatusDialog />
        <ProgressCircular /> 
    </div>
</template>

<script>
    import {
        mapActions,
    } from 'vuex';
    import moment from 'moment';
    import StatusDialog from './StatusDialog.vue';
    import ProgressCircular from './ProgressCircular.vue';

    export default {
        name: 'PerfStatus',
        components: {
            StatusDialog,
            ProgressCircular
        },
        data() {
            return {
                statusList: [],
                headers: [
                    { text: 'Status', align: 'center', value: 'status' },
                    { text: 'Database table', value: 'name' },
                    { text: 'Last updated', value: 'fromNow' },
                    { text: 'Timestamp', value: 'time' }
                ],
            };
        },
        mounted() {
            this.updateStatusList();
        },
        methods: {
            ...mapActions({
                fetchPerfStatus: 'data/fetchPerfStatus',
            }),
            statusIcon(status) {
                let icon = 'event_note';
                if (status === 0) {
                    icon = 'warning';
                } else if (status === 1) {
                    icon = 'notifications';
                }
                return icon;
            },
            statusColor(status) {
                let color = 'success';
                if (status === 0) {
                    color = 'error';
                } else if (status === 1) {
                    color = 'warning';
                }
                return color;
            },
            updateStatusList() {
                this.fetchPerfStatus().then((data) => {
                    this.statusList = Object.keys(data).map((key) => { 
                        let status = 2;
                        const timelimit1 = moment.utc().subtract(1, 'hours').unix();
                        const timelimit2 = moment.utc().subtract(6, 'hours').unix();
                        if (moment.utc(data[key]).unix() < timelimit1 && moment.utc(data[key]).unix() >= timelimit2) {
                            status = 1;
                        } else if (moment.utc(data[key]).unix() < timelimit2) {
                            status = 0;
                        }
                        return {
                            status,
                            name: key, 
                            time: moment.utc(data[key]).format('DD.MM.YYYY HH:mm:ss Z'),
                            fromNow: moment.utc(data[key]).fromNow(),
                        }; 
                    });
                });
            }
        }
    };
</script>

<style>
  
</style>
<style scoped>
    
</style>
