<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <div @click="getData()">
                    <v-icon medium color="red">notification_important</v-icon>
                    <span class="text-body-1 ml-2">Adjust your alert groups</span>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="cardbg">
                <br />
                <!-- create new group -->
                <v-expansion-panels v-model="panel.newGroup">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div>
                                <v-icon medium color="teal" right>group_add</v-icon>
                                <span class="text-body-1 ml-2">Add new group</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="primarytext--text">
                                <v-card-title>
                                    <v-text-field v-model="newGroupName" label="Give the group a name" />
                                </v-card-title>
                                <!-- this table has some sample data below but needs to be tied to users -->
                                <v-data-table :headers="headers" v-model="newGroupUsers" :items="newGroupUsers" item-key="name" class="elevation-1">
                                    <template #item="{item}">
                                        <tr>
                                        <td>{{ item.name }}</td>
                                        <td>
                                            <v-checkbox class="pt-0 mt-0" v-model="item.daily" primary color="teal" hide-details />
                                        </td>
                                        <td>
                                            <v-checkbox class="pt-0 mt-0" v-model="item.instant" primary color="teal" hide-details />
                                        </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                                <v-text-field v-model="newPhones" label="SMS instant alert phones" hint="Comma separated list of phones in international format without plus sign: 3587654321,3581234567" />
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn text @click="closePanel">Cancel</v-btn>
                                    <v-spacer />
                                    <v-btn color="primary" @click="callSaveNewGroup" :disabled="userLevel < 2">
Save
                                        <v-icon right class="hidden-xs-only" dark>check</v-icon>
                                    </v-btn>
                                    <v-spacer />
                                </v-card-actions>
                                <v-divider />
                                <v-card-text>
                                    <p>
You can select to subscribe to two types of notifications.
                                        <br>- A
                                        <b>daily summary</b>, which means that the User will receive a daily
                                        summary at 0600 UTC each day, containing a list of events of the previous UTC day (24hrs)
                                        <br>-
                                        <b>Instant alerts</b>, which means that the User will receive an email
                                        immediately when an events is detected
</p>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <!-- edit existing groups -->
                <v-expansion-panels v-model="panel[group.id]" v-for="(group, index) in groups" :key="index" class="cardbglight">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div @click="callFetchGroupUsers(group.id)">
                                <v-icon medium right>settings</v-icon>
                                <span class="text-body-1 ml-2">{{ group.name }}</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="primarytext--text">
                                <v-card-title>
                                    <v-text-field v-model="group.name" label="Give the group a name" />
                                </v-card-title>
                                <!-- this table has some sample data below but needs to be tied to users -->
                                <v-data-table :headers="headers" :items="groupUsers" item-key="id" class="elevation-1">
                                    <template #item="{item}">
                                        <tr>
                                        <td>{{ item.name }}</td>
                                        <td>
                                            <v-checkbox class="pt-0 mt-0" v-model="item.daily" primary color="teal" hide-details />
                                        </td>
                                        <td>
                                            <v-checkbox class="pt-0 mt-0" v-model="item.instant" primary color="teal" hide-details />
                                        </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                                <v-text-field v-model="group.phones" label="SMS instant alert phones" hint="Comma separated list of phones in international format without plus sign: 3587654321,3581234567" />
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn color="error" @click="deleteGroup(group.id)" :disabled="userLevel < 2">
Delete group 
                                        <v-icon right class="hidden-xs-only" dark>delete</v-icon>
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn color="primary" @click="updateGroup(group.id, group.name, group.phones)" :disabled="userLevel < 2">
Save
                                        <v-icon right class="hidden-xs-only" dark>check</v-icon>
                                    </v-btn>
                                    <v-spacer />
                                </v-card-actions>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-divider />
                <v-card-text>
                    <p>
You can select to subscribe to two types of notifications.
                        <br>- A
                        <b>daily summary</b>, which means that the User will receive a daily summary at 0600
                        UTC each day, containing a list of events in the last 24 hours
                        <br>-
                        <b>Instant alerts</b>, which means that the User will receive an email immediately when
                        an events is detected
</p>
                </v-card-text>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex';

export default {
    name: 'BoqaSettingsAlertGroups',
    data() {
        return {
            headers: [{
                    text: 'User',
                    align: 'left',
                    value: 'name'
                },
                {
                    text: 'Daily',
                    value: 'daily'
                },

                {
                    text: 'Instant',
                    value: 'instant'
                }
            ],
            users: [],
            newGroupName: '',
            newPhones: '',
            newGroupUsers: [],
            panel: []

        };
    },
    created() {
        this.fetchUsers();
        this.fetchGroups();
    },
    computed: {
        groups() {
            return this.getGroups();
        },
        groupUsers() {
            return this.getGroupUsers();
        },
        userLevel() {
            return this.getUser().user.level;
        },
    },
    methods: {
        ...mapGetters({
            getUsers: 'users/getUsers',
            getGroups: 'users/getGroups',
            getGroupUsers: 'users/getGroupUsers',
            getUser: 'authentication/getUser',
        }),
        ...mapActions({
            fetchUsers: 'users/fetchUsers',
            fetchGroupUsers: 'users/fetchGroupUsers',
            fetchGroups: 'users/fetchGroups',
            saveGroup: 'users/saveGroup',
            deleteGroup: 'users/deleteGroup',
        }),
        getData() {
            this.users = this.getUsers();
            this.newGroupUsers = JSON.parse(JSON.stringify(this.users));
        },
        callSaveNewGroup() {
            this.saveGroup({ name: this.newGroupName, users: this.newGroupUsers, phones: this.newPhones });
            setTimeout(() => {
                this.getData();
            }, 1000);
            this.newGroupName = null;
            this.newGroupUsers = [];
            this.newPhones = '';
        },
        updateGroup(groupId, groupName, phones) {
            this.saveGroup({ users: this.groupUsers, groupId, name: groupName, phones});
        },
        callFetchGroupUsers(groupId) {
            if (!this.panel[groupId]) {
                this.fetchGroupUsers(groupId);
            }
        },
        closePanel() {
            this.panel.newGroup = undefined;
            this.newGroupName = '';
            this.newPhones = '';
            this.newGroupUsers = this.users;
        },
    }
};
</script>
