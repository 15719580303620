<template>
    <div>
        <v-card>
            <v-data-table :headers="headers" :items="events" :items-per-page.sync="eventsPerPage" :search="search" :single-expand="true"
                :expanded.sync="expanded" item-key="id" :sort-by="sortBy" :sort-desc="sortDesc" :footer-props="{ showFirstLastPage: true}"
                mobile-breakpoint="100" class="elevation-1">
                <template #top>
                    <v-toolbar>
                        <v-toolbar-title>BOQA events </v-toolbar-title>
                        <v-spacer />
                        <v-spacer />
                        <v-spacer />
                        <div style="width:125px;margin-right:10px">
                        <v-select @change="setTimezone(timezone)" v-model="timezone" class="mt-6" prepend-icon="mdi-clock-outline" :items="timezones" item-key="id" label="Timezone" />
                        </div>
                        <v-text-field v-model="search" append-icon="close" @click:append="search=''" prepend-icon="search" label="Search" single-line
                            hide-details />
                    </v-toolbar>
                </template>
                <template #header="{ props }">
                    <th v-for="(header, index) in props.headers" :key="index" :class="['px-2', 'text-start', 'sortable', header.value === 'description' || header.value === 'areaName' ? 'd-none d-sm-table-cell' : '',
                            header.value === 'areaSeaName' ? 'd-none d-md-table-cell' : '',
                            header.value === 'severity' ? 'severity' : '',
                            header.value === 'name' ? 'name' : '',
                            header.value === 'time' ? 'time' : '',
                            header.value === 'id' ? 'd-none d-md-table-cell' : '',
                            sortDesc ? 'desc' : 'asc', header.value === sortBy ? 'active' : '']" style="text-align:left !important"
                        :aria-sort="sortDesc ? 'descending' : 'ascending'">
                        <v-select style="opacity:0.5" class="filter" label="" :items="listValues(header.value)" v-model="search" append-icon="close"
                            @click:append="search=''"></v-select>
                    </th>
                </template>
                <template #item="{item}">
                    <tr @click="expandRow(item);expandPanel(item.id)">
                        <td class="severity">
                            <v-icon :title="severity(item.severity).text" :color="severity(item.severity).color">{{ severity(item.severity).icon }}</v-icon>
                        </td>
                        <td class="name">{{ item.name }}</td>
                        <td>{{ formatTime(item.time, false) }}</td>
                        <td>{{ item.eventType }}</td>
                        <td class="d-none d-sm-table-cell">{{ item.description }}</td>
                        <td class="d-none d-md-table-cell">{{ item.areaSeaName }}</td>
                        <td class="d-none d-sm-table-cell">{{ showAreaName(item.areaName, item.areaType) }}</td>
                        <td class="d-none d-md-table-cell">{{ item.id }}</td>
                    </tr>
                </template>
                <template #expanded-item="{ headers }">
                    <td :colspan="headers.length" class="pa-0">
                        <v-container fluid class="pa-0">
                            <v-layout wrap>
                                <v-flex sm12 md6>
                                    <v-map v-if="event && event.length > 0" class="map" :zoom="zoom" :center="center" :bounds="bounds" :options="mapOptions">
                                        <v-tilelayer :url="url" :options="{tileSize: 512, crossOrigin: true, zoomOffset: -1}"></v-tilelayer>
                                        <v-tilelayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"></v-tilelayer>
                                        <v-geojson v-for="(geo, index) in geojsons" :key="random()+index" :geojson="geo.geojson" :options="geo.options">
                                        </v-geojson>
                                        <span v-for="(marker, index) in markers" :key="random()+index">
                                            <v-rotated-marker v-if="marker.latLng" :lat-lng="marker.latLng" :icon="marker.icon"
                                                :rotationAngle="marker.direction" :options="marker.options" :rotationOrigin="marker.rotationOrigin">
                                                <l-tooltip v-if="marker.tooltip" :content="marker.tooltip" :options="tooltipOptions" />
                                                <l-popup v-if="marker.popup" :content="marker.popup"></l-popup>
                                            </v-rotated-marker>
                                        </span>
                                        <l-polyline v-for="(polyline, index) in polylines" :key="random()+index" :lat-lngs="polyline.latLngs"
                                            :color="polyline.color" :weight="polyline.weight" :dashArray="polyline.dashArray" />
                                        <l-circle v-for="(circle, index) in circles" :key="random()+index" :lat-lng="circle.center" :radius="circle.radius"
                                            :fillOpacity="0.1" :color="circle.color" :weight="circle.weight"></l-circle>
                                        <div v-if="event && event.length > 0" class="weather primarytext--text">
                                            <span v-show="event[0].windSpeed && event[0].mslp && event[0].Type !== 'Weather'">
                                                <v-icon v-show="event[0].windDirection" small :title="event[0].windDirection + '°'"
                                                    :style="{ transform: 'rotate(' + event[0].windDirection + 'deg)' }">arrow_downward</v-icon>
                                                <span v-show="event[0].windSpeed">{{ event[0].windSpeed }}m/s
                                                    {{ Math.round(event[0].windSpeed*1.94384*10)/10 }}kn</span>
                                                <v-icon v-show="event[0].waveDirection" small :title="event[0].waveDirection + '°'"
                                                    :style="{ transform: 'rotate(' + event[0].waveDirection + 'deg)' }">arrow_downward</v-icon>
                                                <span v-show="event[0].waveHeight">{{ event[0].waveHeight }}m </span>
                                                <span v-show="event[0].mslp">{{ event[0].mslp }}hPa</span>
                                            </span>
                                            <span v-show="!event[0].windSpeed && !event[0].mslp">
                                                Weather forecast not available
                                            </span>
                                        </div>
                                        <div v-if="event && event.length > 0" class="aisinfo primarytext--text">
                                            {{ ais.sog }}kn <span :class="getNavstat(ais.navstat).color + '--text'">
                                                {{ getNavstat(ais.navstat).status }}</span><br />
                                            {{ decimalToDmm(ais.latitude, false) }} {{ decimalToDmm(ais.longitude, true) }}
                                        </div>
                                        <div v-if="event && event.length > 0 && sensor && sensor.rollMax" class="sensorinfo primarytext--text">
                                            Roll max:
                                            {{ sensor.rollMax }}° / Pitch max: {{ sensor.pitchMax }}°
                                        </div>
                                    </v-map>
                                </v-flex>
                                <v-flex sm12 md6>
                                    <v-card class="eventcard primarytext--text mt-3">
                                        <v-container fluid grid-list-md class="pa-0">
                                            <v-data-iterator :items="event" :items-per-page.sync="itemsPerPage" hide-default-footer wrap>
                                                <template #default="props">
                                                    <v-row>
                                                        <v-col v-for="item in props.items" :key="item.name" xs="12" sm="6">
                                                            <v-card class="primarytext--text">
                                                                <v-card-title class="pa-1 pl-4" style="font-size:16px">{{ item.name }}</v-card-title>
                                                                <v-divider></v-divider>
                                                                <v-list class="primarytext--text" dense>
                                                                    <v-list-item :style="key !== 'Desc' ? 'white-space:nowrap;height:20px;min-height:20px' : ''"
                                                                        v-show="key !== 'aisTargets' && key !== 'windSpeed' && key !== 'windDirection' && key !== 'waveHeight' && key !== 'waveDirection' && key !== 'mslp' && key !== 'Mmsi' && key !== 'tsAisId' && key !== 'name' && key !== 'Type' && key !== 'Severity' && key !=='areaGeojson' && key !=='eventJson' && key !== 'routeGeojson' && key !== 'corridorGeojson'"
                                                                        v-for="(value, key) in item" :key="key">
                                                                        <v-list-item-content style="overflow:visible">
                                                                            {{ camelCaseToNormal(key) }}:
                                                                        </v-list-item-content>
                                                                        <v-list-item-content
                                                                            v-if="key !== 'time' && key !== 'Conning time' && key !== 'Local time'"
                                                                            style="overflow:visible;font-weight:700;display:block;width:85%;text-align:right"
                                                                            class="align-end">
                                                                            {{ formatValue(value) }}
                                                                        </v-list-item-content>
                                                                        <v-list-item-content v-if="key === 'time' || key === 'Conning time'"
                                                                            style="overflow:visible;font-weight:700;display:block;width:85%;text-align:right"
                                                                            class="align-end">
                                                                            {{ formatTime(value, true) }}
                                                                        </v-list-item-content>
                                                                        <v-list-item-content v-if="key === 'Local time' && value"
                                                                            style="overflow:visible;font-weight:700;display:block;width:85%;text-align:right"
                                                                            class="align-end">
                                                                            {{ shipTime(value) }}
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-data-iterator>
                                        </v-container>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </td>
                </template>
            </v-data-table>
        </v-card>
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div>
                        <v-icon medium color="primary">help</v-icon>
                        <span class="text-body-1 ml-2">What does this page show</span>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <br />
                    <p>
                        This page lists the most recent BOQA events for you fleet. When you select events in the table, they will also be shown in the
                        map below and you will see the detailed data of the event.
                    </p>
                    <p>
                        Most of the events are triggered by various limit values, as described on the BOQA settings page, and for such events we also
                        store the settings values which were in use when the event was triggered.
                    </p>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <!-- Components -->
        <ProgressCircular />
        <StatusDialog />
    </div>
</template>

<script>
    import L from 'leaflet';
    import {
        LMap,
        LTileLayer,
        LGeoJson,
        LTooltip,
        LPolyline,
        LCircle,
        LPopup,
    } from 'vue2-leaflet';
    import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker';
    import {
        mapGetters,
        mapActions,
    } from 'vuex';
    import moment from 'moment';
    import {
        shipHelpers,
        config,
        tools,
    } from '../helpers';
    import ProgressCircular from '../components/ProgressCircular.vue';
    import StatusDialog from '../components/StatusDialog.vue';

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    export default {
        name: 'BoqaEvents',
        components: {
            'v-map': LMap,
            'v-tilelayer': LTileLayer,
            'v-geojson': LGeoJson,
            LTooltip,
            LPolyline,
            LCircle,
            LPopup,
            'v-rotated-marker': Vue2LeafletRotatedMarker,
            ProgressCircular,
            StatusDialog,
        },
        data: () => ({
            search: '',
            sortBy: 'time',
            sortDesc: true,
            selected: [],
            headers: [{
                    text: 'Severity',
                    value: 'severity'
                },
                {
                    text: 'Ship',
                    value: 'name'
                },
                {
                    text: `Time +00:00`,
                    value: 'time'
                },
                {
                    text: 'Type',
                    value: 'eventType'
                },
                {
                    text: 'Description',
                    value: 'description',
                    class: 'd-none d-sm-table-cell'
                },
                {
                    text: 'Sea area',
                    value: 'areaSeaName',
                    class: 'd-none d-md-table-cell'
                },
                {
                    text: 'Country',
                    value: 'areaName',
                    class: 'd-none d-sm-table-cell'
                },
                {
                    text: 'ID',
                    value: 'id',
                    class: 'd-none d-md-table-cell'
                },
            ],
            events: [],
            event: [],
            ais: {},
            sensor: {},
            itemsPerPage: 12,
            eventsPerPage: 10,
            eventInterval: null,
            expanded: [],
            // Leaflet specific properties
            // attribution: '&copy; <a href="http://www.nauticai.com">nauticAi</a>',
            mapOptions: {
                attributionControl: false
            },
            tooltipOptions: {
                permanent: true,
                direction: 'top',
                offset: L.point(0, -10),
                opacity: 0.8,
            },
            ships: [],
            markers: [],
            geojsons: [],
            polylines: [],
            circles: [],
            center: null,
            bounds: null,
            zoom: null,
            id: null,
            lastEventId: null,
            timezone: '+00:00',
            timezones: ["-12:00","-11:00","-10:00","-09:30","-09:00","-08:00","-07:00","-06:00","-05:00","-04:00","-03:30","-03:00","-02:00","-01:00","+00:00","+01:00","+02:00","+03:00","+03:30","+04:00","+04:30","+05:00","+05:30","+05:45","+06:00","+06:30","+07:00","+08:00","+08:45","+09:00","+09:30","+10:00","+10:30","+11:00","+12:00","+12:45","+13:00","+14:00"],
        }),
        created() {
            let timezone = localStorage.getItem('eventTimezone', this.timezone);
            if (timezone) {
                this.timezone = timezone;
                this.headers[2].text = 'Time ' + this.timezone;
            }
            this.callFetchEvents();
            setTimeout(() => {
                this.ships = this.getShips();
            }, 1500);

            // Expand pre selected event
            this.id = this.$route.params.id;
            if (typeof this.id !== 'undefined' && this.id !== null) {
                this.expandPanel(this.id);
                this.eventsPerPage = 30;
                this.search = this.id.toString();
            }
            localStorage.removeItem('eventId');
        },
        beforeDestroy() {
            clearInterval(this.eventInterval);
        },
        computed: {
            url() {
                if (this.getDarkMode()) {
                    return `https://api.maptiler.com/maps/d0b6607a-df0c-4660-af62-030764e2efc4/{z}/{x}/{y}.png?key=${config.apiKey}`;
                }
                return `https://api.maptiler.com/maps/511be076-e981-4e63-8d86-7f23e4d3e7f5/{z}/{x}/{y}.png?key=${config.apiKey}`;
            },
        },
        methods: {
            ...mapActions({
                fetchEvents: 'data/fetchEvents',
                fetchEvent: 'data/fetchEvent',
            }),
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getEvent: 'data/getEvent',
                getShips: 'data/getShips',
            }),
            callFetchEvent(eventId) {
                this.event = [];
                this.markers = [];
                this.geojsons = [];
                this.polylines = [];
                this.circles = [];
                let marker = {};
                this.center = null;
                this.zoom = 12;
                this.fetchEvent(eventId).then((data) => {
                    if (!data) {
                        return;
                    }
                    // Event
                    if (data && data[0]) {
                        this.event[0] = data[0];
                    }
                    // Area
                    if (data && data[1]) {
                        this.event[1] = data[1];
                    }

                    // Create markers
                    if (data) {
                        if (data[2]) {
                            if (!data[2].latitude && !data[2].longitude) { // CPA event -> copy ais data from eventJson
                                if (data[0].eventJson && data[0].eventJson.oais && data[0].eventJson.oais.latitude) {
                                    data[2] = data[0].eventJson.oais;
                                    data[2].navstat = data[0].eventJson.oais.navStatus;
                                }
                            }
                            this.markers[0] = {};
                            if (data[2].latitude && data[2].longitude) {
                                this.markers[0].latLng = L.latLng(data[2].latitude, data[2].longitude);
                                this.center = L.latLng(data[2].latitude, data[2].longitude);
                                // Create circle around ais position
                                const radiuses = [926, 1852, 2778];
                                for (const radius of radiuses) {
                                    this.circles.push({
                                        center: L.latLng(data[2].latitude, data[2].longitude),
                                        radius,
                                        color: 'rgba(150, 150, 150, 0.3)',
                                        weight: 0.5
                                    });
                                }
                                let ship;
                                this.ships.forEach((element) => {
                                    if (element.mmsi == data[0].Mmsi) {
                                        ship = JSON.parse(JSON.stringify(element));
                                    }
                                });
                                if (ship) {
                                    ship.latitude = data[2].latitude;
                                    ship.longitude = data[2].longitude;
                                    ship.heading = data[2].heading;
                                    ship.cog = data[2].cog;
                                    this.geojsons.push({
                                        geojson: shipHelpers.shipGeojson(JSON.parse(JSON.stringify(ship))),
                                        options: {
                                            style: {
                                                color: '#000000',
                                                weight: 1,
                                                opacity: 0.6
                                            }
                                        }
                                    });
                                }
                            } else {
                                this.markers[0].latLng = null;
                            }
                            const icon = shipHelpers.shipIcon(data[2].navstat, 'os', data[2].mmsi, null, data[2].sog);
                            this.markers[0].icon = L.icon({
                                iconUrl: require(`@/assets/img/ships/${icon}`),
                                iconSize: [11, 30],
                                iconAnchor: [5.5, 15],
                            });
                            this.markers[0].direction = shipHelpers.headingOrCog(data[2].heading, data[2].cog);
                            const ais = data[2];
                            ais.name = data[0].Ship;
                            this.markers[0].popup = shipHelpers.shipPopup(ais);

                            // Ship predictor arrow marker
                            const arrow = shipHelpers.vectorSvg(data[2].sog, 'white');
                            if (arrow) {
                                this.markers.push({
                                    latLng: L.latLng(data[2].latitude, data[2].longitude),
                                    direction: shipHelpers.headingOrCog(data[2].heading, data[2].cog, true),
                                    icon: L.icon({
                                        iconUrl: arrow.image,
                                        iconSize: [8, arrow.height],
                                        iconAnchor: [4, arrow.height],
                                    }),
                                    rotationOrigin: 'center bottom',
                                });
                            }
                        }
                        if (data[0]) {
                            this.geojsons.push({
                                geojson: data[0].routeGeojson,
                                options: {
                                    style: {
                                        color: '#1E88E5',
                                        weight: 1,
                                        opacity: 1
                                    }
                                }
                            });
                            this.geojsons.push({
                                geojson: data[0].corridorGeojson,
                                options: {
                                    style: {
                                        color: '#4CAF50',
                                        weight: 0.1,
                                        opacity: 0.7
                                    }
                                }
                            });
                            // If no marker zoom to route 
                            if (!this.markers[0].latLng) {
                                const routeGeojson = JSON.parse(JSON.stringify(data[0].routeGeojson));
                                if (routeGeojson && routeGeojson.coordinates && routeGeojson.coordinates[0].length > 0) {
                                    this.bounds = L.latLngBounds(routeGeojson.coordinates[0].map((coordinate) => coordinate.reverse()));
                                } else {
                                    this.bounds = null;
                                }
                            }
                        }
                        if (data[0]) {
                            this.markers[0].tooltip = data[0].Ship;
                            // Area event
                            if (data[0].areaGeojson) {
                                this.geojsons.push({
                                    geojson: data[0].areaGeojson,
                                    options: {
                                        style: {
                                            color: '#3949AB',
                                            weight: 1,
                                            opacity: 0.15
                                        }
                                    }
                                });
                            }
                            // Forecast event
                            if (data[0].eventJson && !data[0].eventJson.oais) {
                                // for (const forecast of data[0].eventJson) {
                                const forecast = data[0].eventJson;
                                marker = {};
                                marker.latLng = L.latLng(forecast.latitude, forecast.longitude);
                                marker.direction = forecast.Wind_direction;
                                marker.icon = L.icon({
                                    iconUrl: require('@/assets/img/down-arrow.png'),
                                    iconSize: [20.5, 30],
                                    iconAnchor: [10.25, 15],
                                });
                                marker.tooltip = `Ship ETA: <b>${moment.utc(forecast.shipTime).format('ddd D.M HH:mm')} UTC</b><br/>` +
                                    `Forecast time: <b>${moment.utc(forecast.forecastTime).format('ddd D.M HH:mm')} UTC</b><br/>` +
                                    `Wind: <b>${forecast.Wind_speed} m/s / ${forecast.Wind_direction}°</b>`;
                                if (forecast.Significant_height_of_combined_wind_waves_and_swell > 0) {
                                    marker.tooltip += `<br/>Wave: <b>${forecast.Significant_height_of_combined_wind_waves_and_swell} m / ${ 
                                        forecast.Primary_wave_direction}°</b>`;
                                }
                                if (forecast.Pressure_mean_sea_level__units_) {
                                    marker.tooltip += `<br/>Pressure: <b>${forecast.Pressure_mean_sea_level__units_} hPa</b>`;
                                }
                                this.markers.push(marker);
                                this.center = L.latLng(forecast.latitude, forecast.longitude);
                                this.zoom = 7;
                                // }
                            }
                        }
                        // Past/future track
                        if (data[5] && data[5].geojson) {
                            this.geojsons.push({
                                geojson: data[5].geojson,
                                options: {
                                    style: {
                                        color: '#F4FF81',
                                        weight: 1.5,
                                    }
                                }
                            });
                        }
                        // Sensor data on map
                        if (data[3] && (data[3]['Roll max'] || data[3]['Roll min'])) {
                            this.sensor = {};
                            this.sensor.rollMax = this.absoluteMax(data[3]['Roll max'], data[3]['Roll min']);
                            this.sensor.pitchMax = this.absoluteMax(data[3]['Pitch max'], data[3]['Pitch min']);
                        }
                        // CPA markers
                        if (data[0].eventJson && data[0].eventJson.oclat) {
                            // own ship cpa position
                            marker = {};
                            marker.latLng = L.latLng(data[0].eventJson.oclat, data[0].eventJson.oclon);
                            marker.icon = L.icon({
                                iconUrl: require('@/assets/img/ships/os_red.png'),
                                iconSize: [11, 30],
                                iconAnchor: [5.5, 15],
                            });
                            marker.direction = shipHelpers.headingOrCog(data[0].eventJson.oais.heading, data[0].eventJson.oais.cog);
                            marker.tooltip = '<span style="color:red">CPA</span>';
                            this.markers.push(marker);
                            // Draw line between own ship AIS and CPA position
                            this.polylines.push({
                                latLngs: [
                                    [data[0].eventJson.oais.latitude, data[0].eventJson.oais.longitude],
                                    [data[0].eventJson.oclat, data[0].eventJson.oclon]
                                ],
                                dashArray: '3',
                                weight: 1,
                                color: 'rgba(255, 0, 0, 0.5)',

                            });
                            // target ship position
                            marker = {};
                            marker.latLng = L.latLng(data[0].eventJson.tais.latitude, data[0].eventJson.tais.longitude);
                            const icon = shipHelpers.shipIcon(data[0].eventJson.tais.navStatus, 'ts', data[0].eventJson.tais.mmsi, null, data[0]
                                .eventJson.tais.sog);
                            marker.icon = L.icon({
                                iconUrl: require(`@/assets/img/ships/${icon}`),
                                iconSize: [11, 30],
                                iconAnchor: [5.5, 15],
                            });
                            marker.direction = shipHelpers.headingOrCog(data[0].eventJson.tais.heading, data[0].eventJson.tais.cog);
                            marker.tooltip = data[0].eventJson.tais.shipname ? data[0].eventJson.tais.shipname : data[0].eventJson.tais.mmsi.toString();
                            marker.popup = shipHelpers.shipPopup(data[0].eventJson.tais);
                            this.markers.push(marker);
                            // target ship cpa position
                            marker = {};
                            marker.latLng = L.latLng(data[0].eventJson.tclat, data[0].eventJson.tclon);
                            marker.icon = L.icon({
                                iconUrl: require('@/assets/img/ships/os_red.png'),
                                iconSize: [11, 30],
                                iconAnchor: [5.5, 15],
                            });
                            marker.direction = shipHelpers.headingOrCog(data[0].eventJson.tais.heading, data[0].eventJson.tais.cog);
                            this.markers.push(marker);
                            // Draw line between target ship AIS and CPA position
                            this.polylines.push({
                                latLngs: [
                                    [data[0].eventJson.tais.latitude, data[0].eventJson.tais.longitude],
                                    [data[0].eventJson.tclat, data[0].eventJson.tclon]
                                ],
                                dashArray: '3',
                                weight: 1,
                                color: 'rgba(255, 0, 0, 0.5)',

                            });
                            // Draw line between CPA points
                            this.polylines.push({
                                latLngs: [
                                    [data[0].eventJson.oclat, data[0].eventJson.oclon],
                                    [data[0].eventJson.tclat, data[0].eventJson.tclon]
                                ],
                                dashArray: '5 8',
                                weight: 2.5,
                                color: 'rgba(255, 0, 0, 0.5)',

                            });
                            // BCR
                            if (data[0].eventJson.bct != -1 && data[0].eventJson.bcd != -1 && data[0].eventJson.bcd / 1852 < 1.5) {
                                // own ship bcr position
                                marker = {};
                                marker.latLng = L.latLng(data[0].eventJson.bclat, data[0].eventJson.bclon);
                                marker.icon = L.icon({
                                    iconUrl: require('@/assets/img/ships/os_blue.png'),
                                    iconSize: [11, 30],
                                    iconAnchor: [5.5, 15],
                                });
                                marker.direction = shipHelpers.headingOrCog(data[0].eventJson.oais.heading, data[0].eventJson.oais.cog);
                                marker.tooltip = '<span style="color:blue">BCR</span>';
                                this.markers.push(marker);
                                // target ship bcr position
                                marker = {};
                                marker.latLng = L.latLng(data[0].eventJson.bctlat, data[0].eventJson.bctlon);
                                marker.icon = L.icon({
                                    iconUrl: require('@/assets/img/ships/os_blue.png'),
                                    iconSize: [11, 30],
                                    iconAnchor: [5.5, 15],
                                });
                                marker.direction = shipHelpers.headingOrCog(data[0].eventJson.tais.heading, data[0].eventJson.tais.cog);
                                this.markers.push(marker);
                                // Draw line between BCR points
                                this.polylines.push({
                                    latLngs: [
                                        [data[0].eventJson.bclat, data[0].eventJson.bclon],
                                        [data[0].eventJson.bctlat, data[0].eventJson.bctlon]
                                    ],
                                    dashArray: '5 8',
                                    weight: 2.5,
                                    color: 'rgba(0, 0, 255, 0.5)',

                                });
                            }
                        }

                        // Other Ais targets
                        if (data[0] && data[0].aisTargets && data[0].aisTargets.length > 0) {
                            for (const entry of data[0].aisTargets) {
                                // map ais array to object
                                entry.mmsi = entry[0];
                                entry.name = entry[1];
                                entry.longitude = entry[2];
                                entry.latitude = entry[3];
                                entry.time = entry[4];
                                entry.sog = entry[5];
                                entry.cog = parseFloat(entry[6]);
                                entry.heading = parseFloat(entry[7]);
                                entry.navstat = entry[8];
                                entry.turn = entry[9];
                                entry.imo = entry[10];
                                entry.shiptype = entry[11];
                                entry.a = entry[12];
                                entry.b = entry[13];
                                entry.c = entry[14];
                                entry.d = entry[15];
                                entry.draught = entry[16];
                                entry.destination = entry[17];
                                entry.eta = entry[18];
                                entry.distance = entry[19];
                                if (entry.latitude && entry.longitude) {
                                    // Ship predictor arrow marker
                                    const arrow = shipHelpers.vectorSvg(entry.sog, 'yellow');
                                    if (arrow) {
                                        this.markers.push({
                                            latLng: L.latLng(entry.latitude, entry.longitude),
                                            direction: shipHelpers.headingOrCog(entry.heading, entry.cog),
                                            icon: L.icon({
                                                iconUrl: arrow.image,
                                                iconSize: [8, arrow.height],
                                                iconAnchor: [4, arrow.height],
                                            }),
                                        });
                                    }
                                    // ship marker
                                    const icon = shipHelpers.shipIcon(entry.navstat, 'ts', entry.mmsi, entry.shipType, entry.sog);
                                    const size = {};
                                    size.x = 11;
                                    size.y = 30;
                                    if (entry.mmsi.toString().substring(0, 2) === '99' || entry.mmsi.toString().substring(0, 2) === '00') {
                                        size.x = 20;
                                        size.y = 20;
                                    }
                                    this.markers.push({
                                        latLng: L.latLng(entry.latitude, entry.longitude),
                                        direction: shipHelpers.headingOrCog(entry.heading, entry.cog),
                                        icon: L.icon({
                                            iconUrl: require(`@/assets/img/ships/${icon}`),
                                            iconSize: [size.x, size.y],
                                            iconAnchor: [size.x / 2, size.y / 2],
                                        }),
                                        tooltip: `<small>${entry.name}<small>`,
                                        popup: shipHelpers.shipPopup(entry)
                                    });
                                    // ship geojson
                                    this.geojsons.push({
                                        geojson: shipHelpers.shipGeojson(JSON.parse(JSON.stringify(entry))),
                                        options: {
                                            style: {
                                                color: '#000000',
                                                weight: 1,
                                                opacity: 0.6
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    }
                    this.event = this.event.slice(0, 5);
                    if (data[2].latitude !== null && data[2].longitude !== null) {
                        this.ais = data[2];
                    } else if (data[0] && data[0].eventJson && data[0].eventJson.oais) {
                        this.ais = data[0].eventJson.oais;
                    }

                    if (this.event[0].mmsi) {
                        this.event[0]['Target mmsi'] = this.event[0].mmsi;
                        delete this.event[0].mmsi;
                    }
                });
            },
            getNavstat(navstat) {
                return shipHelpers.getNavstat(navstat);
            },
            callFetchEvents() {
                this.fetchEvents().then((data) => {
                    this.events = data;
                    if (this.id) {
                        this.expanded = data.filter((o) => o.id == this.id);
                    }
                });
                this.eventInterval = setInterval(() => {
                    this.fetchEvents().then((data) => {
                        this.events = data;
                    });
                }, 60 * 1000);
            },
            expandPanel(id) {
                // if (this.expanded.length > 0) {
                if (id !== this.lastEventId) {
                    this.lastEventId = id;
                    this.callFetchEvent(id);
                }
                this.resizeWindow();
                // }
            },
            resizeWindow() {
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                }, 250);
            },
            showAreaName(name, type) {
                name = name || 'International';
                name = type === 'eez' ? name += ' EEZ' : name;
                return name;
            },
            severity(severity) {
                return tools.severity(severity);
            },
            decimalToDmm(value, lng) {
                return tools.decimalToDmm(value, lng);
            },
            camelCaseToNormal(str) {
                str = str.toString();
                str = str.replace(/([A-Z])/g, ' $1');
                str = str.replace(/_/g, ' ');
                return str.charAt(0).toUpperCase() + str.slice(1);
            },
            random() {
                return Math.random();
            },
            absoluteMax(v1, v2) {
                v1 = Math.abs(v1);
                v2 = Math.abs(v2);
                const v = v1 >= v2 ? v1 : v2;
                return Math.round(v * 100) / 100;
            },
            formatTime(value, showTimezone) {
                this.timezone = this.timezone ? this.timezone : '+00:00';
                let time = moment.utc(value).utcOffset(this.timezone).format('ddd D.M HH:mm');
                time += showTimezone ? ' ' + this.timezone : '';
                return time;
            },
            shipTime(tz) {
                if (tz) {
                    return moment.utc(this.event[0].time).add(tz, 'h').format('ddd D.M HH:mm');
                }
            },
            formatValue(value) {
                if (typeof value === 'number') {
                    return Math.round(value * 100) / 100;
                }
                return value;
            },
            listValues(key) {
                let res;
                if (key === 'time') {
                    res = this.events.map((o) => o[key].split(' ')[0]).sort().filter((x) => x);
                } else if (key === 'id') {
                    res = this.events.map((o) => o[key].toString()).sort().filter((x) => x);
                } else {
                    res = this.events.map((o) => o[key]).sort().filter((x) => x);
                }
                return res;
            },
            expandRow(item) {
                this.expanded = item === this.expanded[0] ? [] : [item];
            },
            setTimezone(timezone) {
                localStorage.setItem('eventTimezone', timezone);
                this.headers[2].text = 'Time ' + timezone;
            }
        }
    };
</script>

<style scoped>
    table.v-table thead th,
    table.v-table td {
        padding: 0 5px !important;
    }

    .sectionheader {
        padding-bottom: 10px;
    }

    .shipheader {
        padding: 10px 0px;
    }

    .headertext {
        margin: 16px;
    }

    tr.values td {
        text-align: center;
    }

    tr.expand td {
        padding: 0 !important;
    }

    tr.expand .expansion-panel {
        box-shadow: none;
    }

    tr.expand .expansion-panel li {
        border: none;
    }

    .map {
        height: 400px;
    }

    .eventcard {
        height: 400px;
    }

    .eventid {
        display: none !important;
        width: 0;
    }

    @media only screen and (max-width: 959px) {
        .map {
            height: 350px;
            left: 0;
            right: 0;
            margin: 0;
            min-width: 95vw;
        }

        .eventcard {
            height: auto;
        }
    }
</style>
<style>
    .mypanel .v-expansion-panel__body {
        display: block !important;
        /* Dirty fix for expansion panel content */
    }

    table.v-table {
        width: 99%;
        max-width: 99%;
    }

    .v-data-table td {
        font-size: 13px;
    }

    .filter {
        display: inline-block;
        padding: 0 5px;
    }

    .filter .v-label,
    .filter .v-select__selection {
        font-size: 12px
    }

    .filter .v-text-field__details {
        display: none;
    }

    .v-list--dense .v-list__tile:not(.v-list__tile--avatar) {
        height: 100%;
    }

    .severity {
        text-align: center;
        max-width: 130px;
    }

    .name {
        max-width: 150px;
    }

    .time {
        max-width: 150px;
    }

    .expand-column {
        max-width: 100px;
    }

    .v-data-iterator__actions__select {
        width: 50%;
    }

    .v-sheet {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    @media only screen and (max-width: 959px) {
        .v-data-iterator__actions__select {
            display: none;
        }

        #header .v-text-field .v-input__append-inner {
            display: none;
        }
    }
</style>