import axios from 'axios';
import moment from 'moment';
import { authHeader, handleResponse, tools } from '../helpers';
// import { authHeader, handleResponse } from '../helpers';

export default class GridDataService {
    constructor() {
        this.requestOptions = {
            headers: authHeader()
        };
        this.BASE_URL = tools.getPerfUrl();
        // this.BASE_URL = 'http://localhost:3000';
    }

    get(state) {
        const pageQuery = `/${state.skip}/${state.take}`;
        let sortQuery = '/Date_UTC desc';
        if ((state.sorted || []).length) {
            sortQuery = `/${state.sorted.map((obj) => {
                return obj.direction === 'descending' ? `${obj.name} desc` : obj.name;
            }).reverse().join(',')}`;
        }
        let whereQuery = '/0';
        if (state && state.where && state.where.length > 0) {
            whereQuery = `/${state.where[0].predicates.map((obj) => {
                obj.name = `v.${obj.field}='${obj.value}'`;
                return obj.name;
            }).join(' AND ')}`;
        }
        this.url = `${this.BASE_URL}/dnv${pageQuery}${sortQuery}${whereQuery}`;
        return axios.get(this.url, this.requestOptions)
            .then(handleResponse)
            .then((data) => ({ result: this.parseData(data.result), count: data.count, avg: data.avg })).catch((e) => {
                console.error(e);
                return [];
            });
    }

    getParam(param) {
        return axios.get(`${this.BASE_URL}/dnv/${param}`, this.requestOptions)
            .then(handleResponse)
            .then((data) => data).catch((e) => {
                console.error(e);
                return 0;
            });
    }

    parseData(data, findIssues = true) {
        if (data && data.length > 0) {
            let sorted = {};
            for (let i = 0, il = data.length; i < il; i++) {
                // Format data
                if (data[i].Reporting_date) {
                    data[i].Reporting_date = moment.utc(data[i].Reporting_date).format();
                }
                if (data[i].time) {
                    data[i].time = moment.utc(data[i].time).format();
                }
                if (typeof data[i].Time_Since_Previous_Report !== 'undefined' && data[i].Time_Since_Previous_Report !== null) {
                    data[i].Time_Since_Previous_Report = Math.round(data[i].Time_Since_Previous_Report * 100) / 100;
                }
                if (typeof data[i].Time_Elapsed_Sailing !== 'undefined' && data[i].Time_Elapsed_Sailing !== null) {
                    data[i].Time_Elapsed_Sailing = Math.round(data[i].Time_Elapsed_Sailing * 100) / 100;
                }
                if (typeof data[i].Time_Elapsed_Maneuvering !== 'undefined' && data[i].Time_Elapsed_Maneuvering !== null) {
                    data[i].Time_Elapsed_Maneuvering = Math.round(data[i].Time_Elapsed_Maneuvering * 100) / 100;
                }
                if (typeof data[i].Time_Elapsed_Waiting !== 'undefined' && data[i].Time_Elapsed_Waiting !== null) {
                    data[i].Time_Elapsed_Waiting = Math.round(data[i].Time_Elapsed_Waiting * 100) / 100;
                }
                if (typeof data[i].Time_Elapsed_Loading_Unloading !== 'undefined' && data[i].Time_Elapsed_Loading_Unloading !== null) {
                    data[i].Time_Elapsed_Loading_Unloading = Math.round(data[i].Time_Elapsed_Loading_Unloading * 100) / 100;
                }
                if (typeof data[i].Time_Elapsed_Anchoring !== 'undefined' && data[i].Time_Elapsed_Anchoring !== null) {
                    data[i].Time_Elapsed_Anchoring = Math.round(data[i].Time_Elapsed_Anchoring * 100) / 100;
                }
                if (typeof data[i].Time_Elapsed_Ice !== 'undefined' && data[i].Time_Elapsed_Ice !== null) {
                    data[i].Time_Elapsed_Ice = Math.round(data[i].Time_Elapsed_Ice * 100) / 100;
                }
                if (typeof data[i].Time_Elapsed_Drifting !== 'undefined' && data[i].Time_Elapsed_Drifting !== null) {
                    data[i].Time_Elapsed_Drifting = Math.round(data[i].Time_Elapsed_Drifting * 100) / 100;
                }
                if (typeof data[i].Distance !== 'undefined' && data[i].Distance !== null) {
                    data[i].Distance = Math.round(data[i].Distance * 100) / 100;
                }
                if (typeof data[i].Distance_Ice !== 'undefined' && data[i].Distance_Ice !== null) {
                    data[i].Distance_Ice = Math.round(data[i].Distance_Ice * 100) / 100;
                }
                if (typeof data[i].Cargo_Mt !== 'undefined' && data[i].Cargo_Mt !== null) {
                    data[i].Cargo_Mt = Math.round(data[i].Cargo_Mt * 100) / 100;
                }
                if (typeof data[i].Cargo_M3 !== 'undefined' && data[i].Cargo_M3 !== null) {
                    data[i].Cargo_M3 = Math.round(data[i].Cargo_M3 * 100) / 100;
                }

                if (typeof data[i].ME_Consumption_HFO !== 'undefined' && data[i].ME_Consumption_HFO !== null) {
                    data[i].ME_Consumption_HFO = Math.round(data[i].ME_Consumption_HFO * 100) / 100;
                }
                if (typeof data[i].ME_Consumption_LFO !== 'undefined' && data[i].ME_Consumption_LFO !== null) {
                    data[i].ME_Consumption_LFO = Math.round(data[i].ME_Consumption_LFO * 100) / 100;
                }
                if (typeof data[i].ME_Consumption_MGO !== 'undefined' && data[i].ME_Consumption_MGO !== null) {
                    data[i].ME_Consumption_MGO = Math.round(data[i].ME_Consumption_MGO * 100) / 100;
                }
                if (typeof data[i].ME_Consumption_MDO !== 'undefined' && data[i].ME_Consumption_MDO !== null) {
                    data[i].ME_Consumption_MDO = Math.round(data[i].ME_Consumption_MDO * 100) / 100;
                }
                if (typeof data[i].ME_Consumption_LNG !== 'undefined' && data[i].ME_Consumption_LNG !== null) {
                    data[i].ME_Consumption_LNG = Math.round(data[i].ME_Consumption_LNG * 100) / 100;
                }

                if (typeof data[i].AE_Consumption_HFO !== 'undefined' && data[i].AE_Consumption_HFO !== null) {
                    data[i].AE_Consumption_HFO = Math.round(data[i].AE_Consumption_HFO * 100) / 100;
                }
                if (typeof data[i].AE_Consumption_LFO !== 'undefined' && data[i].AE_Consumption_LFO !== null) {
                    data[i].AE_Consumption_LFO = Math.round(data[i].AE_Consumption_LFO * 100) / 100;
                }
                if (typeof data[i].AE_Consumption_MGO !== 'undefined' && data[i].AE_Consumption_MGO !== null) {
                    data[i].AE_Consumption_MGO = Math.round(data[i].AE_Consumption_MGO * 100) / 100;
                }
                if (typeof data[i].AE_Consumption_MGO !== 'undefined' && data[i].AE_Consumption_MGO !== null) {
                    data[i].AE_Consumption_MGO = Math.round(data[i].AE_Consumption_MGO * 100) / 100;
                }
                if (typeof data[i].AE_Consumption_LNG !== 'undefined' && data[i].AE_Consumption_LNG !== null) {
                    data[i].AE_Consumption_LNG = Math.round(data[i].AE_Consumption_LNG * 100) / 100;
                }

                if (typeof data[i].Boiler_Consumption_HFO !== 'undefined' && data[i].Boiler_Consumption_HFO !== null) {
                    data[i].Boiler_Consumption_HFO = Math.round(data[i].Boiler_Consumption_HFO * 100) / 100;
                }
                if (typeof data[i].Boiler_Consumption_LFO !== 'undefined' && data[i].Boiler_Consumption_LFO !== null) {
                    data[i].Boiler_Consumption_LFO = Math.round(data[i].Boiler_Consumption_LFO * 100) / 100;
                }
                if (typeof data[i].Boiler_Consumption_MGO !== 'undefined' && data[i].Boiler_Consumption_MGO !== null) {
                    data[i].Boiler_Consumption_MGO = Math.round(data[i].Boiler_Consumption_MGO * 100) / 100;
                }
                if (typeof data[i].Boiler_Consumption_MDO !== 'undefined' && data[i].Boiler_Consumption_MDO !== null) {
                    data[i].Boiler_Consumption_MDO = Math.round(data[i].Boiler_Consumption_MDO * 100) / 100;
                }

                if (typeof data[i].Cargo_heating_Consumption_HFO !== 'undefined' && data[i].Cargo_heating_Consumption_HFO !== null) {
                    data[i].Cargo_heating_Consumption_HFO = Math.round(data[i].Cargo_heating_Consumption_HFO * 100) / 100;
                }
                if (typeof data[i].Cargo_heating_Consumption_LFO !== 'undefined' && data[i].Cargo_heating_Consumption_LFO !== null) {
                    data[i].Cargo_heating_Consumption_LFO = Math.round(data[i].Cargo_heating_Consumption_LFO * 100) / 100;
                }
                if (typeof data[i].Cargo_heating_Consumption_MGO !== 'undefined' && data[i].Cargo_heating_Consumption_MGO !== null) {
                    data[i].Cargo_heating_Consumption_MGO = Math.round(data[i].Cargo_heating_Consumption_MGO * 100) / 100;
                }
                if (typeof data[i].Cargo_heating_Consumption_MDO !== 'undefined' && data[i].Cargo_heating_Consumption_MDO !== null) {
                    data[i].Cargo_heating_Consumption_MDO = Math.round(data[i].Cargo_heating_Consumption_MDO * 100) / 100;
                }

                if (typeof data[i].Inert_gas_Consumption_HFO !== 'undefined' && data[i].Inert_gas_Consumption_HFO !== null) {
                    data[i].Inert_gas_Consumption_HFO = Math.round(data[i].Inert_gas_Consumption_HFO * 100) / 100;
                }
                if (typeof data[i].Inert_gas_Consumption_LFO !== 'undefined' && data[i].Inert_gas_Consumption_LFO !== null) {
                    data[i].Inert_gas_Consumption_LFO = Math.round(data[i].Inert_gas_Consumption_LFO * 100) / 100;
                }
                if (typeof data[i].Inert_gas_Consumption_MGO !== 'undefined' && data[i].Inert_gas_Consumption_MGO !== null) {
                    data[i].Inert_gas_Consumption_MGO = Math.round(data[i].Inert_gas_Consumption_MGO * 100) / 100;
                }
                if (typeof data[i].Inert_gas_Consumption_MDO !== 'undefined' && data[i].Inert_gas_Consumption_MDO !== null) {
                    data[i].Inert_gas_Consumption_MDO = Math.round(data[i].Inert_gas_Consumption_MDO * 100) / 100;
                }
                if (typeof data[i].Inert_gas_Consumption_LNG !== 'undefined' && data[i].Inert_gas_Consumption_LNG !== null) {
                    data[i].Inert_gas_Consumption_LNG = Math.round(data[i].Inert_gas_Consumption_LNG * 100) / 100;
                }

                if (typeof data[i].HFO_ROB !== 'undefined' && data[i].HFO_ROB !== null) {
                    data[i].HFO_ROB = Math.round(data[i].HFO_ROB * 100) / 100;
                }
                if (typeof data[i].LFO_ROB !== 'undefined' && data[i].LFO_ROB !== null) {
                    data[i].LFO_ROB = Math.round(data[i].LFO_ROB * 100) / 100;
                }
                if (typeof data[i].MGO_ROB !== 'undefined' && data[i].MGO_ROB !== null) {
                    data[i].MGO_ROB = Math.round(data[i].MGO_ROB * 100) / 100;
                }
                if (typeof data[i].MDO_ROB !== 'undefined' && data[i].MDO_ROB !== null) {
                    data[i].MDO_ROB = Math.round(data[i].MDO_ROB * 100) / 100;
                }
                if (typeof data[i].LNG_ROB !== 'undefined' && data[i].LNG_ROB !== null) {
                    data[i].LNG_ROB = Math.round(data[i].LNG_ROB * 100) / 100;
                }

                if (findIssues) {
                    // Create array for checking false ports
                    if (typeof sorted[data[i].name] === 'undefined') {
                        sorted[data[i].name] = [];
                    }
                    sorted[data[i].name].push(data[i]);
                }
            }
            if (findIssues) {
                // Sort based on etd
                let issues = [];
                for (const key in sorted) {
                    if (Object.hasOwnProperty.call(sorted, key)) {
                        let lastDepArr;
                        let lastArrival;
                        let Cargo_Mt;
                        const voyages = sorted[key].sort((a, b) => ((moment(a.Date_UTC + ' ' + a.Time_UTC).valueOf() > moment(b.Date_UTC + ' ' + b.Time_UTC).valueOf()) ? 1 : ((moment(a.Date_UTC + ' ' + a.Time_UTC).valueOf() < moment(b.Date_UTC + ' ' + b.Time_UTC).valueOf()) ? -1 : 0)));
                        for (let i = 0, il = voyages.length-1; i < il; i++) {
                            if (voyages[i].Event === 'Departure' || voyages[i].Event === 'DepartureSTS') {
                                Cargo_Mt = voyages[i].Cargo_Mt;
                            }
                            if (voyages[i].Event === voyages[i + 1].Event && voyages[i].Event !== 'Noon') {
                                issues.push({
                                  id: voyages[i + 1].id,
                                  type: 'Two events of the same type after each other'
                                });
                              } else if (voyages[i].Event === 'Departure' && voyages[i + 1].Event === 'End anchoring') {
                                issues.push({
                                  id: voyages[i + 1].id,
                                  type: 'End achoring cannot be after departure to port'
                                });
                              } else if (voyages[i].Event === 'Arrival' && (voyages[i + 1].Event === 'Begin anchoring' || voyages[i + 1].Event === 'End anchoring')) {
                                issues.push({
                                  id: voyages[i + 1].id,
                                  type: 'Achoring cannot be after arrival'
                                });
                              } else if (voyages[i].Event === 'Begin anchoring' && (voyages[i + 1].Event === 'Arrival' || voyages[i + 1].Event === 'Departure')) {
                                issues.push({
                                  id: voyages[i].id,
                                  type: 'End achoring missing before departure or arrival'
                                });
                              } else if (lastDepArr && voyages[i].Event === lastDepArr) { // Dep and Arr must be every second -> 2 dep or arr in a row
                                issues.push({
                                  id: voyages[i].id,
                                  type: 'Two departures or arrivals after each other'
                                });
                              } else if (Math.abs(voyages[i + 1].Time_Since_Previous_Report - moment.utc(voyages[i + 1].Date_UTC + ' ' + voyages[i + 1].Time_UTC).diff(moment.utc(voyages[i].Date_UTC + ' ' + voyages[i].Time_UTC), 'hours', true)) > 0.1) {
                                // console.log(voyages[i + 1].Time_Since_Previous_Report, moment(voyages[i + 1].Date_UTC + ' ' + voyages[i + 1].Time_UTC).diff(moment(voyages[i].Date_UTC + ' ' + voyages[i].Time_UTC), 'hours', true), ( moment(voyages[i + 1].Date_UTC + ' ' + voyages[i + 1].Time_UTC).unix() -moment(voyages[i].Date_UTC + ' ' + voyages[i].Time_UTC).unix())/3600);
                                const timeDiff = Math.abs(voyages[i + 1].Time_Since_Previous_Report - moment.utc(voyages[i + 1].Date_UTC + ' ' + voyages[i + 1].Time_UTC).diff(moment.utc(voyages[i].Date_UTC + ' ' + voyages[i].Time_UTC), 'hours', true));
                                // console.log(voyages[i + 1].Date_UTC + ' ' + voyages[i + 1].Time_UTC + ' - ' + voyages[i].Date_UTC + ' ' + voyages[i].Time_UTC + ' - ' + moment.utc(voyages[i + 1].Date_UTC + ' ' + voyages[i + 1].Time_UTC).diff(moment.utc(voyages[i].Date_UTC + ' ' + voyages[i].Time_UTC), 'hours', true))
                                issues.push({
                                  id: voyages[i + 1].id,
                                  type: 'Time since previous report (' + voyages[i + 1].Time_Since_Previous_Report + 'h) does not match time between consecutive events (' + Math.round(timeDiff*100)/100 + 'h)' 
                                });
                              } else if (typeof Cargo_Mt !== 'undefined' && voyages[i].Cargo_Mt !== Cargo_Mt) {
                                // console.log('Cargo MT has changed during a voyage: ' + voyages[i].Cargo_Mt + ' != ' + Cargo_Mt);
                                issues.push({
                                  id: voyages[i].id,
                                  type: 'Cargo MT has changed during a voyage'
                                });
                              } else if (lastArrival && voyages[i].Event === 'Departure' && lastArrival.Voyage_To !== voyages[i].Voyage_From) { 
                                issues.push({
                                  id: voyages[i].id,
                                  type: 'Departure port is different from previous arrival'
                                });
                              }

                            if (voyages[i].Event === 'Arrival' || voyages[i].Event === 'Departure') {
                                lastDepArr = voyages[i].Event;
                                if (voyages[i].Event === 'Arrival') {
                                    lastArrival = voyages[i];
                                }
                            }

                        }    
                    }
                }
                // console.log('ISSUES:', issues);
                data = data.map(o => {
                    o.issue = 'Check OK';
                    return o;
                });
                if (issues.length > 0) {
                    for (const issue of issues) {
                        const index = data.findIndex(o => o.id === issue.id);
                        if (index > -1) {
                            data[index].issue = issue.type;
                        }
                    }
                }
            }
            
        }
        data.sort((a, b) => ((moment(a.Date_UTC + ' ' + a.Time_UTC).valueOf() < moment(b.Date_UTC + ' ' + b.Time_UTC).valueOf()) ? 1 : ((moment(a.Date_UTC + ' ' + a.Time_UTC).valueOf() > moment(b.Date_UTC + ' ' + b.Time_UTC).valueOf()) ? -1 : 0)));
        return data;
    }
}
