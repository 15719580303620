import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from './views/Dashboard.vue';
import EcdisRoutes from './views/EcdisRoutes.vue';
import LoginPage from './views/LoginPage.vue';
import BoqaEvents from './views/BoqaEvents.vue';
import BoqaSettings from './views/BoqaSettings.vue';
import Analytics from './views/Analytics.vue';
import Performance from './views/Performance.vue';
import FleetMap from './views/FleetMap.vue';
import Ships from './views/Ships.vue';
import Terminals from './views/Terminals.vue';
import Users from './views/Users.vue';
import Contacts from './views/Contacts.vue';
import MoneyItems from './views/MoneyItems.vue';
import ProjectTypes from './views/ProjectTypes.vue';
import ProjectScheduler from './views/ProjectScheduler.vue';
import Log from './views/Log.vue';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
        },
        {
            path: '/login',
            name: 'LoginPage',
            component: LoginPage,
        },
        {
            path: '/routes',
            name: 'EcdisRoutes',
            component: EcdisRoutes,
        },
        {
            path: '/events',
            name: 'BoqaEvents',
            component: BoqaEvents,
        },
        {
            path: '/events/:id',
            name: 'BoqaEventsId',
            component: BoqaEvents,
        },
        {
            path: '/settings',
            name: 'BoqaSettings',
            component: BoqaSettings,
        },
        {
            path: '/map',
            name: 'Map',
            component: FleetMap,
        },
        {
            path: '/analytics',
            name: 'Analytics',
            component: Analytics,
        },
        {
            path: '/performance/',
            name: 'Performance',
            component: Performance,
        },
        {
            path: '/performance/:shipId',
            name: 'PerformanceCii',
            component: Performance,
        },
        {
            path: '/ships',
            name: 'Ships',
            component: Ships,
        },
        {
            path: '/terminals',
            name: 'Terminals',
            component: Terminals,
        },
        {
            path: '/users',
            name: 'Users',
            component: Users,
        },
        {
            path: '/contacts',
            name: 'Contacs',
            component: Contacts,
        },
        {
            path: '/projecttypes',
            name: 'ProjectTypes',
            component: ProjectTypes,
        },
        {
            path: '/moneyitems',
            name: 'MoneyItems',
            component: MoneyItems,
        },
        {
            path: '/desk',
            name: 'ProjectScheduler',
            component: ProjectScheduler,
        },
        {
            path: '/desk/:schedulerEventId',
            name: 'ProjectSchedulerId',
            component: ProjectScheduler,
        },
        {
            path: '/log',
            name: 'Log',
            component: Log,
        },
        // otherwise redirect to home
        {
            path: '*',
            redirect: '/dashboard'
        }
    ],
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
    if (typeof to.params.id !== 'undefined' && !isNaN(to.params.id)) {
        localStorage.setItem('eventId', to.params.id);
    } else if (typeof to.params.schedulerEventId !== 'undefined' && !isNaN(to.params.schedulerEventId)) {
        localStorage.setItem('schedulerEventId', to.params.schedulerEventId);
    }

    if (authRequired && !loggedIn) {
        return next('/login');
    }

    next();
});

export default router;
