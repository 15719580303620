<template>
    <div id="child" style="margin-left:10px;">
         <ejs-numerictextbox style="width:70px;vertical-align:-webkit-baseline-middle" v-model="data" placeholder="ETS" format="n2" :step="0.01" :min="-100" :max="100"></ejs-numerictextbox>
         <button class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib" type="button" id="Grid_setEts" tabindex="-1" aria-label="Set ETS" style="width: auto;margin-left:5px;display: inline-block;">
            <span @click="doCustomAction()" class="e-tbar-btn-text">Set ETS</span>
        </button>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { NumericTextBoxPlugin } from '@syncfusion/ej2-vue-inputs';

    import {
        eventBus
    } from '../../main';

    Vue.use(NumericTextBoxPlugin);

    export default {
        data() {
            return {
                data: 0,
            };
        },
        mounted() {
            this.data = localStorage.getItem('rateEts') ? localStorage.getItem('rateEts') : 0;
        },
        methods: {
            doCustomAction() {
                eventBus.$emit('setEts', this.data);
                localStorage.setItem('rateEts', this.data);
            }
        }
    };
</script>

<style>
    .e-baf::before {
      content: '\e7ff';
    }
</style>
