<template>
    <div id="child" style="margin-top:7px">
        <ejs-dropdownlist name="userDropdown" width="500px" ref="selectInstance" id="dropdownlist" :filterType="filterType" :allow-filtering="true" :showClearButton="true" v-model="data.moneyItemId"
            :select="doCustomAction.bind(this)" :placeholder="placeholder" :dataSource="items" :itemTemplate="itemTemplate"
            :fields="fields">
        </ejs-dropdownlist>
        <!-- <ejs-autocomplete name="userDropdown" width="500px" ref="selectInstance" id="dropdownlist" :showClearButton="true" v-model="data.moneyItemId"
            :select="doCustomAction.bind(this)" :placeholder="placeholder" :dataSource="items" :itemTemplate='itemTemplate'
            :fields="fields">
        </ejs-autocomplete> -->
    </div>
</template>

<script>
    import {
        store
    } from '../../store';
    import {
        eventBus
    } from '../../main';
    import MoneyItemRow from './MoneyItemRow.vue';

    export default {
        store,
        data() {
            return {
                data: {},
                items: [],
                placeholder: 'Select',
                filterType: 'Contains',
                fields: {
                    text: 'name',
                    value: 'id',
                    // value: 'name',
                    groupBy: 'type',
                },
                itemTemplate() {
                    return {
                        template: MoneyItemRow
                    };
                },
            };
        },
        mounted() {
            this.items = store.state.data.schedulerParams.moneyItems.filter((o) => o.active === 1);
            if (this.data && this.data.moneyItemName) {
                this.placeholder = this.data.moneyItemName;
            }
        },
        methods: {
            doCustomAction(args) {
                if (args && args.itemData) {
                    eventBus.$emit('moneyItem', args.itemData);
                }
            },
           
        }
    };
</script>

<style>
</style>
