<template>
    <span>
        <span class="name">{{ data.name }}</span> 
        <span class="smaller"> - {{ data.typeName }}</span> 
    </span>
</template>

<script>

    export default {
        data() {
            return {
                data: {},
            };
        },
        methods: {
            
        }
    };
</script>

<style scoped>
    .name {
        font-weight: 500;
    }
    .smaller {
        font-size: smaller;
    }
</style>
