<template>
    <div id="embedContainer">
        <div v-if="reports && reports.type==='iframe'" style="width:100%;height:100%;position: relative;">
            <v-alert v-model="loginAlert" v-if="reports.user && reports.password" dense type="info" dismissible class="mb-0" style="position: absolute; width: 100%;">
                Please login with user <b>{{ reports.user }}</b> and password <b>{{ reports.password }}</b> if login is required
            </v-alert>
            <iframe v-if="reports.url" :style="loginAlert ? 'padding-top:35px': ''" style="width:100%;height:100%" :src="reports.url" frameborder="0" allowFullScreen="true"></iframe>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    // import * as pbi from 'powerbi-client';

    export default {
        name: 'Reports',
        components: {
        },
        data: () => ({
            loginAlert: true,
        }),
        mounted() {
            // Set up the configuration object that determines what to embed and how to embed it.

            // const models = pbi.models;

            // let embedConfiguration = {
            //     authenticationMode: 'ServicePrincipal',
            //     clientId: '72efaacd-d385-4785-817c-017579d143ef',
            //     clientSecret: '2f9d7cef-7daf-4ad4-92a6-aa73e915411a',
            //     tokenType: models.TokenType.Embed,
            //     permissions: models.Permissions.Read,
            //     workspaceId: '',
            //     tenantId: '4edbc869-d583-4aad-a20f-554179dbfcb4',
            //     embedUrl: 'https://app.powerbi.com/reportEmbed',
            //     id: '51664970-eeac-4be2-ba0d-c1613b50a5ca',
            //     type: 'report'
            // };
            

            // // Get a reference to the HTML element that contains the embedded report.
            // let embedContainer = document.getElementById('embedContainer');
            
            // const powerbi = new pbi.service.Service(
            //     pbi.factories.hpmFactory,
            //     pbi.factories.wpmpFactory,
            //     pbi.factories.routerFactory)
            // // Embed the report.
            // let report =  powerbi.embed(embedContainer, embedConfiguration);
            // console.log(report);
        },
        watch: {
        
        },
        computed: {
            reports() {
                const user = this.getUser();
                if (user && user.user && user.user.reports) {
                    return user.user.reports;
                } else {
                    return null;
                }
            }
        },
        beforeDestroy() {
        
        },
        methods: {
            ...mapActions({
             
            }),
            ...mapGetters({
                getUser: 'authentication/getUser'
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
                alertError: 'alert/error',
                alertSuccess: 'alert/success',
            }),
           
           
        }
    };
</script>

<style>
</style>
<style scoped>
    #embedContainer {
        top: 0;
        bottom:0;
        left:0;
        right:0;
        position: absolute;
    }
</style>
