<template>
    <div v-if="typeof features.scheduler !== 'undefined' && features.scheduler === 1">
        <v-toolbar dense height="55">
            <!-- <v-toolbar-side-icon></v-toolbar-side-icon> -->
            <v-toolbar-title>Desk</v-toolbar-title>
            <v-spacer></v-spacer>

            <!--<v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-badge overlap left color="red">
                        <template v-slot:badge>
                            {{ autoCommit ? 'A' : 'M' }}
                        </template>
                        <v-btn icon v-on="on">
                            <v-icon @click="setAutoCommitMode()">save</v-icon>
                        </v-btn>
                    </v-badge>
                </template>
                <span>{{ autoCommit ? 'Auto save mode on' : 'Manual save mode on' }}</span>
            </v-tooltip>
            <span v-show="!autoCommit">
                <v-badge overlap left>
                    <template v-slot:badge>
                        {{ undoBadge }}
                    </template>
                    <v-btn :disabled="undoDisabled" icon>
                        <v-icon @click="undo()">undo</v-icon>
                    </v-btn>
                </v-badge>
                <v-badge overlap>
                    <template v-slot:badge>
                        {{ redoBadge }}
                    </template>
                    <v-btn :disabled="redoDisabled" icon>
                        <v-icon @click="redo()">redo</v-icon>
                    </v-btn>
                </v-badge>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="undoDisabled && redoDisabled" icon v-on="on">
                            <v-icon @click="undoAll()">cancel</v-icon>
                        </v-btn>
                    </template>
                    <span>Clear changes and reset history</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="autoCommit || undoDisabled" icon v-on="on">
                            <v-icon @click="commitChanges()">save</v-icon>
                        </v-btn>
                    </template>
                    <span>Commit changes</span>
                </v-tooltip>
            </span>-->
            <v-select
                class="mt-5 mx-4"
                v-if="mode !== 'table' && mode !== 'cargo'"
                v-model="favorites"
                :items="resources"
                label="Favorite resources"
                multiple
                dense
                item-text="name"
                item-value="id"
                style="max-width:200px"
                @change="filterFavorites"
            >
            <template #selection="{ index }">
                <span v-if="index === 0"
                    class="text-caption"
                    >
                    {{ favorites.length }} / {{ resources.length }} selected
                </span>
            </template>
            </v-select>
            <v-autocomplete @input="openEventManually(search)" v-model="search" :loading="searchLoading" :items="searchedProjects"
                :item-text="searchItemText" item-key="id" :clearable="true" :search-input.sync="projectSearch" cache-items return-object text
                hide-no-data hide-details label="Search" dense style="max-width:200px">
            </v-autocomplete>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn icon :disabled="mode === 'table' || mode === 'cargo'" v-on="on" @click="switchViewPreset('Monthly')" :color="preset==='Monthly' ? 'primary' : ''">
                        <span style="font-size:16px">M</span>
                    </v-btn>
                </template>
                <span>{{ preset==='Monthly' ? 'Montly view on' : 'Switch to monthly view' }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn icon :disabled="mode === 'table' || mode === 'cargo'" v-on="on" @click="switchViewPreset('Weekly')" :color="preset==='Weekly' ? 'primary' : ''">
                        <span style="font-size:16px">W</span>
                    </v-btn>
                </template>
                <span>{{ preset==='Weekly' ? 'Weekly view on' : 'Switch to weekly view' }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn icon :disabled="mode === 'table' || mode === 'cargo'" v-on="on" @click="switchViewPreset('Daily')" :color="preset==='Daily' ? 'primary' : ''">
                        <span style="font-size:16px">D</span>
                    </v-btn>
                </template>
                <span>{{ preset==='Daily' ? 'Daily view on' : 'Switch to daily view' }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <!-- <v-badge overlap right dot offset-x="17" offset-y="20" :color="autoUpdate ? 'green' : 'red'"> -->
                    <v-btn icon v-on="on" :disabled="mode === 'table' || mode === 'cargo'" :color="autoUpdate ? 'primary' : ''">
                        <v-icon @click="autoUpdate=!autoUpdate">cached</v-icon>
                    </v-btn>
                    <!-- </v-badge> -->
                </template>
                <span>{{ autoUpdate ? 'Auto refresh on' : 'Auto refresh off' }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn icon :disabled="mode === 'table' || mode === 'cargo'" v-on="on">
                        <v-icon @click="reload()">refresh</v-icon>
                    </v-btn>
                </template>
                <span>Reload scheduler events</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn icon :disabled="mode === 'table' || mode === 'cargo'" v-on="on">
                        <v-icon @click="initialView()">today</v-icon>
                    </v-btn>
                </template>
                <span>Return to initial scroll</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <!-- <v-badge overlap right dot offset-x="17" offset-y="20" :color="mode==='vertical' ? 'green' : 'red'"> -->
                    <v-btn icon v-on="on" :color="mode==='vertical' ? 'primary' : ''">
                        <v-icon @click="switchMode('vertical')">view_column</v-icon>
                    </v-btn>
                    <!-- </v-badge> -->
                </template>
                <span>{{ mode==='vertical' ? 'Vertical mode on' : 'Switch to vertical mode' }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <!-- <v-badge overlap right dot offset-x="17" offset-y="20" :color="mode==='horizontal' ? 'green' : 'red'"> -->
                    <v-btn icon v-on="on" :color="mode==='horizontal' ? 'primary' : ''">
                        <v-icon @click="switchMode('horizontal')">view_list</v-icon>
                    </v-btn>
                    <!-- </v-badge> -->
                </template>
                <span>{{ mode==='horizontal' ? 'Horizontal mode on' : 'Switch to horizontal mode' }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <!-- <v-badge overlap right dot offset-x="17" offset-y="20" :color="mode==='table' ? 'green' : 'red'"> -->
                    <v-btn icon v-on="on" :color="mode==='table' ? 'primary' : ''">
                        <v-icon @click="switchMode('table')">list_alt</v-icon>
                    </v-btn>
                    <!-- </v-badge> -->
                </template>
                <span>{{ mode==='table' ? 'Table mode on' : 'Switch to table mode' }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn icon v-on="on" :color="mode==='cargo' ? 'primary' : ''">
                        <v-icon @click="switchMode('cargo')">mdi-list-box</v-icon>
                    </v-btn>
                </template>
                <span>{{ mode==='cargo' ? 'Cargo mode on' : 'Switch to cargo mode' }}</span>
            </v-tooltip>
        </v-toolbar>

        <!-- Components -->
        <bryntum-scheduler v-if="mode === 'vertical' || mode === 'horizontal'" ref="scheduler" :key="key" :mode="mode" @beforeeventedit="beforeEventEditHandler"
            @beforeeventdropfinalize="beforeeventdropfinalize" v-bind="schedulerConfig"></bryntum-scheduler>

        <SchedulerTable v-if="mode === 'table'" :key="schedulerTableKey" :events="events" :resources="resources" :params="params"></SchedulerTable>
        <SchedulerTableCargo v-if="mode === 'cargo'" :key="schedulerTableCargoKey" :events="events"></SchedulerTableCargo>

        <SchedulerEditor v-model="showEditor" v-if="showEditor" :eventRecord="eventRecord" :eventStore="eventStore" :events="events" :resource="resource"
            :params="params" @close="onCloseEditor"></SchedulerEditor>

        <StatusDialog />
        <ProgressCircular />
</div>
</template>

<script>
    import {
        mapGetters,
        mapActions,
        mapMutations,
    } from 'vuex';
    import moment from 'moment';
    import { BryntumScheduler } from '@bryntum/scheduler-vue';
    // import { StateTrackingManager } from '@bryntum/scheduler';
    import schedulerConfig from '../components/Scheduler/schedulerConfig';
    import SchedulerEditor from '../components/Scheduler/SchedulerEditor.vue';
    import SchedulerTable from '../components/Scheduler/SchedulerTable.vue';
    import SchedulerTableCargo from '../components/Scheduler/SchedulerTableCargo.vue';
    import StatusDialog from '../components/StatusDialog.vue';
    import ProgressCircular from '../components/ProgressCircular.vue';
    import {
        eventBus
    } from '../main';

    import {
        config,
        shipHelpers,
    } from '../helpers';

    // let stm;
    let cloneCount = 0;

    export default {
        name: 'ProjectScheduler',
        components: {
            StatusDialog,
            ProgressCircular,
            BryntumScheduler,
            SchedulerEditor,
            SchedulerTable,
            SchedulerTableCargo,
        },
        data() {
            return {
                schedulerConfig,
                mode: 'horizontal',
                preset: 'Weekly',
                dark: false,
                key: 0,
                showEditor: false,
                eventRecord: null,
                eventStore: null,
                events: null,
                resource: {},
                resources: [],
                autoCommit: true,
                autoUpdate: false,
                updateInterval: null,
                commitModes: [{
                    name: 'Manual save',
                    value: false
                }, {
                    name: 'Auto save',
                    value: true
                }],
                // undoBadge: 0,
                // undoDisabled: true,
                // redoBadge: 0,
                // redoDisabled: true,
                projects: [],
                schedulerTableKey: 0,
                schedulerTableCargoKey: 0,
                schedulerEventId: null,
                search: null,
                searchedProjects: [],
                searchLoading: false,
                projectSearch: null,
                favorites: [],
            };
        },
        computed: {
            params() {
                return this.getSchedulerParams();
            },
            ...mapGetters({
                features: 'authentication/features',
                getDarkMode: 'users/getDarkMode',
            }),
        },
        watch: {
            getDarkMode() {
                setTimeout(() => {
                    this.key++;
                    setTimeout(() => {
                        this.initialView();
                        this.loadEvents();
                        // stm.resetQueue();
                        // stm.addStore(this.$refs.scheduler.schedulerInstance.eventStore);
                    }, 200);
                }, 500);
            },
            projectSearch(val) {
                val && val !== this.select && this.projectQuerySelections(val);
            },
            resources(newVal) {
                if (newVal && newVal.length > 0) {
                    // Favorite resources    
                    try {
                        const favorites = localStorage.getItem('deskFavorites');
                        this.favorites = JSON.parse(favorites);
                        if (this.favorites && Array.isArray(this.favorites) && this.favorites.length > 0) { 
                            // Filter out new deactivated resources
                            if (this.resources && this.resources.length > 0) {
                                this.favorites = this.favorites.filter(o => this.resources.some(e => e.id === o));
                            }
                            // Filter favorites
                            this.filterFavorites();
                        } else {
                            localStorage.removeItem('deskFavorites');
                            if (this.resources && this.resources.length > 0) {
                                this.favorites = this.resources.map((o) => o.id);
                            }
                        }
                    } catch (e) {
                        localStorage.removeItem('deskFavorites');
                        if (this.resources && this.resources.length > 0) {
                            this.favorites = this.resources.map((o) => o.id);
                        }
                        console.log('Failed to parse favorites from localstorage', e);
                    }
                    // console.log(this.favorites);
                }
            }
        },
        mounted() {
            // if (Object.entries(this.getSchedulerParams()).length === 0 && this.getSchedulerParams().constructor === Object) {
            this.fetchSchedulerParams();
            // }
            if (Object.entries(this.getExchangeRates()).length === 0 && this.getExchangeRates().constructor === Object) {
                this.fetchExchangeRates();
            }
            if (localStorage.getItem('schedulermode') && localStorage.getItem('schedulermode') !== 'horizontal') {
                setTimeout(() => {
                    this.switchMode(localStorage.getItem('schedulermode'));
                }, 1500);
            }
            if (localStorage.getItem('viewPreset') && localStorage.getItem('viewPreset') !== 'Weekly') {
                setTimeout(() => {
                    this.switchViewPreset(localStorage.getItem('viewPreset'));
                }, 1000);
            }
            
            // Open pre selected event
            this.schedulerEventId = this.$route.params.schedulerEventId;
            localStorage.removeItem('schedulerEventId');

            setTimeout(() => {
                this.initialView();
                // this.loadEvents();
                this.resources = this.$refs.scheduler.schedulerInstance.resources;
                console.log(this.$refs.scheduler.schedulerInstance.resources);
                // if (!this.resources || this.resources.length === 0) {
                //     setTimeout(() => {
                //         this.resources = this.$refs.scheduler.schedulerInstance.resources;
                //         console.log(this.$refs.scheduler.schedulerInstance.resources);
                //     }, 2000);
                // }
                this.loadEvents();
            }, 700);

            // Start interval for auto update
            this.updateInterval = setInterval(() => {
                if (this.autoUpdate) {
                    this.loadEvents();
                }
            }, 60 * 1000);


            // const vm = this;
            // stm = new StateTrackingManager({
            //     autoRecord: true,
            //     listeners: {
            //         recordingstop() {
            //             updateUndoRedoControls();
            //         },
            //         restoringstop() {
            //             updateUndoRedoControls();
            //         },
            //         queueReset() {
            //             updateUndoRedoControls();
            //         }
            //     },
            // });
            // stm.addStore(this.$refs.scheduler.schedulerInstance.eventStore);
            // stm.enable();

            // function updateUndoRedoControls() {
            //     vm.undoBadge = stm.position;
            //     vm.redoBadge = stm.length - stm.position;
            //     vm.undoDisabled = !stm.canUndo;
            //     vm.redoDisabled = !stm.canRedo;
            // }

            eventBus.$on('cloneProject', (e) => {
                if (e && typeof e.type !== 'undefined' && e.eventRecord) {
                    if (Number.isInteger(e.eventRecord.id) && cloneCount === 0) {
                        ++cloneCount;
                        // console.log(cloneCount);
                        this.cloneProject({
                            id: e.eventRecord.id,
                            type: e.type
                        }).then(() => {
                            this.loadEvents();
                            cloneCount = 0;
                        });
                    }
                }
            });

            eventBus.$on('onBeforeCommit', () => {
                this.progressOn();
            });
            eventBus.$on('commit', () => {
                this.loadEvents();
            });
            eventBus.$on('tableEdit', (e) => {
                if (e.isVoyage === 2) {
                    this.showEditor = false;
                } else {
                    Object.assign(this, {
                        eventStore: null,
                        events: this.events,
                        resource: e.resource,
                        eventRecord: e.event,
                        showEditor: true
                    });
                }
            });
        },
        updated() {
             eventBus.$on('deleteProject', (data) => {
                console.log('deleteProject received with id: ' + data.id);
                if (data && data.id) {
                    this.deleteProject({
                        ids: [data.id]
                    }).then(() => {
                        this.loadEvents();
                    });
                }
            });
        },
        beforeDestroy() {
            eventBus.$off('cloneProject');
            eventBus.$off('onBeforeCommit');
            eventBus.$off('commit');
            eventBus.$off('tableEdit');
            eventBus.$off('deleteProject');
            if (this.updateInterval) {
                clearInterval(this.updateInterval);
            }
        },
        methods: {
            ...mapGetters({
                getSchedulerParams: 'data/getSchedulerParams',
                getExchangeRates: 'data/getExchangeRates',
            }),
            ...mapActions({
                fetchSchedulerParams: 'data/fetchSchedulerParams',
                fetchExchangeRates: 'data/fetchExchangeRates',
                fetchSchedulerRoutes: 'data/fetchSchedulerRoutes',
                fetchPortCalls: 'data/fetchPortCalls',
                fetchProject: 'data/fetchProject',
                cloneProject: 'data/cloneProject',
                deleteProject: 'data/deleteProject',
                searchProject: 'data/searchProject',
                fetchResource: 'data/fetchResource',
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
                alertError: 'alert/error',
                alertClear: 'alert/clear',
                progressOn: 'data/progressOn',
                progressOff: 'data/progressOff',
            }),
            switchMode(mode) {
                if (this.mode === mode) {
                    return;
                }
                this.mode = mode;
                localStorage.setItem('schedulermode', this.mode);
                this.preset = 'Weekly';
                if (mode === 'vertical' || mode === 'horizontal') {
                    this.key++;
                    setTimeout(() => {
                        this.filterFavorites();
                        this.initialView();
                        // this.loadEvents();
                        // stm.resetQueue();
                        // stm.addStore(this.$refs.scheduler.schedulerInstance.eventStore);
                            this.loadEvents();
                    }, 500);
                } else if (mode === 'table') {
                    if (this.updateInterval) {
                        // this.resources = this.$refs.scheduler.schedulerInstance.resources;
                        ++this.schedulerTableKey;
                        clearInterval(this.updateInterval);
                        this.updateInterval = null;
                        this.autoUpdate = false;
                    }
                } else if (mode === 'cargo') {
                    if (this.updateInterval) {
                        ++this.schedulerTableCargoKey;
                        clearInterval(this.updateInterval);
                        this.updateInterval = null;
                        this.autoUpdate = false;
                    }
                }
            },
            loadEvents(startDate, endDate) {
                if (this.$refs.scheduler && this.$refs.scheduler.schedulerInstance && !this.showEditor) {
                    startDate = startDate ? moment(startDate).format(config.dateFormat) : moment(this.$refs.scheduler.schedulerInstance.startDate).format(config
                        .dateFormat);
                    endDate = endDate ? moment(endDate).format(config.dateFormat) : moment(this.$refs.scheduler.schedulerInstance.endDate).format(config
                        .dateFormat);
                    this.progressOn();
                    this.$refs.scheduler.schedulerInstance.eventStore.load({
                        startDate,
                        endDate
                    }).then((res) => {
                        // console.log('loadEvents', res);
                        this.events = res.data;
                        this.updateEventValues(res.data);
                        this.progressOff();
                        if (this.schedulerEventId) { // pre selected event form url param
                            this.openEventManually(this.schedulerEventId);
                            this.schedulerEventId = null;
                        }
                    }, (error) => {
                        console.log(error);
                        this.progressOff();
                    });
                }
            },
            updateEventValues(data) {
                const length = data.length;
                let events = [];
                let cargos = [];
                for (let i = 0; i < length; i++) {
                    if (data[i].isVoyage === 1) { // Project type is voyage
                        data[i].resizable = false;
                        if (i > 0 && data[i].prevPort === 1) { // Previous port defined
                            // data[i].draggable = false;
                            // Check previous voyage etd time and create ballast dummy box
                            const voyages = data.filter((o) => data[i].resourceId === o.resourceId && o.isVoyage === 1 && moment(o.endDate).valueOf() <= moment(
                                data[i].startDate
                            ).valueOf());
                            let prevVoyage = null;
                            if (voyages.length > 1) {
                                voyages.sort((a, b) => ((moment(a.endDate).valueOf() > moment(b.endDate).valueOf()) ? 1 : ((moment(a.endDate).valueOf() <
                                    moment(
                                        b.endDate
                                    ).valueOf()) ? -1 : 0)));
                                prevVoyage = voyages[voyages.length - 1];
                            } else if (voyages.length === 1) {
                                prevVoyage = voyages[0];
                            }
                            if (prevVoyage && Math.abs(moment(data[i].startDate).valueOf() - moment(prevVoyage.endDate).valueOf()) > 5 * 60 * 1000) {
                                if (!events.some((o) => o.id === 'ballast_' + data[i].id)) {
                                    events.push({
                                        id: 'ballast_' + data[i].id,
                                        resourceId: data[i].resourceId,
                                        startDate: prevVoyage.endDate,
                                        endDate: data[i].startDate,
                                        resizable: false,
                                        status: data[i].status,
                                        draggable: false,
                                        isVoyage: 2,
                                    });
                                }
                            }
                        }
                    } else if (data[i].id >= 1000000000) { // cargo
                        data[i].resizable = false;
                        data[i].draggable = false;
                        let cargo = shipHelpers.calculateCargo(data[i]);
                        if (cargo.cuf > 0 || cargo.ton > 0) {
                            cargos.push({
                                resourceId: data[i].resourceId.replace('cargo_', 'capacity_'),
                                startDate: data[i].startDate,
                                endDate: data[i].endDate,
                                id: 'capacity_' + data[i].id,
                                dwcc: data[i].dwcc,
                                cuf: data[i].cuf,
                                cargo: cargo,
                            })
                        }
                    }
                    events.push(data[i]);
                }
                let capacity = [];
                let cargosByResource = [];
                // Group cargos by resource
                for (let i = 0, il = cargos.length; i < il; i++) {
                    if (!cargosByResource[cargos[i].resourceId]) {
                        cargosByResource[cargos[i].resourceId] = [];
                    }
                    cargosByResource[cargos[i].resourceId].push(cargos[i]);
                }
                // console.log(cargosByResource);
                // Get all start and end times
                for (const key in cargosByResource) {
                    let startDates = cargosByResource[key].map((o) => o.startDate);
                    let endDates = cargosByResource[key].map((o) => o.endDate);
                    let dates = startDates.concat(endDates);
                    dates.sort()
                    dates = [...new Set(dates)]; // unique only
                    // console.log(dates);
                    for (let i = 1, il = dates.length; i < il; i++) {
                        // Find cargos for this timeperiod
                        let cargos = cargosByResource[key].filter((o) => o.startDate >= dates[i-1] && o.endDate <= dates[i]);
                        let sum = {
                            ton: 0,
                            cuf: 0,
                            tonPercent: 0,
                            cufPercent: 0,
                        };
                        for (const entry of cargos) {
                            sum.ton += entry.cargo.ton;
                            sum.cuf += entry.cargo.cuf;
                            sum.tonPercent += entry.cargo.tonPercent;
                            sum.cufPercent += entry.cargo.cufPercent; 
                        }
                        sum.tonPercent = Math.round(sum.tonPercent);
                        sum.cufPercent = Math.round(sum.cufPercent);
                        capacity.push({
                            resourceId: key,
                            startDate: dates[i-1],
                            endDate: dates[i],
                            id: 'capacity_' + Math.random() + i,
                            resizable: false,
                            draggable: false,
                            cargo: sum,
                        })
                    }

                }
                // console.log(capacity);
                events = events.concat(capacity);
                // console.log(events.filter((o) => o.isVoyage===2))
                if (this.$refs.scheduler) {
                    this.$refs.scheduler.schedulerInstance.events = events;
                }
                // this.$refs.scheduler.schedulerInstance.events = data.map((o) => {
                //     if (o.isVoyage === 1) { // Project type is voyage
                //         o.resizable = false;
                //         if (o.prevPort === 1) { // Previous port defined

                //         }
                //     }
                //     return o;
                // })
            },
            setAutoCommitMode() {
                this.autoCommit = !this.autoCommit;
                this.$refs.scheduler.schedulerInstance.eventStore.autoCommit = this.autoCommit;
                // stm.resetQueue();
            },
            initialView(startDays, endDays) {
                if (this.$refs.scheduler && this.$refs.scheduler.schedulerInstance) {
                    startDays = typeof startDays !== 'undefined' ? startDays : 4 * 7; // Weekly view is default
                    endDays = typeof endDays !== 'undefined' ? endDays : 9 * 7;
                    this.$refs.scheduler.schedulerInstance.setTimeSpan(moment().subtract(startDays, 'day').toDate(), moment().add(endDays, 'day').toDate());
                    // this.$refs.scheduler.schedulerInstance.zoomTo({
                    //     preset: 'dayAndWeek'
                    // });
                    setTimeout(() => {
                        this.$refs.scheduler.schedulerInstance.scrollToNow({
                            block: 'center'
                        });
                    }, 10);
                }
            },
            async openEventManually(event) {
                let eventId = event && typeof event === 'object' && event.id ? event.id : event;
                console.log('Trying to open event', eventId);
                if (eventId) {
                    let eventRecord = null;
                    let res = await this.fetchProject(eventId);
                    if (res.success) {
                        eventRecord = res.data;
                        eventRecord.data = res.data;
                    }
                    if (eventRecord) {
                        console.log('Event record found', eventRecord);
                        let resourceRecord = null;
                        let eventStore = null;
                        if (this.$refs.scheduler && this.$refs.scheduler.schedulerInstance) {
                            resourceRecord = this.$refs.scheduler.schedulerInstance.resources.find((o) => o.id === eventRecord.resourceId);
                            eventStore = this.$refs.scheduler.schedulerInstance.eventStore;
                        } else {
                            resourceRecord = this.resources.find((o) => o.id === eventRecord.resourceId);
                            eventStore = null;
                        }
                        if (!resourceRecord) {
                            console.log('Resource not found. Fetching from server.')
                            resourceRecord = await this.fetchResource(eventRecord.resourceId)
                        }
                        console.log('Resource record found', resourceRecord);
                        if (eventRecord.isVoyage === 2) {
                            this.showEditor = false;
                        }
                        setTimeout(() => {
                            Object.assign(this, {
                                eventStore,
                                resource: resourceRecord,
                                eventRecord,
                                showEditor: true
                            });
                        }, 100);
                    }
                }
            },
            beforeEventEditHandler(event) {
                // console.log('beforeEventEditHandler', event);
                // console.log(event);
                if (event.eventRecord.isVoyage === 2 || event.eventRecord.id.toString().startsWith('capacity_') || event.eventRecord.id > 1000000000) {
                    this.showEditor = false;
                    return false;
                }
                // console.log('isCreating ' + event.eventRecord.isCreating);
                // console.log('isPersistable ' + event.eventRecord.isPersistable);
                // event.eventRecord.isCreating = false;
                // console.log(event.eventRecord.isCreating);
                Object.assign(this, {
                    eventStore: event.source.eventStore,
                    events: this.$refs.scheduler.schedulerInstance.events,
                    resource: event.resourceRecord,
                    eventRecord: event.eventRecord,
                    showEditor: true
                });
                return false;
            },
            beforeeventdropfinalize({ context }) {
                if (context.record.isVoyage === 1) {
                    // const recordId = context.record.id;
                    // // const newResourceId = context.newResource.id;
                    // const timeDiff = context.timeDiff;
                    // console.log(context.record.resourceId,  context.newResource.id);
                    // setTimeout(() => {
                    //     let eventIndex = this.$refs.scheduler.schedulerInstance.events.findIndex((o) => o.id === recordId);
                    //     // this.$refs.scheduler.schedulerInstance.events[eventIndex].newResourceId = newResourceId;
                    //     this.$refs.scheduler.schedulerInstance.events[eventIndex].timeDiff = timeDiff;
                    // }, 2500);
                }
            },
            onCloseEditor() {
                console.log('onCloseEditor fired')
                setTimeout(() => {
                    this.loadEvents();
                }, 500);
                // if (this.$refs.scheduler && this.$refs.scheduler.schedulerInstance) {
                //     this.updateEventValues(this.$refs.scheduler.schedulerInstance.events);
                // }
                // This breaks functionality and event will not be triggered again
                // const engine = this.$refs.scheduler.schedulerInstance;
                // if (engine) {
                //     engine.refresh();
                // }
            },
            commitChanges() {
                console.log('trying to commit');
                // if (stm.length === 0) {
                //     return;
                // }
                this.$refs.scheduler.schedulerInstance.eventStore.commit().then((res) => {
                    console.log('commit result: ', res);
                    if (res && !res.exception) {
                        // stm.resetQueue();
                    } else {
                        this.alertError('Failed to save changes');
                    }
                }).catch((e) => {
                    console.log(e);
                });
            },
            // undo() {
            //     if (stm.canUndo) {
            //         stm.undo();
            //     }
            // },
            // undoAll() {
            //     if (stm.canUndo) {
            //         stm.undoAll();
            //         stm.resetQueue();
            //     }
            // },
            // redo() {
            //     if (stm.canRedo) {
            //         stm.redo();
            //     }
            // },
            reload() {
                // if (stm.length === 0 || this.autoCommit === true) {
                if (this.autoCommit === true) {
                    this.loadEvents();
                    // stm.resetQueue();
                } else {
                    this.alertError('Save unsaved changes first');
                }
            },
            switchViewPreset(preset) {
                if (this.$refs.scheduler && this.$refs.scheduler.schedulerInstance) {
                    this.$refs.scheduler.schedulerInstance.viewPreset = preset;
                    localStorage.setItem('viewPreset', preset);
                    this.preset = preset;
                    if (preset === 'Monthly') {
                        this.initialView(6 * 30, 12 * 30);
                    } else if (preset === 'Weekly') {
                        this.initialView(4 * 7, 9 * 7);
                    } else if (preset === 'Daily') {
                        this.initialView(2, 9 * 7);
                    }
                    setTimeout(() => {
                        this.loadEvents();
                    }, 100);
                }
            },
            searchItemText(item) {
                let shipName = item.shipName ? item.shipName : 'N/A';
                let code = item.code ? item.code : 'N/A';
                let ports = item.ports ? item.ports : 'N/A';
                return `${shipName} — ${code} — ${ports}`;
            },
            projectQuerySelections(v) {
                if (v.length > 2 && !this.searchLoading) {
                    this.searchLoading = true;
                    this.searchProject({
                        query: v
                    }).then((data) => {
                        this.searchedProjects = data;
                        this.searchLoading = false;
                    }).finally(() => {
                        this.searchLoading = false;
                    });
                }
            },
            filterFavorites() {
                localStorage.setItem('deskFavorites', JSON.stringify(this.favorites));
                if (this.$refs.scheduler && this.$refs.scheduler.schedulerInstance) {
                    // console.log(this.favorites);
                    this.$refs.scheduler.schedulerInstance.resourceStore.filterBy(resource => this.favorites.includes(Number.parseInt(resource.id.toString().replace('capacity_', '').replace('cargo_', '')))); 
                } else {
                    setTimeout(() => {
                        if (this.$refs.scheduler && this.$refs.scheduler.schedulerInstance) {
                            this.$refs.scheduler.schedulerInstance.resourceStore.filterBy(resource => this.favorites.includes(Number.parseInt(resource.id.toString().replace('capacity_', '').replace('cargo_', '')))); 
                        }
                    }, 500);
                }
            }
        }
    };
</script>

<style lang="scss">
    .event-default {
        opacity: 1;
        color: black !important;
        font-size: 13px;
        font-weight: 400 !important;
        border: 1px solid #d9d9d9 !important;
        height:100%;
    }

    .b-scheduler-container {
        * {
            background-repeat: repeat; // to fix timeline background when vuetify styles are used
        }
    }

    .b-eventeditor.b-popup {
        display: none !important;
    }

    .v-badge--overlap.v-badge--left .v-badge__badge {
        left: 0px;
        top: -4px;
    }

    .v-badge--overlap .v-badge__badge {
        right: 0px;
        top: 1-4px;
    }

    .header-saturday {
        background-color: rgba(243, 156, 18, .15);
    }

    .header-sunday {
        background-color: rgba(231, 76, 60, .15);
    }

    .b-responsive-small {
        .b-sch-style-border.b-sch-event {
            font-size: 10px;
            font-weight: 300;
        }

        .b-resource-info {
            flex-direction: column;
            justify-content: space-evenly;
        }

        .b-grid-header {
            font-size: .8em;
        }
    }

    .b-sch-header-timeaxis-cell {
        font-size: 14px !important;
    }

    .b-grid-splitter {
        display: none;
    }

    header.b-grid-header-container.b-grid-vertical-overflow {
        font-size: 14px;
    }

    .b-column-line-major {
        border-color: rgba(125, 125, 125, 0.4) !important;
    }

    /* Event border */
    .b-sch-style-border .b-sch-event {
        border-color: #d6d6d6 !important;
    }

    // Event tooltip
    .b-sch-event-tooltip {
        border-radius: 5px !important;
        background-color: transparent !important;

        .b-tooltip-content {
            padding: 8px;
            background-color: rgba(38, 50, 56, 0.85);
            border: 1px solid #aaa;
            border-radius: 5px !important;
            margin-bottom: 0px !important;
            z-index: -1;
        }
    }

    // Drag & drop tooltip
    .b-tooltip#container-event-drag-tip {
        border-radius: 5px !important;

        .b-tooltip-content {
            padding: 8px;
            background-color: rgba(38, 50, 56, 0.85);
            border: 1px solid #aaa;
            border-radius: 5px !important;
        }
    }

    // Drag resize tooltip
    .b-tooltip#container-event-resize-tip {
        border-radius: 5px !important;

        .b-tooltip-content {
            padding: 8px;
            background-color: rgba(38, 50, 56, 0.85);
            border: 1px solid #aaa;
            border-radius: 5px !important;
        }
    }

    // Drag create tooltip
    .b-tooltip#container-drag-create-tip {
        border-radius: 5px !important;

        .b-tooltip-content {
            padding: 8px;
            background-color: rgba(38, 50, 56, 0.85);
            border: 1px solid #aaa;
            border-radius: 5px !important;
        }
    }

    // Schedule tooltip
    .b-sch-scheduletip {
        border-radius: 5px !important;

        .b-tooltip-content {
            padding: 8px;
            background-color: rgba(38, 50, 56, 0.85);
            border: 1px solid #aaa;
            border-radius: 5px !important;
        }
    }

    .b-sch-event {
        padding: 5px !important;
    }

    .b-sch-event:not(.b-milestone) .b-sch-event-content {
        margin: 0 5px 0 5px !important; /*0 0.5em 0 0.75em*/;
    }

    .b-sch-event:not(.b-milestone).b-sch-event-selected,
    .b-sch-event:not(.b-milestone).b-sch-event-selected * {
        color: black !important;
        font-weight: 300 !important;
    }

    .b-sch-event-wrap:not(.b-milestone-wrap) .b-sch-event.b-sch-event-selected {
        background-image: none !important;
    }

    /* Non working time fix for vertical mode */
    .b-verticaltimeaxiscolumn .b-sch-timerange {
        display: none;
    }

    .b-sch-timerange.b-sch-nonworkingtime.b-sch-range.b-nonworkingtime {
        background: rgba(255, 0, 0, 0.05);
    }

    /* Hide horizontal mode current time label */
    // .b-grid-header .b-sch-timerange.b-sch-current-time {
    //     display: none;
    // }
    /* Current time line */
    .b-timeline-subgrid .b-sch-line {
        border-left: 3px dashed #fa5252 !important;
    }
    .b-sch-vertical .b-timeline-subgrid .b-sch-line {
        border-top: 3px dashed #fa5252 !important;
    }

    /* Tree column */
    .b-touch .b-icon-tree-expand:before, .b-touch .b-icon-tree-collapse:before {
       font-size: 1.1em !important;
    }
    .b-tree-expander {
        margin: 0 !important;
        margin-right: 4px !important;
    }

    @media only screen and (max-width: 600px) {
        // .b-grid-vertical-scroller  {
        //     font-size: .8em !important;
        // }
        // .b-verticaltimeaxis .b-sch-header-timeaxis-cell {
        //     width: 100% !important;
        // }
        // .b-grid-subgrid.b-grid-horizontal-scroller {
        //     width: 90px !important;
        // }
        // .b-sch-header-timeaxis-cell {
        //     width: 50px !important;
        // }
        // .b-virtual-scrollers .b-virtual-scroller {
        //     overflow-x: hidden;
        // }
    }
</style>