export function authHeader(contentType = false) {
    // return authorization header with jwt token
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        if (contentType) {
            return {
                'Content-Type': contentType,
                Authorization: `Bearer ${user.token}`
            };
        } 
            return {
                Authorization: `Bearer ${user.token}`
            };
    } 
        return {};
}
