<template>
    <div style="margin-top:7px"><ejs-daterangepicker id="laycanpicker" format="d/M" placeholder="Laycan" v-model="data.laycan" floatLabelType="Never"></ejs-daterangepicker></div>
</template>

<script>
// import moment from 'moment';

    export default {
        data() {
            return {
                data: {}
            };
        },
    };
</script>

<style>

</style>
