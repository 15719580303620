<template>
    <div id="child" style="margin-top:7px">
        <ejs-dropdownlist name="accountDropdown" width="380px" ref="accountInstance" id="accountDropdown" :showClearButton="true" v-model="data.account"
            :select="doCustomAction.bind(this)" :placeholder="placeholder" :dataSource="items"
            :fields="fields">
        </ejs-dropdownlist>
    </div>
</template>

<script>
    import {
        store
    } from '../../store';
    import {
        eventBus
    } from '../../main';

    export default {
        store,
        data() {
            return {
                data: {},
                items: [],
                placeholder: 'Select',
                fields: {
                    text: 'text',
                    value: 'value',
                },
            };
        },
        mounted() {
            this.items = store.state.data.schedulerParams.accounts;
            if (this.data && this.data.account) {
                this.placeholder = this.data.account;
            }
        },
        methods: {
            doCustomAction(args) {
                // console.log('account', args);
                if (args && args.itemData) {
                    eventBus.$emit('newAccount', args.itemData.value);
                }
            },
           
        }
    };
</script>

<style>
</style>
