<template>
    <div>
        <v-card class="cardbglight">
            <v-data-table :headers="headers" :items="terminals" :search="search" :single-expand="true" :expanded.sync="expanded" item-key="id" :sort-by="sortBy"
                :sort-desc="sortDesc" :footer-props="{ showFirstLastPage: true}" mobile-breakpoint="100" class="elevation-1">
                <template #top>
                    <v-toolbar>
                        <v-toolbar-title>Terminals </v-toolbar-title>
                        <v-spacer />
                        <v-spacer />
                        <v-spacer />
                        <v-spacer />
                        <v-spacer />
                        <v-layout wrap>
                            <v-flex mt-8 xs6>
                                <v-switch v-model="active" :false-value="0" :true-value="1" :label="active === 1 ? 'Active' : 'Inactive'" color="primary"
                                    hint="Status" persistent-hint />
                            </v-flex>
                            <v-flex mt-6 xs6>
                                <v-text-field v-model="search" append-icon="close" @click:append="search=''" prepend-icon="search" label="Search"
                                    single-linehide-details />
                            </v-flex>
                        </v-layout>
                    </v-toolbar>
                </template>
                <template #item="{item}">
                    <tr @click="expandPanel(item)">
                        <td>{{ item.portName }}</td>
                        <td>{{ round(item.maxDraft) }}</td>
                        <td>{{ item.maxLoa }}</td>
                        <td>{{ item.maxBeam }}</td>
                        <td>{{ item.agentName }}</td>
                        <td class="d-none d-sm-table-cell">{{ item.notes }}</td>
                        <td>{{ item.authorName }}</td>
                        <td>{{ formatDate(item.updated) }}</td>
                    </tr>
                </template>
                <template #expanded-item="{ headers }">
                    <td :colspan="headers.length" class="pa-0">
                        <v-card class="cardbg primarytext--text">
                            <v-form v-model="saveValid" lazy-validation>
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex px-4 xs12 sm6 md5>
                                            <v-autocomplete style="height:62px !important" class="mr-4" v-show="!terminal.portName" prepend-icon="mdi-anchor" v-model="terminal.port"
                                                :loading="portLoading" :items="ports" :item-text="portItemText" item-key="id" :search-input.sync="portSearch" clearable
                                                @change="updatePort" cache-items return-object text hide-no-data hide-details label="Port"></v-autocomplete>
                                            <v-text-field v-show="terminal.portName" prepend-icon="mdi-anchor" class="mr-4" :readonly="true" v-model="terminal.portName" label="Port"
                                                clearable @click:clear="onPortClearClicked(terminal)"></v-text-field>
                                        </v-flex>
                                         <v-flex px-4 xs4 sm4 md2>
                                            <v-text-field v-model="terminal.maxDraft" step="0.01" min="0" type="number" label="Max draft"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs4 sm4 md2>
                                            <v-text-field v-model="terminal.maxLoa" step="0.01" min="0" type="number" label="Max LOA"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs4 sm4 md2>
                                            <v-text-field v-model="terminal.maxBeam" step="0.01" min="0" type="number" label="Max beam"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md5>
                                            <v-autocomplete style="height:62px !important" class="mr-4" v-show="!terminal.agentName" prepend-icon="mdi-account" v-model="terminal.agent"
                                                :loading="agentLoading" :items="agents" :item-text="agentItemText" item-key="id" :search-input.sync="agentSearch" clearable
                                                @change="updateAgent" cache-items return-object text hide-no-data hide-details label="Default agent"></v-autocomplete>
                                            <v-text-field v-show="terminal.agentName" prepend-icon="mdi-account" class="mr-4" :readonly="true" v-model="terminal.agentName" label="Default agent"
                                                clearable @click:clear="onAgentClearClicked(terminal)"></v-text-field>
                                        </v-flex>
                                        
                                        <v-flex px-4 xs12 sm8 md6>
                                            <v-text-field v-model="terminal.notes" label="Notes"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-switch style="margin-top: 18px;" :disabled="userLevel < 1" v-model="terminal.active" :false-value="0" :true-value="1"
                                            :label="terminal.active === 1 ? 'Active' : 'Inactive'" color="primary" hint="Status"
                                            persistent-hint />
                                        <v-spacer />
                                        <v-btn color="error" @click="callDelete(terminal)" :disabled="userLevel < 1">
                                            Delete
                                            <v-icon right dark>delete_forever</v-icon>
                                        </v-btn>
                                        <v-btn color="primary" @click="save(terminal)"
                                            :disabled="!saveValid || userLevel < 1">
                                            Save
                                            <v-icon right dark>check</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-container>
                            </v-form>
                        </v-card>
                    </td>
                </template>
                <v-alert slot="no-results" :value="true" color="error" icon="warning">
                    Your search for "{{ search }}" found no results.
                </v-alert>
            </v-data-table>
            <v-expansion-panels :disabled="userLevel < 1" v-model="newPanel">
                <v-expansion-panel :disabled="userLevel < 1">
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="teal">add_circle</v-icon> <span class="text-body-1 ml-2">Add new terminal</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <v-card class="cardbg">
                            <v-form v-model="newValid" lazy-validation>
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex px-4 xs12 sm6 md5>
                                            <v-autocomplete prepend-icon="mdi-anchor" v-model="newTerminal.port" :loading="portLoading2" :items="ports2"
                                            :item-text="portItemText2" item-key="id" :clearable="true" :search-input.sync="portSearch2" cache-items return-object text
                                            hide-no-data hide-details label="Port">
                                        </v-autocomplete>
                                        </v-flex>
                                         <v-flex px-4 xs4 sm4 md2>
                                            <v-text-field v-model="newTerminal.maxDraft" step="0.01" min="0" type="number" label="Max draft"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs4 sm4 md2>
                                            <v-text-field v-model="newTerminal.maxLoa" step="0.1" min="0" type="number" label="Max LOA"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs4 sm4 md2>
                                            <v-text-field v-model="newTerminal.maxBeam" step="0.1" min="0" type="number" label="Max beam"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md5>
                                             <v-autocomplete prepend-icon="mdi-account" v-model="newTerminal.agent" :loading="agentLoading2" :items="agents2"
                                                :item-text="agentItemText2" item-key="id" :clearable="true" :search-input.sync="agentSearch2" cache-items return-object text
                                                hide-no-data hide-details label="Default agent">
                                            </v-autocomplete>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm8 md6>
                                            <v-text-field v-model="newTerminal.notes" label="Notes"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-switch style="margin-top: 18px;" :disabled="userLevel < 1" v-model="newTerminal.active" :false-value="0" :true-value="1"
                                            :label="newTerminal.active === 1 ? 'Active' : 'Inactive'" @change="active = newTerminal.active" color="primary"
                                            hint="Status" persistent-hint />

                                        <v-spacer />
                                        <v-btn color="primary" @click="callAdd(newTerminal)"
                                            :disabled="!newValid || userLevel < 1 || !newTerminal.port">
                                            Add
                                            <v-icon right dark>add_circle</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-container>
                            </v-form>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="primary">help</v-icon>
                            <span class="text-body-1 ml-2">What does this page show</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <br />
                        <p>
                            The Terminals page shows the terminals and their detailed data. Admin-users can edit the settings and in principle also add new terminals to
                            the system.
                        </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <StatusDialog />
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    import moment from 'moment';
    import StatusDialog from '../components/StatusDialog.vue';
    import {
        eventBus
    } from '../main';

    export default {
        name: 'Terminals',
        components: {
            StatusDialog
        },
        data: () => ({
            search: '',
            sortBy: 'name',
            sortDesc: false,
            active: 1,
            selected: [],
            requiredRules: [
                (v) => !!v || 'Required',
            ],
            headers: [{
                    text: 'Port',
                    value: 'portName'
                },
                {
                    text: 'Max draft',
                    value: 'maxDraft'
                },
                {
                    text: 'Max LOA',
                    value: 'maxLoa'
                },
                {
                    text: 'Max beam',
                    value: 'maxBeam'
                },
                {
                    text: 'Default agent',
                    value: 'agentName'
                },
                {
                    text: 'Notes',
                    value: 'notes'
                },
                {
                    text: 'Author',
                    value: 'authorName'
                },
                {
                    text: 'Updated',
                    value: 'updated'
                },
            ],
            expanded: [],
            newTerminal: {
                port: null,
                portId: null,
                portName: null,
                maxDraft: null,
                maxLoa: null,
                maxBeam: null,
                agent: null,
                agentId: null,
                agentName: null,
                notes: null,
                active: 1,
            },
            newPanel: [],
            deleteTerminalId: null,
            saveValid: true,
            newValid: true,
            portSearch: null,
            ports: [],
            portLoading: false,
            select: null,
            agentSearch: null,
            agents: [],
            agentLoading: false,
            portSearch2: null,
            ports2: [],
            portLoading2: false,
            select2: null,
            agentSearch2: null,
            agents2: [],
            agentLoading2: false,
            terminal: {},
        }),
        created() {
            this.fetchTerminals();
        },
        computed: {
            terminals() {
                return this.getTerminals().filter((o) => o.active === this.active);
            },
            userLevel() {
                return this.getUser().user.level;
            },
        },
        watch: {
            portSearch(val) {
                val && val !== this.select && this.portQuerySelections(val);
            },
            agentSearch(val) {
                val && val !== this.select && this.agentQuerySelections(val);
            },
            portSearch2(val) {
                val && val !== this.select2 && this.portQuerySelections2(val);
            },
            agentSearch2(val) {
                val && val !== this.select2 && this.agentQuerySelections2(val);
            },
        },
        mounted() {
            eventBus.$on('deleteTerminal', () => {
                if (this.deleteTerminalId) {
                    this.deleteTerminal(this.deleteTerminalId);
                    this.deleteTerminalId = null;
                }
            });
        },
        beforeDestroy() {
            eventBus.$off('deleteTerminal');
        },
        methods: {
            ...mapActions({
                fetchTerminals: 'data/fetchTerminals',
                saveTerminal: 'data/saveTerminal',
                deleteTerminal: 'data/deleteTerminal',
                addTerminal: 'data/addTerminal',
                fetchPorts: 'data/fetchPorts',
                fetchContacts: 'data/fetchContacts',
            }),
            ...mapGetters({
                getTerminals: 'data/getTerminals',
                getUser: 'authentication/getUser'
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
                alertError: 'alert/error',
                alertSuccess: 'alert/success',
            }),
            expandPanel(item) {
                this.expanded = item === this.expanded[0] ? [] : [item];
                if (this.expanded.length > 0) {
                    this.terminal = JSON.parse(JSON.stringify(item));
                } else {
                    this.terminal = {};
                }
            },
            callDelete(terminal) {
                this.deleteTerminalId = terminal.id;
                this.alertConfirm({
                    message: `Are you sure you want to delete permanently ${terminal.port}?`,
                    emit: 'deleteTerminal'
                });
            },
            save(terminal) {
                // console.log(terminal);
                if (typeof terminal.port === 'object' && terminal.port !== null) {
                    terminal.portId = terminal.port.portId;
                }
                if (!terminal.portId) {
                    this.alertError('Port is mandatory parameter.');
                    return;
                }
                if (typeof terminal.agent === 'object' && terminal.agent !== null) {
                    terminal.agentId = terminal.agent.id;
                }
                // console.log(terminal);
                this.saveTerminal(terminal);
            },
            callAdd(terminal) {
                if (typeof terminal.port === 'object' && terminal.port !== null) {
                    terminal.portId = terminal.port.portId;
                } else {
                    terminal.portId = null;
                }
                if (typeof terminal.agent === 'object' && terminal.agent !== null) {
                    terminal.agentId = terminal.agent.id;
                } else {
                    terminal.agentId = null;
                }
                console.log(terminal);
                this.addTerminal(terminal);
                this.cancel();
            },
            cancel() {
                this.newPanel = [];
                this.newTerminal = {
                    port: null,
                    portId: null,
                    maxDraft: null,
                    maxLoa: null,
                    maxBeam: null,
                    agent: null,
                    agentId: null,
                    notes: null,
                    active: 1,
                };
            },
            formatDate(date) {
                if (date) {
                    return moment.utc(date).local().format('D.M.Y HH:mm');
                }
                return '';
            },
            portQuerySelections(v) {
                if (v.length > 2) {
                    this.portLoading = true;
                    this.fetchPorts({
                        query: v
                    }).then((data) => {
                        this.ports = data;
                        this.portLoading = false;
                    });
                }
            },
            portItemText(item) {
                return `${item.name} — ${item.ddtLocode}`;
            },
            updatePort(item) {
                if (item) {
                    this.portId = item.portId;
                    this.portName = item.name;
                } else {
                    this.portId = null;
                    this.portName = null;
                }
            },
            portQuerySelections2(v) {
                if (v.length > 2) {
                    this.portLoading2 = true;
                    this.fetchPorts({
                        query: v
                    }).then((data) => {
                        this.ports2 = data;
                        this.portLoading2 = false;
                    });
                }
            },
            portItemText2(item) {
                return `${item.name} — ${item.ddtLocode}`;
            },
            agentQuerySelections(v) {
                if (v.length > 2) {
                    this.agentLoading = true;
                    this.fetchContacts({
                        query: v,
                        type: 1,
                    }).then((data) => {
                        this.agents = data;
                        this.agentLoading = false;
                    });
                }
            },
            agentItemText(item) {
                return `${item.name}`;
            },
            updateAgent(item) {
                if (item) {
                    this.agentId = item.id;
                    this.agentName = item.name;
                } else {
                    this.agentId = null;
                    this.agentName = null;
                }
            },
            agentQuerySelections2(v) {
                if (v.length > 2) {
                    this.agentLoading2 = true;
                    this.fetchContacts({
                        query: v,
                        type: 1,
                    }).then((data) => {
                        this.agents2 = data;
                        this.agentLoading2 = false;
                    });
                }
            },
            agentItemText2(item) {
                return `${item.name}`;
            },
            onPortClearClicked(item) {
                console.log('onPortClearClicked');
                item.port = {};
                item.portId = null;
                item.portName = null;
            },
            onAgentClearClicked(item) {
                console.log('onAgentClearClicked');
                item.agent = {};
                item.agentId = null;
                item.agentName = null;
            },
            round(value) {
                return Math.round(value * 10) / 10;
            }
        }
    };
</script>

<style>
    .v-list--dense .v-list__tile:not(.v-list__tile--avatar) {
        height: 100%;
    }
</style>
<style scoped>
    .mypanel .v-expansion-panel__body {
        display: block !important;
        /* Dirty fix for expansion panel content */
    }

    .sectionheader {
        padding-bottom: 10px;
    }

    .shipheader {
        padding: 10px 0px;
    }

    .headertext {
        margin: 16px;
    }

    tr.expand td {
        padding: 0 !important;
    }

    tr.expand .expansion-panel {
        box-shadow: none;
    }

    tr.expand .expansion-panel li {
        border: none;
    }
</style>