<template>
    <div class="control-section">
        <ejs-grid ref="grid" id="Grid" :class="gridExpand ? 'gridexpand' : ''" :dataSource="data" :allowResizing="true" :rowHeight="25" :toolbar="toolbar" :allowReordering="true"
            :gridSettings="gridSettings" :actionBegin="actionBegin" :toolbarClick="toolbarClick" :actionComplete="actionComplete"
            :allowExcelExport="true" :editSettings="editSettings" :allowPaging="false" :allowSorting="true" :allowMultiSorting="true" :sortSettings="sortOptions" :rowDataBound="rowDataBound"
            :showColumnChooser="true" :queryCellInfo="queryCellInfo" :height="moneyTableHeight()" 
            :rowSelected="rowSelected" :load="load" :key="gridKey">
            <e-columns>
                <e-column field="type" headerText="Type" :visible="isVisible('type')" :allowEditing="false" width="80" clipMode="EllipsisWithTooltip" headerTextAlign="Center"></e-column>
                <e-column field="moneyItemId" headerText="Item" :visible="isVisible('moneyItemId')" :valueAccessor="valueAccessor" :editTemplate="moneyItemTemplate" clipMode="EllipsisWithTooltip" width="170" headerTextAlign="Center"></e-column>
                <e-column field="portCallId" v-if="isVoyage === 1" :visible="isVisible('portCallName')" :valueAccessor="valueAccessor" headerText="Port call" clipMode="EllipsisWithTooltip" :editTemplate="portCallTemplate" width="100" headerTextAlign="Center"></e-column>
                <e-column field="portIdLoad" v-if="isVoyage === 1" :visible="isVisible('pol')" headerText="POL" :valueAccessor="valueAccessor" :editTemplate="polTemplate" clipMode="EllipsisWithTooltip" width="60" headerTextAlign="Center"></e-column>
                <e-column field="portIdDisch" v-if="isVoyage === 1" :visible="isVisible('pod')" headerText="POD" :valueAccessor="valueAccessor" :editTemplate="podTemplate" clipMode="EllipsisWithTooltip" width="60" headerTextAlign="Center"></e-column>
                <!-- <e-column field='name' headerText='Name' width='120' hideAtMedia='(min-width: 600px)' :valueAccessor='valueAccessor' headerTextAlign='Center'></e-column> -->
                <!-- <e-column field='name' headerText='Name' width='120' hideAtMedia='(min-width: 600px)' headerTextAlign='Center'></e-column> -->
                <e-column field="bafPercentage" :visible="isVisible('bafPercentage')" headerText="BAF %" width="60" format="n2" editType="numericedit" :edit="decimal2Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="rateBaf" :visible="isVisible('rateBaf')" headerText="BAF rate" width="60" format="n2" editType="numericedit" :edit="decimal2Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="rateEts" :visible="isVisible('rateEts')" headerText="ETS" width="60" format="n2" editType="numericedit" :edit="decimal2Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="rate" :visible="isVisible('rate')" headerText="Rate" :customAttributes="custom" width="80" format="n2" editType="numericedit" :edit="decimal2Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="unit" :visible="isVisible('unit')" headerText="Unit" :editTemplate="unitTemplate" width="75" headerTextAlign="Center"></e-column>
                <e-column field="currency" :visible="isVisible('currency')" headerText="Currency" :editTemplate="currencyTemplate" width="80" headerTextAlign="Center"></e-column>
                <e-column field="exchangeRate" :visible="isVisible('exchangeRate')" headerText="Exchange rate" width="75" editType="numericedit" format="n6" :edit="decimal6Params" headerTextAlign="Center"></e-column>
                <e-column field="quantity" :visible="isVisible('quantity')" headerText="Qty" :customAttributes="custom" width="80" format="n3" editType="numericedit" :edit="decimal3Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="sum" :visible="isVisible('sum')" headerText="Sum" :allowEditing="false" width="80" format="n2" editType="numericedit" :edit="decimal2Params" textAlign="Right" headerTextAlign="Center"></e-column>
                
                <e-column field="rateFinal" :visible="isVisible('rateFinal')" headerText="Rate final" :customAttributes="customFinal" width="80" editType="numericedit" format="n2" :edit="decimal2Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="quantityFinal" :visible="isVisible('quantityFinal')" headerText="Qty final" :customAttributes="customFinal" width="80" editType="numericedit" format="n3" :edit="decimal3Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="tonFinal" :visible="isVisible('tonFinal')" headerText="Ton final" width="80" :allowEditing="tonFinalEdit()" :customAttributes="customFinal" editType="numericedit" format="n3" :edit="decimal3Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="baf" :visible="isVisible('baf')" headerText="BAF" type="number" format="n2" :valueAccessor="valueAccessor" width="60" :allowEditing="false" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="sumEts" :visible="isVisible('sumEts')" headerText="Sum ETS" type="number" format="n2" :valueAccessor="valueAccessor" width="80" :allowEditing="false" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="sumFinal" :visible="isVisible('sumFinal')" headerText="Sum final" width="80" :customAttributes="customFinal" format="n2" editType="numericedit" :edit="decimal2Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <!-- <e-column field='status' headerText='✔' :visible="showFinal()" width='40' :editTemplate="statusTemplate" headerTextAlign='Center'></e-column> -->
                <e-column field="status" :visible="isVisible('status')" headerText="✔" width="33" editType="booleanedit" headerTextAlign="Center"></e-column>
                <e-column field="invoiceRef" :visible="isVisible('invoiceRef')" headerText="Invoice ref" width="100" clipMode="EllipsisWithTooltip" headerTextAlign="Center"></e-column>
                <e-column field="contactId" :visible="isVisible('contactId')" :valueAccessor="valueAccessor" :editTemplate="contactTemplate" clipMode="EllipsisWithTooltip" headerText="Contact" width="115" headerTextAlign="Center"></e-column>
                <e-column field="contactName" :visible="isVisible('contactName')" headerText="Contact name" width="90" clipMode="EllipsisWithTooltip" headerTextAlign="Center"></e-column>
                <e-column field="notes" :visible="isVisible('notes')" headerText="Notes" width="100" headerTextAlign="Center" clipMode="EllipsisWithTooltip"></e-column>
                <e-column field="sf" :visible="isVisible('sf')" headerText="SF" width="60" format="n2" editType="numericedit" :edit="decimal2Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="quantityMin" :visible="isVisible('quantityMin')" headerText="Qty min" width="80" format="n2" editType="numericedit" :edit="decimal2Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="quantityMax" :visible="isVisible('quantityMax')" headerText="Qty max" width="80" format="n2" editType="numericedit" :edit="decimal2Params" textAlign="Right" headerTextAlign="Center"></e-column>
                
                <e-column field="plusMinus" :visible="isVisible('plusMinus')" headerText="+/-" width="60" format="P" editType="numericedit" :edit="intParams" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="laycan" :visible="isVisible('laycan')" headerText="Laycan" width="120" :editTemplate="laycanTemplate" headerTextAlign="Center"></e-column>
                <e-column field="account" :visible="isVisible('account')" headerText="Account" width="100" :editTemplate="accountTemplate" headerTextAlign="Center"></e-column>
                
                <e-column field="authorName" :visible="isVisible('authorName')" headerText="Editor" :allowEditing="false" width="100" clipMode="EllipsisWithTooltip" headerTextAlign="Center"></e-column>
                <e-column field="created" :visible="isVisible('created')" headerText="Created" format="d/M/y HH:mm" type="date" :allowEditing="false" width="120" headerTextAlign="Center"></e-column>
                <e-column field="modified" :visible="isVisible('modified')" headerText="Modified" format="d/M/y HH:mm" type="date" :allowEditing="false" width="120" headerTextAlign="Center"></e-column>
                
                <e-column field="createdFinal" :visible="isVisible('createdFinal')" headerText="Created final" format="d/M/y HH:mm" type="date" :allowEditing="false" width="120" headerTextAlign="Center"></e-column>
                <e-column field="income" headerText="Income" width="85" :editTemplate="incomeTemplate" headerTextAlign="Center"></e-column>
                <e-column field="id" headerText="ID" :visible="true" :allowEditing="false" :isPrimaryKey="true" width="60" headerTextAlign="Center"></e-column>
            </e-columns>
            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <e-column type="Sum" field="sum" :footerTemplate="footerSum" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="tonFinal" :footerTemplate="footerSum3Decimals" headerTextAlign="Center"></e-column>
                        <e-column type="Sum" field="sumFinal" :footerTemplate="footerSumNoText" headerTextAlign="Center"></e-column>
                    </e-columns>
                </e-aggregate>
                <e-aggregate v-if="isVoyage === 1">
                    <e-columns>
                        <e-column type="Custom" :customAggregate="customSumAggregateFn" columnName="sum" :footerTemplate="footerPerDay" headerTextAlign="Center"></e-column>
                        <e-column type="Custom" :customAggregate="customSumFinalAggregateFn" columnName="sumFinal" :footerTemplate="footerPerDayNoText" headerTextAlign="Center"></e-column>
                    </e-columns>
                </e-aggregate>
            </e-aggregates>
        </ejs-grid>
    </div>
</template>

<script>
    // import moment from 'moment';
    import {
        mapGetters,
        mapActions,
        mapMutations,
    } from 'vuex';
    import Vue from 'vue';
    import {
        GridPlugin,
        Resize,
        Sort,
        Edit,
        Toolbar,
        ExcelExport,
        ColumnChooser,
        Reorder,
        ForeignKey,
        Aggregate,
    } from '@syncfusion/ej2-vue-grids';
    import {
        DateRangePickerPlugin
    } from '@syncfusion/ej2-vue-calendars';
    import { ToolbarPlugin } from '@syncfusion/ej2-vue-navigations';
    import {
        DropDownListPlugin, AutoCompletePlugin
    } from '@syncfusion/ej2-vue-dropdowns';
    import moment from 'moment';
    import { loadCldr, setCulture } from '@syncfusion/ej2-base';
    import MoneyItemDropDown from './MoneyItemDropDown.vue';
    import ContactDropDown from './ContactDropDown.vue';
    import PortCallDropDown from './PortCallDropDown.vue';
    import PolDropDown from './PolDropDown.vue';
    import PodDropDown from './PodDropDown.vue';
    import AccountDropDown from './AccountDropDown.vue';
    import DateRangePicker from './DateRangePicker.vue';
    import SumAggregate from './SumAggregate.vue';
    import SumAggregateNoText from './SumAggregateNoText.vue';
    import SumAggregate3Decimals from './SumAggregate3Decimals.vue';
    import PerDaySumAggregate from './PerDaySumAggregate.vue';
    import PerDaySumAggregateNoText from './PerDaySumAggregateNoText.vue';
    // import EditStatus from './EditStatus.vue';
    import IncomeDropDown from './IncomeDropDown.vue';
    import BafPercentageToolbar from './BafPercentageToolbar.vue';
    import EtsRateToolbar from './EtsRateToolbar.vue';
    import UnitDropDown from './UnitDropDown.vue';
    import CurrencyDropDown from './CurrencyDropDown.vue';
    import {
        eventBus
    } from '../../main';
     import {
        tools
    } from '../../helpers';
    
    loadCldr( 
        require('cldr-data/main/en-FI/ca-gregorian.json'), 
        require('cldr-data/main/en-FI/numbers.json'), 
        require('cldr-data/main/en-FI/timeZoneNames.json'), 
        require('cldr-data/supplemental/numberingSystems.json'),
        require('cldr-data/supplemental/weekData.json'),
    ); 

    Vue.use(DateRangePickerPlugin);
    Vue.use(DropDownListPlugin);
    Vue.use(AutoCompletePlugin);
    Vue.use(GridPlugin);
    Vue.use(ToolbarPlugin);

    let initial;
    let rowId = null;
    export default {
        name: 'SchedulerMoney',
        props: {
            projectId: Number,
            projectTypeId: Number,
            isVoyage: Number,
            portCallData: Array,
            prevPortData: Object,
            params: Object,
            moneyData: Array,
            voyageTotals: Object,
            resource: Object,
            projectStatus: Number,
            shipIdNominated: Number,
        },
        components: {
        },
        data() {
            return {
                key: 0,
                moneyItem: null,
                moneyItemId: null,
                voyageTotalsUpdated: null,
                status: null,
                bafPercentage: 0,
                income: null,
                contactId: null,
                contactText: null,
                portCall: null,
                pol: null,
                pod: null,
                account: null,
                currency: null,
                unit: null,
                rowIndex: null,
                gridExpand: false,
                gridKey: 0,
                gridSettings: {
                    allowSelection: true,
                },
                data: [],
                editSettings: {
                    showDeleteConfirmDialog: true,
                    allowEditOnDblClick: true,
                    allowEditing: true,
                    allowAdding: true,
                    allowDeleting: true,
                    // newRowPosition: 'Bottom',
                },
                custom: { class: 'column' },
                customFinal: { class: 'columnfinal' },
                sortOptions: { columns: [
                    { field: 'income', direction: 'Descending' }, 
                    // { field: 'portCallId', direction: 'Ascending' }, 
                    // { field: 'portIdDisch', direction: 'Ascending' }, 
                    // { field: 'type', direction: 'Ascending' }, 
                    // { field: 'moneyItemId', direction: 'Ascending' }
                    ] }, 
                toolbar: ['Add', 'Delete', 'Edit', 'Update', 'Cancel',
                    {
                        text: 'Copy', tooltipText: 'Create new row with selected row values', disabled: true, prefixIcon: 'e-copy', id: 'copy' 
                    },
                    'Print', 'ColumnChooser', 'ExcelExport',
                    {
                        text: 'Resize', tooltipText: 'Expand/Collapse table view', prefixIcon: 'e-expand', id: 'expand' 
                    },
                    { 
                        template() {
                            return {
                                template: BafPercentageToolbar
                            };
                        }
                    },
                    { 
                        template() {
                            return {
                                template: EtsRateToolbar
                            };
                        }
                    },
                    {
                        text: 'Match quantities', tooltipText: 'Copy freight quantities to corresponding costs', prefixIcon: 'e-quantities', id: 'quantities' 
                    },
                    {
                        text: 'Show/Hide', tooltipText: 'Show/Hide all rows with Sum final = 0', prefixIcon: 'e-sumfinal', id: 'sumFinal' 
                    },
                ],
                intParams: {
                    params: {
                        decimals: 3,
                        format: 'n',
                        showSpinButton: false,
                    }
                },
                decimal2Params: {
                    params: {
                        decimals: 2,
                        format: '######.##',
                        validateDecimalOnType: true,
                        showSpinButton: false,
                    }
                },
                decimal3Params: {
                    params: {
                        decimals: 3,
                        format: '######.###',
                        validateDecimalOnType: true,
                        showSpinButton: false,
                    }
                },
                decimal6Params: {
                    params: {
                        decimals: 6,
                        format: '#####.#######',
                        validateDecimalOnType: true,
                        showSpinButton: false,
                    }
                },
                moneyItemTemplate() {                   
                    return {
                        template: MoneyItemDropDown
                    };
                },
                portCallTemplate() {                   
                    return {
                        template: PortCallDropDown
                    };
                },
                polTemplate() {                   
                    return {
                        template: PolDropDown
                    };
                },
                podTemplate() {                   
                    return {
                        template: PodDropDown
                    };
                },
                accountTemplate() {                   
                    return {
                        template: AccountDropDown
                    };
                },
                laycanTemplate() {
                    return {
                        template: DateRangePicker
                    };
                },
                currencyTemplate() {
                    return {
                        template: CurrencyDropDown
                    };
                },
                unitTemplate() {
                    return {
                        template: UnitDropDown
                    };
                },
                contactTemplate() {                   
                    return {
                        template: ContactDropDown
                    };
                },
                // statusTemplate: function() {
                //     return {
                //         template: EditStatus
                //     }
                // },
                incomeTemplate() {
                    return {
                        template: IncomeDropDown
                    };
                },
                footerSum() {
                    return { template: SumAggregate };
                },
                footerPerDay() {
                    return { template: PerDaySumAggregate };
                },
                footerPerDayNoText() {
                    return { template: PerDaySumAggregateNoText };
                },
                footerSumNoText() {
                    return { template: SumAggregateNoText };
                },
                footerSum3Decimals() {
                    return { template: SumAggregate3Decimals };
                },
            };
        },  
        created() {
            setCulture('en-FI');
            // console.log('project id: ' + this.projectId)
            this.data = this.moneyData ? this.moneyData : [];
            this.portCalls = this.portCallData ? this.portCallData : [];
            // Fix for ej2 data-grid problem, when number formatted column gets string and table data is not visible
            this.data = this.data.map((o) => {
                o.rate = parseFloat(o.rate);
                o.rateFinal = parseFloat(o.rateFinal);
                o.quantity = parseFloat(o.quantity);
                o.quantityFinal = parseFloat(o.quantityFinal);
                o.tonFinal = parseFloat(o.tonFinal);
                return o;
            });
            this.voyageTotalsUpdated = JSON.parse(JSON.stringify(this.voyageTotals));
            // Hide sumFinal = 0 rows if project is confirmed
            // if (this.projectStatus === 2) {
            //     setTimeout(() => {
            //         this.toggleRowVisibility(true);
            //     }, 2000);
            // }
            eventBus.$on('updateVoygeTotals', (e) => {
                this.voyageTotalsUpdated = e;
                // console.log('updateVoygeTotals', e);
                if (this.$refs.grid) {
                    this.$refs.grid.refresh();
                }
            });
            eventBus.$off('newMoneyData');
            eventBus.$on('newMoneyData', (e) => {
                console.log('newMoneyData', e);
                // Fix for ej2 data-grid problem, when number formatted column gets string and table data is not visible
                this.data = e.map((o) => {
                    o.rate = parseFloat(o.rate);
                    o.rateFinal = parseFloat(o.rateFinal);
                    o.quantity = parseFloat(o.quantity);
                    o.quantityFinal = parseFloat(o.quantityFinal);
                    o.tonFinal = parseFloat(o.tonFinal);
                    return o;
                });
                // console.log('moneyData', this.$refs.grid.ej2Instances.dataSource);
                this.$refs.grid.ej2Instances.dataSource = this.data; 
                // setTimeout(() => {
                //     this.toggleRowVisibility(true);
                // }, 10);
                // if (this.$refs.grid) {
                //     this.$refs.grid.refresh();
                // }
            });

            eventBus.$on('updatePortCalls', (e) => {
                this.portCalls = e;
                // console.log('portCallsUpdated', this.portCalls);
                this.setPortCalls(this.portCalls);
            });

            eventBus.$on('setBaf', (e) => {
                if (this.$refs.grid) {
                    // console.log('setBaf', e);
                    this.$refs.grid.ej2Instances.dataSource = this.$refs.grid.ej2Instances.dataSource.map((o) => {
                        if (o.type === 'Freight' && o.rateBaf) {
                            o.bafPercentage = e;
                        }
                        return o;
                    });
                    eventBus.$emit('moneyDataUpdate', this.$refs.grid.ej2Instances.dataSource);
                    // this.$refs.grid.refresh();
                }
            });

            eventBus.$on('setEts', (e) => {
                if (this.$refs.grid) {
                    // console.log('setEts', e);
                    this.$refs.grid.ej2Instances.dataSource = this.$refs.grid.ej2Instances.dataSource.map((o) => {
                        if (o.type === 'Freight') {
                            o.rateEts = e;
                        }
                        return o;
                    });
                    eventBus.$emit('moneyDataUpdate', this.$refs.grid.ej2Instances.dataSource);
                    // this.$refs.grid.refresh();
                }
            });
        },
        beforeDestroy() {
            eventBus.$off('updateVoygeTotals');
            eventBus.$off('updatePortCalls');
            eventBus.$off('newMoneyData');
            eventBus.$off('moneyItem');
            // eventBus.$off('statusUpdated');
            eventBus.$off('incomeUpdated');
            eventBus.$off('unitUpdated');
            eventBus.$off('contactUpdated');
            eventBus.$off('newPortCall');
            eventBus.$off('newPol');
            eventBus.$off('newPod');
            eventBus.$off('newAccount');
            eventBus.$off('currencyUpdated');
            eventBus.$off('setBaf');
            eventBus.$off('setEts');
        },
        watch: {},
        computed: {
            ...mapGetters({
                getUser: 'authentication/getUser',
                exchangeRates: 'data/getExchangeRates',
                features: 'authentication/features',
                dischCostsOnlyPort: 'data/getDischCostsOnlyPort',
                ships: 'data/getShips',
                dark: 'users/getDarkMode',
            }),
        },
        methods: {
            ...mapGetters({
            }),
            ...mapActions({
                fetchAvgDa: 'data/fetchAvgDa',
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
                alertError: 'alert/error',
                alertClear: 'alert/clear',
                setPortCalls: 'data/setPortCalls',
            }),
            onFocusDateRangePicker(args) {
                // console.log(args.model)
                // this.$refs.laycanpicker.ej2Instances.show();
                args.model.show();
            },
            valueAccessor(field, data) {
                if (field === 'moneyItemId') {
                    return data.moneyItemName;
                } if (field === 'portCallId') {
                    let order = '';
                    if (data.portCallId === 2147483647) { // Disch only portCallId 2147483647
                        // order = this.portCalls && this.portCalls.length > 0 ? this.portCalls.length + 1 : this.dischCostsOnlyPort.order + 1;
                        return this.dischCostsOnlyPort ? `DCO ${this.dischCostsOnlyPort.name}` : '';
                    } 
                        if (data.order || data.order === 0) {
                            order = `${data.order + 1}. `;
                        } 
                        return data.portCallName ? order + data.portCallName : '';
                } if (field === 'portIdLoad') {
                    return data.pol ? data.pol : '';
                } if (field === 'portIdDisch') {
                    return data.pod ? data.pod : '';
                } if (field === 'baf') {
                    return data.bafPercentage && data.rateBaf && data.quantityFinal ? Math.round(data.bafPercentage * data.quantityFinal * data.rateBaf / 100 * 100) / 100 : 0;
                } if (field === 'sumEts') {
                    return data.rateEts && data.quantityFinal ? Math.round(data.quantityFinal * data.rateEts * 100) / 100 : 0;
                }
                if (field === 'contactId') {
                    return data.contactText;
                }
            },
            portCallTypeToText(type) {
                switch (type) {
                    case 1:
                        return 'Waiting/Anchoring'; 
                    case 2:
                        return 'Loading'; 
                    case 3:
                        return 'Discharging'; 
                    case 4:
                        return 'Dock/Repairs'; 
                    case 5:
                        return 'Off hire'; 
                    case 6:
                        return 'Transit';
                    case 7:
                        return 'Load/Disch';
                    case 8:
                        return 'Ballast';
                    default:
                        return 'N/A';
                }
            },
            rowDataBound(args) {
                // console.log(args.data.income);
                if (args.data.income > 0) {
                    args.row.classList.add('income');
                }
            },
            customSumAggregateFn(data) {
                let total = 0;
                data = data && data.result ? data.result : data;
                data.map((o) => {
                    if (!isNaN(o.sum)) {
                        total += parseFloat(o.sum);
                    }
                });
                const days = parseFloat(this.voyageTotalsUpdated.voyageTime);
                if (days && !isNaN(total)) {
                    return Math.round(total / days * 100) / 100;
                } 
                    return 0;
            },
            customSumFinalAggregateFn(data) {
                let total = 0;
                data = data && data.result ? data.result : data;
                data.map((o) => {
                    if (!isNaN(o.sumFinal)) {
                        total += parseFloat(o.sumFinal);
                    }
                });
                const days = parseFloat(this.voyageTotalsUpdated.voyageTime);
                if (days && !isNaN(total)) {
                    return Math.round(total / days * 100) / 100;
                } 
                    return 0;
            },
            queryCellInfo(args) { 
                if (this.dark) {
                    args.cell.classList.add('light-text');
                }
                if (args.column.field === 'laycan') { 
                    const laycan = args.data.laycan;
                    if (laycan) {
                        const d1 = moment(laycan[0]).format('D/M');
                        const d2 = moment(laycan[1]).format('D/M');
                        const date = `${d1} - ${d2}`; 
                        args.cell.innerHTML = date;
                    }
                } else if (args.column.field === 'status') { 
                    // if (initial) { 
                        // args.cell.innerHTML = this.status;
                    // } else {
                        args.cell.innerHTML = args.data.status ? '✔' : ''; // '✕';
                    // }
                } else if (args.column.field === 'income') { 
                    if (initial) { 
                        args.cell.innerHTML = this.income;
                    } else {
                        args.cell.innerHTML = args.data.income === 1 ? 'Income' : 'Cost';
                    }
                } else if (args.column.field === 'sumFinal') { 
                    if ((args.data.income === 1 && args.data.sumFinal < 0) || (args.data.income < 1 && args.data.sumFinal > 0)) {
                        args.cell.classList.add('sign-issue');
                    }
                } else if (args.column.field === 'currency') {
                    if (args.data.currency !== 'EUR') {
                        const sum = Number.parseFloat(args.data.rate * args.data.quantity).toFixed(2);
                        const sumFinal = Number.parseFloat(args.data.rateFinal * args.data.quantityFinal).toFixed(2);
                        let title = `Currency: ${args.data.currency}&#x0aRate: ${args.data.exchangeRate}&#x0aSum: ${sum}&#x0aSum final: ${sumFinal}&#x0a`;
                        args.cell.innerHTML = args.data.currency + ' &nbsp<span title="' + title + '">ⓘ</span>';
                    }
                }
                //  else if (args.column.field === "portCallId") { 
                //     if (initial) { 
                //         if (this.portCallId) {
                //             let portCall = this.portCalls.find((o) => o.id === this.portCallId);
                //             args.cell.innerHTML = portCall.name;
                //         } else {
                //             args.cell.innerHTML = '';
                //         }
                //     } else {
                //         if (args.data.portCallId) {
                //             let portCall = this.portCalls.find((o) => o.id === args.data.portCallId);
                //             args.cell.innerHTML = portCall.name;
                //         } else {
                //             args.cell.innerHTML = '';
                //         }
                //     }
                // }
            },
            load() { 
                initial = false; 
                // console.log('load', initial);
            },
            actionBegin(args) {
                // console.log('actionBegin', args.requestType, args); // custom Action
                if (args.requestType === 'beginEdit' || args.requestType === 'add') {
                    eventBus.$emit('disableSave', true);
                    eventBus.$on('moneyItem', (e) => {
                        if (this.$refs.grid && this.$refs.grid.ej2Instances) {
                            const editModule = this.$refs.grid.ej2Instances.editModule;
                            const element = editModule.formObj.element;
                            // here we can get the current Edited row data 
                            // let currentRowData = editModule.getCurrentEditedData(editModule.formObj.element, {});
                            // console.log(currentRowData)
                            // this.moneyItem = e;
                            this.moneyItem = this.params.moneyItems.find((o) => o.id === e.id);
                            // to select other input field   -- grid element id + field name 
                            if (this.moneyItem) {
                                // console.log('money item change', this.moneyItem);
                                if (element.querySelector('#GridcontactName')) {
                                    element.querySelector('#GridcontactName').value = this.moneyItem.contactName;
                                }
                                if (element.querySelector('#Gridcontact')) {
                                    element.querySelector('#Gridcontact').value = this.moneyItem.contactId;
                                }
                                if (element.querySelector('#contactdropdown_hidden')) {
                                    element.querySelector('#contactdropdown_hidden').value = this.moneyItem.contactId;
                                    eventBus.$emit('contactInitial', this.moneyItem.contactId);
                                }
                                if (element.querySelector('#Gridrate')) {
                                    let rate = this.moneyItem.rate ? parseFloat(this.moneyItem.rate) : 0;
                                    if (this.moneyItem.calcRule === 'day' && this.resource.dayRate) {
                                        rate = parseFloat(this.resource.dayRate);
                                    }
                                    if (this.moneyItem.calcRule === 'bunker' && this.resource.bunkerRate) {
                                        rate = parseFloat(this.resource.bunkerRate);
                                    }
                                    if (this.moneyItem.calcRule === 'bunkerSteaming' && this.resource.bunkerSteamingRate) {
                                        rate = parseFloat(this.resource.bunkerSteamingRate);
                                    }
                                    if (this.moneyItem.calcRule === 'secondaryBunkerSteaming' && this.resource.secondaryBunkerSteamingRate) {
                                        rate = parseFloat(this.resource.secondaryBunkerSteamingRate);
                                    }
                                    if (this.moneyItem.calcRule === 'bunkerPort' && this.resource.bunkerPortRate) {
                                        rate = parseFloat(this.resource.bunkerPortRate);
                                    }
                                    // console.log('rate: ' + rate)
                                    element.querySelector('#Gridrate').value = rate;
                                }
                                if (element.querySelector('#Gridcurrency')) {
                                    element.querySelector('#Gridcurrency').value = this.moneyItem.currency;
                                }
                                if (element.querySelector('#currencydropdown_hidden')) {
                                    element.querySelector('#currencydropdown_hidden').value = this.moneyItem.currency;
                                    eventBus.$emit('currencyInitial', this.moneyItem.currency);
                                }
                                if (element.querySelector('#Gridunit')) {
                                    element.querySelector('#Gridunit').value = this.moneyItem.unit;
                                }
                                if (element.querySelector('#unitdropdown_hidden')) {
                                    element.querySelector('#unitdropdown_hidden').value = this.moneyItem.currency;
                                    eventBus.$emit('unitInitial', this.moneyItem.unit);
                                }
                                if (element.querySelector('#Gridsf')) {
                                    element.querySelector('#Gridsf').value = parseFloat(this.moneyItem.sf);
                                }
                                if (element.querySelector('#Gridincome')) {
                                    element.querySelector('#Gridincome').value = this.moneyItem.income;
                                }
                                if (element.querySelector('#incomedropdown_hidden')) {
                                    element.querySelector('#incomedropdown_hidden').value = this.moneyItem.income;
                                    eventBus.$emit('incomeInitial', this.moneyItem.income);
                                }
                                if (element.querySelector('#Gridaccount')) {
                                    element.querySelector('#Gridaccount').value = this.moneyItem.account;
                                }
                                if (element.querySelector('#Gridtype')) {
                                    element.querySelector('#Gridtype').value = this.moneyItem.type;
                                }
                                if (element.querySelector('#GridportIdLoad')) {
                                    element.querySelector('#GridportIdLoad').value = this.moneyItem.portNameLoad;
                                }
                                if (element.querySelector('#GridportIdDisch')) {
                                    element.querySelector('#GridportIdDisch').value = this.moneyItem.portNameDisch;
                                }
                                if (element.querySelector('#GridrateBaf')) {
                                    element.querySelector('#GridrateBaf').value = this.moneyItem.rateBaf;
                                }
                                if (element.querySelector('#Gridnotes')) {
                                    element.querySelector('#Gridnotes').value = this.moneyItem.notes;
                                }
                            }
                        }
                    });
                    // eventBus.$on('statusUpdated', (e) => {
                    //     this.status = e;
                    // });
                    eventBus.$on('incomeUpdated', (e) => {
                        this.income = e;
                    });
                    eventBus.$on('unitUpdated', (e) => {
                        this.unit = e;
                    });
                    eventBus.$on('contactUpdated', (e) => {
                        this.contactId = e.id;
                        this.contactText = e.name;
                    });
                    eventBus.$on('newPortCall', (e) => {
                        this.portCall = e;
                    });
                    eventBus.$on('newPol', (e) => {
                        this.pol = e;
                    });
                    eventBus.$on('newPod', (e) => {
                        this.pod = e;
                    });
                    eventBus.$on('newAccount', (e) => {
                        this.account = e;
                    });
                    eventBus.$on('currencyUpdated', (e) => {
                        this.currency = e;
                    });
                }
                if (args.requestType === 'beginEdit') {
                    // eventBus.$emit('addMoneyItem', args.rowData);
                    this.$refs.grid.ej2Instances.showSpinner();
                }
                if (args.requestType === 'add') {
                    // Init values
                    args.data.quantity = 0;
                    args.data.quantityMin = 0;
                    args.data.quantityMax = 0;
                    args.data.sum = 0;
                    args.data.rate = 0;
                    args.data.income = 0;
                    args.data.rateFinal = 0;
                    args.data.quantityFinal = 0;
                    args.data.tonFinal = 0;
                    args.data.sumFinal = 0;
                    args.data.plusMinus = 0;
                    args.data.status = 0;
                    args.data.created = moment.utc().format();
                    args.data.shipId = this.resource.shipId;
                    args.data.id = Date.now();
                    setTimeout(() => {
                        eventBus.$emit('addMoneyItem', args.data);
                    }, 100);
                }
                
                if (args.requestType === 'save') {
                    if (this.isVoyage === 1) {
                        if (document.getElementsByClassName('e-daterangepicker')[1]) {
                            args.data.laycan = document.getElementsByClassName('e-daterangepicker')[1].ej2_instances[0].value;
                        }
                    } else if (document.getElementsByClassName('e-daterangepicker')[0]) {
                            args.data.laycan = document.getElementsByClassName('e-daterangepicker')[0].ej2_instances[0].value;
                        }
                    let calcRule;
                    if (this.moneyItem) {
                        args.data.moneyItemName = this.moneyItem.name; 
                        args.data.moneyItemId = this.moneyItem.id;
                        args.data.contactId = this.moneyItem.contactId;
                        args.data.contactText = this.moneyItem.contactText;
                        args.data.contactName = this.moneyItem.contactName;
                        args.data.notes = this.moneyItem.notes;
                        args.data.portIdLoad = this.moneyItem.portIdLoad;
                        args.data.pol = this.moneyItem.portNameLoad;
                        args.data.portIdDisch = this.moneyItem.portIdDisch;
                        args.data.pod = this.moneyItem.portNameDisch;
                        args.data.rateBaf = args.data.rateBaf ? args.data.rateBaf : this.moneyItem.rateBaf && this.moneyItem.rateBaf !== '0.00' ? Number.parseFloat(this.moneyItem.rateBaf) : 0;
                        args.data.rate = args.data.rate ? args.data.rate : this.moneyItem.rate && this.moneyItem.rate !== '0.00' ? Number.parseFloat(this.moneyItem.rate) : 0;
                        args.data.unit = this.moneyItem.unit;
                        args.data.currency = this.moneyItem.currency;
                        args.data.income = this.moneyItem.income;
                        args.data.account = this.moneyItem.account;
                        calcRule = this.moneyItem.calcRule;
                        // Get ship for moneyitem shipContactRule check
                        let ship = {};
                        if (this.resource.shipId) {
                            ship = this.ships.find((o) => o.id === this.resource.shipId);
                        } else if (this.shipIdNominated) {
                            ship = this.ships.find((o) => o.id === this.shipIdNominated);
                        }
                        if (this.moneyItem.shipContactRule === 1 && ship && typeof ship.ownerId !== 'undefined') {
                            console.log(`Money item "${this.moneyItem.name}" has ship contact rule active. Ship contact is: ${ship.ownerName}`);
                            if (!args.data.contactId) {
                                args.data.contactId = ship.ownerId;
                                args.data.contactText = ship.ownerName;
                            } else {
                                console.log('ContactId already set, not updated.')
                            }
                            
                        }
                    }
                    if (!args.data.name) {
                        // args.cancel = true;
                        // this.alertError(`Name property is mandatory.`);
                    }
                    args.data.shipId = this.resource.shipId;
                    // if (this.status !== null) {
                        // args.data.status = this.status ? 1 : 0;
                    // }
                    if (this.income !== null) {
                        args.data.income = this.income;
                        this.income = null;
                    }
                    if (this.unit !== null) {
                        args.data.unit = this.unit;
                        this.unit = null;
                    }
                    if (this.contactId !== null) {
                        args.data.contactId = this.contactId;
                        args.data.contactText = this.contactText;
                        this.contactId = null;
                        this.contactText = null;
                    }
                    if (this.currency !== null) {
                        args.data.currency = this.currency;
                        this.currency = null;
                    }
                    if (this.portCall !== null) {
                        args.data.portCallName = this.portCall.portCallName;
                        args.data.portCallId = this.portCall.portCallId;
                        args.data.order = this.portCall.order;
                        args.data.portId = this.portCall.portId;
                        this.portCall = null;
                    }
                    if (args.data.name) {
                        const split = args.data.name.split(' (');
                        args.data.name = split && split[0] ? split[0] : args.data.name;
                    }
                    if (this.pol !== null) {
                        args.data.pol = this.pol.portCallName;
                        args.data.portIdLoad = this.pol.portId;
                        this.pol = null;
                    } 
                    if (this.pod !== null) {
                        args.data.pod = this.pod.portCallName;
                        args.data.portIdDisch = this.pod.portId;
                        this.pod = null;
                    }
                    if (this.account !== null) {
                        args.data.account = this.account;
                        this.account = null;
                    }    
                    if (!calcRule) {
                        this.moneyItem = this.params.moneyItems.find((o) => o.id === args.data.moneyItemId);
                        calcRule = this.moneyItem ? this.moneyItem.calcRule : 'basic';
                    }
                    const sign = args.data.income === 1 ? 1 : -1;
                    // check exchange rate if currency is different than customer base currency
                    const exchangeRate = this.getExchangeRate(args.data.currency);
                    if (!args.data.status) {
                        args.data.exchangeRate = exchangeRate;
                    }
                    if (calcRule === 'bunker') {
                        if (!args.data.rate) {
                            args.data.rate = this.resource.bunkerRate;
                        }
                        args.data.rateFinal = args.data.status ? args.data.rateFinal : args.data.rate;
                        if (args.data.rate) {
                            if (tools.isNumeric(args.data.quantity) || tools.isNumeric(args.data.quantityFinal)) {
                                if (tools.isNumeric(args.data.quantity)) {
                                    args.data.sum = Math.round(args.data.rate * args.data.quantity / exchangeRate * sign * 100) / 100;
                                }
                                if (tools.isNumeric(args.data.quantityFinal) && !args.data.status) {
                                    args.data.sumFinal = Math.round(args.data.rateFinal * args.data.quantityFinal / exchangeRate * sign * 100) / 100;
                                }
                            } else if (!args.data.sum && this.voyageTotalsUpdated && this.voyageTotalsUpdated.cons) {
                                args.data.sum = Math.round(args.data.rate * this.voyageTotalsUpdated.cons / exchangeRate * sign * 100) / 100;
                                args.data.quantity = this.voyageTotalsUpdated.cons;
                                if (!args.data.status) {
                                    args.data.sumFinal = args.data.sum;
                                    args.data.quantityFinal = this.voyageTotalsUpdated.cons;
                                }
                            }
                        }
                        // If status is confirmed calculate rateFinal from sumFinal/qtyFinal
                        if (args.data.status) {
                            if (args.data.quantityFinal && args.data.sumFinal) {
                                args.data.rateFinal = Math.round(args.data.sumFinal / args.data.quantityFinal * exchangeRate * sign * 100) / 100;
                            } else {
                                args.data.rateFinal = 0;
                            }
                        }
                    } else if (calcRule === 'bunkerSteaming') {
                        if (!args.data.rate) {
                            args.data.rate = this.resource.bunkerSteamingRate;
                        }
                        args.data.rateFinal = args.data.status ? args.data.rateFinal : args.data.rate;
                        if (args.data.rate) {
                            if (tools.isNumeric(args.data.quantity) || tools.isNumeric(args.data.quantityFinal)) {
                                if (tools.isNumeric(args.data.quantity)) {
                                    args.data.sum = Math.round(args.data.rate * args.data.quantity / exchangeRate * sign * 100) / 100;
                                }
                                if (tools.isNumeric(args.data.quantityFinal) && !args.data.status) {
                                    args.data.sumFinal = Math.round(args.data.rateFinal * args.data.quantityFinal / exchangeRate * sign * 100) / 100;
                                }
                            } else if (!args.data.sum && this.voyageTotalsUpdated && this.voyageTotalsUpdated.consSteaming) {
                                args.data.sum = Math.round(args.data.rate * this.voyageTotalsUpdated.consSteaming / exchangeRate * sign * 100) / 100;
                                args.data.quantity = this.voyageTotalsUpdated.consSteaming;
                                if (!args.data.status) {
                                    args.data.sumFinal = args.data.sum;
                                    args.data.quantityFinal = this.voyageTotalsUpdated.consSteaming;
                                }
                            }
                        }
                        // If status is confirmed calculate rateFinal from sumFinal/qtyFinal
                        if (args.data.status) {
                            if (args.data.quantityFinal && args.data.sumFinal) {
                                args.data.rateFinal = Math.round(args.data.sumFinal / args.data.quantityFinal * exchangeRate * sign * 100) / 100;
                            } else {
                                args.data.rateFinal = 0;
                            }
                        }
                    } else if (calcRule === 'secondaryBunkerSteaming') {
                        if (!args.data.rate) {
                            args.data.rate = this.resource.secondaryBunkerSteamingRate;
                        }
                        args.data.rateFinal = args.data.status ? args.data.rateFinal : args.data.rate;
                        if (args.data.rate) {
                            if (tools.isNumeric(args.data.quantity) || tools.isNumeric(args.data.quantityFinal)) {
                                if (tools.isNumeric(args.data.quantity)) {
                                    args.data.sum = Math.round(args.data.rate * args.data.quantity / exchangeRate * sign * 100) / 100;
                                }
                                if (tools.isNumeric(args.data.quantityFinal) && !args.data.status) {
                                    args.data.sumFinal = Math.round(args.data.rateFinal * args.data.quantityFinal / exchangeRate * sign * 100) / 100;
                                }
                            } else if (!args.data.sum && this.voyageTotalsUpdated && this.voyageTotalsUpdated.secondaryConsSteaming) {
                                args.data.sum = Math.round(args.data.rate * this.voyageTotalsUpdated.secondaryConsSteaming / exchangeRate * sign * 100) / 100;
                                args.data.quantity = this.voyageTotalsUpdated.secondaryConsSteaming;
                                if (!args.data.status) {
                                    args.data.sumFinal = args.data.sum;
                                    args.data.quantityFinal = this.voyageTotalsUpdated.secondaryConsSteaming;
                                }
                            }
                        }
                        // If status is confirmed calculate rateFinal from sumFinal/qtyFinal
                        if (args.data.status) {
                            if (args.data.quantityFinal && args.data.sumFinal) {
                                args.data.rateFinal = Math.round(args.data.sumFinal / args.data.quantityFinal * exchangeRate * sign * 100) / 100;
                            } else {
                                args.data.rateFinal = 0;
                            }
                        }
                    } else if (calcRule === 'bunkerPort') {
                        if (!args.data.rate) {
                            args.data.rate = this.resource.bunkerPortRate;
                        }
                        args.data.rateFinal = args.data.status ? args.data.rateFinal : args.data.rate;
                        if (args.data.rate) {
                            if (tools.isNumeric(args.data.quantity) || tools.isNumeric(args.data.quantityFinal)) {
                                if (tools.isNumeric(args.data.quantity)) {
                                    args.data.sum = Math.round(args.data.rate * args.data.quantity / exchangeRate * sign * 100) / 100;
                                }
                                if (tools.isNumeric(args.data.quantityFinal) && !args.data.status) {
                                    args.data.sumFinal = Math.round(args.data.rateFinal * args.data.quantityFinal / exchangeRate * sign * 100) / 100;
                                }
                            } else if (!args.data.sum && this.voyageTotalsUpdated && this.voyageTotalsUpdated.consPort) {
                                args.data.sum = Math.round(args.data.rate * this.voyageTotalsUpdated.consPort / exchangeRate * sign * 100) / 100;
                                args.data.quantity = this.voyageTotalsUpdated.consPort;
                                if (!args.data.status) {
                                    args.data.sumFinal = args.data.sum;
                                    args.data.quantityFinal = this.voyageTotalsUpdated.consPort;
                                }
                            }
                        }
                        // If status is confirmed calculate rateFinal from sumFinal/qtyFinal
                        if (args.data.status) {
                            if (args.data.quantityFinal && args.data.sumFinal) {
                                args.data.rateFinal = Math.round(args.data.sumFinal / args.data.quantityFinal * exchangeRate * sign * 100) / 100;
                            } else {
                                args.data.rateFinal = 0;
                            }
                        }
                    } else if (calcRule === 'commission') {
                        args.data.quantity = 0;
                        args.data.quantityFinal = 0;
                        this.$refs.grid.ej2Instances.dataSource.map((o) => {
                            if (o.income > 0 && tools.isNumeric(o.sum) && o.type === 'Freight') {
                                args.data.quantity += o.sum;
                                args.data.quantityFinal += o.sumFinal;
                            }
                        });
                        if (tools.isNumeric(args.data.rate) && tools.isNumeric(args.data.quantity) && args.data.quantity > 0) {
                            args.data.sum = Math.round(args.data.rate * args.data.quantity / exchangeRate * sign / 100 * 100) / 100;
                        }
                        args.data.rateFinal = args.data.status ? args.data.rateFinal : args.data.rate;
                        if (tools.isNumeric(args.data.rateFinal) && tools.isNumeric(args.data.quantityFinal) && args.data.quantityFinal > 0 && !args.data.status) {
                            args.data.sumFinal = Math.round(args.data.rateFinal * args.data.quantityFinal / exchangeRate * sign / 100 * 100) / 100;
                        }
                    } else if (calcRule === 'day') {
                        const days = this.voyageTotalsUpdated.voyageTime;
                        if (!args.data.quantity) {
                            args.data.quantity = days;
                        }
                        if (!args.data.quantityFinal && !args.data.status) {
                            args.data.quantityFinal = days;
                        }
                        if (!args.data.rate) {
                            args.data.rate = this.resource.dayRate;
                        }
                        args.data.rateFinal = args.data.status ? args.data.rateFinal : args.data.rate;
                        if (tools.isNumeric(args.data.rate) && tools.isNumeric(args.data.quantity) && exchangeRate) {
                            args.data.sum = Math.round(args.data.rate * args.data.quantity / exchangeRate * sign * 100) / 100;
                        }
                        if (tools.isNumeric(args.data.rateFinal) && tools.isNumeric(args.data.quantityFinal) && exchangeRate && !args.data.status) {
                            args.data.sumFinal = Math.round(args.data.rateFinal * args.data.quantityFinal / exchangeRate * sign * 100) / 100;
                        }
                    } else if (args.data.type === 'Freight' && tools.isNumeric(args.data.bafPercentage) && args.data.bafPercentage != 0 && args.data.rateBaf) { // BAF calculation for Freight type
                        args.data.rateFinal = args.data.status ? args.data.rateFinal : args.data.rate;
                        if (tools.isNumeric(args.data.rate) && tools.isNumeric(args.data.quantity) && exchangeRate) {
                            args.data.sum = Math.round((args.data.rate + (args.data.bafPercentage / 100) * args.data.rateBaf) * args.data.quantity / exchangeRate * sign * 100) / 100; 
                        } else {
                            args.data.sum = 0;
                        }
                        if (tools.isNumeric(args.data.rateFinal) && tools.isNumeric(args.data.quantityFinal)) {
                            if (!args.data.status) {
                                args.data.sumFinal = Math.round((args.data.rateFinal + (args.data.bafPercentage / 100) * args.data.rateBaf) * args.data.quantityFinal / exchangeRate * sign * 100) / 100;
                            }
                        } else if (!args.data.status) {
                            args.data.sumFinal = 0;
                        }
                    } else {
                        const percentageFactor = args.data.unit === '%' ? 0.01 : 1;
                        if (calcRule === 'loadingCost' || calcRule === 'dischargeCost') {
                            if (!args.data.rateFinal) {
                                args.data.rateFinal = args.data.status ? args.data.rateFinal : args.data.rate;
                            }
                        } else if (!args.data.status) {
                            if (!args.data.rateFinal && args.data.rate) {
                                args.data.rateFinal = args.data.rate;
                            }
                            if (!args.data.rate && args.data.rateFinal) {
                                args.data.rate = args.data.rateFinal;
                            }
                        }
                        if (tools.isNumeric(args.data.rate) && tools.isNumeric(args.data.quantity)) {
                            args.data.sum = Math.round(args.data.rate * args.data.quantity / exchangeRate * sign * percentageFactor * 100) / 100;
                        } else {
                            args.data.sum = 0;
                        }
                        if (tools.isNumeric(args.data.rateFinal) && tools.isNumeric(args.data.quantityFinal)) {
                            if (!args.data.status) {
                                args.data.sumFinal = Math.round(args.data.rateFinal * args.data.quantityFinal / exchangeRate * sign * percentageFactor * 100) / 100;
                            }
                        } else if (!args.data.status) {
                            args.data.sumFinal = 0;
                        }
                    }
                    // Add default final values if not defined and status is not confirmed
                    if (!args.data.status) {
                        if (!args.data.rateFinal && args.data.rate) {
                            args.data.rateFinal = args.data.rate;
                        }
                        if (!args.data.rate && args.data.rateFinal) {
                            args.data.rate = args.data.rateFinal;
                        }
                        if (!tools.isNumeric(args.data.quantityFinal)) {
                            args.data.quantityFinal = args.data.quantity;
                        }
                        if (!tools.isNumeric(args.data.sumFinal)) {
                            args.data.sumFinal = args.data.sum;
                        }
                    }
                    // Add ETS rate
                    if (args.data.rateEts) {
                        console.log('Add ETS rate: ' + args.data.rateEts)
                        args.data.sum = args.data.sum + Math.round(args.data.rateEts * args.data.quantity * 100) / 100;
                        if (!args.data.status) {
                            args.data.sumFinal = args.data.sumFinal + Math.round(args.data.rateEts * args.data.quantityFinal * 100) / 100;
                        }
                    }
                    
                    // Add tonFinal if unit is ton, mton
                    if (args.data.unit === 'ton' && args.data.type === 'Freight' && args.data.income === 1) {
                        args.data.tonFinal = args.data.quantityFinal;
                    } else if (args.data.unit === 'mton' && args.data.type === 'Freight' && args.data.income === 1) {
                        args.data.tonFinal = args.data.quantityFinal * 1000;
                    }
                    eventBus.$off('moneyItem');
                    // eventBus.$off('statusUpdated');
                    this.moneyItem = null;
                }
            },
            actionComplete(args){
                console.log('actionComplete', args.requestType, args.data, args.data ? args.data.id : 'No data');
                if (args.requestType === 'save' && args.data) {
                    console.log(args.data.type, args.data.moneyItemName, args.data.rateFinal, args.data.quantity);
                    args.data.modified = moment.utc().format();
                    if (args.data.status && !moment(args.data.createdFinal).isValid()) {
                        args.data.createdFinal = moment.utc().format();
                    } else if (!args.data.status) {
                        args.data.createdFinal = null;
                    }
                    // let index = this.$refs.grid.ej2Instances.dataSource.findIndex((o) => {return o.id === args.data.id});
                    // console.log('row index', index)
                    // this.$refs.grid.ej2Instances.dataSource[index] = JSON.parse(JSON.stringify(args.data));
                    
                     // Update commission also if Freight is changed
                    if (args.data.type === 'Freight') {
                        this.$refs.grid.ej2Instances.dataSource = this.$refs.grid.ej2Instances.dataSource.map((row) => {
                            const moneyItem = this.params.moneyItems.find((o) => o.id === row.moneyItemId && o.active === 1);
                            const calcRule = moneyItem && moneyItem.calcRule ? moneyItem.calcRule : null;
                            if (calcRule === 'commission') {
                                row.quantity = 0;
                                row.quantityFinal = 0;
                                this.$refs.grid.ej2Instances.dataSource.map((o) => {
                                    if (o.income > 0 && tools.isNumeric(o.sum) && o.type === 'Freight') {
                                        row.quantity += o.sum;
                                    }
                                    if (o.income > 0 && tools.isNumeric(o.sumFinal) && o.type === 'Freight') {
                                        row.quantityFinal += o.sumFinal;
                                    }
                                });
                                const sign = row.income === 1 ? 1 : -1;
                                // check exchange rate if currency is different than customer base currency
                                const exchangeRate = this.getExchangeRate(row.currency);
                                row.exchangeRate = exchangeRate;
                                if (!tools.isNumeric(row.rateFinal) && row.rate) {
                                    row.rateFinal = row.rate;
                                }
                                if (!tools.isNumeric(row.rate) && row.rateFinal) {
                                    row.rate = row.rateFinal;
                                }
                                if (tools.isNumeric(row.rate) && tools.isNumeric(row.quantity) && row.quantity > 0) {
                                    row.sum = Math.round(row.rate * row.quantity / exchangeRate * sign / 100 * 100) / 100;
                                }
                                if (!row.status && tools.isNumeric(row.rate) && tools.isNumeric(row.quantityFinal) && row.quantityFinal > 0) {
                                    row.sumFinal = Math.round(row.rate * row.quantityFinal / exchangeRate * sign / 100 * 100) / 100;
                                }
                                // Add tonFinal if unit is ton, mton
                                if (row.unit === 'ton' && row.type === 'Freight' && row.income === 1) {
                                    row.tonFinal = row.quantityFinal;
                                } else if (row.unit === 'mton' && row.type === 'Freight' && row.income === 1) {
                                    row.tonFinal = row.quantityFinal * 1000;
                                }
                            }
                            return row;
                        });
                    }

                    // Calculate DA rateFinal from sumFinal if status if confirmed
                    if (args.data.name === 'DA' && args.data.status && args.data.sumFinal && args.data.quantityFinal) {
                        this.$refs.grid.ej2Instances.dataSource = this.$refs.grid.ej2Instances.dataSource.map((row) => {
                            if (row.id === args.data.id) {
                                const sign = row.income == 1 ? 1 : -1;
                                row.rateFinal = sign * row.sumFinal*row.exchangeRate/row.quantityFinal;
                                console.log('Calculate DA rateFinal from sumFinal: ' + row.sumFinal + ' sign: ' + sign + ' rateFinal: ' + row.rateFinal + ' exchangeRate: ' + row.exchangeRate);
                            }
                            return row;
                        });
                    }

                    // Update avg DA rate, quantity and calculate sum - NOT WORKING SINCE IT CLEARS THE GRID
                    // if (args.data.name === 'DA' && args.data.portId && !args.data.rate) {
                    //     let dataSource = JSON.parse(JSON.stringify(this.$refs.grid.ej2Instances.dataSource));
                    //     this.fetchAvgDa({portId: args.data.portId, shipId: this.resource.shipId}).then((data) => {
                    //         dataSource[index].rate = data && data.avgDa !== null ? data.avgDa : 1000;
                    //         dataSource[index].quantity = 1;
                    //         dataSource[index].sum = -1 * dataSource[index].rate * dataSource[index].quantity;
                    //         eventBus.$emit('moneyDataUpdate', dataSource);
                    //         this.$refs.grid.ej2Instances.dataSource = dataSource;
                    //         if (this.$refs.grid) {
                    //             this.$refs.grid.refresh();
                    //         }
                    //     });
                    // }

                    // This is a fix if Freight item is added and values are not stored anymore
                    this.data = this.data.map((row) => {
                        // console.log(row.id, args.data.id)
                        if (args.data && typeof args.data.id === 'string') {
                            args.data.id = Number.parseInt(args.data.id);
                        }
                        if (row.id === args.data.id) {
                            row = args.data;
                        }
                        return row;
                    });
                    // this.data = JSON.parse(JSON.stringify(this.$refs.grid.ej2Instances.dataSource));
                    // console.log('actionComplete QUANTITY', args.data.quantity);
                    // console.log('SAVE DATA', this.data[1].quantity);
                    eventBus.$emit('moneyDataUpdate', this.data);
                    // if (this.$refs.grid) {
                        // this.$refs.grid.refresh();
                    // }
                }
                if (args.requestType === 'save' || args.requestType === 'cancel') {
                    eventBus.$emit('disableSave', false);
                }
                if (args.requestType === 'delete') {
                    if (typeof args.data[0].id !== 'undefined' && args.data[0].id >= 0) {
                        this.$refs.grid.ej2Instances.dataSource = this.$refs.grid.ej2Instances.dataSource.filter((o) => o.id !== args.data[0].id);
                        this.data = JSON.parse(JSON.stringify(this.$refs.grid.ej2Instances.dataSource));
                        eventBus.$emit('moneyDataUpdate', this.data);
                    }
                }
                if (args.requestType === 'beginEdit') {
                    this.$refs.grid.ej2Instances.hideSpinner();
                }
            },
            rowSelected(args) {
                this.rowIndex = args.rowIndex;
                console.log(args.data.id)
                rowId = args.data.id;
                this.$refs.grid.ej2Instances.toolbarModule.enableItems(['copy'], true); // Enable toolbar items.
                // console.log('row ' + this.rowIndex, args)
            },
            tonFinalEdit() {
                let selected = false;
                if (rowId || rowId === 0) {
                    selected = this.data.find((o) => o.id === rowId);
                }
                if (selected && selected.income === 1) {
                    return true;
                } else {
                    return false;
                }
            },
            toolbarClick(args) {
                if (args.item.id.includes('excelexport')) {
                    this.$refs.grid.excelExport();
                } else if (args.item.id === 'expand') {
                    this.gridExpand = !this.gridExpand;
                    ++this.gridKey;
                } else if (args.item.id === 'copy') {
                    if (rowId !== null && typeof rowId !== 'undefined') {
                        this.data = JSON.parse(JSON.stringify(this.$refs.grid.ej2Instances.dataSource));
                        const newRow = JSON.parse(JSON.stringify(this.data.find((o) => o.id === rowId)));
                        newRow.id = Date.now();
                        if (newRow.type === 'Fairway') {
                            const x = newRow.notes.split('#');
                            newRow.notes = x[0];
                        }
                        // this.data.push(newRow);
                        this.data.splice(this.rowIndex, 0, newRow)
                        eventBus.$emit('moneyDataUpdate', this.data); // This clear values from money rows!
                    }
                } else if (args.item.id === 'sumFinal') {
                    this.toggleRowVisibility();
                } else if (args.item.id === 'quantities') {
                    if (this.$refs.grid) {
                        this.data = JSON.parse(JSON.stringify(this.$refs.grid.ej2Instances.dataSource));
                        const freights = this.data.filter((o) => o.type === 'Freight' && o.quantityRule && o.portIdLoad && o.portIdDisch);
                        // console.log('Freights with quantityRule, POL and POD defined', freights);
                        if (freights && freights.length > 0) {
                            // First reset quantity to zero when matching
                            for (let i = 0, il = freights.length; i < il; i++) {
                                for (let j = 0, jl = this.data.length; j < jl; j++) {
                                    if (freights[i].quantityRule === this.data[j].quantityRule && this.data[j].income < 1 && !this.data[j].status) {
                                        if (freights[i].portIdLoad === this.data[j].portId && this.data[j].type === 'Loading') {
                                            if (this.data[j].portCallType === 2 || this.data[j].portCallType === 7) {
                                                // console.log('Reseting ' + this.data[j].moneyItemName + ' quantities to zero');
                                                this.data[j].quantity = 0;
                                                this.data[j].quantityFinal = 0;
                                            }
                                        } else if (freights[i].portIdDisch === this.data[j].portId && (this.data[j].type === 'Discharging' || this.data[j].type === 'Discharging - other')) {
                                            if (this.data[j].portCallType === 3 || this.data[j].portCallType === 7) {
                                                // console.log('Reseting ' + this.data[j].moneyItemName + ' quantities to zero');
                                                this.data[j].quantity = 0;
                                                this.data[j].quantityFinal = 0;
                                            }
                                        } else if (this.data[j].type === 'Freight cost' || this.data[j].type === 'Port cost' || this.data[j].type === 'Tally') {
                                            // console.log('Reseting ' + this.data[j].moneyItemName + ' quantities to zero');
                                            this.data[j].quantity = 0;
                                            this.data[j].quantityFinal = 0;
                                        }
                                    }
                                }
                            }
                            for (let i = 0, il = freights.length; i < il; i++) {
                                for (let j = 0, jl = this.data.length; j < jl; j++) {
                                    if (freights[i].quantityRule === this.data[j].quantityRule && this.data[j].income < 1 && !this.data[j].status) {
                                        if (freights[i].portIdLoad === this.data[j].portId && this.data[j].type === 'Loading') {
                                            if (this.data[j].portCallType === 2 || this.data[j].portCallType === 7) {
                                                if (freights[i].unit !== 'ton' && this.data[j].unit === 'ton') {
                                                    this.data[j].quantity += freights[i].tonFinal ? freights[i].tonFinal : 0;
                                                    this.data[j].quantityFinal += freights[i].tonFinal ? freights[i].tonFinal : 0;
                                                    if (freights[i].tonFinal) {
                                                        console.log(`Loading quantity of ${freights[i].tonFinal} (from ton final) added to ${this.data[j].moneyItemName}`);
                                                    }
                                                } else {
                                                    this.data[j].quantity += freights[i].quantity;
                                                    this.data[j].quantityFinal += freights[i].quantityFinal;
                                                    if (freights[i].quantity || freights[i].quantityFinal) {
                                                        console.log(`Loading quantity of ${freights[i].quantity} and final quantity of ${freights[i].quantityFinal} added to ${this.data[j].moneyItemName}`);
                                                    }
                                                }
                                            }
                                        } else if (freights[i].portIdDisch === this.data[j].portId && (this.data[j].type === 'Discharging' || this.data[j].type === 'Discharging - other' || this.data[j].type === 'Tally')) {
                                            if (this.data[j].portCallType === 3 || this.data[j].portCallType === 7) {
                                                if (freights[i].unit !== 'ton' && this.data[j].unit === 'ton') {
                                                    this.data[j].quantity += freights[i].tonFinal ? freights[i].tonFinal : 0;
                                                    this.data[j].quantityFinal += freights[i].tonFinal ? freights[i].tonFinal : 0;
                                                    if (freights[i].tonFinal) {
                                                        console.log(`Discharge quantity of ${freights[i].tonFinal} (from ton final) added to ${this.data[j].moneyItemName}`);
                                                    }
                                                } else {
                                                    this.data[j].quantity += freights[i].quantity;
                                                    this.data[j].quantityFinal += freights[i].quantityFinal;
                                                    if (freights[i].quantity || freights[i].quantityFinal) {
                                                        console.log(`Discharge quantity of ${freights[i].quantity} and final quantity of ${freights[i].quantityFinal} added to ${this.data[j].moneyItemName}`);
                                                    }
                                                }
                                            }
                                        } else if (this.data[j].type === 'Freight cost' || this.data[j].type === 'Port cost') {
                                            console.log(this.data[j].type + ` quantity of ${freights[i].quantity} and final quantity of ${freights[i].quantityFinal} added to ${this.data[j].moneyItemName}`);
                                            this.data[j].quantity += freights[i].quantity;
                                            this.data[j].quantityFinal += freights[i].quantityFinal;
                                        }
                                    }
                                }
                            }
                        }
                        eventBus.$emit('moneyDataUpdate', this.data);
                    }
                }
            },
            moneyTableHeight() {  
                if (this.gridExpand) {
                    return Math.round((window.innerHeight - 140));
                } 
                    const maxRows = 12; // Math.round((window.innerHeight - 220) / 25);
                    let length = 5 * 25;
                    if (Array.isArray(this.data) && this.data.length >= maxRows) {
                        length = maxRows * 25;
                    } else if (Array.isArray(this.data) && this.data.length > 5) {
                        length = 25 * this.data.length;
                    }
                    return length;
            },
            getExchangeRate(currency) {
                if (this.getUser.user.baseCurrency && currency && this.getUser.user.baseCurrency !== currency) {
                    return typeof this.exchangeRates[currency] !== 'undefined' ? this.exchangeRates[currency] : 1;
                } 
                    return 1;
            },
            isVisible(param) {
                if (typeof this.features.moneyTable === 'object' && this.features.moneyTable !== null) {
                    if (typeof this.features.moneyTable.planned === 'object' && this.features.moneyTable.planned !== null 
                        && typeof this.features.moneyTable.planned[param] !== 'undefined' && this.features.moneyTable.planned[param] === 1) {
                        return true;
                    } if (this.projectStatus === 2 && typeof this.features.moneyTable.confirmed === 'object' && this.features.moneyTable.confirmed !== null 
                        && typeof this.features.moneyTable.confirmed[param] !== 'undefined' && this.features.moneyTable.confirmed[param] === 1) {
                            return true;
                    } 
                        return false;
                } 
                    return false;
            },
            toggleRowVisibility(hide = false) {
                if (this.$refs.grid) {
                    const data = this.$refs.grid.ej2Instances.dataSource;
                    const rows = this.$refs.grid.ej2Instances.getRows();
                    if (data && data[0] && rows && rows[0]) {
                        // Show or hide items?
                        if (!hide && rows.findIndex(o => o.style.visibility === 'collapse') !== -1) {
                            for (let i = 0, il = rows.length; i < il; i++) {
                                rows[i].style.visibility = 'visible';        
                            }
                        } else {
                            for (let i = 0, il = rows.length; i < il; i++) {
                                if (!data[i].sumFinal) {
                                    rows[i].style.visibility = 'collapse';
                                }        
                            }
                        }
                    }
                }
            }
        },
        provide: {
            grid: [Sort, Resize, Edit, Toolbar, ExcelExport, ColumnChooser, Reorder, ForeignKey, Aggregate]
        }
    };
</script>

<style>
    /* syncfusion grid */
    .e-grid .e-groupheadercell {
        border-radius: 16px !important;
        height: 32px !important;
        line-height: 32px !important;
    }

    .e-grid .e-groupheadercell .e-grouptext {
        height: 32px !important;
        line-height: 32px !important;
    }

    .e-input-group .e-input-group-icon,
    .e-input-group.e-control-wrapper .e-input-group-icon {
        margin-right: -1px !important;
        margin-left: -1px !important;
    }

    .e-grid .e-rowcell,
    .e-grid .e-headercell,
    .e-grid .e-detailheadercell {
        padding: 4px !important;
    }

    .income .e-rowcell {
        /* color: #f44336 !important; */
        font-weight: 500;
        opacity: 1 !important;
    }
    .sign-issue {
        background-color: rgba(244, 67, 54, 0.3) !important;
    }
    .e-summarycell {
        padding: 4px !important;
    }
    .e-dropdownbase .e-list-group-item, .e-fixed-head {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
    .e-dropdownbase .e-list-item {
        padding-right: 8px !important;
        text-indent: 8px !important;
    }
    .e-dropdownbase.e-dd-group .e-list-item {
        padding-left: 12px !important;
        text-indent: 0 !important;
    }
    .e-expand::before {
      content: '\e70e';
      position: relative;
      top:-1px;
    }
    .e-copy::before {
      content: '\e280';
      position: relative;
      top:-1px;
    }
    .e-quantities::before {
      content: '\e663';
      position: relative;
      top:-2px;
    }
    .e-sumfinal::before {
      content: '\1f441';
      position: relative;
      top:-4px;
    }
    .e-grid .e-rowcell.column{
        background-color: rgba(199, 167, 24, 0.1);
    }   
    .e-grid .e-rowcell.columnfinal{
        background-color: rgba(40, 211, 117, 0.1);
    }
    .gridexpand {
        position: fixed !important;
        top: 0;
        bottom: 0;
        z-index: 1;
        margin: 10px;
        left: 0;
        right: 0;
        background: #fff;
    }
</style>
