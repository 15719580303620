<template>
  <div>
    <v-toolbar dense fixed flat height="65" class="mb-4">
      <v-text-field v-model="search" dense outlined :clearable="true" persistent-placeholder style="width:200px;margin-top:34px" @click:append="search=''" :append-icon="mdiMagnify" label="Search users" />
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table :headers="headers" :items="users" :search="search" :single-expand="true" :expanded.sync="expanded"
      item-key="id" :sort-by="sortBy" :sort-desc="sortDesc" :footer-props="{ showFirstLastPage: true}"
      mobile-breakpoint="100">
<template #item="{item}">
        <tr @click="expandPanel(item)">
          <td>{{ item.titleName }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.username }}</td>
          <td>{{ item.roleNamesText }}</td>
          <td>
            <v-icon :color="item.active ? 'green' : 'red'">{{ item.active ? mdiCheck : mdiClose }}</v-icon>
          </td>
          <td class="d-none d-sm-table-cell">{{ formatDate(item.loginTime) }}</td>
          <td class="d-none d-sm-table-cell">{{ item.author }}</td>
          <td class="d-none d-sm-table-cell">{{ formatDate(item.updated) }}</td>
        </tr>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-card tile>
            <v-card-actions>
              <v-btn text color="warn" @click="cancel(false);">
                Close
              </v-btn>
            </v-card-actions>
            <v-divider />
            <v-form class="mt-6" lazy-validation>
              <v-layout wrap>
                <v-flex px-4 xs12 sm6 md3>
                  <v-combobox :prepend-icon="mdiBadgeAccountOutline" readonly v-model="item.title" :items="titles" label="Title"
                              multiple outlined required></v-combobox>
                </v-flex>
                <v-flex px-4 xs12 sm6 md3>
                  <v-text-field v-model="item.name" outlined required persistent-placeholder readonly
                                :prepend-icon="mdiAccountOutline" label="Name" placeholder="John Doe"></v-text-field>
                </v-flex>
                <v-flex px-4 xs12 sm6 md3>
                  <v-text-field v-model="item.username" :disabled="true" :rules="usernameRules" outlined required persistent-placeholder
                                :prepend-icon="mdiBadgeAccountHorizontalOutline" label="User ID"
                                placeholder="john@blackpearl"></v-text-field>
                </v-flex>
                <v-flex px-4 xs12 sm6 md3>
                  <v-text-field v-model="item.password" :prepend-icon="mdiLockOutline" readonly persistent-placeholder 
                                label="Password" :append-icon="show1 ? mdiEyeOutline : mdiEyeOffOutline" outlined
                                :rules="[rules.min]" :type="show1 ? 'text' : 'password'"
                                hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
                </v-flex>
                <v-flex px-4 xs12 sm6 md3>
                  <v-text-field v-model="item.pin" :prepend-icon="mdiShieldLockOutline" persistent-placeholder
                              label="Sign PIN" :append-icon="show2 ? mdiEyeOutline : mdiEyeOffOutline" outlined
                              :rules="pinRules" :type="show2 ? 'text' : 'password'" readonly
                              hint="4 digits/characters" counter @click:append="show2 = !show2"></v-text-field>
                </v-flex>
                <v-flex px-4 xs12 sm6 md3>
                  <v-text-field v-model="item.email" :rules="emailRules" outlined persistent-placeholder readonly
                                :prepend-icon="mdiEmailOutline" label="Email" placeholder="john@doe.com"></v-text-field>
                </v-flex>
                <v-flex px-4 xs12 sm6 md3>
                  <v-text-field v-model="item.phone" outlined persistent-placeholder :prepend-icon="mdiPhoneOutline" readonly
                                label="Phone" placeholder="+3584012345678"></v-text-field>
                </v-flex>
                <v-flex px-4 xs12 sm6 md3>
                  <v-select v-model="item.roles" item-text="name" item-value="id" :items="roles" readonly
                            persistent-placeholder multiple outlined :prepend-icon="mdiAccountTieHatOutline"
                            label="Roles" placeholder="Captain"></v-select>
                </v-flex>
                <v-flex px-4 xs12 sm6 md3>
                  <v-switch v-model="item.active" persistent-placeholder readonly
                            :label="`Active: ${item.active ? 'Yes' : 'No'}`"></v-switch>
                </v-flex>
              </v-layout>
</v-form>
          </v-card>
        </td>
      </template>
      <v-alert slot="no-results" :value="true" color="error" icon="warning">
        Your search for "{{ search }}" found no results.
      </v-alert>
    </v-data-table>
</div>
</template>

<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import moment from 'moment';
  import {
    mdiMagnify,
    mdiCheck,
    mdiClose,
    mdiAccountTieHatOutline,
    mdiAccountOutline,
    mdiLockOutline,
    mdiEyeOutline,
    mdiEyeOffOutline,
    mdiBadgeAccountHorizontalOutline,
    mdiPhoneOutline,
    mdiEmailOutline,
    mdiBadgeAccountOutline,
    mdiPlusCircleOutline,
    mdiShieldLockOutline,
    mdiClockOutline,
  } from '@mdi/js';

  export default {
    name: 'Users',
    components: {

    },
    data: () => ({
      mdiMagnify,
      mdiCheck,
      mdiClose,
      mdiAccountTieHatOutline,
      mdiAccountOutline,
      mdiLockOutline,
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiBadgeAccountHorizontalOutline,
      mdiBadgeAccountOutline,
      mdiPhoneOutline,
      mdiEmailOutline,
      mdiPlusCircleOutline,
      mdiShieldLockOutline,
      mdiClockOutline,
      search: '',
      sortBy: 'name',
      sortDesc: false,
      headers: [{
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Full name',
          value: 'name'
        },
        {
          text: 'User ID',
          value: 'username'
        },
        {
          text: 'Roles',
          value: 'roleNames'
        },
        {
          text: 'Active',
          value: 'active'
        },
        {
          text: 'Last login',
          value: 'loginTime'
        },
        {
          text: 'Author',
          value: 'author'
        },
        {
          text: 'Updated',
          value: 'updated'
        },
      ],
      titles: [
        'Captain',
        'Chief Officer',
        'Deck Officer',
        'Seaman',
        'Chief Engineer',
        'Engineer',
        'Apprentice',
      ],
      roleList: [],
      expanded: [],
      show1: false,
      show2: false,
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => (v ? v.length >= 8 || 'Min 8 characters' : ''),
      },
      nameRules: [
        (v) => !!v || 'Name is required',
      ],
      usernameRules: [
        (v) => !!v || 'User ID is required',
        (v) => /.+@+/.test(v) || 'User ID must be valid',
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      pinRules: [
        (v) => (v ? v.length === 4 || 'PIN must be 4 characters' : ''),
      ],
    }),
    created() {
    },
    computed: {
      ...mapGetters({
        users: 'logbook/getUsers',
        roles: 'logbook/getRoles',
      }),
      userId() {
        return this.getUser().user.id;
      }
    },
    methods: {
      ...mapActions({
        
      }),
      ...mapGetters({
        
      }),
      ...mapMutations({
        
      }),
      expandPanel(item) {
        this.expanded = item === this.expanded[0] ? [] : [item];
      },
      cancel() {
          this.expanded = [];
      },
      formatDate(date) {
        if (date) {
          return moment.utc(date).local().format('D.M.Y HH:mm');
        }
        return '';
      },
    }
  };
</script>

<style>
 
</style>
<style scoped>
 
</style>