<template>
    <div>
        <v-layout>
            <v-flex xs12>
                <v-card>
                    <v-card-title>BOQA settings</v-card-title>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div>
                                    <v-icon medium color="primary">help</v-icon>
                                    <span class="text-body-1 ml-2">What is BOQA?</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="cardbg">
                                <br />
                                <b>BOQA</b> is a methodology (adopted from the Flight industry) with which ship
                                owners/operators,
                                ship Captains, and other associated shipping stakeholders can automatically and
                                systematically
                                monitor, track, trend and analyse operational quality of (seagoing) vessels.
                                <p>
                                    <a href="https://en.wikipedia.org/wiki/BOQA">BOQA - Read more on Wikipedia</a>
                                </p>
                                <p>
You can review and adjust your fleet's BOQA event settings on this page. Settings
                                    can
                                    be defined on a fleet level or individually for each ship. Settings are
                                    customer-specific,
                                    ie all users have the same settings.
</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-divider />

                    <BoqaSettingsAlertGroups />
</v-card>
            </v-flex>
        </v-layout>
        <!-- Navigation event settings -->
        <v-layout v-if="typeof features.eventTypes.Navigation !== 'undefined' && features.eventTypes.Navigation === 1">
            <v-flex xs12 pt-4>
                <v-card>
                    <v-card-title>Navigation events</v-card-title>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div>
                                    <v-icon medium color="primary">help</v-icon>
                                    <span class="text-body-1 ml-2">What are these?</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="cardbg">
                                <br />
                                <p>
Navigation events include the all important ECDIS-route based operational quality
                                    indicators such as Cross Track, Safety Corridor breach and Not on Active route. The system
                                    can also track Speed deviation, Heading deviation and No-Go zones depending on the content of the ECDIS-files.
</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <span v-if="typeof features.events.Xte !== 'undefined' && features.events.Xte === 1">
                        <v-divider />

                        <BoqaSettingsCard :header="'XTE'" :prefix="'Xte'" :headers="xte.headers" :description="xte.description" />

                    </span>
                    <span v-if="typeof features.events.Corridor !== 'undefined' && features.events.Corridor === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'Safety Corridor'" :prefix="'Corridor'" :headers="xte.headers" :description="corridor.description" />
                    </span>
                    <span v-if="typeof features.events.ActiveRoute !== 'undefined' && features.events.ActiveRoute === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'Active Route'" :prefix="'ActiveRoute'" :headers="active.headers" :description="active.description" />
                    </span>
                    <span v-if="typeof features.events.Ngz !== 'undefined' && features.events.Ngz === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'No-Go Zone'" :prefix="'Ngz'" :headers="xte.headers" :description="ngz.description" />
                    </span>
                    <span v-if="typeof features.events.RouteSpeed !== 'undefined' && features.events.RouteSpeed === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'Route speed'" :prefix="'RouteSpeed'" :headers="routeSpeed.headers" :description="routeSpeed.description" />
                    </span>
                    <span v-if="typeof features.events.Hdg !== 'undefined' && features.events.Hdg === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'Heading'" :prefix="'Hdg'" :headers="hdg.headers" :description="hdg.description" />
                    </span>
                    <span v-if="typeof features.events.Stop !== 'undefined' && features.events.Stop === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'Stop'" :prefix="'Stop'" :headers="stop.headers" :description="stop.description" />
                    </span>
                    <span v-if="typeof features.events.Speeding !== 'undefined' && features.events.Speeding === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'Speeding'" :prefix="'Speeding'" :headers="speeding.headers" :description="speeding.description" />
                    </span>
                    <span v-if="typeof features.events.FinalWP !== 'undefined' && features.events.FinalWP === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'FinalWP'" :prefix="'FinalWP'" :headers="finalWP.headers" :description="finalWP.description" />
                    </span>
                </v-card>
            </v-flex>
        </v-layout>
        <!-- AIS event settings -->
        <v-layout v-if="typeof features.eventTypes.AIS !== 'undefined' && features.eventTypes.AIS === 1">
            <v-flex xs12 pt-4>
                <v-card>
                    <v-card-title>AIS events</v-card-title>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div>
                                    <v-icon medium color="primary">help</v-icon>
                                    <span class="text-body-1 ml-2">What are these?</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="cardbg">
                                <br />
                                <p>
AIS events include various types of events which are derived from the ship's
                                    local AIS-receiver data, such
                                    as adherance to CPA/TCPA limits and Proximity as well as AIS-status changes
                                    which are relevant from a
                                    safety perspective.
</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <span v-if="typeof features.events.Cpa !== 'undefined' && features.events.Cpa === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'CPA/TCPA'" :prefix="'Cpa'" :headers="cpa.headers" :description="cpa.description" />
                    </span>
                    <span v-if="typeof features.events.Proximity !== 'undefined' && features.events.Proximity === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'Proximity'" :prefix="'Proximity'" :headers="active.headers" :description="proximity.description" />
                    </span>
                    <span v-if="typeof features.events.Ais !== 'undefined' && features.events.Ais === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'AIS status'" :prefix="'Ais'" :headers="ais.headers" :description="ais.description" />
                    </span>
                </v-card>
            </v-flex>
        </v-layout>
        <!-- Compliance event settings -->
        <v-layout v-if="typeof features.eventTypes.Compliance !== 'undefined' && features.eventTypes.Compliance === 1">
            <v-flex xs12 pt-4>
                <v-card>
                    <v-card-title>Compliance events</v-card-title>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div>
                                    <v-icon medium color="primary">help</v-icon>
                                    <span class="text-body-1 ml-2">What are these?</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="cardbg">
                                <br />
                                <p>
Compliance events keep track of entry and exit in various areas, such as PSSA,
                                    ECA, High Risk Areas (HRA) and Voluntary Risk Areas (VRA)
</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <span v-if="typeof features.events.Area !== 'undefined' && features.events.Area === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'Area entry/exit'" :prefix="'Area'" :headers="area.headers" :description="area.description" />
                    </span>
                </v-card>
            </v-flex>
        </v-layout>
        <!-- Weather event settings -->
        <v-layout v-if="typeof features.eventTypes.Weather !== 'undefined' && features.eventTypes.Weather === 1">
            <v-flex xs12 pt-4>
                <v-card>
                    <v-card-title>Weather events</v-card-title>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div>
                                    <v-icon medium color="primary">help</v-icon>
                                    <span class="text-body-1 ml-2">What are these?</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="cardbg">
                                <br />
                                <p>
Weather events include alerts for High wind in Port and At sea, forecasted
                                    high wind and waves along
                                    the ship's active ECDIS-route, anchoring weather and nowcast high wind and wave at ship's current location
</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <span v-if="typeof features.events.Tws !== 'undefined' && features.events.Tws === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'Wind in port / at sea'" :prefix="'Tws'" :headers="tws.headers" :description="tws.description" />
                    </span>
                    <span v-if="typeof features.events.Forecast !== 'undefined' && features.events.Forecast === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'Route forecast'" :prefix="'Forecast'" :headers="forecast.headers" :description="forecast.description" />
                    </span>
                    <span v-if="typeof features.events.Nowcast !== 'undefined' && features.events.Nowcast === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'Nowcast'" :prefix="'Nowcast'" :headers="nowcast.headers" :description="nowcast.description" />
                    </span>
                    <span v-if="typeof features.events.Anchoring !== 'undefined' && features.events.Anchoring === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'Anchoring weather'" :prefix="'Anchoring'" :headers="anchoring.headers" :description="anchoring.description" />
                    </span>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout v-if="typeof features.eventTypes.Motion !== 'undefined' && features.eventTypes.Motion === 1">
            <v-flex xs12 pt-4>
                <v-card>
                    <v-card-title>Motion events</v-card-title>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div>
                                    <v-icon medium color="primary">help</v-icon>
                                    <span class="text-body-1 ml-2">What are these?</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="cardbg">
                                <br />
                                 <p>
Motion events are based on the sensor values from the motion sensor onboard the
                                        ship and include steady
                                        List, momentary Roll, Un-comfort index, Rate-of-Turn and Sway/transverse
                                        acceleration.
</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                        <!-- List -->
                    <span v-if="typeof features.events.Motion !== 'undefined' && features.events.Motion === 1">
                        <v-divider />
                            <BoqaSettingsCard :header="'List, Roll, Un-comfort, ROT and Sway'" :prefix="'Motion'" :headers="motion.headers"
                                :description="motion.description" />
                    </span>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout v-if="typeof features.eventTypes.VDR !== 'undefined' && features.eventTypes.VDR === 1">
            <v-flex xs12 pt-4>
                <v-card>
                     <v-card-title>VDR events</v-card-title>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div>
                                    <v-icon medium color="primary">help</v-icon>
                                    <span class="text-body-1 ml-2">What are these?</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="cardbg">
                                <br />
                                 <p>
VDR events are triggered by sensor data which is found in all VDRs and include
                                        Under Keel Clearance, Excessive rudder at speed, RPM command and Crash stop
</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                   
                    <span v-if="typeof features.events.Ukc !== 'undefined' && features.events.Ukc === 1">
                        <v-divider />
                            <BoqaSettingsCard :header="'Under Keel Clearance'" :prefix="'Ukc'" :headers="ukc.headers" :description="ukc.description" />
                    </span>
                    <span v-if="typeof features.events.Rudder !== 'undefined' && features.events.Rudder === 1">
                        <v-divider />
                            <BoqaSettingsCard :header="'Excessive Rudder'" :prefix="'Rudder'" :headers="rudder.headers" :description="rudder.description" />
                    </span>
                    <span v-if="typeof features.events.Rpm !== 'undefined' && features.events.Rpm === 1">
                        <v-divider />
                            <BoqaSettingsCard :header="'RPM command'" :prefix="'Rpm'" :headers="rpm.headers" :description="rpm.description" />
                    </span>
                    <span v-if="typeof features.events.CrashStop !== 'undefined' && features.events.CrashStop === 1">
                        <v-divider />
                            <BoqaSettingsCard :header="'Crash stop'" :prefix="'CrashStop'" :headers="crashStop.headers" :description="crashStop.description" />
                    </span>
                </v-card>
            </v-flex>
        </v-layout>
         <!-- Compliance event settings -->
        <v-layout v-if="typeof features.eventTypes.Alarm !== 'undefined' && features.eventTypes.Alarm === 1">
            <v-flex xs12 pt-4>
                <v-card>
                    <v-card-title>Alarm events</v-card-title>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div>
                                    <v-icon medium color="primary">help</v-icon>
                                    <span class="text-body-1 ml-2">What are these?</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="cardbg">
                                <br />
                                <p>Alarm events include for example IoT-data offline status alerts and other events which relate to alarm signals in the ship's system. These events are only applied to vessel which regularly transmit IoT data ashore and are not used for vessels which utilise AIS-data as their location source.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <span v-if="typeof features.events.IotData !== 'undefined' && features.events.IotData === 1">
                        <v-divider />
                        <BoqaSettingsCard :header="'IoT data status'" :prefix="'IotData'" :headers="iotData.headers" :description="iotData.description" />
                    </span>
                </v-card>
            </v-flex>
        </v-layout>

        <v-layout v-if="typeof features.eventTypes.Custom !== 'undefined' && features.eventTypes.Custom === 1">
            <v-flex xs12 pt-4>
                <v-card>
                    <v-card-title>Custom events</v-card-title>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div>
                                    <v-icon medium color="primary">help</v-icon>
                                    <span class="text-body-1 ml-2">What are these?</span>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="cardbg">
                                <br />
                                 <p>
The BOQA solution includes several ready-made case types for detecting various operational quality events. With this tool
                                        you can also create Custom-events based on many of the ship specific signals.
</p>
                                    <p><b>Available parameters</b></p>
                                    <p>
You can create custom events for some of the parameters for each ship. First select a ship, then the parameter you like
                                        to track and the limit value. If you do not see the parameter you like to monitor, please contact support@nauticai.com.
                                    </p>
                                    <p><b>Speed limit</b></p>
                                    <p>
You can define a minimum speed for when the event should be triggered. If you select for example 5 kn, it means that the
                                        event will only be checked when the vessel is moving faster than 5 knots.
</p>
                                    <p><b>Sample limit</b></p>
                                    <p>
You can also define how many data-points/samples we should check before triggering the event. This is useful for
                                        filtering out unnecessary events which may be triggered by spikes in the signal data. If you for example define a sample
                                        limit of 5, it means that the system will check five consecutive data points before creating the event.
</p>
                                    <p><b>Need more complex rules?</b></p>
                                    <p>
If you need more complex trigger rules than what we have here, please contact support@nauticai.com and we will see how
                                        those can be created.
</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-divider />
                    <!-- Custom -->
                    <BoqaSettingsCustomCard :header="'Create new custom event'" :settings="customSetting" :newEvent="true" />
                    <span v-for="settings in customSettings" :key="settings.id">
                        <v-divider />
                        <BoqaSettingsCustomCard
                            :header="settings.name + ' - ' + settings.paramName + ' ' + settings.operator + ' ' + settings.limitValue + ' - ' + settings.author + ' ' + formatTime(settings.updated)"
                            :settings="settings" :newEvent="false" />
                    </span>
                </v-card>
            </v-flex>
        </v-layout>
        <!-- Status dialog -->
        <StatusDialog />
    </div>
</template>

<script>
    import moment from 'moment';
    import {
        mapGetters,
        mapActions
    } from 'vuex';
    import BoqaSettingsAlertGroups from '../components/BoqaSettingsAlertGroups.vue';
    import BoqaSettingsCard from '../components/BoqaSettingsCard.vue';
    import BoqaSettingsCustomCard from '../components/BoqaSettingsCustomCard.vue';
    import StatusDialog from '../components/StatusDialog.vue';

    export default {
        name: 'BoqaSettings',
        components: {
            BoqaSettingsAlertGroups,
            BoqaSettingsCard,
            BoqaSettingsCustomCard,
            StatusDialog
        },
        data() {
            return {
                customParams: [],
                customSetting: {
                    timeLimit: null,
                    groupId: null,
                    shipId: null,
                    enabled: false,
                    speed: null,
                    param: null,
                    operator: null,
                    limitValue: null,
                    severity: null
                },
                xte: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Sample limit',
                            value: 'timelimit'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: `Main principle

The Cross-track breach (XTE) event is triggered if the ship goes outside of the defined track limits (the two blue lines on both sides of the route line) of an active ECDIS-route AND the ship's speed is >2kn. The event is classed as a yellow notification event.

Additional parameters

You can adjust the sensitivity of this event by selecting a suitable amount of successive data samples to check before we trigger an event. If your ship reports data every 5 minutes and you select Sample limit as "2", then the XTE event is triggered after approximately 10 minutes of being outside the ECDIS track limits.

Typical default values

Typically you would set the Sample limit to 2-10 (depending on the frequency of your data).`,
                },
                active: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Sample limit',
                            value: 'timelimit'
                        },
                        {
                            text: 'Distance',
                            value: 'distance'
                        },
                        {
                            text: 'Speed',
                            value: 'speed'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: `Main principle

The Active route event is triggered if the ship goes too far of the defined center track of an active ECDIS-route. The event is classed as a yellow notification event.

Additional parameters

You can adjust the sensitivity of this event by selecting a suitable amount of successive data samples, maximum distance to an active route and minimum ship speed to check before we trigger an event.

Typical default values

Typically you would set the Sample limit to 2-10 (depending on the frequency of your data), the distance to 2NM and speed to 5kn.`,
                },
                routeSpeed: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Sample limit',
                            value: 'timelimit'
                        },
                        {
                            text: 'Speed drop',
                            value: 'speedDrop'
                        },
                        {
                            text: 'Speeding',
                            value: 'speeding'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: 'This event is triggered if the ship is moving slower or faster than anticipated compared to the ECDIS-route leg-speed setting longer time than alarm limit. Note! Only some ECDIS-files have this data.',
                },
                hdg: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Sample limit',
                            value: 'timelimit'
                        },
                        {
                            text: 'Degrees',
                            value: 'degrees'
                        },
                        {
                            text: 'Speed',
                            value: 'speed'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: `Main principle

The Heading deviation event is triggered if the ship's heading (HDG/HDT = the way its bow points) deviates from the active ECDIS-route leg. The event is used for detecting unnecessary zick zack behavior. The event is classed as a yellow notification event.

Additional parameters

You can adjust the sensitivity of this event by selecting a suitable amount of successive data samples, maximum deviation angle in degrees and minimum ship speed to check before we trigger an event.

Typical default values

Typically you would set the Sample limit to 2-10 (depending on the frequency of your data), the degrees to 30° and speed to 5kn.`,
                },
                stop: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Sample limit',
                            value: 'timelimit'
                        },
                        {
                            text: 'Port distance',
                            value: 'portDistance'
                        },
                        {
                            text: 'Speed',
                            value: 'speed'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: `
Main principle\n
The Stop event is triggered if the ship's speed drops below the limit speed. This event has three sub-types
* Port stop, when the ship stops in port (green routine event)
* Sea stop, when the ship stops at Sea (yellow notification event)
* Start moving again when the ship is on the move again (green routine event)\n
Additional parameters\n
You can adjust the sensitivity of this event by selecting a suitable amount of successive data samples to check before we trigger an event. If your ship reports data every 5 minutes and you select Sample limit as "2", then the Stop event is triggered after approximately 10 minutes of standing still. Start moving again works with the same principle, but vice versa.\n
You can also adjust the distance to a port to control if a Stop-event is a Port stop or a Sea stop. If you have defined Port distance as "1.5NM" and the ship stops in a place which is 1.3NM from the nearest port (as defined in our Port-database of ~11000 ports worldwide), then the event is a Port stop. If the ship stops 1.6NM from any port, then the event is a Sea stop.\n
Typical default values\n
Typically you would set the speed limit to 2-3kn, the Sample limit to 2-10 (depending on the frequency of your data) and the port distance to 1.0NM.`,
                },
                speeding: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Sample limit',
                            value: 'timelimit'
                        },
                        {
                            text: 'Speed',
                            value: 'speed'
                        },
                        {
                            text: 'Timeout',
                            value: 'timeout'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: 'The Speeding event is triggered if the ship travels at a speed greater than the speed limit.',
                },
                finalWP: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Distance',
                            value: 'distance'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: 'This event is triggered if the ship has reached final WP on route.',
                },
                corridor: {
                    description: `Main principle

The Safety-corridor breach event is triggered if the ship goes outside of the defined safety corridor (the green area on both sides of the route line) of an active ECDIS-route AND the ship's speed is >2kn. The event is classed as a red critical event.

Additional parameters

You can adjust the sensitivity of this event by selecting a suitable amount of successive data samples to check before we trigger an event. If your ship reports data every 5 minutes and you select Sample limit as "2", then the Safety-corridor breach event is triggered after approximately 10 minutes of being outside the ECDIS safety corridor.

Typical default values

Typically you would set the Sample limit to 2-10 (depending on the frequency of your data).

Note!

Note! Only some ECDIS-systems support the safety-corridor parameter. We set the safety corridor automatically to 1NM in case the parameter is missing from any route leg.`,
                },
                ngz: {
                    description: 'The No-Go Zone (NGZ) event is triggered if the ship enters a Restricted or No-Go area as defined in the system area database.',
                },
                cpa: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'CPA/TCPA',
                            value: 'tcpa'
                        },
                        {
                            text: 'Distance',
                            value: 'distance'
                        },
                        {
                            text: 'Speed',
                            value: 'speed'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: 'The CPA event is triggered if the CPA/TCPA of another ship (AIS A-class only and excluding boats with AIS-type 50=Pilot boat) is less than the alarm limit. This event includes an escalation mechanism, which means that if the first CPA event is detected at 1.5nm/15min and the target ship subsequently comes even closer (1.0nm/10min or 0.5/5min), then another event will be triggered.',
                },
                proximity: {
                    description: `The Proximity/Close encounter event is triggered if another AIS-target comes closer than the distance limit and stays there for a longer time than the time limit while the ship's speed is over the speed limit.\n
Pilot/Tender subcategory: In case the target ship is a pilot boat or a tender (according to its AIS ship type), then this event is categorised as a Pilot/Tender event.`,
                },
                ais: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Send alerts for',
                            value: 'alertType'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: `The N.U.C. (Not Under Command) AIS status event is triggered if the ship sets its AIS-status to Not Under Command (ais-status=2).\n
The Restricted Manoeuvrability event is triggered if the ship sets its AIS-status to Rest.Man. (ais-status=3).\n
The Rest.Draught event is triggered if the ship sets its AIS-status to Constrained by her draught (ais-status=4).\n
The Aground event is triggered if the ship sets its AIS-status to Aground (ais-status=6).\n
Send alerts for -setting can be used to select which types of events will be sent to selected instant alert group`,
                },
                area: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: 'The Area event is triggered if the ship enters or exits a PSSA, ECA, HRA or VRA area.',
                },
                tws: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Port medium',
                            value: 'portMedium'
                        },
                        {
                            text: 'Port high',
                            value: 'portHigh'
                        },
                        {
                            text: 'Sea medium',
                            value: 'seaMedium'
                        },
                        {
                            text: 'Sea high',
                            value: 'seaHigh'
                        },
                        {
                            text: 'Apparent wind',
                            value: 'aws'
                        },
                        {
                            text: 'Apparent SOG limit',
                            value: 'seaSpeed'
                        },
                        {
                            text: 'Timeout',
                            value: 'timeout'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: `The Wind in port / at sea (TWS port / sea) event is triggered if the ship wind meter (if connected) shows high true wind speed (TWS) when the ship is in port / at sea. The timeout value defines how long we will wait before triggering a new event after an event has already been triggered.\n
Apparent Wind subcategory: This event type includes a separate limit value for apparent wind speed. The apparent wind is checked both in port and at sea.`,
                },
                forecast: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Wind medium',
                            value: 'windMedium'
                        },
                        {
                            text: 'Wind high',
                            value: 'portHigh'
                        },
                        {
                            text: 'Wave medium',
                            value: 'waveMedium'
                        },
                        {
                            text: 'Wave high',
                            value: 'waveHigh'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: 'The Route forecast event is triggered if the weather forecast shows high winds/waves along the ship\'s active ECDIS-route. The forecast is checked twice per day at 0700 and 1900 UTC. The system assumes that the ship travels at a steady normal speed when calculating the location and time for each forecast point.',
                },
                nowcast: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Speed',
                            value: 'speed'
                        },
                        {
                            text: 'Timeout',
                            value: 'timeout'
                        },
                        {
                            text: 'Wind',
                            value: 'wind'
                        },
                        {
                            text: 'Wave',
                            value: 'wave'
                        },
                       
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: 'The Nowcast event is triggered if the weather conditions at the ship\'s location exceed a limit value and ship\'s speed is over limit. Event is checked every 30 minutes. Use the timeout value to limit new event triggering.',
                },
                anchoring: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Timeout',
                            value: 'timeout'
                        },
                        {
                            text: 'Wind (sheltered)',
                            value: 'windSheltered'
                        },
                        {
                            text: 'Current (sheltered)',
                            value: 'currentSheltered'
                        },
                        {
                            text: 'Wind (open water)',
                            value: 'windOpen'
                        },
                        {
                            text: 'Current (open water)',
                            value: 'currentOpen'
                        },
                        {
                            text: 'Wave (open water)',
                            value: 'waveOpen'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: `The Anchoring weather event is triggered if the ship's AIS status is set to At anchor and the forecasted weather conditions exceed the defined limits. The ship is seen to be in sheltered waters if it has an active ECDIS route and the distance to the last waypoint in less than 4NM. Otherwise the ship is in Open water. Timeout can be used to prevent re-triggering`,
                },
                motion: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Speed',
                            value: 'speed'
                        },
                        {
                            text: 'List medium',
                            value: 'listMedium'
                        },
                        {
                            text: 'List high',
                            value: 'listHigh'
                        },
                        {
                            text: 'Roll medium',
                            value: 'rollMedium'
                        },
                        {
                            text: 'Roll high',
                            value: 'rollHigh'
                        },
                        {
                            text: 'Un-comfort',
                            value: 'uIndex'
                        },
                        {
                            text: 'ROT low',
                            value: 'rotLow'
                        },
                        {
                            text: 'ROT medium',
                            value: 'rotMedium'
                        },
                        {
                            text: 'ROT high',
                            value: 'rotHigh'
                        },
                        {
                            text: 'Sway medium',
                            value: 'swayMedium'
                        },
                        {
                            text: 'Sway High',
                            value: 'swayHigh'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: `The motion events work as follows.\n
- The List event is triggered if the ship's steady list exceeds the limit value in a 5-minute sampling period and the ship's speed is more than the speed limit. The unit for list is ° (degrees) and a normal list is usually below 4°, while a high list is over 10°.\n
- The Roll event is triggered if the ship's momentary Roll exceeds the limit value in a 5-minute sampling period and the ship's speed is more than the speed limit. The unit for roll is ° (degrees). Roll is usually acceptable if below 5° (medium limit) and on passenger ships roll exceeding 7° is typically considered as high (high limit).\n
- The Un-comfort event is triggered if the un-comfort index (=standard deviation of roll and pitch motion) exceeds the alarm limit during a 5-minute sampling period and the ship's speed is higher than the speed limit. The un-comfort index is unitless. Values above 2 are fairly rare.\n
- The ROT event is triggered if the ship's Rate-Of-Turn (in the AIS-signal) exceeds the alarm limit. This event has three different limit settings for speed less than 10kn, speed 10-15kn and speed over 15kn. The unit for ROT is °/min. Typical ROT limit values are very ship specific.\n
- The Sway event is triggered if the transverse acceleration at the unit exceeds the alarm limit in a 5-minute sampling period. The unit for sway is m/s2. Typically the sway is acceptable if it is below 2m/s2 (or 0.2 G). On cargo RoRo ships sway starts to be critical at 1G (ie 9.81 m/s2) as it starts to be a problem for lashings.`,
                },
                ukc: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Sample limit',
                            value: 'timelimit'
                        },
                        {
                            text: 'UKC',
                            value: 'ukc'
                        },
                        {
                            text: 'Speed',
                            value: 'speed'
                        },
                        {
                            text: 'Timeout',
                            value: 'timeout'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: 'The UKC event is triggered if the ship travels at a speed greater than the speed limit and the Under Keel Clearance, which is based on the ship\'s echo sounder, shows values which are less than the UKC limit. The sample-limit and timeout-limit can be used to restrict events from being triggered too often in case the ship continues to sail in shallow waters.',
                },
                rudder: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Rudder angle',
                            value: 'angle'
                        },
                        {
                            text: 'Speed',
                            value: 'speed'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: 'The Excessive rudder angle event is triggered if the ship applies a rudder angle greater than the limit and the speed is over the threshold.',
                },
                rpm: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Max RPM',
                            value: 'rpm'
                        },
                        {
                            text: 'Speed',
                            value: 'speed'
                        },
                        {
                            text: 'Timeout',
                            value: 'timeout'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: 'The RPM command event is triggered if the ship sets and RPM value greater than the limit and the speed is over the threshold. The timeout-limit can be used to restrict events from being triggered too often in case the ship continues to sail with high RPM command.',
                },
                crashStop: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Negative RPM',
                            value: 'rpm'
                        },
                        {
                            text: 'Speed',
                            value: 'speed'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: 'The Crash stop event is triggered if the ship sets a negative RPM while moving faster than the limit speed.',
                },
                iotData: {
                    headers: [{
                            text: 'Ship',
                            align: 'left',
                            value: 'name'
                        },
                        {
                            text: 'Time limit (minutes)',
                            value: 'timelimit'
                        },
                        {
                            text: 'Timeout',
                            value: 'timeout'
                        },
                        {
                            text: 'Group',
                            value: 'group'
                        },
                        {
                            text: 'Enabled',
                            value: 'enabled'
                        }
                    ],
                    description: 'The IoT data status event is triggered if we have not received new IoT data from the vessel within the offline time limit. The timeout limit can be used to restrict events from being re-triggered too often if the vessel\'s IoT data stays offline for longer periods of time.',
                },
            };
        },
        mounted() {
            this.fetchSettings('Custom');
            this.fetchCustomParameters();
        },
        computed: {
            ...mapGetters({
                features: 'authentication/features',
                customSettings: 'data/getCustomSettings',
            }),

        },
        methods: {
            ...mapActions({
                fetchSettings: 'data/fetchSettings',
                fetchCustomParameters: 'data/fetchCustomParameters',
            }),
            env(variableName) {
                return process.env[variableName];
            },
            formatTime(value) {
                if (value) {
                    return `${moment.utc(value).format('D.M HH:mm')} UTC`;
                }
            },
        }
    };
</script>

<style scoped>
    .sectionheader {
        padding-bottom: 10px;
    }

    .shipheader {
        padding: 10px 0px;
    }

    .headertext {
        margin: 16px;
    }
</style>
