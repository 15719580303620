import axios from 'axios';
import moment from 'moment';
import { authHeader, handleResponse, tools } from '../helpers';

export default class GridDataService {
    constructor() {
        this.requestOptions = {
            headers: authHeader()
        };
        this.BASE_URL = tools.getPerfUrl();
        // this.BASE_URL = 'http://localhost:3000';
    }

    get(state) {
        const pageQuery = `/${state.skip}/${state.take}`;
        let sortQuery = '/etd desc';
        if ((state.sorted || []).length) {
            sortQuery = `/${state.sorted.map((obj) => {
                obj.name = obj.name === 'statusText' ? 'status' : obj.name;
                return obj.direction === 'descending' ? `${obj.name} desc` : obj.name;
            }).reverse().join(',')}`;
        }
        let whereQuery = '/0';
        if (state && state.where && state.where.length > 0) {
            whereQuery = `/${state.where[0].predicates.map((obj) => {
                obj.field = obj.field === 'statusText' ? 'status' : obj.field;
                obj.name = `v.${obj.field}='${obj.value}'`;
                return obj.name;
            }).join(' AND ')}`;
        }
        this.url = `${this.BASE_URL}/voyages${pageQuery}${sortQuery}${whereQuery}`;
        return axios.get(this.url, this.requestOptions)
            .then(handleResponse)
            .then((data) => (
                { result: this.parseData(data.result), count: data.count }
            )).catch((e) => {
                console.error(e);
                return [];
            });
    }

    edit(state) {
        // console.log('edit', state)
        let sortQuery = 'etd desc';
        if ((state.sorted || []).length) {
            sortQuery = state.sorted.map((obj) => (obj.direction === 'descending' ? `${obj.name} desc` : obj.name)).reverse().join(',');
        }
        
        // Compare edited values
        const values = {};
        for (const key in state.data) {
            if (state.data.hasOwnProperty(key)) {
                for (const key2 in state.rowData) {
                    if (state.rowData.hasOwnProperty(key2) && key === key2) {
                        if ((key === 'atd' || key === 'etd' || key === 'ata' || key === 'eta') && state.data[key] !== state.rowData[key2] && moment.utc(state.data[key]).unix() !== moment.utc(state.rowData[key2]).unix()) {
                            values[key] = [];
                            values[key][0] = state.rowData[key2] ? moment.utc(state.rowData[key2]).format('YYYY-MM-DDTHH:mm:ss') : null;
                            values[key][1] = state.data[key] ? moment.utc(state.data[key]).format('YYYY-MM-DDTHH:mm:ss') : null;
                            console.log(`${key} modified: ${values[key][0]} -> ${values[key][1]}`);
                        // } else if (key !== 'editTime' && key !== 'atd' && key !== 'etd' && key !== 'ata' && key !== 'eta' && (key === 'name' || key === 'depLocode' || key === 'arrLocode' || key === 'distance' || key === 'avgTrueWind' || key === 'avgHeadWind' || key === 'focMeSea' || key === 'focMePort' || key === 'focAuxSea' || key === 'focAuxPort' || key === 'focBoilerSea' || key === 'focBoilerPort' || key === 'propulsionPower' || key === 'route' || key === 'pax' || key === 'carUnits' || key === 'carMeters' || key === 'cargoTons' || key === 'cargoUnits' || key === 'cargoMeters') && state.data[key] !== null && !Object.is(state.data[key], NaN) && state.data[key] !== state.rowData[key2]) {
                        } else if (key !== 'editTime' && key !== 'atd' && key !== 'etd' && key !== 'ata' && key !== 'eta' 
                            && (key === 'name' || key === 'depLocode' || key === 'arrLocode' || key === 'distance' || key === 'avgTrueWind' || key === 'avgHeadWind'
                             || key === 'focMeSea' || key === 'focMePort' || key === 'focAuxSea' || key === 'focAuxPort' || key === 'focBoilerSea' || key === 'focBoilerPort' 
                             || key === 'co2MeSea' || key === 'co2MePort' || key === 'co2AuxSea' || key === 'co2AuxPort' || key === 'co2BoilerSea' || key === 'co2BoilerPort' 
                             || key === 'propulsionPower' || key === 'route' || key === 'pax' || key === 'carUnits' || key === 'carMeters' || key === 'cargoTons' || key === 'cargoUnits' 
                             || key === 'cargoMeters' || key === 'depCode' || key === 'anchorHours' || key === 'timeInIce' || key === 'distanceInIce' || key === 'notes' || 
                             key.includes('fuelType')) && !Object.is(state.data[key], NaN) && state.data[key] !== state.rowData[key2]) {
                            values[key] = [];
                            values[key][0] = state.rowData[key2];
                            values[key][1] = state.data[key];
                            console.log(`${key} modified: ${values[key][0]} -> ${values[key][1]}`);
                        }
                    }
                }
            }
        }
        if (Object.keys(values).length === 0) {
            console.log('No changes. Cancel edit.');
            state.cancelEdit();
            return Promise.resolve(-1);
        }
        const requestData = { queryParams: { skip: state.state.skip, take: state.state.take, sort: sortQuery }, values, id: state.primaryKeyValue[0] };
        return axios.post(`${this.BASE_URL}/voyages`, requestData, this.requestOptions)
            .then(handleResponse)
            .then(() => {
                state.endEdit();
                return 1;
            }).catch((e) => {
                console.error(e);
                state.endEdit();
                return 0;
            });
    }

    add(state) {
        // Compare edited values
        const values = {};
        for (const key in state.data) {
            if (state.data.hasOwnProperty(key)) {
                if (key === 'name' || key === 'depLocode' || key === 'arrLocode' || key === 'distance' || key === 'avgTrueWind' || key === 'avgHeadWind' 
                    || key === 'focMeSea' || key === 'focMePort' || key === 'focAuxSea' || key === 'focAuxPort' || key === 'focBoilerSea' || key === 'focBoilerPort' 
                    || key === 'propulsionPower' 
                    || key === 'co2MeSea' || key === 'co2MePort' || key === 'co2AuxSea' || key === 'co2AuxPort' || key === 'co2BoilerSea' || key === 'co2BoilerPort' 
                    || key === 'pax' || key === 'carUnits' || key === 'carMeters' || key === 'cargoTons' || key === 'cargoUnits' || key === 'cargoMeters') {
                    if (state.data[key] !== null && state.data[key] !== '') {
                        values[key] = state.data[key];
                    }
                } else if (key === 'etd' || key === 'atd' || key === 'eta' || key === 'ata') {
                    if (state.data[key] !== null && state.data[key] !== '') {
                        values[key] = moment.utc(state.data[key]).format('YYYY-MM-DDTHH:mm:ss');
                    }
                } else if (key === 'route' && state.data['depLocode'] && state.data['arrLocode']) {
                    values[key] = state.data['depLocode'] + '-' + state.data['arrLocode'];
                }
            }
        }
        if (typeof values.name === 'undefined' || typeof values.etd === 'undefined' || typeof values.depLocode === 'undefined' || typeof values.arrLocode === 'undefined') {
            state.endEdit();
            return Promise.resolve(-1);
        }
        const requestData = { values };
        return axios.put(`${this.BASE_URL}/voyages`, requestData, this.requestOptions)
            .then(handleResponse)
            .then(() => {
                state.endEdit();
                return 1;
            }).catch((e) => {
                console.error(e);
                state.endEdit();
                return 0;
            });
    }

    delete(state) {
        const deleteId = state.data && state.data[0] && state.data[0].id && !isNaN(state.data[0].id) ? state.data[0].id : null;
        if (deleteId) {
            return axios.delete(`${this.BASE_URL}/voyages/${deleteId}`, this.requestOptions)
                .then(handleResponse)
                .then(() => {
                    state.endEdit();
                    return 1;
                }).catch((e) => {
                    console.error(e);
                    state.endEdit();
                    return 0;
                });
        } 
            return Promise.resolve(-1);
    }

    update(data) {
        return axios.post(`${this.BASE_URL}/voyages`, data, this.requestOptions)
            .then(handleResponse)
            .then(() => 1).catch((e) => {
                console.error(e);
                return 0;
            });
    }

    getParam(param) {
        return axios.get(`${this.BASE_URL}/${param}`, this.requestOptions)
            .then(handleResponse)
            .then((data) => data).catch((e) => {
                console.error(e);
                return 0;
            });
    }

    parseData(data) {
        if (data && data.length > 0) {
            for (let i = 0, il = data.length; i < il; i++) {
                if (data[i].etd) {
                    // data[i].year = moment.utc(data[i].etd).format('Y');
                    // data[i].month = moment.utc(data[i].etd).format('M');
                    // data[i].day = moment.utc(data[i].etd).format('D');
                    // data[i].time = moment.utc(data[i].etd).format('HH:mm');
                    data[i].etd = moment.utc(data[i].etd).format();
                    data[i].etdTime = moment.utc(data[i].etd).local().format('HH:mm');
                }
                if (data[i].atd) {
                    data[i].atd = moment.utc(data[i].atd).format();
                }
                if (data[i].eta) {
                    data[i].eta = moment.utc(data[i].eta).format();
                }
                if (data[i].ata) {
                    data[i].ata = moment.utc(data[i].ata).format();
                }
                if (data[i].editTime) {
                    data[i].editTime = moment.utc(data[i].editTime).format();
                }
                if (data[i].atd && data[i].etd) {
                    data[i].depDelay = tools.secsDelayToHoursMins(moment.utc(data[i].atd).unix() - moment.utc(data[i].etd).unix());
                }
                if (data[i].ata && data[i].eta) {
                    data[i].arrDelay = tools.secsDelayToHoursMins(moment.utc(data[i].ata).unix() - moment.utc(data[i].eta).unix());
                }
                if (data[i].atd && data[i].ata) {
                    data[i].atSea = tools.secsToHoursMins(moment.utc(data[i].ata).unix() - moment.utc(data[i].atd).unix());
                }
                if (data[i].atd || data[i].etd) {
                    if (data[i].inPort) { // in port time is available
                        data[i].inPort = tools.secsToHoursMins(data[i].inPort);
                    } else {
                        // Find previous arrival to same port
                        let depTime = data[i].atd ? data[i].atd : data[i].etd;
                        for (let j = i + 1, jl = data.length; j < jl; j++) { // sorted desc so start from i+1 index
                            if (data[j].arrLocode === data[i].depLocode && data[j].shipId === data[i].shipId) { // Same port and ship previous voyage
                                let arrTime = data[j].ata ? data[j].ata : data[j].eta;
                                data[i].inPort = tools.secsToHoursMins(moment.utc(depTime).unix() - moment.utc(arrTime).unix());
                                break;
                            }
                        }
                    }
                }
                if (typeof data[i].cargoTons !== 'undefined' && data[i].cargoTons !== null) {
                    data[i].cargoTons = Math.round(data[i].cargoTons * 100) / 100;
                }
                if (typeof data[i].cargoMeters !== 'undefined' && data[i].cargoMeters !== null) {
                    data[i].cargoMeters = Math.round(data[i].cargoMeters * 100) / 100;
                }
                if (typeof data[i].carMeters !== 'undefined' && data[i].carMeters !== null) {
                    data[i].carMeters = Math.round(data[i].carMeters * 100) / 100;
                }
                if (typeof data[i].carArea !== 'undefined' && data[i].carArea !== null) {
                    data[i].carArea = Math.round(data[i].carArea * 100) / 100;
                } 
                if (typeof data[i].cargoArea !== 'undefined' && data[i].cargoArea !== null) {
                    data[i].cargoArea = Math.round(data[i].cargoArea * 100) / 100;
                }
                if (typeof data[i].carTons !== 'undefined' && data[i].carTons !== null) {
                    data[i].carTons = Math.round(data[i].carTons * 100) / 100;
                }
                if (typeof data[i].paxAreaShare !== 'undefined' && data[i].paxAreaShare !== null) {
                    data[i].paxAreaShare = Math.round(data[i].paxAreaShare * 100) / 100;
                }
                if (typeof data[i].cargoAreaShare !== 'undefined' && data[i].cargoAreaShare !== null) {
                    data[i].cargoAreaShare = Math.round(data[i].cargoAreaShare * 100) / 100;
                }
                if (typeof data[i].paxMassShare !== 'undefined' && data[i].paxMassShare !== null) {
                    data[i].paxMassShare = Math.round(data[i].paxMassShare * 100) / 100;
                }
                if (typeof data[i].cargoMassShare !== 'undefined' && data[i].cargoMassShare !== null) {
                    data[i].cargoMassShare = Math.round(data[i].cargoMassShare * 100) / 100;
                }
                if (typeof data[i].avgTrueWind !== 'undefined' && data[i].avgTrueWind !== null) {
                    data[i].avgTrueWind = Math.round(data[i].avgTrueWind * 100) / 100;
                }
                if (typeof data[i].avgHeadWind !== 'undefined' && data[i].avgHeadWind !== null) {
                    data[i].avgHeadWind = Math.round(data[i].avgHeadWind * 100) / 100;
                }
                if (data[i].focMeSea !== null || data[i].focAuxSea !== null || data[i].focBoilerSea !== null) {
                    data[i].focSea = data[i].focMeSea + data[i].focAuxSea + data[i].focBoilerSea;
                }
                if (data[i].focMePort !== null || data[i].focAuxPort !== null || data[i].focBoilerPort !== null) {
                    data[i].focPort = data[i].focMePort + data[i].focAuxPort + data[i].focBoilerPort;
                }
                // if (data[i].co2MeSea !== null || data[i].co2AuxSea !== null || data[i].co2BoilerSea !== null) {
                //     data[i].co2Sea = data[i].co2MeSea + data[i].co2AuxSea + data[i].co2BoilerSea;
                // }
                // if (data[i].co2MePort !== null || data[i].co2AuxPort !== null || data[i].co2BoilerPort !== null) {
                //     data[i].co2Port = data[i].co2MePort + data[i].co2AuxPort + data[i].co2BoilerPort;
                // }
                data[i].co2Sea = (data[i].focMeSea || 0) * this.getCO2Factor(data[i].fuelTypeMeSea) + (data[i].focAuxSea || 0) * this.getCO2Factor(data[i].fuelTypeAuxSea) + (data[i].focBoilerSea || 0) * this.getCO2Factor(data[i].fuelTypeBoilerSea);
                data[i].co2Port = (data[i].focMePort || 0) * this.getCO2Factor(data[i].fuelTypeMePort) + (data[i].focAuxPort || 0) * this.getCO2Factor(data[i].fuelTypeAuxPort) + (data[i].focBoilerPort || 0) * this.getCO2Factor(data[i].fuelTypeBoilerPort);

                if (data[i].focSea && data[i].distance) {
                    data[i].cons = Math.round((data[i].focSea * 1000 / data[i].distance) * 100) / 100;
                }
                if (data[i].focSea && data[i].distance && data[i].pax) {
                    data[i].eeoiPax = Math.round((data[i].focSea * 3.1 * 1000000 / (data[i].pax * data[i].distance)) * 100) / 100;
                }
                if (data[i].focSea && data[i].distance && data[i].cargoTons) {
                    data[i].eeoiTons = Math.round((data[i].focSea * 3.1 * 1000000 / (data[i].cargoTons * data[i].distance)) * 100) / 100;
                }
                if (data[i].focSea && data[i].distance && data[i].gt) {
                    data[i].eeoiGt = Math.round((data[i].focSea * 3.1 * 1000000 / (data[i].gt * data[i].distance)) * 100) / 100;
                }
                if (data[i].co2Sea && data[i].distance && data[i].dwt) { 
                    data[i].aer = Math.round((data[i].co2Sea * 1000000 / (data[i].dwt * data[i].distance)) * 100) / 100;
                }
                if (data[i].cargoTons && data[i].distance) { 
                    data[i].transportWorkCargo = Math.round(data[i].cargoTons / data[i].distance * 100) / 100;
                }
                
                if (data[i].distance) { 
                    let carTons = data[i].carTons ? data[i].carTons : 0;
                    let cargoTons = data[i].cargoTons ? data[i].cargoTons : 0;
                    let pax = data[i].pax ? data[i].pax : 0;
                    data[i].transportWorkPax = Math.round((carTons + pax * 0.1) / data[i].distance * 100) / 100;
                    data[i].transportWorkTotal = Math.round((carTons + cargoTons + pax * 0.1) / data[i].distance * 100) / 100;
                    data[i].distance = Math.round(data[i].distance * 100) / 100;
                }

                if (data[i].focSea) {
                    data[i].focSea = Math.round(data[i].focSea * 100) / 100;
                }
                if (data[i].focPort) {
                    data[i].focPort = Math.round(data[i].focPort * 100) / 100;
                }
                if (data[i].focMeSea) {
                    data[i].focMeSea = Math.round(data[i].focMeSea * 100) / 100;
                }
                if (data[i].focMePort) {
                    data[i].focMePort = Math.round(data[i].focMePort * 100) / 100;
                }
                if (data[i].focAuxSea) {
                    data[i].focAuxSea = Math.round(data[i].focAuxSea * 100) / 100;
                }
                if (data[i].focAuxPort) {
                    data[i].focAuxPort = Math.round(data[i].focAuxPort * 100) / 100;
                }
                if (data[i].focBoilerSea) {
                    data[i].focBoilerSea = Math.round(data[i].focBoilerSea * 100) / 100;
                }
                if (data[i].focBoilerPort) {
                    data[i].focBoilerPort = Math.round(data[i].focBoilerPort * 100) / 100;
                }
                if (data[i].co2Sea) {
                    data[i].co2Sea = Math.round(data[i].co2Sea * 100) / 100;
                }
                if (data[i].co2Port) {
                    data[i].co2Port = Math.round(data[i].co2Port * 100) / 100;
                }
                if (data[i].co2MeSea) {
                    data[i].co2MeSea = Math.round(data[i].co2MeSea * 100) / 100;
                }
                if (data[i].co2MePort) {
                    data[i].co2MePort = Math.round(data[i].co2MePort * 100) / 100;
                }
                if (data[i].co2AuxSea) {
                    data[i].co2AuxSea = Math.round(data[i].co2AuxSea * 100) / 100;
                }
                if (data[i].co2AuxPort) {
                    data[i].co2AuxPort = Math.round(data[i].co2AuxPort * 100) / 100;
                }
                if (data[i].co2BoilerSea) {
                    data[i].co2BoilerSea = Math.round(data[i].co2BoilerSea * 100) / 100;
                }
                if (data[i].co2BoilerPort) {
                    data[i].co2BoilerPort = Math.round(data[i].co2BoilerPort * 100) / 100;
                }
                if (data[i].propulsionPower) {
                    data[i].propulsionPower = Math.round(data[i].propulsionPower * 100) / 100;
                }
                if (data[i].anchorHours) {
                    data[i].anchorHours = Math.round(data[i].anchorHours * 10) / 10;
                }
                if (data[i].timeInIce) {
                    data[i].timeInIce = Math.round(data[i].timeInIce * 10) / 10;
                }
                if (data[i].distanceInIce) {
                    data[i].distanceInIce = Math.round(data[i].distanceInIce * 10) / 10;
                }
                // Status
                data[i].statusText = this.getStatusText(data[i].status);
            }
        }
        return data;
    }

    getCO2Factor(fuelType) {
        // HFO (CO2 factor 3.114)
        // LFO (CO2 factor 3.15104)
        // MGO (CO2 factor 3.206)
        if (fuelType === 'HFO') {
            return 3.114;
        } else if (fuelType === 'LFO') {
            return 3.15104;
        } else if (fuelType === 'MGO') {
            return 3.206;
        } else {
            return 0;
        }
    }
    
    getStatusText(status) {
        switch (status) {
            case -1:
                return 'Deleted';
            case 0:
                return 'Not completed ';
            case 1:
                return 'Check';
            case 2:
                return 'Auto OK';
            case 3:
                return 'Editing';
            case 4:
                return 'Verified OK';
            case 5:
                return 'Webfocus error';
            default:
                return 'Check';
        }
    }
}
