import axios from 'axios';
import { authHeader, config, handleResponse } from '../helpers';

// Add a 401 response interceptor
axios.interceptors.response.use((response) => response, (error) => {
    if (error && error.response && error.response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('user');
        location.reload(true);  
    }
    return Promise.reject(error);
});

function login(email, password) {
    return axios.post(`${config.apiUrl}/login`, { email, password })
        .then(handleResponse)
        .then((data) => {
            // login successful if there's a jwt token in the response
            if (data.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(data));
            }
            return data;
        }).catch((e) => {
            console.error(e.message);
            return [];
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function get(path) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${config.apiUrl}/${path}`, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => {
            console.error(e.message);
            return [];
        });
}

function getById(path, id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${config.apiUrl}/${path}/${id}`, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => {
            console.error(e.message);
            return [];
        });
}

function post(path, data) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.post(`${config.apiUrl}/${path}`, data, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => {
            console.error(e.message);
            return [];
        });
}

function put(path, data) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.put(`${config.apiUrl}/${path}`, data, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => {
            console.error(e.message);
            return [];
        });
}

function deleteById(path, id) {
    const requestOptions = {
        headers: authHeader()
    };
    return axios.delete(`${config.apiUrl}/${path}/${id}`, requestOptions)
        .then(handleResponse)
        .then((data) => data).catch((e) => {
            console.error(e.message);
            return [];
        });
}

export const userService = {
    login,
    logout,
    get,
    getById,
    post,
    put,
    deleteById
};
