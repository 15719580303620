<template>
  <div style="position:relative">
    <div>
      <v-toolbar fixed flat>
         <v-select style="width:200px;margin-top:40px" dense v-model="ship" outlined :items="ships" item-key="id" item-text="[name]" return-object label="Select ship" />
        <v-text-field class="ml-3" v-model="search" dense outlined :clearable="true" persistent-placeholder
          @change="switchToSearchTab()" :append-icon="mdiMagnify" label="Filter records"
          style="width:200px;margin-top:40px" />
          <v-spacer></v-spacer>
        <div class="ml-3" style="display:inline-block;width:170px;margin-top:40px">
          <v-menu ref="sdmenu" v-model="startDateMenu" :close-on-content-click="true" :return-value.sync="startDate"
                  transition="scale-transition" offset-y>
            <template #activator="{ on }">
              <v-text-field outlined v-model="startDate" dense persistent-placeholder label="Start date"
                            @change="dateFilter()" :clearable="true">
                <template #prepend-inner>
                  <v-icon v-on="on" color="primary">{{ mdiCalendar }}</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker @change="$refs.sdmenu.save(startDate);dateFilter()" first-day-of-week="1" :show-week="true"
                           v-model="startDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.sdmenu.save(startDate)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div style="display:inline-block;width:170px;margin-top:40px;margin-left:12px">
          <v-menu ref="edmenu" v-model="endDateMenu" :close-on-content-click="true" :return-value.sync="endDate"
                  transition="scale-transition" offset-y>
            <template #activator="{ on }">
              <v-text-field outlined v-model="endDate" dense persistent-placeholder label="End date"
                            @change="dateFilter()" :clearable="true">
                <template #prepend-inner>
                  <v-icon v-on="on" color="primary">{{ mdiCalendar }}</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker @change="$refs.edmenu.save(endDate);dateFilter()" first-day-of-week="1" :show-week="true"
                           v-model="endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="endDateMenu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.edmenu.save(endDate)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <v-tooltip color="tooltip t1--text" bottom>
          <template #activator="{ on, attrs }">
            <v-btn class="ma-2 mx-0 mt-5" icon color="primary" v-bind="attrs" v-on="on" @click="exportDialog = true">
              <v-icon size="30">{{ mdiFileExcelOutline }}</v-icon>
            </v-btn>
          </template>
          <span>Export to Excel</span>
        </v-tooltip>
        <v-tooltip color="tooltip t1--text" bottom>
          <template #activator="{ on, attrs }">
            <v-btn class="ma-2 mx-0 mt-5" icon color="primary" v-bind="attrs" v-on="on" @click="pdfPrint()">
              <v-icon size="30">{{ mdiFileDocumentOutline }}</v-icon>
            </v-btn>
          </template>
          <span>Save to PDF</span>
        </v-tooltip>

        <template #extension>
          <v-tabs v-model="tab" class="mt-2 mb-2">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(item, index) in items" :key="index" :title="item">
              <v-badge color="error" v-if="item !== 'Filter results' && draftCount[index] && draftCount[index] > 0" :content="draftCount[index]">
                {{ item }} 
              </v-badge>
              <span v-else>
                {{ item }} 
                <v-icon small v-if="item === 'Filter results'" @click="closeSearchTab()">{{ mdiWindowClose }}</v-icon>
              </span>
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-divider></v-divider>
    </div>
    <v-tabs-items v-model="tab"
                  class="scrollbar">
      <v-tab-item v-for="i in items" :key="i" :transition="false">
        <v-card tile v-if="i === 'Filter results'">
          <v-data-table ref="datatable" :headers="headers" :items="filteredRecords" :search="search" mobile-breakpoint="900" :footer-props="{'items-per-page-options':[20, 50, 100, -1]}" 
            :custom-filter="customSearch" :options.sync="options" class="record-table">
            <template #item="{item}">
              <tr :class="{'deleted': item.status === -10, 'draft': item.status === 10 }">
                <td @click="openRecord(item)">{{ formatDate(item.eventTime, item.timezone) }}</td>
                <td @click="openRecord(item)">{{ item.timezone ? item.timezone : '+00:00' }}</td>
                <td @click="openRecord(item)">{{ item.name }}</td>
                <td @click="openRecord(item)">{{ item.summary }}</td>
                <td @click="openRecord(item)">{{ item.author }}</td>
                <td @click="openRecord(item)">{{ item.statusText }}</td>
                <td>
                  <v-tooltip left>
                    <template #activator="{ on, attrs }">
                      <v-chip color="color1" v-bind="attrs" v-on="on" @click="expandRow(item);showVersions(item.uuid)">
                        <b>{{ item.version }}</b>
                      </v-chip>
                    </template>
                    <span>Click to view edit history</span>
                  </v-tooltip>
                   <v-icon v-if="item.attachment">{{ mdiPaperclip }} </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
        <v-card tile v-else>
          <v-data-table ref="datatable" :headers="headers" :items="records" :options.sync="options" class="record-table" :footer-props="{'items-per-page-options':[20, 50, 100, -1]}" 
            :expanded.sync="expanded" item-key="uuid" mobile-breakpoint="900">
            <template #item="{item}">
              <tr :class="{'deleted': item.status === -10, 'draft': item.status === 10 }">
                <td @click="openRecord(item)">{{ formatDate(item.eventTime, item.timezone) }}</td>
                <td @click="openRecord(item)">{{ item.timezone ? item.timezone : '+00:00' }}</td>
                <td @click="openRecord(item)">{{ item.name }}</td>
                <td @click="openRecord(item)">{{ item.summary }}</td>
                <td @click="openRecord(item)">{{ item.author }}</td>
                <td @click="openRecord(item)">{{ item.statusText }}</td>
                <td>
                  <v-tooltip left>
                    <template #activator="{ on, attrs }">
                      <v-chip color="color1" v-bind="attrs" v-on="on" @click="expandRow(item);showVersions(item.uuid)">
                        <b>{{ item.version }}</b>
                      </v-chip>
                    </template>
                    <span>Click to view edit history</span>
                  </v-tooltip>
                   <v-icon v-if="item.attachment">{{ mdiPaperclip }} </v-icon>
                </td>
              </tr>
            </template>
            <template #expanded-item="{ headers }">
              <td :colspan="headers.length">
                <v-card>
                  <v-card-title>
Edit history
                    <v-spacer />
                    <v-btn text color="warn" @click="closeHistoryLog();">Close</v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table :headers="headers" :items="recordHistory" color="color1" mobile-breakpoint="900"
                                  :items-per-page="-1" hide-default-footer class="record-table">
                      <template #item="{item, index}">
                        <tr :class="{'deleted': item.status === -10 || index > 0, 'draft': item.status === 10 }" @click="openHistoryRecord(item)">
                          <td>{{ formatDate(item.eventTime, item.timezone) }}</td>
                          <td>{{ item.timezone ? item.timezone : '+00:00' }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.summary }}</td>
                          <td>{{ item.author }}</td>
                          <td>{{ item.statusText }}</td>
                          <td>
                            <v-chip color="color1">
                              <b>{{ item.version }}</b>
                            </v-chip>
                          </td>
                           <v-icon v-if="item.attachment">{{ mdiPaperclip }} </v-icon>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <ExcelExport v-model="exportDialog" v-if="exportDialog" :ship="ship" />
</div>
</template>

<script>
  import moment from "moment";
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import {
    mdiReload,
    mdiMagnify,
    mdiCalendar,
    mdiWindowClose,
    mdiLabel,
    mdiAlphaVCircleOutline,
    mdiFilePdfBox,
    mdiPaperclip,
    mdiAccountTieHatOutline,
    mdiFileSign,
    mdiFileDocumentOutline,
    mdiFileExcelOutline,
  } from '@mdi/js';
  import ExcelExport from './ExcelExport.vue';
  import pdf from '../../helpers/pdf';

  export default {
    name: 'RecordBook',
    components: {
      ExcelExport,
    },
    
    data: () => ({
      tab: 0,
      items: [
        'All'
      ],
      mdiReload,
      mdiMagnify,
      mdiCalendar,
      mdiWindowClose,
      mdiLabel,
      mdiAlphaVCircleOutline,
      mdiFilePdfBox,
      mdiPaperclip,
      mdiAccountTieHatOutline,
      mdiFileSign,
      mdiFileDocumentOutline,
      mdiFileExcelOutline,
      logInterval: null,
      search: '',
      expanded: [],
      headers: [{
          text: 'Time',
          align: 'start',
          value: 'eventTime',
        },
        {
          text: 'Timezone',
          value: 'timezone'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Summary',
          value: 'summary'
        },
        {
          text: 'Author',
          value: 'author'
        },
        {
          text: 'Status',
          value: 'statusText'
        },
        {
          text: 'Version',
          value: 'version'
        },
      ],
      options: {
        itemsPerPage: 20,
        sortBy: ['eventTime'],
        sortDesc: [true],
        multiSort: true,
        page: 1
      },
      startDateMenu: false,
      endDateMenu: false,
      startDate: moment.utc().subtract(1, 'w').format('YYYY-MM-DD'),
      endDate: moment.utc().format('YYYY-MM-DD'),
      recordHistory: [],
      ship: null,
      book: null,
      exportDialog: false,
    }),
    async mounted() {
      // Preselect stored or first ship
      setTimeout(() => {
        if (!this.ships || this.ships.length === 0) {
          this.fetchShips();
        } else {
          const shipId = localStorage.getItem('logShipId') ? parseInt(localStorage.getItem('logShipId')) : null;
          this.ship = this.ships.find((o) => o.id === shipId); // Find if last ship was stored in localStorage
          if (!this.ship) {
            this.ship = this.ships.find((o) => o.logDb); // Find first with logDb defined
            if (!this.ship) {
              this.ship = this.ships[0]; // Get first ship
            }
          }
        }
      }, 10)
      
      
      let search = sessionStorage.getItem('search');
      let options = sessionStorage.getItem('options');
      this.search = search && search !== 'null' ? search : '';
      this.createTabs(this.book);
      try {
        if (options && typeof options === 'string') {
          this.options = JSON.parse(options);
        }
      } catch (e) {
        console.warn('Failed to parse options JSON. ', e);
      }
      try { 
        this.logInterval = setInterval(() => {
          if (this.ship && this.ship.logDb) {
            this.fetchRecords({
              logDb: this.ship.logDb,
              start: this.startDate,
              end: this.endDate
            });
          }
        }, 60 * 1000);

      } catch (e) {
        console.error(e.message);
      }
      if (sessionStorage.getItem('startDate')) {
        this.startDate = sessionStorage.getItem('startDate');
      }
      if (sessionStorage.getItem('endDate')) {
        this.endDate = sessionStorage.getItem('endDate');
      }
    },
    beforeDestroy() {
      clearInterval(this.logInterval);
    },
    watch: {
      tab(newVal) {
        this.setTab(this.items[newVal]);
        // console.log(this.items[newVal]);
      },
      book: {
        handler(newVal) {
          // console.log('Book watch', newVal);
          this.createTabs(newVal);
        },
        deep: true
      },
      options: {
        handler(newVal) {
          sessionStorage.setItem('options', JSON.stringify(newVal));
        },
        deep: true
      },
      search(newVal) {
        if (this.items.findIndex(o => o === 'Filter results') !== -1) {
          sessionStorage.setItem('search', newVal);
        }
      },
      ship: {
        handler(newVal, oldVal) {
          if ((newVal && oldVal && newVal.id !== oldVal.id) || !oldVal) {
            console.log('watch ship change', newVal, oldVal);
            localStorage.setItem('logShipId', newVal.id);
            this.fetchRecords({
              logDb: this.ship.logDb,
              start: this.startDate,
              end: this.endDate
            });
            this.fetchShipLog(this.ship.logDb).then((data) => {
              this.book = data.book;
            });
            this.fetchHistoryRecords({
                logDb: this.ship.logDb,
                start: this.startDate,
                end: this.endDate
              });
            this.fetchAuditLog({
              logDb: this.ship.logDb,
              start: this.startDate,
              end: this.endDate
            });
            this.fetchUsers(this.ship.logDb);
            this.fetchTemplates(this.ship.logDb);
            this.fetchTriggers(this.ship.logDb);
          }
        },
        deep: true
      },
      ships: {
        handler(newVal) {
          if (Array.isArray(newVal) && newVal.length > 0) {
            const shipId = localStorage.getItem('logShipId') ? parseInt(localStorage.getItem('logShipId')) : null;
            this.ship = newVal.find((o) => o.id === shipId);
            if (!this.ship) {
                this.ship = newVal[0];
            }
          }
        },
        deep: true
      },
      startDate(newVal) {
        if (newVal && moment(newVal).isValid()) {
          sessionStorage.setItem('startDate', newVal);
        }
      },
      endDate(newVal) {
        if (newVal && moment(newVal).isValid()) {
          sessionStorage.setItem('endDate', newVal);
        }
      },
    },
    computed: {
      ...mapGetters({
        getUser: 'authentication/getUser',
        record: 'data/getRecord',
        user: 'authentication/getUser',
      }),
      records() {
        let records = this.getRecords();
        if (Array.isArray(records) && records.length > 0) {
          const bookName = this.items[this.tab];
          if (this.items[this.tab] !== 'All') {
            records = records.filter((o) => {
              if (o.book.includes(bookName)) {
                return o;
              }
            })
          }
          return records;
        } else {
          return [];
        }
      },
      draftCount() {
        let records = this.getRecords();
        let count = [];
        if (Array.isArray(records) && records.length > 0) {
          for (let i = 0, il = records.length; i < il; i++) {
            try {
              let book = typeof records[i].book === 'string' ? JSON.parse(records[i].book) : records[i].book;
              for (let j = 0, jl = this.items.length; j < jl; j++) {
                if (records[i].status === 10 && book.includes(this.items[j])) {
                  !count[j] ? count[j] = 1 : count[j]++;
                }
              }
            } catch (e) {
              console.error(e);
            }
          }
        }
        return count;
      },
      filteredRecords() {
        return this.getRecords();
      },
      ships() {
        return this.getShips().filter((o) => o.logDb !== null && this.user.user.logbooks && this.user.user.logbooks.includes(o.logDb));
      },
    },
    methods: {
      ...mapActions({
        fetchRecords: 'logbook/fetchRecords',
        fetchRecord: 'logbook/fetchRecord',
        fetchRecordHistory: 'logbook/fetchRecordHistory',
        fetchHistoryRecords : 'logbook/fetchHistoryRecords',
        fetchHistoryRecord: 'logbook/fetchHistoryRecord',
        fetchShips: 'data/fetchShips',
        fetchUsers: 'logbook/fetchUsers',
        fetchShipLog: 'logbook/fetchShipLog',
        fetchTemplates: 'logbook/fetchTemplates',
        fetchTriggers: 'logbook/fetchTriggers',
        fetchAuditLog: 'logbook/fetchAuditLog',
        fetchPdfData: 'logbook/fetchPdfData',

      }),
      ...mapGetters({
        getRecords: 'logbook/getRecords',
        getTab: 'logbook/getTab',
        getShips: 'data/getShips',
      }),
      ...mapMutations({
        setTab: 'logbook/setTab',
        setHistoryRecord: 'logbook/setHistoryRecord',
        alertError: 'alert/error',
        progressOn: 'data/progressOn',
        progressOff: 'data/progressOff',
      }),
      formatDate(date, timezone) {
        if (timezone) {
          return moment.utc(date).utcOffset(timezone).format('DD.MM HH:mm');
        } else {
          return moment.utc(date).format('DD.MM HH:mm');
        }
      },
      openRecord(item) {
        if (item && item.uuid) {
          this.fetchRecord({logDb: this.ship.logDb, uuid: item.uuid});
        }
      },
      dateFilter() {
        console.log('fetchRecords', this.startDate, this.endDate);
        this.fetchRecords({
          logDb: this.ship.logDb,
          start: this.startDate,
          end: this.endDate
        });
      },
      createTabs(books) {
        if (books && Array.isArray(books)) {
          this.items = books.map(o => o.name);
          this.items.unshift('All');
          setTimeout(() => {
            console.log('Tab ', this.getTab())
            this.tab = this.items.findIndex(o => o === this.getTab());
          }, 10);
          if (this.search) {
            setTimeout(() => {
              this.switchToSearchTab();
            }, 10);
          }
        }
      },
      switchToSearchTab() {
        sessionStorage.setItem('search', this.search ? this.search : '');
        let index = this.items.findIndex(o => o === 'Filter results');
        if (index !== -1) {
          this.tab = index;
          this.options.page = 1;
        } else {
          this.items.push('Filter results');
          this.tab = this.items.length - 1;
        }
      },
      customSearch(value, search, item) {
        let found = [];
        let words = [];
        if (search.includes('"')) {
          let parts = search.split('"');
          for (let i = 0, il = parts.length; i < il; i++) {
            if (i % 2 === 0) {
              if (parts[i] !== '') {
                words = words.concat(parts[i].split(' ')); // outside comments -> words
              }
            } else {
              if (parts[i] !== '') {
                words.push(parts[i]); // inside comments -> sentence
              }
            }
          }
        } else {
          words = search.split(' ');
        }
        // console.log(words);
        
        let searchItem = {
          author: item.author,
          book: item.book,
          category: item.category,
          name: item.name,
          statusText: item.statusText,
          summary: item.summary,
          uuid: item.uuid,
          fields:  item.content.fields ? item.content.fields.map(o => o.value) : '',
        }
        for (let i = 0, il = words.length; i < il; i++) {
          if (Object.values(searchItem).some(v => v && v.toString().toLowerCase().includes(words[i].toString().toLowerCase()))) {
            found.push(true);
          }
        }
        return words.length === found.length;
      },
      closeSearchTab() {
        let index = this.items.findIndex(o => o === 'Filter results')
        if (index) {
          this.items.pop();
          this.tab = 0;
          this.search = '';
          sessionStorage.removeItem('search');
          this.options.page = 1;
        }
      },
      expandRow(item) {
        this.expanded = item === this.expanded[0] ? [] : [item];
      },
      showVersions(uuid) {
        setTimeout(() => {
          if (this.expanded.length > 0) {
            this.fetchRecordHistory({logDb: this.ship.logDb, uuid: uuid}).then((data) => {
              this.recordHistory = data;
            })
          }
        }, 10);
      },
      openHistoryRecord(item) {
        if (item && item.uuid) {
          item.logDb = this.ship.logDb;
          this.setHistoryRecord(true);
          this.fetchHistoryRecord(item);
        }
      },
      closeHistoryLog() {
        this.recordHistory = [];
        this.expanded = [];
      },
      showStatus(status) {
        if (status === -10) {
          return 'Deleted';
        } else if (status === 10) {
          return 'Draft';
        } else if (status === 20) {
          return 'Verified';
        } else if (status === 30) {
          return 'Unsigned';
        } else if (status === 40) {
          return 'Signed';
        } else {
          return '';
        }
      },
      async pdfPrint() {
        this.progressOn();
        setTimeout(async () => {
          try {
            console.log('Printing PDF');
            if (this.items[this.tab] === 'Filter results') { // Search tab selected, filter records with searched words
              let records = this.filteredRecords && this.filteredRecords.length > 0 ? this.filteredRecords : [];
              if (records.length > 0) {
                records = records.filter(o => {
                  let found = [];
                  let words = this.search.split(' ');
                  let fields;
                  try {
                    fields = JSON.parse(o.content).fields
                  } catch (e) {
                    console.warn('Failed to parse content JSON. ' + e);
                  }
                  let searchItem = {
                    author: o.author,
                    book: o.book,
                    category: o.category,
                    name: o.name,
                    statusText: o.statusText,
                    summary: o.summary,
                    uuid: o.uuid,
                    fields: fields ? fields.map(o => o.value) : '',
                  }
                  for (let i = 0, il = words.length; i < il; i++) {
                    if (Object.values(searchItem).some(v => v && v.toString().toLowerCase().includes(words[i]))) {
                      found.push(true);
                    }
                  }
                  return words.length === found.length;
                })
                if (records && records.length > 0) {
                  const uuids = records.map(o => o.uuid).join("', '");
                  const pdfData = await this.fetchPdfData({logDb: this.ship.logDb, uuids, type: 'history'});
                  // records.sort((a, b) => (a.eventTime > b.eventTime) ? 1 : ((b.eventTime > a.eventTime) ? -1 : 0)); // ASC sort for pdf always
                  pdf.create(pdfData, pdfData.entries, 'All');
                } else {
                  this.alertError('No records to save in PDF');
                  this.progressOff();
                }
              }
            } else {
              if (this.records && this.records.length > 0) {
                const tab = this.items[this.tab];
                // debug('Create pdf for tab', tab);
                if (tab && (tab.startsWith('ORB') || tab === 'Cargo' || tab === 'GMDSS' || tab.startsWith('Garbage') || tab === 'Ballast')) {
                  const uuids = this.records.map(o => o.uuid).join("', '");
                  const pdfData = await this.fetchPdfData({logDb: this.ship.logDb, uuids, type: 'records'});
                  pdf.create(pdfData, pdfData.entries, tab);
                } else { // Book specific PDF prints
                  const uuids = this.records.map(o => o.uuid).join("', '");
                  const pdfData = await this.fetchPdfData({logDb: this.ship.logDb, uuids, type: 'history'});
                  console.log(pdfData)
                  pdf.create(pdfData, pdfData.entries, 'All');
                }
              } else {
                this.alertError('No records to save in PDF')
                this.progressOff();
              }
            }
          } catch (e) {
            console.warn('Failed to create PDF: ' + e);
            this.progressOff();
          }
        }, 50);
      },
    }
  };
</script>

<style>
  .record-table td {
    padding: 5px;
  }

  .draft {
    background-color: rgb(255, 82, 82, 0.1);
    /* background-color: rgb(255, 193, 7, 0.1); */
  }

  .deleted {
    text-decoration: line-through;
    opacity: 0.6 !important;
  }
</style>
<style scoped>

</style>