<template>
  <div>
    <record v-if="this.template" />
    <div v-else>
      <div>
        <v-toolbar fixed flat>
          <v-text-field v-model="search" dense outlined :clearable="true" persistent-placeholder :append-icon="mdiMagnify" label="Search records by summary"
                        style="width:200px;margin-top:40px" @keyup.enter="searchRecords()" />
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div style="display:inline-block;width:170px;margin-top:40px">
            <v-menu ref="sdmenu" v-model="startDateMenu" :close-on-content-click="true" :return-value.sync="startDate"
                    transition="scale-transition" offset-y>
              <template #activator="{ on }">
                <v-text-field outlined v-model="startDate" dense persistent-placeholder label="Start date" @keyup.enter="searchRecords()"
                               :clearable="true">
                  <template #prepend-inner>
                    <v-icon v-on="on" color="primary">{{ mdiCalendar }}</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker @change="$refs.sdmenu.save(startDate);" first-day-of-week="1" :show-week="true"
                             v-model="startDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.sdmenu.save(startDate)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <div style="display:inline-block;width:170px;margin-top:40px;margin-left:12px">
            <v-menu ref="edmenu" v-model="endDateMenu" :close-on-content-click="true" :return-value.sync="endDate"
                    transition="scale-transition" offset-y>
              <template #activator="{ on }">
                <v-text-field outlined v-model="endDate" dense persistent-placeholder label="End date" :clearable="true" @keyup.enter="searchRecords()">
                  <template #prepend-inner>
                    <v-icon v-on="on" color="primary">{{ mdiCalendar }}</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker @change="$refs.edmenu.save(endDate)" first-day-of-week="1" :show-week="true"
                             v-model="endDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="endDateMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.edmenu.save(endDate)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <v-btn class="ma-4 mt-7" color="primary" :disabled="search.length < 3" @click="searchRecords()">SEARCH</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
      </div>
          <v-card tile>
            <v-data-table :headers="headers" :items="records" :options="options" class="record-table"
                          mobile-breakpoint="900">
              <template #item="{item}">
                <tr @click="openRecord(item)" :class="{'deleted': item.status < 0 }">
                  <td>{{ formatDate(item.eventTime, item.timezone) }}</td>
                  <td>{{ item.timezone ? item.timezone : '+00:00' }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.summary }}</td>
                  <td>{{ item.author }}</td>
                  <td>{{ item.statusText }}</td>
                  <td>
                    <v-chip color="color1">
                      <b>{{ item.version }}</b>
                    </v-chip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
    </div>
  </div>
</template>

<script>
  import Record from './Record.vue'
  import moment from "moment";
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import {
    mdiReload,
    mdiMagnify,
    mdiCalendar,
    mdiWindowClose,
  } from '@mdi/js';

  export default {
    name: 'HistoryLog',
    components: {
      Record,
    },
    data: () => ({
      mdiReload,
      mdiMagnify,
      mdiCalendar,
      mdiWindowClose,
      logInterval: null,
      search: '',
      headers: [{
          text: 'Time',
          align: 'start',
          value: 'eventTime',
        },
        {
          text: 'Timezone',
          value: 'timezone'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Summary',
          value: 'summary'
        },
        {
          text: 'Author',
          value: 'author'
        },
        {
          text: 'Status',
          value: 'statusText'
        },
        {
          text: 'Version',
          value: 'version'
        },
      ],
      options: {
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
        multiSort: true,
      },
      startDateMenu: false,
      endDateMenu: false,
      startDate: moment.utc().subtract(3, 'month').format('YYYY-MM-DD'),
      endDate: moment.utc().format('YYYY-MM-DD'),
    }),
    async created() {
      try {
        setTimeout(async () => {
          // Set pre defined search string, if set
          let search = this.getSearch();
          if (this.getShip() && this.getShip().id) {
            if (typeof search !== 'undefined' && search !== null) {
              await this.searchRecords({
                logDb: this.getShip().id,
                start: this.startDate,
                end: this.endDate,
                search: search
              });
              this.search = search.toString();
              this.startDate = this.getStartDate() ? this.getStartDate() : moment.utc().subtract(3, 'month').format('YYYY-MM-DD');
              this.endDate = this.getEndDate() ? this.getEndDate() : moment.utc().format('YYYY-MM-DD');
            }
          }
        }, 300);
      } catch (e) {
        console.error(e.message);
      }
    },
    mounted() {
      
    },
    beforeDestroy() {

    },
    watch: {
      
    },
    computed: {
      ...mapGetters({
        record: 'logbook/getRecord',
        template: 'logbook/getTemplate',
        records: 'logbook/getSearchRecords',
      }),
      
    },
    methods: {
      ...mapActions({
        fetchSearchRecords: 'logbook/fetchSearchRecords',
        fetchRecord: 'logbook/fetchRecord',
      }),
      ...mapGetters({
        getShip: 'logbook/getShip',
        getSearch: 'logbook/getSearch',
        getStartDate: 'logbook/getStartDate',
        getEndDate: 'logbook/getEndDate',
      }),
      ...mapMutations({
        setSearch: 'logbook/setSearch',
        setStartDate: 'logbook/setStartDate',
        setEndDate: 'logbook/setEndDate',
        alertError: 'alert/error',
        alertWarning: 'alert/warning',
        alertSuccess: 'alert/success',
      }),
      formatDate(date, timezone) {
        if (timezone) {
          return moment.utc(date).utcOffset(timezone).format('DD.MM HH:mm');
        } else {
          return moment.utc(date).format('DD.MM HH:mm');
        }
      },
      openRecord(item) {
        if (item && item.uuid) {
          item.logDb = this.getShip().id;
          this.fetchRecord(item);
        }
      },
      searchRecords() {
        if (this.getShip() && this.getShip().id && this.search && moment(this.startDate).isValid() && moment(this.endDate).isValid()) {
          if (this.search.length >=3) {
            this.setSearch(this.search);
            this.setStartDate(this.startDate);
            this.setEndDate(this.endDate);
            this.fetchSearchRecords({
              logDb: this.getShip().id,
              start: this.startDate,
              end: this.endDate,
              search: this.search
            });
          } else {
            this.alertError('Search string must be at least 3 characters')
          }
        }
      },
    }
  };
</script>

<style>

</style>
<style scoped>

</style>