<template>
    <div id="child" style="margin-top:7px">
        <ejs-dropdownlist name="portCallDropdown" width="150px" ref="portCallInstance" id="portCallDropdown" :showClearButton="true" v-model="data.portCallId"
            :select="doCustomAction.bind(this)" :placeholder="placeholder" :dataSource="items"
            :fields="fields">
        </ejs-dropdownlist>
    </div>
</template>

<script>
    import {
        store
    } from '../../store';
    import {
        eventBus
    } from '../../main';

    export default {
        store,
        data() {
            return {
                data: {},
                items: [],
                placeholder: 'Select',
                fields: {
                    text: 'name',
                    value: 'portCallId',
                },
            };
        },
        mounted() {
            this.items = store.state.data.portCalls.map((o) => {
                if (!o.portCallId) {
                    o.portCallId = o.id;
                }
                return o;
            });
            // if (store.state.data.dischCostsOnlyPort) {
            //     this.items.push(
            //         store.state.data.dischCostsOnlyPort
            //     );
            // }
            if (this.data && this.data.portCallId) {
                const portCall = this.items.find((o) => o.portCallId && this.data.portCallId);
                this.placeholder = portCall ? portCall.name : '';
            }
            // console.log(this.items);
        },
        methods: {
            doCustomAction(args) {
                if (args && args.itemData) {
                    eventBus.$emit('newPortCall', args.itemData);
                }
            },
           
        }
    };
</script>

<style>
</style>
