<template>
    <span>
        <span class="name">{{ data.name }}</span> 
        <span class="smaller" v-show="data.terms"> - {{ data.terms }}</span> 
        <span class="smaller" v-show="data.portName"> - {{ data.portName }}</span> 
        <span class="smaller" v-show="data.portNameLoad"> - {{ data.portNameLoad }}</span> 
        <span class="smaller" v-show="data.portNameDisch"> - {{ data.portNameDisch }}</span> 
        <span class="smaller" v-show="data.brand"> - {{ data.brand }}</span> 
        <span class="smaller" v-show="data.contactName"> - {{ data.contactName }}</span> 
    </span>
</template>

<script>

    export default {
        data() {
            return {
                data: {},
            };
        },
    };
</script>

<style scoped>
    .name {
        font-weight: 500;
    }
    .smaller {
        font-size: smaller;
    }
</style>
