<template>
    <div id="child" style="margin-top:7px">
        <!-- <ejs-dropdownlist name="contactdropdown" ref="selectInstance" id="contactdropdown" :allow-filtering="true" :showClearButton="true"
            v-model="data.contactId" :change="doCustomAction()" :placeholder="placeholder" :dataSource="items" :fields="fields">
        </ejs-dropdownlist> -->
        <ejs-dropdownlist name="userDropdown" width="230px" ref="selectInstance" id="dropdownlist" :allow-filtering="true" :showClearButton="true"
            v-model="data.contactId" :change="doCustomAction.bind(this)" :placeholder="placeholder" :dataSource="items" :fields="fields" :itemTemplate="itemTemplate">
        </ejs-dropdownlist>
        <!-- <ejs-autocomplete name="userDropdown" width="500px" ref="selectInstance" id="dropdownlist" :showClearButton="true" v-model="data.moneyItemId"
            :select="doCustomAction.bind(this)" :placeholder="placeholder" :dataSource="items" :itemTemplate='itemTemplate'
            :fields="fields">
        </ejs-autocomplete> -->
    </div>
</template>

<script>
    import {
        store
    } from '../../store';
    import {
        eventBus
    } from '../../main';
    import {
        mapActions,
    } from 'vuex';
    import ContactRow from './ContactRow.vue';

    export default {
        store,
        data() {
            return {
                data: {},
                items: [],
                placeholder: 'Select',
                fields: {
                    text: 'name',
                    value: 'id',
                    groupBy: 'typeName',
                },
                itemTemplate() {
                    return {
                        template: ContactRow
                    };
                },
            };
        },
        mounted() {
            this.fetchContacts({
                type: null,
                status: 1
            }).then((data) => {
                this.items = data.map((o)=> {
                    if (o.type === 0) {
                        o.typeName = 'Customer';
                    } else if (o.type === 1) {
                        o.typeName = 'Agent';
                    } else if (o.type === 2) {
                        o.typeName = 'Supplier';
                    }
                    return o;
                });

            });
             eventBus.$on('contactInitial', (e) => {
                this.data.contactId = e;
                this.doCustomAction();
            });

            // if (this.data && this.data.contactText) {
            //     this.placeholder = this.data.contactText;
            // }
        },
        methods: {
            ...mapActions({
                fetchContacts: 'data/fetchContacts',
            }),
            doCustomAction(args) {
                if (args && args.itemData) {
                    eventBus.$emit('contactUpdated', args.itemData);
                }
            },

        }
    };
</script>

<style>
</style>