<template>
    <div>
        <v-container fluid grid-list-lg>
            <v-layout wrap align-center>
                <v-flex xs12 sm4 lg2 d-flex>
                    <v-select v-model="params.shipId" :items="ships" item-key="id" item-text="[name]" item-value="id" label="Select ship" />
                </v-flex>

                <v-flex xs6 sm4 md2 d-flex>
                    <v-menu ref="sdmenu" v-model="startDateMenu" :close-on-content-click="true" :return-value.sync="params.startDate"
                        transition="scale-transition" offset-y min-width="290px">
                        <template #activator="{ on }">
                            <v-text-field v-model="params.startDate" label="Start date">
                                <template #prepend>
                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker @change="$refs.sdmenu.save(params.startDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                            v-model="params.startDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.sdmenu.save(params.startDate)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>
                <v-flex xs6 sm4 md2 d-flex>
                    <v-menu ref="edmenu" v-model="endDateMenu" :close-on-content-click="false" :return-value.sync="params.endDate"
                        transition="scale-transition" offset-y min-width="290px">
                        <template #activator="{ on }">
                            <v-text-field v-model="params.endDate" label="End date">
                                <template #prepend>
                                    <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker @change="$refs.edmenu.save(params.endDate)" :first-day-of-week="firstDayOfWeek" :show-week="true"
                            v-model="params.endDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="endDateMenu = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.edmenu.save(params.endDate)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>
                <v-spacer />
                <div class="mb-4">
                    <v-btn color="success" @click="exportToExcel(params)">
                        Excel <v-icon right class="hidden-xs-only">
                            arrow_drop_down_circle
                        </v-icon>
                    </v-btn>
                    <v-btn color="error" class="ml-4" @click="clearChart()">
                        Clear <v-icon right class="hidden-xs-only">cancel</v-icon>
                    </v-btn>
                    <v-btn color="primary" class="ml-4" @click="getData(params)"
                        :disabled="!params.shipId && !params.startDate && !params.endDate">
                        Get data
                        <v-icon right class="hidden-xs-only">check</v-icon>
                    </v-btn>
                </div>
            </v-layout>
        </v-container>
        <v-container fluid class="pa-0" v-show="chart && chart.data && chart.data.length > 0">
            <v-layout wrap>
                <v-flex xs12>
                    <v-card class="eventcard cardbg primarytext--text">
                        <v-container fluid grid-list-md class="pa-0">
                            <div class="chart" ref="chartdiv">
                            </div>
                        </v-container>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <!-- Components -->
        <ProgressCircular />
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions,
    } from 'vuex';
    import moment from 'moment';
    import * as am4core from '@amcharts/amcharts4/core';
    import * as am4charts from '@amcharts/amcharts4/charts';
    import am4themes_animated from '@amcharts/amcharts4/themes/animated';
    import writeXlsxFile from 'write-excel-file';
    import ProgressCircular from './ProgressCircular.vue';

    am4core.useTheme(am4themes_animated);

    export default {
        name: 'CiiData',
        props: {
            ciiShipId: {
                type: Number,
                required: false,
            }
        },
        components: {
            ProgressCircular
        },
        data() {
            return {
                params: {
                    shipId: null,
                    startDate: moment().startOf('year').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                },
                startDateMenu: false,
                endDateMenu: false,
                chart: {},
                initialTimes: {
                    startDate: null,
                    endDate: null,
                },
            };
        },
        mounted() {
            const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
            chart.dateFormatter.utc = true;
            chart.paddingRight = 20;
            chart.data = [];

            const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.tooltipDateFormat = 'dd.MM';
            dateAxis.renderer.grid.template.location = 0;

            const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "position";
            categoryAxis.renderer.grid.template.disabled = true;
            categoryAxis.tooltip.disabled = true;
            categoryAxis.hidden = true;

            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.tooltip.disabled = true;
            valueAxis.renderer.labels.template.fill = am4core.color('#e59165');
            // valueAxis.renderer.width = 30;
            valueAxis.min = 2;
            valueAxis.max = 6;
            valueAxis.extraMax = 0.1;
            valueAxis.maxZoomFactor = 10;

            const series = chart.series.push(new am4charts.LineSeries());
            series.name = 'CII attained';
            series.dataFields.dateX = 'date';
            series.dataFields.valueY = 'ciiAttained';
            series.tooltipText = '{name}: {valueY.value}';
            series.fill = am4core.color('#e59165');
            series.stroke = am4core.color('#e59165');
            series.strokeWidth = 2;
            series.xAxis = dateAxis;
            // series.segments.template.interactionsEnabled = true;

            const series2 = chart.series.push(new am4charts.LineSeries());
            series2.name = 'CII reference';
            series2.dataFields.dateX = 'date';
            series2.dataFields.valueY = 'ciiRequired';
            series2.fill = am4core.color('#d576a3');
            series2.stroke = am4core.color('#d576a3');
            series2.tooltipText = '{name}: {valueY.value}';
            series2.strokeDasharray = '6 3';
            series2.strokeWidth = 2;
            series2.xAxis = dateAxis;

            const series3 = chart.series.push(new am4charts.ColumnSeries());
            series3.tooltip.disabled = true;
            series3.stacked = true;
            series3.columns.template.fill = am4core.color("#85e085");
            series3.dataFields.valueY = "rB";
            series3.dataFields.categoryX = "position";
            series3.xAxis = categoryAxis;
            series3.strokeWidth = 0;
            series3.fillOpacity  = 0.5;
            series3.hiddenInLegend = true;

            const bullet3 = series3.bullets.push(new am4charts.LabelBullet());
            bullet3.label.text = "A";
            bullet3.label.fill = am4core.color("#000");
            bullet3.locationY = 0.2;

            const series4 = chart.series.push(new am4charts.ColumnSeries());
            series4.tooltip.disabled = true;
            series4.stacked = true;
            series4.columns.template.fill = am4core.color("#99ff99");
            series4.dataFields.valueY = "rC";
            series4.dataFields.categoryX = "position";
            series4.xAxis = categoryAxis;
            series4.strokeWidth = 0;
            series4.fillOpacity  = 0.5;
            series4.hiddenInLegend = true;
            
            const bullet4 = series4.bullets.push(new am4charts.LabelBullet());
            bullet4.label.text = "B";
            bullet4.label.fill = am4core.color("#000");
            bullet4.locationY = 0.5;

            const series5 = chart.series.push(new am4charts.ColumnSeries());
            series5.tooltip.disabled = true;
            series5.stacked = true;
            series5.columns.template.fill = am4core.color("#ffff80");
            series5.dataFields.valueY = "rD";
            series5.dataFields.categoryX = "position";
            series5.xAxis = categoryAxis;
            series5.strokeWidth = 0;
            series5.fillOpacity  = 0.5;
            series5.hiddenInLegend = true;

            const bullet5 = series5.bullets.push(new am4charts.LabelBullet());
            bullet5.label.text = "C";
            bullet5.label.fill = am4core.color("#000");
            bullet5.locationY = 0.5;

            const series6 = chart.series.push(new am4charts.ColumnSeries());
            series6.tooltip.disabled = true;
            series6.stacked = true;
            series6.columns.template.fill = am4core.color("#ffccb3");
            series6.dataFields.valueY = "rE";
            series6.dataFields.categoryX = "position";
            series6.xAxis = categoryAxis;
            series6.strokeWidth = 0;
            series6.fillOpacity  = 0.5;
            series6.hiddenInLegend = true;

            const bullet6 = series6.bullets.push(new am4charts.LabelBullet());
            bullet6.label.text = "D";
            bullet6.label.fill = am4core.color("#000");
            bullet6.locationY = 0.5;

            const series7 = chart.series.push(new am4charts.ColumnSeries());
            series7.tooltip.disabled = true;
            series7.stacked = true;
            series7.columns.template.fill = am4core.color("#ff8080");
            series7.dataFields.valueY = "rMax";
            series7.dataFields.categoryX = "position";
            series7.xAxis = categoryAxis;
            series7.strokeWidth = 0;
            series7.fillOpacity  = 0.5;
            series7.hiddenInLegend = true;

            const bullet7 = series7.bullets.push(new am4charts.LabelBullet());
            bullet7.label.text = "E";
            bullet7.label.fill = am4core.color("#000");
            bullet7.locationY = 0.98;

            chart.cursor = new am4charts.XYCursor();
            chart.cursor.xAxis = dateAxis;

            chart.legend = new am4charts.Legend();
            chart.legend.zIndex = 100;
            chart.legend.opacity = 0.8;
            chart.legend.useDefaultMarker = true;
            const markerTemplate = chart.legend.markers.template;
            markerTemplate.width = 12;
            markerTemplate.height = 12;
            chart.legend.itemContainers.template.paddingTop = 0;
            chart.legend.itemContainers.template.paddingBottom = 0;
            chart.legend.position = 'bottom';

            dateAxis.renderer.grid.template.strokeOpacity = 0.07;
            valueAxis.renderer.grid.template.strokeOpacity = 0.07;

            this.chart = chart;

            // Zoom map to extent on chart zoomed data timeframe
            chart.cursor.events.on('zoomended', function(ev) {
            const range = ev.target.xRange;
            const axis = ev.target.chart.xAxes.getIndex(0);
            if (range && range.start && range.end) {
                const start = axis.positionToDate(axis.toAxisPosition(range.start));
                const end = axis.positionToDate(axis.toAxisPosition(range.end));
                // Update date & time pickers on zoom
                this.params.startDate = moment.utc(start).format('YYYY-MM-DD');
                this.params.endDate = moment.utc(end).format('YYYY-MM-DD');
            }
            }, this);

            // Reset data on zoomout button
            chart.zoomOutButton.events.on('hit', function() {
                this.params.startDate = this.initialTimes.startDate;
                this.params.endDate = this.initialTimes.endDate;
            }, this);

            // Preselect first ship 
            this.fetchShips().then((data) => {
                if (this.ciiShipId) {
                     // Check if valid ship id
                    let ship = data.find((o) => o.perfDb !== null && o.id === this.ciiShipId);
                    if (ship) {
                        this.params.shipId = this.ciiShipId;
                        this.getData(this.params);
                    } else {
                        this.$store.dispatch('alert/error', 'Not valid ship ID for CII data.', {
                        root: true
                    });
                    }
                } else {
                    const shipId = localStorage.getItem('perfShipId') ? parseInt(localStorage.getItem('perfShipId')) : null;
                    // Check if valid ship id
                    let ship = data.find((o) => o.perfDb !== null && o.id === shipId);
                    if (!ship) {
                        // If not select first ship
                        ship = data.find((o) => o.perfDb !== null);
                    }
                    this.params.shipId = ship.id;
                }
            });

            this.params.startDate = moment().startOf('year').format('YYYY-MM-DD');
            this.params.endDate = moment().format('YYYY-MM-DD');
        },
        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose();
            }
        },
        computed: {
            ships() {
                return this.getShips().filter((o) => o.perfDb);
            },
            firstDayOfWeek() {
                return this.getFirstDayOfWeek();
            },
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
                getDynamic: 'data/getDynamic',
                getShips: 'data/getShips',
                getFirstDayOfWeek: 'data/getFirstDayOfWeek',
            }),
            ...mapActions({
                fetchShips: 'data/fetchShips',
                fetchCiiData: 'data/fetchCiiData',
            }),
            getData(params) {
                const ship = this.getShips().filter((o) => o.id === params.shipId)[0];
                localStorage.setItem('perfShipId', ship.id);
                if (!ship.ciiCoefficients) {
                    this.$store.dispatch('alert/error', 'CII coefficients missing for the ship. Cannot calculate CII data.', {
                        root: true
                    });
                    return;
                }
                // console.log(ship);
                this.initialTimes.startDate = params.startDate;
                this.initialTimes.endDate = params.endDate;
                this.fetchCiiData(params).then((data) => {
                    if (data && data.length > 0) {
                        let distanceY = 0;
                        let co2TotalY = 0;
                        let rB = data[0].ciiRequired * ship.ciiCoefficients.b;
                        let rC = data[0].ciiRequired * ship.ciiCoefficients.c - rB;
                        let rD = data[0].ciiRequired * ship.ciiCoefficients.d - rB - rC;
                        let rE = data[0].ciiRequired * ship.ciiCoefficients.e - rB - rC - rD;
                        let rMax = 30;
                        let ciiAttainedMax = 0;
                        for (const key in data) {
                            data[key].date = moment.utc(data[key].date).toDate();
                            distanceY += data[key].distance;
                            co2TotalY += data[key].co2Total;
                            data[key].ciiAttained = Math.round(co2TotalY * 1000000 / (distanceY * ship.dwt) * 1000) / 1000;
                            if (data[key].ciiAttained > ciiAttainedMax) {
                                ciiAttainedMax = data[key].ciiAttained;
                            }
                            data[key].ciiRatingYtd = 'E';
                            if (data[key].ciiAttained < rB) {
                                data[key].ciiRatingYtd = 'A';
                            } else if (data[key].ciiAttained < rB + rC) {
                                data[key].ciiRatingYtd = 'B';
                            } else if (data[key].ciiAttained < rB + rC + rD) {
                                data[key].ciiRatingYtd = 'C';
                            } else if (data[key].ciiAttained < rB + rC + rD + rE) {
                                data[key].ciiRatingYtd = 'D';
                            } 
                        }
                        data.push({position:0});
                        data.push({position:1});
                        data.push({position:2});
                        data.push({position:3});
                        data.push({position:4});
                        data.push({position:5});
                        data.push({position:6});
                        data.push({position:7});
                        data.push({ rB, rC, rD, rE, rMax, position: 8 });
                        if (this.chart) {
                            this.chart.data = data;
                            // Set chart yAxis max based on data max
                            if (ciiAttainedMax > 6) {
                                this.chart.yAxes._values[0].max = ciiAttainedMax;
                            } else {
                                this.chart.yAxes._values[0].max = 6;
                            }
                        }
                    } else {
                        this.$store.dispatch('alert/error', 'Ship has no CII data for this timeperiod.', {
                        root: true
                    });
                    }
                });
            },
            clearChart() {
                this.params.startDate = moment().startOf('year').format('YYYY-MM-DD');
                this.params.endDate = moment().format('YYYY-MM-DD');
                if (this.chart && this.chart.data) {
                    this.chart.data = [];
                }
            },
            formatExcelData(data) {
                let keys = Object.keys(data[0]);
                keys = keys.filter(o => o !== 'date');
                const headerRow = [{type: String, value: 'date', fontWeight: 'bold'}];
                keys.map((o) => {
                    headerRow.push({ type: String, value: o, fontWeight:'bold' });
                })
                const rows = [headerRow];
                const length = keys.length;
                if (length > 0) {
                    for (const entry of data) {
                        if (entry.date) {
                            const columns = [];
                            columns.push({type: Date, value: entry.date, format: 'dd.mm.yyyy'});
                            for (let i = 0; i < length; i++) {
                                let value = entry[keys[i]]; 
                                if (typeof value !== 'undefined') {
                                    let t = String;
                                    if (value === null || value === '') {
                                        value = '';
                                    } else if (typeof value === 'number') {
                                        t = Number;
                                    } else if (typeof value === 'string' && value.startsWith('2') && value.includes('-') && moment(value).isValid()) {
                                        t = String;
                                    } else if (typeof value === 'string' && this.isNumeric(value) && value !== null && value !== '') {
                                        t = Number;
                                        value = parseFloat(value);
                                    }
                                    columns.push({ type: t, value: value});
                                }
                            }
                            rows.push(columns);
                        }
                    }
                }
                return rows;
            },
            async exportToExcel(params) {
                const ship = this.getShips().filter((o) => o.id === params.shipId)[0];
                if (this.chart && this.chart && this.chart.data.length > 0) {
                    const data = this.chart.data;
                    for (let i = 0, il = data.length; i < il; i++) {
                        data[i].ship = ship.name;
                        data[i].imo = ship.imo;
                        data[i].dwt = ship.dwt;
                    }
                    const rows = this.formatExcelData(data);
                    const columns = [{width: 20}];
                    await writeXlsxFile(rows, {
                        columns,
                        fileName: `${ship.name}_${moment.utc(params.startDate).format('YYMMDD')}-${moment.utc(params.endDate).format('YYMMDD')}_CII.xlsx`,
                        stickyRowsCount: 1,
                    });
                } else {
                    this.$store.dispatch('alert/error', 'Get data first', {
                        root: true
                    });
                }
            },
            isNumeric(n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            },
        }
    };
</script>

<style>
    .v-window__container--is-active {
        overflow: visible;
    }
</style>
<style scoped>
    .chart {
        width: 100%;
        height: 500px;
    }
</style>