// import '@babel/polyfill';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import { store } from './store';
import './filter';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWX5fdnRXR2NdUkJ2V0s=');

Vue.config.productionTip = false;
export const eventBus = new Vue();

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
}).$mount('#app');
