export const alert = {

    namespaced: true,
    state: {
        type: null,
        message: null,
        emit: null,
        data: null,
    },
    actions: {
        success({ commit }, message) {
            commit('success', message);
        },
        error({ commit }, message) {
            commit('error', message);
        },
        warning({ commit }, message) {
            commit('warning', message);
        },
        confirm({ commit }, { message, emit, data }) {
            commit('confirm', { message, emit, data });
        },
        clear({ commit }, message) {
            commit('success', message);
        }
    },
    mutations: {
        success(state, message) {
            state.type = 'success';
            state.message = message;
        },
        error(state, message) {
            state.type = 'error';
            state.message = message;
        },
        warning(state, message) {
            state.type = 'warning';
            state.message = message;
        },
        confirm(state, { message, emit, data }) {
            state.type = 'confirm';
            state.message = message;
            state.emit = emit;
            state.data = data;
        },
        missingConfirm(state, { message, emit, data }) {
            state.type = 'quality check';
            state.message = message;
            state.emit = emit;
            state.data = data;
        },
        clear(state) {
            state.type = null;
            state.message = null;
        }
    }
};
