<template>
    <div id="child" style="margin-top:7px">
        <ejs-dropdownlist name="incomeDropdown" id="incomedropdown" v-model="data.income"
            :change="doCustomAction()" placeholder="Select" :dataSource="items" :fields="fields">
        </ejs-dropdownlist>
    </div>
</template>

<script>
    import {
        eventBus
    } from '../../main';

    export default {
        data() {
            return {
                initialLoad: true,
                data: {},
                items: [{ value: 0, text: 'Cost' }, { value: 1, text: 'Income' }],
                fields: {
                    text: 'text',
                    value: 'value'
                }
            };
        },
        mounted() {
            eventBus.$on('incomeInitial', (e) => {
                this.data.income = e;
                this.doCustomAction();
            });
        },
        beforeDestroy() {
            eventBus.$off('incomeInitial');
        },
        methods: {
            doCustomAction() {
                eventBus.$emit('incomeUpdated', this.data.income);
            }
        }
    };
</script>

<style>
</style>
