<template>
    <div class="control-section">
        <ejs-grid ref="grid" id="Grid" :dataSource="data" :allowResizing="true" :rowHeight="25" :toolbar="toolbar" :allowReordering="true"
            :gridSettings="gridSettings" :actionBegin="actionBegin" :toolbarClick="toolbarClick" :actionComplete="actionComplete"
             :editSettings="editSettings" :allowPaging="false" :allowSorting="true" :allowMultiSorting="true" :sortSettings="sortOptions"
            :queryCellInfo="queryCellInfo" :height="moneyTableHeight()" 
            :rowSelected="rowSelected" :key="gridKey">
            <e-columns>
                <e-column field="id" headerText="ID" :visible="false" :allowEditing="false" :isPrimaryKey="true" width="60" headerTextAlign="Center"></e-column>
                <e-column field="moneyItemId" headerText="Item" :valueAccessor="valueAccessor" :allowEditing="false" width="170" headerTextAlign="Center"></e-column>
                <e-column field="portIdLoad" headerText="POL" :valueAccessor="valueAccessor" :allowEditing="false" clipMode="EllipsisWithTooltip" width="140" headerTextAlign="Center"></e-column>
                <e-column field="portIdDisch" headerText="POD" :valueAccessor="valueAccessor" :allowEditing="false" clipMode="EllipsisWithTooltip" width="140" headerTextAlign="Center"></e-column>
                
                <e-column field="unit" headerText="Unit" :editTemplate="unitTemplate" width="100" headerTextAlign="Center"></e-column>
                <e-column field="quantityMin" headerText="Qty min" width="100" format="n2" editType="numericedit" :edit="decimal2Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="quantityMax" headerText="Qty max" width="100" format="n2" editType="numericedit" :edit="decimal2Params" textAlign="Right" headerTextAlign="Center"></e-column>
                <e-column field="notes" headerText="Notes" width="170" headerTextAlign="Center" clipMode="EllipsisWithTooltip"></e-column>
            </e-columns>
        </ejs-grid>
    </div>
</template>

<script>
    // import moment from 'moment';
    import {
        mapGetters,
        mapActions,
        mapMutations,
    } from 'vuex';
    import Vue from 'vue';
    import {
        GridPlugin,
        Resize,
        Sort,
        Edit,
        Toolbar,
        Reorder,
        ForeignKey,
    } from '@syncfusion/ej2-vue-grids';
    import { ToolbarPlugin } from '@syncfusion/ej2-vue-navigations';
    import { loadCldr, setCulture } from '@syncfusion/ej2-base';
    import {
        DropDownListPlugin
    } from '@syncfusion/ej2-vue-dropdowns';

    // import MoneyItemDropDown from './MoneyItemDropDown.vue';
    // import PolDropDown from './PolDropDown.vue';
    // import PodDropDown from './PodDropDown.vue';
    import UnitDropDown from './UnitDropDown.vue';
    import {
        eventBus
    } from '../../main';
    
    loadCldr( 
        require('cldr-data/main/en-FI/ca-gregorian.json'), 
        require('cldr-data/main/en-FI/numbers.json'), 
        require('cldr-data/main/en-FI/timeZoneNames.json'), 
        require('cldr-data/supplemental/numberingSystems.json'),
        require('cldr-data/supplemental/weekData.json'),
    ); 

    Vue.use(GridPlugin);
    Vue.use(ToolbarPlugin);
    Vue.use(DropDownListPlugin);

    let rowId = null;
    export default {
        name: 'SchedulerCargo',
        props: {
            projectId: Number,
            isVoyage: Number,
            moneyData: Array,
            resource: Object,
        },
        components: {
        },
        data() {
            return {
                key: 0,
                moneyItem: null,
                moneyItemId: null,
                rowIndex: null,
                gridKey: 0,
                gridSettings: {
                    allowSelection: true,
                },
                data: [],
                editSettings: {
                    showDeleteConfirmDialog: true,
                    allowEditOnDblClick: true,
                    allowEditing: true,
                    allowAdding: true,
                    allowDeleting: true,
                },
                custom: { class: 'column' },
                sortOptions: { columns: [
                    { field: 'type', direction: 'Descending' }, 
                    ] }, 
                toolbar: ['Delete', 'Edit', 'Update', 'Cancel', {
                        text: 'Copy', tooltipText: 'Create new row with selected row values', disabled: true, prefixIcon: 'e-copy', id: 'copy' 
                    }],
                intParams: {
                    params: {
                        decimals: 3,
                        format: 'n',
                        showSpinButton: false,
                    }
                },
                decimal2Params: {
                    params: {
                        decimals: 2,
                        format: '######.##',
                        validateDecimalOnType: true,
                        showSpinButton: false,
                    }
                },
                decimal3Params: {
                    params: {
                        decimals: 3,
                        format: '######.###',
                        validateDecimalOnType: true,
                        showSpinButton: false,
                    }
                },
                // moneyItemTemplate() {                   
                //     return {
                //         template: MoneyItemDropDown
                //     };
                // },
                // polTemplate() {                   
                //     return {
                //         template: PolDropDown
                //     };
                // },
                // podTemplate() {                   
                //     return {
                //         template: PodDropDown
                //     };
                // },
                unitTemplate() {
                    return {
                        template: UnitDropDown
                    };
                },
            };
        },  
        created() {
            setCulture('en-FI');
            // console.log('project id: ' + this.projectId)
            this.data = this.moneyData ? this.moneyData : [];
            // Fix for ej2 data-grid problem, when number formatted column gets string and table data is not visible
            
            eventBus.$off('newMoneyData');
            eventBus.$on('newMoneyData', () => {
                // console.log('moneyData', this.$refs.grid.ej2Instances.dataSource);
                this.$refs.grid.ej2Instances.dataSource = this.data; 
            });

        },
        beforeDestroy() {
            eventBus.$off('newMoneyData');
            eventBus.$off('moneyItem');
        },
        watch: {},
        computed: {
            ...mapGetters({
                getUser: 'authentication/getUser',
                features: 'authentication/features',
                ships: 'data/getShips',
                dark: 'users/getDarkMode',
            }),
        },
        methods: {
            ...mapGetters({
            }),
            ...mapActions({
                
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
                alertError: 'alert/error',
                alertClear: 'alert/clear',
            }),
            valueAccessor(field, data) {
                if (field === 'moneyItemId') {
                    return data.moneyItemName;
                } if (field === 'portIdLoad') {
                    return data.pol ? data.pol : '';
                } if (field === 'portIdDisch') {
                    return data.pod ? data.pod : '';
                }
            },
            queryCellInfo(args) { 
                if (this.dark) {
                    args.cell.classList.add('light-text');
                }
            },
            actionBegin(args) {
                console.log('actionBegin', args.requestType, args); // custom Action
                if (args.requestType === 'beginEdit' || args.requestType === 'add') {
                    eventBus.$emit('disableSave', true);
                    eventBus.$on('moneyItem', (e) => {
                        if (this.$refs.grid && this.$refs.grid.ej2Instances) {
                            const editModule = this.$refs.grid.ej2Instances.editModule;
                            const element = editModule.formObj.element;
                            // here we can get the current Edited row data 
                            // let currentRowData = editModule.getCurrentEditedData(editModule.formObj.element, {});
                            // console.log(currentRowData)
                            // this.moneyItem = e;
                            this.moneyItem = this.params.moneyItems.find((o) => o.id === e.id);
                            // to select other input field   -- grid element id + field name 
                            if (this.moneyItem) {
                                // console.log('money item change', this.moneyItem);
                                if (element.querySelector('#Gridunit')) {
                                    element.querySelector('#Gridunit').value = this.moneyItem.unit;
                                }
                                if (element.querySelector('#unitdropdown_hidden')) {
                                    element.querySelector('#unitdropdown_hidden').value = this.moneyItem.currency;
                                    eventBus.$emit('unitInitial', this.moneyItem.unit);
                                }
                                if (element.querySelector('#Gridnotes')) {
                                    element.querySelector('#Gridnotes').value = this.moneyItem.notes;
                                }
                                if (element.querySelector('#Gridtype')) {
                                    element.querySelector('#Gridtype').value = this.moneyItem.type;
                                }
                                if (element.querySelector('#GridportIdLoad')) {
                                    element.querySelector('#GridportIdLoad').value = this.moneyItem.portNameLoad;
                                }
                                if (element.querySelector('#GridportIdDisch')) {
                                    element.querySelector('#GridportIdDisch').value = this.moneyItem.portNameDisch;
                                }
                            }
                        }
                    });
                    
                    eventBus.$on('unitUpdated', (e) => {
                        this.unit = e;
                    });
                }
                if (args.requestType === 'beginEdit') {
                    // eventBus.$emit('addMoneyItem', args.rowData);
                    this.$refs.grid.ej2Instances.showSpinner();
                }
                
                if (args.requestType === 'save') {
                    // args.data.shipId = this.resource.shipId;
                    
                    // if (this.moneyItem) {
                    //     args.data.moneyItemName = this.moneyItem.name; 
                    //     args.data.moneyItemId = this.moneyItem.id;
                    //     args.data.notes = this.moneyItem.notes;
                    //     args.data.portIdLoad = this.moneyItem.portIdLoad;
                    //     args.data.pol = this.moneyItem.portNameLoad;
                    //     args.data.portIdDisch = this.moneyItem.portIdDisch;
                    //     args.data.pod = this.moneyItem.portNameDisch;
                    //     args.data.unit = this.moneyItem.unit;
                    // }
                    // if (this.unit !== null) {
                    //     args.data.unit = this.unit;
                    //     this.unit = null;
                    // }

                    // if (!args.data.quantityMin) {
                    //     args.data.quantityMin = 0
                    // } 
                    // if (!args.data.quantityMax) {
                    //     args.data.quantityMax = 0
                    // }
                    if (!args.data.moneyItemId) {
                        args.data.moneyItemId = args.rowData.moneyItemId;
                    }
                    if (!args.data.portIdLoad) {
                        args.data.portIdLoad = args.rowData.portIdLoad;
                    }
                    if (!args.data.portIdDisch) {
                        args.data.portIdDisch = args.rowData.portIdDisch;
                    }
                    
                    eventBus.$off('moneyItem');
                    // this.moneyItem = null;
                }
            },
            actionComplete(args){
                console.log('actionComplete', args.requestType, args.rowData);
                if (args.requestType === 'save' && args.data) {

                    this.data = JSON.parse(JSON.stringify(this.$refs.grid.ej2Instances.dataSource));
                    eventBus.$emit('moneyDataUpdate', this.data);
                }
                if (args.requestType === 'save' || args.requestType === 'cancel') {
                    eventBus.$emit('disableSave', false);
                }
                if (args.requestType === 'delete') {
                    if (typeof args.data[0].id !== 'undefined' && args.data[0].id >= 0) {
                        this.$refs.grid.ej2Instances.dataSource = this.$refs.grid.ej2Instances.dataSource.filter((o) => o.id !== args.data[0].id);
                        this.data = JSON.parse(JSON.stringify(this.$refs.grid.ej2Instances.dataSource));
                        eventBus.$emit('moneyDataUpdate', this.data);
                    }
                }
                if (args.requestType === 'beginEdit') {
                    this.$refs.grid.ej2Instances.hideSpinner();
                }
            },
            rowSelected(args) {
                this.rowIndex = args.rowIndex;
                console.log(args.data.id)
                rowId = args.data.id;
                this.$refs.grid.ej2Instances.toolbarModule.enableItems(['copy'], true); // Enable toolbar items.
                // console.log('row ' + this.rowIndex, args)
            },
            
            toolbarClick(args) {
                if (args.item.id === 'copy') {
                    if (rowId !== null && typeof rowId !== 'undefined') {
                        this.data = JSON.parse(JSON.stringify(this.$refs.grid.ej2Instances.dataSource));
                        const newRow = JSON.parse(JSON.stringify(this.data.find((o) => o.id === rowId)));
                        newRow.id = Date.now();
                        if (newRow.type === 'Fairway') {
                            const x = newRow.notes.split('#');
                            newRow.notes = x[0];
                        }
                        // this.data.push(newRow);
                        this.data.splice(this.rowIndex, 0, newRow)
                        eventBus.$emit('moneyDataUpdate', this.data);
                    }
                }
            },
            moneyTableHeight() {  
                if (this.gridExpand) {
                    return Math.round((window.innerHeight - 140));
                } 
                    const maxRows = 12; // Math.round((window.innerHeight - 220) / 25);
                    let length = 5 * 25;
                    if (Array.isArray(this.data) && this.data.length >= maxRows) {
                        length = maxRows * 25;
                    } else if (Array.isArray(this.data) && this.data.length > 5) {
                        length = 25 * this.data.length;
                    }
                    return length;
            },
        },
        provide: {
            grid: [Sort, Resize, Edit, Toolbar, Reorder, ForeignKey]
        }
    };
</script>

<style>
    /* syncfusion grid */
    .e-grid .e-groupheadercell {
        border-radius: 16px !important;
        height: 32px !important;
        line-height: 32px !important;
    }

    .e-grid .e-groupheadercell .e-grouptext {
        height: 32px !important;
        line-height: 32px !important;
    }

    .e-input-group .e-input-group-icon,
    .e-input-group.e-control-wrapper .e-input-group-icon {
        margin-right: -1px !important;
        margin-left: -1px !important;
    }

    .e-grid .e-rowcell,
    .e-grid .e-headercell,
    .e-grid .e-detailheadercell {
        padding: 4px !important;
    }

    .income .e-rowcell {
        /* color: #f44336 !important; */
        font-weight: 500;
        opacity: 1 !important;
    }
    .sign-issue {
        background-color: rgba(244, 67, 54, 0.3) !important;
    }
    .e-summarycell {
        padding: 4px !important;
    }
    .e-dropdownbase .e-list-group-item, .e-fixed-head {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
    .e-dropdownbase .e-list-item {
        padding-right: 8px !important;
        text-indent: 8px !important;
    }
    .e-dropdownbase.e-dd-group .e-list-item {
        padding-left: 12px !important;
        text-indent: 0 !important;
    }
    .e-expand::before {
      content: '\e70e';
      position: relative;
      top:-1px;
    }
    .e-copy::before {
      content: '\e280';
      position: relative;
      top:-1px;
    }
    .e-quantities::before {
      content: '\e663';
      position: relative;
      top:-2px;
    }
    .e-sumfinal::before {
      content: '\1f441';
      position: relative;
      top:-4px;
    }
    .e-grid .e-rowcell.column{
        background-color: rgba(199, 167, 24, 0.1);
    }   
    .e-grid .e-rowcell.columnfinal{
        background-color: rgba(40, 211, 117, 0.1);
    }
    .gridexpand {
        position: fixed !important;
        top: 0;
        bottom: 0;
        z-index: 1;
        margin: 10px;
        left: 0;
        right: 0;
        background: #fff;
    }
</style>
