import axios from 'axios';
import moment from 'moment';
import { authHeader, handleResponse, tools } from '../helpers';

export default class GridDataService {
    constructor() {
        this.requestOptions = {
            headers: authHeader()
        };
        this.BASE_URL = tools.getPerfUrl();
        // this.BASE_URL = 'http://localhost:3000';
    }

    get(state) {
        const pageQuery = `/${state.skip}/${state.take}`;
        let sortQuery = '/etd desc';
        if ((state.sorted || []).length) {
            sortQuery = `/${state.sorted.map((obj) => {
                obj.name = obj.name === 'statusText' ? 'status' : obj.name;
                return obj.direction === 'descending' ? `${obj.name} desc` : obj.name;
            }).reverse().join(',')}`;
        }
        let whereQuery = '/0';
        if (state && state.where && state.where.length > 0) {
            whereQuery = `/${state.where[0].predicates.map((obj) => {
                obj.field = obj.field === 'statusText' ? 'status' : obj.field;
                obj.name = `v.${obj.field}='${obj.value}'`;
                return obj.name;
            }).join(' AND ')}`;
        }
        this.url = `${this.BASE_URL}/log/voyages${pageQuery}${sortQuery}${whereQuery}`;
        return axios.get(this.url, this.requestOptions)
            .then(handleResponse)
            .then((data) => ({ result: this.parseData(data.result, sortQuery), count: data.count, avg: data.avg })).catch((e) => {
                console.error(e);
                return [];
            });
    }

    edit(state) {
        // console.log('edit', state)
        let sortQuery = 'etd desc';
        if ((state.sorted || []).length) {
            sortQuery = state.sorted.map((obj) => (obj.direction === 'descending' ? `${obj.name} desc` : obj.name)).reverse().join(',');
        }
        
        // Compare edited values
        const values = {};
        for (const key in state.data) {
            if (state.data.hasOwnProperty(key)) {
                for (const key2 in state.rowData) {
                    if (state.rowData.hasOwnProperty(key2) && key === key2) {
                        if ((key === 'atd' || key === 'etd' || key === 'ata' || key === 'eta') && state.data[key] !== state.rowData[key2] && moment.utc(state.data[key]).unix() !== moment.utc(state.rowData[key2]).unix()) {
                            values[key] = [];
                            values[key][0] = state.rowData[key2] ? moment.utc(state.rowData[key2]).format('YYYY-MM-DDTHH:mm:ss') : null;
                            values[key][1] = state.data[key] ? moment.utc(state.data[key]).format('YYYY-MM-DDTHH:mm:ss') : null;
                            console.log(`${key} modified: ${values[key][0]} -> ${values[key][1]}`);
                            
                        } else if (key !== 'editTime' && key !== 'atd' && key !== 'etd' && key !== 'ata' && key !== 'eta' 
                            && (key === 'depLocode' || key === 'arrLocode' || key === 'route' || key === 'inPort' || key === 'type' || key === 'depCode') && !Object.is(state.data[key], NaN) && state.data[key] !== state.rowData[key2]) {
                            values[key] = [];
                            values[key][0] = state.rowData[key2];
                            values[key][1] = state.data[key];
                            console.log(`${key} modified: ${values[key][0]} -> ${values[key][1]}`);
                            if (key === 'inPort' && values[key][1].toString().includes(':')) {
                                const parts = values[key][1].split(':');
                                values[key][1] = Number.parseInt(parts[0]) * 3600 + Number.parseInt(parts[1]) * 60;
                                console.log('New inPort converted value is: ' + values[key][1] + ' seconds');
                            }
                        }
                    }
                }
            }
        }
        if (Object.keys(values).length === 0) {
            console.log('No changes. Cancel edit.');
            state.cancelEdit();
            return Promise.resolve(-1);
        }
        const requestData = { queryParams: { skip: state.state.skip, take: state.state.take, sort: sortQuery }, values, id: state.primaryKeyValue[0] };
        return axios.post(`${this.BASE_URL}/log/voyages`, requestData, this.requestOptions)
            .then(handleResponse)
            .then(() => {
                state.endEdit();
                return 1;
            }).catch((e) => {
                console.error(e);
                state.endEdit();
                return 0;
            });
    }

    delete(state) {
        const deleteId = state.data && state.data[0] && state.data[0].id && !isNaN(state.data[0].id) ? state.data[0].id : null;
        if (deleteId) {
            return axios.delete(`${this.BASE_URL}/log/voyages/${deleteId}`, this.requestOptions)
                .then(handleResponse)
                .then(() => {
                    state.endEdit();
                    return 1;
                }).catch((e) => {
                    console.error(e);
                    state.endEdit();
                    return 0;
                });
        } 
            return Promise.resolve(-1);
    }

    update(data) {
        return axios.post(`${this.BASE_URL}/log/voyages`, data, this.requestOptions)
            .then(handleResponse)
            .then(() => 1).catch((e) => {
                console.error(e);
                return 0;
            });
    }

    getParam(param) {
        return axios.get(`${this.BASE_URL}/log/${param}`, this.requestOptions)
            .then(handleResponse)
            .then((data) => data).catch((e) => {
                console.error(e);
                return 0;
            });
    }

    parseData(data, sortQuery) {
        return tools.parseLogVoyageData(data, null, sortQuery === '/etd desc' ? true : false);
    }
}
