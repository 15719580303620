<template>
  <div>
    <v-toolbar dense fixed flat height="65" class="mb-4">
      <v-text-field v-model="search" dense outlined :clearable="true" persistent-placeholder style="width:200px;margin-top:34px" @click:append="search=''" :append-icon="mdiMagnify" label="Search triggers" />
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider />
    <v-data-table :headers="headers" :items="triggers" :search="search" :single-expand="true" :expanded.sync="expanded"
                  item-key="id" :sort-by="sortBy" :sort-desc="sortDesc" :footer-props="{ showFirstLastPage: true}"
                  mobile-breakpoint="100">
<template #item="{item}">
        <tr>
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.templateName }} ({{ item.templateId }})</td>
          <td>{{ item.type }}</td>
          <td>{{ item.active }}</td>
        </tr>
      </template>
      <!-- <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-card>
            <v-card-actions>
              <v-btn text color="warn" @click="cancel();">
                Close
              </v-btn>
            </v-card-actions>
            <v-divider />
            <v-card class="pa-8" color="cardbg" elevation="1"><pre>{{ JSON.stringify(item, null, 2) }}</pre></v-card>
            <v-divider />
          </v-card>
        </td>
      </template> -->
      <v-alert slot="no-results" :value="true" color="error" icon="warning">
        Your search for "{{ search }}" found no results.
      </v-alert>
    </v-data-table>
</div>
</template>

<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import moment from 'moment';
  import {
    mdiMagnify,
    mdiCheck,
    mdiClose,
  } from '@mdi/js';

  export default {
    name: 'Roles',
    components: {

    },
    data: () => ({
      mdiMagnify,
      mdiCheck,
      mdiClose,
      search: '',
      sortBy: 'name',
      sortDesc: false,
      headers: [{
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Template',
          value: 'template'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Active',
          value: 'active'
        },
      ],
      expanded: [],
    }),
    created() {
      
    },
    watch: {
    },
    computed: {
      ...mapGetters({
        triggers: 'logbook/getTriggers',
      }),
    },
    methods: {
      ...mapActions({
      }),
      ...mapGetters({
      }),
      ...mapMutations({
        alertConfirm: 'alert/confirm',
        alertError: 'alert/error',
      }),
      // expandPanel(item) {
      //   this.expanded = item === this.expanded[0] ? [] : [item];
      // },
      // cancel() {
      //     this.expanded = [];
      // },
      formatDate(date) {
        if (date) {
          return moment.utc(date).local().format('D.M.Y HH:mm');
        }
        return '';
      },
      showBook(book) {
        return Array.isArray(book) ? book.join(', ') : '';
      }
    }
  };
</script>

<style>

</style>
<style scoped>

</style>