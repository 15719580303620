<template>
  <div>
    <div>
      <div>
        <v-toolbar dense fixed flat height="65" class="mb-4">
          <v-text-field v-model="search" dense outlined :clearable="true" persistent-placeholder
                        :append-icon="mdiMagnify" label="Filter records" style="width:200px;margin-top:34px" />
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div style="display:inline-block;width:170px;margin-top:34px">
            <v-menu ref="sdmenu" v-model="startDateMenu" :close-on-content-click="true" :return-value.sync="startDate"
                    transition="scale-transition" offset-y>
              <template #activator="{ on }">
                <v-text-field outlined v-model="startDate" dense persistent-placeholder label="Start date"
                              @change="dateFilter()" :clearable="true">
                  <template #prepend-inner>
                    <v-icon v-on="on" color="primary">{{ mdiCalendar }}</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker @change="$refs.sdmenu.save(startDate);dateFilter()" first-day-of-week="1" :show-week="true"
                             v-model="startDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.sdmenu.save(startDate)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <div style="display:inline-block;width:170px;margin-top:34px;margin-left:12px">
            <v-menu ref="edmenu" v-model="endDateMenu" :close-on-content-click="true" :return-value.sync="endDate"
                    transition="scale-transition" offset-y>
              <template #activator="{ on }">
                <v-text-field outlined v-model="endDate" dense persistent-placeholder label="End date"
                              @change="dateFilter()" :clearable="true">
                  <template #prepend-inner>
                    <v-icon v-on="on" color="primary">{{ mdiCalendar }}</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker @change="$refs.edmenu.save(endDate);dateFilter()" first-day-of-week="1" :show-week="true"
                             v-model="endDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="endDateMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.edmenu.save(endDate)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </v-toolbar>
        <v-divider></v-divider>
      </div>
      <v-card tile>
        <v-data-table dense :headers="headers" :items="auditLog" :search="search" mobile-breakpoint="900"
                      :custom-filter="customSearch" :options="options" class="record-table">
          <template #item="{item}">
            <tr>
              <td>{{ formatDate(item.time) }}</td>
              <td>{{ item.user }}</td>
              <td>{{ item.action }}</td>
              <td @click="openRecord(item)">{{ item.entryUuid }}</td>
              <td style="max-width: 350px;">{{ showData(item.data) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
  import moment from "moment";
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import {
    mdiReload,
    mdiMagnify,
    mdiCalendar,
    mdiWindowClose,
  } from '@mdi/js';

  export default {
    name: 'AuditLog',
    components: {
    },
    data: () => ({
      mdiReload,
      mdiMagnify,
      mdiCalendar,
      mdiWindowClose,
      logInterval: null,
      search: '',
      headers: [{
          text: 'Time UTC',
          align: 'start',
          value: 'time',
        },
        {
          text: 'User',
          value: 'user'
        },
         {
          text: 'Action',
          value: 'action'
        },
        {
          text: 'Entry ID',
          value: 'entryUuid'
        },
        {
          text: 'Data',
          value: 'data'
        },
      ],
      options: {
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        multiSort: true,
      },
      startDateMenu: false,
      endDateMenu: false,
      startDate: moment.utc().subtract(2, 'w').format('YYYY-MM-DD'),
      endDate: moment.utc().format('YYYY-MM-DD'),
    }),
    async created() {
      
    },
    beforeDestroy() {

    },
    watch: {
     
    },
    computed: {
      ...mapGetters({
        auditLog: 'logbook/getAuditLog',
        ship: 'logbook/getShip',
      }),
    },
    methods: {
      ...mapActions({
        fetchAuditLog: 'logbook/fetchAuditLog',
      }),
      ...mapGetters({
        
      }),
      ...mapMutations({
        
      }),
      formatDate(date, timezone) {
        if (timezone) {
          return moment.utc(date).utcOffset(timezone).format('DD.MM HH:mm');
        } else {
          return moment.utc(date).format('DD.MM HH:mm');
        }
      },
      dateFilter() {
        this.fetchAuditLog({
          logDb: this.ship.id,
          start: this.startDate,
          end: this.endDate
        });
      },
      customSearch(value, search, item) {
        let found = [];
        let words = search.split(' ');
        for (let i = 0, il = words.length; i < il; i++) {
          if (Object.values(item).some(v => v && v.toString().toLowerCase().includes(words[i]))) {
            found.push(true);
          }
        }
        return words.length === found.length;
      },
      openRecord(item) {
        console.log(item);
        // if (item.entryUuid) {
        //   const path = '/history/' + item.entryUuid;
        //   this.$router.push({
        //       path: path
        //     });
        // }
      },
      showData(data) {
        if (data) {
          try {
            if (typeof data === 'string') {
              data = JSON.parse(data);
            }
            let arr = [];
            for (const key in data) {
              if (Object.hasOwnProperty.call(data, key)) {
                arr.push(key +': ' + data[key]);
              }
            }
            return Array.isArray(arr) ? arr.join(', ') : '';
          } catch (e) {
            console.log('Failed to parse audit data JSON. ', e);
          }
        } else {
          return '';
        }
      }
    }
  };
</script>

<style>

</style>
<style scoped>

</style>