import { render, staticRenderFns } from "./PerfStatus.vue?vue&type=template&id=7e509e9f&scoped=true"
import script from "./PerfStatus.vue?vue&type=script&lang=js"
export * from "./PerfStatus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e509e9f",
  null
  
)

export default component.exports