import moment from 'moment';
import builder from 'xmlbuilder';
import { point } from '@turf/helpers';
import distance from '@turf/distance';

export function bvs(ship, data) {
    // console.log(data);
    const xml = builder
    .create('Voyage');
    xml.dec('1.0', 'UTF-8', true);
    xml.att('dir', 'AROS');
    const trackInfo = xml.ele('TrackInfo', {
            version: '7.1.0', 
            release: '5', 
            voyageId: '',
            trackCode: '',
            shipName: ship.name,
            callSign: ship.callSign ? ship.callSign : '',
            calmSeaSpeed: ship.normalSog ? ship.normalSog : '',
            charterPartySpeed: '',
            draft: '',
            beam: '',
            length: '',
            rpm: '',
            slip: '',
            ncrSpeed: '',
            powerRatio: '',
            ncrFuelRate: '',
            ncrPower: '',
            mtWave: '',
            mtParam: '',
            mtSync: '',
            mtBroach: '',
            navFrom: '',
        });

    const date = moment.utc();
    let dateString = date.format().replace('Z', '-00:00');
    const filename = `${ship.name}_${date.format()}.bvs`;
    
    for (const k in data) {
        if (Object.hasOwnProperty.call(data, k)) {
            for (const key in data[k].waypoints.coordinate) {
                if (Object.hasOwnProperty.call(data[k].waypoints.coordinate, key)) {
                    if (key > 0) {
                        // Calculate time difference between waypoints using normalSog
                        const from = point(data[k].waypoints.coordinate[key - 1]);
                        const to = point(data[k].waypoints.coordinate[key]);
                        const dist = distance(from, to) / 1.852;
                        // console.log('distance ' + dist);
                        ship.normalSog = ship.normalSog ? ship.normalSog : 20;
                        const timeDiff = dist / ship.normalSog;
                        // console.log('time diff ' + timeDiff);
                        date.add(timeDiff, 'h');
                        dateString = date.format().replace('Z', '-00:00');
                        // console.log(dateString)
                    }
                    const item = trackInfo.ele('Position', {
                        id: ''
                    });
                    if (key === 0) {
                        if (k > 0) { // Add 1 hour to following departures
                            date.add(1, 'h');
                            dateString = date.format().replace('Z', '-00:00');
                        }
                        if (k === 0) {
                            item.att('Type', 'BR');
                        } else {
                            item.att('Type', 'DP');
                        }
                        item.att('Date', dateString);
                        item.att('Lat', parseFloat(data[k].waypoints.coordinate[key][1]).toFixed(4));
                        item.att('Lon', parseFloat(data[k].waypoints.coordinate[key][0]).toFixed(4));
                        item.att('Navigation', 'RL');
                        item.att('Name', data[k].waypoints.waypointName[key]);
                        item.att('TimeFix', '1');
                        item.att('ControlType', 'SC');
                    } else if (key === data[k].waypoints.coordinate.length - 1) {
                        item.att('Type', 'AP');
                        if (k === data.length - 1) {
                            item.att('Type', 'ER');
                        } else {
                            item.att('Type', 'AP');
                        }
                        item.att('Date', dateString);
                        item.att('Lat', parseFloat(data[k].waypoints.coordinate[key][1]).toFixed(4));
                        item.att('Lon', parseFloat(data[k].waypoints.coordinate[key][0]).toFixed(4));
                        item.att('Navigation', 'RL');
                        item.att('Name', data[k].waypoints.waypointName[key]);
                        item.att('TimeFix', '1');
                        item.att('ControlType', 'STOP');
                    } else {
                        item.att('Type', 'WP');
                        item.att('Date', dateString);
                        item.att('Lat', parseFloat(data[k].waypoints.coordinate[key][1]).toFixed(4));
                        item.att('Lon', parseFloat(data[k].waypoints.coordinate[key][0]).toFixed(4));
                        item.att('Navigation', 'RL');
                        item.att('Name', data[k].waypoints.waypointName[key]);
                    }
                }
            }
        }
    }
    
    const xmlString = xml.end({
        pretty: true,
        indent: '\t',
        newline: '\r\n',
        allowEmpty: false,
        spacebeforeslash: ''
    });
    saveFile(xmlString, filename, 'text/xml;charset=utf-8');
}

function saveFile(data, filename, contentType) {
    let success = false;
    try {
        // Try using msSaveBlob if supported
        console.log('Trying saveBlob method ...');
        const blob = new Blob([data], { type: contentType });
        if (navigator.msSaveBlob) navigator.msSaveBlob(blob, filename);
        else {
            // Try using other saveBlob implementations, if available
            const saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
            if (saveBlob === undefined) {
                console.error('Not supported');
            }
            saveBlob(blob, filename);
        }
        console.log('saveBlob succeeded');
        success = true;
    } catch (ex) {
        console.log('saveBlob method failed with the following exception:');
        console.log(ex);
    }
    if (!success) {
        try {
            console.log('Trying click method ...');
            const blob = new Blob([data], { type: contentType });
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob, { type: contentType });
            a.download = filename;
            document.body.appendChild(a);
            a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a);
            console.log('Click method succeeded');
            success = true;
        } catch (ex) {
            console.log('Click method failed with the following exception:');
            console.log(ex);
        }
    }
    if (!success) {
        // Get the blob url creator
        const urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
        if (urlCreator) {
            // Try to use a download link
            const link = document.createElement('a');
            if ('download' in link) {
                // Try to simulate a click
                try {
                    // Prepare a blob URL
                    console.log('Trying download link method with simulated click ...');
                    const blob = new Blob([data], { type: contentType });
                    const url = urlCreator.createObjectURL(blob);
                    link.setAttribute('href', url);
                    // Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
                    link.setAttribute('download', filename);
                    // Simulate clicking the download link
                    const event = document.createEvent('MouseEvents');
                    event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                    link.dispatchEvent(event);
                    console.log('Download link method with simulated click succeeded');
                    success = true;
                } catch (ex) {
                    console.log('Download link method with simulated click failed with the following exception:');
                    console.log(ex);
                }
            }
            if (!success) {
                // Fallback to window.location method
                try {
                    // Prepare a blob URL
                    // Use application/octet-stream when using window.location to force download
                    console.log('Trying download link method with window.location ...');
                    const blob = new Blob([data], { type: contentType });
                    const url = urlCreator.createObjectURL(blob);
                    window.location = url;
                    console.log('Download link method with window.location succeeded');
                    success = true;
                } catch (ex) {
                    console.log('Download link method with window.location failed with the following exception:');
                    console.log(ex);
                }
            }
        }
    }
}
