<template>
    <div id="contacts">
        <v-card class="cardbglight">
            <v-data-table :headers="headers" :items="contacts" :search="search" :single-expand="true" :expanded.sync="expanded" item-key="id" :sort-by="sortBy"
            :sort-desc="sortDesc" :footer-props="{ showFirstLastPage: true}" mobile-breakpoint="100"
            class="elevation-1">
                <template #top>
                    <v-toolbar>
                        <v-toolbar-title>Contacts </v-toolbar-title>
                        <v-spacer />
                        <v-spacer />
                        <v-layout wrap>
                            <v-flex mt-6 xs4>
                                <v-select v-model="type" prepend-icon="contacts" :items="types" item-key="value" item-text="[text]" item-value="value" label="Contact type" @change="callFetchContacts()" />
                            </v-flex>
                            <v-flex mt-6 xs1>
                            </v-flex>
                            <v-flex mt-8 xs2>
                                <v-switch v-model="status" :false-value="0" :true-value="1" :label="status === 1 ? 'Active' : 'Inactive'" color="primary" hint="Status" @change="callFetchContacts()" persistent-hint />
                            </v-flex>
                            <v-flex mt-6 xs4>
                                <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details />
                            </v-flex>
                        </v-layout>
                    </v-toolbar>
                </template>
                <template #item="{item}">
                    <tr @click="expandPanel(item)">
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }} {{ item.locode }}</td>
                        <td>{{ item.pics }}</td>
                        <td>{{ item.phones }}</td>
                        <td style="max-width:300px">{{ item.emails }}</td>
                        <td>{{ item.aohs }}</td>
                        <td class="d-none d-md-table-cell">{{ item.author }}</td>
                        <td class="d-none d-md-table-cell" style="white-space:nowrap">{{ formatDate(item.updated) }}</td>
                    </tr>
                </template>
                <template #expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-0">    
                        <v-card class="cardbg primarytext--text">
                            <v-form v-model="saveValid" lazy-validation>
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="item.name" :rules="nameRules" required prepend-icon="perm_contact_calendar" label="Name"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="item.pics" prepend-icon="person" label="Pics"></v-text-field>
                                        </v-flex>
                                            <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="item.phones" prepend-icon="phone" label="Phones"></v-text-field>
                                        </v-flex>
                                            <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="item.emails" :rules="emailRules" prepend-icon="alternate_email" label="Emails"></v-text-field>
                                        </v-flex>
                                            <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="item.aohs" prepend-icon="nights_stay" label="Aohs"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="item.locode" prepend-icon="label" label="Locode"></v-text-field>
                                        </v-flex>
                                            <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="item.accountingId" prepend-icon="label" label="Accounting ID"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 md4>
                                            <v-select v-model="item.type" required :items="types" prepend-icon="contacts" item-text="text" item-value="value" label="Contact type"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs6 md2>
                                            <v-switch style="margin-top: 18px;" v-model="item.status" :false-value="0" :true-value="1" :label="item.status === 1 ? 'Active' : 'Inactive'" color="primary" hint="Status" persistent-hint />
                                        </v-flex>
                                    </v-layout>
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-btn color="error" @click="callDeleteContact(item)" :disabled="userLevel < 1">
Delete
                                            <v-icon right dark>delete_forever</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                        <v-btn color="primary" @click="saveContact(item)" :disabled="!saveValid || userLevel < 1">
Save
                                            <v-icon right dark>check</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-container>
                            </v-form>
                        </v-card>
                    </td>
                </template>
                <v-alert slot="no-results" :value="true" color="error" icon="warning">
                    Your search for "{{ search }}" found no results.
                </v-alert>
            </v-data-table>
            <v-expansion-panels :disabled="userLevel < 1" v-model="newContactPanel">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="teal">add_circle</v-icon> <span class="text-body-1 ml-2">Add new contact</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <v-card class="cardbg">
                            <v-form v-model="newValid" lazy-validation>
                                <v-container>
                                    <v-layout wrap>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="newContact.name" :rules="nameRules" prepend-icon="perm_contact_calendar" label="Name"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="newContact.pics" prepend-icon="person" label="Pics"></v-text-field>
                                        </v-flex>
                                            <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="newContact.phones" prepend-icon="phone" label="Phones"></v-text-field>
                                        </v-flex>
                                            <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="newContact.emails" :rules="emailRules" prepend-icon="alternate_email" label="Emails"></v-text-field>
                                        </v-flex>
                                            <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="newContact.aohs" prepend-icon="nights_stay" label="Aohs"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="newContact.locode" prepend-icon="label" label="Locode"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs12 sm6 md4>
                                            <v-text-field v-model="newContact.accountingId" prepend-icon="label" label="Accounting ID"></v-text-field>
                                        </v-flex>
                                        <v-flex px-4 xs6 md4>
                                            <v-select v-model="newContact.type" required :items="types" prepend-icon="contacts" item-text="text" item-value="value" label="Contact type"></v-select>
                                        </v-flex>
                                        <v-flex px-4 xs6 md2>
                                            <v-switch v-model="newContact.status" :false-value="0" :true-value="1" :label="newContact.status === 1 ? 'Active' : 'Inactive'" color="primary" hint="Status" persistent-hint />
                                        </v-flex> 
                                    </v-layout>
                                        <v-card-actions>
                                        <v-spacer />
                                        <v-btn text @click="cancel()">
Cancel
                                            <v-icon right dark>cancel</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                        <v-btn color="primary" @click="callAddContact(newContact)" :disabled="!newValid || !newContact.name">
Add
                                            <v-icon right dark>add_circle</v-icon>
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-container>
                            </v-form>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
             <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="primary">help</v-icon>
                            <span class="text-body-1 ml-2">What does this page show</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <br />
                        <p>Contact management</p>
                                <p>On this page you can add and edit contacts.</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
        <StatusDialog />
        <ProgressCircular />
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    import moment from 'moment';
    import StatusDialog from '../components/StatusDialog.vue';
    import ProgressCircular from '../components/ProgressCircular.vue';
    import { eventBus } from '../main';

    export default {
        name: 'Contacts',
        components: {
            StatusDialog,
            ProgressCircular,
        },
        data: () => ({
            search: '',
            sortBy: 'name',
            sortDesc: false,
            headers: [
                {
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Name',
                    value: 'name'
                },
                {
                    text: 'Pics',
                    value: 'pics'
                },
                {
                    text: 'Phones',
                    value: 'phones'
                },
                {
                    text: 'Emails',
                    value: 'emails'
                },
                {
                    text: 'Aohs',
                    value: 'aohs'
                },
                {
                    text: 'Author',
                    value: 'author'
                },
                {
                    text: 'Updated',
                    value: 'updated'
                }
            ],
            expanded: [],
            newContact: { name: '', emails: '' },
            newContactPanel: 1,
            contacts: [],
            type: 0,
            types: [
                { text: 'Customer', value: 0 },
                { text: 'Agent', value: 1 },
                { text: 'Supplier', value: 2 },
            ],
            status: 1,
            statuses: [
                { text: 'Inactive', value: 0 },
                { text: 'Active', value: 1 },
            ],
            deleteContactId: null,
            saveValid: true,
            newValid: true,
            rules: {
                required: (value) => !!value || 'Required.',
                min: (v) => (v ? v.length >= 8 || 'Min 8 characters' : ''),
            },
            nameRules: [
                (v) => !!v || 'Name is required',
            ],
            emailRules: [
                // v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
        }),
        mounted() {
            this.callFetchContacts();
        },
        computed: {
            userLevel() {
                return this.getUser().user.level;
            },
        },
        updated() {
            eventBus.$on('deleteContact', () => {
                if (this.deleteContactId) {
                    this.deleteContact(this.deleteContactId).then(() => {
                        setTimeout(() => {
                            this.callFetchContacts();
                        }, 100);
                    });
                    this.deleteContactId = null;
                }
            });
        },
        beforeDestroy() {
            eventBus.$off('deleteContact');
        },
        methods: {
            ...mapActions({
                fetchContacts: 'data/fetchContacts',
                saveContact: 'data/saveContact',
                deleteContact: 'data/deleteContact',
                addContact: 'data/addContact',
            }),
            ...mapGetters({
                getUser: 'authentication/getUser'
            }),
            ...mapMutations({
                alertConfirm: 'alert/confirm',
            }),
            callFetchContacts() {
                this.fetchContacts({ type: this.type, status: this.status }).then((data) => {
                    this.contacts = data;
                });
            },
            expandPanel(item) {
                this.expanded = item === this.expanded[0] ? [] : [item];
            },
            callDeleteContact(contact) {
                this.deleteContactId = contact.id;
                this.alertConfirm(
                    { message: `Are you sure you want to delete permanently ${contact.name}?`, emit: 'deleteContact' }
                );
            },
            callAddContact(contact) {
                this.addContact(contact).then(() => {
                    this.type = contact.type;
                    this.callFetchContacts();
                });
                this.cancel();
            },
            cancel() {
                this.newContactPanel = 1;
                this.newContact = {};
            },
            showStatus(value) {
                switch (value) {
                    case 0:
                        return 'Inactive';
                    case 1:
                        return 'Active';
                    default:
                        return 'Not set';
                }
            },
            formatDate(date) {
                if (date) {
                    return moment.utc(date).local().format('D.M.Y HH:mm');
                } 
                    return '';
            }
        }
    };
</script>

<style>
    .v-list--dense .v-list__tile:not(.v-list__tile--avatar) {
        height: 100%;
    }
    #contacts .v-messages {
        position: absolute;
        margin-top: -15px;
    }
</style>
<style scoped>
    .mypanel .v-expansion-panel__body {
        display: block !important; /* Dirty fix for expansion panel content */
    }
    tr.expand td {
        padding: 0 !important;
    }

    tr.expand .expansion-panel {
        box-shadow: none;
    }

    tr.expand .expansion-panel li {
        border: none;
    }
</style>
