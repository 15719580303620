<template>
    <v-expansion-panels v-model="panel.settings">
        <v-expansion-panel>
            <v-expansion-panel-header>
                <div>
                    <span class="text-body-1">{{ header }}</span>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="cardbg">
                <br />
                <v-card class="cardbg">
                    <v-container grid-list-lg>
                        <v-layout wrap>
                            <v-flex v-if="header === 'CPA/TCPA'" xs12 sm4 md3 lg2>
                                <v-select v-model="tcpa" :items="tcpas" item-key="value" item-text="[name]" item-value="value" label="CPA/TCPA" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Ukc'" xs12 sm4 md3 lg2>
                                <v-select v-model="ukc" :items="ukcs" item-key="value" item-text="[name]" item-value="value" label="UKC" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Rudder'" xs12 sm4 md3 lg2>
                                <v-select v-model="angle" :items="degreess" item-key="value" item-text="[name]" item-value="value" label="Rudder angle" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Rpm'" xs12 sm4 md3 lg2>
                                <v-text-field v-model="rpm" type="number" min="0" max="200" label="Max RPM" append-outer-icon="add" @click:append-outer="increment(true)" prepend-icon="remove" @click:prepend="decrement(true)"></v-text-field>
                            </v-flex>
                            <v-flex v-if="prefix === 'CrashStop'" xs12 sm4 md3 lg2>
                                <v-text-field v-model="rpm" type="number" min="-200" max="0" label="Negative RPM" append-outer-icon="add" @click:append-outer="increment(false)" prepend-icon="remove" @click:prepend="decrement(false)"></v-text-field>
                            </v-flex>
                            <v-flex v-if="header === 'Heading'" xs12 sm4 md3 lg2>
                                <v-select v-model="degrees" :items="degreess" item-key="value" item-text="[name]" item-value="value" label="Degrees" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="header === 'CPA/TCPA'" xs12 sm4 md3 lg2>
                                <v-select v-model="distance" :items="distancesTcpa" item-key="value" item-text="[name]" item-value="value" label="Distance" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Stop'" xs12 sm4 md3 lg2>
                                <v-select v-model="portDistance" :items="portDistances" item-key="value" item-text="[name]" item-value="value" label="Port distance" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="header === 'Active Route' || prefix === 'FinalWP'" xs12 sm4 md3 lg2>
                                <v-select v-model="distance" :items="distances" item-key="value" item-text="[name]" item-value="value" label="Distance" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="header === 'Proximity'" xs12 sm4 md3 lg2>
                                <v-select v-model="distance" :items="proximities" item-key="value" item-text="[name]" item-value="value" label="Distance" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Speeding' || prefix === 'Nowcast' || prefix === 'Ukc' || prefix === 'Rpm' || prefix === 'Rudder' || prefix === 'CrashStop' || prefix === 'Motion' || header === 'Active Route' || header === 'Heading' || header === 'Stop' || header === 'CPA/TCPA' || header === 'Proximity'" xs12 sm4 md3 lg2>
                                <v-select v-model="speed" :items="speeds" item-key="value" item-text="[name]" item-value="value" label="Speed" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="header === 'Route speed'" xs12 sm4 md3 lg2>
                                <v-select v-model="speedDrop" :items="speedDrops" item-key="value" item-text="[name]" item-value="value" label="Speed drop" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="header === 'Route speed'" xs12 sm4 md3 lg2>
                                <v-select v-model="speeding" :items="speedDrops" item-key="value" item-text="[name]" item-value="value" label="Speeding" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Tws'" xs12 sm4 md3 lg2>
                                <v-select v-model="portMedium" :items="winds" item-key="value" item-text="[name]" item-value="value" label="Port medium" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Tws'" xs12 sm4 md3 lg2>
                                <v-select v-model="portHigh" :items="winds" item-key="value" item-text="[name]" item-value="value" label="Port high" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Tws'" xs12 sm4 md3 lg2>
                                <v-select v-model="seaMedium" :items="winds" item-key="value" item-text="[name]" item-value="value" label="Sea medium" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Tws'" xs12 sm4 md3 lg2>
                                <v-select v-model="seaHigh" :items="winds" item-key="value" item-text="[name]" item-value="value" label="Sea high" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Tws'" xs12 sm4 md3 lg2>
                                <v-select v-model="aws" :items="winds" item-key="value" item-text="[name]" item-value="value" label="Apparent wind" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Tws'" xs12 sm4 md3 lg2>
                                <v-select v-model="seaSpeed" :items="speeds" item-key="value" item-text="[name]" item-value="value" label="Apparent SOG limit" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="header !== 'FinalWP' && prefix !== 'Nowcast' && header !== 'CPA/TCPA' && header !== 'AIS status' && header !== 'Area entry/exit' && prefix !== 'Tws' && prefix !== 'Forecast' && prefix !== 'Anchoring' && prefix !== 'Motion' && prefix !== 'Rudder' && prefix !== 'Rpm' && prefix !== 'CrashStop' && prefix !== 'IotData'" xs12 sm4 md3 lg2>
                                <v-select v-model="timeLimit" :items="timelimits" item-key="value" item-text="[name]" item-value="value" label="Sample limit" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'IotData'" xs12 sm4 md3 lg2>
                                <v-select v-model="timeLimit" :items="timeouts2" item-key="value" item-text="[name]" item-value="value" label="Offline limit" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Speeding' || prefix === 'Tws' || prefix === 'Ukc' || prefix === 'Rpm'" xs12 sm4 md3 lg2>
                                <v-select v-model="timeout" :items="timeouts" item-key="value" item-text="[name]" item-value="value" label="Timeout" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Nowcast' || prefix === 'IotData' || prefix === 'Anchoring'" xs12 sm4 md3 lg2>
                                <v-select v-model="timeout" :items="timeouts2" item-key="value" item-text="[name]" item-value="value" label="Timeout" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Nowcast'" xs12 sm4 md3 lg2>
                                <v-select v-model="wind" :items="winds" item-key="value" item-text="[name]" item-value="value" label="Wind" hint="Fleetwide" persistent-hint />
                            </v-flex>
                             <v-flex v-if="prefix === 'Nowcast'" xs12 sm4 md3 lg2>
                                <v-select v-model="wave" :items="waves" item-key="value" item-text="[name]" item-value="value" label="Wave" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Forecast'" xs12 sm4 md3 lg2>
                                <v-select v-model="windMedium" :items="winds" item-key="value" item-text="[name]" item-value="value" label="Wind medium" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Forecast'" xs12 sm4 md3 lg2>
                                <v-select v-model="windHigh" :items="winds" item-key="value" item-text="[name]" item-value="value" label="Wind high" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Forecast'" xs12 sm4 md3 lg2>
                                <v-select v-model="waveMedium" :items="waves" item-key="value" item-text="[name]" item-value="value" label="Wave medium" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Forecast'" xs12 sm4 md3 lg2>
                                <v-select v-model="waveHigh" :items="waves" item-key="value" item-text="[name]" item-value="value" label="Wave high" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Anchoring'" xs12 sm4 md3 lg2>
                                <v-select v-model="windSheltered" :items="winds" item-key="value" item-text="[name]" item-value="value" label="Wind (sheltered)" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Anchoring'" xs12 sm4 md3 lg2>
                                <v-select v-model="currentSheltered" :items="currents" item-key="value" item-text="[name]" item-value="value" label="Current (sheltered)" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Anchoring'" xs12 sm4 md3 lg2>
                                <v-select v-model="windOpen" :items="winds" item-key="value" item-text="[name]" item-value="value" label="Wind (open water)" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Anchoring'" xs12 sm4 md3 lg2>
                                <v-select v-model="currentOpen" :items="currents" item-key="value" item-text="[name]" item-value="value" label="Current (open water)" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Anchoring'" xs12 sm4 md3 lg2>
                                <v-select v-model="waveOpen" :items="waves" item-key="value" item-text="[name]" item-value="value" label="Wave (open water)" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Motion'" xs12 sm4 md3 lg2>
                                <v-select v-model="listMedium" :items="motions" item-key="value" item-text="[name]" item-value="value" label="List medium" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Motion'" xs12 sm4 md3 lg2>
                                <v-select v-model="listHigh" :items="motions" item-key="value" item-text="[name]" item-value="value" label="List high" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Motion'" xs12 sm4 md3 lg2>
                                <v-select v-model="rollMedium" :items="motions" item-key="value" item-text="[name]" item-value="value" label="Roll medium" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Motion'" xs12 sm4 md3 lg2>
                                <v-select v-model="rollHigh" :items="motions" item-key="value" item-text="[name]" item-value="value" label="Roll high" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Motion'" xs12 sm4 md3 lg2>
                                <v-select v-model="uIndex" :items="motions" item-key="value" item-text="[name]" item-value="value" label="Un-comfort" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Motion'" xs12 sm4 md3 lg2>
                                <v-select v-model="rotLow" :items="rots" item-key="value" item-text="[name]" item-value="value" label="ROT low" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Motion'" xs12 sm4 md3 lg2>
                                <v-select v-model="rotMedium" :items="rots" item-key="value" item-text="[name]" item-value="value" label="ROT medium" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Motion'" xs12 sm4 md3 lg2>
                                <v-select v-model="rotHigh" :items="rots" item-key="value" item-text="[name]" item-value="value" label="ROT high" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Motion'" xs12 sm4 md3 lg2>
                                <v-select v-model="swayMedium" :items="sways" item-key="value" item-text="[name]" item-value="value" label="Sway medium" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Motion'" xs12 sm4 md3 lg2>
                                <v-select v-model="swayHigh" :items="sways" item-key="value" item-text="[name]" item-value="value" label="Sway high" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex v-if="prefix === 'Ais'" xs12 sm4 md3 lg2>
                                <v-select v-model="alertType" multiple :items="aisTypes" item-key="value" item-text="[name]" item-value="value" label="Send alerts for" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex xs12 sm4 md3 lg2>
                                <v-select v-model="groupId" :items="groups" item-key="id" item-text="[name]" item-value="id" label="Alert group" hint="Fleetwide" persistent-hint />
                            </v-flex>
                            <v-flex xs12 sm4 md3 lg2 d-flex>
                                <v-spacer />
                                <v-switch v-model="enabled" :label="enabled ? 'On' : 'Off'" @change="toggleAllShipsEnabled()" color="primary" hint="Enabled?" persistent-hint />
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <v-card-actions>
                        <v-spacer />
                            <v-btn text @click="closePanel('settings')">cancel</v-btn>
                        <v-spacer />
                        <v-btn color="primary" @click="saveFleetWide()" :disabled="userLevel < 2">
Save
                            <v-icon right class="d-none d-sm-block" dark>check</v-icon>
                        </v-btn>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
                            <!-- ship specific settings -->
                            <v-expansion-panels v-model="panel.ship">
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <div>
                                            <span class="text-body-1">Ship specific settings</span>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-data-table :items-per-page="-1" :headers="headers" :items="settings" item-key="name" v-model="settings" class="elevation-1" hide-default-footer>
                                            <template slot-scope="props">
                                                <span>
                                                    {{ props.header }}
                                                </span>
                                            </template>
                                            <template #item="{item}">
                                                <tr v-if="item.enabled !== null">
                                                    <td>{{ item.name }}</td>
                                                    <td v-if="prefix !== 'Motion' && prefix !== 'FinalWP' && prefix !== 'Nowcast' && prefix !== 'Anchoring' && header !== 'CPA/TCPA' && header !== 'AIS status' && header !== 'Area entry/exit' && prefix !== 'Tws' && prefix !== 'Forecast' && prefix !== 'Rudder' && prefix !== 'Rpm' && prefix !== 'CrashStop' && prefix !== 'IotData'"> 
                                                        <v-select v-model="item.timeLimit" :items="timelimits" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'IotData'">
                                                        <v-select v-model="item.timeLimit" :items="timeouts2" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="header === 'CPA/TCPA'">
                                                        <v-select v-model="item.tcpa" :items="tcpas" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="header === 'Heading'">
                                                        <v-select v-model="item.degrees" :items="degreess" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="header === 'CPA/TCPA'">
                                                        <v-select v-model="item.distance" :items="distancesTcpa" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Stop'">
                                                        <v-select v-model="item.portDistance" :items="portDistances" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Ukc'">
                                                        <v-select v-model="item.ukc" :items="ukcs" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Rudder'">
                                                        <v-select v-model="item.angle" :items="degreess" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Rpm'">
                                                        <v-text-field v-model="item.rpm" type="number" min="0" max="200" item-key="value" item-text="[name]" item-value="value"></v-text-field>
                                                    </td>
                                                    <td v-if="prefix === 'CrashStop'">
                                                        <v-text-field v-model="item.rpm" type="number" min="-200" max="0" item-key="value" item-text="[name]" item-value="value"></v-text-field>
                                                    </td>
                                                    <td v-if="header === 'Proximity'">
                                                        <v-select v-model="item.distance" :items="proximities" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="header === 'Active Route' || prefix === 'FinalWP'">
                                                        <v-select v-model="item.distance" :items="distances" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Nowcast' || prefix === 'Speeding' || prefix === 'Ukc' || prefix === 'Rudder' || prefix === 'Rpm' || prefix === 'CrashStop' || header === 'Active Route' || header === 'Heading' || header === 'Stop' || header === 'CPA/TCPA' || header === 'Proximity' || prefix === 'Motion'">
                                                        <v-select v-model="item.speed" :items="speeds" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="header === 'Route speed'">
                                                        <v-select v-model="item.speedDrop" :items="speedDrops" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="header === 'Route speed'">
                                                        <v-select v-model="item.speeding" :items="speedDrops" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Tws'">
                                                        <v-select v-model="item.portMedium" :items="winds" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Tws'">
                                                        <v-select v-model="item.portHigh" :items="winds" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Tws'">
                                                        <v-select v-model="item.seaMedium" :items="winds" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Tws'">
                                                        <v-select v-model="item.seaHigh" :items="winds" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Tws'">
                                                        <v-select v-model="item.aws" :items="winds" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Tws'">
                                                        <v-select v-model="item.seaSpeed" :items="speeds" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Speeding' || prefix === 'Ukc' || prefix === 'Rpm' || prefix === 'Tws'">
                                                        <v-select v-model="item.timeout" :items="timeouts" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                     <td v-if="prefix === 'Nowcast' || prefix === 'IotData' || prefix === 'Anchoring'">
                                                        <v-select v-model="item.timeout" :items="timeouts2" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Forecast'">
                                                        <v-select v-model="item.windMedium" :items="winds" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Forecast'">
                                                        <v-select v-model="item.windHigh" :items="winds" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Forecast'">
                                                        <v-select v-model="item.waveMedium" :items="waves" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Forecast'">
                                                        <v-select v-model="item.waveHigh" :items="waves" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Nowcast'">
                                                        <v-select v-model="item.wind" :items="winds" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Nowcast'">
                                                        <v-select v-model="item.wave" :items="waves" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Anchoring'">
                                                        <v-select v-model="item.windSheltered" :items="winds" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Anchoring'">
                                                        <v-select v-model="item.currentSheltered" :items="currents" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Anchoring'">
                                                        <v-select v-model="item.windOpen" :items="winds" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Anchoring'">
                                                        <v-select v-model="item.currentOpen" :items="currents" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Anchoring'">
                                                        <v-select v-model="item.waveOpen" :items="waves" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Motion'">
                                                        <v-select v-model="item.listMedium" :items="motions" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Motion'">
                                                        <v-select v-model="item.listHigh" :items="motions" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Motion'">
                                                        <v-select v-model="item.rollMedium" :items="motions" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Motion'">
                                                        <v-select v-model="item.rollHigh" :items="motions" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Motion'">
                                                        <v-select v-model="item.uIndex" :items="motions" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Motion'">
                                                        <v-select v-model="item.rotLow" item-key="value" item-text="[name]" item-value="value" :items="rots" />
                                                    </td>
                                                    <td v-if="prefix === 'Motion'">
                                                        <v-select v-model="item.rotMedium" item-key="value" item-text="[name]" item-value="value" :items="rots" />
                                                    </td>
                                                    <td v-if="prefix === 'Motion'">
                                                        <v-select v-model="item.rotHigh" item-key="value" item-text="[name]" item-value="value" :items="rots" />
                                                    </td>
                                                    <td v-if="prefix === 'Motion'">
                                                        <v-select v-model="item.swayMedium" item-key="value" item-text="[name]" item-value="value" :items="sways" />
                                                    </td>
                                                    <td v-if="prefix === 'Motion'">
                                                        <v-select v-model="item.swayHigh" :items="sways" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td v-if="prefix === 'Ais'">
                                                        <v-select v-model="item.alertType" multiple :items="aisTypes" item-key="value" item-text="[name]" item-value="value" />
                                                    </td>
                                                    <td>
                                                        <v-select v-model="item.groupId" :items="groups" item-key="id" item-text="[name]" item-value="id" />
                                                    </td>
                                                    <td>
                                                        <v-checkbox v-model="item.enabled" primary color="teal" />
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                        <v-card-actions>
                                            <v-spacer />
                                            <v-btn text @click="closePanel('ship')">cancel</v-btn>
                                            <v-spacer />
                                            <v-btn color="primary" @click="saveShipSpecific()" :disabled="userLevel < 2">
Save
                                                <v-icon right class="d-none d-sm-block" dark>check</v-icon>
                                            </v-btn>
                                            <v-spacer />
                                        </v-card-actions>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-icon medium color="teal">help</v-icon>
                                            <span class="text-body-1 ml-2">How does this event work?</span>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="cardbg">
                                        <br />
                                        <p style="white-space: pre-line"> {{ description }}</p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex';

export default {
    name: 'BoqaSettingsCard',
    props: {
        header: {
            type: String,
            required: true,
        },
        prefix: {
            type: String,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        description: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            timeLimit: null,
            distance: null,
            speed: null,
            degrees: null,
            speedDrop: null,
            speeding: null,
            tcpa: null,
            portMedium: null,
            portHigh: null,
            seaMedium: null,
            seaHigh: null,
            aws: null,
            seaSpeed: null,
            portDistance: null,
            timeout: null,
            windMedium: null,
            windHigh: null,
            waveMedium: null,
            waveHigh: null,
            wind: null,
            wave: null,
            windSheltered: null,
            currentSheltered: null,
            windOpen: null,
            currentOpen: null,
            waveOpen: null,
            listMedium: null,
            listHigh: null,
            rollMedium: null,
            rollHigh: null,
            uIndex: null,
            rotLow: null,
            rotMedium: null,
            rotHigh: null,
            swayMedium: null,
            swayHigh: null,
            alertType: null,
            groupId: null,
            enabled: null,
            ukc: null,
            angle: null,
            rpm: 0,
            timelimits: [
                { value: 0, name: '0' },
                { value: 1, name: '1' },
                { value: 2, name: '2' },
                { value: 3, name: '3' },
                { value: 4, name: '4' },
                { value: 5, name: '5' },
                { value: 6, name: '6' },
                { value: 7, name: '7' },
                { value: 8, name: '8' },
                { value: 9, name: '9' },
                { value: 10, name: '10' },
                { value: 12, name: '12' },
                { value: 15, name: '15' },
                { value: 20, name: '20' },
                { value: 30, name: '30' },
                { value: 40, name: '40' },
                { value: 50, name: '50' },
                { value: 60, name: '60' },
                { value: 70, name: '70' },
                { value: 80, name: '80' },
                { value: 90, name: '90' },
                { value: 100, name: '100' },
                { value: 120, name: '120' },
                { value: 140, name: '140' },
                { value: 160, name: '160' },
                { value: 180, name: '180' },
                { value: 200, name: '200' },
            ],
            distances: [
                { value: 0.1, name: '0.1 NM' },
                { value: 0.2, name: '0.2 NM' },
                { value: 0.3, name: '0.3 NM' },
                { value: 0.4, name: '0.4 NM' },
                { value: 0.5, name: '0.5 NM' },
                { value: 1, name: '1 NM' },
                { value: 2, name: '2 NM' },
                { value: 3, name: '3 NM' },
                { value: 4, name: '4 NM' },
                { value: 5, name: '5 NM' },
                { value: 6, name: '6 NM' },
                { value: 7, name: '7 NM' },
                { value: 8, name: '8 NM' },
                { value: 9, name: '9 NM' },
                { value: 10, name: '10 NM' },
                { value: 12, name: '12 NM' },
                { value: 15, name: '15 NM' },
                { value: 20, name: '20 NM' },
                { value: 30, name: '30 NM' },
                { value: 40, name: '40 NM' },
                { value: 50, name: '50 NM' },
                { value: 75, name: '75 NM' },
                { value: 100, name: '100 NM' },
            ],
            proximities: [
                { value: '0.10', name: '0.10 NM' },
                { value: '0.15', name: '0.15 NM' },
                { value: '0.20', name: '0.20 NM' },
            ],
            aisTypes: [
                { value: 11, name: 'N.U.C.' },
                { value: 12, name: 'Rest.Man.' },
                { value: 13, name: 'Rest.Draught' },
                { value: 14, name: 'Aground' },
            ],
            distancesTcpa: [
                { value: 0.5, name: '0.5 NM' },
                { value: 1.0, name: '1.0 NM' },
                { value: 1.5, name: '1.5 NM' },
            ],
            portDistances: [
                { value: 0.5, name: '0.5 NM' },
                { value: 1, name: '1.0 NM' },
                { value: 1.5, name: '1.5 NM' },
                { value: 2, name: '2.0 NM' },
                { value: 2.5, name: '2.5 NM' },
                { value: 3, name: '3.0 NM' },
                { value: 3.5, name: '3.5 NM' },
                { value: 4, name: '4.0 NM' },
                { value: 4.5, name: '4.5 NM' },
                { value: 5, name: '5.0 NM' },
            ],
            speeds: [
                { value: 0, name: '0 kn' },
                { value: 0.2, name: '0.2 kn' },
                { value: 0.5, name: '0.5 kn' },
                { value: 1, name: '1 kn' },
                { value: 2, name: '2 kn' },
                { value: 3, name: '3 kn' },
                { value: 4, name: '4 kn' },
                { value: 5, name: '5 kn' },
                { value: 6, name: '6 kn' },
                { value: 7, name: '7 kn' },
                { value: 8, name: '8 kn' },
                { value: 9, name: '9 kn' },
                { value: 10, name: '10 kn' },
                { value: 11, name: '11 kn' },
                { value: 12, name: '12 kn' },
                { value: 13, name: '13 kn' },
                { value: 14, name: '14 kn' },
                { value: 15, name: '15 kn' },
                { value: 16, name: '16 kn' },
                { value: 17, name: '17 kn' },
                { value: 18, name: '18 kn' },
                { value: 19, name: '19 kn' },
                { value: 20, name: '20 kn' },
                { value: 21, name: '21 kn' },
                { value: 22, name: '22 kn' },
                { value: 23, name: '23 kn' },
                { value: 24, name: '24 kn' },
                { value: 25, name: '25 kn' },
                { value: 26, name: '25 kn' },
                { value: 27, name: '27 kn' },
                { value: 28, name: '28 kn' },
                { value: 29, name: '29 kn' },
                { value: 30, name: '30 kn' },
                { value: 31, name: '31 kn' },
                { value: 32, name: '32 kn' },
                { value: 33, name: '33 kn' },
                { value: 34, name: '34 kn' },
                { value: 35, name: '35 kn' },
                { value: 36, name: '36 kn' },
                { value: 37, name: '37 kn' },
                { value: 38, name: '38 kn' },
                { value: 39, name: '39 kn' },
                { value: 40, name: '40 kn' },
            ],
            tcpas: [
                { value: 5, name: '5 min' },
                { value: 10, name: '10 min' },
                { value: 15, name: '15 min' },
            ],
            speedDrops: [
                { value: 0, name: '0 %' },
                { value: 5, name: '5 %' },
                { value: 10, name: '10 %' },
                { value: 25, name: '25 %' },
                { value: 50, name: '50 %' },
                { value: 75, name: '75 %' },
                { value: 90, name: '90 %' },
            ],
            degreess: [
                { value: 5, name: '5°' },
                { value: 10, name: '10°' },
                { value: 15, name: '15°' },
                { value: 20, name: '20°' },
                { value: 25, name: '25°' },
                { value: 30, name: '30°' },
                { value: 35, name: '35°' },
                { value: 40, name: '40°' },
                { value: 45, name: '45°' },
                { value: 50, name: '50°' },
                { value: 55, name: '55°' },
                { value: 60, name: '60°' },
                { value: 65, name: '65°' },
                { value: 70, name: '70°' },
            ],
            winds: [
                { value: 4, name: '4 m/s' },
                { value: 5, name: '5 m/s' },
                { value: 6, name: '6 m/s' },
                { value: 7, name: '7 m/s' },
                { value: 8, name: '8 m/s' },
                { value: 9, name: '9 m/s' },
                { value: 10, name: '10 m/s' },
                { value: 11, name: '11 m/s' },
                { value: 12, name: '12 m/s' },
                { value: 13, name: '13 m/s' },
                { value: 14, name: '14 m/s' },
                { value: 15, name: '15 m/s' },
                { value: 16, name: '16 m/s' },
                { value: 17, name: '17 m/s' },
                { value: 18, name: '18 m/s' },
                { value: 19, name: '19 m/s' },
                { value: 20, name: '20 m/s' },
                { value: 21, name: '21 m/s' },
                { value: 22, name: '22 m/s' },
                { value: 23, name: '23 m/s' },
                { value: 24, name: '24 m/s' },
                { value: 25, name: '25 m/s' },
                { value: 26, name: '26 m/s' },
                { value: 27, name: '27 m/s' },
                { value: 28, name: '28 m/s' },
                { value: 29, name: '29 m/s' },
                { value: 30, name: '30 m/s' },
                { value: 31, name: '31 m/s' },
                { value: 32, name: '32 m/s' },
                { value: 33, name: '33 m/s' },
                { value: 34, name: '34 m/s' },
                { value: 35, name: '35 m/s' },
                { value: 36, name: '36 m/s' },
                { value: 37, name: '37 m/s' },
                { value: 38, name: '38 m/s' },
                { value: 39, name: '39 m/s' },
                { value: 40, name: '40 m/s' },
                { value: 41, name: '41 m/s' },
                { value: 42, name: '42 m/s' },
                { value: 43, name: '43 m/s' },
                { value: 44, name: '44 m/s' },
                { value: 45, name: '45 m/s' },
                { value: 46, name: '46 m/s' },
                { value: 47, name: '47 m/s' },
                { value: 48, name: '48 m/s' },
                { value: 49, name: '49 m/s' },
                { value: 50, name: '50 m/s' },
            ],
            currents: [
                { value: 0.5, name: '0.5 m/s' },
                { value: 1, name: '1 m/s' },
                { value: 1.5, name: '1.5 m/s' },
                { value: 2, name: '2 m/s' },
                { value: 2.5, name: '2.5 m/s' },
                { value: 3, name: '3 m/s' },
                { value: 3.5, name: '3.5 m/s' },
                { value: 4, name: '4 m/s' },
                { value: 4.5, name: '4.5 m/s' },
                { value: 5, name: '5 m/s' },
            ],
            waves: [
                { value: 1, name: '1 m' },
                { value: 1.5, name: '1.5 m' },
                { value: 2, name: '2 m' },
                { value: 2.5, name: '2.5 m' },
                { value: 3, name: '3 m' },
                { value: 3.5, name: '3.5 m' },
                { value: 4, name: '4 m' },
                { value: 4.5, name: '4.5 m' },
                { value: 5, name: '5 m' },
                { value: 5.5, name: '5.5 m' },
                { value: 6, name: '6 m' },
                { value: 6.5, name: '6.5 m' },
                { value: 7, name: '7 m' },
                { value: 7.5, name: '7.5 m' },
                { value: 8, name: '8 m' },
                { value: 8.5, name: '8.5 m' },
                { value: 9, name: '9 m' },
                { value: 9.5, name: '9.5 m' },
                { value: 10, name: '10 m' },
                { value: 10.5, name: '10.5 m' },
                { value: 11, name: '11 m' },
                { value: 11.5, name: '11.5 m' },
                { value: 12, name: '12 m' },
                { value: 12.5, name: '12.5 m' },
                { value: 13, name: '13 m' },
                { value: 13.5, name: '13.5 m' },
                { value: 14, name: '14 m' },
                { value: 14.5, name: '14.5 m' },
                { value: 15, name: '15 m' },
            ],
            timeouts: [
                { value: 10, name: '10 min' },
                { value: 20, name: '20 min' },
                { value: 30, name: '30 min' },
                { value: 40, name: '40 min' },
                { value: 50, name: '50 min' },
                { value: 60, name: '60 min' },
                { value: 90, name: '90 min' },
                { value: 120, name: '2 hours' },
                { value: 180, name: '3 hours' },
                { value: 240, name: '4 hours' },
                { value: 300, name: '5 hours' },
            ],
            timeouts2: [
                { value: 30, name: '30 mins' },
                { value: 60, name: '1 hour' },
                { value: 120, name: '2 hours' },
                { value: 180, name: '3 hours' },
                { value: 240, name: '4 hours' },
                { value: 300, name: '5 hours' },
                { value: 360, name: '6 hours' },
                { value: 540, name: '9 hours' },
                { value: 720, name: '12 hours' },
                { value: 1080, name: '18 hours' },
                { value: 1440, name: '24 hours' },
            ],
            motions: [
                { value: 1, name: '1°' },
                { value: 2, name: '2°' },
                { value: 3, name: '3°' },
                { value: 4, name: '4°' },
                { value: 5, name: '5°' },
                { value: 6, name: '6°' },
                { value: 7, name: '7°' },
                { value: 8, name: '8°' },
                { value: 9, name: '9°' },
                { value: 10, name: '10°' },
                { value: 11, name: '11°' },
                { value: 12, name: '12°' },
                { value: 13, name: '13°' },
                { value: 14, name: '14°' },
                { value: 15, name: '15°' },
                { value: 16, name: '16°' },
            ],
            rots: [
                { value: 5, name: '5°/min' },
                { value: 10, name: '10°/min' },
                { value: 15, name: '15°/min' },
                { value: 20, name: '20°/min' },
                { value: 25, name: '25°/min' },
                { value: 30, name: '30°/min' },
                { value: 35, name: '35°/min' },
                { value: 40, name: '40°/min' },
                { value: 45, name: '45°/min' },
                { value: 50, name: '50°/min' },
            ],
            sways: [
                { value: 1, name: '1 m/s2' },
                { value: 1.5, name: '1.5 m/s2' },
                { value: 2, name: '2 m/s2' },
                { value: 3, name: '3 m/s2' },
                { value: 4, name: '4 m/s2' },
                { value: 5, name: '5 m/s2' },
                { value: 6, name: '6 m/s2' },
                { value: 7, name: '7 m/s2' },
                { value: 8, name: '8 m/s2' },
                { value: 9, name: '9 m/s2' },
                { value: 10, name: '10 m/s2' },
                { value: 11, name: '11 m/s2' },
                { value: 12, name: '12 m/s2' },
            ],
            ukcs: [
                { value: 1, name: '1 m' },
                { value: 2, name: '2 m' },
                { value: 3, name: '3 m' },
                { value: 4, name: '4 m' },
                { value: 5, name: '5 m' },
                { value: 6, name: '6 m' },
                { value: 7, name: '7 m' },
                { value: 8, name: '8 m' },
                { value: 9, name: '9 m' },
                { value: 10, name: '10 m' },
                { value: 11, name: '11 m' },
                { value: 12, name: '12 m' },
                { value: 13, name: '13 m' },
                { value: 14, name: '14 m' },
                { value: 15, name: '15 m' },
                { value: 16, name: '16 m' },
                { value: 17, name: '17 m' },
                { value: 18, name: '18 m' },
                { value: 19, name: '19 m' },
                { value: 20, name: '20 m' },
                { value: 21, name: '21 m' },
                { value: 22, name: '22 m' },
                { value: 23, name: '23 m' },
                { value: 24, name: '24 m' },
                { value: 25, name: '25 m' },
            ],
            settings: [],
            panel: {},
        };
    },
    mounted() {
        this.fetchSettings(this.prefix).then((data) => {
            this.settings = data;
            // check fleetwide settings. If every ship has same value, display fleetwide selected value
            const fleetwide = {};
            for (const key in this.settings) {
                if (this.settings.hasOwnProperty(key) && key > 0) {
                    for (const key2 in this.settings[key]) {
                        if (this.settings[key].hasOwnProperty(key2)) {
                            if (this.prefix === 'Ais') {
                                if (key2 === 'alertType') {
                                    if (this.settings[key - 1][key2].toString() !== this.settings[key][key2].toString()) {
                                        fleetwide[key2] = false;    
                                    }
                                } else if (this.settings[key - 1][key2] !== null && this.settings[key][key2] !== null && this.settings[key - 1][key2] !== this.settings[key][key2]) {
                                    fleetwide[key2] = false;
                                }
                            } else if (this.settings[key - 1][key2] !== null && this.settings[key][key2] !== null && this.settings[key - 1][key2] !== this.settings[key][key2]) {
                                fleetwide[key2] = false;
                            } 
                        }
                    }
                }
            }
            for (const key in this.settings[0]) {
                if (this.settings[0].hasOwnProperty(key)) {
                    if (typeof fleetwide[key] === 'undefined') {
                        this[key] = this.settings[0][key];
                    }
                }
            }
        });
    },
    computed: {
        groups() {
            return this.getGroups();
        },
        userLevel() {
            return this.getUser().user.level;
        },
    },
    methods: {
        ...mapGetters({
            getSettings: 'data/getSettings',
            getGroups: 'users/getGroups',
            getUser: 'authentication/getUser',
        }),
        ...mapActions({
            fetchSettings: 'data/fetchSettings',
            saveSettings: 'data/saveSettings'
        }),
        // callGetSettings(e) {
        //     if (e.target.className === 'v-expansion-panel__header' || e.target.offsetParent.className === 'v-expansion-panel__header') {
        //         console.log(this.prefix);
        //         this.settings = this.getSettings()[this.prefix];
        //         // check fleetwide settings. If every ship has same value, display fleetwide selected value
        //         let fleetwide = {};
        //         for (const key in this.settings) {
        //             if (this.settings.hasOwnProperty(key) && key > 0) {
        //                 for (const key2 in this.settings[key]) {
        //                     if (this.settings[key].hasOwnProperty(key2)) {
        //                         if (this.settings[key-1][key2] !== this.settings[key][key2]) {
        //                             fleetwide[key2] = false;
        //                         } 
        //                     }
        //                 }
        //             }
        //         }
        //         for (const key in this.settings[0]) {
        //             if (this.settings[0].hasOwnProperty(key)) {
        //                 if (typeof fleetwide[key] === 'undefined') {
        //                     this[key] = this.settings[0][key];
        //                 }
                        
        //             }
        //         }
        //     }
        // },
        toggleAllShipsEnabled() {
            this.settings = this.settings.map((o) => {
                o.enabled = this.enabled;
                return o;
            });
        },
        saveFleetWide() {
            // Copy fleetwide settings to every ship
            for (const key in this.settings) {
                if (this.settings.hasOwnProperty(key)) {
                    for (const key2 in this.settings[key]) {
                        if (this.settings[key].hasOwnProperty(key2)) {
                            if (typeof this[key2] !== 'undefined') {
                                this.settings[key][key2] = this[key2];
                            }
                        }
                    }
                }
            }
            this.saveShipSpecific();
        },
        saveShipSpecific() {
            this.saveSettings({ settings: this.settings, prefix: this.prefix });
        },
        increment(positive) {
            if ((positive && this.rpm < 200) || (!positive && this.rpm < 0)) {
                this.rpm = parseInt(this.rpm, 10) + 1;
            }
        },
        decrement(positive) {
            if ((positive && this.rpm > 0) || (!positive && this.rpm > -200)) {
                this.rpm = parseInt(this.rpm, 10) - 1;
            }
        },
        closePanel(panel) {
            if (panel === 'ship') {
                this.panel.ship = null;
            } else if (panel === 'settings') {
                this.panel.settings = null;
            }
        }
    }
};
</script>
