<template>
    <v-app :dark="dark">
        <!-- Default -->
        <v-app-bar id="topnav" color="navbar" dense class="elevation-4">
            <img v-if="!loginStatus.loggedIn" :class="'login-logo-' + env('VUE_APP_NAME')" :style="'display:inline-block;width: ' + env('VUE_APP_LOGO_WIDTH2')" :src="getImgUrl(env('VUE_APP_LOGO'))" :alt="env('VUE_APP_TITLE')" />
            <router-link v-if="loginStatus.loggedIn" :to="{name: 'Dashboard'}" :style="'display:inline-block;width: ' + env('VUE_APP_LOGO_WIDTH')">
                <img :src="getImgUrl(env('VUE_APP_LOGO'))" :alt="env('VUE_APP_TITLE')"
                    :class="'logo-' + env('VUE_APP_NAME')" />
            </router-link>
            <v-toolbar-items v-if="loginStatus.loggedIn">
                <v-btn class="white--text" to="/dashboard" text>
                    <v-icon medium color="logo" class="pr-1">dashboard</v-icon> <span class="hidden-md-and-down">Dashboard</span>
                </v-btn>
                <v-btn class="white--text" to="/events" text>
                    <v-icon medium color="logo" class="pr-1">event_note</v-icon> <span
                        class="hidden-md-and-down">Events</span>
                </v-btn>
                <v-btn class="white--text" to="/map" text>
                    <v-icon medium color="logo" class="pr-1">map</v-icon> <span class="hidden-md-and-down">Map</span>
                </v-btn>
                <v-btn v-if="typeof features.scheduler !== 'undefined' && features.scheduler === 1" class="white--text" to="/desk" text>
                    <v-icon medium color="logo" class="pr-1">assessment</v-icon> <span
                        class="hidden-md-and-down">Desk</span>
                </v-btn>
                <v-btn v-if="typeof features.routes !== 'undefined' && features.routes === 1" class="white--text hidden-sm-and-down" to="/routes" text>
                    <v-icon medium color="logo" class="pr-1">timeline</v-icon> <span
                        class="hidden-md-and-down">Routes</span>
                </v-btn>
                <v-menu open-on-hover bottom offset-y>
                    <template #activator="{ on }">
                        <v-btn class="white--text hidden-sm-and-down" text v-on="on">
                            <v-icon medium color="logo" class="pr-1">settings</v-icon> <span class="hidden-md-and-down">Settings</span>
                        </v-btn>
                    </template>
                    <v-list>
                        <span v-for="(item, index) in settings" :key="index">
                            <v-list-item :to="item.path" v-if="item.feature !== 'scheduler' || (typeof features.scheduler !== 'undefined' && features.scheduler === 1)">
                                <v-icon color="logo" class="pr-1" style="margin-right:5px"> {{ item.icon }}</v-icon> 
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item>
                        </span>
                    </v-list>
                </v-menu>
                <v-btn class="white--text hidden-md-and-down" to="/analytics" text>
                    <v-icon medium color="logo" class="pr-1">show_chart</v-icon> <span
                        class="hidden-md-and-down">AIS Analytics</span>
                </v-btn>
                <v-btn v-show="features && features.performance === 1" class="white--text" to="/performance" text>
                    <v-icon medium color="logo" class="pr-1">multiline_chart</v-icon> <span
                        class="hidden-md-and-down">Performance</span>
                </v-btn>
                <v-btn v-show="features && features.logbook === 1" class="white--text" to="/log" text>
                    <v-icon medium color="logo" class="pr-1">menu_book</v-icon> <span
                        class="hidden-md-and-down">Log</span>
                </v-btn>
            </v-toolbar-items>
           
            <v-spacer />
            <v-btn class="hidden-sm-and-down" v-if="loginStatus.loggedIn" icon>
                <v-icon class="white--text" @click="toggleMode()" :title="'Switch day/night mode'">
                    invert_colors
                </v-icon>
            </v-btn>
            <router-link v-if="!loginStatus.loggedIn" to="/login">
                <v-btn icon disabled>
                    <v-icon class="green--text" title="Login">person</v-icon>
                </v-btn>
            </router-link>
            <!-- <v-btn class="hidden-md-and-down" v-if="loginStatus.loggedIn" icon>
                <v-icon class="green--text" @click="logout" :title="'Logged in as ' + getUser.user.name + '. Click to logout.'">person</v-icon>
            </v-btn> -->
            <v-menu v-if="loginStatus.loggedIn">
                <template #activator="{ on }">
                    <v-btn v-on="on" icon><v-icon class="white--text">more_vert</v-icon></v-btn>
                </template>
                <v-list>
                    <span v-for="(view) in views" :key="view.path">
                        <v-list-item :to="view.path" v-if="(view.feature !== 'scheduler' || (typeof features.scheduler !== 'undefined' && features.scheduler === 1)) && (view.feature !== 'routes' || (typeof features.routes !== 'undefined' && features.routes === 1))">
                            <v-icon color="logo" class="pr-1" style="margin-right:5px"> {{ view.icon }}</v-icon>
                            <v-list-item-title v-text="view.name" />
                        </v-list-item>
                    </span>
                    <span v-for="(view) in settings" :key="view.path">
                        <v-list-item :to="view.path" v-if="view.feature !== 'scheduler' || (typeof features.scheduler !== 'undefined' && features.scheduler === 1)">
                            <v-icon color="logo" class="pr-1" style="margin-right:5px"> {{ view.icon }}</v-icon>
                            <v-list-item-title v-text="view.name" />
                        </v-list-item>
                    </span>
                    <v-list-item class="hidden-md-and-up">
                        <v-icon color="logo" class="pr-1" style="margin-right:5px"> invert_colors</v-icon>
                        <v-list-item-title text="Switch mode" @click="toggleMode()" title="Switch day/night mode">
                            Switch mode
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-icon color="logo" class="pr-1" style="margin-right:5px"> person_outline</v-icon>
                        <v-list-item-title text="Logout" @click="logout"
                            :title="'Logged in as ' + getUser.user.name + '. Click to logout.'">
                            Logout
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-main>
            <router-view></router-view>
        </v-main>
        <v-footer class="pa-0">
            <v-flex navbar py-2 text-center white--text xs12>
                &copy; {{ year() }} —
                <strong>
                    <a :href="env('VUE_APP_COPYRIGHT_URL')" class="white--text" target="_blank">
                        {{ env('VUE_APP_COPYRIGHT') }}</a>
                </strong>
                <small> v{{ env('VUE_APP_VERSION') }}</small>
            </v-flex>
        </v-footer>
    </v-app>
</template>

<script>
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    import moment from 'moment';
    import { eventBus } from './main';
    
    export default {
        components: {},
        data() {
            return {
                views: [
                    {
                        name: 'Dashboard',
                        path: '/dashboard',
                        icon: 'dashboard',
                        feature: 'basic',
                    },
                    {
                        name: 'Live map',
                        path: '/map',
                        icon: 'map',
                        feature: 'basic',
                    },
                    {
                        name: 'Desk',
                        path: '/desk',
                        icon: 'assessment',
                        feature: 'scheduler',
                    },
                    {
                        name: 'ECDIS routes',
                        path: '/routes',
                        icon: 'timeline',
                        feature: 'routes',
                    },
                    {
                        name: 'Events',
                        path: '/events',
                        icon: 'event_note',
                        feature: 'boqa',
                    },
                    {
                        name: 'AIS Analytics',
                        path: '/analytics',
                        icon: 'show_chart',
                        feature: 'performance',
                    },
                    {
                        name: 'Performance',
                        path: '/performance',
                        icon: 'multiline_chart',
                        feature: 'performance',
                    },
                    {
                        name: 'Log',
                        path: '/log',
                        icon: 'menu_book',
                        feature: 'log',
                    },
                ],
                dark: false,
                dataInterval: null,
                settings: [
                    {
                        name: 'BOQA',
                        path: '/settings',
                        icon: 'assignment',
                        feature: 'boqa',
                    },
                    {
                        name: 'Ships',
                        path: '/ships',
                        icon: 'directions_boat',
                        feature: 'basic',
                    },
                    {
                        name: 'Terminals',
                        path: '/terminals',
                        icon: 'mdi-anchor',
                        feature: 'scheduler',
                    },
                    {
                        name: 'Users',
                        path: '/users',
                        icon: 'people',
                        feature: 'basic',
                    },
                    {
                        name: 'Contacts',
                        path: '/contacts',
                        icon: 'contacts',
                        feature: 'scheduler'
                    },
                    {
                        name: 'Project types',
                        path: '/projecttypes',
                        icon: 'dynamic_feed',
                        feature: 'scheduler'
                    },
                    {
                        name: 'Money items',
                        path: '/moneyitems',
                        icon: 'attach_money',
                        feature: 'scheduler'
                    },
                ]
            };
        },
        updated() {
            eventBus.$on('startDataInterval', () => {
                if (this.loginStatus.loggedIn && !this.dataInterval) {
                    this.getData();
                }
            });
        },
        created() {
            if (this.loginStatus.loggedIn && !this.dataInterval) {
                this.getData();
            }
            // Set first day of week by locate settings
            function weekStart(region) {
                if ('AEAFBHDJDZEGIQIRJOKWLYOMQASDSY'.match(/../g).includes(region)) {
                    return '7'; // Saturday
                }
                if ('AGARASAUBDBRBSBTBWBZCACNCODMDOETGTGUHKHNIDILINJMJPKEKHKRLAMHMMMOMTMXMZNINPPAPEPHPKPRPTPYSASGSVTHTTTWUMUSVEVIWSYEZAZW'.match(/../g).includes(region)) {
                    return '0'; // Sunday
                }
                return '1'; // Monday
            }
            function decodeLocale(locale) {
                return locale.match(/^([a-zA-Z]{2,3})(?:[_-]+([a-zA-Z]{3})(?=$|[_-]+))?(?:[_-]+([a-zA-Z]{4})(?=$|[_-]+))?(?:[_-]+([a-zA-Z]{2}|\d{3})(?=$|[_-]+))?/);
            }

            const firstDayOfWeek = weekStart(decodeLocale(navigator.language)[4]);
            this.setFirstDayOfWeek(firstDayOfWeek);
            if (localStorage.getItem('darkmode') === true) {
                this.toggleMode();
            }
        },
        beforeDestroy() {
            clearInterval(this.dataInterval);
            eventBus.$off('startDataInterval');
        },
        computed: {
            ...mapGetters({
                loginStatus: 'authentication/loginStatus',
                getUser: 'authentication/getUser',
                features: 'authentication/features',
            }),
        },
        methods: {
            ...mapActions({
                fetchDynamic: 'data/fetchDynamic',
                fetchShips: 'data/fetchShips',
                fetchUsers: 'users/fetchUsers',
            }),
            ...mapMutations({
                setDarkMode: 'users/setDarkMode',
                setFirstDayOfWeek: 'data/setFirstDayOfWeek',
            }),
            getData() {
                this.fetchDynamic();
                this.fetchUsers();
                this.fetchShips();
                this.dataInterval = setInterval(() => {
                    this.fetchDynamic();
                }, 60 * 1000);
            },
            logout() {
                this.$store.dispatch('authentication/logout');
            },
            toggleMode() {
                this.dark = !this.dark;
                localStorage.setItem('darkmode', this.dark);
                this.setDarkMode(this.dark);
                this.$vuetify.theme.dark = this.dark;
                eventBus.$emit('darkMode', this.dark);
                if (this.dark) {
                     setTimeout(() => {
                        document.getElementById('theme').href = '/themes/scheduler.classic-dark.css';    
                        document.getElementById('ej2').href = '/themes/ej2-material-dark.css';
                    }, 100);
                } else {
                    setTimeout(() => {
                        document.getElementById('theme').href = '/themes/scheduler.material.css';   
                        document.getElementById('ej2').href = '/themes/ej2-material.css'; 
                    }, 100);
                }
                // if (this.$vuetify.theme === theme.light) {
                //     this.$vuetify.theme = theme.dark;
                //     setTimeout(() => {
                //         document.getElementById('theme').href = '/themes/scheduler.classic-dark.css';    
                //         document.getElementById('ej2').href = "/themes/ej2-material-dark.css";
                //     }, 100);
                // } else {
                //     this.$vuetify.theme = theme.light;
                //     setTimeout(() => {
                //         document.getElementById('theme').href = '/themes/scheduler.material.css';   
                //         document.getElementById('ej2').href = "/themes/ej2-material.css"; 
                //     }, 100);
                // }
            },
            year() {
                return moment.utc().format('YYYY');
            },
            env(variableName) {
                return process.env[variableName];
            },
            getImgUrl(pic) {
                // eslint-disable-next-line
                return require('./assets/icon/' + pic);
            }
        }
    };
</script>

<style>
    @import "~leaflet/dist/leaflet.css";

    @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";

    html {
        overflow-y: auto !important;
        font-family: "Helvetica Neue", Arial, Helvetica, sans-serif !important;
    }
    
    a {
        text-decoration: inherit;
        color: inherit !important;
    }

    footer {
        height:auto !important;
    }

    #topnav {
        flex:none !important;
    }

    .container {
        padding: 6px;
    }

    .container.grid-list-lg {
        padding: 8px;
    }

    .container.grid-list-lg .layout {
        margin: 0 !important;
    }

    .container.grid-list-lg .layout .flex {
        padding: 0;
        padding-left: 8px;
        padding-right: 8px;
    }

    .link-color {
        color: #4fc3f7;
    }

    .v-menu__content.menuable__content__active {
        z-index: 10000 !important;
    }

    .leaflet-popup-content {
        margin: 5px 8px;
        line-height: 1.4;
    }

    .leaflet-tooltip {
        position: absolute;
        padding: 0px 2px;
    }

    .leaflet-top.leaflet-left {
        color: black;
    }

    .application.theme--light {
        background: #e2e7e9;
    }

    .application.theme--dark {
        background: #1f292e;
    }

    .theme--dark .v-table,
    .application .theme--dark.v-datatable .v-datatable__actions,
    .theme--dark .v-datatable .v-datatable__actions,
    .application .theme--dark.v-table tbody tr[active],
    .theme--dark .v-table tbody tr[active] {
        background-color: #2a363c;
    }

    .application .theme--dark.v-table tbody tr:hover:not(.v-datatable__expand-row),
    .theme--dark .v-table tbody tr:hover:not(.v-datatable__expand-row) {
        background-color: #263238;
    }

    .ellipsis {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    a.leaflet-popup-close-button {
        color: #000 !important;
    }

    .weather {
        position: absolute;
        z-index: 999;
        right: 5px;
        top: 2px;
    }

    .aisinfo {
        position: absolute;
        z-index: 999;
        left: 5px;
        bottom: 4px;
        
        font-size:16px;
    }

    .sensorinfo {
        position: absolute;
        z-index: 999;
        right: 5px;
        bottom: 2px;
    }

    .tooltip-valid {
        border-left: 5px solid green;
    }
    .tooltip-warn {
        border-left: 5px solid orange;
    }
    .tooltip-error{
        border-left: 5px solid red;
    }

    .control-btn {
        position: absolute;
        z-index: 9999;
        left: 10px;
        font-size: 22px;
        border: 2px solid rgba(0, 0, 0, 0.2);
        background-clip: padding-box;
        border-radius: 4px;
        width: 35px;
        height: 35px;
        line-height: 32px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        display: block;
        background-color: #fff;
        display: block;
        text-align: center;
        text-decoration: none;
        color: black !important;
        cursor: pointer;
    }

    .control-btn:hover {
        background-color: #f4f4f4;
    }

    .v-tabs__slider {
        height: 3px;
    }

    .v-tabs__items {
        overflow: visible;
    }

    .v-icon.v-icon.v-icon--link {
        color: #000;
    }

    .v-data-table th {
        height:30px !important;
    }

    .leaflet-container .leaflet-control-mouseposition {
        background-color: rgba(255, 255, 255, 0.7);
        -webkit-box-shadow: 0 0 5px #bbb;
        box-shadow: 0 0 5px #bbb;
        padding: 0 5px;
        margin: 0;
        color: #333;
        font: 11px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
        position: absolute;
        margin-left: 110px;
        white-space: nowrap;
        bottom: 0;
    }

    .leaflet-bottom .leaflet-control-scale {
        margin-bottom: 0;
    }

    .leaflet-left .leaflet-control-scale {
        margin-left: 0;
    }

    .logo-nauticai {
        width: 80%;
        margin-top: 7%;
    }

    .logo-danelec {
        width: 80%;
        margin-top: 4%;
    } 

    .v-sheet {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    /* syncfusion grid */
    .e-grid .e-groupheadercell {
        border-radius: 16px !important;
        height: 32px !important;
        line-height: 32px !important;
    }

    .e-grid .e-groupheadercell .e-grouptext {
        height: 32px !important;
        line-height: 32px !important;
    }

    .e-input-group .e-input-group-icon, .e-input-group.e-control-wrapper .e-input-group-icon {
        margin-right: -1px !important;
        margin-left: -1px !important;
    }

    .e-grid .e-rowcell, .e-grid .e-headercell, .e-grid .e-detailheadercell {
        padding-left: 12px !important;
        padding-right: 12px !important; 
    }

    /* .e-grid .e-rowcell {
        color: inherit !important;
    } */

    @media only screen and (max-width: 959px) {
        .container {
            padding: 6px;
        }
    }

    @media only screen and (max-width: 450px) {
        .leaflet-container .leaflet-control-mouseposition {
            display: none;
        }

        .logo-danelec {
            width: 120%;
            margin-top: 25%;
            margin-left: -9px;
        }
    }
</style>
<style scoped>
    .v-btn {
        min-width: auto;
        padding: 0 10px;
    }
</style>
