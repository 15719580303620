<template>
    <div id="child" style="margin-left:10px; display: inline-block;">
         <ejs-numerictextbox style="width:70px;vertical-align:-webkit-baseline-middle" v-model="data" placeholder="BAF %" format="n2" :step="0.01" :min="-1000" :max="1000"></ejs-numerictextbox>
         <button class="e-tbar-btn e-tbtn-txt e-control e-btn e-lib" type="button" id="Grid_setBaf" tabindex="-1" aria-label="Set BAF" style="width: auto;margin-left:5px;display: inline-block;">
            <span @click="doCustomAction()" class="e-tbar-btn-text">Set BAF %</span>
        </button>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { NumericTextBoxPlugin } from '@syncfusion/ej2-vue-inputs';

    import {
        eventBus
    } from '../../main';

    Vue.use(NumericTextBoxPlugin);

    export default {
        data() {
            return {
                data: 0,
            };
        },
        mounted() {
            this.data = localStorage.getItem('bafPercentage') ? localStorage.getItem('bafPercentage') : 0;
        },
        methods: {
            doCustomAction() {
                eventBus.$emit('setBaf', this.data);
                localStorage.setItem('bafPercentage', this.data);
            }
        }
    };
</script>

<style>
    .e-baf::before {
      content: '\e7ff';
    }
</style>
