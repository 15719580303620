<template>
    <v-container fluid grid-list-md pa-2>
        <v-layout wrap>
            <v-flex d-flex xs12 sm4 pa-1>
                <v-layout wrap align-content-start>
                    <v-flex d-flex v-for="(card, index) in cards" :key="card.id" v-if="(index === 2 || (windowWidth >= 500 && (index === 5 || index === 6 || index === 9 || index === 12 || index === 15 || index === 18 || index === 21)))">
                        <DashboardCard style="width:100%;min-width:250px" :card="card" />
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex d-flex xs12 sm8 pa-1>
                <v-layout wrap align-content-start>
                    <v-flex d-flex sm6 v-for="(card, index) in cards" :key="card.id" v-if="!(index === 2 || (windowWidth >= 500 && (index === 5 || index ===6 || index === 9 || index === 12 || index === 15 || index === 18 || index === 21)))">
                        <DashboardCard style="width:100%;min-width:250px" :card="card" />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

        <!-- Components -->
        <StatusDialog />
        <ProgressCircular />
    </v-container>
</template>

<script>
    import {
        mapGetters, mapMutations, mapActions
    } from 'vuex';
    import moment from 'moment';
    import {
        eventBus
    } from '../main';
    import DashboardCard from '../components/DashboardCard.vue';
    import StatusDialog from '../components/StatusDialog.vue';
    import ProgressCircular from '../components/ProgressCircular.vue';
    import {
        shipHelpers
    } from '../helpers';

    export default {
        name: 'Dashboard',
        components: {
            DashboardCard,
            StatusDialog,
            ProgressCircular
        },
        beforeRouteEnter(to, from, next) {
            if (from.name === 'LoginPage') {
                eventBus.$emit('startDataInterval');
            }
            next();
        },
        data() {
            return {
                activeCameras: [],
                windowWidth: 700,
            };
        },
        created() {
            eventBus.$on('createStream', (cam) => {
                const activeCameras = this.getActiveCameras();
                if (!activeCameras.includes(cam)) {
                    activeCameras.push(cam);
                    this.setActiveCameras(activeCameras);
                }
            });
            eventBus.$on('endStream', (cam) => {
                let activeCameras = this.getActiveCameras();
                activeCameras = activeCameras.filter((o) => o.id !== cam.id);
                this.setActiveCameras(activeCameras);
            });
            this.windowWidth = window.innerWidth;
            if (!this.ciiRatings || this.ciiRatings.length === 0){
                this.fetchCiiRatings();
            }
        },
        beforeDestroy() {
            eventBus.$off('createStream');
            eventBus.$off('endStream');
        },
        computed: {
            ...mapGetters({
                ciiRatings: 'data/getCiiRatings',
            }),
            cards() {
                const cards = [];
                cards.push({
                    id: 'map',
                    header: 'Live map',
                    map: true,
                    info: 'The live map shows details of your fleet\'s current status',
                    items: [],
                    action: 'Open map',
                    path: '/map',
                });
                cards.push({
                    id: 'ecdis',
                    header: 'ECDIS routes',
                    info: 'Upload, Activate and manage ECDIS routes here',
                    items: [],
                    action: 'Manage routes',
                    path: '/routes',
                });
                cards.push({
                    id: 'events',
                    header: 'BOQA events (24hrs)',
                    info: 'Browse recent BOQA events here',
                    items: [],
                    action: 'View events',
                    path: '/events',
                    large: true,
                });
                cards.push({
                    id: 'ships',
                    header: 'Ships',
                    info: 'Manage your fleet and ship settings here.',
                    items: [],
                    action: 'Manage fleet',
                    path: '/ships',
                });
                cards.push({
                    id: 'users',
                    header: 'Users',
                    info: 'Add/Remove users and edit user rights.',
                    items: [],
                    action: 'Edit users',
                    path: '/users',
                });

                const dynamic = this.getDynamic();
                if (dynamic && dynamic.ais && dynamic.ais.length > 0) {
                    /* cards[4].items.push({
                        text: dynamic.ais.length + ' ships in this fleet'
                    }); */
                    for (const ship of dynamic.ais) {
                        if (ship.external !== 1) {
                            if (ship.routeName) {
                                cards[1].items.push({
                                    vIcon: 'timeline',
                                    vIconColor: 'success',
                                    text: `${ship.shortName} - ${ship.routeName}`,
                                });
                            } else {
                                cards[1].items.push({
                                    vIcon: 'timeline',
                                    vIconColor: 'error',
                                    text: `${ship.shortName} - No active route`,
                                });
                            }
                        }
                        const shipData = this.getShips().filter((o) => ship.id === o.id && o.streamParams);
                        let streamParams = [];
                        if (shipData && shipData[0] && shipData[0].streamParams && shipData[0].streamParams.length > 0) {
                            streamParams = shipData[0].streamParams;
                            const activeCameras = this.getActiveCameras();
                            for (const key in streamParams) {
                                if (activeCameras.find((o) => o.id === streamParams[key].id)) {
                                    streamParams[key].vIconColor = 'success';
                                } else {
                                    streamParams[key].vIconColor = 'greydark';
                                }
                            }
                        }
                        let iotColor;
                        let iotText;
                        if (ship.lastIotTime) {
                            const since = moment.utc().unix() - moment.utc(ship.lastIotTime).unix();
                            iotText = 'Last IoT data received ';
                            if (since > 0 && since < 60 * 60) {
                                iotText += `${Math.round(since / 60)} mins ago`;
                                iotColor = 'success';
                            } else if (since > 0 && since < 60 * 60 * 48) {
                                iotText += `${Math.round(since / (60 * 60))} hrs ago`;
                                iotColor = 'warning';
                            } else {
                                iotText += moment.utc(ship.lastIotTime).local().format('D.M HH:mm');
                                iotColor = 'error';
                            }
                        }
                        const dtg = ship.dtg ? ` (${ship.dtg}NM)` : ''; 
                        const eta = ship.calcEta ? ` ETA ${moment.utc(ship.calcEta).local().format('D.M HH:mm')}` : '';
                        const ext = ship.external ? ' (EXT) ' : '';
                        let ciiRating;
                        if (this.getCiiRatings()) {
                            ciiRating = this.getCiiRatings().find((o) => ship.id === o.shipId);
                            if (ciiRating && ciiRating.ciiRating) {
                                ciiRating = ciiRating.ciiRating;
                            }
                        }
                        cards[3].items.push({
                            id: ship.id,
                            vIcon: 'directions_boat',
                            vIconColor: shipHelpers.getNavstat(ship.navstat).color,
                            text: `${ship.shortName + ext} - ${shipHelpers.getNavstat(ship.navstat, true).status 
                                } ${Math.round(ship.sog * 10) / 10}kn${dtg}${eta}`,
                            cams: streamParams,
                            iotColor,
                            iotText,
                            ciiRating,
                        });
                    }
                } else {
                    cards[1].items.push({
                        vIcon: 'warning',
                        vIconColor: 'error',
                        text: 'No ships in this fleet'
                    });
                    cards[3].items.push({
                        vIcon: 'warning',
                        vIconColor: 'error',
                        text: 'No ships in this fleet'
                    });
                }

                if (dynamic && dynamic.users && dynamic.users.length > 0) {
                    const onlineUsers = dynamic.users.filter((o) => moment.utc().unix() - moment.utc(o.lastUpdate).unix() <= 2 * 60 * 60);
                    const offlineUsers = dynamic.users.filter((o) => moment.utc().unix() - moment.utc(o.lastUpdate).unix() > 2 * 60 * 60);
                    cards[4].header = `Users (${onlineUsers.length})`;
                    onlineUsers.sort((a, b) => ((a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))); 
                    offlineUsers.sort((a, b) => ((a.lastUpdate < b.lastUpdate) ? 1 : ((b.lastUpdate < a.lastUpdate) ? -1 : 0))); 
                    offlineUsers.map((o) => {
                        const since = moment.utc().unix() - moment.utc(o.lastUpdate).unix();
                        if (since < 60 * 60) {
                            o.timeText = `${Math.round(since / 60)} mins ago`;
                        } else if (since < 60 * 60 * 48) {
                            o.timeText = `${Math.round(since / (60 * 60))} hrs ago`;
                        } else {
                            o.timeText = `${moment.utc(o.lastUpdate).format('D.M HH:mm')} UTC`;
                        }
                        return o;
                    });
                    
                    for (const user of onlineUsers) {
                        if (user.lastUpdate) {
                            cards[4].items.push({
                                vIcon: 'person',
                                vIconColor: 'success',
                                text: user.name,
                            });
                        }
                    }
                    for (const user of offlineUsers) {
                        if (user.loginTime) {
                            cards[4].items.push({
                                vIcon: 'person',
                                vIconColor: 'error',
                                text: `${user.name} - ${user.timeText}`,
                            });
                        }
                    }
                }

                if (dynamic && dynamic.events && dynamic.events.length > 0) {
                    for (const event of dynamic.events) {
                        let icon = 'event_note';
                        let color = 'success';
                        if (event.severity === 2) {
                            icon = 'warning';
                            color = 'error';
                        } else if (event.severity === 1) {
                            icon = 'notifications';
                            color = 'warning';
                        }
                        cards[2].items.push({
                            vIcon: icon,
                            vIconColor: color,
                            vIconTitle: `${event.name} ${event.eventType}`,
                            text: `${event.shortName} - ${event.eventType} - ${moment.utc(event.time)
                                .format('HH:mm')} UTC`,
                        });
                    }
                } else {
                    cards[2].items.push({
                        vIcon: 'thumb_up',
                        vIconColor: 'success',
                        text: 'No new events in last 24 hours',
                    });
                }

                // Camera streams
                const activeCameras = this.getActiveCameras();
                if (activeCameras.length > 0) {
                    for (const key in activeCameras) {
                        const streamCard = {
                            id: activeCameras[key].id,
                            header: activeCameras[key].name,
                            info: activeCameras[key].description,
                            streamUrl: activeCameras[key].url,
                            timeout: activeCameras[key].timeout,
                            controls: activeCameras[key].controls ? activeCameras[key].controls : [],
                            items: [],
                            action: 'End stream',
                            path: '',
                        };

                        if (key == 1) {
                            cards[1] = streamCard;
                        } else if (key == 2) {
                            cards[4] = streamCard;
                        } else {
                            cards.push(streamCard);
                            cards[2].large = false;
                        }
                    }
                }
                return cards;
            }
        },
        methods: {
            ...mapGetters({
                getUsers: 'users/getUsers',
                getDynamic: 'data/getDynamic',
                getShips: 'data/getShips',
                getActiveCameras: 'data/getActiveCameras',
                getCiiRatings: 'data/getCiiRatings',
            }),
            ...mapMutations({
                setActiveCameras: 'data/setActiveCameras',
            }),
            ...mapActions({
                fetchCiiRatings: 'data/fetchCiiRatings',
            }),
        },

    };
</script>

<style scoped>

</style>
