<template>
    <span style="white-space:nowrap;margin-left:-38px">Total: {{ Math.round(data.Sum*10)/10 }}</span>
</template>

<script>
    export default {
        data() {
            return {
                data: {},
            };
        },
    };
</script>
