<template>
    <div id="child" style="margin-top:7px">
        <ejs-dropdownlist name="unitdropdown" id="unitdropdown" width="100px" :change="doCustomAction()" v-model="data.unit" placeholder="Select" :dataSource="items" :fields="fields">
        </ejs-dropdownlist>
    </div>
</template>

<script>
    import {
        eventBus
    } from '../../main';

    export default {
        data() {
            return {
                data: {},
                items: [{ value: '', text: '' },
                    { value: 'hour', text: 'hour' },
                    { value: 'man/hour', text: 'man/hour' },
                    { value: 'gang/hour', text: 'gang/hour' },
                    { value: 'shift', text: 'shift' },
                    { value: 'man/shift', text: 'man/shift' },
                    { value: 'gang/shift', text: 'gang/shift' },
                    { value: 'day', text: 'day' },
                    { value: 'call', text: 'call' },
                    { value: 'port', text: 'port' },
                    { value: 'pc', text: 'pc' },
                    { value: 'ton', text: 'ton' },
                    { value: 'dton', text: 'dton' },
                    { value: 'm3', text: 'm3' },
                    { value: 'cuf', text: 'cuf' },
                    { value: 'cassette', text: 'cassette' },
                    { value: 'container', text: 'container' },
                    { value: 'mafi', text: 'mafi' },
                    { value: 'trailer', text: 'trailer' },
                    { value: 'lumpsum', text: 'lumpsum' },
                    { value: '%', text: '%' }
                ],
                fields: {
                    text: 'text',
                    value: 'value'
                }
            };
        },
        mounted() {
            eventBus.$on('unitInitial', (e) => {
                this.data.unit = e;
                this.doCustomAction();
            });
        },
        beforeDestroy() {
            eventBus.$off('unitInitial');
        },
        methods: {
            doCustomAction() {
                eventBus.$emit('unitUpdated', this.data.unit);
            }
        
        }
    };
</script>

<style>
</style>
