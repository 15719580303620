import { dataService } from '../services';
import moment from 'moment';

export const data = {
    namespaced: true,
    state: {
        ships: [],
        routes: [], 
        settings: {},
        events: [],
        dynamic: {},
        pastTrack: {},
        event: [],
        eventHistory: [],
        wmsCapabilities: {},
        analyticsData: [],
        performanceData: [],
        performanceParams: [],
        aggregateValues: {},
        trips: [],
        progress: false,
        customParameters: [],
        customSettings: [],
        streamUrl: null,
        activeCameras: [],
        schedulerParams: {},
        exchangeRates: {},
        ports: [],
        contacts: [],
        schedulerRoutes: [],
        portCalls: [],
        moneyData: [],
        avgDa: null,
        firstDayOfWeek: '1',
        dischCostsOnlyPort: null,
        terminals: [],
        ciiRatings: [],
        lastOpenedEvent: null,
    },
    actions: {
        fetchShips({ commit }) {
            return dataService.get('ships')
                .then(
                    (ships) => {
                        commit('setShips', ships);
                        return ships;
                    }
                );
        },
        fetchTerminals({ commit }) {
            return dataService.get('terminals')
                .then(
                    (data) => {
                        commit('setTerminals', data);
                        return data;
                    }
                );
        },
        fetchDynamic({ commit }) {
            return dataService.get('dynamic')
                .then(
                    (data) => {
                        commit('setDynamic', data);
                        return data;
                    }
                );
        },
        fetchPorts({ commit }, data) {
            return dataService.post('ports', data)
                .then(
                    (data) => {
                        commit('setPorts', data);
                        return data;
                    }
                );
        },
        // eslint-disable-next-line no-empty-pattern
        fetchCargos({}, data) {
            return dataService.post('cargos', data)
                .then(
                    (data) => {
                        return data;
                    }
                );
        },
        fetchContacts({ commit }, data) {
            return dataService.post('contacts', data)
                .then(
                    (data) => { 
                        commit('setContacts', data);
                        return data;
                    }
                );
        },
        fetchPastTrack({ commit }) {
            return dataService.get('pasttrack')
                .then(
                    (data) => {
                        commit('setPastTrack', data);
                        return data;
                    }
                );
        },
        fetchEvents({ commit }) {
            return dataService.get('events')
                .then(
                    (data) => {
                        commit('setEvents', data);
                        return data;
                    }
                );
        },
        fetchEventHistory({ commit }, time) {
            commit('progressOn');
            return dataService.getById('eventhistory', time)
                .then(
                    (data) => {
                        commit('setEventHistory', data);
                        commit('progressOff');
                        return data;
                    }
                );
        },
        fetchCustomParameters({ commit }) {
            return dataService.get('customparameters')
                .then(
                    (data) => {
                        commit('setCustomParameters', data);
                        return data;
                    }
                );
        },
        fetchSchedulerRoutes({ commit }, data) {
            commit('progressOn');
            return dataService.post('scheduler/routes', data)
                .then(
                    (data) => {
                        commit('setSchedulerRoutes', data);
                        commit('progressOff');
                        const noRouteOnSomeLeg = data.find((o, i) => (i > 0 || o.prevPort === 1) && o.routeId === null);
                        if (noRouteOnSomeLeg && noRouteOnSomeLeg.name) {
                            const noRoutes = data.filter((o, i) => (i > 0 || o.prevPort === 1) && o.routeId === null).map((o) => o.name).join(', ');
                            commit('alert/error', `Failed to fetch routes to following port(s): ${noRoutes}. Distance was set to zero, please set ETA manually for now and try again later with recalculate routes feature. If problem persists, contact support.`, { root: true });  
                        }
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch route', error);
                        commit('alert/error', 'Failed to fetch route. Try again later.', { root: true });
                        commit('progressOff');
                        return [];
                    }
                );
        },
        fetchWmsCapabilities({ commit }, source) {
            return dataService.getCapabilities(source)
                .then(
                    (data) => {
                        commit('setWmsCapabilities', data);
                        return data;
                    }
                );
        },
        fetchEvent({ commit }, routeId) {
            commit('progressOn');
            return dataService.getById('event', routeId)
                .then(
                    (data) => {
                        commit('setEvent', data);
                        commit('progressOff');
                        return data;
                    }
                );
        },
        fetchChecklists({ commit }, shipId) {
            commit('progressOn');
            return dataService.getById('checklists', shipId)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    }
                );
        },
        fetchNorppaData({ commit }, mmsi) {
            commit('progressOn');
            return dataService.getById('norppa', mmsi)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    }
                );
        },
        fetchChecklistTitles({ commit }) {
            commit('progressOn');
            return dataService.get('checklisttitles')
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    }
                );
        },
        saveChecklistItems({ commit }, data) {
            commit('progressOn');
            return dataService.post('checklists', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    }
                );
        },
        fetchTrips({ commit }, id) {
            return dataService.getByIdPerf('trips', id)
                .then(
                    (data) => {
                        commit('setTrips', data);
                        return data;
                    }
                );
        },
        fetchRoutes({ commit }, { shipId, routeId }) {
            dataService.fetchRoutes(shipId, routeId)
                .then(
                    (routes) => {
                        commit('setRoutes', routes);
                    }
                );
        },
        fetchWaypoints({ commit }, data) {
            return dataService.post('waypoints', data)
                .then(
                    (data) => {
                        commit('setWaypoints', data);
                        return data;
                    }
                );
        },
        fetchStreamUrl({ commit }, data) {
            return dataService.post('streamurl', data)
                .then(
                    (data) => {
                        commit('setStreamUrl', data);
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch stream url', error);
                    }
                );
        },
        sendCameraCommand({ commit }, data) {
            commit('progressOn');
            return dataService.post('cameracommand', data)
                .then(
                    (response) => {
                        commit('progressOff');
                        commit('alert/success', 'Camera command successful', { root: true });
                        return response;
                    },
                    (error) => {
                        console.error('Failed to send camera command', error);
                        commit('alert/error', 'Failed to send camera command', { root: true });
                        commit('progressOff');
                    }
                );
        },
        sendCameraCommandImage({ commit }, data) {
            commit('progressOn');
            return dataService.cameraCommandImage(data)
                .then(
                    (response) => { 
                        commit('progressOff');
                        if (!response.headers) {
                            commit('alert/error', 'Failed to receive image from ship. Request timeout.', { root: true });    
                            return null;
                        } else {
                            commit('alert/success', 'Camera send image command successful', { root: true });
                            return response; 
                        }
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to send camera image command', error);
                        commit('alert/error', 'Failed to send camera image command', { root: true });
                    }
                );
        },
        fetchAisTargets({ commit }, shipId) {
            return dataService.getById('aistargets', shipId)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch nearby Ais targets.', error);
                    }
                    
                );
        },
        fetchShipWeather({ commit }, shipId) {
            return dataService.getById('weather', shipId)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch latest ship weather data.', error);
                    }
                    
                );
        },
        fetchAnalyticsData({ commit }, data) {
            commit('progressOn');
            return dataService.post('analyticsdata', data)
                .then(
                    (data) => {
                        if (data && data.length === 0) {
                            commit('alert/error', 'No data available on given timeframe', { root: true });
                        }
                        commit('setAnalyticsData', data);
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch analytics data', error);
                        commit('alert/error', 'Failed to fetch analytics data', { root: true });
                        commit('progressOff');
                    }
                );
        },
        fetchPerformanceData({ commit }, data) {
            commit('progressOn');
            return dataService.postPerf('data', data)
                .then(
                    (data) => {
                        if (data && data.length === 0) {
                            commit('alert/error', 'No data available on given timeframe', { root: true });
                        }
                        commit('setPerformanceData', data);
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch performance data.', error);
                        commit('alert/error', 'Failed to fetch performance data.', { root: true });
                        commit('progressOff');
                    }
                );
        },
        fetchAggregateValues({ commit }, data) {
            commit('progressOn');
            return dataService.postPerf('aggregate', data)
                .then(
                    (data) => {
                        commit('setAggregateValues', data);
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch performance data aggregate values', error);
                        commit('progressOff');
                    }
                );
        },
        fetchCPComplianceValues({ commit }, data) {
            commit('progressOn');
            return dataService.postPerf('compliance', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch CP compliance data', error);
                        commit('progressOff');
                    }
                );
        },
        fetchCiiData({ commit }, data) {
            commit('progressOn');
            return dataService.postPerf('cii', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch CII data', error);
                        commit('progressOff');
                    }
                );
        },
        fetchCiiRatings({ commit }, data) {
            return dataService.getPerf('cii', data)
                .then(
                    (data) => {
                        commit('setCiiRatings', data);
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch CII ratings', error);
                    }
                );
        },
        fetchPerformanceParams({ commit }, prefix) {
            commit('progressOn');
            return dataService.postPerf('params', prefix)
                .then(
                    (data) => {
                        if (data && data.length === 0) {
                            commit('alert/error', 'Performance parameters are not available in database', { root: true });
                        }
                        commit('setPerformanceParams', data);
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch performance parameters.', error);
                        commit('alert/error', 'Failed to fetch performance parameters.', { root: true });
                        commit('progressOff');
                    }
                );
        },
        fetchPerformanceAisTargets({ commit }, data) {
            commit('progressOn');
            return dataService.postPerf('aistargets', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch nearby Ais targets. ', error);
                        commit('alert/error', 'Failed to fetch nearby Ais targets.', { root: true });
                        commit('progressOff');
                    }
                );
        },
        fetchComparePerformanceData({ commit }, data) {
            commit('progressOn');
            return dataService.postPerf('compare', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch performance data. ', error);
                        commit('alert/error', 'Failed to fetch performance data.', { root: true });
                        commit('progressOff');
                    }
                );
        },
        setActiveRoute({ commit }, { shipId, routeId }) {
            dataService.setActiveRoute(shipId, routeId)
                .then(
                    (data) => {
                        commit('setShip', data);
                        commit('alert/success', 'Route activated successful', { root: true });
                    },
                    (error) => {
                        console.error('Route activation failed', shipId, routeId, error);
                        commit('alert/error', 'Route activation failed', { root: true });
                    }
                );
        },
        deactivateRoute({ commit }, shipId) {
            dataService.setActiveRoute(shipId, null)
                .then(
                    (data) => {
                        commit('setShip', data);
                        commit('alert/success', 'Route deactivated successful', { root: true });
                    },
                    (error) => {
                        console.error('Route deactivation failed', error);
                        commit('alert/error', 'Route deactivation failed', { root: true });
                    }
                );
        },
        uploadRoute({ commit, dispatch }, data) {
            dataService.uploadRoute(data)
                .then(
                    () => {
                        dispatch('fetchShips');
                        commit('alert/success', 'Route upload successful', { root: true });
                    },
                    (error) => {
                        console.error('Route upload failed.', error);
                        commit('alert/error', 'Route upload failed', { root: true });
                    }
                );
        },
        deleteRoutes({ commit, dispatch }, { shipId, data }) {
            dataService.deleteRoutes(shipId, data)
                .then(
                    (data) => {
                        dispatch('fetchRoutes', data.shipId);
                        commit('alert/success', 'Route(s) deleted successfully', { root: true });
                    },
                    (error) => {
                        console.error('Route delete failed', error);
                        commit('alert/error', 'Route delete failed', { root: true });
                    }
                );
        },
        fetchSettings({ commit }, prefix) {
            return dataService.fetchSettings(prefix)
                .then(
                    (data) => {
                        if (prefix === 'Custom') {
                            commit('setCustomSettings', data);
                        } else {
                            commit('setSettings', data);
                        }
                        return data.data;
                    }
                );
        },
        saveSettings({ commit }, { settings, prefix }) {
            dataService.saveSettings(settings, prefix)
                .then(
                    (data) => {
                        commit('setSettings', data);
                        commit('alert/success', 'Settings saved successfully', { root: true });
                    },
                    (error) => {
                        console.error('Settings save failed', error);
                        commit('alert/error', 'Settings save failed', { root: true });
                    }
                );
        },
        addShip({ commit }, data) {
            dataService.put('ship', data)
                .then(
                    (data) => {
                        commit('addShip', data);
                        commit('alert/success', 'New ship saved successfully', { root: true });
                    },
                    (error) => {
                        if (error.response.status === 403) {
                            console.error('Failed to save new ship. Max active ships quota reached.', error);
                            commit('alert/error', 'Failed to save new ship. Max active ships quota reached.', { root: true });
                        } else {
                            console.error('Failed to save new ship', error);
                        commit('alert/error', 'Failed to save new ship', { root: true });
                        }
                    }
                );
        },
        saveShip({ commit }, data) {
            dataService.post('ship', data)
                .then(
                    (data) => {
                        commit('setShip', data);
                        commit('alert/success', 'Ship data saved successfully', { root: true });
                    },
                    (error) => {
                        if (error.response.status === 403) {
                            console.error('Ship data save failed. Max active ships quota reached.', error);
                            commit('alert/error', 'Ship data save failed. Max active ships quota reached.', { root: true });
                        } else {
                            console.error('Ship data save failed', error);
                            commit('alert/error', 'Ship data save failed', { root: true });
                        }
                    }
                );
        },
        addTerminal({ commit }, data) {
            dataService.put('terminal', data)
                .then(
                    (data) => {
                        commit('setTerminals', data);
                        commit('alert/success', 'New terminal saved successfully', { root: true });
                    },
                    (error) => {
                       
                        console.error('Failed to save new terminal', error);
                        commit('alert/error', 'Failed to save new terminal', { root: true });
                    }
                );
        },
        saveTerminal({ commit }, data) {
            dataService.post('terminal', data)
                .then(
                    (data) => {
                        commit('setTerminals', data);
                        commit('alert/success', 'Terminal data saved successfully', { root: true });
                    },
                    (error) => {
                        console.error('Terminal data save failed', error);
                        commit('alert/error', 'Terminal data save failed', { root: true });
                    }
                );
        },
        addVoyage({ commit }, data) {
            commit('progressOn');
            return dataService.put('voyages', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        commit('alert/success', 'New voyage added successfully.', { root: true });
                        return data;
                    },
                    (error) => {
                        console.error(error);
                        commit('progressOff');
                        commit('alert/error', 'Failed to add new voyage', { root: true });
                    }
                );
        },
        saveCustomSettings({ commit }, data) {
            dataService.put('customsettings', data)
                .then(
                    (data) => {
                        commit('setCustomSettings', data);
                        commit('alert/success', 'Custom event settings saved successfully', { root: true });
                    },
                    (error) => {
                        console.error('Custom event settgins save failed', error);
                        commit('alert/error', 'Custom event settings save failed', { root: true });
                    }
                );
        },
        deleteCustomSettings({ commit }, id) {
            dataService.deleteById('customsettings', id)
                .then(
                    (id) => {
                        commit('deleteCustomSettings', id);
                        commit('alert/success', 'Custom event settings deleted successfully', { root: true });
                    },
                    (error) => {
                        console.error('Custom event settings delete failed', error);
                        commit('alert/error', 'Custom event settings delete failed', { root: true });
                    }
                );
        },
        deleteShip({ commit }, id) {
            dataService.deleteById('ship', id)
                .then(
                    (id) => {
                        commit('deleteShip', id);
                        commit('alert/success', 'Ship deleted successfully', { root: true });
                    },
                    (error) => {
                        console.error('Ship delete failed', error);
                        commit('alert/error', 'Ship delete failed', { root: true });
                    }
                );
        },
        deleteTerminal({ commit }, id) {
            dataService.deleteById('terminal', id)
                .then(
                    (id) => {
                        commit('deleteTerminal', id);
                        commit('alert/success', 'Terminal deleted successfully', { root: true });
                    },
                    (error) => {
                        console.error('Terminal delete failed', error);
                        commit('alert/error', 'Terminal delete failed', { root: true });
                    }
                );
        },
        fetchSchedulerParams({ commit }) {
            return dataService.get('scheduler/params')
                .then(
                    (data) => {
                        commit('setSchedulerParams', data);
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch scheduler parameters', error);
                        commit('alert/error', 'Failed to fetch scheduler parameters', { root: true });
                    }
                );
        },
        fetchExchangeRates({ commit }) {
            return dataService.get('scheduler/exchangerates')
                .then(
                    (data) => {
                        commit('setExchangeRates', data);
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch currency exchange rates', error);
                    }
                );
        },
        fetchPortCalls({ commit }, id) {
            commit('progressOn');
            return dataService.getById('scheduler/portcalls', id)
                .then(
                    (data) => {
                        data.portCalls = data.portCalls.map((o) => {
                            o.portCallName = o.name;
                            return o;
                        });
                        commit('setPortCalls', data.portCalls);
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch voyage port calls', error);
                        commit('alert/error', 'Failed to fetch voyage port calls', { root: true });
                        commit('progressOff');
                    }
                );
        },
        fetchMoneyData({ commit }, id) {
            commit('progressOn');
            return dataService.getById('scheduler/moneydata', id)
                .then(
                    (data) => {
                        data.money = data.money.map((o) => {
                            o.rate = parseFloat(o.rate);
                            o.rateFinal = parseFloat(o.rateFinal);
                            o.quantity = parseFloat(o.quantity);
                            o.quantityMin = parseFloat(o.quantityMin);
                            o.quantityMax = parseFloat(o.quantityMax);
                            o.sum = parseFloat(o.sum);
                            o.quantityFinal = parseFloat(o.quantityFinal);
                            o.sumFinal = parseFloat(o.sumFinal);
                            o.tonFinal = parseFloat(o.tonFinal);
                            o.status = !!o.status;
                            return o;
                        });
                        commit('setMoneyData', data);
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch project money data', error);
                        commit('alert/error', 'Failed to fetch project money data', { root: true });
                        commit('progressOff');
                    }
                );
        },
        fetchFairwayCount({ commit }, data) {
            commit('progressOn');
            return dataService.getByIdAndType('scheduler/fairwaycount', data.shipId, data.startDate)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch ships fairway count', error);
                        commit('progressOff');
                    }
                );
        },
        fetchAvgDa({ commit }, data) {
            return dataService.post('scheduler/avgda', data)
                .then(
                    (data) => {
                        commit('setAvgDa', data);
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch average DA value', error);
                        commit('alert/error', 'Failed to fetch average DA value', { root: true });
                    }
                );
        },
        deleteProject({ commit }, data) {
            return dataService.post('scheduler/projects/delete', data)
                .then(
                    () => {
                        commit('alert/success', 'Project deleted successfully', { root: true });
                    },
                    (error) => {
                        console.error('Failed to delete project', error);
                        commit('alert/error', 'Failed to delete project', { root: true });
                    }
                );
        },
        cloneProject({ commit }, data) {
            commit('progressOn');
            return dataService.getByIdAndType('scheduler/projects/clone', data.id, data.type)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to clone/duplicate project', error);
                        commit('alert/error', 'Failed to clone/duplicate project', { root: true });
                    }
                );
        },
        updateProject({ commit }, data) {
            commit('progressOn');
            return dataService.post('scheduler/projects/update', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to save project data', error);
                        commit('alert/error', 'Failed to save project data', { root: true });
                    }
                );
        },
        searchProject({ commit }, data) {
            commit('progressOn');
            return dataService.post('scheduler/projects/search', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to search project data', error);
                        commit('alert/error', 'Failed to search project data', { root: true });
                    }
                );
        },
        unlockProject({ commit }, data) {
            commit('progressOn');
            return dataService.post('scheduler/projects/unlock', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to unlock project', error);
                        commit('alert/error', 'Failed to unlock project', { root: true });
                    }
                );
        },
        fetchLockStatus({ commit }, data) {
            commit('progressOn');
            return dataService.getById('scheduler/projects/lockstatus', data.id)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to fetch lock status', error);
                    }
                );
        },
        fetchProject({ commit }, id) {
            commit('progressOn');
            return dataService.getById('scheduler/projects/read', id)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to fetch project data', error);
                    }
                );
        },
        checkProjectCode({ commit }, data) {
            commit('progressOn');
            return dataService.post('scheduler/projectcode', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to fetch project code status', error);
                    }
                );
        },
        saveContact({ commit }, data) {
            commit('progressOn');
            return dataService.post('contact', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        commit('alert/success', 'Contact data saved', { root: true });
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to save contact data', error);
                        commit('alert/error', 'Failed to save contact data', { root: true });
                    }
                );
        },
        addContact({ commit }, data) {
            commit('progressOn');
            return dataService.put('contact', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        commit('alert/success', 'New contact saved successfully', { root: true });
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to save new contact', error);
                        commit('alert/error', 'Failed to save new contact', { root: true });
                    }
                );
        },
        deleteContact({ commit }, id) {
            commit('progressOn');
            dataService.deleteById('contact', id)
                .then(
                    () => {
                        commit('progressOff');
                        commit('alert/success', 'Contact deleted successfully', { root: true });
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Contact delete failed', error);
                        commit('alert/error', 'Contact delete failed', { root: true });
                    }
                );
        },
        saveProjectType({ commit }, data) {
            commit('progressOn');
            return dataService.post('scheduler/projecttype', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        commit('alert/success', 'Project type saved', { root: true });
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to save project type', error);
                        commit('alert/error', 'Failed to save project type', { root: true });
                    }
                );
        },
        addProjectType({ commit }, data) {
            commit('progressOn');
            return dataService.put('scheduler/projecttype', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        commit('alert/success', 'New project type saved successfully', { root: true });
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to save new project type', error);
                        commit('alert/error', 'Failed to save new project type', { root: true });
                    }
                );
        },
        saveMoneyItem({ commit }, data) {
            commit('progressOn');
            return dataService.post('scheduler/moneyitem', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        commit('alert/success', 'Money item saved', { root: true });
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to save money item', error);
                        commit('alert/error', 'Failed to save money item', { root: true });
                    }
                );
        },
        addMoneyItem({ commit }, data) {
            commit('progressOn');
            return dataService.put('scheduler/moneyitem', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        commit('alert/success', 'New money item saved successfully', { root: true });
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to save new money item', error);
                        commit('alert/error', 'Failed to save new money item', { root: true });
                    }
                );
        },
        fetchPerfStatus({ commit }) {
            commit('progressOn');
            return dataService.getPerf('status')
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Performance status fetch failed', error);
                        commit('alert/error', 'Performance status fetch failed', { root: true });
                    }
                );
        },
        uploadFiles({ commit }, data) {
            commit('progressOn');
            return dataService.uploadFiles('scheduler/upload', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        commit('alert/success', 'File(s) saved', { root: true });
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to save file(s) data', error);
                        commit('alert/error', 'Failed to save file(s) data', { root: true });
                    }
                );
        },
        listFiles({ commit }, id) {
            if (!id || id === -1) {
                return;
            }
            return dataService.getById('scheduler/files', id)
                .then(
                    (data) => data,
                    (error) => {
                        console.error('Failed to fetch project files', error);
                        commit('alert/error', 'Failed to fetch project files', { root: true });
                    }
                );
        },
        downloadFile({ commit }, id) {
            return dataService.downloadFile('scheduler/file', id)
                .then(
                    (response) => response,
                    (error) => {
                        console.error('Failed to download project file', error);
                        commit('alert/error', 'Failed to download project file', { root: true });
                    }
                );
        },
        deleteFile({ commit }, id) {
            commit('progressOn');
            return dataService.deleteById('scheduler/file', id)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('File delete failed', error);
                        commit('alert/error', 'File delete failed', { root: true });
                    }
                );
        },
        getPresignPostS3({ commit }, data) {
            commit('progressOn');
            return dataService.post('upload-sign', data)
                .then(
                    (data) => {
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to get presign post', error);
                        commit('alert/error', 'Failed to upload file(s)', { root: true });
                    }
                );
        },
        uploadFileS3({ commit }, data) {
            return dataService.uploadFileS3(data.url, data.form)
                .then(
                    (data) => {
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to upload file to S3', error);
                        commit('alert/error', 'Failed to upload file(s)', { root: true });
                    }
                );
        },
        uploadFileSuccessS3({ commit }, data) {
            return dataService.post('upload-success', data)
                .then(
                    (data) => {
                        commit('progressOff');
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to upload S3 file information to server', error);
                        commit('alert/error', 'Failed to upload file(s)', { root: true });
                    }
                );
        },
        getPresignUrlS3({ commit }, id) {
            commit('progressOn');
            return dataService.getById('download-sign', id)
                .then(
                    (data) => {
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to get presign url for file download', error);
                        commit('alert/error', 'Failed to get presign url for file download', { root: true });
                    }
                );
        },
        downloadFileS3({ commit }, url) {
            commit('progressOn');
            return dataService.downloadFileS3(url)
                .then(
                    (response) => {
                        commit('progressOff');
                        return response;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to download project file', error);
                        commit('alert/error', 'Failed to download project file', { root: true });
                    }
                );
        },
        fetchNavtorRouteList({ commit }, shipId) {
            commit('progressOn');
            return dataService.getById('navtor/list', shipId)
                .then(
                    (data) => {
                        commit('progressOff');
                        // commit('alert/success', 'Navtor route list updated from external API', { root: true });
                        return data;
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to get Navtor route list from external API', error);
                        commit('alert/error', 'Failed to get Navtor route list from external API', { root: true });
                    }
                );
        },
        fetchNavtorRoutes({ commit }, params) {
            commit('progressOn');
            return dataService.post('navtor/route', params)
                .then(
                    (data) => {
                        commit('progressOff');
                        if (data.success === data.total) {
                            commit('alert/success', `${data.success}/${data.total} of Navtor routes downloaded from external API`, { root: true });
                        } else {
                            commit('alert/error', `${data.success}/${data.total} Navtor routes downloaded from external API`, { root: true });
                        }
                    },
                    (error) => {
                        commit('progressOff');
                        console.error('Failed to download Navtor routs from external API', error);
                        commit('alert/error', 'Failed to download Navtor routes from external API', { root: true });
                    }
                );
        },
        fetchResource({ commit }, id) {
            return dataService.getById('scheduler/resource', id)
                .then(
                    (data) => {
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch resource', error);
                        commit('alert/error', 'Failed to fetch resource', { root: true });
                    }
                );
        },
        fetchMotionData({ commit }, id) {
            return dataService.getByIdPerf('motion', id)
                .then(
                    (data) => {
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch motion data', error);
                        commit('progressOff');
                    }
                );
        },
        progressOn({ commit }) {
            commit('progressOn');
        },
        progressOff({ commit }) {
            commit('progressOff');
        },
        fetchIceData({ commit }) {
            return dataService.get('icedata')
                .then(
                    (data) => {
                        return data;
                    },
                    (error) => {
                        console.error('Failed to fetch ice data', error);
                        commit('progressOff');
                        return null;
                    }
                );
        },
    },
    mutations: {
        setShips(state, data) {
            state.ships = data;
        },
        setTerminals(state, data) {
            state.terminals = data;
        },
        setDynamic(state, data) {
            state.dynamic = data;
        },
        setFirstDayOfWeek(state, data) {
            state.firstDayOfWeek = data;
        },
        setEvents(state, data) {
            state.events = data;
        },
        setTrips(state, data) {
            state.trips = data;
        },
        setPorts(state, data) {
            state.ports = data;
        },
        setSchedulerRoutes(state, data) {
            state.schedulerRoutes = data;
        },
        setEvent(state, data) {
            state.event = data;
        },
        setEventHistory(state, data) {
            state.eventHistory = data;
        },
        setStreamUrl(state, data) {
            state.streamUrl = data;
        },
        setPastTrack(state, data) {
            state.pastTrack = { ...data };
        },
        setSettings(state, data) {
            state.settings[data.prefix] = data.data;
        },
        setCustomSettings(state, data) {
            state.customSettings = data.data;
        },
        deleteCustomSettings(state, id) {
            let index;
            for (const key in state.customSettings) {
                if (state.customSettings.hasOwnProperty(key)) {
                    if (state.customSettings[key].id === id) {
                        index = key;
                    }
                }
            }
            state.customSettings.splice(index, 1);  
        },
        setRoutes(state, data) {
            state.routes = data;
        },
        setWaypoints(state, data) {
            state.waypoints = data;
        },
        setAnalyticsData(state, data) {
            state.analyticsData = data;
        },
        setPerformanceData(state, data) {
            state.performanceData = data;
        },
        setAggregateValues(state, data) {
            state.aggregateValues = data;
        },
        setPerformanceParams(state, data) {
            state.performanceParams = data;
        },
        setCustomParameters(state, data) {
            state.customParameters = data;
        },
        setShip(state, data) {
            let index;
            for (const key in state.ships) {
                if (state.ships.hasOwnProperty(key)) {
                    if (state.ships[key].id === data.id) {
                        index = key;
                    }
                }
            }
            state.ships.splice(index, 1, data);  
        },
        addShip(state, data) {
            state.ships.push(data);
        },
        deleteShip(state, id) {
            let index;
            for (const key in state.ships) {
                if (state.ships.hasOwnProperty(key)) {
                    if (state.ships[key].id === id) {
                        index = key;
                    }
                }
            }
            state.ships.splice(index, 1);  
        },
        deleteTerminal(state, id) {
            let index;
            for (const key in state.terminals) {
                if (state.terminals.hasOwnProperty(key)) {
                    if (state.terminals[key].id === id) {
                        index = key;
                    }
                }
            }
            state.terminals.splice(index, 1);  
        },
        setWmsCapabilities(state, data) {
            state.wmsCapabilities = data;
        },
        progressOn(state) {
            state.progress = true;
        },
        progressOff(state) {
            state.progress = false;
        },
        setActiveCameras(state, data) {
            state.activeCameras = data;
        },
        setSchedulerParams(state, data) {
            state.schedulerParams = { ...data };
        },
        setExchangeRates(state, data) {
            state.exchangeRates = { ...data };
        },
        setPortCalls(state, data) {
            state.portCalls = data;
        },
        setMoneyData(state, data) {
            state.moneyData = data;
        },
        setContacts(state, data) {
            state.contacts = data;
        },
        setAvgDa(state, data) {
            state.avgDa = data;
        },
        setDischCostsOnlyPort(state, data) {
            state.dischCostsOnlyPort = { ...data };
        },
        setCiiRatings(state, data) {
            state.ciiRatings = data;
        },
        setLastOpenedEvent(state, id) {
            state.lastOpenedEvent = {id, time: moment().unix()};
        },
    },
    getters: {
        getShips(state) {
            return state.ships;
        },
        getTerminals(state) {
            return state.terminals;
        },
        getDynamic(state) {
            return state.dynamic;
        },
        getFirstDayOfWeek(state) {
            return state.firstDayOfWeek;
        },
        getEvent(state) {
            return state.event;
        },
        getPorts(state) {
            return state.ports;
        },
        getSchedulerRoutes(state) {
            return state.schedulerRoutes;
        },
        getPastTrack(state) {
            return state.pastTrack;
        },
        getEvents(state) {
            return state.events;
        },
        getEventHistory(state) {
            return state.eventHistory;
        },
        getStreamUrl(state) {
            return state.streamUrl;
        },
        getCustomParameters(state) {
            return state.customParameters;
        },
        getTrips(state) {
            return state.trips;
        },
        getSettings(state) {
            return state.settings;
        },
        getCustomSettings(state) {
            return state.customSettings;
        },
        getRoutes(state) {
            return state.routes;
        },
        getWaypoints(state) {
            return state.waypoints;
        },
        getWmsCapabilities(state) {
            return state.wmsCapabilities;
        },
        getAnalyticsData(state) {
            return state.analyticsData;
        },
        getProgress(state) {
            return state.progress;
        },
        getActiveCameras(state) {
            return state.activeCameras;
        },
        getSchedulerParams(state) {
            return state.schedulerParams;
        },
        getExchangeRates(state) {
            return state.exchangeRates;
        },
        getPortCalls(state) {
            return state.portCalls;
        },
        getMoneyData(state) {
            return state.moneyData;
        },
        getAvgDa(state) {
            return state.avgDa;
        },
        getDischCostsOnlyPort(state) {
            return state.dischCostsOnlyPort;
        },
        getCiiRatings(state) {
            return state.ciiRatings;
        },
        getLastOpenedEvent(state) {
            return state.lastOpenedEvent;
        }
    }
};
