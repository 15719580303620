<template>
    <div>
        <v-card>
            <v-card-title>ECDIS routes</v-card-title>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div>
                            <v-icon medium color="secondary">help</v-icon> <span class="ml-2">How do we use ECDIS routes in BOQA</span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="cardbg">
                        <br />
                        <h3>ECDIS routes in BOQA</h3>
                        <p>
ECDIS routes are used in many BOQA events, such as tracking Cross track and Safety corridor breach, monitoring Heading and Speed and
                            calculating route weather forecasts. In case a ship has no active route in the system or is too far away from an active route, then
                            the "Active route" event is triggered. Routes are always ship specific and each ship can only have one active route at any given
                            time. ECDIS routes are also used to display DTG and ETA on the Dashboard.
</p>

                        <h4>Navtor route import</h4>
                        <p>
Routes from Navtor NavTracker can be imported in case the ship is equipped with Navtor NavTracker/NavBox and the Navtor user
                            credentials are available on the Ship settings page
</p>

                        <h4>BVS route creation</h4>
                        <p>
Uploaded ECDIS routes can be used for generating composite itinerary files for the Bon Voyage system in its special .bvs file-format.
                        </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <!-- route management for each ship -->
        <EcdisRouteItem v-for="(ship, index) in ships" :key="index" :ship="ship" />

        <StatusDialog />
</div>
</template>

<script>
    import {
        mapActions
    } from 'vuex';
    import EcdisRouteItem from '../components/EcdisRouteItem.vue';
    import StatusDialog from '../components/StatusDialog.vue';

    export default {
        name: 'EcdisRoutes',
        components: {
            EcdisRouteItem,
            StatusDialog
        },
        data() {
            return {};
        },
        created() {
            this.fetchShips();
            this.fetchDynamic();
        },
        computed: {
            ships() {
                return this.$store.getters['data/getShips'].filter((o) => o.external !== 1);
            },
        },
        methods: {
            ...mapActions({
                fetchShips: 'data/fetchShips',
                fetchDynamic: 'data/fetchDynamic',
            }),
        },

    };
</script>

<style scoped>
    .sectionheader {
        padding-bottom: 10px;
    }
</style>
