import { userService } from '../services';
import router from '../router';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ dispatch, commit }, { email, password }) {
            commit('loginRequest', { email });
            userService.login(email, password)
                .then(
                    (user) => {
                        commit('loginSuccess', user);
                        if (localStorage.getItem('schedulerEventId')) { // redirect to events page
                            const id = localStorage.getItem('schedulerEventId');
                            localStorage.removeItem('schedulerEventId');
                            document.location.href = `/desk/${id}`;
                        } else if (localStorage.getItem('eventId')) { // redirect to events page
                            const id = localStorage.getItem('eventId');
                            localStorage.removeItem('eventId');
                            document.location.href = `/events/${id}`;
                        } else {
                            document.location.href = '/dashboard';
                        }
                    },
                    (error) => {
                        commit('loginFailure', error);
                        dispatch('alert/error', 'Login failed. Check your credentials.', { root: true });
                    }
                );
        },
        logout({ commit }) {
            userService.logout();
            commit('logout');
            if (router.history.current.path !== '/login') {
                router.push('/login');
            }
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
        }
    },
    getters: {
        loginStatus(state) {
            return state.status;
        },
        getUser(state) {
            return state.user;
        },
        features(state) {
            return state.user.user.features;
        }
    }
};
