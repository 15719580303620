import axios from 'axios';
import moment from 'moment';
import { authHeader, handleResponse, config } from '../helpers';

export default class GridDataService {
    constructor() {
        this.requestOptions = {
            headers: authHeader()
        };
        this.BASE_URL = config.apiUrl;
        // this.BASE_URL = 'http://localhost:3000';
    }

    get(state) {
        const pageQuery = `/${state.skip}/${state.take}`;
        let sortQuery = '/startDate desc';
        if ((state.sorted || []).length) {
            sortQuery = `/${state.sorted.map((obj) => (obj.direction === 'descending' ? `${obj.name} desc` : obj.name)).reverse().join(',')}`;
        }
        let whereQuery = '/0';
        if (state && state.where && state.where.length > 0) {
            let filter = state.where[0];
            // console.log(state.where);
            if (filter.isComplex === false) {
                whereQuery = '/p.' + filter.predicates[0].field + operator(filter.predicates[0].operator) + `'` + filter.predicates[0].value.toString() + `'`; 
            } else { 
                whereQuery = '/(';
                let tzOffset = moment().utcOffset();
                tzOffset = tzOffset ? tzOffset : 0;
                for (let i = 0; i < filter.predicates.length; i++) {
                    if (filter.predicates[i].isComplex) {
                        if (i > 0) {
                            whereQuery += ' ' + filter.condition + ' ';
                        }
                        whereQuery += '(';
                        for (let j = 0; j < filter.predicates[i].predicates.length; j++) {
                            if (j > 0) {
                                whereQuery += ' ' + filter.predicates[i].condition + ' ';
                            } 
                            if (filter.predicates[i].predicates[j].field === 'yearmonth') {
                                const date = filter.predicates[i].predicates[j].value.split('-');
                                if (date[1] === 'X') {
                                    whereQuery += `YEAR(DATE_ADD(p.startDate, interval ${tzOffset} minute))=` + date[0];
                                } else {
                                    whereQuery += `YEAR(DATE_ADD(p.startDate, interval ${tzOffset} minute))=${date[0]} AND MONTH(DATE_ADD(p.startDate, interval ${tzOffset} minute))=${date[1]}`;
                                }
                            } else if (filter.predicates[i].predicates[j].field === 'startEtdMonth') {
                                whereQuery += `MONTH(DATE_ADD(p.startEtd, interval ${tzOffset} minute))=` + filter.predicates[i].predicates[j].value.toString(); 
                            } else if (filter.predicates[i].predicates[j].field === 'startEtdYear') {
                                whereQuery += `YEAR(DATE_ADD(p.startEtd, interval ${tzOffset} minute))=` + filter.predicates[i].predicates[j].value;
                            } else {
                                whereQuery += 'p.' + filter.predicates[i].predicates[j].field + operator(filter.predicates[i].predicates[j].operator) + `'` + filter.predicates[i].predicates[j].value.toString() + `'`; 
                            }
                        }
                        whereQuery += ')';
                    } else {
                        if (i > 0) {
                            whereQuery += ' ' + filter.condition + ' ';
                        }
                        if (filter.predicates[i].field === 'yearmonth') {
                            const date = filter.predicates[i].value.split('-');
                            if (date[1] === 'X') {
                                whereQuery += `YEAR(DATE_ADD(p.startDate, interval ${tzOffset} minute))=` + date[0];
                            } else {
                                whereQuery += `YEAR(DATE_ADD(p.startDate, interval ${tzOffset} minute))=${date[0]} AND MONTH(DATE_ADD(p.startDate, interval ${tzOffset} minute))=${date[1]}`;
                            }
                        } else if (filter.predicates[i].field === 'startEtdMonth') {
                            whereQuery += `MONTH(DATE_ADD(p.startEtd, interval ${tzOffset} minute))=${filter.predicates[i].value.toString()}`;
                        } else if (filter.predicates[i].field === 'startEtdYear') {
                            whereQuery += `YEAR(DATE_ADD(p.startEtd, interval ${tzOffset} minute))=` + filter.predicates[i].value;
                        } else {
                            whereQuery += 'p.' + filter.predicates[i].field + operator(filter.predicates[i].operator) + `'` + filter.predicates[i].value.toString() + `'`; 
                        }
                    }
               } 
               whereQuery += ')';
           } 
        //    console.log(whereQuery);
        }
        function operator(operator) {
            return operator === 'greaterthan' ? '>' : operator === 'lessthan' ? '<' : '=';
        }
        
        this.url = `${this.BASE_URL}/scheduler/table${pageQuery}${sortQuery}${whereQuery}`;
        return axios.get(this.url, this.requestOptions)
            .then(handleResponse)
            .then((data) => ({ result: this.parseData(data.result), count: data.count })).catch((e) => {
                console.error(e);
                return [];
            });
    }

    getParam(param) {
        return axios.get(`${this.BASE_URL}/${param}`, this.requestOptions)
            .then(handleResponse)
            .then((data) => data).catch((e) => {
                console.error(e);
                return 0;
            });
    }

    parseData(data) {
        if (data && data.length > 0) {
            for (let i = 0, il = data.length; i < il; i++) {
                if (data[i].contactId === null) {
                    data[i].contactId = '';
                }
            }
        }
        return data;
    }
}
