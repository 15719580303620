<template>
    <div class="compass" :style="'background-image: url(' + url + ')'">
        <img class="center" :style="rotate(heading)" :src="shipIcon" />
        <img class="center" v-show="sog > 2" :style="rotate(cog)" :src="require('@/assets/img/cog_arrow.png')" />
        <img class="center" v-show="windDir || windDir === 0" :style="rotate(windDir)" :src="require('@/assets/img/wind_arrow.png')" />
        <img class="center" v-show="swellDir || swellDir === 0" :style="rotate(swellDir)" :src="require('@/assets/img/swell_arrow.png')" />
        <img class="center" v-show="currentDir || currentDir === 0" :style="rotate(currentDir)" :src="require('@/assets/img/current_arrow.png')" />
        <img class="center" v-show="shipTwd || shipTwd === 0" :style="rotate(shipTwd)" :src="require('@/assets/img/true_wind_arrow.png')" />
    </div>
</template>

<script>
    import {
        shipHelpers,
    } from '../helpers';
    import {
        mapGetters,
    } from 'vuex';
    export default {
        name: 'WeatherCompass',
        props: {
            sog: {
                type: Number
            },
            cog: {
                type: Number
            },
            heading: {
                type: Number
            },
            swellDir: {
                type: Number
            },
            windDir: {
                type: Number
            },
            currentDir: {
                type: Number
            },
            shipTwd: {
                type: Number
            },
            navstat: {
                type: Number
            },
            mmsi: {
                type: String
            },
        },
        data() {
            return {
                
            };
        },
        mounted() {
            
        },
        computed: {
            url() {
                if (this.getDarkMode()) {
                    return require(`@/assets/img/compass500.png`);
                } else {
                    return require(`@/assets/img/compass500i.jpg`);
                }
            },
            shipIcon() {
                const icon = shipHelpers.shipIcon(this.navstat, 'os', this.mmsi, null, this.sog);
                return require(`@/assets/img/ships/${icon}`);
            }
        },
        methods: {
            ...mapGetters({
                getDarkMode: 'users/getDarkMode',
            }),
            rotate(angle) {
                return 'transform: rotate('+ Number.parseFloat(angle) + 'deg)';
            },
        }
    };
</script>

<style>
  
</style>
<style scoped>
    .compass {
        position: absolute;
        width: 200px;
        height: 200px;
        background-size: 100%;
        right: 30px;
        top: 10px;
        bottom:0;
        margin:auto;
    }
    .center{
        left: 0;
        position: absolute;
        opacity: 1;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
</style>
