<template>
    <v-expansion-panels>
        <v-expansion-panel>
             <v-expansion-panel-header>
                <span class="text-body-1 ml-2">{{ header }}</span>   
            </v-expansion-panel-header>
            <v-expansion-panel-content v-model="panel.settings" class="cardbg">
                <v-card class="cardbg">
                    <v-container grid-list-lg>
                        <v-layout wrap>
                            <v-flex xs12 sm4 md3 lg2>
                                <v-select v-if="newEvent" @change="getShipParams(settings.shipId)" v-model="settings.shipId" :items="ships" item-key="id" item-text="[name]" item-value="id" label="Ship" persistent-hint />
                                <v-text-field v-if="!newEvent" disabled v-model="settings.name" single-line label="Ship" persistent-hint />
                            </v-flex>
                            <v-flex xs12 sm4 md3 lg2>
                                <v-select v-if="newEvent" v-model="settings.param" :items="params" item-key="param" item-text="[name]" item-value="param" label="Parameter" persistent-hint />
                                <v-text-field v-if="!newEvent" disabled v-model="settings.paramName" single-line label="Parameter" persistent-hint />
                            </v-flex>
                            <v-flex xs12 sm4 md3 lg2>
                                <v-select v-model="settings.operator" :items="operators" item-key="value" item-text="[name]" item-value="value" label="Operator" persistent-hint />
                            </v-flex>
                            <v-flex xs12 sm4 md3 lg2>
                                <v-text-field v-model="settings.limitValue" hide-details single-line type="number" label="Limit value" persistent-hint />
                            </v-flex>
                            <v-flex xs12 sm4 md3 lg2>
                                <v-select v-model="settings.speed" :items="speeds" item-key="value" item-text="[name]" item-value="value" label="Speed limit" persistent-hint />
                            </v-flex>
                            <v-flex xs12 sm4 md3 lg2>
                                <v-select v-model="settings.timeLimit" :items="timelimits" item-key="value" item-text="[name]" item-value="value" label="Sample limit" persistent-hint />
                            </v-flex>
                            <v-flex xs12 sm4 md3 lg2>
                                <v-select v-model="settings.severity" :items="severitys" item-key="value" item-text="[name]" item-value="value" label="Severity" persistent-hint />
                            </v-flex>
                            <v-flex xs12 sm4 md3 lg2>
                                <v-select v-model="settings.groupId" :items="groups" item-key="id" item-text="[name]" item-value="id" label="Alert group" persistent-hint />
                            </v-flex>
                            <v-flex xs12 sm4 md3 lg2 d-flex>
                                <v-spacer />
                                <v-switch v-model="settings.enabled" :label="settings.enabled ? 'On' : 'Off'" color="primary" hint="Enabled?" persistent-hint />
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn v-if="newEvent" text @click="closePanel('settings');params = [];settings = {
                            timeLimit: null,
                            groupId: null,
                            shipId: null,
                            enabled: false,
                            speed: null,
                            param: null,
                            operator: null,
                            limitValue: null,
                            severity: null }
                            ">
cancel
</v-btn>
                    
                        <v-btn v-if="!newEvent" color="error" @click="deleteCustomSettings(settings.id)" :disabled="userLevel < 1">
Delete
                            <v-icon right class="hidden-xs-only" dark>clear</v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn color="primary" @click="callSaveCustomSettings()" :disabled="userLevel < 1">
Save
                            <v-icon right class="hidden-xs-only" dark>check</v-icon>
                        </v-btn>
                        <v-spacer />
                    </v-card-actions>
                </v-card>
</v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex';

export default {
    name: 'BoqaSettingsCard',
    props: {
        header: {
            type: String,
            required: true,
        },
        settings: {
            type: Object,
            required: true,
        },
        newEvent: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            params: [],
            timelimits: [
                { value: 0, name: '0' },
                { value: 1, name: '1' },
                { value: 2, name: '2' },
                { value: 3, name: '3' },
                { value: 4, name: '4' },
                { value: 5, name: '5' },
                { value: 6, name: '6' },
                { value: 7, name: '7' },
                { value: 8, name: '8' },
                { value: 9, name: '9' },
                { value: 10, name: '10' },
                { value: 12, name: '12' },
                { value: 15, name: '15' },
                { value: 20, name: '20' },
                { value: 30, name: '30' },
                { value: 40, name: '40' },
                { value: 50, name: '50' },
                { value: 60, name: '60' },
                { value: 70, name: '70' },
                { value: 80, name: '80' },
                { value: 90, name: '90' },
                { value: 100, name: '100' },
                { value: 120, name: '120' },
                { value: 140, name: '140' },
                { value: 160, name: '160' },
                { value: 180, name: '180' },
                { value: 200, name: '200' },
            ],
            speeds: [
                { value: 0, name: '0 kn' },
                { value: 1, name: '1 kn' },
                { value: 2, name: '2 kn' },
                { value: 3, name: '3 kn' },
                { value: 4, name: '4 kn' },
                { value: 5, name: '5 kn' },
                { value: 6, name: '6 kn' },
                { value: 7, name: '7 kn' },
                { value: 8, name: '8 kn' },
                { value: 9, name: '9 kn' },
                { value: 10, name: '10 kn' },
                { value: 12, name: '12 kn' },
                { value: 15, name: '15 kn' },
                { value: 20, name: '20 kn' },
            ],
            operators: [
                { value: '<', name: 'Less (<)' },
                { value: '<=', name: 'Less or equal (<=)' },
                { value: '=', name: 'Equal (=)' },
                { value: '>=', name: 'Greater or equal (>=)' },
                { value: '>', name: 'Greater (>)' },
            ],
            severitys: [
                { value: 0, name: 'Routine' },
                { value: 1, name: 'Notification' },
                { value: 2, name: 'Critical' },
            ],
            panel: {},
            headers: [{
                    text: 'Ship',
                    align: 'left',
                    value: 'name'
                },
                {
                    text: 'Parameter',
                    value: 'param'
                },
                {
                    text: 'Operator',
                    value: 'operator'
                },
                {
                    text: 'Limit value',
                    value: 'limitValue'
                },
                {
                    text: 'Speed limit',
                    value: 'speed'
                },
                {
                    text: 'Sample limit',
                    value: 'timeLimit'
                },
                {
                    text: 'Severity',
                    value: 'severity'
                },
                {
                    text: 'Alert group',
                    value: 'groupId'
                },
                {
                    text: 'Enabled',
                    value: 'enabled'
                },
                {
                    text: 'Author',
                    value: 'author'
                }
            ],
        };
    },
    mounted() {
        // console.log(this.settings);
        // this.getShipParams(this.settings.shipId);
        // console.log(this.params)
    },
    computed: {
        groups() {
            return this.getGroups();
        },
        userLevel() {
            return this.getUser().user.level;
        },
        ships() {
            return this.getShips();
        },
    },
    methods: {
        ...mapGetters({
            getGroups: 'users/getGroups',
            getShips: 'data/getShips',
            getUser: 'authentication/getUser',
            getCustomParameters: 'data/getCustomParameters',
        }),
        ...mapActions({
            saveCustomSettings: 'data/saveCustomSettings',
            deleteCustomSettings: 'data/deleteCustomSettings',
            fetchSettings: 'data/fetchSettings',
        }),
        getShipParams(shipId) {
            if (shipId) {
                this.params = this.getCustomParameters().filter((o) => o.shipId === shipId);
            } else {
                this.params = [];
            }
        },
        callSaveCustomSettings() {
            if (!this.settings.shipId) {
                this.$store.dispatch('alert/error', 'Ship must be defined.', { root: true });
                return;
            } if (!this.settings.param) {
                this.$store.dispatch('alert/error', 'Parameter must be defined.', { root: true });
                return;
            } if (!this.settings.operator) {
                this.$store.dispatch('alert/error', 'Operator must be defined.', { root: true });
                return;
            } if (typeof this.settings.limitValue === 'undefined' || this.settings.limitValue === null) {
                this.$store.dispatch('alert/error', 'Limit value must be defined.', { root: true });
                return;
            } 
            if (typeof this.settings.speed === 'undefined' || this.settings.speed === null) {
                this.settings.speed = 0;
            }
            if (typeof this.settings.timeLimit === 'undefined' || this.settings.timeLimit === null) {
                this.settings.timeLimit = 0;
            }
            if (typeof this.settings.enabled === 'undefined' || this.settings.enabled === null) {
                this.settings.enabled = 0;
            }
            if (typeof this.settings.severity === 'undefined' || this.settings.severity === null) {
                this.settings.severity = 0;
            }
            
            this.saveCustomSettings(this.settings).then(() => {
                this.fetchSettings('Custom');
            });
        },
        closePanel(panel) {
            if (panel === 'ship') {
                this.panel.ship = null;
            } else if (panel === 'settings') {
                this.panel.settings = null;
            }
        },
    }
};
</script>
